import { useTranslation } from 'react-i18next';
import PreToggleButton from '../../../components/presentations/PreToggleButton';

type Props = {
  onEmissionTypeToggle: (value: CRREMConfigToggleEnum) => void;
  defaultValue: CRREMConfigToggleEnum;
};

export enum CRREMConfigToggleEnum {
  CUSTOM = 'custom',
  DEFAULT = 'default',
}

const CRREMConfigToggle = ({ onEmissionTypeToggle, defaultValue }: Props) => {
  const { t } = useTranslation();

  return (
    <PreToggleButton
      leftValue={{ key: CRREMConfigToggleEnum.DEFAULT, value: t('General_Standard') }}
      rightValue={{ key: CRREMConfigToggleEnum.CUSTOM, value: t('General_Specific') }}
      size="small"
      onChange={(_, value) => onEmissionTypeToggle(value)}
      initialValueType={defaultValue}
      onToggle={onEmissionTypeToggle}
    />
  );
};

export default CRREMConfigToggle;
