/* eslint-disable @typescript-eslint/ban-ts-comment */
import sortBy from 'lodash/sortBy';
import { TARGET_PATH_FIRST_YEAR } from './TargetPathConstants';

const pathToYearIndex = (year: number): number => year - TARGET_PATH_FIRST_YEAR;
const emissionYearToIndex = (year: number): number => year - TARGET_PATH_FIRST_YEAR;

export function pathValueAtYear<T>(path: T[], year: number): T {
  return path[pathToYearIndex(year)];
}

export function pathValuesFromYear<T>(path: T[], year: number): T[] {
  if (!path || !year) return [];

  return path.slice(pathToYearIndex(year));
}

export function pathValuesWithYearFromYear<T>(path: T[], year: number = TARGET_PATH_FIRST_YEAR): [number, T][] {
  if (!path || !year) return [];

  const pathFromYear = pathValuesFromYear(path, year);
  return pathFromYear.map((value, idx) => [year + idx, value]);
}

export function co2EmissionsAtYear(emissions: number[], year: number): number {
  return emissions[emissionYearToIndex(year)];
}

export function co2EmissionsFromYear(emissions: number[], year: number): number[] {
  return emissions.slice(emissionYearToIndex(year));
}

/**
 * Given an active building model ("status quo") and energy paths of each action,
 * this function merges the energy paths the actions into a single path and retrieves the value at a given year.
 */
export function getValueFromMergedPath<T>(
  basePath: T[],
  actionData: { data: T[]; implementation_year: number; order: number }[],
  year: number,
): T {
  let currentData = basePath;

  const lastActionBeforeYear = sortBy(
    actionData.filter((action) => action.implementation_year <= year),
    (action) => action.order,
  ).pop();

  if (lastActionBeforeYear) {
    currentData = lastActionBeforeYear.data;
  }

  return pathValueAtYear(currentData, year);
}

/**
 * Given an active building model ("status quo") and energy paths of each action,
 * this function retrieves the value at a given year from the last action that was implemented - independently of the year.
 */
export function getValueFromLastAction<T>(
  basePath: T[],
  actionData: { data: T[]; implementation_year: number; order: number }[],
  year: number,
): T {
  let currentData = basePath;

  const lastAction = sortBy(actionData, (action) => action.order).pop();

  if (lastAction) {
    currentData = lastAction.data;
  }

  return pathValueAtYear(currentData, year);
}
