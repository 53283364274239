// eslint-disable-next-line no-restricted-imports
import { useQuery } from '@apollo/client';
import { Navigate, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { GET_SINGLE_BUILDING_DATA } from '../graphql/EsgAnalysis.queries';
import { DataCollectionTabEnum } from '../sections/DataCollection/Buildings/TabsEnums';
import { PATHS } from './paths';

export const redirects = [
  {
    from: '/action-planning/actionplan/:id',
    to: PATHS.actionPlanning.scenarios,
  },
  {
    from: '/data-collection/building-draft/:id',
    to: PATHS.dataCollection.energyCertificateDraft,
  },
  {
    from: '/data-collection/building/:id/consumption/:consumptionId',
    to: PATHS.dataCollection.buildingConsumptionInvoice,
  },
  {
    from: '/data-collection/building/:id',
    to: PATHS.dataCollection.building,
  },
  {
    from: '/org/user/:id',
    to: PATHS.org.user,
  },
  {
    from: '/data-collection/emission-certificate/:id',
    to: PATHS.dataCollection.emissionCertificate,
  },
  {
    from: '/data-collection/emission-certificate-draft/:id',
    to: PATHS.dataCollection.emissionCertificateDraft,
  },
  {
    from: '/data-collection/emission-certificate-drafts',
    to: PATHS.dataCollection.emissionCertificateDrafts,
  },
];

export const Redirect = ({ redirect }: { redirect: (typeof redirects)[number] }) => {
  const params = useParams();
  const [searchParams] = useSearchParams();

  return (
    <Navigate
      to={{
        pathname: redirect.to(params as any),
        search: searchParams.toString().replace('showTab', 'tab'),
      }}
      replace
    />
  );
};

export const RedirectToEsgAnalysisBuilding = () => {
  const navigate = useNavigate();
  void useQuery(GET_SINGLE_BUILDING_DATA, {
    onCompleted: (data) => {
      const buildings = data.building;
      if (buildings.length === 0) {
        return navigate(PATHS.dataCollection.buildings(null, { tab: DataCollectionTabEnum.BUILDINGS }), {
          replace: true,
        });
      }

      const firstBuildingId = data.building.at(0)?.id;
      if (firstBuildingId) {
        return navigate(PATHS.esgAnalysis.building({ id: firstBuildingId }), { replace: true });
      }
      return navigate(PATHS.notFound());
    },
  });

  return null;
};
