import { Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { FileCompareIcon } from '../../../../assets/Icons/FileCompareIcon';

type Props = {
  handleCompareScenarioClick: VoidFunction;
};

export const CompareScenariosButton = ({ handleCompareScenarioClick }: Props) => {
  const { t } = useTranslation();

  return (
    <Button
      onClick={handleCompareScenarioClick}
      startIcon={<FileCompareIcon />}
      variant={'outlined'}
      size="small"
      sx={{ mr: 2 }}
    >
      <Typography variant={'inherit'}>{t('ActionPlanningScenario_CompareScenarios')}</Typography>
    </Button>
  );
};
