/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useQuery } from '@apollo/client';
import {
  Button,
  Card,
  Checkbox,
  debounce,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import {
  BuildingDetailsFragment,
  buildings_table_view_bool_exp,
  buildings_table_view_order_by,
} from '@predium/client-graphql';
import { country_enum, eu_taxonomy_compliance_enum, order_by, type_of_use_enum } from '@predium/enums';
import { getBuildingsWhere } from '@predium/graphql-where-client';
import { translateTypeOfUseEnum_dynamic, translateUnitEnum_dynamic } from '@predium/i18n/client';
import { ensureDefined, Units } from '@predium/utils';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { ICONS } from '../../../../assets/icons';
import EfficiencyClassLabel from '../../../../components/EfficiencyClassLabel';
import Iconify from '../../../../components/Iconify';
import { DelayedLoading } from '../../../../components/Loading';
import OverflowText from '../../../../components/OverflowText';
import Scrollbar from '../../../../components/Scrollbar';
import SearchNotFoundRow from '../../../../components/SearchNotFound';
import { TableSelectedActions } from '../../../../components/table';
import TableMoreMenu from '../../../../components/table/TableMoreMenu';
import TablePaginationStandard from '../../../../components/table/TablePaginationStandard';
import { GET_BUILDINGS_COUNT, GET_FILTERED_BUILDINGS_FROM_VIEW } from '../../../../graphql/EsgAnalysis.queries';
import useEsgAnalysisFilters from '../../../../hooks/useEsgAnalysisFilters';
import useTable from '../../../../hooks/useTable';
import { useLanguage } from '../../../../provider/LanguageProvider';
import { PATHS } from '../../../../routes';
import { getBuildingWhereFilters } from '../../../../utils/getWhereFilters';
import { highlightText } from '../../../../utils/highlightText';
import EsgPdfExport from '../../Building/EsgPdfExport';
import EUTaxonomyTooltip, { calculateEUTaxonomyToBeSaved, euTaxonomyColors } from '../../Components/EUTaxonomyTooltip';
import { EsgAnalysisFiltersKeys, EsgAnalysisFiltersSearchParams } from '../../EsgAnalysisFilters/EsgAnalysisFilters';
import EsgAnalysisBuildingListHead, { HeadLabel } from './EsgAnalysisBuildingListHead';
import EsgAnalysisBuildingListToolbar from './EsgAnalysisBuildingListToolbar';

//PRE-5877 refactor this
const getStrandingYearFilter = (filters: EsgAnalysisFiltersSearchParams) => {
  let strandingYearFilter: buildings_table_view_bool_exp['first_stranding_year'] = {};
  const minStrandingYear = filters?.minStrandingYear?.[0];
  const maxStrandingYear = filters?.maxStrandingYear?.[0];

  if (minStrandingYear) {
    strandingYearFilter = strandingYearFilter || {};
    strandingYearFilter._gte = minStrandingYear;
  }
  if (maxStrandingYear) {
    strandingYearFilter = strandingYearFilter || {};
    strandingYearFilter._lte = maxStrandingYear;
  }

  return strandingYearFilter;
};

type Props = {
  showTotal: boolean;
};

export default function EsgAnalysisBuildingListTab({ showTotal }: Props) {
  const { t } = useTranslation();
  const { localize } = useLanguage();
  const navigate = useNavigate();
  const { filters, replaceFilter } = useEsgAnalysisFilters();
  const { buildingIds } = filters;
  const currentYear = new Date().getFullYear();

  const [selected, setSelected] = useState<number[]>(buildingIds ?? []);
  const [buildingToExportAsPdf, setBuildingToExportAsPdf] = useState<{
    id: number;
    street: string;
    city: string;
    postalCode: string;
  } | null>(null);
  const [filteredRows, setFilteredRows] = useState<BuildingDetailsFragment[]>([]);

  const {
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    order,
    orderBy,
    filterName,
    handleFilterByName,
    handleRequestSort,
  } = useTable({ defaultOrderBy: 'street', defaultRowsPerPage: 25 });

  const [debouncedFilterName, setDebouncedSearchTerm] = useState('');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetSearchTerm = useCallback(
    debounce((value) => {
      setDebouncedSearchTerm(value);
    }, 300), // Adjust the debounce delay as needed
    [],
  );

  useEffect(() => {
    debouncedSetSearchTerm(filterName);
    setPage(0);
  }, [filterName, debouncedSetSearchTerm, setPage]);

  const getOrderBy = useCallback((): buildings_table_view_order_by[] => {
    const newOrder = order === 'asc' ? order_by.asc : order_by.desc;
    const fallbackOrder = { street: newOrder };

    switch (orderBy) {
      case 'final_energy':
        return showTotal
          ? [{ energy_final_total: newOrder }, fallbackOrder]
          : [{ energy_final: newOrder }, fallbackOrder];
      case 'primary_energy':
        return showTotal
          ? [{ energy_primary_total: newOrder }, fallbackOrder]
          : [{ energy_primary: newOrder }, fallbackOrder];
      case 'co2_emissions':
        return showTotal
          ? [{ co2_emissions_total: newOrder }, fallbackOrder]
          : [{ co2_emissions: newOrder }, fallbackOrder];
      case 'co2_taxes':
        return showTotal ? [{ co2_costs_total: newOrder }, fallbackOrder] : [{ co2_costs: newOrder }, fallbackOrder];
      case 'efficiency_class':
        return [{ efficiency_class_id_order: newOrder }, fallbackOrder];
      case 'eu_taxonomy':
        return [{ eu_taxonomy_compliance_id_order: newOrder }, fallbackOrder];
      case 'street':
        return [{ street: newOrder }];
      default:
        return [{ street: newOrder }];
    }
  }, [order, orderBy, showTotal]);

  const buildingWhere = {
    energy_path_year: { _eq: currentYear },
    building: {
      ...getBuildingsWhere(getBuildingWhereFilters(filters), debouncedFilterName),
    },
    first_stranding_year: getStrandingYearFilter(filters),
  } as buildings_table_view_bool_exp;

  const {
    data: filteredBuildingsData,
    previousData: filteredBuildingsPreviousData,
    loading,
  } = useQuery(GET_FILTERED_BUILDINGS_FROM_VIEW, {
    fetchPolicy: 'cache-and-network',
    variables: {
      buildingWhere,
      orderBy: getOrderBy(),
      limit: rowsPerPage,
      offset: page * rowsPerPage,
    },
  });

  const { data: buildingsCountData, previousData } = useQuery(GET_BUILDINGS_COUNT, {
    variables: {
      buildingWhere,
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    setSelected(buildingIds ?? []);
  }, [buildingIds]);

  const handleAssetClick = (id: number) => {
    navigate(PATHS.esgAnalysis.building({ id }));
  };

  useEffect(() => {
    const filteredBuildings = filteredBuildingsData?.buildings_table_view
      ? filteredBuildingsData.buildings_table_view
      : filteredBuildingsPreviousData?.buildings_table_view
      ? filteredBuildingsPreviousData.buildings_table_view
      : [];

    setFilteredRows(filteredBuildings);
  }, [filteredBuildingsData, filteredBuildingsPreviousData]);

  const totalBuildingsCount = useMemo(() => {
    return buildingsCountData?.buildings_table_view_aggregate.aggregate?.count
      ? buildingsCountData?.buildings_table_view_aggregate.aggregate?.count
      : previousData?.buildings_table_view_aggregate.aggregate?.count ?? 0;
  }, [buildingsCountData, previousData]);

  const handleCheckboxClick = (id: number) => {
    const selectedIndex = selected.indexOf(id);
    if (selectedIndex === -1) {
      setSelected([...selected, id]);
    } else {
      // removes the index from the array in a more efficient way than filter
      setSelected([...selected.slice(0, selectedIndex), ...selected.slice(selectedIndex + 1)]);
    }
  };

  const handleSelectAllClick = (checked: boolean) => {
    if (checked) {
      const newSelectedIds: number[] = filteredRows.map((row) => ensureDefined(row.id));
      setSelected(newSelectedIds);
    } else {
      setSelected([]);
    }
  };

  const isNotFound = !filteredRows.length && Boolean(filterName);

  const tableHead: HeadLabel[] = [
    { id: 'street', label: t('General_Address'), minWidth: 240 },
    { id: 'typesOfUse', label: t('General_TypeOfUse'), minWidth: 140, sortingDisabled: true },
    {
      id: 'co2_emissions',
      label: t('General_CO2Intensity'),
      subtitle: showTotal
        ? translateUnitEnum_dynamic(Units.co2EmissionsPerYear, t)
        : translateUnitEnum_dynamic(Units.co2EmissionsPerAreaYear, t),
      minWidth: 140,
    },
    {
      id: 'co2_taxes',
      label: t('General_CO2Taxes'),
      subtitle: showTotal
        ? translateUnitEnum_dynamic(Units.pricePerYear, t)
        : translateUnitEnum_dynamic(Units.pricePerAreaYear, t),
      minWidth: 140,
    },
    {
      id: 'primary_energy',
      label: t('General_PrimaryEnergy'),
      subtitle: showTotal
        ? translateUnitEnum_dynamic(Units.energyPerYear, t)
        : translateUnitEnum_dynamic(Units.energyPerAreaYear, t),
      minWidth: 140,
    },
    {
      id: 'final_energy',
      label: t('General_FinalEnergy'),
      subtitle: showTotal
        ? translateUnitEnum_dynamic(Units.energyPerYear, t)
        : translateUnitEnum_dynamic(Units.energyPerAreaYear, t),
      minWidth: 120,
    },
    { id: 'efficiency_class', label: t('General_EfficiencyClass'), minWidth: 160 },
    { id: 'eu_taxonomy', label: t('General_EUTaxonomyIndex'), minWidth: 180 },
    { id: 'actions', label: '', sortingDisabled: true },
  ];

  return (
    <Card sx={{ pt: 3 }}>
      <EsgAnalysisBuildingListToolbar filterName={filterName} onFilterName={handleFilterByName} />
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          {/*@ts-ignore*/}
          {selected.length > 0 && !buildingIds.length && (
            <TableSelectedActions
              selectedText={t('General_BuildingSelected', { count: selected.length })}
              numSelected={selected.length}
              rowCount={filteredRows.length}
              onSelectAllRows={handleSelectAllClick}
              action={
                <>
                  {selected.length > 0 && (
                    <Button
                      variant="text"
                      onClick={() => {
                        setPage(0);
                        replaceFilter(EsgAnalysisFiltersKeys.buildingIds, selected);
                      }}
                      endIcon={<Iconify icon={'material-symbols:add-box-outline'} />}
                    >
                      {t('EsgAnalysisBuildingListTab_LimitAnalysisTo')}
                    </Button>
                  )}
                </>
              }
              sx={{ height: 90 }}
            />
          )}
          <Table>
            <EsgAnalysisBuildingListHead
              order={order}
              orderBy={orderBy}
              headLabel={tableHead}
              rowCount={filteredRows.length}
              numSelected={selected.length}
              onRequestSort={handleRequestSort}
              onSelectAllClick={handleSelectAllClick}
              //@ts-ignore
              buildingIds={buildingIds}
              isNotFound={isNotFound}
            />
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell
                    align="center"
                    colSpan={9}
                    sx={{ textAlign: 'center', verticalAlign: 'middle', height: 100 }}
                  >
                    <DelayedLoading delay={0} />
                  </TableCell>
                </TableRow>
              ) : (
                <>
                  {filteredRows.map((row: BuildingDetailsFragment) => {
                    const {
                      id: buildingId,
                      street,
                      city,
                      postal_code,
                      efficiency_class_id,
                      energy_primary,
                      energy_primary_total,
                      energy_final,
                      energy_final_total,
                      co2_emissions,
                      co2_emissions_total,
                      eu_taxonomy_compliance_id,
                      country_id,
                      co2_costs,
                      co2_costs_total,
                      types_of_use,
                    } = row;
                    const typesOfUse = types_of_use;
                    const id = ensureDefined(buildingId);
                    const isItemSelected = id ? selected.includes(id) : false;
                    const compliance = eu_taxonomy_compliance_id;

                    const primaryEnergyToBeSaved =
                      !!energy_primary && compliance && country_id && typesOfUse[0]
                        ? calculateEUTaxonomyToBeSaved(
                            energy_primary,
                            compliance as eu_taxonomy_compliance_enum,
                            country_id as country_enum,
                            // Fix ME in multiple NUF
                            typesOfUse[0],
                          )
                        : 0;

                    let primaryEnergy, finalEnergy, co2Emissions, co2Costs;

                    // The types are inferred as null, but we know that they are not null
                    const getValue = (value: number | undefined | null, totalValue: number | undefined | null) =>
                      localize.formatAsCompact(showTotal ? totalValue : value);

                    primaryEnergy = getValue(energy_primary, energy_primary_total);
                    finalEnergy = getValue(energy_final, energy_final_total);
                    co2Emissions = getValue(co2_emissions, co2_emissions_total);
                    co2Costs = getValue(co2_costs, co2_costs_total);

                    return (
                      <TableRow
                        hover
                        key={id}
                        role="checkbox"
                        //@ts-ignore
                        selected={!filters.buildingIds.length && isItemSelected}
                        sx={{ cursor: 'pointer' }}
                      >
                        {/*@ts-ignore*/}
                        {!filters.buildingIds.length && (
                          <TableCell padding="checkbox">
                            <Checkbox checked={isItemSelected} onClick={() => handleCheckboxClick(id)} />
                          </TableCell>
                        )}
                        <TableCellWithSubtitle
                          onClick={() => handleAssetClick(id)}
                          title={
                            <OverflowText
                              text={street ? highlightText(street, filterName) : ''}
                              maxWidth={'200px'}
                              color={'text.primary'}
                              variant="body2"
                              TooltipProps={{
                                arrow: true,
                                placement: 'top-start',
                              }}
                            />
                          }
                          subtitle={
                            <OverflowText
                              text={postal_code && city ? highlightText(`${postal_code} ${city}`, filterName) : ''}
                              maxWidth="250px"
                              color={'text.secondary'}
                              variant="body2"
                              TooltipProps={{
                                arrow: true,
                                placement: 'top-start',
                              }}
                            />
                          }
                        />
                        <TableCell onClick={() => handleAssetClick(id)}>
                          {typesOfUse
                            .map((type: type_of_use_enum) => translateTypeOfUseEnum_dynamic(type, t))
                            .join(', ')}
                        </TableCell>
                        <TableCell onClick={() => handleAssetClick(id)}>{co2Emissions}</TableCell>
                        <TableCell onClick={() => handleAssetClick(id)}>{co2Costs}</TableCell>
                        <TableCell onClick={() => handleAssetClick(id)}>{primaryEnergy}</TableCell>
                        <TableCell onClick={() => handleAssetClick(id)}>{finalEnergy}</TableCell>
                        <TableCell onClick={() => handleAssetClick(id)} align="center">
                          {/*@ts-ignore */}
                          <EfficiencyClassLabel efficiencyClass={efficiency_class_id} />
                        </TableCell>
                        <TableCell>
                          {/*@ts-ignore */}
                          <EuTaxonomyTableCell compliance={compliance} toBeSaved={primaryEnergyToBeSaved} />
                        </TableCell>
                        <TableCell>
                          <TableMoreMenu
                            actions={
                              <MenuItem
                                onClick={() =>
                                  city && street && postal_code
                                    ? setBuildingToExportAsPdf({
                                        id,
                                        city,
                                        street,
                                        postalCode: postal_code,
                                      })
                                    : null
                                }
                              >
                                <Iconify icon={ICONS.PDF_EXPORT} />
                                {t('EsgAnalysis_ExportDialogOpen-ListMenuItem')}
                              </MenuItem>
                            }
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </>
              )}
              {filterName.length === 0 && filteredRows.length === 0 && !loading && (
                <TableRow>
                  <TableCell
                    align="center"
                    colSpan={9}
                    sx={{ textAlign: 'center', verticalAlign: 'middle', height: 400 }}
                  >
                    {t('EsgAnalysisBuildingListTab_NoBuildingsFound')}
                  </TableCell>
                </TableRow>
              )}
              {isNotFound && <SearchNotFoundRow searchQuery={filterName} colSpan={9} />}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>

      <TablePaginationStandard
        count={totalBuildingsCount}
        rowsPerPage={rowsPerPage}
        page={page}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
      />
      {/*@ts-ignore*/}
      <EsgPdfExport building={buildingToExportAsPdf} handleClose={() => setBuildingToExportAsPdf(null)} />
    </Card>
  );
}

type TableCellWithSubtitleProps = {
  title: ReactNode;
  subtitle: ReactNode;
  onClick: () => void;
};

function TableCellWithSubtitle({ title, subtitle, onClick }: TableCellWithSubtitleProps) {
  return (
    <TableCell onClick={onClick}>
      <Stack spacing={0.5} sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography>{title}</Typography>
        <Typography color="text.secondary" variant="body2">
          {subtitle}
        </Typography>
      </Stack>
    </TableCell>
  );
}

function EuTaxonomyTableCell({
  compliance,
  toBeSaved,
}: {
  compliance: eu_taxonomy_compliance_enum;
  toBeSaved: number;
}) {
  const { t } = useTranslation();

  const text = {
    COMPLIANT: t('ESGAnalysis_EUTaxonomyCompliance-compliant'),
    NON_COMPLIANT: t('ESGAnalysis_EUTaxonomyCompliance-nonCompliant'),
    MEETS_DNSH_CRITERIA: t('ESGAnalysis_EUTaxonomyCompliance-partiallyCompliant'),
    UNKNOWN: t('ESGAnalysis_EUTaxonomyCompliance-unknown'),
  };

  return (
    <EUTaxonomyTooltip compliance={compliance} toBeSaved={toBeSaved}>
      <Stack direction="row" justifyContent="center" alignItems="center" gap={0.5}>
        <Iconify
          icon={euTaxonomyColors.icon[compliance]}
          width={20}
          height={20}
          color={euTaxonomyColors.iconColor[compliance]}
        />
        <Typography
          color={euTaxonomyColors.complianceColor[compliance]}
          variant="subtitle2"
          sx={{ textDecoration: 'underline dotted', textUnderlinePosition: 'under' }}
        >
          {text[compliance]}
        </Typography>
      </Stack>
    </EUTaxonomyTooltip>
  );
}
