/* eslint-disable @typescript-eslint/ban-ts-comment */
import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/material';
import { energy_system_type_enum } from '@predium/client-graphql';
import { forwardRef, useImperativeHandle } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormProvider } from '../../../../../../../components/hook-form';
import InfoTable from '../../../../../../../components/presentations/InfoTable';
import { BuildingTabEnum } from '../../../../../../../pages/DataCollection/DataCollectionBuilding';
import { useLanguage } from '../../../../../../../provider/LanguageProvider';
import { createSystemInfoTable } from '../../../../../Scenario/scenarios.util';
import { getMinDate } from '../../../../ActionPlan.utils';
import { NoCostAvailableWithLink } from '../../../../Components/NoCostAvailableWithLink';
import { HeatingHotWaterActionFormSchema } from '../../../CreateActions/validation-schema';
import { TechnicalActionEditProps } from '../../TechnicalAction';
import { HeatingHotWaterDefaultType } from './HeatingAction';
import TechnicalCommonFields from './TechnicalCommonFields';

const HotWaterAction = forwardRef(
  (
    {
      previousActionBuildingModel,
      resetSimulatedData,
      loading,
      parameters,
      affectedParts,
      implementationTo,
      isRecommendedAction,
      implementationToComponent,
    }: TechnicalActionEditProps & {
      parameters: HeatingHotWaterDefaultType['system_renovation_parameter'];
    },
    ref,
  ) => {
    const { language } = useLanguage();

    const { t } = useTranslation();
    const currentActionType = energy_system_type_enum.HOT_WATER;

    const defaultValues: HeatingHotWaterDefaultType = {
      system_renovation_parameter: parameters,
      implementation_to: implementationTo,
      affected_parts: affectedParts,
    };

    const buildingId = previousActionBuildingModel.building!.id;
    const minimumDate = isRecommendedAction ? implementationTo : getMinDate();

    const formMethods = useForm<HeatingHotWaterDefaultType>({
      mode: 'onChange',
      reValidateMode: 'onChange',
      defaultValues: defaultValues,
      resolver: yupResolver(HeatingHotWaterActionFormSchema(minimumDate, language, isRecommendedAction)),
    });

    useImperativeHandle(ref, () => ({
      methods: formMethods,
    }));

    const infoTableData = createSystemInfoTable(currentActionType, previousActionBuildingModel, t);

    return (
      <FormProvider methods={formMethods}>
        <Box my={3}>
          {infoTableData && (
            <Box mb={3}>
              <InfoTable
                buildingId={buildingId}
                showTab={BuildingTabEnum.technology}
                data={infoTableData}
                title={t('General_StateBeforeRenovation')}
              />
            </Box>
          )}

          <TechnicalCommonFields
            selectedActionType={currentActionType}
            loading={loading}
            resetSimulatedData={resetSimulatedData}
            noCostAvailableMessage={<NoCostAvailableWithLink />}
            previousActionBuildingModel={previousActionBuildingModel}
            affectedParts={affectedParts}
            implementationToComponent={implementationToComponent}
          />
        </Box>
      </FormProvider>
    );
  },
);

export default HotWaterAction;
