import { Box, Tooltip, TooltipProps, Typography, TypographyProps, TypographyVariant } from '@mui/material';
import { ReactNode, useEffect, useRef, useState } from 'react';

type Props = {
  text: ReactNode;
  /**
   * The maximum width of the container after which ellipsis and tooltip should be shown.
   */
  maxWidth: string | number;
  variant?: TypographyVariant;
  color?: string;
  /**
   * Props to pass to the Tooltip component. Children and title will be overwritten by this component.
   */
  TooltipProps?: Omit<TooltipProps, 'children' | 'title'>;
  TypographyProps?: TypographyProps;
  showTooltip?: boolean;
};

/**
 * Component that shows a text with ellipsis and tooltip if the text is overflowing.
 * @returns A `Typography` component with ellipsis and and a `Tooltip`component if the text is overflowing.
 */
const OverflowText = ({
  text,
  maxWidth,
  variant = 'body2',
  color = 'text.primary',
  TooltipProps,
  TypographyProps,
  showTooltip = true,
}: Props) => {
  const textRef = useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    const textElement = textRef.current;
    if (textElement && showTooltip) {
      setIsOverflowing(textElement.scrollWidth > textElement.clientWidth);
    }
  }, [text, showTooltip]);

  const typography = (
    <Typography component={'span'} variant={variant} sx={{ color: color, ...TypographyProps?.sx }} {...TypographyProps}>
      {text}
    </Typography>
  );

  return (
    <Tooltip placement="right-start" {...TooltipProps} title={isOverflowing ? text : ''}>
      <Box
        ref={textRef}
        sx={{
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          maxWidth: maxWidth,
        }}
      >
        {typography}
      </Box>
    </Tooltip>
  );
};

export default OverflowText;
