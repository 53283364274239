/* eslint-disable @typescript-eslint/ban-ts-comment */
import { energy_system_type_enum } from '@predium/enums';
import { translateEnergyDistributionTypeEnum } from '@predium/i18n/client';
import { COMMON_DATE_FORMATS, formatDateToLocale, Units } from '@predium/utils';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TechnologyIcons } from '../../../../../../../../assets/images';
import { useLanguage } from '../../../../../../../../provider/LanguageProvider';
import { HYPHEN } from '../../../../../../../../utils/constants';
import { SystemRoute } from '../../../../../BuildingTechnology';
import { generateSystemRouteFieldNames } from '../../../../../Common/building.util';
import useBuilding from '../../../../../Context/useBuilding';
import { TechnologyUnitSubTitle } from '../../TechnologyConsumerRoute';
import TechnologyUnitTable, { TableData } from '../../TechnologyUnitTable';
import EmptyTechnologyUnitCard from '../EmptyTechnologyUnit';
import TechnologyUnitCard from '../TechnologyUnit';
import DistributionAddModal from './DistributionAddModal';
import DistributionEditModal from './DistributionEditModal';

type Props = {
  energySystemRoute: SystemRoute;
  routeIndex: number;
  systemIndex: number;
};

const DistributionCard: React.FC<Props> = ({ energySystemRoute, routeIndex, systemIndex }) => {
  const { t } = useTranslation();
  const { hasEditAccess } = useBuilding();
  const { language, localize } = useLanguage();

  const [editDistributionModalOpen, setEditDistributionModalOpen] = useState<boolean>(false);
  const [addDistributionModalOpen, setAddDistributionModalOpen] = useState<boolean>(false);

  const {
    energyDistributionType,
    distributionHeatLoss,
    transferHeatLoss,
    lastHydraulicBalancing,
    lastBalancingSetToNever,
    energyDistributionConstructionYear,
    distributionId,
    energySystemType,
  } = energySystemRoute;

  const { getFieldState } = useFormContext();

  if (!energySystemType) return null;

  const isHeatingSystem = energySystemType === energy_system_type_enum.HEATING;
  const {
    distributionHeatLossFieldname,
    transferHeatLossFieldname,
    lastHydraulicBalancingFieldname,
    lastBalancingSetToNeverFieldname,
    energyDistributionConstructionYearFieldname,
    energyDistributionTypeFieldname,
    distributionDeletedFieldname,
  } = generateSystemRouteFieldNames(energySystemType, systemIndex, routeIndex);

  const isDistributionTypeDirty = getFieldState(energyDistributionTypeFieldname).isDirty;
  const isDistributionConstructionYearDirty = getFieldState(energyDistributionConstructionYearFieldname).isDirty;
  const isDistributionHeatLossDirty = getFieldState(distributionHeatLossFieldname).isDirty;
  const isTransferHeatLossDirty = getFieldState(transferHeatLossFieldname).isDirty;
  const isLastHydraulicBalancingDirty =
    getFieldState(lastHydraulicBalancingFieldname).isDirty || getFieldState(lastBalancingSetToNeverFieldname).isDirty;
  const isDistributionDeletedDirty = getFieldState(distributionDeletedFieldname).isDirty;

  const lastHydraulicBalancingString = lastHydraulicBalancing
    ? formatDateToLocale(lastHydraulicBalancing, COMMON_DATE_FORMATS.DAY_MONTH_YEAR, language)
    : lastBalancingSetToNever
    ? t('General_Never')
    : t('General_UnspecifiedShort');

  const distributionTableData: TableData[] = [
    {
      label: t('General_YearOfInstallation'),
      value: energyDistributionConstructionYear ?? HYPHEN,
      isDirty: isDistributionConstructionYearDirty,
      tooltipProps: {
        labels: {
          title: t('General_YearOfInstallation'),
          body: t('Education_YearOfInstallationBody'),
        },
      },
    },
    ...(isHeatingSystem
      ? [
          {
            label: t('DataCollection_Technology_LastHydraulicBalancingTitleShort'),
            value: lastHydraulicBalancingString,
            isDirty: isLastHydraulicBalancingDirty,
            tooltipProps: {
              labels: {
                title: t('DataCollection_Technology_LastHydraulicBalancingTitle'),
                body: t('Education_LastHydraulicBalancingBody'),
              },
            },
          } as TableData,
        ]
      : []),
    {
      label: t('DataCollection_Technology_DistributionLossTitle'),
      value: localize.formatAsFloat(distributionHeatLoss, { unit: Units.energyPerAreaYear }),
      isDirty: isDistributionHeatLossDirty,
      tooltipProps: {
        labels: {
          title: t('DataCollection_Technology_DistributionLossTitle'),
          body: t('Education_DistributionLossBody'),
        },
      },
    },
    ...(isHeatingSystem
      ? [
          {
            label: t('DataCollection_Technology_TransferLossTitle'),
            value: localize.formatAsFloat(transferHeatLoss, { unit: Units.energyPerAreaYear }),
            isDirty: isTransferHeatLossDirty,
            tooltipProps: {
              labels: {
                title: t('DataCollection_Technology_TransferLossTitle'),
                body: t('Education_TransferLossBody'),
              },
            },
          },
        ]
      : []),
  ];

  const title = t('DataCollection_Technology_DistributionTitle');
  const icon = TechnologyIcons.distribution;

  return (
    <>
      {distributionId ? (
        <TechnologyUnitCard
          icon={icon}
          title={title}
          subtitle={
            <TechnologyUnitSubTitle
              subtitle={energyDistributionType ? translateEnergyDistributionTypeEnum(energyDistributionType) : '–'}
              isDirty={isDistributionTypeDirty}
              isDeleted={isDistributionDeletedDirty}
            />
          }
          showArrow={false}
          body={<TechnologyUnitTable data={distributionTableData} isDeleted={isDistributionDeletedDirty} />}
          onSemanticButtonClick={() => {
            hasEditAccess && setEditDistributionModalOpen(true);
          }}
          isDeleted={isDistributionDeletedDirty}
        />
      ) : (
        <EmptyTechnologyUnitCard
          icon={icon}
          title={title}
          showArrow={false}
          onButtonClick={() => {
            hasEditAccess && setAddDistributionModalOpen(true);
          }}
          noDataText={t('DataCollection_Technology_DistributionNotAvailable')}
        />
      )}

      <DistributionAddModal
        open={addDistributionModalOpen}
        onClose={() => setAddDistributionModalOpen(false)}
        energySystemRoute={energySystemRoute}
        index={routeIndex}
        systemIndex={systemIndex}
        isHeatingSystem={isHeatingSystem}
      />

      <DistributionEditModal
        open={editDistributionModalOpen}
        onClose={() => setEditDistributionModalOpen(false)}
        energySystemRoute={energySystemRoute}
        systemIndex={systemIndex}
        isHeatingSystem={isHeatingSystem}
      />
    </>
  );
};

export default DistributionCard;
