import { alpha, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Label from '../../../components/Label';
import { EmissionCertificateStatusType } from './EmissionCertificatesTabs';

export default function StatusBadge({ status }: { status: EmissionCertificateStatusType }) {
  const { t } = useTranslation();
  const theme = useTheme();

  let textColor: string, backgroundColor: string;

  switch (status) {
    case 'active':
      textColor = theme.palette.success.dark;
      backgroundColor = alpha(theme.palette.success.main, 0.16);
      break;
    case 'in_review':
      textColor = theme.palette.info.dark;
      backgroundColor = theme.palette.info.lighter;
      break;
    case 'expired':
      textColor = theme.palette.error.dark;
      backgroundColor = alpha(theme.palette.error.main, 0.16);
      break;
    default:
      textColor = theme.palette.grey[200];
      backgroundColor = theme.palette.grey[900];
  }

  const getDisplayValueFromStatus = (status: EmissionCertificateStatusType) => {
    switch (status) {
      case 'active':
        return t('General_Valid');
      case 'expired':
        return t('General_Expired');
      case 'in_review':
        return t('General_InReview');
      default:
        return '';
    }
  };

  return (
    <Label
      sx={{
        color: textColor,
        backgroundColor,
      }}
    >
      {getDisplayValueFromStatus(status)}
    </Label>
  );
}
