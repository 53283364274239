import { useCallback, useMemo } from 'react';
// eslint-disable-next-line no-restricted-imports
import { NavigateOptions, useSearchParams } from 'react-router-dom';
import { type Merge } from 'type-fest';
import { convertFromSearchParams, convertToSearchParams, InferParamsConfigType, ParamsConfig } from './utils';

/**
 * This hook offers type-safe access to a routes search params if defined in the PATHS object.
 * Pass the accessor function for the path as a generic parameter and access the search params object directly.
 *
 * @example
 * const { searchParams, setSearchParams } = useTypeSafeSearchParams(SEARCH_PARAMS.esgAnalysis, { ids: [1, 2, 3] });
 */
export function useTypeSafeSearchParams<
  TParamsConfig extends ParamsConfig,
  DefaultValue extends InferParamsConfigType<TParamsConfig>,
>(paramsConfig: TParamsConfig, defaultValues?: DefaultValue) {
  type AllSearchParams = InferParamsConfigType<TParamsConfig>;

  const [searchParams, setSearchParams] = useSearchParams();

  const searchParamObject = useMemo(
    () => ({ ...defaultValues, ...convertFromSearchParams(paramsConfig, searchParams) }),
    [defaultValues, searchParams, paramsConfig],
  );

  const setSearchParamsWithObject = useCallback(
    (params: AllSearchParams, config?: NavigateOptions) => {
      setSearchParams(convertToSearchParams(paramsConfig, params), config);
    },
    [paramsConfig, setSearchParams],
  );

  return {
    searchParams: searchParamObject as Merge<AllSearchParams, DefaultValue>,
    setSearchParams: setSearchParamsWithObject,
  };
}
