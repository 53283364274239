/* eslint-disable @typescript-eslint/ban-ts-comment */
import { BuildingModelFragment, EnvelopeUnitFragment } from '@predium/client-graphql';
import {
  energy_consumer_type_enum,
  energy_source_type_enum,
  energy_system_type_enum,
  envelope_type_enum,
} from '@predium/enums';
import {
  translateEnergyConsumerTechnologyTypeEnum_dynamic,
  translateEnergySourceTypeEnum_dynamic,
} from '@predium/i18n/client';
import { Localize, Units } from '@predium/utils';
import { TFunction } from 'i18next';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { InfoTableData } from 'src/components/presentations/InfoTable';
import { ConsumerPartialRenovationOption } from '../ActionPlan/Actions/CreateAction';

const generateEnvelopeTableData = (envelopes: EnvelopeUnitFragment[], envelopeType: envelope_type_enum | null) => {
  if (envelopeType === null) {
    return;
  }
  const filteredEnvelopes = envelopes.filter((envelope) => envelope.envelope_type_id === envelopeType);

  const totalItems: number = filteredEnvelopes.length;
  //make sure we have one decimal
  const totalArea: number = parseFloat(
    filteredEnvelopes.reduce((total, envelope) => total + envelope.area, 0).toFixed(1),
  );

  const insulationMaterialsArray = filteredEnvelopes.map((envelope) => envelope.insulation_material_name);
  let insulationMaterials: string = '';
  if (insulationMaterialsArray.length > 0) {
    const uniqueInsulationMaterials = uniq(insulationMaterialsArray);
    insulationMaterials = uniqueInsulationMaterials.join(', ');
  }

  let averageValue: number;
  if (envelopeType === envelope_type_enum.WINDOW || envelopeType === envelope_type_enum.DOOR) {
    //@ts-ignore
    const totalUValue = filteredEnvelopes.reduce((total, envelope) => total + envelope.u_value, 0);
    averageValue = parseFloat((totalUValue / totalItems).toFixed(2));
  } else {
    //@ts-ignore
    const totalThickness = filteredEnvelopes.reduce((total, envelope) => total + envelope.insulation_thickness, 0);
    averageValue = parseFloat((totalThickness / totalItems).toFixed(2)) * 100;
  }

  return {
    totalItems,
    totalArea,
    insulationMaterials,
    averageValue,
  };
};

type InfoTableLabel = {
  existingLabel: string;
  typeLabel: string;
  areaLabel: string;
};

const getLabelsForEnvelopeType = (
  envelope_type: envelope_type_enum,
  total: number,
  t: TFunction<'translation', undefined>,
): InfoTableLabel => {
  const areaLabel = total === 1 ? t('General_Area') : t('General_TotalArea');
  switch (envelope_type) {
    case envelope_type_enum.WINDOW:
      return {
        existingLabel: t('General_Window', { count: total }),
        typeLabel: t('General_WindowType'),
        areaLabel,
      };
    case envelope_type_enum.DOOR:
      return {
        existingLabel: t('General_Door', { count: total }),
        typeLabel: t('General_DoorType'),
        areaLabel,
      };
    case envelope_type_enum.FLAT_ROOF:
      return {
        existingLabel: t('General_RoofPart', { count: total }),
        typeLabel: t('General_InsulationMaterial'),
        areaLabel,
      };
    case envelope_type_enum.PITCHED_ROOF:
      return {
        existingLabel: t('General_RoofPart', { count: total }),
        typeLabel: t('General_InsulationMaterial'),
        areaLabel,
      };
    case envelope_type_enum.FLOOR:
      return {
        existingLabel: t('General_Floor', { count: total }),
        typeLabel: t('General_InsulationMaterial'),
        areaLabel,
      };
    case envelope_type_enum.BASEMENT_CEILING:
      return {
        existingLabel: t('General_BasementCeiling', { count: total }),
        typeLabel: t('General_InsulationMaterial'),
        areaLabel,
      };
    case envelope_type_enum.WALL:
      return {
        existingLabel: t('General_ExteriorWall', { count: total }),
        typeLabel: t('General_InsulationMaterial'),
        areaLabel,
      };
    case envelope_type_enum.TOP_FLOOR_CEILING:
      return {
        existingLabel: t('General_TopFloorCeiling', { count: total }),
        typeLabel: t('General_InsulationMaterial'),
        areaLabel,
      };
    default:
      const exhaustiveCheck: never = envelope_type;
      throw new Error(`Unhandled envelope_type: ${exhaustiveCheck}`);
  }
};

export const createEnvelopeInfoTable = (
  actionType: envelope_type_enum | '',
  envelopes: EnvelopeUnitFragment[],
  t: TFunction<'translation', undefined>,
  localize: Localize,
): InfoTableData[] | null => {
  if (actionType === '') {
    return null;
  }
  const tableData = generateEnvelopeTableData(envelopes, actionType);

  if (tableData) {
    const { totalItems, insulationMaterials, totalArea, averageValue } = tableData;
    const { existingLabel, typeLabel, areaLabel } = getLabelsForEnvelopeType(actionType, totalItems, t);
    const windowDoorAction = actionType === envelope_type_enum.WINDOW || actionType === envelope_type_enum.DOOR;
    const value = localize.formatAsFloat(averageValue > 0 ? averageValue : null, {
      unit: windowDoorAction ? Units.uValue : Units.centimeters,
    });

    let avgValueLabel = '';
    if (windowDoorAction) {
      avgValueLabel = totalItems === 1 ? t('General_UValue') : t('General_AverageUValue');
    } else {
      avgValueLabel = totalItems === 1 ? t('General_InsulationThickness') : t('General_AverageInsulationThickness');
    }

    if (windowDoorAction) {
      return [
        {
          label: t('ScenariosUtil_AffectedParts'),
          value: `${totalItems} ${existingLabel}`,
        },
        {
          label: typeLabel,
          value: insulationMaterials.length > 0 ? insulationMaterials : '-',
        },
        {
          label: areaLabel,
          value: localize.formatAsFloat(totalArea, { unit: Units.area }),
        },
        {
          label: avgValueLabel,
          value,
        },
      ];
    } else {
      return [
        {
          label: t('ScenariosUtil_AffectedParts'),
          value: `${totalItems} ${existingLabel}`,
        },
        {
          label: areaLabel,
          value: localize.formatAsFloat(totalArea, { unit: Units.area }),
        },
        {
          label: typeLabel,
          value: insulationMaterials.length > 0 ? insulationMaterials : '-',
        },
        {
          label: avgValueLabel,
          value,
        },
      ];
    }
  } else {
    return null;
  }
};

export const createSystemInfoTable = (
  actionType: energy_consumer_type_enum | energy_system_type_enum,
  baseBuildingModal: BuildingModelFragment,
  t: TFunction<'translation', undefined>,
): InfoTableData[] => {
  const consumerTechnologies = sortBy(
    uniqBy(
      baseBuildingModal.energy_systems.flatMap((energySystem) =>
        energySystem.energy_system_consumer_routes
          .filter((route) => route.energy_consumer.energy_consumer_type_id === actionType)
          .map((route) => route.energy_consumer),
      ),
      (c) => c.id,
    ),
    // TODO use consumer secondary ids instead of route secondary ids
    (c) => c.energy_system_consumer_routes[0].secondary_id,
  ).map((consumer) => consumer.energy_consumer_technology_type_id);

  const totalConsumerTechnologies = consumerTechnologies.length;
  let tableData: InfoTableData[] = [];
  if (totalConsumerTechnologies > 0) {
    const consumerLabel = t('General_Consumer-WithPreCount', { count: totalConsumerTechnologies });

    tableData = [
      {
        label: t('ScenariosUtil_ExistingConsumers'),
        value: consumerLabel,
      },
    ];

    consumerTechnologies.forEach((tech, index) => {
      if (tech) {
        tableData.push({
          label: t('General_Consumer-WithIndex', { index: index + 1 }),
          value: translateEnergyConsumerTechnologyTypeEnum_dynamic(tech, t, 1),
        });
      }
    });

    return tableData;
  }
  return [];
};

export const createSolarInfoTable = (
  totalArea: number,
  baseBuildingModal: BuildingModelFragment,
  t: TFunction<'translation', undefined>,
  localize: Localize,
): InfoTableData[] => {
  const solarRoutes = baseBuildingModal.energy_systems.flatMap((energySystem) =>
    energySystem.energy_system_producer_routes.filter(
      (route) => route.energy_source_type_id === energy_source_type_enum.SOLAR,
    ),
  );

  const solarData = solarRoutes
    .map((route) => route.energy_producer)

    //@ts-ignore
    .reduce((total, producer) => total + producer.nominal_power, 0);

  const totalSolarSystems = solarRoutes.length;
  const consumerLabel =
    totalSolarSystems === 0
      ? t('General_Consumer-WithPreCount-zero')
      : t('General_Consumer-WithPreCount', { count: totalSolarSystems });

  return [
    {
      label: t('ScenariosUtil_ExistingConsumers'),
      value: consumerLabel,
    },
    {
      label: t('ScenariosUtil_TotalRoofArea'),
      value: localize.formatAsInteger(totalArea, { unit: Units.area }),
    },
    // {
    //   label: 'Solaraktive Fläche',
    //   value: '130 m2',
    // },
    {
      label: t('ScenariosUtil_TotalModulPower'),
      value: localize.formatAsFloat(solarData, { unit: Units.solarModulePower }),
    },
  ];
};

export const createEnergyInfoTable = (
  selectedType: energy_source_type_enum | '',
  baseBuildingModel: BuildingModelFragment,
  t: TFunction<'translation', undefined>,
  localize: Localize,
): InfoTableData[] | null => {
  if (selectedType === '') {
    return null;
  }

  let totalLabel: string = '';
  let portionLabel: string = '';
  let existingSources: energy_source_type_enum[];

  switch (selectedType) {
    case energy_source_type_enum.ELECTRICITY_GREEN:
      totalLabel = t('ScenariosUtil_FinalEnergyElectricity');
      portionLabel = t('ScenariosUtil_GreenElectricityShare');
      existingSources = [energy_source_type_enum.ELECTRICITY_MIX];
      break;
    case energy_source_type_enum.BIO_GAS:
      existingSources = [energy_source_type_enum.NATURAL_GAS];
      totalLabel = t('ScenariosUtil_FinalEnergyNaturalGas');
      portionLabel = t('ScenariosUtil_BioGasShare');
      break;
    case energy_source_type_enum.DISTRICT_HEATING_CHP_RENEWABLE:
    case energy_source_type_enum.DISTRICT_HEATING_PLANTS_RENEWABLE:
      existingSources = [
        energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_COAL,
        energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_GAS,
        energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_COAL,
        energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_GAS,
      ];
      totalLabel = t('ScenariosUtil_DistrictHeating');
      portionLabel = t('ScenariosUtil_RegenerativeDistrictHeatingShare');
      break;
  }

  const existingSourceEnergy = baseBuildingModel.energy_systems
    .flatMap((energySystem) =>
      energySystem.energy_system_consumer_routes.filter((route) =>
        existingSources.includes(route.energy_source_type_id),
      ),
    )
    .reduce((total, route) => total + route.energy_final, 0);

  const changedSourceEnergy = baseBuildingModel.energy_systems
    .flatMap((energySystem) =>
      energySystem.energy_system_consumer_routes.filter((route) => route.energy_source_type_id === selectedType),
    )
    .reduce((total, route) => total + route.energy_final, 0);

  const totalEnergy = existingSourceEnergy + changedSourceEnergy;
  const changedEnergyPortion = Math.round((changedSourceEnergy / totalEnergy) * 100);

  return [
    {
      label: totalLabel,
      value: localize.formatAsCompact(totalEnergy, { unit: Units.energyPerAreaYear }),
    },
    {
      label: portionLabel,
      value: localize.formatAsPercentage(changedEnergyPortion),
    },
  ];
};

export const createHydraulicBalancingInfoTable = (
  sumOfDistributionTransferLosses: number,
  setToNever: boolean,
  t: TFunction<'translation', undefined>,
  localize: Localize,
) => {
  return [
    {
      label: t('DataCollection_Technology_LastHydraulicBalancingTitle'),
      value: setToNever ? t('General_Never') : t('General_UnspecifiedShort'),
    },
    {
      label: t('DataCollection_Technology_TransferLossTitle'),
      value: localize.formatAsInteger(sumOfDistributionTransferLosses, {
        unit: Units.energyPerAreaYear,
      }),
    },
  ];
};

export function getEnergySourceNameForPartialRenovations(
  part: ConsumerPartialRenovationOption,
  t: TFunction<'translation', undefined>,
) {
  const routes = part.energy_system_consumer_routes;

  // for electricity routes, we don't want to show the electricity type, but just "Electricity" in general
  const energySourceType = routes[0].energy_source_type_id;
  if (
    energySourceType === energy_source_type_enum.ELECTRICITY_GREEN ||
    energySourceType === energy_source_type_enum.ELECTRICITY_MIX ||
    energySourceType === energy_source_type_enum.SOLAR
  ) {
    return t('Enum_ConsumptionTypeEnum-ELECTRICITY');
  }
  return translateEnergySourceTypeEnum_dynamic(routes[0].energy_source_type_id, t);
}
