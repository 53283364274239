import { Card, CardProps } from '@mui/material';
import { pathValuesFromYear } from '@predium/client-lookup';
import { translateUnitEnum_dynamic } from '@predium/i18n/client';
import { getFilename, Localize, Units } from '@predium/utils';
import { TFunction } from 'i18next';
import isEqual from 'lodash/isEqual';
import merge from 'lodash/merge';
import { memo } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../../../provider/LanguageProvider';
import BaseOptionChart from '../../../theme/apexcharts';
import addCustomCSVExportButtonToChartToolbar from '../../../utils/addCustomCSVExportButtonToChartToolbar';
import { toCsvContent } from '../../../utils/toCsvContent';

const getCsvContent = (
  costs: [number, number][],
  title: string,
  t: TFunction<'translation', undefined>,
  localize: Localize,
) => {
  const csvRows = [
    [title, ''],
    [t('General_Year'), t('General_EnergyCost')],
    ...costs.map((cost) => [cost[0], localize.formatAsCurrency(cost[1])]),
  ];

  return toCsvContent(csvRows);
};

interface Props extends CardProps {
  energyCosts: number[];
  energyCostsTotal: number[];
  showTotal: boolean;
  animated?: boolean;
}

function EnergieCostDevelopmentGraph({ energyCosts, energyCostsTotal, showTotal, animated, sx }: Props) {
  const { t } = useTranslation();
  const { language, localize } = useLanguage();
  const currentYear = new Date().getFullYear();
  const energyCostsFromCurrentYear = pathValuesFromYear(showTotal ? energyCostsTotal : energyCosts, currentYear);
  const data: [number, number][] = energyCostsFromCurrentYear.map((costs, idx) => [currentYear + idx, costs]);

  const series = [
    {
      name: t('General_EnergyCost'),
      data,
    },
  ];

  const yAxisTitle = `${t('General_EnergyCost')} ${
    showTotal
      ? `(${translateUnitEnum_dynamic(Units.pricePerYear, t)})`
      : `(${translateUnitEnum_dynamic(Units.pricePerAreaYear, t)})`
  }`;
  const filename = getFilename([t('EnergyCostDevelopmentGraph_Title')], language);

  const chartState: ApexCharts.ApexOptions = merge(BaseOptionChart(), {
    chart: {
      animations: {
        enabled: !!animated,
      },
      type: 'area',
      events: {
        mounted(chart: any) {
          addCustomCSVExportButtonToChartToolbar(chart.el.id, getCsvContent(data, yAxisTitle, t, localize), filename);
        },
        updated(chart: any) {
          addCustomCSVExportButtonToChartToolbar(chart.el.id, getCsvContent(data, yAxisTitle, t, localize), filename);
        },
      },
      toolbar: {
        export: {
          svg: {
            filename,
          },
          png: {
            filename,
          },
        },
      },
    },
    colors: ['#22609B'],
    title: {
      text: t('EnergyCostDevelopmentGraph_Title'),
    },
    yaxis: {
      labels: {
        formatter: (value: number) => localize.formatAsCompact(value, { fractionDigits: showTotal ? 1 : 0 }),
      },
      title: {
        text: yAxisTitle,
      },
    },
    xaxis: {
      tooltip: {
        enabled: false,
      },
      labels: {
        formatter: (value: number, timestamp: number) => (timestamp % 5 === 0 ? value : ''),
      },
    },
    stroke: {
      width: 3,
    },
    tooltip: {
      y: {
        formatter: (value: number) => localize.formatAsCurrency(value, { displayCompact: true }),
      },
    },
  });

  return (
    <Card sx={{ p: 3, pb: 1, ...sx }}>
      <ReactApexChart
        id={'development_of_energy_cost_graph'}
        type={chartState.chart?.type}
        options={chartState}
        series={series}
        height={350}
      />
    </Card>
  );
}

export default memo(EnergieCostDevelopmentGraph, isEqual);
