/* eslint-disable @typescript-eslint/ban-ts-comment */
import { type useSubscription } from '@apollo/client';
import { Badge, Box, Stack, Tab, Tabs } from '@mui/material';
import Container from '@mui/material/Container';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Page from '../../components/Page';
import { UnavailablePageForMissingPortfolios } from '../../components/Unavailable';
import {
  BUILDING_DRAFT_COUNT_SUBSCRIPTION,
  ENERGY_CERTIFICATE_DRAFT_COUNT_SUBSCRIPTION,
} from '../../graphql/DataCollection.queries';
import useDataCollectionTabs from '../../hooks/useDataCollectionTabs';
import usePermissions from '../../hooks/usePermissions';
import { OnFilterCallbackParams } from '../../hooks/useTable';
import DataCollectionBuildingList from '../../sections/DataCollection/Buildings/DataCollectionBuildingList';
import DataCollectionEnergyCertificateDraftsList from '../../sections/DataCollection/Buildings/DataCollectionEnergyCertificateDraftsList';
import { DataCollectionTabActionsEnum, DataCollectionTabEnum } from '../../sections/DataCollection/Buildings/TabsEnums';
import DataCollectionEconomicUnitList from '../../sections/DataCollection/EconomicUnits/EconomicUnitsList';

type Props = {
  useSubscription: typeof useSubscription;
};

type PageTab = {
  label: string;
  value: DataCollectionTabEnum;
  component: ReactNode;
};

type TabFilterType = {
  [key in DataCollectionTabEnum]?: {
    orderBy: string;
    order: 'asc' | 'desc';
  };
};

export default function DataCollectionTabs({ useSubscription }: Props) {
  const { t } = useTranslation();
  const { hasPortfolioWithWriteAccess } = usePermissions();
  const { currentTab, currentAction, isEconomicUnitsToggled, setCurrentTab } = useDataCollectionTabs();
  const [tabsTableFilters, setTabsTableFilters] = useState<TabFilterType>();

  const onFilterChange = ({ orderBy, order }: OnFilterCallbackParams) => {
    if (currentTab)
      setTabsTableFilters({
        ...tabsTableFilters,
        [currentTab]: {
          orderBy,
          order,
        },
      });
  };

  const buildingTabs = [
    isEconomicUnitsToggled
      ? {
          label: t('General_EconomicUnit_other'),
          value: DataCollectionTabEnum.ECONOMIC_UNITS,
          component: (
            <DataCollectionEconomicUnitList
              tabTableFilter={tabsTableFilters?.[DataCollectionTabEnum.ECONOMIC_UNITS]}
              onFilterChange={onFilterChange}
            />
          ),
        }
      : undefined,
    {
      label: t('General_Building'),
      value: DataCollectionTabEnum.BUILDINGS,
      component: (
        <DataCollectionBuildingList
          tabTableFilter={tabsTableFilters?.[DataCollectionTabEnum.BUILDINGS]}
          setCurrentTab={(tabData) => setCurrentTab(tabData.value)}
          onFilterChange={onFilterChange}
        />
      ),
    },
    hasPortfolioWithWriteAccess
      ? {
          label: t('General_Drafts'),
          value: DataCollectionTabEnum.ENERGY_CERTIFICATE_DRAFTS,
          component: (
            <DataCollectionEnergyCertificateDraftsList
              useSubscription={useSubscription}
              tabTableFilter={tabsTableFilters?.[DataCollectionTabEnum.ENERGY_CERTIFICATE_DRAFTS]}
              shouldOpenCreateModal={currentAction === DataCollectionTabActionsEnum.CREATE_ENERGY_CERTIFICATE_DRAFT}
              setCurrentTab={(tabData) => setCurrentTab(tabData.value)}
              onFilterChange={onFilterChange}
            />
          ),
        }
      : undefined,
  ].filter((item) => typeof item !== 'undefined') as PageTab[];

  return (
    <UnavailablePageForMissingPortfolios title={t('General_DataCollection')} heading={t('General_DataCollection')}>
      <Page title={t('General_DataCollection')}>
        <Container maxWidth="lg" sx={{ mb: 4 }}>
          <HeaderBreadcrumbs heading={t('General_DataCollection')} links={[]} />
          <Tabs
            value={currentTab}
            scrollButtons="auto"
            variant="scrollable"
            allowScrollButtonsMobile
            onChange={(_, value) => setCurrentTab(value)}
          >
            {buildingTabs.map((tab) => (
              <Tab
                disableRipple
                key={tab.value}
                label={
                  tab.value === DataCollectionTabEnum.ENERGY_CERTIFICATE_DRAFTS ? (
                    <EnergyCertificateDraftCount useSubscription={useSubscription} label={tab.label} />
                  ) : (
                    tab.label
                  )
                }
                value={tab.value}
              />
            ))}
          </Tabs>
          <Box sx={{ mb: 5 }} />

          {buildingTabs.map((tab) => {
            if (tab) {
              const isMatched = tab.value === currentTab;
              return isMatched ? <Box key={tab.value}>{tab.component}</Box> : null;
            }
            return null;
          })}
        </Container>
      </Page>
    </UnavailablePageForMissingPortfolios>
  );
}

type EnergyCertificateDraftCountProps = {
  label: string;
  useSubscription: typeof useSubscription;
};

function EnergyCertificateDraftCount({ label, useSubscription }: EnergyCertificateDraftCountProps) {
  const subscription = useSubscription(ENERGY_CERTIFICATE_DRAFT_COUNT_SUBSCRIPTION);
  const buildingSubscription = useSubscription(BUILDING_DRAFT_COUNT_SUBSCRIPTION);

  const energyCertificateDraftCount = subscription.data?.energy_certificate_draft_aggregate?.aggregate?.count ?? 0;
  const buildingDraftCount = buildingSubscription.data?.building_draft_aggregate?.aggregate?.count ?? 0;

  const sum = energyCertificateDraftCount + buildingDraftCount;

  return (
    <Stack direction="row" alignItems="center" spacing={0.5}>
      <div>{label}</div>
      {sum > 0 && (
        <Badge
          badgeContent={sum}
          color="error"
          sx={{
            '&, & .MuiBadge-badge': {
              position: 'static',
              transform: 'none',
            },
          }}
        />
      )}
    </Stack>
  );
}
