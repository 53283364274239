import Typography from '@mui/material/Typography';
import { TypographyProps } from '@mui/material/Typography/Typography';
import { PropsWithChildren } from 'react';

export const TypographyEllipsis = ({
  children,
  maxWidth,
  title,
  variant = 'caption',
  color = 'text.secondary',
}: PropsWithChildren<
  {
    maxWidth: number;
  } & TypographyProps
>) => {
  return (
    <Typography
      variant={variant}
      color={color}
      noWrap
      sx={{
        display: 'inline-block',
        verticalAlign: 'middle',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth,
      }}
      title={title}
    >
      {children}
    </Typography>
  );
};
