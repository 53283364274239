import { useMutation } from '@apollo/client';
import { t } from 'i18next';
import { useSnackbar } from 'notistack';
import React from 'react';
import { Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import DeleteConfirmationModal from '../../../../components/DeleteConfirmationModal';
import { SnackbarTimeouts } from '../../../../components/NotistackProvider';
import { DELETE_ACTIONPLAN } from '../../../../graphql/ActionPlanning.mutations';
import { GET_ANALYZED_SCENARIO } from '../../../../graphql/ActionPlanning.queries';
import { PATHS } from '../../../../routes';
import { ActionPlanType } from './ActionPlanActionMenu';

type Props = {
  deleteModalOpen: boolean;
  setDeleteModalOpen: (open: boolean) => void;
  actionPlan: ActionPlanType;
};

const DeleteActionPlanModal: React.FC<Props> = ({ deleteModalOpen, setDeleteModalOpen, actionPlan }) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const totalActions = actionPlan.totalActions;

  const [deleteActionPlanMutation, { loading: loadingDeleteActionPlan }] = useMutation(DELETE_ACTIONPLAN, {
    refetchQueries: [
      {
        query: GET_ANALYZED_SCENARIO,
        variables: {
          scenario_id: actionPlan.scenarioId,
        },
      },
    ],
    onError: () =>
      enqueueSnackbar(t('ActionPlanningActionPlan_DeleteActionPlan-error'), {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
      }),
    update: (cache, { data }) => {
      //@ts-ignore
      if (data.delete_action_plan_by_pk) {
        const normalizedId = cache.identify({
          //@ts-ignore
          id: data.delete_action_plan_by_pk.id,

          //@ts-ignore
          __typename: data.delete_action_plan_by_pk.__typename,
        });
        cache.evict({ id: normalizedId });
        cache.gc();
      }
    },
  });

  const deleteActionPlan = async () => {
    await deleteActionPlanMutation({ variables: { actionPlanId: actionPlan.id } });
    setDeleteModalOpen(false);
    navigate(PATHS.actionPlanning.scenario({ id: actionPlan.scenarioId }));
  };

  return (
    <>
      {/*associatedData is not added as it is integrated in the description in this case*/}
      <DeleteConfirmationModal
        open={deleteModalOpen}
        onClose={() => {
          setDeleteModalOpen(false);
        }}
        onDelete={deleteActionPlan}
        title={t('General_DeleteModalTitle-ActionPlan', { count: 1 })}
        description={
          <Trans
            i18nKey={'General_DeleteModalDescription-ActionPlan'}
            values={{
              name: actionPlan.buildingAddress.street,
              count: 1,
              associatedActions:
                totalActions > 0
                  ? t('General_DeleteModalDescription-ActionPlan-AssociatedActions', {
                      count: totalActions,
                    })
                  : '',
            }}
            components={{ bold: <strong /> }}
          />
        }
        loading={loadingDeleteActionPlan}
      />
    </>
  );
};

export default DeleteActionPlanModal;
