import { yupResolver } from '@hookform/resolvers/yup';
import { InputAdornment, MenuItem, Stack } from '@mui/material';
import { getClassOfUse } from '@predium/client-lookup';
import { area_system_type_enum, area_type_enum, data_source_type_enum, type_of_use_enum } from '@predium/enums';
import { translateUnitEnum_dynamic } from '@predium/i18n/client';
import { Units } from '@predium/utils';
import { FieldArrayWithId, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { FormProvider, RHFNumberField, RHFSelect, RHFTextField } from '../../../../../components/hook-form';
import { useLanguage } from '../../../../../provider/LanguageProvider';
import { AreaFormValues, AreaMutation } from '../types';
import { getSortedTypesOfUse } from '../utils';

// due to circular dependency, we need to define the type here instead of using yup.InferType<typeof schema>
type FormValue = {
  name: string;
  description: string;
  is_rented: boolean;
  type_of_use_id: type_of_use_enum;
  value: number;
  uid?: string;
};

type Props = {
  defaultValue?: FormValue;
  onSubmit: (values: AreaMutation) => void;
  existingAreas: FieldArrayWithId<AreaFormValues, 'areas', 'uid'>[];
};

const TenantAreasForm = ({ onSubmit, defaultValue, existingAreas }: Props) => {
  const { t } = useTranslation();
  const { localize } = useLanguage();

  const schema = yup.object().shape(
    {
      name: yup.string().when('description', {
        is: (description: string) => description === '',
        then: yup.string().required(t('DataCollectionAreas_NameOrDescriptionIsRequired')),
        otherwise: yup.string(),
      }),
      description: yup
        .string()
        .when('name', {
          is: (name: string) => name === '',
          then: yup.string().required(t('DataCollectionAreas_NameOrDescriptionIsRequired')),
          otherwise: yup.string(),
        })
        .test('unique-description', t('DataCollectionAreas_DuplicateDescription'), function (value) {
          if (!value) return true;

          // When editing, we need to exclude the current area from the check
          const isDuplicate = existingAreas.some(
            (area) => area.description === value && (!defaultValue?.uid || area.uid !== defaultValue.uid),
          );

          return !isDuplicate;
        }),
      is_rented: yup.boolean().typeError(t('General_Required')).required(t('General_Required')),
      type_of_use_id: yup
        .mixed<type_of_use_enum>()
        .oneOf(Object.values(type_of_use_enum), t('General_Required'))
        .required(t('General_Required')),
      value: yup
        .number()
        .min(0.01, t('General_MinRangeMessage', { min: 0 }))
        .max(
          99_999_999,
          t('General_MaxRangeMessage', {
            max: localize.formatAsCompact(99_999_999),
          }),
        )
        .required(t('General_Required')),
    },
    // to avoid circular dependency
    [['name', 'description']],
  );

  const methods = useForm<FormValue>({
    defaultValues: {
      name: defaultValue?.name ?? '',
      description: defaultValue?.description ?? '',
      is_rented: defaultValue?.is_rented,
      type_of_use_id: defaultValue?.type_of_use_id,
      value: defaultValue?.value ?? 0,
    },
    resolver: yupResolver(schema),
  });

  const handleSubmit = methods.handleSubmit((values) => {
    onSubmit({
      ...values,
      area_system_id: area_system_type_enum.PREDIUM_2024,
      area_type_id: area_type_enum.MF,
      class_of_use_id: getClassOfUse(values.type_of_use_id),
      data_source_id: data_source_type_enum.MANUAL,
    });
  });

  const sortedTypeOfUse = getSortedTypesOfUse();

  return (
    <FormProvider formId="tenant-area-form" methods={methods} onSubmit={handleSubmit}>
      <Stack spacing={4} my={1}>
        <RHFTextField name="description" label={t('General_Title')} variant="outlined" />
        <RHFTextField name="name" label="ID" variant="outlined" helperText={t('DataCollectionAreas_TitleHelperText')} />
        <RHFSelect name="is_rented" label={t('DataCollectionAreas_Management')}>
          <MenuItem value="true">{t('DataCollectionAreas_Rented')}</MenuItem>
          <MenuItem value="false">{t('DataCollectionAreas_OwnUse')}</MenuItem>
        </RHFSelect>
        <RHFSelect name="type_of_use_id" label={t('General_TypeOfUse')}>
          {sortedTypeOfUse.map(({ value, label }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </RHFSelect>
        <RHFNumberField
          name="value"
          label={t('General_Area')}
          variant="outlined"
          decimalScale={2}
          InputProps={{
            endAdornment: <InputAdornment position="end">{translateUnitEnum_dynamic(Units.area, t)}</InputAdornment>,
          }}
          max={99_999_999}
        />
      </Stack>
    </FormProvider>
  );
};

export default TenantAreasForm;
