import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import {
  MAPBOX_CURRENT_BUILDING_LAYER_ID,
  MAPBOX_HOVERED_BUILDING_LAYER_ID,
  MAPBOX_OTHER_BUILDINGS_LAYER_ID,
  MAPBOX_SELECTED_BUILDING_LAYER_ID,
} from '@predium/client-lookup';
import { expandSelection, mergePolygons, mergeSameIdPolygons } from '@predium/geo-utils';
import { bbox, center, featureCollection, point } from '@turf/turf';
import { Feature, MultiPolygon, Point, Polygon } from 'geojson';
import uniq from 'lodash/uniq';
import { type LngLatBoundsLike, MapEvent, default as mapboxgl } from 'mapbox-gl';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Map, { Marker } from 'react-map-gl';
import { ICONS } from '../../assets/icons';
import { useLanguage } from '../../provider/LanguageProvider';
import Iconify from '../Iconify';
import EditModeNavigationControl from './EditModeNavigationControl';
import CustomNavigationControl from './MapNavigationControl';

const BUILDING_MAX_DISTANCE = 200;

declare global {
  interface Window {
    debugMapboxMode: boolean;
  }
}

window.debugMapboxMode = false;
mapboxgl.accessToken = import.meta.env.VITE_MAPBOX_ACCESS_TOKEN;

export type MapAddress = {
  street: string;
  longitude?: number | null | undefined;
  latitude?: number | null | undefined;
  mapbox_building_ids?: string[];
  mapbox_zoom?: number | null | undefined;
  mapbox_pitch?: number | null | undefined;
  mapbox_bearing?: number | null | undefined;
};

type BuildingMapCommonProps = {
  id: string;
  /**
   * This prop enables us to block map interactions. It's useful for when we already have all the map information available and we just
   * want to show it to the user and we don't want them to change it.
   * lockView = true will prevent building selection, zoom/panel changes and will change the mouse cursor to the regular arrow.
   */
  lockView?: boolean;
  /**
   * Invoked when the user clicks on a building. It passes whether the action is selecting or unselecting the building.
   * This was introduced mainly for analytics purposes to see how often users were interacting with the map.
   */
  onBuildingClick?: (isSelectingBuilding: boolean) => void;
  /**
   * Method that exposes the building selection to the parent component. It can be used to pass selection down to a form for instance.
   */
  onSelectionChange?: (ids: number[]) => void;
};

/**
 * Props used within the building details flow where the user can manually edit the building selection after the building is created.
 * In this flow, we show additional UI to enable users to confirm their or reset their choices.
 */
type BuildingMapProps = BuildingMapCommonProps & {
  /**
   * Invoked when user pressed the edit icon overlay. We're using it to open the editing modal.
   */
  onEdit?: () => void;
  /**
   * Invoked when user pressed either pressed on the X icon or cancel button. We're closing the editing modal.
   */
  onCancel?: () => void;
  /**
   * Invoked when user pressed to save their changes.
   */
  onSave?: (args: { ids: string[]; longitude?: number; latitude?: number }) => void;
  onBuildingResult?: never;
  editMode?: boolean;
  isLoading?: boolean;
  editDisabled?: boolean;
  hasEditAccess?: boolean;
  address: MapAddress;
  preSelectBuilding?: never;
};

/**
 * Props used in the new building creation flow where the user can search for an address.
 * The "never" types are not used by the component in that case. The main use case in the flow is to
 * load the map and preselect a building based on the selected coords for the given address.
 */
type BuildingMapPropsWithSelectionOnly = BuildingMapCommonProps & {
  onEdit?: never;
  onCancel?: never;
  onSave?: never;
  editMode: true;
  isLoading?: never;
  editDisabled?: never;
  hasEditAccess?: never;
  address: {
    latitude: number;
    longitude: number;
    mapbox_building_ids?: string[];
    mapbox_zoom?: number;
    mapbox_pitch?: never;
    mapbox_bearing?: never;
  };
  /**
   * Required prop for when we want to enable the logic of preselecting a building based the selected address coords
   */
  preSelectBuilding: true;
  /**
   * Invoked right after attempting to highlight a building based on the selected address. It was originally added to measure
   * how often we're failing to find a building for the selected address.
   */
  onBuildingResult?: (isBuildingFound: boolean) => void;
};

export default function BuildingMap({
  id,
  onEdit,
  onCancel,
  onSave,
  onSelectionChange,
  onBuildingClick,
  onBuildingResult,
  editMode = false,
  isLoading = false,
  editDisabled = false,
  hasEditAccess,
  address,
  preSelectBuilding,
  lockView,
}: BuildingMapProps | BuildingMapPropsWithSelectionOnly) {
  const { t } = useTranslation();
  const { localize } = useLanguage();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const mapRef = useRef<mapboxgl.Map | null>(null);

  const [mapLoaded, setMapLoaded] = useState(false);
  const [showPendingChangesDialog, setShowPendingChangesDialog] = useState(false);
  const [currentBuildings, setCurrentBuildings] = useState<Feature<Polygon | MultiPolygon>[]>([]);
  const [selectionPoint, setSelectionPoint] = useState<[number, number] | null>(null);
  const [selectedBuildingsIds, setSelectedBuildingsIds] = useState<number[]>(
    editMode ? address?.mapbox_building_ids?.map((idsAsString: string) => parseInt(idsAsString)) ?? [] : [],
  );
  const [viewState, setViewState] = useState({
    longitude: address.longitude ?? 0,
    latitude: address.latitude ?? 0,
    zoom: address.mapbox_zoom ?? 16,
    pitch: address.mapbox_pitch ?? 55,
    bearing: address.mapbox_bearing ?? 0,
  });
  const [showAddressPin, setShowAddressPin] = useState(false);

  const currentBuildingIds = useMemo(() => {
    return address?.mapbox_building_ids?.map((idsAsString: string) => parseInt(idsAsString)) ?? [];
  }, [address.mapbox_building_ids]);

  const showLoadingState = isLoading && !address?.mapbox_building_ids?.length && !editMode;
  const showErrorState = !isLoading && !address?.mapbox_building_ids?.length && !editMode && !preSelectBuilding;

  const hasCoordinates = !!address.longitude && !!address.latitude;

  const noSelectionMade =
    selectedBuildingsIds.every((id) => currentBuildingIds?.includes(id)) &&
    (selectedBuildingsIds.length !== 0 || address.mapbox_building_ids?.length === 0) &&
    currentBuildingIds?.every((id) => selectedBuildingsIds.includes(id));

  const buildingByIdFilter = (ids: number[]) => ['in', ['id'], ['literal', ids]] as any;

  const recenterMap = useCallback(() => {
    const mergedPolygons = mergePolygons(
      mapRef.current!.querySourceFeatures('composite', {
        sourceLayer: 'building',
        filter: buildingByIdFilter(currentBuildingIds),
      }) as Feature<Polygon>[],
    );

    if (mergedPolygons === null) {
      console.error('Could not merge polygons');
      return;
    }

    const bounds = bbox(mergedPolygons);
    const centerPoint = center(mergedPolygons).geometry.coordinates as [number, number];

    mapRef.current!.fitBounds(
      [
        [bounds[0], bounds[1]],
        [bounds[2], bounds[3]],
      ],
      {
        padding: { top: 10, bottom: 25, left: 15, right: 15 },
        center: centerPoint,
        // use user defined zoom but a little less because the pdf map is so small
        maxZoom: Math.max(
          16,
          address.mapbox_zoom ? address.mapbox_zoom - 0.5 : 17.5 - (currentBuildingIds.length - 1) * 0.25,
        ),
        speed: 0.5,
        bearing: address.mapbox_bearing ?? 0,
        pitch: address.mapbox_pitch ?? 55,
        curve: 1,
        essential: true,
      },
    );
  }, [address, currentBuildingIds]);

  const highlightBuilding = useCallback(
    (
      layerId:
        | typeof MAPBOX_HOVERED_BUILDING_LAYER_ID
        | typeof MAPBOX_SELECTED_BUILDING_LAYER_ID
        | typeof MAPBOX_CURRENT_BUILDING_LAYER_ID
        | typeof MAPBOX_OTHER_BUILDINGS_LAYER_ID,
      ids: number[],
    ) => {
      const otherBuildingIds = uniq([...ids, ...selectedBuildingsIds]);

      //set the filter
      mapRef.current?.setFilter(layerId, buildingByIdFilter(ids));

      //remove from the other buildings layer
      mapRef.current?.setFilter(MAPBOX_OTHER_BUILDINGS_LAYER_ID, ['!', buildingByIdFilter(otherBuildingIds)]);
    },
    [selectedBuildingsIds],
  );

  const resetSelection = () => {
    setSelectedBuildingsIds(currentBuildingIds);
  };

  const handleLoad = useCallback(
    (event: MapEvent) => {
      mapRef.current = event.target;
      mapRef.current?.setConfigProperty('basemap', 'lightPreset', 'day');
      mapRef.current?.setConfigProperty('basemap', 'showPointOfInterestLabels', false);
      mapRef.current?.setConfigProperty('basemap', 'showRoadLabels', true);
      mapRef.current?.setConfigProperty('basemap', 'show3dObjects', false);
      //standard navigation control + recenter button
      const customNav = new CustomNavigationControl(
        { showCompass: true, showZoom: true, visualizePitch: true },
        t('MapNavigationControlRecenter_Title'),
        () => {
          if (!showLoadingState) {
            recenterMap();
          }
        },
      );

      if (!lockView) {
        //edit mode navigation control
        const editNav = new EditModeNavigationControl(
          {
            showCompass: false,
            showZoom: false,
            visualizePitch: false,
          },
          () => {
            if (!showLoadingState && !editDisabled) {
              onEdit?.();
            }
          },
          editDisabled,
          t('DataCollection_MapBoxEditMode_Title'),
        );

        if (!showLoadingState && !showErrorState) {
          mapRef.current?.addControl(customNav, 'bottom-right');
          if (!editMode && hasEditAccess) {
            mapRef.current?.addControl(editNav, 'top-right');
          }
        }
      }

      highlightBuilding(
        editMode ? MAPBOX_SELECTED_BUILDING_LAYER_ID : MAPBOX_CURRENT_BUILDING_LAYER_ID,
        currentBuildingIds,
      );
      recenterMap();
      setMapLoaded(true);
    },
    [
      t,
      lockView,
      editMode,
      highlightBuilding,
      currentBuildingIds,
      recenterMap,
      onEdit,
      showLoadingState,
      showErrorState,
      editDisabled,
      hasEditAccess,
    ],
  );

  const handleSave = () => {
    if (onSave === undefined) return;

    if (showPendingChangesDialog) {
      setShowPendingChangesDialog(false);
    }

    if (mapRef.current === null) {
      onSave({ ids: selectedBuildingsIds.map((id) => id.toString()) });
      return;
    }

    const features = mapRef.current.querySourceFeatures('composite', {
      sourceLayer: 'building',
      filter: buildingByIdFilter(selectedBuildingsIds),
    }) as Feature<Polygon>[];
    const centerPoint = center(featureCollection(features)).geometry.coordinates;
    const longitude = centerPoint[0];
    const latitude = centerPoint[1];
    onSave({ ids: selectedBuildingsIds.map((id) => id.toString()), longitude, latitude });
  };

  const handleCloseMapbox = () => {
    if (noSelectionMade) {
      onCancel?.();
    } else {
      setShowPendingChangesDialog(true);
    }
  };

  useEffect(() => {
    if (!editMode) return;
    highlightBuilding(MAPBOX_SELECTED_BUILDING_LAYER_ID, selectedBuildingsIds);
  }, [highlightBuilding, selectedBuildingsIds, editMode]);

  const isStyleLoaded = mapRef?.current?.isStyleLoaded();

  const getEventFeatures = (e: mapboxgl.MapMouseEvent & { features?: mapboxgl.GeoJSONFeature[] }) => {
    if (e.features && e.features.length > 0) {
      const rendered: Feature<Polygon>[] = mapRef.current!.querySourceFeatures('composite', {
        sourceLayer: 'building',
        filter: ['<=', ['distance', point([e.lngLat.lng, e.lngLat.lat])], BUILDING_MAX_DISTANCE],
      }) as Feature<Polygon>[];
      const merged = mergeSameIdPolygons(rendered);

      return expandSelection(
        merged as Feature<Polygon>[],
        e.features.map((feature) => String(feature.id)),
      );
    }
    return [];
  };

  const getMaxBounds = (): LngLatBoundsLike => {
    if (currentBuildings.length > 0 && !preSelectBuilding) {
      const type = currentBuildings[0].geometry.type;

      if (type === 'Polygon') {
        return [
          [currentBuildings[0].geometry.coordinates[0][0][0], currentBuildings[0].geometry.coordinates[0][0][1]],
          [currentBuildings[0].geometry.coordinates[0][0][0], currentBuildings[0].geometry.coordinates[0][0][1]],
        ];
      }

      if (type === 'MultiPolygon') {
        return [
          [currentBuildings[0].geometry.coordinates[0][0][0][0], currentBuildings[0].geometry.coordinates[0][0][0][1]],
          [currentBuildings[0].geometry.coordinates[0][0][0][0], currentBuildings[0].geometry.coordinates[0][0][0][1]],
        ];
      }

      throw new Error('Unknown geometry type');
    }

    if (address.longitude && address.latitude) {
      return [
        [address.longitude - 0.01, address.latitude - 0.01],
        [address.longitude + 0.01, address.latitude + 0.01],
      ];
    }

    return [
      [-0.01, -0.01],
      [0.01, 0.01],
    ];
  };

  useEffect(() => {
    if (mapRef.current && isStyleLoaded) {
      setCurrentBuildings(
        mapRef?.current?.querySourceFeatures('composite', {
          sourceLayer: 'building',
          filter: buildingByIdFilter(currentBuildingIds),
        }) as Feature<Polygon>[],
      );
    }
  }, [isStyleLoaded, currentBuildingIds]);

  useEffect(() => {
    if (!mapRef.current || !mapLoaded) return;

    if (lockView) mapRef.current!.getCanvas().style.cursor = 'default';

    const mouseMoveHandler = (e: mapboxgl.MapMouseEvent & { features?: mapboxgl.GeoJSONFeature[] }) => {
      if (e.features && e.features.length > 0 && editMode) {
        const hoveredBuildingIds = getEventFeatures(e).map((feature) => parseInt(feature.id as string));
        highlightBuilding(MAPBOX_HOVERED_BUILDING_LAYER_ID, hoveredBuildingIds);
        mapRef.current!.getCanvas().style.cursor = 'pointer';
      }
    };

    const mouseLeaveHandler = () => {
      if (editMode) {
        highlightBuilding(MAPBOX_HOVERED_BUILDING_LAYER_ID, []);
        mapRef.current!.getCanvas().style.cursor = '';
      }
    };

    const selectBuildingHandler = (e: mapboxgl.MapMouseEvent & { features?: mapboxgl.GeoJSONFeature[] }) => {
      if (e.features && e.features.length > 0 && editMode) {
        const eventFeatures = getEventFeatures(e);
        const newSelection = uniq([
          ...selectedBuildingsIds,
          ...eventFeatures.map((feature) => parseInt(feature.id as string)),
        ]);
        setSelectionPoint([e.lngLat.lng, e.lngLat.lat]);

        const selectedId = eventFeatures.map((feature) => parseInt(feature.id as string))[0];
        const isSelectingBuilding = !selectedBuildingsIds.includes(selectedId);

        onBuildingClick?.(isSelectingBuilding);
        setSelectedBuildingsIds(newSelection);
        highlightBuilding(MAPBOX_SELECTED_BUILDING_LAYER_ID, newSelection);
      }
    };

    const unselectBuildingHandler = (e: mapboxgl.MapMouseEvent & { features?: mapboxgl.GeoJSONFeature[] }) => {
      if (e.features && e.features.length > 0 && editMode) {
        const selectedBuildingIds = getEventFeatures(e).map((feature) => parseInt(feature.id as string));
        const newValue = selectedBuildingsIds.filter((id) => !selectedBuildingIds.includes(id));
        setSelectedBuildingsIds(newValue);
        highlightBuilding(MAPBOX_HOVERED_BUILDING_LAYER_ID, newValue);
      }
    };

    const layers = [
      MAPBOX_OTHER_BUILDINGS_LAYER_ID,
      MAPBOX_CURRENT_BUILDING_LAYER_ID,
      MAPBOX_SELECTED_BUILDING_LAYER_ID,
      MAPBOX_HOVERED_BUILDING_LAYER_ID,
    ];

    layers.forEach((layer) => {
      if (!lockView) mapRef.current!.on('mousemove', layer, mouseMoveHandler);
    });

    if (!lockView) {
      mapRef.current?.on('mouseleave', MAPBOX_HOVERED_BUILDING_LAYER_ID, mouseLeaveHandler);
      mapRef.current?.on('mouseleave', MAPBOX_SELECTED_BUILDING_LAYER_ID, mouseLeaveHandler);
      mapRef.current?.on('click', MAPBOX_HOVERED_BUILDING_LAYER_ID, selectBuildingHandler);
      mapRef.current?.on('click', MAPBOX_SELECTED_BUILDING_LAYER_ID, unselectBuildingHandler);
    }

    return () => {
      if (mapRef.current) {
        layers.forEach((layer) => {
          mapRef.current!.off('mousemove', layer, mouseMoveHandler);
        });
        mapRef.current.off('mouseleave', MAPBOX_HOVERED_BUILDING_LAYER_ID, mouseLeaveHandler);
        mapRef.current.off('mouseleave', MAPBOX_SELECTED_BUILDING_LAYER_ID, mouseLeaveHandler);
        mapRef.current.off('click', MAPBOX_HOVERED_BUILDING_LAYER_ID, selectBuildingHandler);
        mapRef.current.off('click', MAPBOX_SELECTED_BUILDING_LAYER_ID, unselectBuildingHandler);
      }
    };
  }, [editMode, highlightBuilding, lockView, mapLoaded, onBuildingClick, selectedBuildingsIds]);

  const handleZoomEnd = () => {
    const currentZoom = mapRef.current!.getZoom();
    setShowAddressPin(currentZoom < 15);
  };

  useEffect(() => {
    // Select and highlight a building based on the provided coords
    const highlightBuildingBasedOnCoords = async () => {
      const lat = address.latitude || 0;
      const lng = address.longitude || 0;
      const pointInView = await mapRef?.current?.project({ lat, lng });
      const pos: [number, number] = [pointInView?.x || 0, pointInView?.y || 0];
      const features = mapRef.current?.queryRenderedFeatures(pos);

      // Mocks the structure of the event object like it was the a user clicking on the building
      const mockedEvent = {
        features,
        lngLat: {
          lat: address.latitude,
          lng: address.longitude,
        },
      } as mapboxgl.MapMouseEvent & { features?: mapboxgl.GeoJSONFeature[] };

      const idsToSelect = getEventFeatures(mockedEvent).map((feature) => parseInt(feature.id as string));

      onBuildingResult?.(!!idsToSelect.length);

      setSelectedBuildingsIds(idsToSelect);
    };

    if (preSelectBuilding && mapLoaded && !address.mapbox_building_ids?.length) {
      highlightBuildingBasedOnCoords();
      mapRef.current?.zoomTo(17.5, { duration: 1000 });
    }
  }, [
    recenterMap,
    preSelectBuilding,
    address.latitude,
    address.longitude,
    mapLoaded,
    currentBuildingIds,
    onBuildingResult,
    address.mapbox_building_ids?.length,
  ]);

  useEffect(() => {
    onSelectionChange?.(selectedBuildingsIds);
  }, [selectedBuildingsIds, onSelectionChange, mapLoaded]);

  /*
    Necessary effect to update the viewState if/when the lat/long data changes.
    This is necessary for the case where the building information is still being enriched and the coords
    are made available after the map loads.
    Without this, the map might shown in a blue/grey state.
  */
  useEffect(() => {
    if (address?.latitude && address?.longitude)
      setViewState((current) => ({
        ...current,
        latitude: address?.latitude as number,
        longitude: address?.longitude as number,
      }));
  }, [address?.latitude, address?.longitude]);

  return (
    <Box
      height="100%"
      width="100%"
      position="relative"
      sx={{
        '& .MuiDialogActions-root': {
          px: '0px !important',
        },
      }}
    >
      {editMode && !preSelectBuilding && (
        <DialogTitle sx={{ p: 0, pb: 2 }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Stack direction="row" alignItems="center" gap={2}>
              <IconButton onClick={handleCloseMapbox}>
                <Iconify icon={ICONS.CHEVRON_LEFT} width={24} height={24} color="action.active" />
              </IconButton>
              <Typography variant="h6">{t('DataCollection_MapBoxEditMode_Title')}</Typography>
            </Stack>
            <IconButton onClick={handleCloseMapbox}>
              <Iconify icon={ICONS.CLOSE} width={24} height={24} color="action.active" />
            </IconButton>
          </Stack>
        </DialogTitle>
      )}

      {editMode && !preSelectBuilding && (
        <Card
          sx={{
            position: 'absolute',
            top: 'calc(16px + 40px + 24px)',
            right: '24px',
            zIndex: 1000,
            width: '300px',
            boxShadow: theme.shadows[12],
          }}
        >
          <CardHeader title={t('General_BuildingLocation')} />
          <CardContent
            sx={{
              paddingY: 2,
            }}
          >
            <Typography variant="body2" color="text.secondary">
              {t('DataCollection_MapboxEditMode_Description')}
            </Typography>
            <Typography variant="subtitle2" color="text.primary" sx={{ mt: 2 }}>
              {address.street}
            </Typography>
            <Typography variant="body2" color="text.primary">
              {localize.formatAsFloat(address.latitude, { fractionDigits: 4 })}°N,
              {localize.formatAsFloat(address.longitude, { fractionDigits: 4 })}°E
            </Typography>
            <Stack direction="row" justifyContent="flex-end" mt={2}>
              <Button
                disabled={noSelectionMade || showLoadingState}
                variant="text"
                onClick={resetSelection}
                startIcon={<Iconify icon={ICONS.RESTORE} />}
              >
                {t('General_ResetToDefault')}
              </Button>
            </Stack>
          </CardContent>
        </Card>
      )}

      <Map
        {...viewState}
        onMove={(evt) => setViewState(evt.viewState)}
        id={id}
        mapStyle="mapbox://styles/predium/cm0l1v65u008u01o39pm9f7bw"
        mapboxAccessToken={import.meta.env.VITE_MAPBOX_ACCESS_TOKEN}
        style={{
          width: '100%',
          height: editMode && !preSelectBuilding ? 'calc(100% - 36px - 24px - 24px - 24px - 24px)' : '100%',
          borderRadius: editMode ? '16px' : undefined,
          opacity: showLoadingState || showErrorState ? 0.5 : 1,
          filter: showLoadingState || !hasCoordinates ? 'grayscale(1)' : 'none',
        }}
        onLoad={handleLoad}
        doubleClickZoom={false}
        dragPan={editMode && !showLoadingState && !lockView}
        scrollZoom={editMode && !lockView}
        onZoom={handleZoomEnd}
        maxBounds={editMode ? undefined : getMaxBounds()}
      >
        {showAddressPin && (
          <Marker color="#5035FA" longitude={address.longitude ?? 0} latitude={address.latitude ?? 0} anchor="center" />
        )}
      </Map>

      {editMode && !preSelectBuilding && (
        <DialogActions>
          {selectedBuildingsIds.length === 0 && (
            <Stack direction="row" gap={1} alignItems="center">
              <Iconify icon={ICONS.DANGER} color="text.primary" />
              <Typography variant="body2" color="text.primary">
                {t('DataCollection_MapboxEditMode_Warning')}
              </Typography>
            </Stack>
          )}
          <Stack direction="row" gap={1.5}>
            <Button variant="outlined" onClick={handleCloseMapbox} disabled={showLoadingState}>
              {t('General_Cancel')}
            </Button>
            <Button
              variant="contained"
              onClick={handleSave}
              disabled={noSelectionMade || selectedBuildingsIds.length === 0 || showLoadingState}
            >
              {t('General_Save')}
            </Button>
            {window.debugMapboxMode && (
              <Button
                variant="contained"
                onClick={() => {
                  if (mapRef.current === null || selectionPoint === null) {
                    return;
                  }
                  const clickPoint = point(selectionPoint);

                  const rendered: Feature<Polygon>[] = mapRef.current!.querySourceFeatures('composite', {
                    sourceLayer: 'building',
                    filter: ['<=', ['distance', clickPoint], BUILDING_MAX_DISTANCE],
                  }) as Feature<Polygon>[];
                  const merged = mergeSameIdPolygons(rendered).map((f) => ({
                    id: f.id,
                    type: f.type,
                    properties: f.properties ?? {},
                    geometry: f.geometry,
                  }));

                  // Pull the ids to the features
                  merged.forEach((feature) => {
                    feature.properties = feature.properties ?? {};
                    feature.properties.id = feature.id;
                  });

                  merged
                    .filter((feature) => selectedBuildingsIds.includes(parseInt(feature.id as string)))
                    .forEach((feature) => {
                      feature.properties = feature.properties ?? {};
                      feature.properties['fill'] = 'blue';
                      feature.properties['stroke'] = 'blue';
                    });

                  const collection = featureCollection<Polygon | Point>([...merged, clickPoint]);
                  navigator.clipboard.writeText(JSON.stringify(collection));
                  enqueueSnackbar('Last click GeoJSON Copied to clipboard', { variant: 'success' });
                }}
              >
                Debug
              </Button>
            )}
          </Stack>
        </DialogActions>
      )}

      {editMode && !preSelectBuilding && (
        <Card
          sx={{
            position: 'absolute',
            bottom: 'calc(24px + 24px + 32px + 24px)',
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 1000,
          }}
        >
          <CardContent sx={{ padding: 2, '&:last-child': { paddingBottom: 2 } }}>
            <Stack direction="row" gap={1}>
              <Box
                sx={{
                  width: 12,
                  height: 12,
                  borderRadius: '50%',
                  backgroundColor: '#454F5B',
                  alignSelf: 'center',
                }}
              />
              <Typography variant="body2">{t('General_SelectedLocation', { count: 2 })}</Typography>
            </Stack>
          </CardContent>
        </Card>
      )}

      {showPendingChangesDialog && (
        <Dialog open={showPendingChangesDialog} onClose={() => setShowPendingChangesDialog(false)}>
          <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {t('DataCollection_MapboxEditMode_PendingChangesTitle')}
            <IconButton onClick={() => setShowPendingChangesDialog(false)}>
              <Iconify icon={ICONS.CLOSE} width={20} height={20} />
            </IconButton>
          </DialogTitle>
          <DialogContent sx={{ minWidth: '480px' }}>
            <Typography variant="body2">{t('DataCollection_MapboxEditMode_PendingChangesDescription')}</Typography>
          </DialogContent>
          <DialogActions>
            <Stack direction="row" gap={1} justifyContent="space-between" width="100%">
              <Button variant="text" color="error" onClick={onCancel}>
                {t('General_DontSave')}
              </Button>
              <Stack direction="row" gap={1}>
                <Button variant="outlined" onClick={() => setShowPendingChangesDialog(false)}>
                  {t('General_KeepEditing')}
                </Button>
                <Button
                  variant="contained"
                  onClick={handleSave}
                  disabled={noSelectionMade || selectedBuildingsIds.length === 0}
                >
                  {t('General_Save')}
                </Button>
              </Stack>
            </Stack>
          </DialogActions>
        </Dialog>
      )}

      {showLoadingState && <LoadingOverlay />}

      {showErrorState && <ErrorOverlay onEdit={onEdit} hasCoordinates={hasCoordinates} />}
    </Box>
  );
}

function LoadingOverlay() {
  return (
    <Box
      sx={{
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1000,
      }}
    >
      <CircularProgress />
    </Box>
  );
}

function ErrorOverlay({ onEdit, hasCoordinates }: { onEdit?: () => void; hasCoordinates: boolean }) {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        position: 'absolute',
        borderRadius: '16px',
        display: 'flex',
        gap: 1.5,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        zIndex: 1000,
      }}
    >
      <Iconify icon={ICONS.ALERT_CIRCLE} width={40} height={40} color="error.main" />
      <Typography variant="body2" color="error.darker">
        {hasCoordinates
          ? t('DataCollection_MapboxEditMode_Error-hasCoordinates')
          : t('DataCollection_MapboxEditMode_Error-noCoordinates')}
      </Typography>
      {!!onEdit && hasCoordinates && (
        <Button variant="outlined" onClick={onEdit} sx={{ borderColor: 'grey.300' }}>
          {t('DataCollection_MapboxEditMode_Error_EditManually')}
        </Button>
      )}
    </Box>
  );
}
