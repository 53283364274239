import { ActionPlanningScenariosScenarioFragment } from '@predium/client-graphql';
import { PermissionType } from '../../../contexts/PermissionContext';

export const hasScenarioEditDeletePermission = (
  scenario: ActionPlanningScenariosScenarioFragment,
  checkPortfolioPermission: (portfolio_id: number, permissionType: PermissionType) => boolean,
) => {
  return scenario?.portfolio ? checkPortfolioPermission(scenario.portfolio.id, PermissionType.READ) : false;
};

export const getScenarioNames = (scenarios: { name: string; id: number }[], scenarioId?: number) => {
  const baseScenarios = scenarioId ? scenarios.filter((s) => s.id !== scenarioId) : scenarios;
  return baseScenarios.map((s) => s.name);
};

export const getScenarioToDelete = (scenario: ActionPlanningScenariosScenarioFragment) => {
  const actionPlanCount = scenario?.action_plans?.length || 0;
  const actionCount = scenario?.action_plans?.reduce(
    (acc, actionPlan) => acc + (actionPlan?.actions_aggregate?.aggregate?.count || 0),
    0,
  );

  return {
    id: scenario.id,
    name: scenario.name,
    totalActionPlans: actionPlanCount,
    totalActions: actionCount,
  } as const;
};
