import { Box, Divider, Stack, Tab, Tabs } from '@mui/material';
import { EsgAnalysisGetPortfolioQuery } from '@predium/client-graphql';
import { t } from 'i18next';
import { useState } from 'react';
import { ESG_TAB_ICONS } from '../../../assets/icons';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import Iconify from '../../../components/Iconify';
import StickyBox from '../../../components/common/StickyBox';
import useEsgAnalysisFilters from '../../../hooks/useEsgAnalysisFilters';
import usePosthogTracking from '../../../hooks/usePosthogTracking';
import useSessionData from '../../../hooks/useSessionData';
import { useLanguage } from '../../../provider/LanguageProvider';
import { PATHS } from '../../../routes';
import EsgAnalysisBenchmark, { BenchmarkType } from '../Components/EsgAnalysisBenchmark';
import EsgAnalysisUnitSwitcher from '../Components/EsgAnalysisUnitSwitcher';
import EsgAnalysisViewSwitcher, { DisplayViewType } from '../Components/EsgAnalysisViewSwitcher';
import MetaInformation from '../Components/MetaInformation';
import { EsgAnalysisFilterPopupContextProvider } from '../EsgAnalysisFilters/EsgAnalysisFilterPopupContext';
import EsgAnalysisFiltersSidebar from '../EsgAnalysisFilters/EsgAnalysisFiltersSidebar';
import FilterButton from '../EsgAnalysisFilters/FilterButton';
import { getPortfolioMetaInformation } from '../EsgAnalysisUtil';
import BuildingsSelector from '../NavigationSelectors/BuildingsSelector';
import EconomicUnitsSelector from '../NavigationSelectors/EconomicUnitsSelector';

export type ClimateRiskData = EsgAnalysisGetPortfolioQuery['getAnalyzedBuildings']['climateRisks'];
export type CurrentPageView = 'esg-portfolio' | 'esg-economics-units';
export type EsgAnalysisTab = 'demand' | 'consumption' | 'climate_risks';

type Props = {
  currentTab: EsgAnalysisTab;
  setCurrentTab: (tab: EsgAnalysisTab) => void;
  setShowTotal: (showTotal: boolean) => void;
  setCurrentView: (view: DisplayViewType) => void;
  currentView: DisplayViewType;
  setSelectedBenchmark: (benchmark: BenchmarkType) => void;
};

export default function EconomicUnitAnalysisHeader({
  currentTab,
  setCurrentTab,
  setShowTotal,
  setCurrentView,
  currentView,
  setSelectedBenchmark,
}: Props) {
  const { trackEvent } = usePosthogTracking();
  const { localize } = useLanguage();

  const { filters, analyzedBuildings } = useEsgAnalysisFilters();
  const [openFilter, setOpenFilter] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const { serverSideFeatureFlags } = useSessionData();
  const isClimateRiskV2Enabled = serverSideFeatureFlags.CLIMATE_RISK_V2;

  const tabs = [
    {
      label: t('General_Demand'),
      value: 'demand',
      icon: ESG_TAB_ICONS.DEMAND,
    },
    ...(!isClimateRiskV2Enabled
      ? [
          {
            label: t('General_ClimateRisks'),
            value: 'climate_risks',
            icon: ESG_TAB_ICONS.CLIMATE_RISKS,
          },
        ]
      : []),
  ];

  const onDisplayBaseChange = (newDisplayBase: 'per_square_meter' | 'absolute') => {
    const doShowTotal = newDisplayBase === 'absolute';
    setShowTotal(doShowTotal);
    trackEvent('DISPLAY_BASE_CHANGED', {
      display_base: newDisplayBase,
    });
  };

  const links = [
    { name: t('General_ESGAnalysis'), href: PATHS.esgAnalysis.portfolio() },
    { name: t('General_EconomicUnit') },
  ];

  const totalBuildings = analyzedBuildings?.buildingsGeneralData.length || 0;
  const floorAreaByBuildingClass = analyzedBuildings?.floorAreaBySubBuildingClass || [];

  const metaInfo = getPortfolioMetaInformation(floorAreaByBuildingClass, totalBuildings, localize);

  const isBuildingsFiltersActive = filters.buildingIds && filters.buildingIds.length > 0;

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenFilter(true);
  };

  return (
    <StickyBox>
      <Stack mb={2}>
        <HeaderBreadcrumbs
          heading={t('General_EsgAnalysisNavigationLabel')}
          links={links}
          sx={{ mb: 0 }}
          navigationSelector={
            <Stack direction="row" gap={1}>
              {isBuildingsFiltersActive && <BuildingsSelector currentPageView={'esg-economics-units'} />}
              <EconomicUnitsSelector
                filtersComponent={
                  <EsgAnalysisFilterPopupContextProvider>
                    <FilterButton handleOpen={handleOpen} isOpen={openFilter} />

                    {openFilter && (
                      <EsgAnalysisFiltersSidebar
                        onClose={() => setOpenFilter(false)}
                        anchorEl={anchorEl}
                        setAnchorEl={setAnchorEl}
                        currentPageView={'esg-economics-units'}
                      />
                    )}
                  </EsgAnalysisFilterPopupContextProvider>
                }
              />
            </Stack>
          }
        />
        {floorAreaByBuildingClass.length > 0 && (
          <Box mt={-2}>
            <MetaInformation items={metaInfo} />
          </Box>
        )}
      </Stack>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Tabs
          value={currentTab}
          scrollButtons="auto"
          variant="scrollable"
          allowScrollButtonsMobile
          onChange={(_, value) => setCurrentTab(value)}
        >
          {tabs.map((tab) => (
            <Tab
              disableRipple
              key={tab.value}
              label={tab.label}
              value={tab.value}
              sx={{ mb: 1 }}
              icon={<Iconify icon={tab.icon} width={24} height={24} />}
            />
          ))}
        </Tabs>

        {currentTab !== 'climate_risks' && (
          <Stack direction={'row'} spacing={2}>
            {currentTab !== 'consumption' && (
              <>
                <EsgAnalysisBenchmark setSelectedBenchmark={setSelectedBenchmark} />
                <EsgAnalysisViewSwitcher onViewChange={(view) => setCurrentView(view)} currentView={currentView} />
              </>
            )}
            <EsgAnalysisUnitSwitcher onDisplayBaseChange={onDisplayBaseChange} />
          </Stack>
        )}
      </Stack>

      <Divider />
    </StickyBox>
  );
}
