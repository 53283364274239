import { useQuery } from '@apollo/client';
import { Box, Stack } from '@mui/material';
import { SearchBuildingFragment } from '@predium/client-graphql';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SearchBoxWithLazyLoading, { SearchItemType } from '../../../components/search/SearchBoxWithLazyLoading';
import { GET_BUILDINGS_BY_SEARCHED_STRING } from '../../../graphql/EsgAnalysis.queries';
import NavigationSelectorButton from './NavigationSelectorButton';

/**
 * Creates each option for the ecoUnit select
 */
export const mapToBuildingSelectOption = (building: SearchBuildingFragment): SearchItemType => {
  return {
    value: building.id,
    label: `${building.address.street}, ${building.address.postal_code}, ${building.address.city}`,
  };
};

type Props = {
  selectedBuilding: SearchBuildingFragment;
  onBuildingSelected: (building: SearchItemType) => void;
};

const BuildingSelector = ({ selectedBuilding, onBuildingSelected }: Props) => {
  const { t } = useTranslation();
  const limit = 10; // limit for pagination

  const [buildings, setBuildings] = useState<SearchItemType[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(''); // For debounced query trigger

  const ref = useRef<HTMLDivElement>(null);

  const { loading, fetchMore, refetch } = useQuery(GET_BUILDINGS_BY_SEARCHED_STRING, {
    variables: { offset: 0, limit, text: `%${debouncedSearchTerm.trim()}%` },
    skip: !showSearchBox || !debouncedSearchTerm, // Skip if no debounced search term
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (data?.building) {
        setBuildings(data.building.map(mapToBuildingSelectOption));
        setHasMore(data.building.length === limit); // Check if we have more data
      }
    },
  });

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setShowSearchBox(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  const loadMore = async (newOffset: number, newDebouncedSearchTerm: string) => {
    if (!hasMore) return;

    const result = await fetchMore({
      variables: {
        offset: newOffset,
        limit,
        text: `%${newDebouncedSearchTerm.trim()}%`,
      },
    });

    // Check if we received more data
    if (result.data.building.length < limit) {
      setHasMore(false); // No more data
    } else {
      setHasMore(true); // More data exists
    }

    // Append new buildings
    setBuildings((prev) => [...prev, ...result.data.building.map(mapToBuildingSelectOption)]);
  };

  const handleBuildingNameClick = () => {
    setShowSearchBox(!showSearchBox);
    if (buildings.length === 0) {
      refetch(); // Fetch initial data if buildings are empty
    }
  };

  const handleBuildingChange = (newValue: SearchItemType) => {
    setShowSearchBox(false);
    onBuildingSelected(newValue);
  };

  return (
    <Box ml={1} ref={ref}>
      <Stack alignItems={'center'}>
        <NavigationSelectorButton
          title={`${selectedBuilding.address.street}, ${selectedBuilding.address.postal_code}, ${selectedBuilding.address.city}`}
          onClick={handleBuildingNameClick}
          isSelected={showSearchBox}
          tooltipText={t('General_SelectBuilding')}
        />
      </Stack>
      {showSearchBox && (
        <SearchBoxWithLazyLoading
          refetch={refetch}
          onItemSelected={handleBuildingChange}
          selectedItems={[mapToBuildingSelectOption(selectedBuilding)]}
          loading={loading}
          listItems={buildings}
          loadMore={loadMore}
          hasMore={hasMore}
          setHasMore={setHasMore}
          setDebouncedSearchTerm={setDebouncedSearchTerm}
          debouncedSearchTerm={debouncedSearchTerm}
          noDataText={t('EsgAnalysisFilterBarBuildingFilter_NoBuildingsFound')}
          placeholder={t('General_SearchBuildingPlaceholder')}
          onHideSearchBox={() => setShowSearchBox(false)}
        />
      )}
    </Box>
  );
};

export default BuildingSelector;
