const GITBOOK_BASE_URL = import.meta.env.VITE_GITBOOK_URL;

// Knowledge base paths for different sections
const PATHS = {
  ACADEMY: {
    HOME: '',
    TECHNICAL: {
      ACTION_COSTS: '/fachwissen/berechnungsgrundlagen/technik/massnahmenkosten-technik',
    },
    GLOSSARY: {
      SCOPE_123: '/fachwissen/glossar/scope-1-2-and-3-emissionen',
      CO2_PATH: '/fachwissen/glossar/co-e-pfad',
      ENERGY_PATH_CRREM: '/fachwissen/glossar/energiepfad-nach-crrem',
    },
    REGULATIONS: {
      EU_TAXONOMY: '/fachwissen/regulatorik/eu-taxonomie-einfach-erklart',
    },
    MODULES: {
      BUILDING_CREATION: '/module/datensammlung/gebaude-in-predium-anlegen',
    },
  },
} as const;

// Generate full URLs by combining base URL with paths
export const ACADEMY_LINKS = {
  ACADEMY_HOME: `${GITBOOK_BASE_URL}${PATHS.ACADEMY.HOME}`,
  NO_COST_AVAILABLE_FOR_ACTION_WITH_EXPLANATION: `${GITBOOK_BASE_URL}${PATHS.ACADEMY.TECHNICAL.ACTION_COSTS}`,
  SCOPE_123_GRAPH: `${GITBOOK_BASE_URL}${PATHS.ACADEMY.GLOSSARY.SCOPE_123}`,
  EU_TAXONOMY_TOOLTIP: `${GITBOOK_BASE_URL}${PATHS.ACADEMY.REGULATIONS.EU_TAXONOMY}`,
  BUILDING_CREATION_ESTIMATION_TOOLTIP: `${GITBOOK_BASE_URL}${PATHS.ACADEMY.MODULES.BUILDING_CREATION}`,
  CO2_PATH_GRAPH: `${GITBOOK_BASE_URL}${PATHS.ACADEMY.GLOSSARY.CO2_PATH}`,
  ENERGY_PATH_GRAPH: `${GITBOOK_BASE_URL}${PATHS.ACADEMY.GLOSSARY.ENERGY_PATH_CRREM}`,
} as const;
