import { Tooltip, Typography } from '@mui/material';
import { PARIS_READY_YEAR } from '@predium/client-lookup';
import React from 'react';
import { useTranslation } from 'react-i18next';

// Component for Paris Ready with Tooltip
interface ParisReadyDateProps {
  dateToCheck: string | number | undefined;
}

const ParisReadyTooltip: React.FC<ParisReadyDateProps> = ({ dateToCheck }) => {
  const { t } = useTranslation();

  // Check if the value is a string (for dates) or number (for years)
  const isParisReady = dateToCheck === PARIS_READY_YEAR.toString() || dateToCheck === PARIS_READY_YEAR;

  return isParisReady ? (
    <Tooltip
      placement="top"
      arrow
      title={t('ActionPlan-CO2Emissions_ParisReadyTooltip')}
      componentsProps={{
        tooltip: { sx: { maxWidth: 140, textAlign: 'center' } },
      }}
    >
      <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }}>
        {t('General_ParisReadyLabel')}
      </Typography>
    </Tooltip>
  ) : (
    <Typography variant="body2">{dateToCheck}</Typography>
  );
};

export { ParisReadyTooltip };
