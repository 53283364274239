import { Toolbar, styled } from '@mui/material';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import SearchField from '../../../components/search/SearchField';

//TODO Move this to common place.
export const TableToolbar = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  gap: theme.spacing(2),
  padding: theme.spacing(0, 1, 0, 3),
  pl: 3,
}));

type Props = {
  filterName: string;
  onFilterName: (value: string) => void;
};

export default function PortfolioTableToolbar({ filterName, onFilterName, children }: PropsWithChildren<Props>) {
  const { t } = useTranslation();
  return (
    <TableToolbar>
      <SearchField value={filterName} onChange={onFilterName} placeholder={t('General_SearchPortfolioAction')} />
      {children}
    </TableToolbar>
  );
}
