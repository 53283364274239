import { CardContent, Skeleton, Stack } from '@mui/material';
import Card from '@mui/material/Card';
import { useTranslation } from 'react-i18next';
import { CircleIcon } from '../../../../assets/Icons/CircleIcon';

export const CompareScenarioCardLoading = ({ dataColor }: { dataColor: string }) => {
  const { t } = useTranslation();

  return (
    <Card
      aria-label={t('ActionPlanningScenario_CompareScenarios_ScenarioCardLoadingAriaLabel')}
      sx={{ backgroundColor: 'grey.5008', padding: 2, borderRadius: 1 }}
    >
      <CardContent sx={{ padding: 0 }}>
        <Stack spacing={1}>
          <Stack spacing={1} direction={'row'}>
            <CircleIcon backgroundColor={dataColor} />
            <Skeleton variant="rectangular" width={440} height={28} />
          </Stack>
          <Stack spacing={2} direction={'row'}>
            <Skeleton variant="rectangular" width={200} height={18} />
            <Skeleton variant="rectangular" width={90} height={18} />
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};
