/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useQuery } from '@apollo/client';
import { Card, Container, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { ActionPlanningScenariosScenarioFragment } from '@predium/client-graphql';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import InlineUser from '../../components/InlineUser';
import { createStandardErrorSnackbar } from '../../components/NotistackProvider';
import Page from '../../components/Page';
import PreDialog from '../../components/presentations/PreDialog/PreDialog';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import TablePaginationStandard from '../../components/table/TablePaginationStandard';
import TableRowWithHighlight, { hasRecentlyChanged } from '../../components/table/TableRowWithHighlight';
import Unavailable from '../../components/Unavailable';
import { GET_SCENARIOS } from '../../graphql/ActionPlanning.queries';
import { useMultiSelectFilter } from '../../hooks/useMultiSelectFilter';
import usePermissions from '../../hooks/usePermissions';
import useTable, { applySortFilter } from '../../hooks/useTable';
import { PATHS } from '../../routes';
import CreateScenarioModal from '../../sections/Scenarios/Scenario/CreateScenarioModal';
import ScenariosTableHead, { HeadLabel } from '../../sections/Scenarios/Scenario/ScenariosTableHead';
import ScenariosTableToolbar from '../../sections/Scenarios/Scenario/ScenariosTableToolbar';
import { ScenarioTableRowActionMenu } from '../../sections/Scenarios/Scenario/ScenarioTableRowActionMenu';
import { getScenarioNames, hasScenarioEditDeletePermission } from '../../sections/Scenarios/Scenario/utils';

export default function ActionPlanningScenarios() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  // TABLE STATE
  const {
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    order,
    orderBy,
    filterName,
    handleFilterByName,
    handleRequestSort,
  } = useTable({
    defaultOrderBy: 'name',
    defaultRowsPerPage: 25,
  });
  const { checkPortfolioPermission } = usePermissions();

  const [modalOpen, setModalOpen] = useState(false);
  const [filteredScenarios, setFilteredScenarios] = useState<ActionPlanningScenariosScenarioFragment[]>([]);
  const [portfolioFilterOptions, setPortfolioFilterOptions] = useState([{ key: 0, label: t('General_AllPortfolios') }]);
  const [selectedPortfolios, setSelectedPortfolios] = useState<number[]>([0]);

  const displayStandardMutationQueryErrorSnackbar = createStandardErrorSnackbar(t);
  // QUERY
  const { data: scenarioQueryData } = useQuery(GET_SCENARIOS, {
    onError: () => displayStandardMutationQueryErrorSnackbar(enqueueSnackbar),
  });

  const scenarios = useMemo(() => {
    if (!scenarioQueryData) return [];
    /**
     * Filter out scenarios where the portfolio is null which might happen in certain edge cases
     * for details see PRE-4595 here:
     * https://linear.app/predium/issue/PRE-4595/handle-buildings-in-scenarios-correctly-if-the-get-moved-out-of-a
     */
    const portfolioOptions = [{ key: 0, label: t('General_AllPortfolios') }];
    const scenariosWithoutNull = scenarioQueryData.scenario.filter((s) => s.portfolio);

    scenariosWithoutNull.forEach((s) => {
      if (!portfolioOptions.some((option) => option.key === s.portfolio.id)) {
        portfolioOptions.push({ key: s.portfolio.id, label: s.portfolio.name ?? '' });
      }
    });

    setPortfolioFilterOptions(portfolioOptions);
    setSelectedPortfolios((prevSelected) => {
      const validSelections = prevSelected.filter((id) => portfolioOptions.some((option) => option.key === id));
      return validSelections.length > 0 ? validSelections : [0];
    });

    return scenariosWithoutNull;
  }, [scenarioQueryData, t]);

  useEffect(() => {
    if (selectedPortfolios.length === 1 && selectedPortfolios[0] === 0) {
      setFilteredScenarios(scenarios);
    } else {
      setFilteredScenarios(
        scenarios.filter((scenario) => selectedPortfolios.some((id) => scenario.portfolio?.id === id)),
      );
    }
  }, [selectedPortfolios, scenarios]);

  //TABLE
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - scenarios.length) : 0;

  const filteredRows = applySortFilter({
    data: filteredScenarios,
    nameFilter: {
      fieldName: 'name',
      filterValue: filterName,
    },
    orderOptions: {
      order,
      orderBy,
    },
  });

  const isNotFound = !filteredRows.length && Boolean(filterName);

  const handleRowClick = (id: number) => {
    navigate(PATHS.actionPlanning.scenario({ id }));
  };

  const tableHead: HeadLabel[] = [
    { id: 'name', label: t('General_Name'), minWidth: 300 },
    // FIXME: Sorting is broken because nested properties on the object want to be compared. Disabled for now.
    { id: 'portfolio', label: t('General_Portfolio'), sortingDisabled: true, minWidth: 200 },
    { id: 'owner', label: t('General_CreatedBy'), sortingDisabled: true, minWidth: 200 },
    { id: 'actions', label: '', sortingDisabled: true, minWidth: 200 },

    // { id: 'last_updated', label: 'Letzte Änderung', format: 'number' },
  ];

  const { tempSelection, handleSelectionChange, applySelection, clearSelection, isApplyDisabled, isClearDisabled } =
    useMultiSelectFilter<number>(setSelectedPortfolios, 0);

  const scenariosNames = useCallback((scenarioId?: number) => getScenarioNames(scenarios, scenarioId), [scenarios]);

  return (
    <Page title={t('PageName_Scenarios')}>
      <Container maxWidth="lg">
        <HeaderBreadcrumbs
          heading={t('PageName_Scenarios')}
          links={[]}
          infoText={t('ActionPlanningScenarios_ScenarioInfoText')}
        />
        <Card>
          <ScenariosTableToolbar
            numSelected={0}
            onFilterName={handleFilterByName}
            filterName={filterName}
            onCreateScenario={() => {
              setModalOpen(true);
            }}
            portfolioOptions={portfolioFilterOptions
              .filter((portfolio) => portfolio.key !== 0)
              .map((portfolio) => ({
                name: portfolio.label,
                value: portfolio.key,
              }))}
            tempSelection={tempSelection}
            handleSelectionChange={handleSelectionChange}
            applySelection={applySelection}
            clearSelection={clearSelection}
            isApplyDisabled={isApplyDisabled}
            isClearDisabled={isClearDisabled}
            selectedPortfolios={selectedPortfolios}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <ScenariosTableHead
                  numSelected={0}
                  order={order}
                  orderBy={orderBy}
                  headLabel={tableHead}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { id, name } = row;
                    return (
                      <TableRowWithHighlight
                        hover
                        key={id}
                        role="checkbox"
                        sx={{ cursor: 'pointer' }}
                        onClick={() => handleRowClick(row.id)}
                        // @ts-ignore
                        background={hasRecentlyChanged(row.created_at ?? row.updated_at) ? 'success' : 'default'}
                      >
                        <TableCell>{name}</TableCell>
                        <TableCell>{row.portfolio?.name}</TableCell>
                        <TableCell>
                          {/*@ts-ignore */}
                          <InlineUser firstName={row.owner.first_name} lastName={row.owner.last_name} size={25} />
                        </TableCell>

                        <TableCell
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          align="right"
                        >
                          <ScenarioTableRowActionMenu
                            scenario={row}
                            hasActionPlans={row.action_plans.length > 0}
                            canEditOrDelete={hasScenarioEditDeletePermission(row, checkPortfolioPermission)}
                            otherScenarioNames={scenariosNames(row.id)}
                          />
                        </TableCell>
                      </TableRowWithHighlight>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {scenarios?.length === 0 && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Unavailable
                          iconString="gridicons:create"
                          title={t('ActionPlanningScenarios_NoScenarioAvailable')}
                          subTitle={t('ActionPlanningScenarios_NoScenarioAvailableInfo')}
                          onClick={() => {
                            setModalOpen(true);
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}

                {isNotFound && scenarios?.length > 0 && (
                  <TableBody>
                    <SearchNotFound searchQuery={filterName} />
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePaginationStandard
            count={filteredRows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
          />
        </Card>
        <PreDialog
          type="definedByChildren"
          open={modalOpen}
          onClose={() => {
            setModalOpen(false);
          }}
          maxWidth="sm"
          fullWidth
        >
          <CreateScenarioModal
            onClose={() => {
              setModalOpen(false);
            }}
            scenariosNames={scenariosNames()}
          />
        </PreDialog>
      </Container>
    </Page>
  );
}
