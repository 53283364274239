import { Stack, Tooltip } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import { PortfolioManagementPortfolioFragment } from '@predium/client-graphql';
import { COMMON_DATE_FORMATS, formatDateToLocale } from '@predium/utils';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import Label from '../../../components/Label';
import TableMoreMenu from '../../../components/table/TableMoreMenu';
import TableRowWithHighlight, { hasRecentlyChanged } from '../../../components/table/TableRowWithHighlight';
import useOrgPreferences from '../../../hooks/useOrgPreferences';
import { useLanguage } from '../../../provider/LanguageProvider';

type Props = {
  row: PortfolioManagementPortfolioFragment;
  actions: ReactNode;
};

export default function PortfolioTableRow({ actions, row }: Props) {
  const { t } = useTranslation();
  const { language } = useLanguage();
  const { activeCRREMConfiguration, crremConfigurations } = useOrgPreferences();

  const { name, id, created_at } = row;
  const crremConfiguration = crremConfigurations.find((config) => config.isActiveForPortfolio(id));

  return (
    <TableRowWithHighlight
      sx={{ cursor: 'pointer' }}
      hover
      background={
        row.created_at && hasRecentlyChanged(row.created_at)
          ? 'success'
          : row.updated_at && hasRecentlyChanged(row.updated_at)
          ? 'warning'
          : 'default'
      }
    >
      <TableCell>{name}</TableCell>
      <TableCell>
        {crremConfiguration ? (
          crremConfiguration.getTitleElement()
        ) : (
          <Stack direction="row" alignItems={'center'}>
            {activeCRREMConfiguration.getTitleElement()}
            <Tooltip title={t('PortfolioTable_EmissionFactorTypeTitle')} arrow placement="top">
              <Label ml={1}>{t('General_Standard')}</Label>
            </Tooltip>
          </Stack>
        )}
      </TableCell>
      <TableCell>
        {created_at && formatDateToLocale(created_at, COMMON_DATE_FORMATS.DAY_MONTH_YEAR, language)}
      </TableCell>
      <TableCell align="right">
        <TableMoreMenu actions={actions} />
      </TableCell>
    </TableRowWithHighlight>
  );
}
