import { useCallback, useEffect, useRef, useState } from 'react';

/**
 * Custom Hook to detect text overflow and trigger tooltips accordingly.
 *
 * This hook provides a mechanism to detect if text content overflows its container.
 * It stores a reference for each item's corresponding DOM element and determines if
 * the content is truncated (i.e., scroll width is greater than client width).
 *
 * @param {Array<{ value: string | number }>} items - List of items that need overflow detection.
 * @returns {{
 *   textRefs: Record<string, HTMLDivElement | null>,
 *   truncatedStates: Record<string, boolean>,
 *   startObserving: () => void,
 *   stopObserving: () => void
 * }}
 *   - `textRefs`: A ref object to be attached to each text container.
 *   - `truncatedStates`: A record of items indicating whether each one is truncated.
 *   - `startObserving`: Function to start observing DOM mutations for overflow recalculation.
 *   - `stopObserving`: Function to stop mutation observation.
 */
export function useOverflowDetection(items: { value: string | number }[]) {
  // Stores references to text elements
  const textRefs = useRef<Record<string, HTMLDivElement | null>>({});

  // Tracks whether each item is truncated
  const [truncatedStates, setTruncatedStates] = useState<Record<string, boolean>>({});

  // Stores a MutationObserver instance
  const observerRef = useRef<MutationObserver | null>(null);

  /**
   * Function to check if text overflows its container and update the state accordingly.
   */
  const checkOverflow = useCallback(() => {
    const newTruncatedStates: Record<string, boolean> = {};
    let hasChanges = false;

    items.forEach((item) => {
      const element = textRefs.current[item.value];
      if (element) {
        const isTruncated = element.scrollWidth > element.clientWidth;
        newTruncatedStates[item.value] = isTruncated;

        // Update state only if there are changes
        if (truncatedStates[item.value] !== isTruncated) {
          hasChanges = true;
        }
      }
    });

    // Only update state if there are changes, preventing unnecessary re-renders
    if (hasChanges) {
      setTruncatedStates((prev) => ({
        ...prev,
        ...newTruncatedStates,
      }));
    }
  }, [items, truncatedStates]);

  /**
   * Starts observing DOM changes to detect layout shifts affecting text truncation.
   */
  const startObserving = () => {
    if (!observerRef.current) {
      observerRef.current = new MutationObserver(() => checkOverflow());
      observerRef.current.observe(document.body, {
        childList: true,
        subtree: true,
      });
    }
  };

  /**
   * Stops observing DOM mutations to optimize performance.
   */
  const stopObserving = () => {
    if (observerRef.current) {
      observerRef.current.disconnect();
      observerRef.current = null;
    }
  };

  // Run overflow check on window resize
  useEffect(() => {
    window.addEventListener('resize', checkOverflow);

    return () => {
      window.removeEventListener('resize', checkOverflow);
      stopObserving();
    };
  }, [checkOverflow]);

  return { textRefs, truncatedStates, startObserving, stopObserving };
}
