import Iconify from '../../components/Iconify';
import { ICONS } from '../icons';

export const EditIcon = ({ text }: { text?: string }) => {
  if (!text) {
    return <Iconify icon={ICONS.EDIT} width={18} height={18} />;
  }

  return (
    <>
      <Iconify icon={ICONS.EDIT} width={18} height={18} /> {text}
    </>
  );
};
