import sum from 'lodash/sum';
import { TARGET_PATH_YEARS_RANGE } from './targetPaths/TargetPathConstants';

export const YEAR_RANGE = TARGET_PATH_YEARS_RANGE;
export const SUPPORTED_YEARS = [2020, 2021, 2022, 2023];
export const DEFAULT_DISCOUNT_RATE = 0.036;

export function getDiscountedCost(list: number[], discountRate: number): number {
  return applyDiscountRate(
    list.map((v) => Math.max(v, 0)),
    discountRate,
  );
}

export function getDiscountedValue(list: number[], discountRate: number): number {
  const discountedValue = applyDiscountRate(
    list.map((v) => Math.min(v, 0)),
    discountRate,
  );
  return discountedValue === 0 ? 0 : -discountedValue;
}

export function applyDiscountRate(list: number[], discountRate: number): number {
  if (list.length !== YEAR_RANGE.length) {
    list = Array(YEAR_RANGE.length - list.length)
      .fill(0)
      .concat(list);
  }
  const [first, ...rest] = list;
  return first + sum(rest.map((v, idx) => v / Math.pow(1 + discountRate, idx + 2)));
}
