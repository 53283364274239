import { CircularProgress, InputAdornment, MenuItem, TextField, TextFieldProps, useTheme } from '@mui/material';
import { useMeasureOnResizeWidth } from '../sections/Scenarios/Scenario/hooks';
import { TypographyEllipsis } from './typography/TypographyEllipsis';

export type SimpleSelectValue<TId extends number | string> = {
  id: TId;
  name: string;
  isPlaceholder?: boolean;
};

interface Props<TId extends number | string> {
  values: SimpleSelectValue<TId>[];
  initialId: number | string;
  onChange: (newId: number) => void;
  selectType?: 'string' | 'number';
  isLoading?: boolean;
  isHidden?: boolean;
}

export default function SimpleSelect<TId extends number | string>({
  values,
  initialId,
  onChange,
  selectType = 'number',
  isLoading = false,
  isHidden = false,
  ...props
}: Props<TId> & Omit<TextFieldProps, 'onChange'>) {
  const theme = useTheme();
  const [textfieldRef, textfieldWidth] = useMeasureOnResizeWidth(100);

  return (
    <TextField
      ref={textfieldRef}
      select
      variant="outlined"
      value={initialId}
      sx={{
        width: '100%',
        '& .MuiFormLabel-root': {
          backgroundColor: theme.palette.common.white,
          px: 0.5,
        },
        display: isHidden ? 'none' : 'inherit',
        ...props.sx,
      }}
      {...props}
      onChange={(event) => {
        const value = event.target.value;
        onChange(parseInt(value));
      }}
      InputProps={{
        startAdornment: isLoading ? (
          <InputAdornment position="start">
            <CircularProgress size={25} />
          </InputAdornment>
        ) : undefined,
      }}
      size={'small'}
    >
      {values.map((value) => (
        <MenuItem key={value.id} value={value.id} disabled={value.isPlaceholder ?? false}>
          <TypographyEllipsis
            variant={'body1'}
            color={'text.primary'}
            maxWidth={textfieldWidth - 50}
            title={value.name}
          >
            {value.name}
          </TypographyEllipsis>
        </MenuItem>
      ))}
    </TextField>
  );
}
