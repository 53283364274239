import { MenuItem, Stack } from '@mui/material';
import { t } from 'i18next';
import React, { useState } from 'react';
import { ICONS } from '../../../../assets/icons';
import Iconify from '../../../../components/Iconify';
import ActionMoreMenu from '../../../../components/common/ActionMoreMenu';
import ActionPlanExportPdf from './ActionPlanExportPdf';
import DeleteActionPlanModal from './DeleteActionPlanModal';

export type ActionPlanType = {
  id: number;
  buildingAddress: {
    street: string;
    postalCode: string;
    city: string;
  };
  totalActions: number;
  scenarioId: number;
};

type Props = {
  viewType?: 'table' | 'list';
  hasActionPlanDeletePermission: boolean;
  actionPlan: ActionPlanType;
};

const ActionPlanActionMenu: React.FC<Props> = ({ actionPlan, hasActionPlanDeletePermission }) => {
  const [deleteActionPlanModalOpen, setDeleteActionPlanModalOpen] = useState(false);
  const [exportActionPlanModalOpen, setExportActionPlanModalOpen] = useState(false);

  return (
    <>
      <Stack direction="row" gap={2}>
        <ActionMoreMenu
          variant="icon"
          actions={
            <>
              <MenuItem onClick={() => setExportActionPlanModalOpen(true)} disabled={actionPlan.totalActions === 0}>
                <Iconify icon={ICONS.PDF_EXPORT} width={18} height={18} />
                {t('ActionPlanning_ActionPlan-ExportAsPdfButton')}
              </MenuItem>

              {hasActionPlanDeletePermission && (
                <MenuItem
                  onClick={() => {
                    setDeleteActionPlanModalOpen(true);
                  }}
                  sx={{ color: 'error.main' }}
                >
                  <Iconify icon={ICONS.TRASH} width={18} height={18} />
                  {t('General_Delete')}
                </MenuItem>
              )}
            </>
          }
        />
      </Stack>

      {exportActionPlanModalOpen && (
        <ActionPlanExportPdf
          open={exportActionPlanModalOpen}
          handleClose={() => setExportActionPlanModalOpen(false)}
          actionPlan={actionPlan}
        />
      )}

      <DeleteActionPlanModal
        deleteModalOpen={deleteActionPlanModalOpen}
        setDeleteModalOpen={() => setDeleteActionPlanModalOpen(false)}
        actionPlan={actionPlan}
      />
    </>
  );
};

export default ActionPlanActionMenu;
