import { MenuItem } from '@mui/material';
import { ActionPlanningScenariosScenarioFragment } from '@predium/client-graphql';
import { useTranslation } from 'react-i18next';
import { DeleteIcon } from '../../../assets/Icons/DeleteIcon';
import { EditIcon } from '../../../assets/Icons/EditIcon';
import { ExportIcon } from '../../../assets/Icons/ExportIcon';
import ActionMoreMenu from '../../../components/common/ActionMoreMenu';
import DeleteScenarioDialog from './DeleteScenarioDialog';
import EditScenarioDialog from './EditScenarioDialog';
import { useDeleteScenarioDialog, useEditScenarioDialog, useScenarioDownload } from './hooks';

type Props = {
  scenario: ActionPlanningScenariosScenarioFragment;
  hasActionPlans: boolean;
  canEditOrDelete: boolean;
  otherScenarioNames: string[];
};

export const ScenarioTableRowActionMenu = ({
  scenario,
  hasActionPlans,
  canEditOrDelete,
  otherScenarioNames,
}: Props) => {
  const { t } = useTranslation();

  const [isEditScenarioDialogOpen, handleEditScenarioClick] = useEditScenarioDialog();

  const [handleScenarioDownload, isLoading] = useScenarioDownload(scenario.name, scenario.id);

  const [isDeleteScenarioDialogOpen, handleDeleteScenarioClick, scenarioToDelete] = useDeleteScenarioDialog(scenario);

  if (!canEditOrDelete && !hasActionPlans) {
    return null;
  }

  return (
    <>
      <ActionMoreMenu
        ariaLabel={t('ActionPlanningScenario_MoreActions')}
        variant="icon"
        isLoading={isLoading}
        actions={
          <>
            {canEditOrDelete ? (
              <MenuItem onClick={handleEditScenarioClick}>
                <EditIcon text={t('General_Edit')} />
              </MenuItem>
            ) : undefined}
            {hasActionPlans ? (
              <MenuItem onClick={handleScenarioDownload}>
                <ExportIcon text={t('ActionPlanningScenario_ExportAsExcel')} />
              </MenuItem>
            ) : undefined}
            {canEditOrDelete ? (
              <MenuItem onClick={handleDeleteScenarioClick} sx={{ color: 'error.main' }}>
                <DeleteIcon text={t('General_Delete')} />
              </MenuItem>
            ) : undefined}
          </>
        }
      />
      <EditScenarioDialog
        isOpen={isEditScenarioDialogOpen}
        handleOnClose={handleEditScenarioClick}
        scenario={scenario}
        scenariosNames={otherScenarioNames}
      />
      <DeleteScenarioDialog
        deleteModalOpen={isDeleteScenarioDialogOpen}
        handleDeleteScenarioClick={handleDeleteScenarioClick}
        scenario={scenarioToDelete}
      />
    </>
  );
};
