import { useQuery } from '@apollo/client';
import { Stack, Typography } from '@mui/material';
import { api_response_status_enum } from '@predium/enums';
import { COMMON_DATE_FORMATS, ensureDefined, formatDateToLocale } from '@predium/utils';
import { Trans, useTranslation } from 'react-i18next';
import { ICONS } from '../../../../assets/icons';
import CenterBox from '../../../../components/common/CenterBox';
import DetailedTooltip from '../../../../components/DetailedTooltip/DetailedTooltip';
import { DelayedLoading } from '../../../../components/Loading';
import { GET_CLIMATE_RISK_V2_REPORT } from '../../../../graphql/DataCollection.queries';
import useSessionData from '../../../../hooks/useSessionData';
import { useLanguage } from '../../../../provider/LanguageProvider';
import useBuilding from '../Context/useBuilding';
import ClimateRiskV2Body from './ClimateRiskV2Body';
import ExportClimateRiskDialog from './components/ExportClimateRiskDialog';
import NoClimateRiskReport from './components/NoClimateRiskReport';

const ClimateRiskV2 = () => {
  const { building } = useBuilding();
  const { t } = useTranslation();
  const { language } = useLanguage();
  const {
    serverSideFeatureFlags: { CLIMATE_RISK_V2_MOCK },
  } = useSessionData();

  const hasReport = !!building.climate_risks_v2_aggregate.aggregate?.count || CLIMATE_RISK_V2_MOCK;

  const { data, loading } = useQuery(GET_CLIMATE_RISK_V2_REPORT, {
    skip: !hasReport,
    variables: {
      buildingId: building.id,
    },
    fetchPolicy: 'network-only',
  });

  if (!hasReport) {
    return <NoClimateRiskReport />;
  }

  if (loading || !data) {
    return (
      <CenterBox height={400}>
        <DelayedLoading />
      </CenterBox>
    );
  }

  if (!data.readClimateRiskV2Report.success) {
    throw new Error(`API error - building id: ${building.id}`);
  }

  const report = ensureDefined(data.readClimateRiskV2Report.data);

  if (report.status === api_response_status_enum.PENDING) {
    return (
      <CenterBox height={400}>
        <DelayedLoading />
      </CenterBox>
    );
  }

  // report is not completed, means that the report is failed or something went wrong
  // We shouldn't reach this point
  if (report.status !== api_response_status_enum.COMPLETED) {
    throw new Error(`Report is not completed - building id: ${building.id}`);
  }

  const createdAt = ensureDefined(report.createdAt);
  const hasValuation = building.building_valuations.length > 0;

  return (
    <Stack spacing={3}>
      <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
        <Stack spacing={1}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="h5">{t('ClimateRiskV2_Header_Title')}</Typography>
            <DetailedTooltip
              labels={{
                title: t('ClimateRiskV2_Header_TooltipTitle'),
                preBody: (
                  <Trans
                    i18nKey="ClimateRiskV2_Header_TooltipBody"
                    components={{
                      br: <br />,
                    }}
                  />
                ),
              }}
              size={16}
              slotsProps={{
                icon: {
                  icon: ICONS.INFO_CONTAINED,
                  color: 'text.primary',
                },
                tooltip: {
                  sx: {
                    '& .MuiTooltip-tooltip': {
                      maxWidth: '420px',
                    },
                  },
                },
              }}
            />
          </Stack>
          <Typography variant="body1" color="text.secondary">
            {t('ClimateRiskV2_Header_Description', {
              date: formatDateToLocale(createdAt, COMMON_DATE_FORMATS.DAY_MONTH_YEAR, language),
            })}
          </Typography>
        </Stack>
        <ExportClimateRiskDialog reportFiles={report.files} />
      </Stack>
      <ClimateRiskV2Body scenarios={report.scenarios} hasValuation={hasValuation} />
    </Stack>
  );
};

export default ClimateRiskV2;
