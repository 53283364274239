import { useTheme } from '@mui/material/styles';
import { translateUnitEnum_dynamic } from '@predium/i18n/client';
import { getFilename, Localize, Units } from '@predium/utils';
import { TFunction } from 'i18next';
import merge from 'lodash/merge';
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../../../../provider/LanguageProvider';
import BaseOptionChart from '../../../../theme/apexcharts';
import addCustomCSVExportButtonToChartToolbar from '../../../../utils/addCustomCSVExportButtonToChartToolbar';
import { toCsvContent } from '../../../../utils/toCsvContent';

function getCsvContent(
  dataToExport: { name: string; data: number[] }[],
  categories: number[],
  t: TFunction<'translation', undefined>,
  localize: Localize,
  translatedUnit: string,
) {
  const yearHeading = t('General_Year');
  const costHeading = t('General_Investments');

  // Generate CSV rows
  const csvRows = [
    [yearHeading, `${costHeading} (${translatedUnit})`],
    ...categories.map((year, index) => [year, ...dataToExport.map((item) => localize.formatAsFloat(item.data[index]))]),
  ];

  return toCsvContent(csvRows);
}

type YearlyInvestmentChartProps = {
  categories: number[];
  series: { name: string; data: number[] }[];
  isDataAvailable?: boolean;
  isHidden?: boolean;
};

const YearlyInvestmentChart: React.FC<YearlyInvestmentChartProps> = ({
  categories,
  series,
  isDataAvailable,
  isHidden = false,
}) => {
  const theme = useTheme();
  const { localize, language } = useLanguage();
  const { t } = useTranslation();

  const filename = getFilename([series[0].name], language);
  const translatedUnit = translateUnitEnum_dynamic(Units.price, t);

  const chartOptions = merge(BaseOptionChart(), {
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        enabled: isDataAvailable,
        export: {
          svg: { filename },
          png: { filename },
        },
      },
      events: {
        mounted: function (chart: any) {
          addCustomCSVExportButtonToChartToolbar(
            chart.el.id,
            getCsvContent(series, categories, t, localize, translatedUnit),
            filename,
          );
        },
        updated: function (chart: any) {
          addCustomCSVExportButtonToChartToolbar(
            chart.el.id,
            getCsvContent(series, categories, t, localize, translatedUnit),
            filename,
          );
        },
      },
    },
    plotOptions: {
      bar: {
        columnWidth: '60%',
        borderRadius: 4,
        borderRadiusApplication: 'end',
        dataLabels: {
          position: 'top',
        },
      },
    },
    colors: [theme.palette.scenarioImpactGraphColors.yearlyCost],
    dataLabels: {
      enabled: false,
      formatter: function (val: number) {
        return localize.formatAsCurrency(val, { displayCompact: true });
      },
      offsetY: -20,
      style: {
        fontSize: '12px',
        colors: [theme.palette.text.secondary],
      },
    },
    xaxis: {
      categories,
      title: {
        text: t('General_Year'),
        style: {
          fontSize: '12px',
          color: theme.palette.text.secondary,
          fontWeight: 500,
        },
      },
      labels: {
        formatter: function (val: number) {
          return Math.round(val);
        },
      },
      tickAmount: 15,
      ...(!isDataAvailable
        ? {
            categories: Array.from({ length: 31 }, (_, i) => 2020 + i),
            tickAmount: 15,
          }
        : {}),
    },
    yaxis: {
      title: {
        text:
          t('General_Investments', {
            count: 1,
          }) + ` (${translatedUnit})`,
        style: {
          fontSize: '12px',
          color: theme.palette.text.secondary,
          fontWeight: 500,
        },
      },
      min: 0,
      ...(!isDataAvailable
        ? {
            tickAmount: 5,
          }
        : {}),
      labels: {
        formatter: function (val: number) {
          return localize.formatAsCompact(val);
        },
      },
    },
    tooltip: {
      y: {
        formatter: function (val: number) {
          return localize.formatAsCurrency(val, { displayCompact: true });
        },
      },
    },
    noData: {
      text: t('General_NoData'),
      offsetY: -30,
      style: {
        fontSize: '14px',
      },
    },
  });

  return (
    <ReactApexChart
      id="yearly_investment_graph"
      type="bar"
      series={series}
      options={chartOptions}
      height={350}
      style={{ display: isHidden ? 'none' : 'inherit' }}
    />
  );
};

export default YearlyInvestmentChart;
