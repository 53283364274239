import { Box, Grid, Stack, Typography } from '@mui/material';
import { TARGET_PATH_LAST_YEAR } from '@predium/client-lookup';
import { translateUnitEnum_dynamic } from '@predium/i18n/client';
import { Units } from '@predium/utils';
import isEqual from 'lodash/isEqual';
import { memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import InfoTooltip from '../../../../components/InfoTooltip';
import { useLanguage } from '../../../../provider/LanguageProvider';
import BenchmarkLabel from '../../Components/BenchmarkLabel';
import { Co2PathGraph } from '../../Visualization/EsgPathGraphs/Co2PathGraph';

type Props = {
  co2Path: number[];
  co2TargetPath: number[];
  buildingLegendName: string;
  strandingYear?: number | null;
  cumulativeExcessEmissions: number | undefined;
  graphStartYear: number;
  benchmark: {
    selectedBenchmarkName: string;
    strandingYearChange: number | null;
  };
};

function Co2CrremAnalysis({
  co2Path,
  co2TargetPath,
  buildingLegendName,
  strandingYear,
  cumulativeExcessEmissions,
  graphStartYear,
  benchmark: { selectedBenchmarkName, strandingYearChange },
}: Props) {
  const { t } = useTranslation();
  const { localize } = useLanguage();

  const isDataAvailable = co2Path && co2Path.length > 0;

  const excessiveEmissionsValue =
    isDataAvailable && cumulativeExcessEmissions !== undefined ? cumulativeExcessEmissions / 1000 : null;
  const formattedExcessiveEmissions = localize.formatAsCompact(excessiveEmissionsValue, {
    fractionDigits: (excessiveEmissionsValue ?? 0) <= 1_000 ? 0 : 1,
  });

  return (
    <Grid container>
      <Grid container>
        <Grid item xs={12} md={2} lg={2}>
          <Box>
            <Box mb={4}>
              <Typography variant="subtitle1" sx={{ mr: 1 }}>
                {t('General_StrandingDate')}
                <InfoTooltip
                  text={
                    <Trans
                      i18nKey={'EsgAnalysis_Co2CrremAnalysis_StrandingDateTooltip'}
                      components={{
                        bold: <strong />,
                        list: <ul style={{ paddingLeft: '1rem', margin: 0 }} />,
                        item: <li style={{ paddingLeft: '0.5rem' }} />,
                        // Link will not be shown until  <articleLink /> is in the Tooltip text
                        // Leaving it here for future use once the proper article is created.
                        // TODO: update the tooltip text once the article is created PRE-6000
                      }}
                    />
                  }
                  maxwidth={370}
                  sx={{ ml: 1, p: 0, svg: { height: 20, width: 20 } }}
                />
              </Typography>
              <Typography variant="h3" sx={{ mr: 1 }}>
                {isDataAvailable ? strandingYear ?? t('General_ParisReadyLabel') : '–'}
              </Typography>
              {isDataAvailable && selectedBenchmarkName && (
                <BenchmarkLabel
                  change={strandingYearChange}
                  selectedBenchmarkName={selectedBenchmarkName}
                  unit={Units.year}
                  inverse
                />
              )}
            </Box>
            <Stack>
              <Stack direction={'row'} alignItems={'center'}>
                <Typography variant="subtitle1" sx={{ mr: 1 }}>
                  {t('EsgAnalysis_CrremAnalysis-CumulativeExcessEmissions')}
                  <InfoTooltip
                    text={t('EsgAnalysis_Co2CrremAnalysis_ExcessEmissionsTooltip')}
                    sx={{ ml: 1, p: 0, svg: { height: 20, width: 20 } }}
                  ></InfoTooltip>
                </Typography>
              </Stack>
              <Stack direction={'row'} alignItems={'baseline'} sx={{ flexWrap: 'wrap' }}>
                <Typography variant="h3" sx={{ mr: 1 }}>
                  {formattedExcessiveEmissions}
                </Typography>
                <Typography variant="subtitle2" color={'text.secondary'}>
                  {translateUnitEnum_dynamic(Units.excessiveEmissions, t)}
                </Typography>
              </Stack>
              <Typography variant="caption" color={'text.secondary'} sx={{ mt: -1 }}>
                {t('EsgAnalysis_Co2CrremAnalysisUntil2050')}
              </Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={12} md={10} lg={10}>
          <Stack direction={'column'} gap={8}>
            <Co2PathGraph
              co2Path={co2Path}
              co2TargetPath={co2TargetPath}
              buildingLegendName={buildingLegendName}
              graphStartYear={graphStartYear}
              strandingYears={strandingYear ? [{ startYear: strandingYear, endYear: TARGET_PATH_LAST_YEAR }] : []}
            />
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default memo(Co2CrremAnalysis, isEqual);
