import { Card, CardHeader, Divider, Stack, Typography, useTheme } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { ModificationImpactsFragment } from '@predium/client-graphql';
import { Localize, Units } from '@predium/utils';
import { useTranslation } from 'react-i18next';
import { ICONS } from '../../../../../../assets/icons';
import { classifyChange } from '../../../../../../components/data-visialization/WidgetSummary';
import Iconify from '../../../../../../components/Iconify';
import { useLanguage } from '../../../../../../provider/LanguageProvider';

function DifferenceText({ value }: { value: number }) {
  const theme = useTheme();
  const change = classifyChange(value);
  const { localize } = useLanguage();

  if (change === 'neutral') {
    return (
      <Typography variant="body2" fontWeight={700}>
        {localize.formatAsCurrency(0, {
          roundToNearestHundred: true,
          perUnit: Units.year,
        })}
      </Typography>
    );
  }

  const color = change === 'positive' ? theme.palette.success.main : theme.palette.error.main;
  const icon = change === 'positive' ? ICONS.ARROW_DOWN : ICONS.ARROW_UP;
  return (
    <Stack direction="row" alignItems="center" justifyContent="end" spacing={0.5}>
      <Iconify width={16} height={16} icon={icon} color={color} />
      <Typography variant="body2" color={color} fontWeight={700}>
        {localize.formatAsCurrency(Math.abs(value), {
          roundToNearestHundred: true,
          perUnit: Units.year,
        })}
      </Typography>
    </Stack>
  );
}

type RowData = {
  title: string;
  description: string;
  data: { costBefore: number | null; costAfter: number | null };
};

function Row(props: { row: RowData }) {
  const { t } = useTranslation();
  const { localize } = useLanguage();
  const {
    row: {
      title,
      description,
      data: { costBefore, costAfter },
    },
  } = props;
  const formattedCostBefore = localize.formatAsCurrency(costBefore, {
    roundToNearestHundred: true,
    perUnit: Units.year,
  });
  const formattedCostAfter = localize.formatAsCurrency(costAfter, {
    roundToNearestHundred: true,
    perUnit: Units.year,
  });
  const totalDifference =
    costBefore != null && costAfter != null
      ? Localize.roundToNearestHundred(costBefore, true) - Localize.roundToNearestHundred(costAfter, true)
      : 0;

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell component="th" scope="row">
          {`${description} (${t('General_PreModificationShort')})`}
        </TableCell>
        <TableCell align="right">{formattedCostBefore}</TableCell>
        <TableCell sx={{ width: '40px' }}></TableCell>
      </TableRow>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell component="th" scope="row">
          {`${description} (${t('General_PostModificationShort')})`}
        </TableCell>
        <TableCell align="right">{formattedCostAfter}</TableCell>
        <TableCell sx={{ width: '40px' }}></TableCell>
      </TableRow>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell component="th" scope="row">
          <Typography variant="body2" fontWeight={700}>
            {title}
          </Typography>
        </TableCell>
        <TableCell align="right">
          <DifferenceText value={totalDifference} />
        </TableCell>
      </TableRow>
    </>
  );
}

type Props = {
  modificationImpact: ModificationImpactsFragment;
  title: string;
};

export default function ActionPricingTotalSaving({ title, modificationImpact }: Props) {
  const { t } = useTranslation();

  const { co2_cost_total, energy_cost_total } = modificationImpact;

  const energyCostSavings: RowData & { id: string } = {
    id: 'ENERGY_SAVINGS',
    title: t('ActionPricingTotalSaving_AnnualEnergyCostDifference'),
    description: t('General_EnergyCost'),
    data: {
      costBefore: energy_cost_total.before ?? null,
      costAfter: energy_cost_total.after ?? null,
    },
  };
  const co2CostSavings: RowData & { id: string } = {
    id: 'TAX_SAVINGS',
    title: t('ActionPricingTotalSaving_AnnualC02CostDifference'),
    description: t('General_CO2Cost'),
    data: {
      costBefore: co2_cost_total.before ?? null,
      costAfter: co2_cost_total.after ?? null,
    },
  };

  return (
    <Card sx={{ mb: 2, height: '100%' }} key={title}>
      <CardHeader titleTypographyProps={{ variant: 'h6' }} title={title} />
      <TableContainer component={Paper} sx={{ p: 2, minHeight: 80, pb: 0 }}>
        <Table
          sx={{
            '.MuiTableCell-root': {
              p: 'unset',
              pl: 1,
              pb: 1,
            },
          }}
        >
          <TableBody>
            <Row key={energyCostSavings.id} row={energyCostSavings} />
            <TableRow>
              <TableCell colSpan={2}>
                <Divider />
              </TableCell>
            </TableRow>
            <Row key={co2CostSavings.id} row={co2CostSavings} />
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}
