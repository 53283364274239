import { CardContent, CircularProgress, Grid } from '@mui/material';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { PropsWithChildren } from 'react';

type Props = {
  header: string;
  isLoading: boolean;
};

export const CompareScenariosEnergeticMetricsCard = ({ children, header, isLoading }: PropsWithChildren<Props>) => {
  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant={'subtitle1'} sx={{ display: 'inline', paddingRight: 1 }}>
              {header}
            </Typography>
            {isLoading ? <CircularProgress size={16} /> : undefined}
          </Grid>
          {Array.isArray(children) ? (
            children.map((child, index) => {
              return (
                <Grid key={`energetic-metric-item-${index}`} item xs={12}>
                  {child}
                </Grid>
              );
            })
          ) : (
            <Grid item xs={12}>
              {children}
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};
