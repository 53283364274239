import { alpha, Box, Stack, Typography, useTheme } from '@mui/material';
import { TARGET_PATH_FIRST_YEAR, TARGET_PATH_LAST_YEAR } from '@predium/client-lookup';
import { getFilename, Localize, Units } from '@predium/utils';
import { TFunction } from 'i18next';
import merge from 'lodash/merge';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../../../../provider/LanguageProvider';
import BaseOptionChart from '../../../../theme/apexcharts';
import addCustomCSVExportButtonToChartToolbar from '../../../../utils/addCustomCSVExportButtonToChartToolbar';
import { toCsvContent } from '../../../../utils/toCsvContent';

const getCsvContent = (
  data: number[],
  categories: number[],
  t: TFunction<'translation', undefined>,
  localize: Localize,
) => {
  const csvRows = [
    [
      t('General_Year'),
      t('EsgAnalysis_ExcessEmissionCost-AvoidedCost'),
      t('EsgAnalysis_ExcessEmissionCost-IncurredCost'),
    ],
    ...data.map((it, index) => [
      categories[index],
      it < 0 ? localize.formatAsCurrency(it) : 0,
      it > 0 ? localize.formatAsCurrency(it) : 0,
    ]),
  ];

  return toCsvContent(csvRows);
};

type Props = {
  data: number[];
  graphStartYear: number;
};

const ExcessEmissionCost = ({ data, graphStartYear }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { language, localize } = useLanguage();

  const isDataAvailable = data.length > 0;

  const totalYears = TARGET_PATH_LAST_YEAR - graphStartYear;
  const categories = isDataAvailable
    ? Array.from({ length: totalYears + 1 }, (_, i) => graphStartYear + i)
    : Array.from({ length: 31 }, (_, i) => TARGET_PATH_FIRST_YEAR + i);

  const filename = getFilename([t('EsgAnalysis_ExcessEmissionCost-Title')], language);

  const options: ApexCharts.ApexOptions = merge(BaseOptionChart(), {
    chart: {
      type: 'bar',
      height: 350,
      animations: {
        enabled: false,
      },
      events: {
        mounted(chart: any) {
          addCustomCSVExportButtonToChartToolbar(chart.el.id, getCsvContent(data, categories, t, localize), filename);
        },
        updated(chart: any) {
          addCustomCSVExportButtonToChartToolbar(chart.el.id, getCsvContent(data, categories, t, localize), filename);
        },
      },
      toolbar: {
        offsetY: -40,
        export: {
          svg: {
            filename,
          },
          png: {
            filename,
          },
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        barHeight: '80%',
        borderRadius: 8,
        borderRadiusApplication: 'end',
        colors: {
          ranges: [
            {
              from: -Infinity,
              to: 0,
              color: theme.palette.excessEmissionCostColors.value,
            },
            {
              from: 0,
              to: Infinity,
              color: theme.palette.excessEmissionCostColors.cost,
            },
          ],
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      title: {
        text: t('General_Year', { count: 1 }),
        style: {
          fontSize: '12px',
          color: theme.palette.text.secondary,
          fontWeight: 500,
        },
        offsetY: -10,
      },
      categories: categories,
      labels: {
        formatter: (value: number, timestamp: number) => (timestamp % 2 === 0 ? value : ''),
      },
    },
    yaxis: {
      labels: {
        formatter: (value: number) => localize.formatAsCompact(value, { fractionDigits: 0 }),
      },
    },
    crosshairs: {
      enabled: false,
    },

    tooltip: {
      enabled: true,
      shared: false,

      custom: function ({
        series,
        seriesIndex,
        dataPointIndex,
      }: {
        series: number[][];
        seriesIndex: number;
        dataPointIndex: number;
        w: any;
      }) {
        const xValue = categories[dataPointIndex];
        const yValue = series[seriesIndex][dataPointIndex];
        return `
        <div  class="apexcharts-custom-tooltip" style="font-size:12px;">
         <div>
            <span>${t('General_Year')}: <strong>${xValue}</strong></span>
            </div>
            <div>
              <span>${
                yValue > 0
                  ? t('EsgAnalysis_ExcessEmissionCost-IncurredCost')
                  : t('EsgAnalysis_ExcessEmissionCost-AvoidedCost')
              }: <strong>${localize.formatAsCurrency(yValue, {
          displayCompact: true,
          perUnit: Units.year,
        })}</strong></span>
            </div>
        </div>
        `;
      },
      legend: {
        show: false,
      },
    },
    states: {
      hover: {
        filter: {
          type: 'none',
        },
      },
      active: {
        filter: {
          type: 'none',
        },
      },
    },
    noData: {
      text: t('General_NoDataAvailable'),
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: -40,
      style: {
        color: theme.palette.text.secondary,
        fontSize: '12px',
        fontFamily: undefined,
      },
    },
  });

  const series = [
    {
      name: '',
      data,
    },
  ];

  return (
    <Box>
      <ReactApexChart id="excess_emission_cost_graph" options={options} series={series} type="bar" height={350} />
      <Stack alignItems={'center'} justifyContent={'center'} direction={'row'}>
        <Stack direction={'row'} alignItems={'center'} mr={2}>
          <Box
            sx={{
              width: 12,
              height: 12,
              backgroundColor: alpha(theme.palette.success.light, 0.8),
              mr: 1,
            }}
          />
          <Typography variant="body2">{t('EsgAnalysis_ExcessEmissionCost-AvoidedCost')}</Typography>
        </Stack>
        <Stack direction={'row'} alignItems={'center'}>
          <Box
            sx={{
              width: 12,
              height: 12,
              backgroundColor: alpha(theme.palette.error.light, 0.8),
              mr: 1,
            }}
          />
          <Typography variant="body2">{t('EsgAnalysis_ExcessEmissionCost-IncurredCost')}</Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

export default ExcessEmissionCost;
