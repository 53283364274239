import { useQuery } from '@apollo/client';
import { Card, Grid } from '@mui/material';
import { pathValueAtYear } from '@predium/client-lookup';
import { energy_source_type_enum } from '@predium/enums';
import { Units } from '@predium/utils';
import sum from 'lodash/sum';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DelayedLoading } from '../../../components/Loading';
import SimpleComparisonMetric from '../../../components/SimpleComparisonMetric';
import Unavailable from '../../../components/Unavailable';
import CenterBox from '../../../components/common/CenterBox';
import BuildingsByEnergySourceType from '../../../components/data-visialization/esg-analysis/BuildingsByEnergySourceTypeGraph';
import CO2CostDevelopmentGraph from '../../../components/data-visialization/esg-analysis/CO2CostDevelopmentGraph';
import EnergyCostDevelopmentGraph from '../../../components/data-visialization/esg-analysis/EnergyCostDevelopmentGraph';
import EnergySourceConsumptionGraph from '../../../components/data-visialization/esg-analysis/EnergySourceConsumptionGraph';
import EuTaxonomyGraph from '../../../components/data-visialization/esg-analysis/EuTaxonomyGraph';
import { MemoizedPortfolioBuildingDistributionByCO2CostGraph } from '../../../components/data-visialization/esg-analysis/MemoizedPortfolioBuildingDistributionByCO2CostGraph';
import { MemoizedPortfolioBuildingDistributionByEfficiencyClassGraph } from '../../../components/data-visialization/esg-analysis/MemoizedPortfolioBuildingDistributionByEfficiencyClassGraph';
import Scope123Graph from '../../../components/data-visialization/esg-analysis/Scope123Graph';
import { GET_BENCHMARK } from '../../../graphql/EsgAnalysis.queries';
import useEsgAnalysisFilters from '../../../hooks/useEsgAnalysisFilters';
import { PATHS } from '../../../routes';
import { DataCollectionTabActionsEnum, DataCollectionTabEnum } from '../../DataCollection/Buildings/TabsEnums';
import { determineStartYear } from '../../Scenarios/ActionPlan/ActionPlan.utils';
import { BenchmarkType, ENTIRE_COMPANY } from '../Components/EsgAnalysisBenchmark';
import CrremAnalysis from '../DemandAnalysis/CrremAnalysis';
import { EsgAnalysisFiltersKeys } from '../EsgAnalysisFilters/EsgAnalysisFilters';
import AreaBubbleChart from '../Visualization/AreaBubbleChart';
import { CurrentPageView } from './PortfolioAnalysisHeader';

type Props = {
  showTotal: boolean;
  currentPageView?: CurrentPageView;
  selectedBenchmark: BenchmarkType;
};

export default function EsgAnalysisSection({ showTotal, currentPageView = 'esg-portfolio', selectedBenchmark }: Props) {
  const { t } = useTranslation();
  const { setFilter, isFilterDataLoading, analyzedBuildings } = useEsgAnalysisFilters();
  const navigate = useNavigate();

  const currentYear = new Date().getFullYear();

  const {
    data,
    previousData,
    loading: benchmarkLoading,
    error,
  } = useQuery(GET_BENCHMARK, {
    variables: {
      portfolioId: selectedBenchmark.id ?? ENTIRE_COMPANY,
    },
  });

  const handleEnergySourceSelect = useCallback(
    (value: energy_source_type_enum) => {
      setFilter(EsgAnalysisFiltersKeys.energySourceTypes, value);
    },
    [setFilter],
  );

  const selectedBenchmarkName = selectedBenchmark.name;

  if ((benchmarkLoading || isFilterDataLoading || !data || !analyzedBuildings) && !error) {
    return (
      <CenterBox height={'50vh'}>
        <DelayedLoading delay={0} />
      </CenterBox>
    );
  }

  const benchmark = data?.getBenchmark ?? previousData?.getBenchmark;

  if (!benchmark || !analyzedBuildings || error) {
    return (
      <CenterBox height={'50vh'}>
        <Unavailable
          title={t('General_BuildingsUnavailable-title')}
          subTitle={t('ESGAnalysisSection_AddFirstBuildingToPortfolio')}
          onClick={() =>
            navigate(
              PATHS.dataCollection.buildings(null, {
                tab: DataCollectionTabEnum.ENERGY_CERTIFICATE_DRAFTS,
                action: DataCollectionTabActionsEnum.CREATE_ENERGY_CERTIFICATE_DRAFT,
              }),
            )
          }
        />
      </CenterBox>
    );
  }

  const calculateBenchmarkChange = (value: number, benchmarkValue?: number) => {
    if (!benchmarkValue) return null;
    return (1 - value / benchmarkValue) * -1;
  };

  if (analyzedBuildings.analyzedBuildingsCount === 0) {
    return (
      <CenterBox height={'50vh'}>
        <Unavailable
          title={t('ESGAnalysisSection_FilterNotApplicable')}
          subTitle={t('ESGAnalysisSection_ChangeFilterToStartAnalysis')}
        />
      </CenterBox>
    );
  }

  const constructionYear = Math.min(...analyzedBuildings.buildingsGeneralData.map((building) => building.year));

  const series = analyzedBuildings?.floorAreaBySubBuildingClass.map((group) => group.area);
  const totalArea = sum(series);

  const co2EmissionsChange = calculateBenchmarkChange(
    analyzedBuildings.averageCo2Emissions,
    benchmark?.averageCo2Emissions,
  );

  const co2Costs = pathValueAtYear(analyzedBuildings.co2CostPath.m2, currentYear);
  const co2CostsChange = calculateBenchmarkChange(co2Costs, benchmark?.averageCo2Costs);

  const energyCosts = pathValueAtYear(analyzedBuildings.energyCostPath.m2, currentYear);
  const energyCostsChange = calculateBenchmarkChange(energyCosts, benchmark?.averageEnergyCosts);

  const finalEnergyChange = calculateBenchmarkChange(
    analyzedBuildings.averageFinalEnergy,
    benchmark?.averageFinalEnergy,
  );
  const primaryEnergyChange = calculateBenchmarkChange(
    analyzedBuildings.averagePrimaryEnergy,
    benchmark?.averagePrimaryEnergy,
  );

  const heatingDemand = analyzedBuildings.averageHeatingDemand;

  const heatingDemandChange =
    heatingDemand && benchmark?.averageHeatingDemand
      ? (1 - heatingDemand / benchmark?.averageHeatingDemand) * -1
      : null;

  const currentEnergyDataBySource = analyzedBuildings.energyDataBySourceType;

  const energySources = currentEnergyDataBySource.map((energySource) => ({
    energy_source_type: energySource.energySourceType,
    energy_consumption: energySource.energyConsumptionM2,
    energy_consumption_total: energySource.energyConsumptionTotal,
  }));

  const scope123Data = pathValueAtYear(analyzedBuildings.scope123Co2Emissions, currentYear);
  const buildingsByEnergySource = analyzedBuildings.buildingsByEnergySourceType;
  const buildingsByEUTaxonomy = analyzedBuildings.buildingsByEUTaxonomyCompliance;

  const hasHeatingDemand = !!heatingDemand;
  const firstContainerSize = hasHeatingDemand ? 10 : 8;
  const itemSizeFirstContainer = hasHeatingDemand ? 3 : 4;

  const { averageFinalEnergy, averagePrimaryEnergy, averageCo2Emissions } = analyzedBuildings;

  const graphStartYear: number = determineStartYear(constructionYear);

  return (
    <Grid container spacing={3}>
      <Grid container item spacing={3} sx={{ pt: 3 }}>
        <Grid item xs={firstContainerSize}>
          <Card sx={{ p: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={itemSizeFirstContainer}>
                <SimpleComparisonMetric
                  title={t('General_FinalEnergy')}
                  value={showTotal ? averageFinalEnergy * totalArea : averageFinalEnergy}
                  change={finalEnergyChange}
                  selectedBenchmarkName={selectedBenchmarkName}
                  unit={showTotal ? Units.energyPerYear : Units.energyPerAreaYear}
                  showTotal={showTotal}
                />
              </Grid>
              <Grid item xs={itemSizeFirstContainer}>
                <SimpleComparisonMetric
                  title={t('General_PrimaryEnergy')}
                  value={showTotal ? averagePrimaryEnergy * totalArea : averagePrimaryEnergy}
                  change={primaryEnergyChange}
                  selectedBenchmarkName={selectedBenchmarkName}
                  unit={showTotal ? Units.energyPerYear : Units.energyPerAreaYear}
                  showTotal={showTotal}
                />
              </Grid>
              {hasHeatingDemand && (
                <Grid item xs={3}>
                  <SimpleComparisonMetric
                    title={t('General_HeatingDemand')}
                    value={showTotal ? heatingDemand * totalArea : heatingDemand}
                    change={heatingDemandChange}
                    hideNoChange={heatingDemandChange === null}
                    selectedBenchmarkName={selectedBenchmarkName}
                    unit={showTotal ? Units.energyPerYear : Units.energyPerAreaYear}
                    showTotal={showTotal}
                  />
                </Grid>
              )}
              <Grid item xs={itemSizeFirstContainer}>
                <SimpleComparisonMetric
                  title={t('General_CO2Intensity')}
                  value={showTotal ? averageCo2Emissions * totalArea : averageCo2Emissions}
                  change={co2EmissionsChange}
                  selectedBenchmarkName={selectedBenchmarkName}
                  unit={showTotal ? Units.co2EmissionsPerYear : Units.co2EmissionsPerAreaYear}
                  showTotal={showTotal}
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>

        <Grid item xs={12 - firstContainerSize}>
          <Card sx={{ p: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={hasHeatingDemand ? 12 : 6}>
                <SimpleComparisonMetric
                  title={t('General_EnergyCost')}
                  value={showTotal ? energyCosts * totalArea : energyCosts}
                  change={energyCostsChange}
                  selectedBenchmarkName={selectedBenchmarkName}
                  unit={showTotal ? Units.pricePerYear : Units.pricePerAreaYear}
                  showTotal={showTotal}
                />
              </Grid>
              {!hasHeatingDemand && (
                <Grid item xs={6}>
                  <SimpleComparisonMetric
                    title={t('General_CO2Taxes')}
                    value={showTotal ? co2Costs * totalArea : co2Costs}
                    change={co2CostsChange}
                    selectedBenchmarkName={selectedBenchmarkName}
                    unit={showTotal ? Units.pricePerYear : Units.pricePerAreaYear}
                    showTotal={showTotal}
                  />
                </Grid>
              )}
            </Grid>
          </Card>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <CrremAnalysis
          buildingLegendName={t('General_Portfolio')}
          cumulativeExcessEmissions={analyzedBuildings.crremAnalysis.totalCumulativeExcessEmissions}
          graphStartYear={graphStartYear}
          selectedBenchmarkName={selectedBenchmarkName}
          benchmarkCrremAnalysis={benchmark.crremAnalysis}
          crremAnalysis={analyzedBuildings.crremAnalysis}
          strandingData={analyzedBuildings.strandingData}
          currentPageView={currentPageView}
        />
      </Grid>
      {currentPageView === 'esg-portfolio' && (
        <Grid item xs={12}>
          <AreaBubbleChart buildings={analyzedBuildings.buildingsGeneralData} navigate={navigate} />
        </Grid>
      )}
      <Grid item container xs={12} spacing={3}>
        <Grid item xs={6}>
          <Scope123Graph scopeData={scope123Data.data} showTotal={showTotal} />
        </Grid>
        <Grid item xs={6}>
          <EnergySourceConsumptionGraph
            energySources={energySources}
            showTotal={showTotal}
            totalArea={totalArea}
            onItemSelect={handleEnergySourceSelect}
          />
        </Grid>
        <Grid item container xs={12} spacing={3}>
          <Grid item xs={6}>
            <CO2CostDevelopmentGraph
              co2CostDistributionPath={analyzedBuildings.co2CostDistributionPath}
              showTotal={showTotal}
              sx={{ minHeight: '100%' }}
            />
          </Grid>
          <Grid item xs={6}>
            <EnergyCostDevelopmentGraph
              energyCosts={analyzedBuildings.energyCostPath.m2}
              energyCostsTotal={analyzedBuildings.energyCostPath.total}
              showTotal={showTotal}
              sx={{ minHeight: '100%' }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item container xs={12} spacing={3}>
        <Grid item xs={6}>
          <MemoizedPortfolioBuildingDistributionByCO2CostGraph
            buildingsByTaxBracket={analyzedBuildings.buildingsByTaxBracket}
          />
        </Grid>
        <Grid item xs={6}>
          <MemoizedPortfolioBuildingDistributionByEfficiencyClassGraph
            buildingsByEfficiencyClass={analyzedBuildings.buildingsByEfficiencyClass}
          />
        </Grid>
      </Grid>
      <Grid item container spacing={3}>
        <Grid item xs={6}>
          <EuTaxonomyGraph scopeData={buildingsByEUTaxonomy} />
        </Grid>
        <Grid item xs={6}>
          <BuildingsByEnergySourceType data={buildingsByEnergySource} onItemClick={handleEnergySourceSelect} />
        </Grid>
      </Grid>
    </Grid>
  );
}
