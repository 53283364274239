import { Link, SxProps, Tooltip } from '@mui/material';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { ACADEMY_LINKS } from '../../assets/links';
import { PATHS } from '../../routes';
import Iconify from '../Iconify';
import { IconButtonAnimate } from '../animate';

type Props = {
  showTooltip?: boolean;
  deep?: boolean;
  sx?: SxProps;
};

export default function GitBookLink({ children, showTooltip = true, deep = false, sx }: PropsWithChildren<Props>) {
  const { t } = useTranslation();
  const route = useLocation();

  // will be used to get the deep link for the gitbook in the future
  // @TODO: move this into a helper function and make it smarter and more readable
  // PRE-5921
  const getDeepLink = () => {
    if (deep) {
      //REPORTING
      if (route.pathname.startsWith(PATHS.reporting.root())) {
        return '/module/reporting';
      }

      //ACTION PLANNING
      if (route.pathname.startsWith(PATHS.actionPlanning.root())) {
        return '/module/massnahmenplanung';
      }

      //DATA COLLECTION
      if (route.pathname.startsWith(PATHS.dataCollection.emissionCertificates())) {
        return '/module/datensammlung/emissionszertifikate-verwalten';
      }
      if (route.pathname.startsWith(PATHS.dataCollection.root())) {
        return '/module/datensammlung';
      }

      //SETTINGS
      if (route.pathname.startsWith(PATHS.org.users())) {
        return '/module/einstellungen/nutzer-verwalten';
      }
      if (route.pathname.startsWith(PATHS.org.portfolios())) {
        return '/module/einstellungen/portfoliostruktur-verwalten';
      }
    }
    return '';
  };

  const href = ACADEMY_LINKS.ACADEMY_HOME + getDeepLink();

  return (
    <Tooltip title={showTooltip ? t('General_Academy-tooltip') : ''} placement="left" arrow>
      <Link href={href} target="_blank" rel="noopener noreferrer" sx={{ ...sx }}>
        {children || (
          <IconButtonAnimate data-cy="cy-help-center-menu" sx={{ width: 40, height: 40, mr: 1 }}>
            <Iconify icon="mdi:help-circle-outline" />
          </IconButtonAnimate>
        )}
      </Link>
    </Tooltip>
  );
}
