import { Box, Divider, Stack, Tab, Tabs } from '@mui/material';
import { EsgAnalysisBuildingBasicDetailsFragment } from '@predium/client-graphql';
import { useTranslation } from 'react-i18next';
import { ESG_TAB_ICONS } from '../../../assets/icons';
import Iconify from '../../../components/Iconify';
import usePosthogTracking from '../../../hooks/usePosthogTracking';
import useSessionData from '../../../hooks/useSessionData';
import EsgAnalysisBenchmark from '../Components/EsgAnalysisBenchmark';
import EsgAnalysisUnitSwitcher from '../Components/EsgAnalysisUnitSwitcher';
import ReportingYearSelector from '../Components/ReportingYearSelector';
import { EsgAnalysisTab } from '../Portfolio/PortfolioAnalysisHeader';

type Props = {
  selectedBuilding: EsgAnalysisBuildingBasicDetailsFragment;
  currentTab: EsgAnalysisTab;
  setCurrentTab: (tab: EsgAnalysisTab) => void;
  setShowTotal: (showTotal: boolean) => void;
  setSelectedReportingYear: (year: number) => void;
  selectedReportingYear: number;
  setSelectedBenchmark: ({ id, name }: { id: number | undefined; name: string }) => void;
};

export default function EsgAnalysisBuildingTabs({
  selectedBuilding,
  currentTab,
  setCurrentTab,
  setShowTotal,
  setSelectedReportingYear,
  selectedReportingYear,
  setSelectedBenchmark,
}: Props) {
  const { trackEvent } = usePosthogTracking();
  const { t } = useTranslation();
  const { serverSideFeatureFlags } = useSessionData();

  const isClimateRiskV2Enabled = serverSideFeatureFlags.CLIMATE_RISK_V2;
  const isConsumptionAnalysisEnabled = serverSideFeatureFlags.CONSUMPTION_ANALYSIS;

  const tabs: {
    label: string;
    value: EsgAnalysisTab;
    icon: string;
  }[] = [
    {
      label: t('General_Demand'),
      value: 'demand' as const,
      icon: ESG_TAB_ICONS.DEMAND,
    },
    ...(isConsumptionAnalysisEnabled
      ? [
          {
            label: t('General_Consumption'),
            value: 'consumption' as const,
            icon: ESG_TAB_ICONS.CONSUMPTION,
          },
        ]
      : []),
    ...(!isClimateRiskV2Enabled
      ? [
          {
            label: t('General_ClimateRisks'),
            value: 'climate_risks' as const,
            icon: ESG_TAB_ICONS.CLIMATE_RISKS,
          },
        ]
      : []),
  ];

  const onDisplayBaseChange = (newDisplayBase: 'per_square_meter' | 'absolute') => {
    const doShowTotal = newDisplayBase === 'absolute';
    setShowTotal(doShowTotal);
    trackEvent('DISPLAY_BASE_CHANGED', {
      display_base: newDisplayBase,
    });
  };

  return (
    <Box>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Tabs
          value={currentTab}
          scrollButtons="auto"
          variant="scrollable"
          allowScrollButtonsMobile
          onChange={(_, value) => setCurrentTab(value)}
        >
          {tabs.map((tab) => (
            <Tab
              disableRipple
              key={tab.value}
              label={tab.label}
              value={tab.value}
              sx={{ mb: 1 }}
              icon={<Iconify icon={tab.icon} width={24} height={24} />}
            />
          ))}
        </Tabs>

        {currentTab !== 'climate_risks' && (
          <Stack direction={'row'} spacing={2}>
            {currentTab !== 'consumption' && <EsgAnalysisBenchmark setSelectedBenchmark={setSelectedBenchmark} />}
            <EsgAnalysisUnitSwitcher
              onDisplayBaseChange={onDisplayBaseChange}
              isConsumptionAnalysis={currentTab === 'consumption'}
            />
            {isConsumptionAnalysisEnabled && currentTab !== 'demand' && (
              <ReportingYearSelector
                setSelectedReportingYear={setSelectedReportingYear}
                selectedReportingYear={selectedReportingYear}
                constructionYear={selectedBuilding.year_constructed}
              />
            )}
          </Stack>
        )}
      </Stack>

      <Divider />
    </Box>
  );
}
