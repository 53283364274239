import { LoadingButton } from '@mui/lab';
import { Button, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ICONS } from '../../../../assets/icons';
import Iconify from '../../../../components/Iconify';
import { PATHS } from '../../../../routes';
import { useBuildingCreation } from './BuildingCreationProvider';
import { steps } from './Steps';
import { useCreateBuilding } from './hooks/useCreateBuilding';
import { ACTION_TYPES } from './hooks/useGlobalState';

const BuildingCreationActions = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state, handleBack, handleNext, handleSaveAsDraft, dispatch, isLoading, currentFormSubstep } =
    useBuildingCreation();

  const [createBuildingFromBuildingDraft, { loading: createLoading }] = useCreateBuilding();

  const showBackButton =
    state.activeStepIndex >= 0 && (!state.hasSubsteps || (state.hasSubsteps && currentFormSubstep !== 0));
  const showNextButton = state.activeStepIndex < steps.length - 1;
  const showSaveAsDraft = state.activeStepIndex > 0;
  const showSaveButton =
    steps.length - 1 === state.activeStepIndex &&
    (state.hasSubsteps && currentFormSubstep !== undefined
      ? currentFormSubstep + 1 === steps[state.activeStepIndex].substeps?.length
      : true);

  const handleCreateBuilding = async () => {
    dispatch({ type: ACTION_TYPES.SET_IS_CREATING_BUILDING, payload: true });

    handleSaveAsDraft(
      (draftId) => {
        if (draftId) {
          createBuildingFromBuildingDraft({
            variables: { buildingDraftId: draftId },
            onCompleted: () => {
              dispatch({ type: ACTION_TYPES.SET_IS_CREATING_BUILDING, payload: false });
            },
            onError: () => {
              dispatch({ type: ACTION_TYPES.SET_IS_CREATING_BUILDING, payload: false });
              navigate(PATHS.dataCollection.buildingDraft({ id: draftId }));
            },
          });
        }
      },
      true,
      true,
    );
  };

  const loading = isLoading || createLoading;

  return (
    <Stack direction="row" justifyContent={showBackButton ? 'space-between' : 'flex-end'} spacing={2} flex={1} px={2}>
      {showBackButton && (
        <Button sx={{ borderColor: 'divider' }} variant="outlined" onClick={() => handleBack()} disabled={loading}>
          {t('General_Back')}
        </Button>
      )}
      <Stack direction="row" spacing={2}>
        {state.invalidMessage && (
          <Typography display="flex" alignItems="center" variant="body2">
            <Iconify icon={ICONS.INFO_CONTAINED} width={20} height={20} color="grey.600" mr={1} />
            {state.invalidMessage}
          </Typography>
        )}
        {showSaveAsDraft && (
          <Button
            sx={{ borderColor: 'divider' }}
            variant="outlined"
            onClick={() => handleSaveAsDraft()}
            disabled={state.isCurrentStepInValid || loading}
          >
            {t('General_SaveAsDraft')}
          </Button>
        )}
        {showNextButton && (
          <Button variant="contained" onClick={handleNext} disabled={state.isCurrentStepInValid || loading}>
            {t('General_Next')}
          </Button>
        )}
        {showSaveButton && (
          <LoadingButton
            loading={loading}
            variant="contained"
            onClick={handleCreateBuilding}
            disabled={state.isCurrentStepInValid || loading}
          >
            {t('General_CreateBuilding')}
          </LoadingButton>
        )}
      </Stack>
    </Stack>
  );
};

export default BuildingCreationActions;
