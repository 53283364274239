/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Card, Grid, Typography } from '@mui/material';
import { climate_risk_type_enum } from '@predium/enums';
import { translateClimateRiskTypeEnum } from '@predium/i18n/client';
import { COMMON_DATE_FORMATS, formatDateToLocale } from '@predium/utils';
import { Trans, useTranslation } from 'react-i18next';
import Iconify from '../../../components/Iconify';
import Unavailable from '../../../components/Unavailable';
import useSessionData from '../../../hooks/useSessionData';
import { useLanguage } from '../../../provider/LanguageProvider';
import BuildingClimateRiskItem from './ClimateRisk/BuildingClimateRiskItem';
import ClimateRiskV2UpgradeAlert from './ClimateRisk/ClimateRiskV2UpgradeAlert';
import useBuilding from './Context/useBuilding';

export default function BuildingClimateRisk() {
  const { t } = useTranslation();
  const { language } = useLanguage();
  const { building } = useBuilding();
  const {
    serverSideFeatureFlags: { CLIMATE_RISK_V2 },
  } = useSessionData();

  const SECTIONS = [
    {
      icon: 'wpf:snow',
      label: translateClimateRiskTypeEnum(climate_risk_type_enum.WINTER_STORM),
      riskType: climate_risk_type_enum.WINTER_STORM,
      info: <Trans i18nKey="DataCollection_BuildingClimateRiskInfo_WinterStorm" />,
    },
    {
      icon: 'bi:cloud-rain-heavy',
      label: translateClimateRiskTypeEnum(climate_risk_type_enum.HEAVY_RAIN),
      riskType: climate_risk_type_enum.HEAVY_RAIN,
      info: <Trans i18nKey="DataCollection_BuildingClimateRiskInfo_HeavyRain" />,
    },
    {
      icon: 'bi:cloud-hail',
      label: translateClimateRiskTypeEnum(climate_risk_type_enum.HAIL),
      riskType: climate_risk_type_enum.HAIL,
      info: <Trans i18nKey="DataCollection_BuildingClimateRiskInfo_Hail" />,
    },
    {
      icon: 'bi:cloud-snow',
      label: translateClimateRiskTypeEnum(climate_risk_type_enum.SNOW_LOAD),
      riskType: climate_risk_type_enum.SNOW_LOAD,
      info: <Trans i18nKey="DataCollection_BuildingClimateRiskInfo_SnowLoad" />,
    },
    {
      icon: 'carbon:lightning',
      label: translateClimateRiskTypeEnum(climate_risk_type_enum.LIGHTNING_STRIKE),
      riskType: climate_risk_type_enum.LIGHTNING_STRIKE,
      info: <Trans i18nKey="DataCollection_BuildingClimateRiskInfo_LightningStrike" />,
    },
    {
      icon: 'wi:earthquake',
      label: translateClimateRiskTypeEnum(climate_risk_type_enum.EARTHQUAKE),
      riskType: climate_risk_type_enum.EARTHQUAKE,
      info: <Trans i18nKey="DataCollection_BuildingClimateRiskInfo_Earthquake" />,
    },
    {
      icon: 'la:fire-alt',
      label: translateClimateRiskTypeEnum(climate_risk_type_enum.FOREST_FIRE),
      riskType: climate_risk_type_enum.FOREST_FIRE,
      info: <Trans i18nKey="DataCollection_BuildingClimateRiskInfo_ForestFire" />,
    },
    {
      icon: 'bi:thermometer-sun',
      label: translateClimateRiskTypeEnum(climate_risk_type_enum.HEAT),
      riskType: climate_risk_type_enum.HEAT,
      info: <Trans i18nKey="DataCollection_BuildingClimateRiskInfo_Heat" />,
    },
  ];

  const climateRisksAvailable = building.climate_risks.length > 0;

  if (!climateRisksAvailable) {
    return <Unavailable title={t('DataCollection_BuildingClimateRisk_UnavailableTitle')} />;
  }

  return (
    <Grid container spacing={3}>
      {CLIMATE_RISK_V2 && <ClimateRiskV2UpgradeAlert />}
      <Grid item xs={12} sx={{ textAlign: 'right', pr: 3 }}>
        {t('General_UpdatedOn')}:{' '}
        <Typography component="label" sx={{ fontWeight: 'bold' }}>
          {formatDateToLocale(
            //@ts-ignore

            //@ts-ignore

            //@ts-ignore
            building.climate_risks_aggregate.aggregate.max.created_at,
            COMMON_DATE_FORMATS.DAY_MONTH_YEAR,
            language,
          )}
        </Typography>
      </Grid>
      {SECTIONS.map((section, idx) => (
        <Grid item xs={12} md={6} key={idx}>
          <Card sx={{ p: 3, height: '100%' }}>
            <Grid container>
              <Grid item md={1} sx={{ justifyContent: 'center', display: 'flex', pt: 3 }}>
                <Iconify icon={section.icon} width={50} height={50} />
              </Grid>
              <Grid item md={11}>
                <BuildingClimateRiskItem
                  building={building}
                  riskType={section.riskType}
                  label={section.label}
                  info={section.info}
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}
