import { useQuery } from '@apollo/client';
import { Box, Card, Divider, Stack, Typography, useTheme } from '@mui/material';
import {
  consumption_sub_type_enum,
  consumption_type_enum,
  energy_consumption_type_enum,
  energy_source_type_enum,
} from '@predium/enums';
import {
  translateConsumptionSubTypeEnum_dynamic,
  translateConsumptionTypeEnum_dynamic,
  translateEnergySourceTypeEnum_dynamic,
} from '@predium/i18n/client';
import { accessEnum, maybeEnum, Units, UnitsValue } from '@predium/utils';
import dayjs from 'dayjs';
import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';
import { useTranslation } from 'react-i18next';
import { ICONS } from '../../../assets/icons';
import InfoTooltip from '../../../components/InfoTooltip';
import { DelayedLoading } from '../../../components/Loading';
import { ESG_ANALYSIS_CONSUMPTION_ALLOCATION } from '../../../graphql/EsgAnalysis.queries';
import { getValueOrAbsolute } from '../EsgAnalysisUtil';
import CO2Share from '../Visualization/Consumption/CO2Share';
import AddConsumptionButton from './ConsumptionComponents/AddConsumptionButton';
import KPI from './ConsumptionComponents/KPI';
import Meter from './ConsumptionComponents/Meter';

type Props = {
  buildingId: number;
  year: number;
  isAbsolute: boolean;
};

const ConsumptionAllocation = ({ buildingId, year, isAbsolute }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { data, loading } = useQuery(ESG_ANALYSIS_CONSUMPTION_ALLOCATION, {
    variables: {
      buildingId,
      from: dayjs().year(year).startOf('year').valueOf(),
      to: dayjs().year(year).endOf('year').valueOf(),
    },
    fetchPolicy: 'cache-and-network',
  });

  const getTranslationForConsumptionType = (type: string) => {
    if (maybeEnum(consumption_sub_type_enum, type)) {
      return translateConsumptionSubTypeEnum_dynamic(accessEnum(consumption_sub_type_enum, type), t);
    }

    return translateEnergySourceTypeEnum_dynamic(accessEnum(energy_source_type_enum, type), t);
  };

  const energyConsumptionData = [
    {
      type: energy_consumption_type_enum.ENERGY,
      icon: ICONS.ENERGY,
      color: theme.palette.consumptionColors.ELECTRICITY.main,
      label: t('General_Energy'),
      unit: isAbsolute ? Units.energy : Units.energyPerArea,
    },
    {
      type: energy_consumption_type_enum.WATER,
      icon: ICONS.WATER,
      color: theme.palette.consumptionColors.WATER.main,
      label: translateConsumptionTypeEnum_dynamic(consumption_type_enum.WATER, t),
      unit: isAbsolute ? Units.waterConsumption : Units.waterConsumptionPerArea,
    },
    {
      type: energy_consumption_type_enum.WASTE,
      icon: ICONS.TRASH,
      color: theme.palette.consumptionColors.WASTE.main,
      label: translateConsumptionTypeEnum_dynamic(consumption_type_enum.WASTE, t),
      unit: isAbsolute ? Units.wasteConsumptionTons : Units.wasteConsumptionTonsPerArea,
    },
  ] satisfies {
    type: energy_consumption_type_enum;
    icon: string;
    label: string;
    color: string;
    unit: UnitsValue;
  }[];

  if (loading || !data) {
    return (
      <Stack height="300px" alignItems="center" justifyContent="center">
        <DelayedLoading />
      </Stack>
    );
  }

  const { carbonEmissions, energyConsumptions, co2Tax } = data?.getConsumptionAllocation;

  const groupedEnergyConsumption = mapValues(groupBy(energyConsumptions, 'type'), (values) => values[0]) as Record<
    energy_consumption_type_enum,
    (typeof energyConsumptions)[number]
  >;

  return (
    <Stack spacing={3}>
      <Card>
        <Stack p={4} direction="row" justifyContent="space-between" alignItems="center">
          <Stack spacing={2} direction="row" alignItems="center">
            <Typography variant="h6">{t('EsgAnalysisConsumptionAllocation_Title', { year })}</Typography>
            <Typography variant="caption" color="grey.600" pt={0.25}>
              {t('EsgAnalysisConsumptionAllocation_Subtitle')}
            </Typography>
          </Stack>
          <AddConsumptionButton buildingId={buildingId} />
        </Stack>
        <Divider />
        <Box p={4}>
          <Stack direction="row" spacing={9}>
            <Stack flex={1} spacing={3}>
              <Typography variant="subtitle1">{t('EsgAnalysisConsumptionAllocation_CarbonEmission')} </Typography>
              <Stack spacing={3} divider={<Divider />}>
                <Stack direction="row">
                  <KPI
                    title={t('General_CO2Intensity')}
                    titleTooltip={t('EsgAnalysisConsumptionAllocation_CO2IntensityTooltip')}
                    unit={isAbsolute ? Units.co2Emissions : Units.co2EmissionsPerArea}
                    value={carbonEmissions ? getValueOrAbsolute(isAbsolute, carbonEmissions, 'intensity') : null}
                    slotsProp={{ root: { flex: 1 }, title: { variant: 'h3' }, unitsRoot: { alignSelf: 'center' } }}
                  />
                  <Stack flex={2} spacing={2}>
                    <Stack spacing={0.5}>
                      <Typography variant="body2">{t('EsgAnalysisConsumptionAllocation_ScopeTitle')}</Typography>
                      <Typography variant="body2" color="text.secondary">
                        {t('EsgAnalysisConsumptionAllocation_ScopeSubtitle')}
                      </Typography>
                    </Stack>
                    <Stack divider={<Divider />} spacing={2}>
                      <Meter
                        title={t('General_Scope_1')}
                        value={carbonEmissions?.scope1.value}
                        percentage={carbonEmissions?.scope1.percentage}
                        unit={isAbsolute ? Units.co2Emissions : Units.co2EmissionsPerArea}
                      />
                      <Meter
                        title={t('General_Scope_2')}
                        value={carbonEmissions?.scope2.value}
                        percentage={carbonEmissions?.scope2.percentage}
                        unit={isAbsolute ? Units.co2Emissions : Units.co2EmissionsPerArea}
                      />
                      <Meter
                        title={t('General_Scope_3')}
                        value={carbonEmissions?.scope3.value}
                        percentage={carbonEmissions?.scope3.percentage}
                        unit={isAbsolute ? Units.co2Emissions : Units.co2EmissionsPerArea}
                      />
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
              <Divider />
              <Stack spacing={3} divider={<Divider />}>
                <Stack direction="row">
                  <Stack flex={1} spacing={1}>
                    <KPI
                      title={t('General_CO2Tax')}
                      titleTooltip={t('EsgAnalysisConsumptionAllocation_CO2TaxTooltip')}
                      unit={isAbsolute ? Units.price : Units.pricePerArea}
                      value={co2Tax ? getValueOrAbsolute(isAbsolute, co2Tax, 'total') : null}
                      slotsProp={{ root: { flex: 1 }, title: { variant: 'h3' }, unitsRoot: { alignSelf: 'center' } }}
                    />
                    <KPI
                      title={t('General_CostShare')}
                      titleTooltip={t('EsgAnalysisConsumptionAllocation_CostShareTooltip')}
                      unit={Units.percentage}
                      value={co2Tax?.costShare}
                      slotsProp={{ root: { flex: 1 }, title: { variant: 'h3' }, unitsRoot: { alignSelf: 'center' } }}
                    />
                  </Stack>
                  <Stack flex={2}>
                    <Stack direction="row" spacing={0.75} alignItems="center">
                      <Typography variant="body2">{t('EsgAnalysisConsumptionAllocation_ForecastPerYear')}</Typography>
                      <InfoTooltip
                        text={t('EsgAnalysisConsumptionAllocation_ForecastPerYearTooltip')}
                        sx={{ p: 0, svg: { height: 18, width: 18 } }}
                      />
                    </Stack>
                    <CO2Share
                      co2Tax={co2Tax}
                      hasData={(co2Tax && co2Tax?.totalAbsolute && co2Tax.totalAbsolute > 0) || false}
                      isAbsolute={isAbsolute}
                      year={year}
                    />
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            <Stack flex={1} spacing={3}>
              <Typography variant="subtitle1">{t('EsgAnalysisConsumptionAllocation_EnergyConsumption')}</Typography>
              <Stack spacing={3} divider={<Divider />}>
                {energyConsumptionData.map((consumptionGroup, index) => (
                  <Stack direction="row" key={index}>
                    <KPI
                      key={consumptionGroup.type}
                      icon={consumptionGroup.icon}
                      iconColor={consumptionGroup.color}
                      title={consumptionGroup.label}
                      unit={consumptionGroup.unit}
                      value={getValueOrAbsolute(isAbsolute, groupedEnergyConsumption[consumptionGroup.type], 'total')}
                      hasValue={groupedEnergyConsumption[consumptionGroup.type].items.length > 0}
                      slotsProp={{
                        root: { flex: 1 },
                      }}
                    />
                    <Stack flex={1} spacing={2}>
                      {groupedEnergyConsumption[consumptionGroup.type].items.length > 0 ? (
                        groupedEnergyConsumption[consumptionGroup.type].items.map((item) => (
                          <Meter
                            title={
                              item.type !== 'null' ? getTranslationForConsumptionType(item.type) : t('General_Unknown')
                            }
                            value={getValueOrAbsolute(isAbsolute, item, 'value')}
                            percentage={item.percentage}
                            hidePercentage={consumptionGroup.type === energy_consumption_type_enum.WATER}
                            unit={consumptionGroup.unit}
                          />
                        ))
                      ) : (
                        <Meter
                          title={t('General_NoData')}
                          unit={consumptionGroup.unit}
                          hidePercentage={consumptionGroup.type === energy_consumption_type_enum.WATER}
                        />
                      )}
                    </Stack>
                  </Stack>
                ))}
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </Card>
    </Stack>
  );
};

export default ConsumptionAllocation;
