import { t } from 'i18next';
import { useCallback, useEffect, useMemo, useReducer } from 'react';
import { SimpleSelectValue } from '../../../../components/SimpleSelect';
import usePosthogTracking from '../../../../hooks/usePosthogTracking';
import { useGetScenariosByPortfolioId } from './queries/useGetScenariosByPortfolioId';

export type CompareScenariosSelectStateType = {
  values: SimpleSelectValue<number>[];
  selectedId: number;
  selectedName: string;
  isDisabled: boolean;
  isScenarioCardHidden: boolean;
};
type CompareScenariosSelectActionType =
  | { type: 'set_loading' }
  | { type: 'set_empty' }
  | { type: 'select_scenario'; scenarioId: number; scenarioName: string }
  | { type: 'change_select_scenario' }
  | {
      type: 'set_values';
      values: SimpleSelectValue<number>[];
    };

export const EMPTY_PORTFOLIO_OPTION: SimpleSelectValue<number> = {
  id: Number.MAX_VALUE,
  name: t('ActionPlanningScenario_CompareScenarios_EmptySelect'),
};
export const LOADING_PORTFOLIO_OPTION: SimpleSelectValue<number> = {
  id: Number.MIN_VALUE,
  name: t('ActionPlanningScenario_CompareScenarios_LoadingSelect'),
};
export const SELECT_SCENARIO_OPTION: SimpleSelectValue<number> = {
  id: 999_999_999,
  name: t('General_Scenario', { count: 1 }),
  isPlaceholder: true,
};

function compareScenarioSelectReducer(
  state: CompareScenariosSelectStateType,
  action: CompareScenariosSelectActionType,
): CompareScenariosSelectStateType {
  switch (action.type) {
    case 'set_loading':
      return {
        values: [LOADING_PORTFOLIO_OPTION],
        selectedId: LOADING_PORTFOLIO_OPTION.id,
        selectedName: '',
        isDisabled: true,
        isScenarioCardHidden: true,
      };
    case 'set_empty':
      return {
        values: [EMPTY_PORTFOLIO_OPTION],
        selectedId: EMPTY_PORTFOLIO_OPTION.id,
        selectedName: '',
        isDisabled: true,
        isScenarioCardHidden: true,
      };
    case 'select_scenario':
      return {
        ...state,
        selectedId: action.scenarioId,
        selectedName: action.scenarioName,
        isDisabled: false,
        isScenarioCardHidden: false,
      };
    case 'change_select_scenario':
      return {
        ...state,
        selectedId: SELECT_SCENARIO_OPTION.id,
        selectedName: '',
        isScenarioCardHidden: true,
      };
    case 'set_values':
      return {
        values: [SELECT_SCENARIO_OPTION, ...action.values],
        selectedId: SELECT_SCENARIO_OPTION.id,
        selectedName: '',
        isDisabled: false,
        isScenarioCardHidden: true,
      };
    default:
      return state;
  }
}

export const useCompareScenarioSelect = (portfolioId: number, firstScenarioId: number) => {
  const { trackEvent } = usePosthogTracking();

  const [state, dispatch] = useReducer(compareScenarioSelectReducer, {
    values: [LOADING_PORTFOLIO_OPTION],
    selectedId: LOADING_PORTFOLIO_OPTION.id,
    selectedName: LOADING_PORTFOLIO_OPTION.name,
    isDisabled: true,
    isScenarioCardHidden: true,
  });

  const [scenarios, isLoading] = useGetScenariosByPortfolioId(portfolioId);
  const scenariosWithoutFirstScenario = useMemo(
    () => scenarios.filter((scenario) => scenario.id !== firstScenarioId),
    [firstScenarioId, scenarios],
  );

  useEffect(() => {
    if (isLoading) {
      dispatch({ type: 'set_loading' });
      return;
    }
    if (scenariosWithoutFirstScenario.length === 0) {
      dispatch({ type: 'set_empty' });
      return;
    }
    dispatch({ type: 'set_values', values: scenariosWithoutFirstScenario });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const handleSecondScenarioChange = useCallback(
    (scenarioId: number) => {
      trackEvent('SCENARIO_COMPARISON_DIALOG_SELECT_SCENARIO');
      dispatch({
        type: 'select_scenario',
        scenarioId,
        scenarioName: scenariosWithoutFirstScenario.find((it) => it.id === scenarioId)?.name ?? '',
      });
    },
    [scenariosWithoutFirstScenario, trackEvent],
  );

  const handleSelectChangeClick = useCallback(() => {
    dispatch({ type: 'change_select_scenario' });
  }, []);

  return [state, isLoading, handleSecondScenarioChange, handleSelectChangeClick] as const;
};
