import { useQuery } from '@apollo/client';
import { GET_SCENARIOS_BY_PORTFOLIO_ID } from '../../../../../graphql/ActionPlanning.queries';

export const useGetScenariosByPortfolioId = (portfolioId: number) => {
  const { data, loading: isLoading } = useQuery(GET_SCENARIOS_BY_PORTFOLIO_ID, {
    variables: { portfolioId },
    fetchPolicy: 'cache-and-network',
  });

  const scenarios = data?.scenarios ?? [];

  return [scenarios, isLoading] as const;
};
