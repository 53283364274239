/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { createStandardErrorSnackbar } from '../../../../components/NotistackProvider';
import { BUILDING_EXCEL_EXPORT } from '../../../../graphql/DataCollection.mutations';
import BulkExport, { TriggerExportParams } from './Components/BulkExport';

type Props = {
  buildingIds: number[];
  economicUnitsCount?: number;
  onActionCompleted?: () => void;
  onClose: VoidFunction;
};

export default function LimitedBulkExport({ buildingIds, economicUnitsCount, onActionCompleted, onClose }: Props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const displayStandardMutationQueryErrorSnackbar = createStandardErrorSnackbar(t);

  const [buildingExcelExportMutation, { loading }] = useMutation(BUILDING_EXCEL_EXPORT, {
    onError: () => displayStandardMutationQueryErrorSnackbar(enqueueSnackbar),
  });

  const triggerExport = useCallback(
    ({ export_template_id, onSuccess, onError }: TriggerExportParams) =>
      buildingExcelExportMutation({
        variables: {
          export_template_id,
          buildingIds,
        },
        onCompleted: (data) => onSuccess(data.exportBuildingsAsExcel.body),
        onError,
      }),
    [buildingExcelExportMutation, buildingIds],
  );

  return (
    <BulkExport
      count={buildingIds.length}
      economicUnitsCount={economicUnitsCount}
      loading={loading}
      triggerExport={triggerExport}
      onActionCompleted={onActionCompleted}
      onClose={onClose}
    />
  );
}
