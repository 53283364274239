import { Typography } from '@mui/material';
import React from 'react';
import CenterBox from '../../../../../components/common/CenterBox';

type NoDataPlaceholderProps = {
  message: string;
};

const NoDataPlaceholder: React.FC<NoDataPlaceholderProps> = ({ message }) => (
  <CenterBox height={300}>
    <Typography variant="body1" color="text.secondary">
      {message}
    </Typography>
  </CenterBox>
);

export default NoDataPlaceholder;
