/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  alpha,
  useTheme,
} from '@mui/material';
import { DataCollectionEmissionCertificateDraftFragment } from '@predium/client-graphql';
import { COMMON_DATE_FORMATS, formatDateToLocale } from '@predium/utils';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Label from '../../../components/Label';
import Scrollbar from '../../../components/Scrollbar';
import Unavailable from '../../../components/Unavailable';
import TablePaginationStandard from '../../../components/table/TablePaginationStandard';
import TableRowWithHighlight, { hasRecentlyChanged } from '../../../components/table/TableRowWithHighlight';
import useSessionData from '../../../hooks/useSessionData';
import useTable, { applySortFilter } from '../../../hooks/useTable';
import { useLanguage } from '../../../provider/LanguageProvider';
import { PATHS } from '../../../routes';
import DataCollectionHead from '../../../sections/DataCollection/Buildings/DataCollectionHead';
import { HeadLabel } from '../../../sections/Scenarios/Scenario/ScenariosTableHead';
import EmissionCertificateDraftActionMenu from './EmissionCertificateDraftActionMenu';
import { EmissionCertificateDraftStatusType } from './EmissionCertificatesTabs';

type EmissionCertificatesDraftsListProps = {
  emissionCertificates: DataCollectionEmissionCertificateDraftFragment[];
  openCreationModal: () => void;
};

type RowProps = {
  row: DataCollectionEmissionCertificateDraftFragment;
};

export default function EmissionCertificatesDraftsList({
  emissionCertificates,
  openCreationModal,
}: EmissionCertificatesDraftsListProps) {
  const { t } = useTranslation();

  const COLUMNS: HeadLabel[] = [
    { id: 'file_name', label: t('General_FileName'), minWidth: 300 },
    { id: 'created_at', label: t('General_UploadedAt'), minWidth: 180 },
    // { id: 'uploaded_by', label: t('General_UploadedBy') },
    { id: 'status', label: t('General_Status'), minWidth: 180 },
    { id: 'action_menu', label: '', sortingDisabled: true },
  ];

  const {
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    order,
    orderBy,
    // filterName,
    // handleFilterByName,
    handleRequestSort,
  } = useTable({ defaultOrderBy: 'created_at', defaultOrder: 'desc', defaultRowsPerPage: 10 });

  const sortedRows = applySortFilter({
    data: emissionCertificates,
    orderOptions: {
      order,
      orderBy,
    },
  });

  // tracking the empty rows in the next page, to prevent the table from shifting
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - emissionCertificates.length) : 0;

  const noData = emissionCertificates.length === 0;

  return (
    <>
      <Card>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <DataCollectionHead
                order={order}
                orderBy={orderBy}
                headLabel={COLUMNS}
                rowCount={sortedRows.length}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                  return <Row key={row.id} row={row} />;
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              {noData && <EmptyState openCreationModal={openCreationModal} />}
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePaginationStandard
          count={sortedRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
        />
      </Card>
    </>
  );
}

function Row({ row }: RowProps) {
  const { language } = useLanguage();
  const navigate = useNavigate();

  const onClickHandler = () => {
    navigate(PATHS.dataCollection.emissionCertificateDraft({ id: row.id }));
  };

  return (
    <>
      <TableRowWithHighlight
        background={hasRecentlyChanged(row.created_at) ? 'success' : 'default'}
        sx={{ '& > *': { borderBottom: 'unset' }, cursor: 'pointer' }}
        hover
        onClick={onClickHandler}
      >
        <TableCell>
          <Typography variant="subtitle2">{row.file?.name}</Typography>
        </TableCell>
        <TableCell>{formatDateToLocale(row.created_at, COMMON_DATE_FORMATS.DAY_MONTH_YEAR, language)}</TableCell>
        <TableCell>
          <StatusBadge status="in_review" />
        </TableCell>
        <TableCell
          align="right"
          onClick={(e) => {
            // prevent the edit modal from opening when clicking on the action menu
            e.stopPropagation();
          }}
        >
          <EmissionCertificateDraftActionMenu
            //@ts-ignore
            filename={row.file?.name}
            openEditModal={onClickHandler}
            emissionCertificateDraftId={row.id}
          />
        </TableCell>
      </TableRowWithHighlight>
    </>
  );
}

function StatusBadge({ status }: { status: EmissionCertificateDraftStatusType }) {
  const { t } = useTranslation();
  const theme = useTheme();

  //change this to switch statement if we need more than 2 colors
  const textColor = status === 'uploading' ? theme.palette.info.dark : theme.palette.text.secondary;
  const backgroundColor = status === 'uploading' ? theme.palette.info.lighter : alpha(theme.palette.grey[900], 0.18);

  const getDisplayValueFromStatus = (status: EmissionCertificateDraftStatusType) => {
    switch (status) {
      case 'uploading':
        return t('General_Uploading');
      case 'ocr_scan':
        return t('General_OcrScan');
      case 'in_review':
        return t('General_NeedsReview');
      default:
        return '';
    }
  };

  return (
    <Label
      sx={{
        color: textColor,
        backgroundColor,
      }}
    >
      {getDisplayValueFromStatus(status)}
    </Label>
  );
}

function EmptyState({ openCreationModal }: { openCreationModal: () => void }) {
  const { t } = useTranslation();
  const { isAdmin } = useSessionData();

  return (
    <TableBody>
      <TableRow>
        <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
          {isAdmin ? (
            <Unavailable
              iconString="gridicons:create"
              title={t('General_NoEmissionCertificatesFound')}
              subTitle={t('DataCollection_EmissionCertificates_AddCertificatePrompt')}
              onClick={() => {
                openCreationModal();
              }}
            />
          ) : (
            <Unavailable title={t('General_NoEmissionCertificatesFound')} />
          )}
        </TableCell>
      </TableRow>
    </TableBody>
  );
}
