/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useMutation, useQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import DeleteConfirmationModal from '../../../../components/DeleteConfirmationModal';
import { SnackbarTimeouts } from '../../../../components/NotistackProvider';
import PreDialog from '../../../../components/presentations/PreDialog/PreDialog';
import { PermissionType } from '../../../../contexts/PermissionContext';
import { DELETE_BUILDINGS, DELETE_ECONOMIC_UNITS } from '../../../../graphql/DataCollection.mutations';
import { GET_BUILDINGS_ECONOMIC_UNIT_IDS, IS_LAST_EU_FROM_BUILDINGS } from '../../../../graphql/DataCollection.queries';
import useGetAssociatedDataForBuilding from '../../../../hooks/useGetAssociatedDataForBuilding';
import useOrgPreferences from '../../../../hooks/useOrgPreferences';
import usePermissions from '../../../../hooks/usePermissions';
import usePosthogTracking from '../../../../hooks/usePosthogTracking';
import useTooltipConstants from '../../../../hooks/useTooltipConstants';
import { DeleteEmptyEconomicUnit } from '../../Building/Common/DeleteEmptyEconomicUnit';
import ActionButtons from './Components/ActionButtons';
import LimitedBulkExport from './LimitedBulkExport';
import LimitedBulkMove from './LimitedBulkMove';

type Props = {
  buildingsId: number[];
  onActionCompleted: VoidFunction;
};

export default function LimitedBulkActionsMenu({ buildingsId, onActionCompleted }: Props) {
  const count = buildingsId.length;
  const { trackEvent } = usePosthogTracking();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { economicUnitsToggled } = useOrgPreferences();
  const { tooltips: deleteTooltip } = useTooltipConstants();
  const { checkBuildingPermission } = usePermissions();
  const [keepEmptyEu, setKeepEmptyEu] = useState(false);
  const [openBuildingExportDialog, setOpenBuildingExportDialog] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [openMoveBuildingModal, setOpenMoveBuildingModal] = useState(false);
  const { associatedDataForDeletion } = useGetAssociatedDataForBuilding({
    buildingIds: buildingsId,
    modalOpen: deleteModalOpen,
  });

  const { data: economicUnits } = useQuery(GET_BUILDINGS_ECONOMIC_UNIT_IDS, {
    variables: {
      buildingIds: buildingsId,
    },
  });

  const economicUnitIds = economicUnits?.economic_unit.map((eu) => eu.id) ?? [];
  const { data: isLastEuData } = useQuery(IS_LAST_EU_FROM_BUILDINGS, {
    variables: {
      ids: buildingsId,
    },
  });

  const showErrorSnackbar = () => {
    enqueueSnackbar(t('General_DeleteBuilding-error', { count }), {
      variant: 'error',
      autoHideDuration: SnackbarTimeouts.Error,
    });
  };

  const [deleteEconomicUnits] = useMutation(DELETE_ECONOMIC_UNITS);

  const removeEmptyEconomicUnits = async (economicUnitsToggled: boolean, economicUnitIds: number[]) => {
    if (!economicUnitsToggled) {
      await deleteEconomicUnits({
        variables: {
          economicUnitIds,
        },
      });
      return;
    }
    if (keepEmptyEu) {
      return;
    }
    await deleteEconomicUnits({
      variables: {
        economicUnitIds,
      },
    });
  };

  const [deleteBuildings] = useMutation(DELETE_BUILDINGS, {
    variables: {
      buildingIds: buildingsId,
    },
    update: (cache, { data }) => {
      //@ts-ignore

      //@ts-ignore
      if (data.delete_building.affected_rows > 0) {
        //@ts-ignore

        //@ts-ignore
        data.delete_building.returning.forEach(({ id, __typename }) => {
          const normalizedId = cache.identify({
            id,
            __typename,
          });
          cache.evict({ id: normalizedId });
        });

        cache.gc();
      }
    },
    onError: () => {
      showErrorSnackbar();
    },

    onCompleted: async (data) => {
      if (!data.delete_building?.affected_rows) {
        showErrorSnackbar();
        return;
      }
      const { returning } = data.delete_building;
      // get all economicUnitIds that now is empty.
      const economicUnitIds = Array.from(
        new Set(
          returning
            .filter(({ economic_unit }) => economic_unit.associatedBuildingsAndDrafts.amount === 0)
            .map(({ economic_unit }) => economic_unit.id),
        ),
      );
      await removeEmptyEconomicUnits(economicUnitsToggled, economicUnitIds);
      enqueueSnackbar(t('General_DeleteBuilding-success'), {
        variant: 'success',
        autoHideDuration: SnackbarTimeouts.Success,
      });
      trackEvent('BUILDING_DELETED', { building_id: buildingsId });
      setDeleteModalOpen(false);
      onActionCompleted();
    },
  });

  const hasEditAccess = buildingsId.every((buildingId) => checkBuildingPermission(buildingId, PermissionType.WRITE));

  const handleClose = () => {
    setOpenBuildingExportDialog(false);
  };

  return (
    <>
      <ActionButtons
        hasEditAccess={hasEditAccess}
        onMoveTo={() => setOpenMoveBuildingModal(true)}
        onExportData={() => setOpenBuildingExportDialog(true)}
        onDelete={() => setDeleteModalOpen(true)}
      />

      <PreDialog type="definedByChildren" open={openBuildingExportDialog} onClose={handleClose}>
        <LimitedBulkExport buildingIds={buildingsId} onActionCompleted={onActionCompleted} onClose={handleClose} />
      </PreDialog>

      <PreDialog
        type="definedByChildren"
        open={openMoveBuildingModal}
        onClose={() => setOpenMoveBuildingModal(false)}
        maxWidth="md"
        fullWidth
      >
        <LimitedBulkMove
          buildingIds={buildingsId}
          onMoveCompleted={() => {
            setOpenMoveBuildingModal(false);
            onActionCompleted();
          }}
          onCancel={() => setOpenMoveBuildingModal(false)}
          economicUnitIds={economicUnitIds}
        />
      </PreDialog>

      <DeleteConfirmationModal
        open={deleteModalOpen}
        title={t('General_DeleteModalTitle-Building', { count })}
        description={
          <Trans
            i18nKey={'General_DeleteModalDescription-Building'}
            values={{
              name: '',
              count,
              alongWithAssociatedData:
                associatedDataForDeletion.size > 0 ? t('General_DeleteWarningAssociatedDataText', { count }) : '',
            }}
            components={{ bold: <strong /> }}
          />
        }
        onClose={() => {
          setDeleteModalOpen(false);
        }}
        onDelete={() => {
          deleteBuildings();
        }}
        associatedData={associatedDataForDeletion}
        deleteConfirmationText={t('General_DeleteModalConfirmation-Building', { count })}
        dataLossWarningText={associatedDataForDeletion.size > 0 ? t('General_DeleteModalWarning-Building') : undefined}
        tooltips={deleteTooltip}
      >
        {isLastEuData && isLastEuData.hasSingleBuildingOrDraft && (
          <DeleteEmptyEconomicUnit
            isBulkOperation
            economicUnitsToggled={economicUnitsToggled}
            setKeepEmptyEu={setKeepEmptyEu}
          />
        )}
      </DeleteConfirmationModal>
    </>
  );
}
