/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, MenuItem, Stack, Typography, useTheme } from '@mui/material';
import {
  checkDistrictHeating,
  getSupportedSourceTypesForSystemAndTechnologyType,
  getSupportedSourceTypesForSystemType,
} from '@predium/client-lookup';
import { data_source_type_enum, emission_factor_type_enum, energy_source_type_enum } from '@predium/enums';
import { translateEmissionFactorTypeEnum_dynamic, translateEnergySourceTypeEnum } from '@predium/i18n/client';
import { Units, ensureDefined, maybeEnum } from '@predium/utils';
import { useEffect, useMemo } from 'react';
import { useForm, useFormContext, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { object, string } from 'yup';
import { TechnologyIcons } from '../../../../../../../../assets/images';
import DetailedTooltip from '../../../../../../../../components/DetailedTooltip/DetailedTooltip';
import Label from '../../../../../../../../components/Label';
import { DelayedLoading } from '../../../../../../../../components/Loading';
import OverflowText from '../../../../../../../../components/OverflowText';
import { FieldIcon, IconState } from '../../../../../../../../components/custom-data-source-input/FieldIcon';
import { FormProvider, RHFSelect } from '../../../../../../../../components/hook-form';
import PreDialog, {
  PreDialogBody,
  PreDialogTitle,
} from '../../../../../../../../components/presentations/PreDialog/PreDialog';
import { GET_ALL_EMISSION_CERTIFICATES_DROPDOWN } from '../../../../../../../../graphql/DataCollection.queries';
import useOrgPreferences from '../../../../../../../../hooks/useOrgPreferences';
import { useLanguage } from '../../../../../../../../provider/LanguageProvider';
import { SystemRoute, getDefaultParameterValues } from '../../../../../BuildingTechnology';
import { generateSystemFieldNames, generateSystemRouteFieldNames } from '../../../../../Common/building.util';
import useBuilding from '../../../../../Context/useBuilding';
import {
  EmissionFactorInfo,
  GlOBAL_EMISSION_CERTIFICATE_ID,
  PrimaryEnergyFactorInfo,
  getSelectDisabledStyles,
  getSelectStyles,
} from './EnergySourceEditModal';
import TechnologyNumberField from './TechnologyNumberField';
import TechnologyTextField from './TechnologyTextField';
import { getDefaultValuesForDistrictHeating, updateAllRelatedEnergySources } from './utils';

type EnergySourceEditSchemaType = {
  energySource: energy_source_type_enum | '';
  emissionFactor: number | undefined;
  primaryEnergyFactor: number | undefined;
  pricePerKwh: string | '';
  emissionCertificateId: number;
  primaryEnergyFactorEmissionCertificateId: number;
};

type Props = {
  open: boolean;
  onClose: () => void;
  energySystemRoute: SystemRoute;
  baseEmissionFactors: Record<energy_source_type_enum, number>;
  index: number;
  systemIndex: number;
};

const EnergySourceAddModal = ({ open, onClose, energySystemRoute, index, systemIndex, baseEmissionFactors }: Props) => {
  const { t } = useTranslation();
  const { localize } = useLanguage();
  const theme = useTheme();

  const { building } = useBuilding();
  const { activeCRREMConfiguration, crremConfigurations } = useOrgPreferences();
  const crremConfiguration =
    crremConfigurations.find((config) => config.isActiveForPortfolio(building.economic_unit.portfolio.id)) ??
    activeCRREMConfiguration;
  const { setValue: setParentFormValue, getValues: getParentFormValues } = useFormContext();
  const REQUIRED_MESSAGE = t('General_Required');

  const { loading, data } = useQuery(GET_ALL_EMISSION_CERTIFICATES_DROPDOWN);

  const emissionFactorsCertificates = useMemo(
    () =>
      data?.emission_factor_emission_certificates.map(
        (certificate) =>
          ({
            id: certificate.id,
            emissionFactor: ensureDefined(certificate.emission_factor),
            issuer: certificate.issuer,
          } satisfies EmissionFactorInfo),
      ) ?? [],
    [data],
  );

  const primaryEnergyFactorsCertificates = useMemo(() => {
    const certificates = data?.primary_energy_factor_emission_certificates ?? [];

    return certificates.map(
      (certificate) =>
        ({
          id: certificate.id,
          primaryEnergyFactor: ensureDefined(certificate.primary_energy_factor),
          issuer: certificate.issuer,
        } satisfies PrimaryEnergyFactorInfo),
    );
  }, [data]);

  const { consumerTechnologyType, energySystemType } = energySystemRoute;

  const defaultEmissionCertificateSource = {
    id: GlOBAL_EMISSION_CERTIFICATE_ID,
    emissionFactor: 0,
    primaryEnergyFactor: 0,
  };

  const supportedEnergySources = consumerTechnologyType
    ? getSupportedSourceTypesForSystemAndTechnologyType(energySystemType!, consumerTechnologyType)
    : getSupportedSourceTypesForSystemType(energySystemType!);

  const EnergySourceEditSchema = object().shape({
    energySource: string().required(REQUIRED_MESSAGE).oneOf(supportedEnergySources, REQUIRED_MESSAGE),
  });

  const defaultValues: EnergySourceEditSchemaType = {
    energySource: '',
    pricePerKwh: '',
    primaryEnergyFactor: undefined,
    emissionFactor: undefined,
    emissionCertificateId: GlOBAL_EMISSION_CERTIFICATE_ID,
    primaryEnergyFactorEmissionCertificateId: GlOBAL_EMISSION_CERTIFICATE_ID,
  };

  const methods = useForm<EnergySourceEditSchemaType>({
    resolver: yupResolver(EnergySourceEditSchema),
    defaultValues: defaultValues,
    mode: 'onChange',
  });

  const { handleSubmit, reset, getFieldState, watch, control, setValue } = methods;

  const { isDirty } = useFormState({
    control,
  });

  const isFieldDirty = getFieldState('energySource').isDirty;
  const newEnergySource = watch('energySource');
  const newEmissionCertificateId = watch('emissionCertificateId');
  const newPrimaryEnergyFactorEmissionCertificateId = watch('primaryEnergyFactorEmissionCertificateId');

  const isEmissionCertificateIdDirty = getFieldState('emissionCertificateId').isDirty;
  const isPrimaryEnergyFactorEmissionCertificateIdDirty = getFieldState(
    'primaryEnergyFactorEmissionCertificateId',
  ).isDirty;

  useEffect(() => {
    const safeNewEnergySource = maybeEnum(energy_source_type_enum, newEnergySource);

    if (!safeNewEnergySource) {
      return;
    }

    const { primaryEnergyFactor, pricePerKwh } = getDefaultParameterValues(
      safeNewEnergySource,
      building.address,
      localize,
    );

    setValue('pricePerKwh', pricePerKwh);

    if (!checkDistrictHeating(safeNewEnergySource).isDistrictHeating) {
      setValue('primaryEnergyFactorEmissionCertificateId', GlOBAL_EMISSION_CERTIFICATE_ID);
      setValue('primaryEnergyFactor', primaryEnergyFactor);
      return;
    }

    if (newPrimaryEnergyFactorEmissionCertificateId === GlOBAL_EMISSION_CERTIFICATE_ID) {
      return setValue('primaryEnergyFactor', primaryEnergyFactor);
    }

    const certificatePrimaryEnergyFactor = primaryEnergyFactorsCertificates.find(
      (source) => source.id === newPrimaryEnergyFactorEmissionCertificateId,
    )?.primaryEnergyFactor;

    if (certificatePrimaryEnergyFactor === undefined) {
      return;
    }

    setValue('primaryEnergyFactor', certificatePrimaryEnergyFactor);
  }, [
    building.address,
    localize,
    newEmissionCertificateId,
    newEnergySource,
    newPrimaryEnergyFactorEmissionCertificateId,
    primaryEnergyFactorsCertificates,
    setValue,
  ]);

  useEffect(() => {
    const safeNewEnergySource = maybeEnum(energy_source_type_enum, newEnergySource);
    if (!safeNewEnergySource) return;
    if (
      newEmissionCertificateId !== GlOBAL_EMISSION_CERTIFICATE_ID &&
      checkDistrictHeating(safeNewEnergySource).isDistrictHeating
    ) {
      const certificateEmissionFactor = emissionFactorsCertificates.find(
        (source) => source.id === newEmissionCertificateId,
      )?.emissionFactor;

      setValue('emissionFactor', certificateEmissionFactor, {
        shouldDirty: true,
      });
    } else {
      const defaultEmissionFactor = baseEmissionFactors[safeNewEnergySource];

      setValue('emissionFactor', defaultEmissionFactor, {
        shouldDirty: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue, newEnergySource, newEmissionCertificateId]);

  if (loading) {
    return <DelayedLoading />;
  }

  const handleClose = () => {
    onClose();
    reset(defaultValues);
  };

  const emissionFactorSources: EmissionFactorInfo[] = [
    defaultEmissionCertificateSource,
    ...emissionFactorsCertificates,
  ];

  const primaryEnergyFactorSources: PrimaryEnergyFactorInfo[] = [
    defaultEmissionCertificateSource,
    ...primaryEnergyFactorsCertificates,
  ];

  const onSubmit = (data: EnergySourceEditSchemaType) => {
    handleClose();

    if (isDirty) {
      const {
        energySourcefieldname,
        emissionFactorFieldname,
        primaryFactorFieldname,
        pricePerKwhFieldname,
        emissionCertificateIdFieldname,
        primaryEnergyFactorEmissionCertificateIdFieldname,
        //@ts-ignore
      } = generateSystemRouteFieldNames(energySystemType, systemIndex, index);

      setParentFormValue(energySourcefieldname, data.energySource, {
        shouldDirty: true,
        shouldValidate: true,
      });

      setParentFormValue(emissionCertificateIdFieldname, data.emissionCertificateId, {
        shouldDirty: true,
      });

      setParentFormValue(emissionFactorFieldname, data.emissionFactor, {
        shouldDirty: true,
      });
      setParentFormValue(primaryFactorFieldname, data.primaryEnergyFactor, {
        shouldDirty: true,
      });
      setParentFormValue(pricePerKwhFieldname, data.pricePerKwh);
      setParentFormValue(
        primaryEnergyFactorEmissionCertificateIdFieldname,
        data.primaryEnergyFactorEmissionCertificateId,
        {
          shouldDirty: true,
        },
      );

      //@ts-ignore
      const { dataSourceTypeFieldname } = generateSystemFieldNames(energySystemType, systemIndex);

      setParentFormValue(dataSourceTypeFieldname, data_source_type_enum.MANUAL, {
        shouldDirty: true,
      });

      if (checkDistrictHeating(data.energySource as energy_source_type_enum).isDistrictHeating) {
        updateAllRelatedEnergySources(
          getParentFormValues(),
          setParentFormValue,
          {
            emissionCertificateId: data.emissionCertificateId,
            primaryEnergyFactorEmissionCertificateId: data.primaryEnergyFactorEmissionCertificateId,
            emissionFactor: data.emissionFactor!,
            primaryEnergyFactor: data.primaryEnergyFactor!,
          },
          baseEmissionFactors,
          building.address,
          localize,
        );
      }
    }
  };

  return (
    <PreDialog open={open} onClose={onClose} fullWidth type="definedByChildren">
      <FormProvider
        methods={methods}
        onSubmit={(event) => {
          event.stopPropagation();
          event.preventDefault();
          handleSubmit(onSubmit)();
        }}
      >
        <PreDialogBody
          dialogtitle={
            <PreDialogTitle
              icon={
                <Box component="img" src={TechnologyIcons.energySourceV2} mt={0.25} mr={1} width={24} height={24} />
              }
              title={t('DataCollection_Technology-AddEnergySourceTitle')}
            />
          }
          content={
            <Box my={1}>
              <Grid container spacing={2}>
                <Grid item xs={12} mb={3}>
                  <RHFSelect
                    select
                    label={t('General_EnergySource')}
                    name="energySource"
                    helperText={t('DataCollection_Technology_EditEnergySourceHelperText')}
                    sx={getSelectStyles(isFieldDirty)}
                    onValueChange={(value) => {
                      if (!checkDistrictHeating(value as energy_source_type_enum).isDistrictHeating) {
                        setValue('emissionCertificateId', GlOBAL_EMISSION_CERTIFICATE_ID, { shouldDirty: true });
                      } else {
                        Object.entries(getDefaultValuesForDistrictHeating(getParentFormValues())).forEach(
                          ([key, value]) => {
                            setValue(key as keyof EnergySourceEditSchemaType, value, { shouldDirty: true });
                          },
                        );
                      }
                    }}
                  >
                    {supportedEnergySources.map((type) => (
                      <MenuItem sx={{ height: 40 }} key={type} value={type}>
                        {isFieldDirty && newEnergySource === type && (
                          <Box mt={0.5}>
                            <FieldIcon state={IconState.ManualEdit} />
                          </Box>
                        )}
                        <Box>{translateEnergySourceTypeEnum(type)}</Box>
                      </MenuItem>
                    ))}
                  </RHFSelect>
                </Grid>
                <Grid container spacing={2} ml={0} mb={2}>
                  <Grid item xs={12}>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography variant="subtitle1">{t('General_EmissionFactor')}</Typography>
                      <DetailedTooltip
                        labels={{
                          title: t('General_EmissionFactor'),
                          body: t('Education_EmissionFactorBody'),
                          equations: `${t('General_EmissionFactor')} * ${t('General_FinalEnergy')} = ${t(
                            'General_CO2Intensity',
                          )}`,
                        }}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={7}>
                    <RHFSelect
                      select
                      label={t('General_Source')}
                      name="emissionCertificateId"
                      disabled={!checkDistrictHeating(newEnergySource as energy_source_type_enum).isDistrictHeating}
                      sx={{
                        ...getSelectStyles(isEmissionCertificateIdDirty),
                        ...getSelectDisabledStyles(theme),
                      }}
                    >
                      {emissionFactorSources.map((source) => (
                        <MenuItem sx={{ height: 40 }} key={source.id} value={source.id}>
                          <Stack direction="row" alignItems="center" flex={'1'} justifyContent="space-between">
                            <Stack direction={'row'} alignItems="center">
                              {isEmissionCertificateIdDirty && newEmissionCertificateId === source.id && (
                                <Box mt={0.5}>
                                  <FieldIcon state={IconState.ManualEdit} />
                                </Box>
                              )}
                              <OverflowText
                                text={
                                  source.id === GlOBAL_EMISSION_CERTIFICATE_ID
                                    ? crremConfiguration.getTitle()
                                    : source.issuer
                                }
                                maxWidth={'160px'}
                                variant="body1"
                                TooltipProps={{
                                  placement: 'top',
                                }}
                              />
                            </Stack>
                            <Box>
                              <Label>
                                {source.id === GlOBAL_EMISSION_CERTIFICATE_ID
                                  ? t('General_LocationBased')
                                  : t('General_MarketBased')}
                              </Label>
                            </Box>
                          </Stack>
                        </MenuItem>
                      ))}
                    </RHFSelect>
                  </Grid>
                  <Grid item xs={5}>
                    <TechnologyNumberField
                      name="emissionFactor"
                      label={t('General_EmissionFactor')}
                      unit={Units.emissionFactor}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2} ml={0} mb={2}>
                  <Grid item xs={12}>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography variant="subtitle1">{t('General_PrimaryEnergyFactor')}</Typography>
                      <DetailedTooltip
                        variant="calculation"
                        labels={{
                          title: t('General_PrimaryEnergyFactor'),
                          body: t('Education_PrimaryEnergyFactorBody'),
                          equations: `${t('General_PrimaryEnergyFactor')} * ${t('General_FinalEnergy')} = ${t(
                            'General_PrimaryEnergy',
                          )}`,
                        }}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={7}>
                    <RHFSelect
                      select
                      label={t('General_Source')}
                      name="primaryEnergyFactorEmissionCertificateId"
                      disabled={!checkDistrictHeating(newEnergySource as energy_source_type_enum).isDistrictHeating}
                      sx={{
                        ...getSelectStyles(isPrimaryEnergyFactorEmissionCertificateIdDirty),
                        ...getSelectDisabledStyles(theme),
                      }}
                    >
                      {primaryEnergyFactorSources.map((source) => (
                        <MenuItem sx={{ height: 40 }} key={source.id} value={source.id}>
                          {isPrimaryEnergyFactorEmissionCertificateIdDirty &&
                            newPrimaryEnergyFactorEmissionCertificateId === source.id && (
                              <Box mt={0.5}>
                                <FieldIcon state={IconState.ManualEdit} />
                              </Box>
                            )}
                          <Stack direction="row" flex="1" justifyContent="space-between">
                            <OverflowText
                              text={
                                source.id === GlOBAL_EMISSION_CERTIFICATE_ID
                                  ? translateEmissionFactorTypeEnum_dynamic(emission_factor_type_enum.GEG, t)
                                  : source.issuer
                              }
                              maxWidth={'160px'}
                              variant="body1"
                              TooltipProps={{
                                placement: 'top',
                              }}
                            />
                            <Label>
                              {source.id === GlOBAL_EMISSION_CERTIFICATE_ID
                                ? t('General_LocationBased')
                                : t('General_MarketBased')}
                            </Label>
                          </Stack>
                        </MenuItem>
                      ))}
                    </RHFSelect>
                  </Grid>
                  <Grid item xs={5}>
                    <TechnologyNumberField name="primaryEnergyFactor" label={t('General_PrimaryEnergyFactor')} />
                  </Grid>
                </Grid>

                <Grid container spacing={2} ml={0} mb={2}>
                  <Grid item xs={12}>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography variant="subtitle1">{t('General_PricePerKwh')}</Typography>
                      <DetailedTooltip
                        labels={{
                          title: t('General_PricePerKwh'),
                          body: t('Education_PricePerKwhBody'),
                        }}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={7}>
                    <TechnologyTextField
                      name="invalid_key" // This is a dummy key because the key is required
                      label={t('General_Source')}
                      defaultValue={'Predium'}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <TechnologyNumberField
                      name="pricePerKwh"
                      label={t('General_PricePerKwh')}
                      unit={Units.pricePerEnergy}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          }
          actions={
            <>
              <Button variant="outlined" onClick={handleClose}>
                {t('General_Cancel')}
              </Button>
              <Button type="submit" variant="contained">
                {t('DataCollection_Technology_EnergySourceEditDone')}
              </Button>
            </>
          }
        ></PreDialogBody>
      </FormProvider>
    </PreDialog>
  );
};

export default EnergySourceAddModal;
