import { useMutation } from '@apollo/client';
import { Button } from '@mui/material';
import { ensureDefined, getFilename } from '@predium/utils';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Iconify from '../../../../../components/Iconify';
import { SnackbarTimeouts } from '../../../../../components/NotistackProvider';
import PreDialog from '../../../../../components/presentations/PreDialog/PreDialog';
import { EXPORT_CLIMATE_RISK_V2_REPORT } from '../../../../../graphql/DataCollection.mutations';
import usePosthogTracking from '../../../../../hooks/usePosthogTracking';
import { useLanguage } from '../../../../../provider/LanguageProvider';
import { downloadFileUri, onExportCompleted } from '../../../../../utils/createDownloadLink';
import useBuilding from '../../Context/useBuilding';
import { ClimateRiskV2ReportFiles } from '../types';
import ExportClimateRiskDialogContent, { OnExport } from './ExportClimateRiskDialogContent';

type Props = {
  reportFiles: ClimateRiskV2ReportFiles;
};

const ExportClimateRiskDialog = ({ reportFiles }: Props) => {
  const { t } = useTranslation();
  const { building } = useBuilding();
  const { language } = useLanguage();
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { trackEvent } = usePosthogTracking();

  const [exportExcelMutation] = useMutation(EXPORT_CLIMATE_RISK_V2_REPORT, {
    variables: {
      buildingId: building.id,
    },
    onError: () => {
      enqueueSnackbar(t('ClimateRiskV2_ExportDialog-Error'), {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
      });
    },
    onCompleted: () => {
      enqueueSnackbar(t('ClimateRiskV2_ExportDialog-Success'), {
        variant: 'success',
        autoHideDuration: SnackbarTimeouts.Success,
      });
    },
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const docxFile = reportFiles.find((file) => file.path.endsWith('.docx'));

  const handleExport: OnExport = ({ exportExcel, exportDocx }) => {
    if (exportExcel) {
      exportExcelMutation().then(({ data }) => {
        const body = data?.exportClimateRiskV2Report.body;
        if (body) {
          onExportCompleted(body, 'xlsx', ['Climate-Risk-Report'], language);
        }
      });
    }

    if (exportDocx) {
      const filePath = ensureDefined(docxFile).publicPath;

      if (filePath) {
        downloadFileUri(filePath, getFilename(['Climate-Risk-Document'], language) + '.docx');
      }
    }

    trackEvent('CLIMATE_RISK_V2_EXPORTED', {
      buildingId: building.id,
      exportExcel,
      exportDocx,
    });
  };

  return (
    <>
      <Button
        variant="contained"
        startIcon={<Iconify icon={'eva:cloud-download-outline'} width={20} height={20} />}
        color="primary"
        onClick={handleOpen}
      >
        {t('General_Export')}
      </Button>
      <PreDialog open={open} onClose={handleClose} type="definedByChildren" maxWidth="sm" fullWidth>
        <ExportClimateRiskDialogContent onClose={handleClose} onExport={handleExport} hasDocx={!!docxFile} />
      </PreDialog>
    </>
  );
};

export default ExportClimateRiskDialog;
