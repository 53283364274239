import { Box } from '@mui/material';
import { BuildingModelFragment, renovation_type_enum } from '@predium/client-graphql';
import { useEffect, useState } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import { CustomCostsForm } from '../ActionDetails/ActionPricing/RowCosts';
import CustomActionToggle, { CUSTOM_ACTION_TYPE } from '../Components/CustomActionToggle';
import { CustomActionFormRef } from '../CreateAction';
import CustomEnergeticAction from './CustomActions/CustomEnergeticAction';
import MaintenanceAction from './CustomActions/MaintenanceAction';

export default function CustomAction({
  formRef,
  setSelectedActionType,
  resetSimulatedData,
  previousActionBuildingModel,
  minimumDate,
  loading,
  setCostFormValue,
}: {
  formRef: CustomActionFormRef;
  minimumDate: Date;
  loading: boolean;
  setCostFormValue: UseFormSetValue<CustomCostsForm>;
  resetSimulatedData: () => void;
  previousActionBuildingModel: BuildingModelFragment;
  setSelectedActionType: (actionType: renovation_type_enum) => void;
}) {
  const [energeticImpact, setEnergeticImpact] = useState<CUSTOM_ACTION_TYPE | null>(null);

  useEffect(() => {
    setSelectedActionType(
      energeticImpact === 'NO_IMPACT' ? renovation_type_enum.MAINTENANCE : renovation_type_enum.CUSTOM,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [energeticImpact]);

  const onChange = (type: CUSTOM_ACTION_TYPE) => {
    setEnergeticImpact(type);
  };

  return (
    <Box sx={{ mt: 3 }}>
      <CustomActionToggle energeticImpact={energeticImpact} onChange={onChange} />

      {energeticImpact === 'NO_IMPACT' ? (
        <MaintenanceAction
          minimumDate={minimumDate}
          loading={loading}
          setCostFormValue={setCostFormValue}
          ref={formRef}
        />
      ) : energeticImpact === 'WITH_IMPACT' ? (
        <CustomEnergeticAction
          minimumDate={minimumDate}
          loading={loading}
          setCostFormValue={setCostFormValue}
          previousActionBuildingModel={previousActionBuildingModel}
          resetSimulatedData={resetSimulatedData}
          ref={formRef}
        />
      ) : null}
    </Box>
  );
}
