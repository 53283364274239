import { MenuItem } from '@mui/material';
import { ActionPlanningScenariosScenarioFragment } from '@predium/client-graphql';
import { useTranslation } from 'react-i18next';
import { DeleteIcon } from '../../../assets/Icons/DeleteIcon';
import { EditIcon } from '../../../assets/Icons/EditIcon';
import ActionMoreMenu from '../../../components/common/ActionMoreMenu';
import DeleteScenarioDialog from './DeleteScenarioDialog';
import { DownloadScenarioButton } from './DownloadScenarioButton';
import EditScenarioDialog from './EditScenarioDialog';
import { useDeleteScenarioDialog, useEditScenarioDialog } from './hooks';

type Props = {
  scenario: ActionPlanningScenariosScenarioFragment;
  hasActionPlans: boolean;
  canEditOrDelete: boolean;
  otherScenarioNames: string[];
};

const ScenarioActionMenuWithoutCompareScenario = ({
  scenario,
  hasActionPlans,
  canEditOrDelete,
  otherScenarioNames,
}: Props) => {
  const { t } = useTranslation();

  const [isEditScenarioDialogOpen, handleEditScenarioClick] = useEditScenarioDialog();

  const [isDeleteScenarioDialogOpen, handleDeleteScenarioClick, scenarioToDelete] = useDeleteScenarioDialog(scenario);

  return (
    <>
      {hasActionPlans ? <DownloadScenarioButton scenarioId={scenario.id} scenarioName={scenario.name} /> : undefined}
      {!canEditOrDelete && !hasActionPlans ? null : (
        <>
          <ActionMoreMenu
            ariaLabel={t('ActionPlanningScenario_MoreActions')}
            variant="icon"
            actions={
              <>
                {canEditOrDelete ? (
                  <MenuItem onClick={handleEditScenarioClick}>
                    <EditIcon text={t('General_Edit')} />
                  </MenuItem>
                ) : undefined}
                {canEditOrDelete ? (
                  <MenuItem onClick={handleDeleteScenarioClick} sx={{ color: 'error.main' }}>
                    <DeleteIcon text={t('General_Delete')} />
                  </MenuItem>
                ) : undefined}
              </>
            }
          />
          <EditScenarioDialog
            isOpen={isEditScenarioDialogOpen}
            handleOnClose={handleEditScenarioClick}
            scenario={scenario}
            scenariosNames={otherScenarioNames}
          />
          <DeleteScenarioDialog
            deleteModalOpen={isDeleteScenarioDialogOpen}
            handleDeleteScenarioClick={handleDeleteScenarioClick}
            scenario={scenarioToDelete}
          />
        </>
      )}
    </>
  );
};

export default ScenarioActionMenuWithoutCompareScenario;
