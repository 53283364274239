import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Stack, Typography } from '@mui/material';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormProvider } from '../../../../../../components/hook-form';
import { AutoCompleteOption } from '../../../../../../components/hook-form/RHFAutoComplete';
import usePosthogTracking from '../../../../../../hooks/usePosthogTracking';
import { ActiveStepRef, BuildingCreationFormCast, useBuildingCreation } from '../../BuildingCreationProvider';
import AddressSearchInput from '../AddressSearchInput';
import { AddressSearchFormType, getAddressSearchFormSchema } from '../validations';

export const ADDRESS_SEARCH = 'ADDRESS_SEARCH' as const;
export type AddressSearchFormRef = {
  type: typeof ADDRESS_SEARCH;
  onSubmit: UseFormReturn<AddressSearchFormType>['handleSubmit'];
  onSubmitSuccess: (formValues: Partial<BuildingCreationFormCast>) => void;
};

export type AddressSearchFormValues = ReturnType<ReturnType<typeof getAddressSearchFormSchema>['cast']>;

type Props = {
  defaultValues: AddressSearchFormValues;
  onSubmitSuccess: (formValues: Partial<BuildingCreationFormCast>) => void;
  onValidChange: (isValid: boolean) => void;
};

const AddressSearch = forwardRef<ActiveStepRef, Props>(({ defaultValues, onSubmitSuccess, onValidChange }, ref) => {
  const { t } = useTranslation();
  const schema = getAddressSearchFormSchema(t);
  const { handleNext } = useBuildingCreation();
  const { trackEvent } = usePosthogTracking();

  const methods = useForm<AddressSearchFormType>({
    defaultValues,
    resolver: yupResolver(schema, { stripUnknown: true }),
  });

  useImperativeHandle(ref, () => ({
    type: ADDRESS_SEARCH,
    onSubmit: methods.handleSubmit,
    onSubmitSuccess,
  }));

  useEffect(() => {
    onValidChange(methods.formState.isValid);
  }, [methods.formState.isSubmitted, methods.formState.isValid, onValidChange, methods.formState.isDirty]);

  const handleAddressChange = (address: AutoCompleteOption | null) => {
    const isManualInput = typeof address === 'object' && address?.inputValue;

    trackEvent('ADDRESS_SELECTED', { manual_input: isManualInput });

    if (isManualInput) handleNext();
  };

  return (
    <FormProvider methods={methods}>
      <Grid container maxWidth="sm" mx="auto" spacing={2} py={3}>
        <Grid xs={12} pt={2}>
          <Stack spacing={1} mb={2}>
            <Typography variant="h4">{t('BuildingCreation_ManualAddressTitle')}</Typography>
            <Typography variant="body1" color="text.secondary">
              {t('BuildingCreation_ManualAddressSubtitle')}
            </Typography>
          </Stack>
        </Grid>
        <Grid xs={12} pl={0} pt={2}>
          <AddressSearchInput onAddressChange={handleAddressChange} onEnterPress={handleNext} />
        </Grid>
      </Grid>
    </FormProvider>
  );
});

export default AddressSearch;
