/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as Sentry from '@sentry/react';
import { ElementType, lazy, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useRoutes } from 'react-router-dom';
import AdminAccessRequired from '../components/error-boundaries/AdminErrorBoundary';
import PageErrorBoundary from '../components/error-boundaries/PageErrorBoundary';
import LoadingScreen from '../components/LoadingScreen';
import { DataCollectionFilterContextProvider } from '../contexts/DataCollectionFiltersContext';
import { EsgAnalysisFilterContextProvider } from '../contexts/EsgAnalysisFilterContext';
import AuthGuard from '../guards/AuthGuard';
import GuestGuard from '../guards/GuestGuard';
import useSubscriptionWithFallback from '../hooks/useSubscriptionFallback';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import ActionPlanningActionPlan from '../pages/ActionPlanning/ActionPlanningActionPlan';
import ActionPlanningScenario from '../pages/ActionPlanning/ActionPlanningScenario';
import ActionPlanningScenarios from '../pages/ActionPlanning/ActionPlanningScenarios';
import Login from '../pages/authentication/Login';
import DataCollectionBuilding from '../pages/DataCollection/DataCollectionBuilding';
import DataCollectionEnergyCertificateDraft from '../pages/DataCollection/DataCollectionEnergyCertificateDraft';
import DataCollectionSubBuildingConsumption from '../pages/DataCollection/DataCollectionSubBuildingConsumption';
import DataCollectionSubBuildingConsumptionDraft from '../pages/DataCollection/DataCollectionSubBuildingConsumptionDraft';
import DataCollectionTabs from '../pages/DataCollection/DataCollectionTabs';
import EmissionCertificateDraftPage from '../pages/DataCollection/EmissionCertificates/EmissionCertificateDraftPage';
import EmissionCertificatePage from '../pages/DataCollection/EmissionCertificates/EmissionCertificatePage';
import EmissionCertificatesTabs from '../pages/DataCollection/EmissionCertificates/EmissionCertificatesTabs';
import EsgAnalysisBuilding from '../pages/EsgAnalysis/EsgAnalysisBuilding';
import EsgAnalysisEconomicUnits, {
  EsgAnalysisEconomicUnitsNotActivated,
} from '../pages/EsgAnalysis/EsgAnalysisEconomicUnits';
import EsgAnalysisPortfolios from '../pages/EsgAnalysis/EsgAnalysisPortfolios';
import OrgSettings from '../pages/Org/OrgSettings';
import PortfolioManagement from '../pages/Org/PortfolioManagement';
import UserProfile from '../pages/Org/UserProfile';
import Users from '../pages/Org/Users';
import ReportingReport from '../pages/Reporting/ReportingReport';
import ReportingReports from '../pages/Reporting/ReportingReports';
import { BuildingContextProvider } from '../sections/DataCollection/Building/Context/BuildingContext';
import BuildingCreation from '../sections/DataCollection/Buildings/BuildingCreation/BuildingCreation';
import { ActionPlanContextProvider } from '../sections/Scenarios/ActionPlan/Context/ActionPlanContext';
import { PATH_AUTH, PATHS } from './paths';
import { Redirect, redirects, RedirectToEsgAnalysisBuilding } from './redirects';
import { removeRootPath } from './utils';

// ----------------------------------------------------------------------

const useWrappedRoutes = Sentry.wrapUseRoutes(useRoutes);

const Loadable = (Component: ElementType) => (props: any) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return useWrappedRoutes([
    {
      path: removeRootPath(PATH_AUTH.root),
      children: [
        {
          path: removeRootPath(PATH_AUTH.login),
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        { path: removeRootPath(PATH_AUTH.loginUnprotected), element: <Login /> },
      ],
    },

    {
      path: PATHS.root(),
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: '',
          element: <RedirectToEsgAnalysisBuilding />,
        },

        // ESG-analysis
        {
          path: removeRootPath(PATHS.esgAnalysis.root()),
          element: <RedirectToEsgAnalysisBuilding />,
        },
        {
          path: removeRootPath(PATHS.esgAnalysis.buildings()),
          element: (
            <PageErrorBoundary pageName={t('PageName_ESGAnalysisBuildingView')}>
              <RedirectToEsgAnalysisBuilding />
            </PageErrorBoundary>
          ),
        },
        {
          path: removeRootPath(PATHS.esgAnalysis.building({ id: ':id' })),
          element: (
            <PageErrorBoundary pageName={t('PageName_ESGAnalysisBuildingView')}>
              <EsgAnalysisBuilding />
            </PageErrorBoundary>
          ),
        },
        {
          path: removeRootPath(PATHS.esgAnalysis.portfolio()),
          element: (
            <PageErrorBoundary pageName={t('PageName_ESGAnalysisPortfolioView')}>
              <EsgAnalysisFilterContextProvider>
                <EsgAnalysisPortfolios />
              </EsgAnalysisFilterContextProvider>
            </PageErrorBoundary>
          ),
        },
        {
          path: removeRootPath(PATHS.esgAnalysis.economicUnits()),
          element: (
            <PageErrorBoundary pageName={t('PageName_ESGAnalysisEconomicUnitView')}>
              <EsgAnalysisEconomicUnitsNotActivated>
                <EsgAnalysisFilterContextProvider>
                  <EsgAnalysisEconomicUnits />
                </EsgAnalysisFilterContextProvider>
              </EsgAnalysisEconomicUnitsNotActivated>
            </PageErrorBoundary>
          ),
        },

        // Action planning

        {
          path: removeRootPath(PATHS.actionPlanning.scenarios()),
          element: (
            <PageErrorBoundary pageName={t('PageName_Scenarios')}>
              <ActionPlanningScenarios />
            </PageErrorBoundary>
          ),
        },
        {
          path: removeRootPath(PATHS.actionPlanning.scenario({ id: ':id' })),
          element: (
            <PageErrorBoundary pageName={t('PageName_Scenario')}>
              <ActionPlanningScenario />
            </PageErrorBoundary>
          ),
        },
        {
          path: removeRootPath(PATHS.actionPlanning.actionPlan({ id: ':id', scenarioId: ':scenarioId' })),
          element: (
            <PageErrorBoundary pageName={t('PageName_ActionPlan')}>
              <ActionPlanContextProvider>
                <ActionPlanningActionPlan />
              </ActionPlanContextProvider>
            </PageErrorBoundary>
          ),
        },
        //Reporting
        {
          path: removeRootPath(PATHS.reporting.root()),
          element: <Navigate to={PATHS.reporting.reports()} replace />,
        },
        {
          path: removeRootPath(PATHS.reporting.reports()),
          element: (
            <PageErrorBoundary pageName={t('PageName_Reports')}>
              <ReportingReports />
            </PageErrorBoundary>
          ),
        },
        {
          path: removeRootPath(PATHS.reporting.report({ id: ':id' })),
          element: (
            <PageErrorBoundary pageName={t('PageName_Report')}>
              <ReportingReport />
            </PageErrorBoundary>
          ),
        },

        //Data collection

        {
          path: removeRootPath(PATHS.dataCollection.root()),
          element: <Navigate to={PATHS.dataCollection.buildings()} replace />,
        },
        {
          path: removeRootPath(PATHS.dataCollection.buildings()),
          element: (
            <DataCollectionFilterContextProvider>
              <PageErrorBoundary pageName={t('PageName_DataCollection')}>
                <DataCollectionTabs useSubscription={useSubscriptionWithFallback} />
              </PageErrorBoundary>
            </DataCollectionFilterContextProvider>
          ),
        },
        {
          path: removeRootPath(PATHS.dataCollection.building({ id: ':id' })),
          element: (
            <DataCollectionFilterContextProvider>
              <BuildingContextProvider>
                <PageErrorBoundary pageName={t('PageName_BuildingOverview')}>
                  <DataCollectionBuilding useSubscription={useSubscriptionWithFallback} />
                </PageErrorBoundary>
              </BuildingContextProvider>
            </DataCollectionFilterContextProvider>
          ),
        },
        {
          path: removeRootPath(PATHS.dataCollection.energyCertificateDraft({ id: ':id' })),
          element: (
            <DataCollectionFilterContextProvider>
              <PageErrorBoundary pageName={t('PageName_DataVerification')}>
                <DataCollectionEnergyCertificateDraft useSubscription={useSubscriptionWithFallback} />
              </PageErrorBoundary>
            </DataCollectionFilterContextProvider>
          ),
        },
        {
          path: removeRootPath(PATHS.dataCollection.buildingDraftNew()),
          element: (
            <PageErrorBoundary pageName={t('PageName_DataCollection_BuildingDraft')}>
              <BuildingCreation open onClose={() => navigate(PATHS.dataCollection.buildings())} />
            </PageErrorBoundary>
          ),
        },
        {
          path: removeRootPath(PATHS.dataCollection.buildingDraft({ id: ':id' })),
          element: (
            <PageErrorBoundary pageName={t('PageName_DataCollection_BuildingDraft')}>
              <BuildingCreation open onClose={() => navigate(PATHS.dataCollection.buildings())} />
            </PageErrorBoundary>
          ),
        },
        {
          path: removeRootPath(PATHS.dataCollection.emissionCertificates()),
          element: (
            <PageErrorBoundary pageName={t('PageName_DataCollection_EmissionCertificates')}>
              <EmissionCertificatesTabs />
            </PageErrorBoundary>
          ),
        },
        {
          path: removeRootPath(PATHS.dataCollection.emissionCertificateDrafts()),
          element: (
            <PageErrorBoundary pageName={t('PageName_DataCollection_EmissionCertificates')}>
              <AdminAccessRequired>
                <EmissionCertificatesTabs />
              </AdminAccessRequired>
            </PageErrorBoundary>
          ),
        },

        //Org
        {
          path: removeRootPath(PATHS.org.users()),
          element: (
            <PageErrorBoundary pageName={t('PageName_ManageUsers')}>
              <AdminAccessRequired>
                <Users />
              </AdminAccessRequired>
            </PageErrorBoundary>
          ),
        },
        {
          path: removeRootPath(PATHS.org.user({ id: ':id' })),
          element: (
            <PageErrorBoundary pageName={t('PageName_ManageUsers')}>
              <AdminAccessRequired>
                <UserProfile />
              </AdminAccessRequired>
            </PageErrorBoundary>
          ),
        },
        {
          path: removeRootPath(PATHS.org.settings()),
          element: (
            <PageErrorBoundary pageName={t('PageName_Settings')}>
              <AdminAccessRequired>
                {/*@ts-ignore */}
                <OrgSettings />
              </AdminAccessRequired>
            </PageErrorBoundary>
          ),
        },
        {
          path: removeRootPath(PATHS.org.portfolios()),
          element: (
            <PageErrorBoundary pageName={t('PageName_PortfolioOverview')}>
              <AdminAccessRequired>
                <PortfolioManagement />
              </AdminAccessRequired>
            </PageErrorBoundary>
          ),
        },
      ],
    },

    // FOCUS PAGES
    {
      path: removeRootPath(PATHS.dataCollection.emissionCertificate({ id: ':id' })),

      element: (
        <AuthGuard>
          <PageErrorBoundary pageName={t('PageName_DataCollection_EmissionCertificate')}>
            <EmissionCertificatePage />
          </PageErrorBoundary>
        </AuthGuard>
      ),
    },
    {
      path: removeRootPath(PATHS.dataCollection.emissionCertificateDraft({ id: ':id' })),
      element: (
        <AuthGuard>
          <PageErrorBoundary pageName={t('PageName_DataCollection_EmissionCertificates')}>
            <EmissionCertificateDraftPage />
          </PageErrorBoundary>
        </AuthGuard>
      ),
    },
    {
      path: removeRootPath(PATHS.dataCollection.buildingConsumptionInvoiceDraft({ id: ':id', draftId: ':draftId' })),
      element: (
        <AuthGuard>
          <PageErrorBoundary pageName={t('PageName_ConsumptionDraft')}>
            <DataCollectionFilterContextProvider>
              <DataCollectionSubBuildingConsumptionDraft useSubscription={useSubscriptionWithFallback} />
            </DataCollectionFilterContextProvider>
          </PageErrorBoundary>
        </AuthGuard>
      ),
    },
    {
      path: removeRootPath(
        PATHS.dataCollection.buildingConsumptionInvoice({ id: ':id', consumptionInvoiceId: ':consumptionInvoiceId' }),
      ),
      element: (
        <AuthGuard>
          <PageErrorBoundary pageName={t('PageName_Consumption')}>
            <DataCollectionFilterContextProvider>
              <DataCollectionSubBuildingConsumption />
            </DataCollectionFilterContextProvider>
          </PageErrorBoundary>
        </AuthGuard>
      ),
    },

    ...redirects.map((redirect) => ({
      path: redirect.from,
      element: <Redirect redirect={redirect} />,
    })),
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: removeRootPath(PATHS.notFound()), element: <NotFound /> },
        { path: '*', element: <Navigate to={PATHS.notFound()} replace /> },
      ],
    },
    { path: '*', element: <Navigate to={PATHS.notFound()} replace /> },
  ]);
}

const NotFound = Loadable(lazy(() => import('../pages/Page404')));
