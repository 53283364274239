import { GetClimateRiskV2ReportQuery } from '@predium/client-graphql';
import { RiskScenario } from './mapping';

export enum ViewMode {
  RISK_CATEGORY = 'Risk Category',
  PERCENTAGE = 'Percentage',
  MONETARY = 'Monetary',
}

export type ClimateRiskV2Report = NonNullable<GetClimateRiskV2ReportQuery['readClimateRiskV2Report']['data']>;
export type ClimateRiskV2ReportFiles = ClimateRiskV2Report['files'];
export type Scenarios = ClimateRiskV2Report['scenarios'];
export type Scenario = Scenarios[number];
export type Risks = Scenario['risks'];
export type RiskWithScenario = Risks[number] & { scenario: RiskScenario };
