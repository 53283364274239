import { DialogContent, DialogTitle, Grid, useTheme } from '@mui/material';
import { ActionPlanningScenariosScenarioFragment, AnalyzeScenarioOutputFragment } from '@predium/client-graphql';
import { useTranslation } from 'react-i18next';
import PreDialog from '../../../../components/presentations/PreDialog/PreDialog';
import { useMeasureOnResizeHeight } from '../hooks';
import { CompareScenarioCard } from './CompareScenarioCard';
import { CompareScenariosAlertInfo } from './CompareScenariosAlertInfo';
import { CompareScenariosSelect } from './CompareScenariosSelect';
import { CompareScenariosEnergeticMetrics } from './EnergeticMetrics/CompareScenariosEnergeticMetrics';
import { useCompareScenarioSelect } from './useCompareScenarioSelect';

export type CompareScenarioDialogProps = {
  isOpen: boolean;
  handleOnClose: VoidFunction;
  scenario: ActionPlanningScenariosScenarioFragment;
  analyzedScenario?: AnalyzeScenarioOutputFragment;
  scenarioOneTotalActions: number;
};

export const CompareScenarioDialog = ({
  isOpen,
  handleOnClose,
  scenario,
  analyzedScenario,
  scenarioOneTotalActions,
}: CompareScenarioDialogProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const scenarioOneDataColor = theme.palette.scenarioComparisonColors.scenarioOne;
  const scenarioTwoDataColor = theme.palette.scenarioComparisonColors.scenarioTwo;

  const [state, isLoadingSelectValues, handleSecondScenarioChange, handleSelectChangeClick] = useCompareScenarioSelect(
    scenario.portfolio.id,
    scenario.id,
  );

  // padding-y=48, dialogTitleHeight=76
  const [dialogContentRef, dialogContentHeight] = useMeasureOnResizeHeight(window.innerHeight - 48 - 76);

  return (
    <PreDialog type={'definedByChildren'} open={isOpen} onClose={handleOnClose} maxWidth="lg" fullWidth>
      <DialogTitle variant={'h6'} gutterBottom={false}>
        {t('ActionPlanningScenario_CompareScenarios')}
      </DialogTitle>
      <DialogContent ref={dialogContentRef} dividers={true} sx={{ padding: 0, overflowY: 'hidden' }}>
        <Grid container wrap={'nowrap'} spacing={3} sx={{ padding: 3, paddingRight: 0, paddingBottom: 0 }}>
          <Grid
            item
            container
            spacing={3}
            xs={4}
            sx={{ borderRight: '1px solid', borderColor: theme.palette.divider, paddingBottom: 3, paddingRight: 3 }}
            alignContent={'start'}
          >
            <Grid item xs={12}>
              <CompareScenariosAlertInfo />
            </Grid>
            <Grid item xs={12}>
              <CompareScenarioCard scenario={scenario} dataColor={scenarioOneDataColor} />
            </Grid>
            <Grid item xs={12}>
              <CompareScenariosSelect
                state={state}
                handleSecondScenarioChange={handleSecondScenarioChange}
                isLoadingSelectValues={isLoadingSelectValues}
                scenarioTwoDataColor={scenarioTwoDataColor}
                handleSelectChangeClick={handleSelectChangeClick}
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            spacing={3}
            xs={8}
            sx={{
              padding: 3,
              overflowY: 'auto',
              height: `${dialogContentHeight}px`,
            }}
          >
            <CompareScenariosEnergeticMetrics
              scenarioOne={{ id: scenario.id, name: scenario.name }}
              analyzedScenarioOne={analyzedScenario}
              scenarioOneTotalActions={scenarioOneTotalActions}
              scenarioTwo={{ id: state.selectedId, name: state.selectedName }}
              scenarioOneDataColor={scenarioOneDataColor}
              scenarioTwoDataColor={scenarioTwoDataColor}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </PreDialog>
  );
};
