import { useMutation, useQuery, type useSubscription } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, AlertTitle, Button, Grid, MenuItem, Typography } from '@mui/material';
import { PortfolioManagementPortfolioFragment } from '@predium/client-graphql';
import { async_job_status_type_enum } from '@predium/enums';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { object, string } from 'yup';
import FullScreenLoader from '../../../components/FullScreenLoader';
import Label from '../../../components/Label';
import { SnackbarTimeouts } from '../../../components/NotistackProvider';
import { FormProvider, RHFSelect, RHFTextField } from '../../../components/hook-form';
import PreDialog, { PreDialogBody } from '../../../components/presentations/PreDialog/PreDialog';
import { UPDATE_PORTFOLIO } from '../../../graphql/Portfolio.mutations';
import { GET_PORTFOLIOS } from '../../../graphql/Portfolio.queries';
import { ASYNC_JOB_SUBSCRIPTION, GET_ORG_PREFERENCES } from '../../../graphql/common.queries';
import useOrgPreferences from '../../../hooks/useOrgPreferences';
import usePosthogTracking from '../../../hooks/usePosthogTracking';
import useSubscriptionWithFallback from '../../../hooks/useSubscriptionFallback';
import CRREMConfigToggle, { CRREMConfigToggleEnum } from './EmissionFactorToggle';
import { CRREM_CONFIG_REQUIRED_MESSAGE, PortfolioFormValuesProps } from './PortfolioCreateDialog';

type Props = {
  portfolio: PortfolioManagementPortfolioFragment;
  open: boolean;
  onClose: () => void;
  useSubscription: typeof useSubscription;
};

function PortfolioEditDialogContent({
  open,
  onClose,
  portfolio,
  setJobStatus,
  useSubscription,
}: Props & { setJobStatus: (status: async_job_status_type_enum) => void }) {
  const { t } = useTranslation();
  const { trackEvent } = usePosthogTracking();
  const { enqueueSnackbar } = useSnackbar();
  const initialPageLoad = useRef<boolean>(true);
  const { crremConfigurations, activeCRREMConfiguration: activeCRREMConfigurationForOrg } = useOrgPreferences();

  const activeConfigurationForPortfolio = crremConfigurations.find((c) => c.isActiveForPortfolio(portfolio.id));
  const [currentEmissionFactorToggle, setCurrentEmissionFactorToggle] = useState<CRREMConfigToggleEnum>(
    activeConfigurationForPortfolio ? CRREMConfigToggleEnum.CUSTOM : CRREMConfigToggleEnum.DEFAULT,
  );

  const { data } = useQuery(GET_PORTFOLIOS, { skip: !open });

  const portfolios = data?.portfolio ?? [];

  const [updateMutateFunction, { loading }] = useMutation(UPDATE_PORTFOLIO, {
    refetchQueries: [GET_PORTFOLIOS, GET_ORG_PREFERENCES],
  });

  useSubscription(ASYNC_JOB_SUBSCRIPTION, {
    skip: !open,
    variables: {
      title: 'RECALCULATE_PORTFOLIO',
      entityId: portfolio?.id,
    },
    onData: ({ data: subscriptionData }) => {
      if (!subscriptionData.data || !subscriptionData.data.async_job.length) {
        return;
      }

      const latestJob = subscriptionData.data.async_job[0];
      const status: async_job_status_type_enum = latestJob.status_id;
      setJobStatus(status);
      if (initialPageLoad.current && status !== async_job_status_type_enum.PENDING) {
        // Make sure we don't show the snackbar on initial page load if the job is already in complete / error state
        initialPageLoad.current = false;
        return;
      }
      initialPageLoad.current = false;

      // TODO: the following snacks are only displayed if this Dialog is mounted --> and this is only the case if at least the menu (...) is clicked
      // Do we even need the snacks then?
      if (status === async_job_status_type_enum.COMPLETED) {
        enqueueSnackbar(t('General_ChangesSavedSuccessfully'), {
          variant: 'success',
          autoHideDuration: SnackbarTimeouts.Success,
        });
      } else if (status === async_job_status_type_enum.ERROR) {
        enqueueSnackbar(t('General_FailedToSaveChanges'), {
          variant: 'error',
          autoHideDuration: SnackbarTimeouts.Error,
        });
      }
    },
  });

  const NewPortfolioSchema = object().shape({
    name: string()
      .required(t('General_PortfolioNameIsRequired'))
      .notOneOf(
        portfolios.map((p) => p.name).filter((n) => n !== portfolio?.name),
        t('General_PortfolioAlreadyExists'),
      ),
    crremConfigId: string()
      .required(CRREM_CONFIG_REQUIRED_MESSAGE)
      .oneOf(
        crremConfigurations.map((config) => config.getStringId()),
        CRREM_CONFIG_REQUIRED_MESSAGE,
      ),
  });

  const defaultValues: PortfolioFormValuesProps = useMemo(
    () => ({
      name: portfolio?.name || '',
      crremConfigId: activeConfigurationForPortfolio?.getStringId() ?? activeCRREMConfigurationForOrg.getStringId(),
    }),
    [activeConfigurationForPortfolio, portfolio?.name, activeCRREMConfigurationForOrg],
  );

  const methods = useForm<PortfolioFormValuesProps>({
    resolver: yupResolver(NewPortfolioSchema),
    defaultValues,
    mode: 'onChange',
  });

  const { handleSubmit, watch, control, setValue, reset } = methods;
  const { dirtyFields } = useFormState({ control });
  const crremConfigFieldName = 'crremConfigId';

  const currentCRREMConfigType = watch(crremConfigFieldName);

  useEffect(() => reset(defaultValues), [reset, defaultValues]);

  const onEmissionTypeToggle = (value: CRREMConfigToggleEnum) => {
    setCurrentEmissionFactorToggle(value);
    if (value === CRREMConfigToggleEnum.DEFAULT) {
      setValue(crremConfigFieldName, activeCRREMConfigurationForOrg.getStringId(), {
        shouldValidate: true,
      });
    } else {
      setValue(crremConfigFieldName, activeConfigurationForPortfolio?.getStringId() ?? '');
    }
  };

  const onSubmit = async (data: PortfolioFormValuesProps) => {
    onClose();

    const chosenConfig = crremConfigurations.find((config) => config.getStringId() === data.crremConfigId);

    const result = await updateMutateFunction({
      variables: {
        name: data.name,
        id: portfolio.id,
        ...(currentEmissionFactorToggle === CRREMConfigToggleEnum.DEFAULT
          ? null
          : {
              baseEmissionFactorType: chosenConfig?.getBaseEmissionFactor(),
              configId: chosenConfig?.getConfigId(),
            }),
      },
    });

    if (result.errors?.length !== 0 || !result.errors) {
      trackEvent('PORTFOLIO_UPDATED', {
        emissionFactorType:
          currentEmissionFactorToggle === CRREMConfigToggleEnum.DEFAULT ? null : chosenConfig?.getStringId() ?? null,
      });
    }
  };

  return (
    <FormProvider
      methods={methods}
      onSubmit={(event) => {
        event.stopPropagation();
        event.preventDefault();
        handleSubmit(onSubmit)();
      }}
    >
      <PreDialogBody
        dialogtitle={t('PortfolioEditDialog_Title')}
        content={
          <Grid container>
            <Grid item xs={12}>
              <RHFTextField name="name" label={t('General_Name')} sx={{ mt: 1 }} />
            </Grid>
            <Grid container alignItems={'center'} mt={4} mb={2}>
              <Grid item xs={8}>
                <Typography variant="subtitle1">{t('General_DatabaseEmissionFactors')}</Typography>
              </Grid>
              <Grid item xs={4}>
                <CRREMConfigToggle
                  onEmissionTypeToggle={onEmissionTypeToggle}
                  defaultValue={currentEmissionFactorToggle}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} mb={2}>
              <RHFSelect
                select
                label={t('General_SelectDatabaseEmissionFactors')}
                name={crremConfigFieldName}
                disabled={currentEmissionFactorToggle === CRREMConfigToggleEnum.DEFAULT}
              >
                {crremConfigurations.map((config) => (
                  <MenuItem
                    key={config.getStringId()}
                    value={config.getStringId()}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    {config.getTitleElement()}
                    {config.getStringId() === activeCRREMConfigurationForOrg.getStringId() && (
                      <Label ml={1}> {t('General_Standard')} </Label>
                    )}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Grid>
            {dirtyFields.crremConfigId &&
              currentCRREMConfigType !== activeCRREMConfigurationForOrg.getStringId() &&
              currentCRREMConfigType !== '' && (
                <Alert severity="warning" sx={{ mt: 3, textAlign: 'justify' }}>
                  <AlertTitle>{t('General_CO2KeyFiguresAreBeingRecalculated')}</AlertTitle>
                  {t('UpdatePortfolioEmissionFactorDialog_Description')}
                </Alert>
              )}
          </Grid>
        }
        actions={
          <>
            <Button variant="outlined" onClick={onClose}>
              {t('General_Cancel')}
            </Button>
            <LoadingButton type="submit" variant="contained" loading={loading}>
              {t('General_Save')}
            </LoadingButton>
          </>
        }
      ></PreDialogBody>
    </FormProvider>
  );
}

export default function PortfolioEditDialog({ open, onClose, portfolio }: Props) {
  const { t } = useTranslation();
  const [jobStatus, setJobStatus] = useState<async_job_status_type_enum | null>(null);

  const hasBuildings = (portfolio?.economic_units_with_buildings?.flatMap((eu) => eu.buildings).length ?? 0) > 0;
  return (
    <>
      <PreDialog open={open} onClose={onClose} fullWidth type="definedByChildren">
        {open && (
          <PortfolioEditDialogContent
            useSubscription={useSubscriptionWithFallback}
            open={open}
            onClose={onClose}
            portfolio={portfolio}
            setJobStatus={setJobStatus}
          />
        )}
      </PreDialog>

      <FullScreenLoader
        open={jobStatus === async_job_status_type_enum.PENDING && hasBuildings}
        text={t('General_KeyFiguresAreBeingRecalculated')}
        disableCloseAction={true}
        onClose={() => onClose()}
      />
    </>
  );
}
