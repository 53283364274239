import { Typography } from '@mui/material';
import { Units, UnitsValue } from '@predium/utils';
import { ICONS } from '../../assets/icons';
import { useLanguage } from '../../provider/LanguageProvider';
import Iconify from '../Iconify';
import Label, { LabelColor } from '../Label';
import { classifyChange } from './WidgetSummary';

type Props = {
  change: number;
  unit?: Extract<UnitsValue, 'PERCENTAGE' | 'YEAR'>;
  inverse?: boolean;
  digits?: 0 | 1;
};

export default function WidgetSummaryLabel({ change, unit = Units.percentage, inverse = false, digits = 1 }: Props) {
  const { localize } = useLanguage();

  let percent = Number(((change ?? 0) * 100).toFixed(digits));
  const changeDirection = classifyChange(percent);
  if (changeDirection === 'neutral') {
    if (unit === Units.percentage) return <Label sx={{ minWidth: '75px' }}>{localize.formatAsPercentage(0)}</Label>;
    return <Label sx={{ minWidth: '75px' }}>{localize.formatAsInteger(0, { unit })}</Label>;
  }

  let color: LabelColor;
  let icon: string;
  if (inverse) {
    color = changeDirection === 'positive' ? 'success' : 'error';
    icon = changeDirection === 'positive' ? ICONS.ARROW_DOWN : ICONS.ARROW_UP;
  } else {
    color = changeDirection === 'positive' ? 'error' : 'success';
    icon = changeDirection === 'positive' ? ICONS.ARROW_UP : ICONS.ARROW_DOWN;
  }

  let child;
  switch (unit) {
    case Units.percentage:
      if (percent === Infinity) {
        percent = 100;
      } else if (percent === -Infinity) {
        percent = -100;
      } else if (Number.isNaN(percent)) {
        percent = 0;
      }

      child = (
        <Typography variant="caption" fontWeight={700}>
          {localize.formatAsPercentage(percent, {
            fractionDigits: digits,
            signDisplay: 'never',
          })}
        </Typography>
      );
      break;
    case Units.year:
      child = localize.formatAsInteger(change, { unit: Units.year });
      break;
    default: {
      const exhaustive: never = unit;
      throw new Error(`Unhandled type: ${exhaustive}`);
    }
  }

  return (
    <Label sx={{ minWidth: '75px' }} color={color} startIcon={<Iconify width={16} height={16} icon={icon} />}>
      {child}
    </Label>
  );
}
