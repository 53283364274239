import { language_enum } from '@predium/enums';
import { COMMON_DATE_FORMATS, formatDateToLocale } from '@predium/utils';

const toKebabCase = (str: string): string => {
  return str
    .normalize('NFC') // NFC: to preserve German umlauts
    .replace(/[\s,:._-]+/g, '-') // Replace spaces & special chars with hyphen
    .replace(/[^a-zA-Z0-9-ßäöü]/g, '') // Remove remaining invalid chars (German umlauts and ß are allowed)
    .replace(/^-+|-+$/g, '') // Trim leading/trailing hyphens
    .toLowerCase();
};

export function getFilename(entities: string[], language: language_enum, includeDate = true, extension = ''): string {
  const localizedDate = formatDateToLocale(new Date(), COMMON_DATE_FORMATS.DAY_MONTH_YEAR, language);

  const fileName = ['predium', ...entities, ...(includeDate ? [localizedDate] : [])].map(toKebabCase).join('-');

  if (extension) {
    return `${fileName}.${extension}`;
  }

  return fileName;
}
