import { Box, ClickAwayListener, InputAdornment } from '@mui/material';
import { data_source_type_enum } from '@predium/client-graphql';
import { translateUnitEnum_dynamic } from '@predium/i18n/client';
import { UnitsValue } from '@predium/utils';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import { MouseEventHandler, useState } from 'react';
import { flushSync } from 'react-dom';
import { useForm, useFormContext, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Iconify from '../Iconify';
import { FormProvider } from '../hook-form';
import RHFNumberField, { RHFNumberFieldProps } from '../hook-form/RHFNumberField';
import Value from './Value';

export type InputProps = {
  name: string;
  unit?: UnitsValue;
  source?: data_source_type_enum;
  onClickAway?: (value: number) => void;
  numberFieldProps?: Partial<RHFNumberFieldProps>;
  showTooltip?: boolean;
  isLoading?: boolean;
  ignoreIsDirty?: boolean;
  onSourceClick?: MouseEventHandler<HTMLDivElement>;
  onSourceClickAway?: () => void;
  disabled?: boolean;
  min?: number;
  max?: number;
};

const Input = ({
  name,
  source,
  unit,
  numberFieldProps,
  onClickAway,
  showTooltip,
  disabled = false,
  min,
  max,
}: InputProps) => {
  const { t } = useTranslation();
  const [editMode, setEditMode] = useState(false);
  const parentMethods = useFormContext();
  const { watch, setValue } = parentMethods;

  const value = watch(name);

  const { dirtyFields } = useFormState({
    control: parentMethods.control,
    name,
    exact: true,
  });

  const methods = useForm({
    values: {
      [name]: value,
    },
  });

  const isDirty = get(dirtyFields, name) ?? false;

  const handleOpenEditMode = () => {
    if (disabled) {
      return;
    }
    setEditMode(true);
  };

  const handleClickAway = () => {
    if (!editMode) {
      return;
    }

    setEditMode(false);
    flushSync(() => {
      let value = methods.getValues(name);

      if (!isNil(min)) {
        value = Math.max(value, min);
      }

      if (!isNil(max)) {
        value = Math.min(value, max);
      }

      setValue(name, value, {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      });

      onClickAway?.(value ?? 0);
    });
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box>
        {!editMode && (
          <Value
            value={value}
            source={source}
            unit={unit}
            showSourceTooltip={showTooltip}
            isEdited={isDirty}
            valueRootProps={{
              sx: {
                cursor: disabled ? 'default' : 'pointer',
                borderRadius: 0.75,
                '&:hover': {
                  bgcolor: 'white',
                },
              },
              onClick: handleOpenEditMode,
            }}
            disabled={disabled}
          />
        )}

        {editMode && (
          <FormProvider methods={methods}>
            <RHFNumberField
              name={name}
              size="small"
              sx={{ width: 170 }}
              decimalScale={2}
              onEnter={handleClickAway}
              onBlur={handleClickAway} //onClickAway doesn't work here properly
              InputProps={{
                endAdornment: unit ? (
                  <InputAdornment position="end">{translateUnitEnum_dynamic(unit, t)}</InputAdornment>
                ) : undefined,
                startAdornment: <Iconify fontSize={40} color="success.main" icon="mdi:pencil-box" mr={1} />,
                autoComplete: 'off',
                autoFocus: true,
              }}
              {...numberFieldProps}
            />
          </FormProvider>
        )}
      </Box>
    </ClickAwayListener>
  );
};

export default Input;
