import { useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import {
  ActionFragment,
  ActionImpactDataOutputFragment,
  ActionPlanningActionPlanActionPlanFragment,
  BuildingModelFragment,
} from '@predium/client-graphql';
import { PARIS_READY_DATE } from '@predium/client-lookup';
import { country_enum, efficiency_class_enum, renovation_type_enum } from '@predium/enums';
import { translateRenovationTypeEnum, translateUnitEnum_dynamic } from '@predium/i18n/client';
import {
  COMMON_DATE_FORMATS,
  ensureDefined,
  formatDateToLocale,
  getBuildingIsMixed,
  Localize,
  Units,
} from '@predium/utils';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ICONS } from '../../../../../assets/icons';
import DeleteConfirmationModal from '../../../../../components/DeleteConfirmationModal';
import EfficiencyClassLabel from '../../../../../components/EfficiencyClassLabel';
import Iconify from '../../../../../components/Iconify';
import Label from '../../../../../components/Label';
import { SnackbarTimeouts } from '../../../../../components/NotistackProvider';
import OverflowText from '../../../../../components/OverflowText';
import { ParisReadyTooltip } from '../../../../../components/paris-ready-label/ParisReadyTooltip';
import { StrandingChangeLabel } from '../../../../../components/paris-ready-label/StrandingChangeLabel';
import Scrollbar from '../../../../../components/Scrollbar';
import StickyTableCell from '../../../../../components/table/StickyTableCell';
import { DELETE_ACTION } from '../../../../../graphql/ActionPlanning.mutations';
import {
  GET_ACTION_PLAN_METRICS,
  GET_ACTIONS_FOR_ACTION_PLAN,
  GET_ANALYZED_SCENARIO,
  GET_RECOMMENDED_ACTIONS_FOR_BUILDING_MODEL,
  GET_SUBSIDIES,
} from '../../../../../graphql/ActionPlanning.queries';
import usePosthogTracking from '../../../../../hooks/usePosthogTracking';
import useSessionData from '../../../../../hooks/useSessionData';
import { ActionPlanMetricsData } from '../../../../../pages/ActionPlanning/ActionPlanningActionPlan';
import { useLanguage } from '../../../../../provider/LanguageProvider';
import { HeadLabel } from '../../../../EsgAnalysis/Portfolio/BuildingListTab/EsgAnalysisBuildingListHead';
import {
  affectedConsumerPartsByAction,
  affectedEnvelopePartsByAction,
  getActionName,
  getCustomActionType,
  getIconURLByAction,
} from '../../ActionPlan.utils';
import EnvelopeAffectedPartsSubtext from '../../Actions/PartialRenovations/EnvelopeRenovation/EnvelopeAffectedPartsSubtext';
import AffectedConsumersSubtext from '../../Actions/PartialRenovations/SystemRenovation/AffectedConsumersSubtext';
import ActionPlanSectionTitle from '../../Components/ActionPlanSectionTitle';
import { ComparisonTableCell } from '../../Components/ComparisonTableCell';
import CustomLabelWithTooltip from '../../Components/CustomLabelWithTooltip';
import RecommendedActions from '../RecommendedActions';
import ActionListSkeletons from './ActionListSkeletons';
import ActionListStatusQuo from './ActionListStatusQuo';
import EmptyActionList from './EmptyActionList';

export type ActionWithMetricData = ActionFragment & {
  metric: ActionImpactDataOutputFragment;
};

//PRE PRE-4289 create a fragment for building
const getEfficiencyClassColumnTitle = (building: ActionPlanningActionPlanActionPlanFragment['building']) => {
  const isMixed = getBuildingIsMixed(building.areas);

  if (!isMixed) {
    return t('General_EfficiencyClass-SHORT');
  }

  if (building.address.country_id === country_enum.DE) {
    return [t('General_EfficiencyClass-SHORT'), t('General_Residence')].join(' ');
  }

  return t('General_AverageEfficiencyClass-SHORT');
};

const getEfficiencyClassTooltip = (building: ActionPlanningActionPlanActionPlanFragment['building']) => {
  const isMixed = getBuildingIsMixed(building.areas);

  if (!isMixed) {
    return t('General_EfficiencyClass');
  }

  if (building.address.country_id === country_enum.DE) {
    return [t('General_EfficiencyClass'), t('General_Residence')].join(' ');
  }

  return t('General_AverageEfficiencyClass-SHORT');
};

const getTableHead = (
  building: ActionPlanningActionPlanActionPlanFragment['building'],
  localize: Localize,
): HeadLabel[] => {
  const action = { id: 'name', label: t('General_Action', { count: 1 }), width: 250 };

  const primaryEnergy = {
    id: 'energy_primary_impact',
    label: t('General_PrimaryEnergy'),
    subtitle: translateUnitEnum_dynamic(Units.energyPerAreaYear, t),
    minWidth: 150,
  };

  const finalEnergy = {
    id: 'energy_final_impact',
    label: t('General_FinalEnergy'),
    subtitle: translateUnitEnum_dynamic(Units.energyPerAreaYear, t),
    minWidth: 150,
  };

  let subtitle;
  if (building.address.country_id === country_enum.AT) {
    if (building.active_building_model?.heating_demand) {
      subtitle = t('General_AccordingToHeatingDemand');
    } else {
      subtitle = t('General_AccordingToPrimaryEnergy');
    }
  } else {
    subtitle = t('General_AccordingToFinalEnergy');
  }

  const efficiencyClass = {
    id: 'efficiency_class_impact',
    label: getEfficiencyClassColumnTitle(building),
    tooltip: getEfficiencyClassTooltip(building),
    minWidth: 100,
    subtitle,
  };

  const co2Emissions = {
    id: 'co2_emissions_impact',
    label: t('General_CO2Intensity'),
    subtitle: translateUnitEnum_dynamic(Units.co2EmissionsPerAreaYear, t),
    minWidth: 150,
  };

  const strandingDate = {
    id: 'stranding_date_impact',
    label: t('General_StrandingDate'),
    subtitle: t('General_AccordingToDegreePath', {
      degree: localize.formatAsFloat(1.5, { fractionDigits: 1 }),
    }),
    minWidth: 120,
  };

  const totalInvestment = {
    id: 'total_investment',
    label: t('General_TotalInvestment'),
    subtitle: `${translateUnitEnum_dynamic(Units.price, t)} ${t('General_Gross')}`,
    minWidth: 100,
    alignment: 'right',
  };

  const subsidies = {
    id: 'subsidies',
    label: t('General_SubsidyAmount'),
    subtitle: `${translateUnitEnum_dynamic(Units.price, t)} ${t('General_Gross')}`,
    minWidth: 100,
    alignment: 'right',
  };

  const year = { id: 'year', label: t('General_PlannedFor'), minWidth: 140 };

  const deleteAction = { id: 'delete', label: '', minWidth: 20 };

  return [
    action,
    finalEnergy,
    efficiencyClass,
    primaryEnergy,
    co2Emissions,
    strandingDate,
    totalInvestment,
    subsidies,
    year,
    deleteAction,
  ];
};

type Props = {
  actionPlan: ActionPlanningActionPlanActionPlanFragment;
  handleActionSelection: (selectedActionId: number, previousActionId: number | undefined) => void;
  hoverRowId: number | null;
  setMetricsData: (data: ActionPlanMetricsData) => void;
  totalArea: number;
  baseBuildingModel: BuildingModelFragment;
  onNewAction: () => void;
};

const ActionListTable = ({
  actionPlan,
  handleActionSelection,
  hoverRowId,
  setMetricsData,
  baseBuildingModel,
  totalArea,
  onNewAction,
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const { language, localize } = useLanguage();
  const { serverSideFeatureFlags } = useSessionData();
  const { trackEvent } = usePosthogTracking();

  const [deleteActionModalOpen, setDeleteActionModalOpen] = useState(false);
  const [selectedAction, setSelectedAction] = useState<ActionFragment | null>(null);
  const [actionsWithImpact, setActionsMetricsData] = useState<ActionWithMetricData[] | []>([]);
  const [isDataLoading, setIsDataLoading] = useState(false);

  //TODO both queries will be combined in one query later. separate ticket
  const { data: actionsData, loading: actionsLoading } = useQuery(GET_ACTIONS_FOR_ACTION_PLAN, {
    variables: { actionPlanId: actionPlan.id },
  });

  const { data: modificationImpacts, loading } = useQuery(GET_ACTION_PLAN_METRICS, {
    variables: { actionPlanId: actionPlan.id },
    skip: !actionsData,
  });

  const [deleteActionMutation, { loading: loadingDeleteAction }] = useMutation(DELETE_ACTION, {
    refetchQueries: [
      GET_ACTIONS_FOR_ACTION_PLAN,
      GET_ACTION_PLAN_METRICS,
      GET_SUBSIDIES,
      GET_RECOMMENDED_ACTIONS_FOR_BUILDING_MODEL,
      { query: GET_ANALYZED_SCENARIO, variables: { scenario_id: actionPlan.scenario.id } },
    ],
    onError: () =>
      enqueueSnackbar(t('ActionPlanningActionPlan_DeleteAction-error'), {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
      }),
    onCompleted: (data) => {
      if (data.deleteAction.error) {
        const errorMessage = t('ActionPlanningActionPlan_DeleteAction-ACTION_PLAN_INCONSISTENT');
        enqueueSnackbar(errorMessage, {
          variant: 'error',
          autoHideDuration: SnackbarTimeouts.Error,
        });
        if (selectedAction) {
          trackEvent('ACTION_DELETE_FAILED', {
            message: errorMessage,
            actionId: selectedAction.id,
          });
        }
      } else {
        enqueueSnackbar(t('ActionPlanningActionPlan_DeleteAction-success'), {
          variant: 'success',
          autoHideDuration: SnackbarTimeouts.Success,
        });

        const renovationType = selectedAction?.renovations[0]?.renovation_type_id;

        let systemType = '';
        if (renovationType === renovation_type_enum.ENERGY_CONSUMER) {
          systemType =
            selectedAction?.renovations_energy_consumer?.[0]?.new_energy_consumer?.energy_consumer_type_id ?? '';
        }
        if (selectedAction) {
          trackEvent('ACTION_DELETED', {
            renovationType: renovationType ?? null,
            systemType: systemType,
            actionId: selectedAction.id ?? null,
          });
        }
      }
    },
  });

  useEffect(() => {
    setIsDataLoading(true);
    if (modificationImpacts && actionsData) {
      const metrics = modificationImpacts?.getActionPlanMetrics ?? [];

      const metricsData = actionsData?.action.map((action) => {
        const metric = metrics?.actions.find((m) => m.action_id === action.id);
        return {
          ...(action as ActionFragment),
          metric: metric as ActionImpactDataOutputFragment,
        };
      });

      const data: ActionPlanMetricsData = {
        actions: metricsData,
        action_plan: metrics.action_plan,
      };
      setMetricsData(data);
      setActionsMetricsData(metricsData);
    } else {
      return;
    }
    setIsDataLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modificationImpacts, actionsData]);

  const showSkeletonLoader = actionsLoading || loading || !actionsWithImpact || isDataLoading;
  const actions = actionsData?.action ?? [];

  const { building } = actionPlan;

  const isRecommendedActionsEnabled = serverSideFeatureFlags.RECOMMENDED_ACTIONS;

  const deleteAction = async () => {
    if (!selectedAction) return;
    deleteActionMutation({ variables: { actionId: selectedAction.id } });
    setDeleteActionModalOpen(false);
  };

  const tableHead = getTableHead(building, localize);

  const onActionRowClick = (selectedActionId: number, index: number) => {
    const previousAction = index > 0 ? actionsWithImpact[index - 1] : undefined;
    handleActionSelection(selectedActionId, previousAction?.id);
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} width={'100%'}>
            <Stack flexDirection={'row'} justifyContent={'space-between'}>
              <ActionPlanSectionTitle
                title={t('General_Action_other')}
                subtitle={t('ActionDetails_EnergeticKPIs_Subtitle')}
                icon={ICONS.CHART_TIMELINE}
              />
            </Stack>
            <Button variant="contained" onClick={onNewAction}>
              <Stack direction={'row'} alignItems={'center'} gap={1}>
                <Iconify icon={ICONS.PLUS} width={20} height={20} />
                {t('ActionPlanning_ActionPlan-AddActionButton')}
              </Stack>
            </Button>
          </Stack>
          <Card sx={{ boxShadow: 'none', border: 'none' }}>
            <Scrollbar>
              <TableContainer>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      {tableHead.map((headCell) => (
                        <TableCell
                          key={headCell.id}
                          sx={{
                            minWidth: headCell.minWidth,
                            verticalAlign: 'top',
                            backgroundColor: 'transparent',
                            backgroundImage: 'none',
                            borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                          }}
                        >
                          <TableSortLabel
                            style={{ cursor: 'default' }}
                            hideSortIcon
                            sx={{
                              display: 'flex',
                            }}
                          >
                            <Stack flexDirection={'column'} width={'100%'}>
                              <span style={{ whiteSpace: 'nowrap', textAlign: headCell.alignment ?? 'left' }}>
                                {headCell.tooltip ? (
                                  <Tooltip title={headCell.tooltip} placement="top">
                                    <span>{headCell.label}</span>
                                  </Tooltip>
                                ) : (
                                  headCell.label
                                )}
                              </span>
                              {headCell.subtitle && (
                                <Typography
                                  variant="caption"
                                  color="text.secondary"
                                  whiteSpace={'nowrap'}
                                  component={'div'}
                                  textAlign={headCell.alignment ?? 'left'}
                                >
                                  {headCell.subtitle}
                                </Typography>
                              )}
                            </Stack>
                          </TableSortLabel>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  {!showSkeletonLoader ? (
                    <TableBody>
                      <ActionListStatusQuo modificationMetric={modificationImpacts?.getActionPlanMetrics} />

                      {actionsWithImpact.map((row, index) => {
                        const { implementation_to } = row;

                        if (!row?.metric?.modification_impact) {
                          return null;
                        }
                        const { co2_emissions, efficiency_class, final_energy, stranding_date, primary_energy } =
                          row.metric.modification_impact;

                        const { total_renovation_cost, total_subsidy_cost } = row.metric;
                        const finalEnergyAfter = localize.formatAsFloat(final_energy?.after ?? 0, {
                          fractionDigits: 1,
                        });

                        const co2EmissionAfter = localize.formatAsFloat(co2_emissions?.after ?? 0, {
                          fractionDigits: 1,
                        });

                        const use_custom_cost =
                          row.renovations && row.renovations.some((renovation) => renovation.use_custom_cost);

                        const strandingDateAfter = dayjs(stranding_date?.after ?? PARIS_READY_DATE).format('YYYY');
                        const strandingDateChange = stranding_date?.impact ?? 0;

                        const primaryEnergyAfter = localize.formatAsFloat(primary_energy?.after ?? 0, {
                          fractionDigits: 1,
                        });
                        const efficiencyClassAfter = efficiency_class?.after ?? efficiency_class_enum.UNKNOWN;

                        const renovationType = row?.renovations[0]?.renovation_type_id;

                        const affectedEnvelopeParts = affectedEnvelopePartsByAction(
                          row,
                          actions,
                          ensureDefined(building.active_building_model),
                        );

                        const affectedConsumerParts = affectedConsumerPartsByAction(
                          row,
                          actions,
                          ensureDefined(building.active_building_model),
                        );

                        const strandingDateText = localize.formatAsInteger(-strandingDateChange, {
                          unit: Units.year,
                          signDisplay: 'exceptZero',
                        });

                        return (
                          <TableRow
                            key={index}
                            hover
                            sx={{
                              cursor: 'pointer',
                              backgroundColor: hoverRowId === row.id ? theme.palette.action.hover : '',
                            }}
                            onClick={() => {
                              onActionRowClick(row.id, index);
                            }}
                          >
                            <TableCell
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                              }}
                            >
                              <Tooltip placement="top" arrow title={translateRenovationTypeEnum(renovationType)}>
                                <Box component={'img'} src={getIconURLByAction(row)} width={20} mr={2} />
                              </Tooltip>

                              <Stack>
                                <OverflowText text={getActionName(row)} maxWidth={250} />
                                {renovationType === renovation_type_enum.MAINTENANCE && (
                                  <Typography variant="caption" color="text.secondary">
                                    {t('ActionPlanning_CreateAction-CustomAction-NoEnergeticImpact')}
                                  </Typography>
                                )}
                                {renovationType === renovation_type_enum.CUSTOM && (
                                  <Typography variant="caption" color="text.secondary">
                                    {t('ActionPlanning_CreateAction-CustomAction-WithEnergeticImpact')}
                                  </Typography>
                                )}
                                {!!building.active_building_model &&
                                  !row.custom_action_type_id &&
                                  (row.renovations_envelope.length > 0 ? (
                                    <EnvelopeAffectedPartsSubtext
                                      buildingModel={row.building_models[0]}
                                      affectedParts={affectedEnvelopeParts}
                                      renovationType={renovationType}
                                    />
                                  ) : (
                                    <AffectedConsumersSubtext
                                      buildingModel={row.building_models[0]}
                                      affectedParts={affectedConsumerParts}
                                      renovationType={renovationType}
                                    />
                                  ))}
                                {!!row.custom_name && (
                                  <>
                                    <Typography variant="caption" color="text.secondary">
                                      {getCustomActionType(row)}
                                    </Typography>
                                    <Typography variant="caption" color="text.secondary">
                                      <CustomLabelWithTooltip />
                                    </Typography>
                                  </>
                                )}
                              </Stack>
                            </TableCell>

                            <ComparisonTableCell
                              after={finalEnergyAfter}
                              comparisonPercent={final_energy?.impact ?? 0}
                            />
                            <ComparisonTableCell
                              after={<EfficiencyClassLabel efficiencyClass={efficiencyClassAfter} />}
                              hideComparisonLabel
                            />
                            <ComparisonTableCell
                              after={primaryEnergyAfter}
                              comparisonPercent={primary_energy?.impact ?? 0}
                            />
                            <ComparisonTableCell
                              after={co2EmissionAfter}
                              comparisonPercent={co2_emissions?.impact ?? 0}
                            />
                            <TableCell>
                              <Stack display="flex" flexDirection={'row'} justifyContent={'start'} gap={2}>
                                <Box display="flex" flexDirection="row" alignItems={'center'} gap={0.5}>
                                  <ParisReadyTooltip dateToCheck={strandingDateAfter} />
                                </Box>
                                <StrandingChangeLabel
                                  strandingDateChange={-strandingDateChange}
                                  strandingDateAfter={strandingDateAfter}
                                  strandingDateText={strandingDateText}
                                />
                              </Stack>
                            </TableCell>

                            <TableCell>
                              <Typography variant="body2">
                                <Tooltip
                                  arrow
                                  placement="top"
                                  title={use_custom_cost ? undefined : t('General_ApproximatedValue')}
                                >
                                  <Stack
                                    direction={'row'}
                                    justifyContent={'end'}
                                    alignItems={'center'}
                                    whiteSpace={'nowrap'}
                                  >
                                    {!use_custom_cost && (
                                      <Iconify
                                        width={20}
                                        height={20}
                                        color="info.main"
                                        icon="mdi:chart-timeline-variant-shimmer"
                                        mr={1}
                                      />
                                    )}
                                    <span>
                                      {localize.formatAsCurrency(total_renovation_cost, {
                                        roundToNearestHundred: true,
                                      })}
                                    </span>
                                  </Stack>
                                </Tooltip>
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Stack display="flex" flexDirection={'row'} gap={2} justifyContent={'end'}>
                                <Box display="flex" flexDirection="row" alignItems={'center'} gap={1}>
                                  <Typography variant="body2">
                                    {localize.formatAsCurrency(total_subsidy_cost, {
                                      roundToNearestHundred: true,
                                    })}
                                  </Typography>
                                  {!!total_subsidy_cost && total_subsidy_cost > 0 && (
                                    <Label color="success">
                                      {localize.formatAsPercentage(
                                        (total_subsidy_cost / (total_renovation_cost ?? Number.EPSILON)) * 100,
                                      )}
                                    </Label>
                                  )}
                                </Box>
                              </Stack>
                            </TableCell>
                            <TableCell>{`${formatDateToLocale(
                              implementation_to,
                              COMMON_DATE_FORMATS.DAY_MONTH_YEAR,
                              language,
                            )}`}</TableCell>

                            <StickyTableCell>
                              <Stack direction={'row'} gap={1}>
                                <IconButton
                                  size="small"
                                  onClick={(e) => {
                                    onActionRowClick(row.id, index);
                                    e.stopPropagation();
                                  }}
                                  disabled={loadingDeleteAction}
                                >
                                  <Iconify icon={ICONS.EDIT} color="text.secondary" />
                                </IconButton>

                                <IconButton
                                  size="small"
                                  onClick={(e) => {
                                    setSelectedAction(row);
                                    setDeleteActionModalOpen(true);
                                    e.stopPropagation();
                                  }}
                                  disabled={loadingDeleteAction}
                                >
                                  <Iconify icon={ICONS.TRASH} color="error.main" />
                                </IconButton>
                              </Stack>
                            </StickyTableCell>
                          </TableRow>
                        );
                      })}

                      {!showSkeletonLoader && actionsWithImpact.length === 0 ? (
                        <EmptyActionList />
                      ) : (
                        <TableRow>
                          <TableCell colSpan={9} />
                        </TableRow>
                      )}

                      {baseBuildingModel && isRecommendedActionsEnabled && (
                        <RecommendedActions
                          baseBuildingModel={ensureDefined(baseBuildingModel)}
                          actionPlan={actionPlan}
                          totalArea={totalArea}
                          actionPlanEmpty={actionsWithImpact.length === 0}
                        />
                      )}
                    </TableBody>
                  ) : (
                    <TableBody>
                      <ActionListSkeletons />
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>
          </Card>
        </Grid>
      </Grid>

      {selectedAction && (
        <DeleteConfirmationModal
          open={deleteActionModalOpen}
          onClose={() => {
            setDeleteActionModalOpen(false);
          }}
          onDelete={deleteAction}
          title={t('General_DeleteModalTitle-Action', { count: 1 })}
          description={
            <Trans
              i18nKey={'General_DeleteModalDescription-Action'}
              values={{ name: getActionName(selectedAction), count: 1 }}
              components={{ bold: <strong /> }}
            />
          }
        />
      )}
    </>
  );
};

export default ActionListTable;
