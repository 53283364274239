import { InputAdornment, SxProps, Theme } from '@mui/material';
import { translateUnitEnum_dynamic } from '@predium/i18n/client';
import { UnitsValue } from '@predium/utils';
import { useTranslation } from 'react-i18next';
import { RHFNumberField } from '../../../../../../../../components/hook-form';

type Props = {
  name: string;
  label: string;
  unit?: UnitsValue;
  fieldLabel?: string;
};

export const technologyNumberFieldSx: SxProps<Theme> = (theme) => ({
  background: theme.palette.background.neutral,
  input: { cursor: 'not-allowed' },
  opacity: 1,
  '.Mui-disabled.MuiInputBase-input': {
    WebkitTextFillColor: theme.palette.text.primary,
  },
  '.MuiInputLabel-root.Mui-disabled': {
    bgcolor: theme.palette.background.neutral,
    px: 0.5,
    borderRadius: 0.5,
    color: theme.palette.text.secondary,
  },
});

const TechnologyNumberField: React.FC<Props> = ({ name, label, unit }) => {
  const { t } = useTranslation();
  return (
    <RHFNumberField
      name={name}
      label={label}
      variant="outlined"
      InputProps={{
        endAdornment: unit ? <InputAdornment position="end">{translateUnitEnum_dynamic(unit, t)}</InputAdornment> : '',
      }}
      disabled
      decimalScale={3}
      sx={technologyNumberFieldSx}
    />
  );
};

export default TechnologyNumberField;
