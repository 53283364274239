/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useQuery } from '@apollo/client';
import { Box, Stack, Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { area_type_enum, country_enum, data_source_type_enum, efficiency_class_enum } from '@predium/enums';
import {
  translateBuildingStateEnum,
  translateCountryEnum,
  translateCountryStateEnum,
  translateEfficiencyClassEnum,
  translateEnergySourceTypeEnum,
  translateTypeOfUseEnum_dynamic,
} from '@predium/i18n/client';
import {
  COMMON_DATE_FORMATS,
  ensureDefined,
  formatDateToLocale,
  getAreaByType,
  getAreaSum,
  getBuildingIsOnlyCommercial,
  getEbfTypesOfUse,
  getNetArea,
  primaryEnergySourceFromEnergySystems,
  Units,
} from '@predium/utils';
import sortBy from 'lodash/sortBy';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ICONS } from '../../../assets/icons';
import DetailedTooltip, { DetailedTooltipProps } from '../../../components/DetailedTooltip/DetailedTooltip';
import Iconify from '../../../components/Iconify';
import InlineUser from '../../../components/InlineUser';
import AccessRightsWrapper from '../../../components/permission-tooltips/AccessRightsWrapper';
import { PermissionType } from '../../../contexts/PermissionContext';
import { GET_AMOUNT_OF_ACTIONS_FOR_BUILDING } from '../../../graphql/DataCollection.queries';
import useOrgPreferences from '../../../hooks/useOrgPreferences';
import usePermissions from '../../../hooks/usePermissions';
import useSessionData from '../../../hooks/useSessionData';
import { useLanguage } from '../../../provider/LanguageProvider';
import { SEARCH_PARAMS, useTypeSafeSearchParams } from '../../../routes';
import BuildingMapbox from './BuildingMapbox';
import useBuilding from './Context/useBuilding';
import BuildingEditingDialog from './General/BuildingEditingDialog';

export default function BuildingGeneral({ isProcessing }: { isProcessing: boolean }) {
  const { t } = useTranslation();
  const { localize, language } = useLanguage();
  const { economicUnitsToggled } = useOrgPreferences();
  const { building } = useBuilding();
  const subBuilding = building.sub_buildings[0];
  const typesOfUse = getEbfTypesOfUse(building.areas);
  const { checkBuildingPermission } = usePermissions();

  const [buildingMapKey, setBuildingMapKey] = useState(0);
  const { searchParams, setSearchParams } = useTypeSafeSearchParams(SEARCH_PARAMS.dataCollection.building, {
    openEditDialog: false,
  });

  const { data: amountOfActionsForBuilding } = useQuery(GET_AMOUNT_OF_ACTIONS_FOR_BUILDING, {
    variables: {
      buildingId: building.id,
    },
  });

  const {
    serverSideFeatureFlags: { AUSTRIAN_BGF },
  } = useSessionData();

  const buildingModel = ensureDefined(building.active_building_model);

  const hasEnvelope = buildingModel.envelope_units.length > 0;
  const hasApproximatedEnvelope =
    hasEnvelope &&
    buildingModel.envelope_units.some((unit) => unit.data_source_type_id !== data_source_type_enum.MANUAL);
  const hasManualInputEnvelope = buildingModel.envelope_units.some(
    (unit) => unit.data_source_type_id === data_source_type_enum.MANUAL,
  );

  const hasActions = (amountOfActionsForBuilding?.action_plan.length ?? 0) > 0;

  const SUB_BUILDING_VALUES: { label: string; key: keyof typeof subBuilding; unit: string }[] = [
    {
      label: t('DataCollectionSubBuildingValues_UnitsResidential'),
      key: 'units_residential',
      unit: '',
    },
    {
      label: t('DataCollectionSubBuildingValues_UnitsCommercial'),
      key: 'units_commercial',
      unit: '',
    },
  ];

  const primaryEnergySourceType = primaryEnergySourceFromEnergySystems(
    //@ts-ignore
    building.active_building_model.energy_systems.flatMap((system) => system.energy_system_consumer_routes),
  );
  const primaryEnergySource = translateEnergySourceTypeEnum(primaryEnergySourceType);

  const buildingState = translateBuildingStateEnum(building.building_state_id);

  const latestValuation = sortBy(building.building_valuations, 'valuation_at').at(-1);
  const marketValue = latestValuation?.value;
  const formattedMarketValue = marketValue
    ? localize.formatAsCurrency(marketValue, {
        currency: 'EUR',
        fractionDigits: 2,
      })
    : '-';

  const cityAndPostalCode = `${building.address.postal_code ?? ''} ${building.address.city ?? ''}`;
  const countryState = building.address.country_state_id
    ? translateCountryStateEnum(building.address.country_state_id)
    : '—';
  const country = building.address.country_id ? translateCountryEnum(building.address.country_id) : '—';
  const portfolioName = building.economic_unit?.portfolio?.name ?? '';
  const customerExternalIdentifier = building.customer_external_identifier ?? '';

  const handleMapboxUpdate = () => {
    setBuildingMapKey((prevKey) => prevKey + 1);
  };

  const openEditDialog = searchParams.openEditDialog;
  const setOpenEditDialog = (open: boolean) => {
    setSearchParams({
      ...searchParams,
      // remove the param if it's false
      openEditDialog: open || undefined,
    });
  };

  useEffect(() => {
    if (isProcessing) return;
    setBuildingMapKey((prevKey) => prevKey + 1);
  }, [isProcessing]);

  const hasEditAccess = checkBuildingPermission(building.id, PermissionType.WRITE);

  const isEBFEstimated = useMemo(() => {
    return getAreaByType(building.areas, area_type_enum.EBF)[0].data_source_id === data_source_type_enum.APPROXIMATED;
  }, [building.areas]);

  const hasEnergyCertificate = useMemo(() => {
    return building.sub_buildings[0].energy_certificates.length > 0;
  }, [building]);

  const energySystemDataSourceType = buildingModel.energy_systems[0].data_source_type_id;
  const isEnergySystemApproximated =
    energySystemDataSourceType === data_source_type_enum.TABULA ||
    energySystemDataSourceType === data_source_type_enum.APPROXIMATED;

  const estimatedFieldsCount = useMemo(() => {
    if (hasEnergyCertificate) return 0;

    let count = 0;
    if (isEBFEstimated) {
      count += 2;
    }
    if (building.year_data_source === data_source_type_enum.APPROXIMATED) count++;
    if (building.sub_buildings[0].units_data_source === data_source_type_enum.APPROXIMATED) count++;
    if (isEnergySystemApproximated) {
      count += 3;
    }

    return count;
  }, [hasEnergyCertificate, isEBFEstimated, building, isEnergySystemApproximated]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item md={4}>
          <Card sx={{ height: '100%', textAlign: 'center' }}>
            <BuildingMapbox
              key={buildingMapKey}
              buildingId={building.id}
              isProcessing={isProcessing}
              hasEnvelope={hasEnvelope}
              hasApproximatedEnvelope={hasApproximatedEnvelope}
              hasManualInputEnvelope={hasManualInputEnvelope}
              hasActions={hasActions}
              onMapboxUpdate={handleMapboxUpdate}
            />
          </Card>
        </Grid>
        <Grid item md={8}>
          <Card sx={{ p: 3, height: '100%' }}>
            <Stack direction="row" px={3} justifyContent="space-between" alignItems="center">
              <CardHeader sx={{ mb: 2, px: 0 }} title={t('General_GeneralData')} />
              <Stack direction="row" spacing={2}>
                {!isProcessing && estimatedFieldsCount > 0 && (
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Iconify width={20} height={20} color="info.main" icon={ICONS.APPROXIMATED} />
                    <Typography variant="body1">{t('BuildingCreation_EstimatedFields')}</Typography>
                  </Stack>
                )}
                <AccessRightsWrapper hasAccess={hasEditAccess}>
                  <Button
                    variant="outlined"
                    onClick={() => setOpenEditDialog(true)}
                    startIcon={<Iconify icon={ICONS.EDIT_SQUARE} />}
                  >
                    {t('General_Edit')}
                  </Button>
                </AccessRightsWrapper>
              </Stack>
            </Stack>
            <GeneralDataRow label={t('General_Address')} value={building.address.street} />
            {economicUnitsToggled && (
              <GeneralDataRow label={t('General_EconomicUnit', { count: 1 })} value={building.economic_unit.name} />
            )}
            <GeneralDataRow label={t('General_Portfolio')} value={portfolioName.trim() || '—'} />
            <GeneralDataRow
              label={t('General_CustomerExternalIdentifier')}
              value={customerExternalIdentifier ? customerExternalIdentifier.trim() : '-'}
            />
            <GeneralDataRow label={t('General_City')} value={cityAndPostalCode.trim() || '—'} />
            <GeneralDataRow label={t('General_CountryState')} value={countryState} />
            <GeneralDataRow label={t('General_Country')} value={country} />
            <GeneralDataRow label={t('General_State')} value={buildingState} />
            <GeneralDataRow
              label={t('General_CurrentBuildingValue')}
              value={
                latestValuation ? (
                  <Tooltip
                    title={t('BuildingValuation_ValuationDate', {
                      date: formatDateToLocale(
                        latestValuation.valuation_at,
                        COMMON_DATE_FORMATS.DAY_MONTH_YEAR,
                        language,
                      ),
                    })}
                    placement="top"
                    arrow
                  >
                    <Typography variant="body1">{formattedMarketValue}</Typography>
                  </Tooltip>
                ) : (
                  <Button
                    variant="contained"
                    size="small"
                    sx={{
                      backgroundColor: (theme) => theme.palette.grey[500_8],
                      color: 'text.primary',
                      boxShadow: 'none',
                      '&:hover': {
                        backgroundColor: (theme) => theme.palette.grey[500_16],
                        boxShadow: 'none',
                      },
                    }}
                    onClick={() => setOpenEditDialog(true)}
                  >
                    {t('DataCollectionBuilding_BuildingValuationCTA')}
                  </Button>
                )
              }
            />
            <BuildingEditingDialog open={openEditDialog} onClose={() => setOpenEditDialog(false)} />

            <GeneralDataRow
              label={t('General_MonumentProtection')}
              value={building.monument_protection ? t('General_Yes') : t('General_No')}
              tooltipProps={{
                labels: {
                  title: t('General_MonumentProtection'),
                  body: t('Education_MonumentProtectionBody'),
                },
              }}
            />

            <GeneralDataRow
              label={t('General_HeritageDistrict')}
              value={building.heritage_district ? t('General_Yes') : t('General_No')}
              tooltipProps={{
                labels: {
                  title: t('General_HeritageDistrict'),
                  body: t('Education_HeritageDistrictBody'),
                },
              }}
            />

            <GeneralDataRow
              label={t('General_MilieuProtection')}
              value={building.milieu_protection ? t('General_Yes') : t('General_No')}
              tooltipProps={{
                labels: {
                  title: t('General_MilieuProtection'),
                  body: t('Education_MilieuProtectionBody'),
                },
                hide: building.address.country_id !== country_enum.DE,
              }}
            />

            <GeneralDataRow
              label={t('General_Leasehold')}
              value={building.leasehold ? t('General_Yes') : t('General_No')}
              tooltipProps={{
                labels: {
                  title: t('General_Leasehold'),
                  body: t('Education_LeaseholdBody'),
                },
              }}
            />

            <GeneralDataRow
              label={t('General_TypeOfUse', { count: typesOfUse.length })}
              value={typesOfUse.map((type) => translateTypeOfUseEnum_dynamic(type, t)).join(', ')}
              {...(!hasEnergyCertificate && {
                isApproximated: isEBFEstimated,
              })}
            />

            <GeneralDataRow
              label={t('General_EnergyReferenceArea')}
              value={localize.formatAsFloat(getNetArea(building.areas), { unit: Units.area })}
              {...(!hasEnergyCertificate && {
                isApproximated: isEBFEstimated,
              })}
            />

            {AUSTRIAN_BGF && building.address.country_id === country_enum.AT && (
              <GeneralDataRow
                label={t('DataCollectionAreas_GrossFloorArea')}
                value={localize.formatAsFloat(getAreaSum(getAreaByType(building.areas, area_type_enum.BGF)), {
                  unit: Units.area,
                })}
              />
            )}

            {SUB_BUILDING_VALUES.map((value) =>
              subBuilding[value.key] ? (
                <GeneralDataRow
                  key={value.key}
                  label={t(value.label)}
                  value={`${subBuilding[value.key]} ${value.unit}`}
                  {...(!hasEnergyCertificate && {
                    isApproximated: subBuilding.units_data_source === data_source_type_enum.APPROXIMATED,
                  })}
                />
              ) : null,
            )}

            <GeneralDataRow
              label={t('General_ConstructionYear')}
              value={building.year_constructed}
              {...(!hasEnergyCertificate && {
                isApproximated: building.year_data_source === data_source_type_enum.APPROXIMATED,
              })}
            />
            <GeneralDataRow
              label={t('General_MainEnergySource')}
              value={primaryEnergySource ?? '—'}
              tooltipProps={{
                labels: {
                  title: t('General_MainEnergySource'),
                  body: t('Education_MainEnergySourceBody'),
                },
              }}
              {...(!hasEnergyCertificate && {
                isApproximated: isEnergySystemApproximated,
              })}
            />
            <GeneralDataRow
              label={t('General_EnergyDemand')}
              value={localize.formatAsFloat(buildingModel.energy_final, { unit: Units.energyPerAreaYear })}
              tooltipProps={{
                labels: {
                  title: t('General_EnergyDemand'),
                  ...(building.address.country_id === country_enum.DE && {
                    body: t('Education_EnergyDemandBody-DE'),
                  }),
                  ...(building.address.country_id === country_enum.AT && {
                    body: t('Education_EnergyDemandBody-AT'),
                  }),
                },
                hide: ![country_enum.DE, country_enum.AT].includes(building.address.country_id),
              }}
              {...(!hasEnergyCertificate && {
                isApproximated: isEnergySystemApproximated,
              })}
            />
            <GeneralDataRow
              label={t('General_EfficiencyClass')}
              value={translateEfficiencyClassEnum(
                buildingModel.energy_paths[0]?.efficiency_class_id ?? efficiency_class_enum.UNKNOWN,
              )}
              {...(!hasEnergyCertificate && {
                isApproximated: isEnergySystemApproximated,
              })}
              tooltipProps={{
                labels: {
                  title: t('General_EfficiencyClass'),
                  ...(building.address.country_id === country_enum.DE && {
                    body: getBuildingIsOnlyCommercial(building.areas)
                      ? t('Education_EfficiencyClassCommercialBody-DE')
                      : t('Education_EfficiencyClassBody-DE'),
                  }),
                  ...(building.address.country_id === country_enum.AT && {
                    body: t('Education_EfficiencyClassBody-AT'),
                  }),
                },
                hide: ![country_enum.DE, country_enum.AT].includes(building.address.country_id),
              }}
            />
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4} lg={4}>
          <Card sx={{ p: 2, pb: 4, textAlign: 'center', mt: 3 }}>
            <CardHeader sx={{ textAlign: 'left', mb: 2 }} title={t('General_ResponsibleUser')} />

            {building.responsible_user ? (
              <InlineUser
                //@ts-ignore
                firstName={building.responsible_user.first_name}
                //@ts-ignore
                lastName={building.responsible_user.last_name}
                sx={{ pl: 2 }}
              />
            ) : null}
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

type GeneralDataRowProps = {
  label: ReactNode;
  value: ReactNode;
  isApproximated?: boolean;
  tooltipProps?: DetailedTooltipProps;
};

const GeneralDataRow = ({ label, value, tooltipProps, isApproximated }: GeneralDataRowProps) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={3} sx={{ px: 3, py: 1 }}>
      <Grid item xs={12} md={6}>
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <Typography>{label}</Typography>
          {tooltipProps && <DetailedTooltip {...tooltipProps} />}
        </Stack>
      </Grid>
      <Grid textAlign="right" item xs={12} md={6}>
        <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={0.5}>
          {isApproximated && (
            <Box display="inline-flex" title={t('BuildingCreation_EstimatedFields')}>
              <Iconify width={20} height={20} color="info.main" icon={ICONS.APPROXIMATED} />
            </Box>
          )}
          <Typography align="right">{value}</Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};
