import { Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import SimpleSelect from '../../../../components/SimpleSelect';
import { CompareScenarioCard } from './CompareScenarioCard';
import { useGetScenarioById } from './queries/useGetScenarioById';
import { CompareScenariosSelectStateType } from './useCompareScenarioSelect';

type Props = {
  state: CompareScenariosSelectStateType;
  handleSecondScenarioChange: (scenarioId: number) => void;
  isLoadingSelectValues: boolean;
  scenarioTwoDataColor: string;
  handleSelectChangeClick: VoidFunction;
};

export const CompareScenariosSelect = ({
  state,
  handleSecondScenarioChange,
  isLoadingSelectValues,
  scenarioTwoDataColor,
  handleSelectChangeClick,
}: Props) => {
  const { t } = useTranslation();

  const [scenario, isLoadingScenario] = useGetScenarioById(state.selectedId);

  return (
    <Stack direction={'column'} spacing={2}>
      <Typography variant={'subtitle1'}>{t('ActionPlanningScenario_CompareScenarios_CompareWith')}</Typography>
      <SimpleSelect
        aria-label={t('ActionPlanningScenario_CompareScenarios_SelectAriaLabel')}
        isLoading={isLoadingSelectValues}
        isHidden={!state.isScenarioCardHidden}
        values={state.values}
        initialId={state.selectedId}
        onChange={handleSecondScenarioChange}
        disabled={state.isDisabled}
      />
      <CompareScenarioCard
        hasExternalLink
        isHidden={state.isScenarioCardHidden}
        scenario={scenario}
        dataColor={scenarioTwoDataColor}
        isLoading={isLoadingScenario}
        handleSelectChangeClick={handleSelectChangeClick}
      />
    </Stack>
  );
};
