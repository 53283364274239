import { useQuery } from '@apollo/client';
import { GET_SCENARIO } from '../../../../../graphql/ActionPlanning.queries';

export const useGetScenarioById = (scenarioId: number) => {
  const { data, loading: isLoading } = useQuery(GET_SCENARIO, {
    variables: { scenarioId, year: 2025 },
    skip: scenarioId === Number.MIN_VALUE || scenarioId === Number.MAX_VALUE,
    fetchPolicy: 'cache-and-network',
  });
  const scenario = data?.scenario_by_pk ?? undefined;

  return [scenario, isLoading] as const;
};
