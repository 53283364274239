/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Grid, IconButton, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import {
  action_subsidy_value_type_enum,
  ActionPlanningActionSubsidyFragment,
  DataCollectionSubBuildingSubBuildingSubsidyFragment,
  renovation_type_enum,
  SubsidyInput,
} from '@predium/client-graphql';
import { mapActionCategoryToSubsidyCategories } from '@predium/enums';
import groupBy from 'lodash/groupBy';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import Iconify from '../../../../../../components/Iconify';
import { createStandardErrorSnackbar } from '../../../../../../components/NotistackProvider';
import Scrollbar from '../../../../../../components/Scrollbar';
import { WarningModal } from '../../../../../../components/WarningModal';
import { FormProvider } from '../../../../../../components/hook-form';
import PreDialog, { PreDialogBody } from '../../../../../../components/presentations/PreDialog/PreDialog';
import { GET_ACTIONPLAN } from '../../../../../../graphql/ActionPlanning.queries';
import { useLanguage } from '../../../../../../provider/LanguageProvider';
import EditSubsidyButton from './EditSubsidyButton';
import SelectedSubsidies from './Subsidy/SelectedSubsidies';
import { SubsidyData } from './Subsidy/SubsidyList';
import { SubsidyListWithCheckboxes } from './Subsidy/SubsidyListWithCheckboxes';

type Props = {
  onAddSubsidy: (data: SubsidyInput[]) => void;
  actionSubsidies: Omit<ActionPlanningActionSubsidyFragment, 'id'>[];
  totalCosts: number;
  renovationType?: renovation_type_enum;
  subBuildingSubsidies: DataCollectionSubBuildingSubBuildingSubsidyFragment[];
  isOverview: boolean;
};

export function AddSubsidyModal({
  onAddSubsidy,
  actionSubsidies,
  totalCosts,
  renovationType,
  subBuildingSubsidies,
  isOverview,
}: Props) {
  const { t } = useTranslation();
  const theme = useTheme();

  const { id: actionPlanIdString } = useParams();

  const actionPlanId = parseInt(actionPlanIdString ?? '-1');
  const { enqueueSnackbar } = useSnackbar();

  const [addSubsidyModalOpen, setAddSubsidyModalOpen] = useState(false);

  const displayStandardMutationQueryErrorSnackbar = createStandardErrorSnackbar(t);

  const { data: actionPlanData } = useQuery(GET_ACTIONPLAN, {
    variables: { actionPlanId },
    onError: () => displayStandardMutationQueryErrorSnackbar(enqueueSnackbar),
    skip: !actionPlanId || actionPlanId < 0,
  });

  const city = actionPlanData?.action_plan_by_pk?.building.address.city;

  const hasNoSubsidies =
    subBuildingSubsidies.length === 0 ||
    subBuildingSubsidies.filter((item) => isSubsidyApplicableToAction(item, renovationType)).length === 0;

  const onClose = () => {
    setAddSubsidyModalOpen(false);
  };

  // the user already selected some subsidies, thus we need to show the edit button
  if (actionSubsidies.length > 0 && !isOverview) {
    return (
      <>
        <EditSubsidyButton
          actionSubsidies={actionSubsidies}
          totalCost={totalCosts}
          onClick={() => setAddSubsidyModalOpen(true)}
          isOverview={isOverview}
        />
        <AddSubsidyForm
          open={addSubsidyModalOpen}
          onAddSubsidy={onAddSubsidy}
          onClose={onClose}
          subBuildingSubsidies={subBuildingSubsidies}
          actionSubsidies={actionSubsidies}
          action={{
            cost: totalCosts,
            renovationType: renovationType,
          }}
          //@ts-ignore
          city={city}
        />
      </>
    );
  }

  return (
    <>
      <Tooltip
        title={hasNoSubsidies ? t('ActionPlanningSubsidies_AddSubsidyButtonDisabledTooltip') : null}
        arrow
        placement="top-start"
      >
        <span>
          <Button
            variant="outlined"
            sx={{ border: `1px solid ${theme.palette.grey[500_24]}` }}
            startIcon={<Iconify color={theme.palette.text.secondary} icon="ic:baseline-plus" />}
            onClick={() => setAddSubsidyModalOpen(true)}
            disabled={hasNoSubsidies || totalCosts === 0}
          >
            {t('General_Add')}
          </Button>
        </span>
      </Tooltip>
      <AddSubsidyForm
        open={addSubsidyModalOpen}
        onAddSubsidy={onAddSubsidy}
        onClose={onClose}
        subBuildingSubsidies={subBuildingSubsidies}
        actionSubsidies={actionSubsidies}
        action={{
          cost: totalCosts,
          renovationType: renovationType,
        }}
        //@ts-ignore
        city={city}
      />
    </>
  );
}

export type AddSubsidyFormValueProps = {
  selectedSubsidies: {
    subsidyId: number;
    amount: number;
    type: action_subsidy_value_type_enum;
  }[];
};

type AddSubsidyFormProps = {
  onAddSubsidy: (data: SubsidyInput[]) => void;
  open: boolean;
  onClose: () => void;
  subBuildingSubsidies: DataCollectionSubBuildingSubBuildingSubsidyFragment[];
  actionSubsidies: Omit<ActionPlanningActionSubsidyFragment, 'id'>[];
  action: {
    cost: number;
    renovationType?: renovation_type_enum;
  };
  city: string;
};

function AddSubsidyForm({
  onAddSubsidy,
  onClose,
  open,
  subBuildingSubsidies,
  actionSubsidies,
  action,
  city,
}: AddSubsidyFormProps) {
  const { t } = useTranslation();
  const { localize } = useLanguage();
  const theme = useTheme();

  const [warningModalOpen, setWarningModalOpen] = useState(false);

  //UX input - we try to use as much screen real estate as possible
  //@TODO: fix this in PRE-1765
  const height = 'min(calc(100vh - 32px - 32px - 74px - 84px), 1000px)';

  const maxValueString = localize.formatAsCurrency(action.cost);

  const ErrorMessages = {
    positive: t('ActionPlanning_SubsidiesAddSubsidyForm-positiveError'),
    maxRelative: t('ActionPlanning_SubsidiesAddSubsidyForm-maxRelativeError', { value: '100%' }),
    maxAbsolute: t('ActionPlanning_SubsidiesAddSubsidyForm-maxAbsoluteError', { value: maxValueString }),
    notMoreThanActionCost: t('ActionPlanning_SubsidiesAddSubsidyForm-notMoreThanActionCostError', {
      value: maxValueString,
    }),
    required: t('ActionPlanning_SubsidiesAddSubsidyForm-requiredError'),
  };

  /**
   * Commented out because a different solution has been found
   * Keeping this here for reference, in case we need to use the Yup context again
   **/

  /**
   * the context is not typed correctly in this yup version (0.32.11), the 'from' property exists but is missing in the typing.
   * In the newest version (1.x.x) this is fixed, so this is a temporary fix.
   * I typed it myself, from what the context actually looks like.
   * see https://github.com/jquense/yup/issues/1631
   */
  // interface ExtendedTestContext extends Yup.TestContext {
  //   from: [
  //     {
  //       //this is the value of the field
  //       value: AddSubsidyFormValueProps['selectedSubsidies'][0];
  //     },
  //     {
  //       // this is the value of the fieldArray
  //       value: {
  //         selectedSubsidies: AddSubsidyFormValueProps['selectedSubsidies'];
  //       };
  //     },
  //   ];
  // }

  const AddSubsidyFormSchema = Yup.object().shape({
    selectedSubsidies: Yup.array().of(
      Yup.object().shape({
        subsidyId: Yup.number().required(),
        amount: Yup.number().when('type', {
          is: 'percentage',
          then: Yup.number()
            .positive(ErrorMessages.positive)
            .max(100, ErrorMessages.maxRelative)
            .required(ErrorMessages.required),
          otherwise: Yup.number()
            .positive(ErrorMessages.positive)
            .max(action.cost, ErrorMessages.maxAbsolute)
            .required(ErrorMessages.required),
        }),
        type: Yup.string()
          .oneOf([action_subsidy_value_type_enum.absolute, action_subsidy_value_type_enum.percentage])
          .required(),
      }),
    ),
  });

  const methods = useForm<AddSubsidyFormValueProps>({
    mode: 'onChange',
    resolver: yupResolver(AddSubsidyFormSchema),
  });
  const fieldArray = useFieldArray({
    control: methods.control,
    name: 'selectedSubsidies',
  });

  const onSelect = (subsidyId: number) => {
    const index = fieldArray.fields.findIndex((field) => field.subsidyId === subsidyId);
    if (index !== -1) {
      fieldArray.remove(index);
    } else {
      fieldArray.append({
        subsidyId,
        amount: 0,
        type: action_subsidy_value_type_enum.percentage,
      });
    }
  };

  const onSubmit = (data: AddSubsidyFormValueProps) => {
    const formattedData = data.selectedSubsidies.map((item) => ({
      id: item.subsidyId,
      type: item.type,
      value: item.amount,
    }));
    onAddSubsidy(formattedData);
    onClose();
  };

  const checkIfDirtyAndClose = () => {
    if (methods.formState.isDirty) {
      setWarningModalOpen(true);
    } else {
      onClose();
    }
  };

  const submitDisabled = Object.keys(methods.formState.errors).length > 0;
  const applicableSubsidies = subBuildingSubsidies.filter((item) =>
    isSubsidyApplicableToAction(item, action.renovationType),
  );
  const subBuildingSubsidiesGroupedByRegionType = groupBy(applicableSubsidies, 'region_type') as SubsidyData;

  const resetFormToDefault = () => {
    methods.reset({
      selectedSubsidies: actionSubsidies.map(({ subsidy, type, value }) => {
        return {
          subsidyId: subsidy.id,
          amount: value,
          type,
        };
      }),
    });
  };

  useEffect(() => {
    if (open) {
      resetFormToDefault();
    }
    // Only run when opened to set defaultValues and reset form state. Ignore other deps.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <PreDialog open={open} onClose={checkIfDirtyAndClose} type="definedByChildren" fullWidth maxWidth="lg">
      <FormProvider
        methods={methods}
        onSubmit={(event) => {
          event.stopPropagation();
          event.preventDefault();
          methods.handleSubmit(onSubmit)();
        }}
      >
        <PreDialogBody
          dialogtitle={
            <Stack
              direction={'row'}
              spacing={2}
              alignItems={'center'}
              sx={{ borderBottom: `1px solid ${theme.palette.grey[500_24]}`, p: 2 }}
            >
              <IconButton onClick={checkIfDirtyAndClose}>
                <Iconify icon="material-symbols:chevron-left" color={'text.secondary'} width={24} height={24} />
              </IconButton>
              <Typography variant="h6">{t('ActionPlanningSubsidies_AddSubsidyButton')}</Typography>
            </Stack>
          }
          actions={
            <Button variant="contained" type="submit" disabled={submitDisabled}>
              {t('General_Save')}
            </Button>
          }
          content={
            <Grid direction={'row'} container>
              <Scrollbar sx={{ height: height, mr: 1 }}>
                <Grid
                  item
                  sx={{
                    // different values for every side because of the scrollbar
                    pb: 2,
                    pt: 4,
                    pl: 0,
                    pr: 1,
                    '&: focus': {
                      outline: 'none',
                    },
                  }}
                >
                  <Stack>
                    <Typography variant="subtitle1" fontWeight={700}>
                      {t('ActionPlanningSubsidiesAddSubsidyForm_AvailableSubsidies')}
                    </Typography>
                    <Typography
                      sx={{
                        my: 2,
                      }}
                    >
                      {t('ActionPlanningSubsidiesAddSubsidyForm_AvailableSubsidiesDescription', { city: city })}
                    </Typography>
                  </Stack>
                  <SubsidyListWithCheckboxes data={subBuildingSubsidiesGroupedByRegionType} onSelect={onSelect} />
                </Grid>
              </Scrollbar>
              <Scrollbar
                sx={{
                  height: height,
                  borderLeft: `1px solid ${theme.palette.grey[500_24]}`,
                }}
              >
                <Grid
                  item
                  sx={{
                    p: 4,
                    height: height,
                  }}
                >
                  <SelectedSubsidies
                    selectedSubsidies={fieldArray.fields}
                    subBuildingSubsidies={subBuildingSubsidies}
                    actionCost={action.cost}
                    deselectSubsidy={(index: number) => fieldArray.remove(index)}
                  />
                </Grid>
              </Scrollbar>
            </Grid>
          }
          showDividers
        />
      </FormProvider>
      <WarningModal
        title={t('General_DiscardChanges')}
        open={warningModalOpen}
        description={t('General_DiscardChangesDescription')}
        onAcknowledge={() => {
          setWarningModalOpen(false);
          resetFormToDefault();
          onClose();
        }}
        onClose={() => {
          setWarningModalOpen(false);
        }}
        buttonText={t('General_Discard')}
        cancelText={t('General_Cancel')}
      />
    </PreDialog>
  );
}

export const isSubsidyApplicableToAction = (
  subsidy: DataCollectionSubBuildingSubBuildingSubsidyFragment,
  renovationType?: renovation_type_enum,
) => {
  if (!renovationType) return false;
  const applicableCategories = mapActionCategoryToSubsidyCategories(renovationType);
  //check if subsidy.categories includes one of the applicable categories
  return applicableCategories.some((category) =>
    subsidy.categories.some((subsidyCategory) => subsidyCategory.subsidy_category_type === category),
  );
};
