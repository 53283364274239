/* eslint-disable @typescript-eslint/ban-ts-comment */
import Grid from '@mui/material/Grid';
import { calculateUValue } from '@predium/client-lookup';
import { envelope_type_enum } from '@predium/enums';
import { cmToM, Units } from '@predium/utils';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PitchedRoofFormValuesProps } from '../../BuildingEnvelope';
import { getEnvelopeIcon } from '../Envelope.util';
import { EnvelopeConstructionDetails } from './EnvelopeConstructionDetails';
import { EnvelopeInsulation } from './EnvelopeInsulation';
import { EnvelopeUnitField } from './EnvelopeUnitField';
import { EnvelopeUnitWrapper, FieldSectionTitle } from './EnvelopeUnitWrapper';

export function EnvelopePitchedRoof({
  data,
  title,
  index,
  handleCopy,
  handleRemove,
}: {
  data: PitchedRoofFormValuesProps;
  title: string;
  index: number;
  handleCopy: (index: number) => void;
  handleRemove: (index: number) => void;
}) {
  const { t } = useTranslation();
  const type = envelope_type_enum.PITCHED_ROOF;

  const { setValue, getValues } = useFormContext();

  const fieldNamePrefix = `${type}[${index}]`;

  const uValueFieldName = `${fieldNamePrefix}.u_value`;

  const constructionThicknessFieldName = `${fieldNamePrefix}.base_construction_thickness`;
  const constructionLambdaFieldName = `${fieldNamePrefix}.base_construction_lambda`;

  const thicknessFieldName = `${fieldNamePrefix}.insulation_thickness`;
  const lambdaFieldName = `${fieldNamePrefix}.insulation_lambda`;

  const reCalculateUValue = () => {
    const newUValue = calculateUValue(
      type,
      cmToM(getValues(constructionThicknessFieldName)),
      getValues(constructionLambdaFieldName),
      cmToM(getValues(thicknessFieldName)),
      getValues(lambdaFieldName),
    );

    setValue(uValueFieldName, newUValue);
  };

  return (
    <EnvelopeUnitWrapper
      title={title}
      icon={getEnvelopeIcon(type)}
      data={data}
      index={index}
      handleCopy={handleCopy}
      handleRemove={handleRemove}
    >
      <>
        {/* general information */}
        <Grid item container md={4}>
          <Grid item xs={12}>
            <FieldSectionTitle title={t('General_BasicData')} />
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={6}>
              <EnvelopeUnitField
                title={t('General_ComponentArea')}
                unit={Units.area}
                name={`${type}[${index}].area`}
                //@ts-ignore
                id={data.id}
                fieldType="number"
              ></EnvelopeUnitField>
            </Grid>
            <Grid item xs={6}>
              <EnvelopeUnitField
                title={t('General_Inclination')}
                name={`${type}[${index}].inclination`}
                unit={Units.inclination}
                //@ts-ignore
                id={data.id}
                fieldType="number"
              />
            </Grid>
            <Grid item xs={6}>
              <EnvelopeUnitField
                title={t('General_UValue')}
                unit={Units.uValue}
                name={`${type}[${index}].u_value`}
                //@ts-ignore
                id={data.id}
                fieldType="number"
                isFieldEditable={false}
                tooltipProps={{
                  labels: {
                    title: t('General_UValue'),
                    body: t('Education_UValueBody'),
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <EnvelopeUnitField
                title={t('General_Orientation')}
                name={`${type}[${index}].orientation`}
                //@ts-ignore
                id={data.id}
                fieldType="orientation"
              />
            </Grid>
          </Grid>
        </Grid>

        {/* base construction */}
        <Grid item container md={4}>
          <EnvelopeConstructionDetails data={data} index={index} reCalculateUValue={reCalculateUValue} />
        </Grid>

        <Grid item container md={4} pl={3}>
          <EnvelopeInsulation data={data} index={index} reCalculateUValue={reCalculateUValue} />
        </Grid>
      </>
    </EnvelopeUnitWrapper>
  );
}
