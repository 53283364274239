export const CircleIcon = ({ backgroundColor }: { backgroundColor: string }) => {
  return (
    <span
      style={{
        backgroundColor,
        width: '10px',
        minWidth: '10px',
        height: '10px',
        display: 'inline-block',
        marginTop: 'auto',
        marginBottom: 'auto',
        borderRadius: '50%',
      }}
    />
  );
};
