import { useMutation, useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Grid, MenuItem, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { object, string } from 'yup';
import Label from '../../../components/Label';
import { SnackbarTimeouts } from '../../../components/NotistackProvider';
import { FormProvider, RHFSelect, RHFTextField } from '../../../components/hook-form';
import PreDialog, { PreDialogBody } from '../../../components/presentations/PreDialog/PreDialog';
import { CREATE_PORTFOLIO } from '../../../graphql/Portfolio.mutations';
import { GET_PORTFOLIOS, GET_PORTFOLIOS_WITH_WRITE_PERMISSIONS } from '../../../graphql/Portfolio.queries';
import { GET_ORG_PREFERENCES } from '../../../graphql/common.queries';
import useOrgPreferences from '../../../hooks/useOrgPreferences';
import usePosthogTracking from '../../../hooks/usePosthogTracking';
import useSessionData from '../../../hooks/useSessionData';
import CRREMConfigToggle, { CRREMConfigToggleEnum } from './EmissionFactorToggle';

export const CRREM_CONFIG_REQUIRED_MESSAGE = 'Die Datenbank für Emissionsfaktoren ist ein Pflichtfeld';

type Props = {
  open: boolean;
  onClose: () => void;
  onPortfolioCreate?: () => void;
};

export type PortfolioFormValuesProps = {
  name: string;
  crremConfigId: string;
};

export default function PortfolioCreateDialog({ open, onClose, onPortfolioCreate }: Props) {
  const { t } = useTranslation();
  const { trackEvent } = usePosthogTracking();
  const { enqueueSnackbar } = useSnackbar();
  const { data } = useQuery(GET_PORTFOLIOS);

  const [currentEmissionFactorToggle, setCurrentEmissionFactorToggle] = useState(CRREMConfigToggleEnum.DEFAULT);

  const portfolios = data?.portfolio ? data.portfolio : [];
  const { activeCRREMConfiguration, crremConfigurations } = useOrgPreferences();

  const {
    //@ts-ignore
    user: { id: userId },
  } = useSessionData();

  const [createMutateFunction, { loading }] = useMutation(CREATE_PORTFOLIO, {
    onError: () => {
      enqueueSnackbar(t('PortfolioCreateDialog_SnackbarResponse-Error'), {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
      });
    },

    onCompleted: () => {
      enqueueSnackbar(t('PortfolioCreateDialog_SnackbarResponse-Success'), {
        variant: 'success',
        autoHideDuration: SnackbarTimeouts.Success,
      });
      onClose();
      reset();
      onPortfolioCreate?.();
    },
    refetchQueries: [
      GET_PORTFOLIOS,
      { query: GET_PORTFOLIOS_WITH_WRITE_PERMISSIONS, variables: { user_id: userId } },
      GET_ORG_PREFERENCES,
    ],
  });

  const NewPortfolioSchema = object().shape({
    name: string()
      .required(t('General_PortfolioNameIsRequired'))
      .notOneOf(
        portfolios.map((p) => p.name),
        t('General_PortfolioAlreadyExists'),
      ),
    crremConfigId: string()
      .required(CRREM_CONFIG_REQUIRED_MESSAGE)
      .oneOf(
        crremConfigurations.map((c) => c.getStringId()),
        CRREM_CONFIG_REQUIRED_MESSAGE,
      ),
  });

  const defaultValues: PortfolioFormValuesProps = useMemo(
    () => ({
      name: '',
      crremConfigId: activeCRREMConfiguration.getStringId(),
    }),
    [activeCRREMConfiguration],
  );

  const methods = useForm<PortfolioFormValuesProps>({
    resolver: yupResolver(NewPortfolioSchema),
    defaultValues,
    mode: 'onChange',
  });

  const { handleSubmit, reset, setValue } = methods;
  const crremConfigFieldName = 'crremConfigId';

  const onEmissionTypeToggle = (value: CRREMConfigToggleEnum) => {
    setCurrentEmissionFactorToggle(value);
    if (value === CRREMConfigToggleEnum.DEFAULT) {
      setValue(crremConfigFieldName, activeCRREMConfiguration.getStringId());
    } else {
      setValue(crremConfigFieldName, '');
    }
  };

  const onSubmit = async (data: PortfolioFormValuesProps) => {
    const chosenConfig =
      currentEmissionFactorToggle === CRREMConfigToggleEnum.DEFAULT
        ? undefined
        : crremConfigurations.find((config) => config.getStringId() === data.crremConfigId);

    const createResult = await createMutateFunction({
      variables: {
        name: data.name,
        baseEmissionFactorType: chosenConfig?.getBaseEmissionFactor(),
        configId: chosenConfig?.getConfigId(),
      },
    });

    if (createResult.errors?.length !== 0 || !createResult.errors) {
      trackEvent('PORTFOLIO_CREATED', {
        emissionFactorType: currentEmissionFactorToggle === CRREMConfigToggleEnum.DEFAULT ? null : data.crremConfigId,
      });
    }
  };

  const onDialogClose = () => {
    setCurrentEmissionFactorToggle(CRREMConfigToggleEnum.DEFAULT);
    onClose();
  };

  return (
    <PreDialog open={open} onClose={onDialogClose} fullWidth type="definedByChildren">
      <FormProvider
        methods={methods}
        onSubmit={(event) => {
          //TODO Adding stopPropagation and  preventDefault for here now as page reloads on submit, investigate this later why.
          event.stopPropagation();
          event.preventDefault();
          handleSubmit(onSubmit)();
        }}
      >
        <PreDialogBody
          dialogtitle={t('General_CreateNewPortfolio')}
          content={
            <Grid container>
              <Typography sx={{ textAlign: 'justify', mb: 3 }}>{t('PortfolioCreateDialog_Description')}</Typography>
              <Grid item xs={12}>
                <RHFTextField name="name" label={t('General_EnterName')} />
              </Grid>
              <Grid container alignItems={'center'} mt={4} mb={2}>
                <Grid item xs={8}>
                  <Typography variant="subtitle1">{t('OrgSettings_DatabaseEmissionFactors')}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <CRREMConfigToggle
                    onEmissionTypeToggle={onEmissionTypeToggle}
                    defaultValue={currentEmissionFactorToggle}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} mb={2}>
                <RHFSelect
                  select
                  name={crremConfigFieldName}
                  label={t('General_SelectDatabaseEmissionFactors')}
                  disabled={currentEmissionFactorToggle === CRREMConfigToggleEnum.DEFAULT}
                >
                  {crremConfigurations.map((config) => (
                    <MenuItem
                      key={config.getStringId()}
                      value={config.getStringId()}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      {config.getTitleElement()}
                      {config.getStringId() === activeCRREMConfiguration.getStringId() && (
                        <Label ml={1}> {t('General_Standard')} </Label>
                      )}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Grid>
            </Grid>
          }
          actions={
            <>
              <Button variant="outlined" onClick={onClose}>
                {t('General_Cancel')}
              </Button>
              <LoadingButton type="submit" variant="contained" loading={loading}>
                {t('General_Create')}
              </LoadingButton>
            </>
          }
        ></PreDialogBody>
      </FormProvider>
    </PreDialog>
  );
}
