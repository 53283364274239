import { useQuery } from '@apollo/client';
import { Box, Button, MenuItem, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from '../../../../../components/data-table/debounce';
import { RHFSelect } from '../../../../../components/hook-form';
import InlineUser from '../../../../../components/InlineUser';
import BuildingMap from '../../../../../components/map/BuildingMap';
import { GET_USERS } from '../../../../../graphql/DataCollection.queries';
import usePosthogTracking from '../../../../../hooks/usePosthogTracking';
import { useBuildingCreation } from '../BuildingCreationProvider';

const Sidebar = () => {
  const { t } = useTranslation();
  const { trackEvent } = usePosthogTracking();
  const { getBuilding, setValue, handleBack } = useBuildingCreation();
  const [hovered, setHovered] = useState(false);
  // debouncedHovered so we can render the component and then animate it
  const debouncedHovered = useDebounce(hovered, 100);
  const { data } = useQuery(GET_USERS);
  const building = getBuilding();
  const users = data?.user ?? [];
  const showMap = building.addressSearch?.addressSearch?.postalCode && building.buildingSelection?.building_ids?.length;
  const whichSubstepToGoBack = showMap ? 0 : 1;
  const hoveredHeight = showMap ? '420px' : '160px';
  const regularHeight = showMap ? '360px' : '90px';

  const handleSelectionChange = (ids: number[]) => {
    setValue('buildingSelection.building_ids', ids);
  };

  const handleBuildingClick = (isBeingSelected: boolean) => {
    if (isBeingSelected) {
      trackEvent('MAP_BUILDING_SELECTED');
    } else {
      trackEvent('MAP_BUILDING_UNSELECTED');
    }
  };

  return (
    <Stack flexBasis={310} maxWidth={300} flexShrink={0} position="sticky" top={0} alignSelf="flex-start">
      <Stack
        border="1px solid"
        borderColor="divider"
        borderRadius={2}
        padding={3}
        marginBottom={2}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        sx={{
          transition: 'all 0.2s ease',
          height: hovered ? hoveredHeight : regularHeight,
        }}
      >
        {showMap ? (
          <Stack
            maxWidth={236}
            width="100%"
            marginTop={1}
            marginLeft="auto"
            marginRight="auto"
            borderRadius={2}
            marginBottom={3}
            sx={{
              outline: (theme) => `8px solid ${theme.palette.grey[200]}`,
            }}
          >
            <Box height={236}>
              <BuildingMap
                editMode
                preSelectBuilding
                lockView
                id="building-creation-map-information"
                onSelectionChange={handleSelectionChange}
                onBuildingClick={handleBuildingClick}
                address={{
                  mapbox_building_ids: building.buildingSelection.building_ids,
                  latitude: building.addressSearch.addressSearch.lat,
                  longitude: building.addressSearch.addressSearch.long,
                }}
              />
            </Box>
          </Stack>
        ) : undefined}
        <Typography variant="subtitle2" fontWeight={700} color="text.primary">
          {building.address.street}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {building.address.postalCode} {building.address.city}
        </Typography>
        {hovered && (
          <Button
            type="button"
            variant="soft"
            size="large"
            onClick={() => handleBack(whichSubstepToGoBack)}
            sx={{
              marginTop: '16px',
              opacity: debouncedHovered ? 1 : 0,
              transition: 'all 0.2s ease',
            }}
          >
            {t('BuildingCreation_Step2ChangeLocation')}
          </Button>
        )}
      </Stack>
      <RHFSelect size="small" label={t('General_ResponsibleUser')} name="responsibleUserId">
        {users.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            <InlineUser size={30} firstName={option.first_name} lastName={option.last_name} />
          </MenuItem>
        ))}
      </RHFSelect>
    </Stack>
  );
};

export default Sidebar;
