import { useQuery } from '@apollo/client';
import { Box, Card, Divider, Grid, MenuItem, Stack, Switch, TextField, Typography } from '@mui/material';
import Container from '@mui/material/Container';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Page from '../../components/Page';
import { CRREMConfiguration } from '../../contexts/OrgPreferences/CRREMConfiguration';
import { GET_PORTFOLIOS } from '../../graphql/Portfolio.queries';
import useOrgPreferences from '../../hooks/useOrgPreferences';
import useSubscriptionWithFallback from '../../hooks/useSubscriptionFallback';
import { CRREMConfigurationChangeDialog } from '../../sections/Org/Settings/CRREMConfigurationChangeDialog';

export default function OrgSettings() {
  const { t } = useTranslation();

  const { economicUnitsToggled, toggleEconomicUnits, crremConfigurations, activeCRREMConfiguration } =
    useOrgPreferences();

  const [newCRREMConfiguration, setNewCRREMConfiguration] = useState<CRREMConfiguration>();
  const [showEmissionFactorChangeDialog, setShowEmissionFactorChangeDialog] = useState<boolean>(false);

  const { data: portfolios } = useQuery(GET_PORTFOLIOS);

  return (
    <Page title={t('Settings_ManageOrganization')}>
      <Container maxWidth="lg">
        <HeaderBreadcrumbs heading={t('Settings_ManageOrganization')} links={[]} />
        <Card sx={{ p: 3, alignContent: 'center' }}>
          <Grid container>
            <Grid item xs={12}>
              <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <SettingInfo
                  title={t('OrgSettings_EnableEconomicUnits')}
                  description={t('OrgSettings_EnableEconomicUnitsDescription')}
                />

                <Switch
                  checked={economicUnitsToggled}
                  onChange={() => {
                    toggleEconomicUnits();
                  }}
                />
              </Stack>

              <Divider sx={{ my: 2 }} />

              <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <SettingInfo
                  title={t('OrgSettings_DatabaseEmissionFactors')}
                  description={t('OrgSettings_DatabaseEmissionFactorsDescription')}
                />
                <div style={{ width: 250 }}>
                  <TextField
                    fullWidth
                    select
                    value={activeCRREMConfiguration.getStringId()}
                    onChange={(event) => {
                      setNewCRREMConfiguration(
                        crremConfigurations.find((config) => event.target.value === config.getStringId()),
                      );
                      setShowEmissionFactorChangeDialog(true);
                    }}
                  >
                    {crremConfigurations.map((configuration) => (
                      <MenuItem key={configuration.getStringId()} value={configuration.getStringId()}>
                        {configuration.getTitleElement()}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </Stack>
            </Grid>
          </Grid>
        </Card>
        {newCRREMConfiguration && (
          <CRREMConfigurationChangeDialog
            open={showEmissionFactorChangeDialog}
            onClose={() => setShowEmissionFactorChangeDialog(false)}
            newCRREMConfiguration={newCRREMConfiguration}
            currentCRREMConfiguration={activeCRREMConfiguration}
            portfoliosCount={portfolios?.portfolio.length ?? 0}
            useSubscription={useSubscriptionWithFallback}
          />
        )}
      </Container>
    </Page>
  );
}

type SettingInfoType = {
  title: string;
  description: string;
};

const SettingInfo = ({ title, description }: SettingInfoType) => {
  return (
    <Box>
      <Typography variant="body1">{title}</Typography>
      <Typography variant="body2" color="text.secondary">
        {description}
      </Typography>
    </Box>
  );
};
