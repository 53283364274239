import { Button } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ICONS } from '../../../../assets/icons';
import Iconify from '../../../../components/Iconify';
import PreDialog from '../../../../components/presentations/PreDialog/PreDialog';
import SingleSelectFilter from '../../../../components/search/SingleSelectFilter';
import useBuilding from '../Context/useBuilding';
import { DataType, getAvailableDataTypes } from './Documents';
import UploadFileDialog from './UploadFileDialog';

// ----------------------------------------------------------------------

type Props = {
  selectedDataType: DataType;
  handleDataTypeChange: (value: DataType) => void;
  filterName: string;
  handleFilterNameChange: (value: string) => void;
  onUploadSuccess: () => void;
};

export default function DocumentsToolbar({
  filterName,
  handleFilterNameChange,
  handleDataTypeChange,
  selectedDataType,
  onUploadSuccess,
}: Props) {
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);

  const {
    hasEditAccess,
    building: { id: buildingId },
  } = useBuilding();
  const { t } = useTranslation();
  const AVAILABLE_DATA_TYPES = getAvailableDataTypes(t);

  const filterItems = AVAILABLE_DATA_TYPES.map((type) => ({
    name: type.label,
    value: type.key,
  }));

  const handleUploadDialogOpen = () => {
    setIsUploadDialogOpen(true);
  };

  const handleUploadDialogClose = () => {
    setIsUploadDialogOpen(false);
    onUploadSuccess();
  };

  return (
    <Stack px={2} direction="row" justifyContent="space-between" alignItems="center">
      <Stack direction="row" mb={3}>
        <TextField
          value={filterName}
          onChange={(event) => handleFilterNameChange(event.target.value)}
          placeholder={t('General_SearchPlaceholder_Document')}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
              </InputAdornment>
            ),
          }}
          sx={{
            minWidth: 250,
            mr: 2,
            '.MuiOutlinedInput-input': {
              py: 1,
            },
          }}
        />

        <SingleSelectFilter
          items={filterItems}
          selectedItem={selectedDataType}
          onChanged={(value) => handleDataTypeChange(value as DataType)}
          label={t('General_FileType')}
        />
      </Stack>
      {hasEditAccess && (
        <Button
          variant="contained"
          color="primary"
          startIcon={<Iconify icon={ICONS.FILEUPLOADOUTLINE} />}
          onClick={handleUploadDialogOpen}
        >
          {t('General_Upload')}
        </Button>
      )}
      <PreDialog type="definedByChildren" maxWidth="lg" open={isUploadDialogOpen} onClose={handleUploadDialogClose}>
        <UploadFileDialog buildingId={buildingId} onClose={handleUploadDialogClose} />
      </PreDialog>
    </Stack>
  );
}
