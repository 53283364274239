/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useLazyQuery, useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import {
  ActionImpactDataOutputFragment,
  ActionPlanningActionSubsidyFragment,
  BuildingModelFragment,
  DataCollectionSubBuildingSubBuildingSubsidyFragment,
  EnvelopeRenovationParameterInput,
  EnvelopeRenovationParameterOutput,
  SimulateActionOutputResponseFragment,
  SubsidyInput,
  action_mutation_error_enum,
  country_enum,
  envelope_type_enum,
  renovation_type_enum,
} from '@predium/client-graphql';
import { ensureDefined } from '@predium/utils';
import dayjs from 'dayjs';
import isNil from 'lodash/isNil';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { DelayedLoading } from '../../../../components/Loading';
import { SnackbarTimeouts } from '../../../../components/NotistackProvider';
import Scrollbar from '../../../../components/Scrollbar';
import CenterBox from '../../../../components/common/CenterBox';
import { FormProvider } from '../../../../components/hook-form';
import PreDialog from '../../../../components/presentations/PreDialog/PreDialog';
import { EDIT_ACTION, SIMULATE_EDIT_ACTION } from '../../../../graphql/ActionPlanning.mutations';
import {
  GET_ACTIONPLAN,
  GET_ACTIONS_FOR_ACTION_PLAN,
  GET_ACTION_PLAN_METRICS,
  GET_ANALYZED_SCENARIO,
  GET_EDIT_ACTION_STATUS_QUO,
  GET_SUBSIDIES,
} from '../../../../graphql/ActionPlanning.queries';
import usePosthogTracking from '../../../../hooks/usePosthogTracking';
import { ActionState } from '../../../../pages/ActionPlanning/ActionPlanningActionPlan';
import { getActionIcon } from '../../../../utils/icons';
import {
  affectedConsumerPartsByAction,
  affectedEnvelopePartsByAction,
  getActionName,
  getEditActionModificationImpact,
  getEditActionStatusQue,
  getIconURLByAction,
  getMaxDate,
  getMinDate,
  getModificationImpactAfterSimulation,
  getModificationImpactBeforeSimulation,
  isCustomCostLowerThanSubsidies,
  processSubsidies,
} from '../ActionPlan.utils';
import { ActionWithMetricData } from '../ActionPlanSections/ActionList/ActionListTable';
import DiscardActionModal from '../Components/DiscardActionModal';
import ActionDetails from './ActionDetails';
import { CustomCostsForm } from './ActionDetails/ActionPricing/RowCosts';
import {
  ActionFormSchemaType,
  CustomActionFormRef,
  EnvelopeActionFormRef,
  EnvelopePartialRenovationOption,
  TechnicalActionFormRef,
  categoryShortNameConversion,
  category_enum,
  formatFormData,
} from './CreateAction';
import ImplementationToDate from './EditActions/Compoenents/ImplementationToDate';
import CustomAction from './EditActions/CustomAction';
import { getActionInputParameters } from './EditActions/EditActionParameters';
import EnvelopeAction from './EditActions/EnvelopeAction';
import TechnicalAction from './EditActions/TechnicalAction';

export type EnvelopeActionEditProps = {
  selectedAction?: ActionWithMetricData;
  previousActionBuildingModel: BuildingModelFragment;
  selectedActionType: envelope_type_enum | '';
  ref: EnvelopeActionFormRef;
  /**
   * Date for the action implementation
   * selected action implementation to date
   * @default selected action implementation to date
   * */
  implementationTo: Date;
  resetSimulatedData: () => void;
  loading: boolean;
  parameters: EnvelopeRenovationParameterOutput;
  editable?: boolean;
  affectedParts: EnvelopePartialRenovationOption[];
  isRecommendedAction?: Boolean;
  implementationToComponent: JSX.Element;
};

type Props = {
  onClose: VoidFunction;
  baseBuildingModel: BuildingModelFragment;
  country: country_enum;
  actionSubsidies: ActionPlanningActionSubsidyFragment[];
  allActions: ActionWithMetricData[] | [];
  isRecommendedAction?: Boolean;
  actionState: ActionState;
  subBuildingSubsidies: DataCollectionSubBuildingSubBuildingSubsidyFragment[];
  scenarioId: number;
};

export default function EditAction({
  onClose,
  baseBuildingModel,
  country,
  actionSubsidies,
  allActions,
  actionState,
  subBuildingSubsidies,
  scenarioId,
}: Props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { id: actionPlanId } = useParams();
  const { trackEvent } = usePosthogTracking();

  const { selected, previous: previousAction } = actionState;
  const selectedAction = ensureDefined(selected);

  const renovationType = ensureDefined(selectedAction?.renovations[0]?.renovation_type_id);

  const [category, setCategory] = useState(
    renovationType === renovation_type_enum.ENVELOPE
      ? category_enum.ENVELOPE
      : renovationType === renovation_type_enum.CUSTOM || renovationType === renovation_type_enum.MAINTENANCE
      ? category_enum.CUSTOM
      : category_enum.TECHNICAL,
  );
  const filteredActionSubsidies = actionSubsidies.filter((subsidy) => subsidy.action.id === selectedAction?.id);

  const shortSubsidyData: SubsidyInput[] = filteredActionSubsidies.map((subsidy) => ({
    id: subsidy.subsidy.id,
    type: subsidy.type,
    value: subsidy.value,
  }));

  const [simulatedData, setSimulatedData] = useState<SimulateActionOutputResponseFragment | undefined>(undefined);
  const [modificationImpact, setModificationImpact] = useState<Partial<ActionImpactDataOutputFragment> | undefined>(
    selectedAction ? selectedAction.metric : undefined,
  );

  const [isCurrentFormDirty, setIsCurrentFormDirty] = useState(false);
  const [showCloseWarningDialog, setCloseWarningDialog] = useState(false);
  const [subsidyData, setSubsidyData] = useState<SubsidyInput[]>(shortSubsidyData);
  const [shouldDisable, setShouldDisable] = useState(true);

  const formRef: EnvelopeActionFormRef | TechnicalActionFormRef | CustomActionFormRef = useRef(null);

  const previousActionBuildingModel = previousAction ? previousAction.building_models[0] : baseBuildingModel;

  const handleCustomErrors = (error: action_mutation_error_enum) => {
    switch (error) {
      case action_mutation_error_enum.ACTION_PLAN_INCONSISTENT:
        enqueueSnackbar(t('ActionPlanning-EditActionSimulation-ACTION_PLAN_INCONSISTENT'), {
          variant: 'error',
          autoHideDuration: SnackbarTimeouts.Error,
        });
        break;
      case action_mutation_error_enum.MUTATION_NOT_POSSIBLE:
        enqueueSnackbar(t('ActionPlanning-EditActionSimulation-MUTATION_NOT_POSSIBLE'), {
          variant: 'error',
          autoHideDuration: SnackbarTimeouts.Error,
        });
        break;
      default:
        const exhaustiveCheck: never = error;
        throw new Error(`Unhandled action_mutation_error_enum: ${exhaustiveCheck}`);
    }
  };

  const [getEditActionStatusQuo, { data: statusQuo, loading: loadingStatusQuo }] = useLazyQuery(
    GET_EDIT_ACTION_STATUS_QUO,
    {
      onError: () =>
        enqueueSnackbar(t('ActionPlanning_SimulateAction-error'), {
          variant: 'error',
          autoHideDuration: SnackbarTimeouts.Error,
        }),
      onCompleted: (data) => {
        const error = data.getEditStatusQuo.error;
        if (error) {
          handleCustomErrors(error);
        }
        const statusQuo = data.getEditStatusQuo.result;
        if (statusQuo) {
          if (renovationType === renovation_type_enum.MAINTENANCE) {
            setModificationImpact(getEditActionModificationImpact(statusQuo, statusQuo));
          } else {
            setModificationImpact(getEditActionStatusQue(statusQuo));
          }
        }
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  const statusQuoData = statusQuo?.getEditStatusQuo.result;

  const [simulateEnvelopeAction, { loading: simulationLoading }] = useMutation(SIMULATE_EDIT_ACTION, {
    onCompleted: (data) => {
      const error = data.simulateEditAction.error;
      if (error) {
        handleCustomErrors(error);
        setModificationImpact(selectedAction.metric);
        const cost2Str = Math.round(selectedAction.metric.total_renovation_cost ?? 0).toString();
        setCostFormValue('cost_custom_input', cost2Str);
      }
      const result = data?.simulateEditAction.result;
      setSimulatedData(result ?? undefined);

      if (result && statusQuoData) {
        const impact = getEditActionModificationImpact(statusQuoData, result);
        if (impact) {
          setModificationImpact(impact);
        }
      } else if (result) {
        const impact = getModificationImpactAfterSimulation(selectedAction.metric, result);
        if (impact) {
          setModificationImpact(impact);
        }
      }
    },
    onError: () =>
      enqueueSnackbar(t('ActionPlanning_SimulateAction-error'), {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
      }),
  });

  const [editAction, { loading: editActionLoading }] = useMutation(EDIT_ACTION, {
    onError: () =>
      enqueueSnackbar(t('ActionPlanning_EditAction-error'), {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
      }),
    onCompleted: (data) => {
      const error = data.editAction.error;

      if (data.editAction.error) {
        const errorMessage =
          error === action_mutation_error_enum.ACTION_PLAN_INCONSISTENT
            ? t('ActionPlanning-EditActionSimulation-ACTION_PLAN_INCONSISTENT')
            : t('ActionPlanning-EditActionSimulation-MUTATION_NOT_POSSIBLE');

        enqueueSnackbar(errorMessage, {
          variant: 'error',
          autoHideDuration: SnackbarTimeouts.Error,
        });
        if (selectedAction) {
          trackEvent('ACTION_EDIT_FAILED', {
            message: errorMessage,
            actionId: selectedAction.id,
          });
        }
        return;
      } else {
        enqueueSnackbar(t('ActionPlanning_EditAction-success'), {
          variant: 'success',
          autoHideDuration: SnackbarTimeouts.Success,
        });

        let systemType = '';
        if (parameters && renovationType === renovation_type_enum.ENERGY_CONSUMER && 'system_type' in parameters) {
          systemType = parameters ? (parameters.system_type as string) : '';
        }

        if (selectedAction) {
          trackEvent('ACTION_EDITED', {
            renovationType: renovationType ?? null,
            systemType: systemType,
            withCustomCost: customCostWithNumbers?.use_custom_cost ?? false,
            withSubsidies: subsidyData.length > 0,
            subsidies: subsidyData.flatMap((s) => s.id),
            actionId: selectedAction.id,
          });
        }
      }
      onClose();
    },
    refetchQueries: [
      GET_ACTIONPLAN,
      GET_ACTIONS_FOR_ACTION_PLAN,
      GET_ACTION_PLAN_METRICS,
      GET_SUBSIDIES,
      { query: GET_ANALYZED_SCENARIO, variables: { scenario_id: scenarioId } },
    ],
  });

  const refetchStatusQuoOnDateChange = (date: Date) => {
    const maxDate = getMaxDate();
    const minDate = getMinDate();

    //not refetch: if date is not valid, date is same as current action date, date is out of range
    if (
      date &&
      dayjs(date).isValid() &&
      dayjs(date) !== dayjs(selectedAction.implementation_to) &&
      dayjs(date) <= dayjs(maxDate) &&
      dayjs(date) >= dayjs(minDate)
    ) {
      getEditActionStatusQuo({
        variables: {
          action_id: selectedAction?.id,
          implementation_to: date,
        },
      });
    }

    //this should not be necessary. cost form behaves weirdly .
    //form field get dirty even if form fields are not touched. refactoring coming soon
    if (renovationType === renovation_type_enum.CUSTOM || renovationType === renovation_type_enum.MAINTENANCE) {
      return;
    }
    if (!getValues().use_custom_cost) {
      setCostFormValue('cost_custom_input', '');
      setCostFormValue('cost_total_custom', undefined);
      setCostFormValue('use_custom_cost', false);
    }
  };

  const renovationArea = useMemo(() => {
    if (!selectedAction?.renovations_envelope?.length) return 0;
    return selectedAction.renovations_envelope.reduce((acc, curr) => acc + (curr.new_envelope?.area ?? 0), 0);
  }, [selectedAction]);

  const defaultCustomCosts = {
    use_custom_cost: false,
    cost_total_custom: 0,
    cost_total: 0,
  };

  const totalCustomCosts =
    selectedAction?.renovations?.reduce((acc, curr) => {
      return {
        use_custom_cost: curr.use_custom_cost || acc.use_custom_cost,
        cost_total: (curr.cost_total ?? 0) + (acc.cost_total ?? 0),
        cost_total_custom: (curr.cost_total_custom ?? 0) + (acc.cost_total_custom ?? 0),
      };
    }, defaultCustomCosts) ?? defaultCustomCosts;

  const costFormMethods = useForm<CustomCostsForm>({
    defaultValues: {
      cost_custom_input: Math.round(totalCustomCosts?.cost_total ?? 0).toString(),
      cost_total_custom: !isNil(totalCustomCosts?.cost_total_custom) ? totalCustomCosts?.cost_total_custom : undefined,
      use_custom_cost: totalCustomCosts?.use_custom_cost ?? false,
    },
  });

  const { getValues, setValue: setCostFormValue, formState: costFormState } = costFormMethods;

  useEffect(() => {
    if (simulatedData) {
      if (!getValues().use_custom_cost) {
        const cost2Str = Math.round(simulatedData?.renovation_costs ?? 0).toString();
        setCostFormValue('cost_custom_input', cost2Str);
        setCostFormValue('use_custom_cost', false);
        setCostFormValue('cost_total_custom', undefined);
      } else {
        return;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [simulatedData]);

  const resetSimulatedData = () => {
    setIsCurrentFormDirty(true);

    if (renovationType === renovation_type_enum.MAINTENANCE) {
      return;
    }
    setSimulatedData(undefined);
    setModificationImpact(undefined);

    if (statusQuoData) {
      setModificationImpact(getEditActionStatusQue(statusQuoData));
    } else {
      const impact = getModificationImpactBeforeSimulation(selectedAction.metric, false);
      if (impact) {
        setModificationImpact(impact);
      }
    }

    if (!getValues().use_custom_cost) {
      setCostFormValue('cost_custom_input', '');
      setCostFormValue('cost_total_custom', undefined);
      setCostFormValue('use_custom_cost', false);
    }
  };

  const getCustomCost = () => {
    const formValues = getValues();
    return {
      cost_custom_input: formValues.cost_custom_input,
      cost_total_custom: formValues.cost_total_custom,
      use_custom_cost: formValues.use_custom_cost ?? false,
    };
  };

  const isDataSimulated = simulatedData !== undefined;
  const customCost = getCustomCost();
  const renovationCost = customCost.use_custom_cost
    ? customCost.cost_total_custom
    : simulatedData
    ? simulatedData.renovation_costs
    : modificationImpact?.total_renovation_cost
    ? modificationImpact?.total_renovation_cost
    : 0;

  useEffect(() => {
    if (renovationCost === 0) {
      setSubsidyData([]);
    }
  }, [renovationCost]);

  const isCostFormDirty = costFormState.isDirty;

  const isSubsidyDataChanged = () => {
    const newSubsidyData = subsidyData.map((subsidy) => ({
      id: subsidy.id,
      type: subsidy.type,
      value: subsidy.value,
    }));

    const isChanged = JSON.stringify(newSubsidyData) !== JSON.stringify(shortSubsidyData);
    return isChanged;
  };

  const isSubsidyFormDirty = isSubsidyDataChanged();

  const selectedSubsidies: Omit<ActionPlanningActionSubsidyFragment, 'id'>[] = processSubsidies(
    subBuildingSubsidies,
    subsidyData,
    selectedAction,
    renovationType,
    simulatedData,
    getCustomCost(),
  );

  useEffect(() => {
    const shouldDisableButton = () => {
      let disable = false;

      const isSubsidyError = isCustomCostLowerThanSubsidies(renovationCost ?? 0, selectedSubsidies);

      // simulated is not happened yet but cost and subsidies are edited
      if (!simulatedData) {
        disable = true;

        //cost and subsidy edited
        if (isCostFormDirty && isCurrentFormDirty) {
          disable = true;
        }

        if (isSubsidyFormDirty && isCostFormDirty && isSubsidyError) {
          //cost and subsidies are edited and there is an error
          disable = true;
        }

        if (!isSubsidyFormDirty && isCostFormDirty && isSubsidyError) {
          //cost edited but subsidies not and there is an error
          disable = true;
        }

        if (isCostFormDirty && !isSubsidyError && !isCurrentFormDirty) {
          //only cost is edited and there is no error
          disable = false;
        }

        if (isSubsidyFormDirty && !isCostFormDirty && isSubsidyError) {
          //only subsidies edited and there is an error
          disable = true;
        }

        if (isSubsidyFormDirty && !isCostFormDirty && !isSubsidyError) {
          //subsidies edited and there is no error but cost is not edited
          disable = false;
        }

        if (isCurrentFormDirty) {
          //current form is dirty but no simulation is happened
          disable = true;
        }
      } else if ((isSubsidyFormDirty && isCostFormDirty) || isCurrentFormDirty || isCostFormDirty) {
        //simulation is happened but then cost and/or subsidies are edited
        disable = isSubsidyError;
      }

      return disable;
    };

    if (renovationType === renovation_type_enum.MAINTENANCE) {
      if (isCurrentFormDirty || isCostFormDirty) {
        setShouldDisable(false);
      } else {
        setShouldDisable(true);
      }
    } else {
      setShouldDisable(shouldDisableButton());
    }
  }, [
    renovationCost,
    isCostFormDirty,
    isSubsidyFormDirty,
    isCurrentFormDirty,
    selectedSubsidies,
    simulatedData,
    renovationType,
  ]);

  if (!selectedAction) {
    return null;
  }

  const customCostWithNumbers = {
    use_custom_cost: customCost.use_custom_cost ?? false,
    cost_total_custom: customCost.cost_total_custom ?? 0,
  };

  const handleSubmitForm = async (): Promise<ActionFormSchemaType | null> => {
    let formData: ActionFormSchemaType | null = null;
    const formMethods = formRef?.current?.methods;

    if (!formMethods) {
      return null;
    }

    await formMethods.handleSubmit((data: ActionFormSchemaType) => {
      formData = data;
    })();

    return formData;
  };

  const onSimulateActionClick = async () => {
    const formData = await handleSubmitForm();

    if (!formData || !actionPlanId) {
      return;
    }
    const implementationToDate = formData.implementation_to as Date;

    //reassigning to new variable is important rather than updating formData directly. it also updates the form input
    // because of useref
    const updatedFormData = formatFormData(formData, renovationType as renovation_type_enum);

    simulateEnvelopeAction({
      variables: {
        action_id: selectedAction.id,
        ...updatedFormData,
        implementation_to: implementationToDate,
        subsidies: subsidyData,
      },
    });
  };

  const onEditActionClick = async () => {
    const formData = await handleSubmitForm();

    if (!formData || !actionPlanId) {
      return;
    }

    const implementationToDate = formData.implementation_to as Date;

    const updatedFormData = formatFormData(formData, renovationType as renovation_type_enum);

    await editAction({
      variables: {
        action_id: selectedAction.id,
        ...updatedFormData,
        implementation_to: implementationToDate,
        customCost: customCostWithNumbers ?? [],
        subsidies: subsidyData,
      },
    });
  };

  const implementationToComponent = (
    <ImplementationToDate
      resetSimulatedData={resetSimulatedData}
      refetchStatusQuoOnDateChange={(date) => refetchStatusQuoOnDateChange(date)}
      disabled={simulationLoading || editActionLoading || loadingStatusQuo}
    />
  );

  const props = {
    previousActionBuildingModel: previousActionBuildingModel,
    resetSimulatedData: resetSimulatedData,
    loading: simulationLoading || editActionLoading,
    implementationTo: new Date(selectedAction.implementation_to),
    implementationToComponent,
  };

  const handleCancel = () => {
    if (isCurrentFormDirty && simulatedData) {
      setCloseWarningDialog(true);
    } else {
      onClose();
    }
  };

  const envelopeData =
    renovationType === renovation_type_enum.ENVELOPE ? selectedAction.renovations_envelope[0].new_envelope : null;

  const parameters: EnvelopeRenovationParameterInput | null = envelopeData
    ? {
        cost_per_m2: (envelopeData && envelopeData.cost_per_m2) ?? undefined,
        envelope_type: envelopeData.envelope_type_id,
        insulation_lambda: (envelopeData && envelopeData.insulation_lambda) ?? undefined,
        insulation_material_category: envelopeData.insulation_material_category_id || undefined,
        insulation_method: envelopeData.insulation_method_id || undefined,
        insulation_thickness: (envelopeData && envelopeData.insulation_thickness) ?? undefined,
        material_name: (envelopeData && envelopeData.insulation_material_name) ?? '',
        u_value: envelopeData && envelopeData.u_value ? envelopeData.u_value : undefined,
      }
    : null;

  let consumerData;
  let systemType;

  if (renovationType === renovation_type_enum.ENERGY_CONSUMER) {
    consumerData = ensureDefined(selectedAction?.renovations_energy_consumer[0]?.new_energy_consumer);
    systemType = consumerData.energy_consumer_type_id;
  }

  let component;
  switch (renovationType) {
    case renovation_type_enum.ENVELOPE:
      component =
        parameters && 'envelope_type' in parameters && selectedAction ? (
          <EnvelopeAction
            {...props}
            selectedAction={selectedAction}
            formRef={formRef as EnvelopeActionFormRef}
            parameters={parameters}
            affectedParts={affectedEnvelopePartsByAction(selectedAction, allActions, baseBuildingModel)}
          />
        ) : null;
      break;
    case renovation_type_enum.CUSTOM:
    case renovation_type_enum.MAINTENANCE:
      component = (
        <CustomAction
          formRef={formRef as CustomActionFormRef}
          previousActionBuildingModel={previousActionBuildingModel}
          action={selectedAction}
          affectedParts={affectedConsumerPartsByAction(selectedAction, allActions, baseBuildingModel)}
          resetSimulatedData={resetSimulatedData}
          implementationToComponent={implementationToComponent}
        />
      );
      break;
    default:
      component = (
        <TechnicalAction
          {...props}
          affectedParts={affectedConsumerPartsByAction(selectedAction, allActions, baseBuildingModel)}
          formRef={formRef as TechnicalActionFormRef}
          parameters={getActionInputParameters(selectedAction)}
          renovationType={renovationType}
          systemType={systemType}
        />
      );
  }

  return (
    <>
      <PreDialog
        type="definedByChildren"
        open={true}
        onClose={handleCancel}
        maxWidth="lg"
        fullWidth
        disableCloseAction={simulationLoading || editActionLoading}
      >
        <DialogTitle>
          <Stack direction={'row'} alignItems={'center'}>
            <Box component={'img'} src={getIconURLByAction(selectedAction)} width={24} mr={2} />
            <Typography variant="h6">
              {t('General_Edit')} - {getActionName(selectedAction)}
            </Typography>
          </Stack>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Scrollbar>
            <Box component={Paper} height={'100%'}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={4} lg={4} xl={4} p={0}>
                  <Box py={3} pr={3} minHeight={500}>
                    <ToggleButtonGroup
                      size="small"
                      value={category}
                      exclusive
                      sx={{ width: '100%' }}
                      onChange={(_, value) => {
                        setCategory(value);
                      }}
                      disabled={true}
                    >
                      {Object.values(category_enum).map((value, i) => {
                        return (
                          <ToggleButton
                            key={i}
                            value={value}
                            sx={{
                              width: '100%',
                              textAlign: 'center',
                            }}
                          >
                            <Box component={'img'} src={getActionIcon(value)} sx={{ width: 16 }} />
                            <Typography sx={{ pl: 0.5 }} variant="subtitle2">
                              {categoryShortNameConversion(value)}
                            </Typography>
                          </ToggleButton>
                        );
                      })}
                    </ToggleButtonGroup>

                    {component}

                    {renovationType !== renovation_type_enum.MAINTENANCE && (
                      <Box justifyContent={'end'} mt={4} display={'flex'}>
                        <LoadingButton
                          size="medium"
                          variant="contained"
                          //Enabling if all form values are there for now
                          disabled={simulationLoading || !isCurrentFormDirty || isDataSimulated || loadingStatusQuo}
                          loading={simulationLoading}
                          onClick={onSimulateActionClick}
                        >
                          {t('General_Simulate')}
                        </LoadingButton>
                      </Box>
                    )}
                  </Box>
                </Grid>
                <Grid xs={12} item md={8} lg={8} xl={8} p={0}>
                  {loadingStatusQuo ? (
                    <CenterBox height={900}>
                      <DelayedLoading />
                    </CenterBox>
                  ) : (
                    <FormProvider methods={costFormMethods}>
                      <ActionDetails
                        //@ts-ignore
                        metricData={modificationImpact}
                        currentView="action_modal_view"
                        actionSubsidies={selectedSubsidies}
                        renovationArea={renovationArea}
                        simulated
                        showAddSubsidyButton={true}
                        country={country}
                        renovationType={renovationType}
                        setSubsidyData={setSubsidyData}
                        category={category}
                      />
                    </FormProvider>
                  )}
                </Grid>
              </Grid>
              <Divider orientation="vertical" flexItem sx={{ mr: '-2px' }} />
            </Box>
          </Scrollbar>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            onClick={handleCancel}
            variant="outlined"
            sx={{
              mr: 1,
            }}
            disabled={editActionLoading || simulationLoading || loadingStatusQuo} //TODO allow cancellation. separate ticket RED-614.
          >
            {t('General_Cancel')}
          </Button>
          <LoadingButton
            disabled={shouldDisable}
            variant="contained"
            onClick={onEditActionClick}
            loading={editActionLoading}
          >
            {t('General_Save')}
          </LoadingButton>
        </DialogActions>
      </PreDialog>

      {showCloseWarningDialog && (
        <DiscardActionModal
          isOpen={showCloseWarningDialog}
          onClose={() => setCloseWarningDialog(false)}
          onAcknowledge={onClose}
        />
      )}
    </>
  );
}
