/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useMutation } from '@apollo/client';
import { Box, MenuItem } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { IMAGES } from '../../../assets';
import { ICONS } from '../../../assets/icons';
import DeleteConfirmationModal from '../../../components/DeleteConfirmationModal';
import Iconify from '../../../components/Iconify';
import { SnackbarTimeouts } from '../../../components/NotistackProvider';
import PreDialog from '../../../components/presentations/PreDialog/PreDialog';
import TableMoreMenu from '../../../components/table/TableMoreMenu';
import { PermissionType } from '../../../contexts/PermissionContext';
import { DELETE_BUILDING, DELETE_ECONOMIC_UNIT } from '../../../graphql/DataCollection.mutations';
import {
  GET_ECONOMIC_UNITS_WITH_BUILDINGS,
  GET_ECONOMIC_UNITS_WITH_PORTFOLIOS,
} from '../../../graphql/DataCollection.queries';
import useGetAssociatedDataForBuilding from '../../../hooks/useGetAssociatedDataForBuilding';
import useOrgPreferences from '../../../hooks/useOrgPreferences';
import usePermissions from '../../../hooks/usePermissions';
import usePosthogTracking from '../../../hooks/usePosthogTracking';
import useTooltipConstants from '../../../hooks/useTooltipConstants';
import { PATHS } from '../../../routes';
import LimitedBulkExport from '../Buildings/BulkActions/LimitedBulkExport';
import { DeleteEmptyEconomicUnit } from './Common/DeleteEmptyEconomicUnit';
import MoveBuildingModal from './MoveBuildingDialog';

type Props = {
  buildingId: number;
  street: string;
  currentAmountBuildingsInEu?: number;
  economicUnitId?: number;
};

export default function BuildingActionMenu({ economicUnitId, buildingId, street, currentAmountBuildingsInEu }: Props) {
  const { t } = useTranslation();
  const { trackEvent } = usePosthogTracking();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { economicUnitsToggled } = useOrgPreferences();
  const [openBuildingExportDialog, setOpenBuildingExportDialog] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [keepEmptyEu, setKeepEmptyEu] = useState(false);
  const [openMoveBuildingModal, setOpenMoveBuildingModal] = useState(false);
  const { tooltips: deleteTooltip } = useTooltipConstants();
  const { checkBuildingPermission } = usePermissions();

  const { associatedDataForDeletion } = useGetAssociatedDataForBuilding({
    buildingIds: [buildingId],
    modalOpen: deleteModalOpen,
  });

  const hasDeleteAccess = useMemo(() => {
    return checkBuildingPermission(buildingId, PermissionType.WRITE);
  }, [buildingId, checkBuildingPermission]);

  const showErrorSnackbar = () => {
    enqueueSnackbar(t('General_DeleteBuilding-error_one'), {
      variant: 'error',
      autoHideDuration: SnackbarTimeouts.Error,
    });
  };

  const removeEmptyEconomicUnit = async (
    economicUnitsToggled: boolean,
    economicUnitId: number,
    count: number | undefined,
  ) => {
    if (!economicUnitsToggled) {
      await deleteEconomicUnit({
        variables: {
          id: economicUnitId,
        },
      });
      return;
    }
    // Check if count is defined, and equals 0. If is, and the user don't want to keep empty economic units, delete it.
    const shouldDeleteEconomicUnit = count !== undefined && count === 0 && !keepEmptyEu;
    if (!shouldDeleteEconomicUnit) {
      return;
    }
    await deleteEconomicUnit({
      variables: {
        id: economicUnitId,
      },
    });
  };

  const [deleteEconomicUnit] = useMutation(DELETE_ECONOMIC_UNIT, {
    onCompleted: (data) => {
      const deletedBuildingIds = data.delete_economic_unit_by_pk?.buildings.map((b) => b.id) ?? [];
      if (deletedBuildingIds.length > 0) {
        trackEvent('BUILDING_DELETED', { building_ids: deletedBuildingIds });
      }
    },
  });

  const [deleteBuilding] = useMutation(DELETE_BUILDING, {
    refetchQueries: [
      { query: GET_ECONOMIC_UNITS_WITH_BUILDINGS },
      {
        query: GET_ECONOMIC_UNITS_WITH_PORTFOLIOS,
      },
    ],
    update: (cache, { data }) => {
      //@ts-ignore
      if (data.delete_building_by_pk) {
        const normalizedId = cache.identify({
          //@ts-ignore
          id: data.delete_building_by_pk.id,

          //@ts-ignore
          __typename: data.delete_building_by_pk.__typename,
        });
        cache.evict({ id: normalizedId });
        cache.gc();
      }
    },
    onError: () => showErrorSnackbar(),
    onCompleted: async (data) => {
      if (!data.delete_building_by_pk) {
        showErrorSnackbar();
        return;
      }
      await removeEmptyEconomicUnit(
        economicUnitsToggled,
        data.delete_building_by_pk.economic_unit.id,
        data.delete_building_by_pk.economic_unit.associatedBuildingsAndDrafts.amount,
      );
      enqueueSnackbar(t('General_DeleteBuilding-success'), {
        variant: 'success',
        autoHideDuration: SnackbarTimeouts.Success,
      });
      trackEvent('BUILDING_DELETED', { building_id: buildingId });
      setDeleteModalOpen(false);
      navigate(PATHS.dataCollection.buildings());
    },
  });

  const handleClose = () => {
    setOpenBuildingExportDialog(false);
  };

  return (
    <>
      <TableMoreMenu
        actions={
          <>
            {economicUnitsToggled && hasDeleteAccess && (
              <MenuItem onClick={() => setOpenMoveBuildingModal(true)}>
                <Box component={'img'} src={IMAGES.MoveToIcon} mr={2} />
                {t('General_MoveTo')}
              </MenuItem>
            )}
            <MenuItem onClick={() => setOpenBuildingExportDialog(true)}>
              <Box component={'img'} src="/icons/data_export.svg" mr={2} />
              {t('General_DataExport')}
            </MenuItem>
            {hasDeleteAccess && (
              <MenuItem onClick={() => setDeleteModalOpen(true)} sx={{ color: 'error.main' }}>
                <Iconify icon={ICONS.TRASH} />
                {t('General_Delete')}
              </MenuItem>
            )}
          </>
        }
      />

      <PreDialog type="definedByChildren" open={openBuildingExportDialog} onClose={handleClose}>
        <LimitedBulkExport buildingIds={[buildingId]} onClose={handleClose} />
      </PreDialog>

      <PreDialog
        type="definedByChildren"
        open={openMoveBuildingModal}
        onClose={() => setOpenMoveBuildingModal(false)}
        maxWidth="md"
        fullWidth
      >
        <MoveBuildingModal
          economicUnitId={economicUnitId!}
          currentAmountBuildingsInEu={currentAmountBuildingsInEu}
          buildingId={buildingId}
          onMoveCompleted={() => {
            setOpenMoveBuildingModal(false);
          }}
          onCancel={() => setOpenMoveBuildingModal(false)}
        />
      </PreDialog>

      <DeleteConfirmationModal
        open={deleteModalOpen}
        title={t('General_DeleteModalTitle-Building', { count: 1 })}
        description={
          <Trans
            // This is a hacky way to get _one and _other to work in the translation file for <Trans /> component
            // t('General_DeleteModalDescription-Building', { count })
            i18nKey={'General_DeleteModalDescription-Building'}
            values={{
              name: street,
              count: 1,
              alongWithAssociatedData:
                associatedDataForDeletion.size > 0 ? t('General_DeleteWarningAssociatedDataText', { count: 1 }) : '',
            }}
            components={{ bold: <strong /> }}
          />
        }
        onClose={() => {
          setDeleteModalOpen(false);
        }}
        onDelete={() => {
          deleteBuilding({
            variables: {
              id: buildingId,
            },
          });
        }}
        associatedData={associatedDataForDeletion}
        deleteConfirmationText={t('General_DeleteModalConfirmation-Building', { count: 1 })}
        dataLossWarningText={associatedDataForDeletion.size > 0 ? t('General_DeleteModalWarning-Building') : undefined}
        tooltips={deleteTooltip}
      >
        <DeleteEmptyEconomicUnit
          setKeepEmptyEu={setKeepEmptyEu}
          economicUnitsToggled={economicUnitsToggled}
          currentAmountBuildingsInEu={currentAmountBuildingsInEu}
        />
      </DeleteConfirmationModal>
    </>
  );
}
