import { Card, Stack, Typography } from '@mui/material';
import { AnalyzeScenarioOutputFragment } from '@predium/client-graphql';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import usePosthogTracking from '../../../../hooks/usePosthogTracking';
import EnergeticImpactLineChart from '../Visualization/EnergeticImpactLineChart';
import YearlyInvestmentChart from '../Visualization/YearlyInvestmentChart';
import ScenarioImpactGraphSelect, { ScenarioGraphType } from './ScenarioImpactGraphs/ScenarioImpactGraphSelect';
import { useEnergyOrEmissionsData, useYearlyInvestmentData } from './ScenarioImpactGraphs/hooks';

type ScenarioImpactGraphsProps = {
  analyzedScenario: AnalyzeScenarioOutputFragment;
  totalActions: number;
};

const ScenarioImpactGraphs: React.FC<ScenarioImpactGraphsProps> = ({ analyzedScenario, totalActions }) => {
  const { t } = useTranslation();
  const { trackEvent } = usePosthogTracking();

  const [graphType, setGraphType] = useState<ScenarioGraphType>('co2_emissions');

  const yearlyInvestmentData = useYearlyInvestmentData(analyzedScenario);
  const energeticImpactData = useEnergyOrEmissionsData(analyzedScenario, graphType, totalActions);
  const { series, unit, yAxisLabel, legendData } = energeticImpactData;

  return (
    <Card sx={{ p: 3 }}>
      <Stack direction="row" alignItems="center" sx={{ mb: 3 }}>
        <Typography variant="h6">{t('General_DevelopmentOf')}</Typography>
        <ScenarioImpactGraphSelect
          graphType={graphType}
          onSelect={(type) => {
            setGraphType(type);
            trackEvent('SCENARIO_IMPACT_ANALYSIS_GRAPH_CHANGED', {
              selectedGraph: type,
            });
          }}
        />
      </Stack>
      <YearlyInvestmentChart
        isHidden={graphType !== 'investment'}
        categories={yearlyInvestmentData.categories}
        series={yearlyInvestmentData.series}
        isDataAvailable={analyzedScenario.cost_paths.length > 0}
      />
      <EnergeticImpactLineChart
        ariaLabel={`${graphType}-lineChart`}
        graphType={graphType}
        series={series}
        unit={unit}
        yAxisLabel={yAxisLabel}
        legendData={legendData}
        totalActions={totalActions}
        displayToolbar
        isHidden={graphType === 'investment'}
      />
    </Card>
  );
};

export default ScenarioImpactGraphs;
