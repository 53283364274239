/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { Alert, Button, List, ListItem, Stack, Typography } from '@mui/material';
import { EditAreasMutation } from '@predium/client-graphql';
import { area_type_enum } from '@predium/enums';
import { ensureDefined } from '@predium/utils';
import { useSnackbar } from 'notistack';
import { PropsWithChildren, useCallback, useLayoutEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { DelayedLoading } from '../../../../components/Loading';
import { SnackbarTimeouts } from '../../../../components/NotistackProvider';
import PreDialog, { PreDialogBody } from '../../../../components/presentations/PreDialog/PreDialog';
import { EDIT_AREAS } from '../../../../graphql/DataCollection.mutations';
import {
  COUNT_BUILDING_CONSUMPTIONS_BY_AREA_TYPE,
  GET_BUILDING,
  GET_BUILDING_CONSUMPTION_PRECONDITION,
} from '../../../../graphql/DataCollection.queries';
import usePosthogTracking from '../../../../hooks/usePosthogTracking';
import useSessionData from '../../../../hooks/useSessionData';
import { ConflictDialog } from '../Common/ConflictDialog';
import useBuilding from '../Context/useBuilding';
import { AreaFormValues, AreaMutation, IConsumptionAggregateResponse } from './types';
import { getAreaMutationsFromForm } from './utils';
import { useValidation } from './Validation/ValidationProvider';

const PreList = ({ children }: PropsWithChildren) => (
  <List sx={{ listStyleType: 'disc', pt: 0, pl: 2, '& .MuiListItem-root': { display: 'list-item', pl: 0, pb: 0 } }}>
    {children}
  </List>
);

type SaveChangesModalContentProps = {
  onClose: VoidFunction;
  handleSave: VoidFunction;
};

const SaveChangesModalContent = ({ onClose, handleSave }: SaveChangesModalContentProps) => {
  const { getValues, formState } = useFormContext<AreaFormValues>();
  const { building } = useBuilding();
  const { t } = useTranslation();
  const [hasLinkedConsumptions, setHasLinkedConsumptions] = useState(false);

  const isAreaChanged = useCallback(
    (areaType: area_type_enum) => {
      const areas = getValues('areas');
      const dirtyAreas = ensureDefined(formState.dirtyFields.areas);
      const areasIndexes = areas.map((area, index) => (area.area_type_id === areaType ? index : null));
      return dirtyAreas.some((fields, index) => Object.keys(fields).length > 0 && areasIndexes.includes(index));
    },
    [getValues, formState.dirtyFields.areas],
  );

  const isAFSetToZero = useCallback(() => {
    const areas = getValues('areas');
    return areas.some(
      (area) => area.area_type_id === area_type_enum.AF && area.value === 0 && isAreaChanged(area_type_enum.AF),
    );
  }, [getValues, isAreaChanged]);

  useQuery<IConsumptionAggregateResponse>(COUNT_BUILDING_CONSUMPTIONS_BY_AREA_TYPE, {
    variables: {
      buildingId: building.id,
      areaTypeId: area_type_enum.AF,
    },
    skip: !(isAreaChanged(area_type_enum.AF) && isAFSetToZero()),
    fetchPolicy: 'network-only', // To ensure that the query is always executed (no cache)
    onCompleted: (data) => {
      setHasLinkedConsumptions(data.consumption_aggregate.aggregate.count > 0);
    },
  });

  const isEBFChanged = isAreaChanged(area_type_enum.EBF);
  const isAnyTenantAreaChanged = [area_type_enum.MF, area_type_enum.AF].some((areaType) => isAreaChanged(areaType));
  const hasActionPlans = (building.action_plans_aggregate.aggregate?.count ?? 0) > 0;
  const isEBFAndTenantAreasChanged = isEBFChanged && isAnyTenantAreaChanged;
  const isNoneChanged = !isEBFChanged && !isAnyTenantAreaChanged;

  return (
    <PreDialogBody
      dialogtitle={t('General_SaveChanges')}
      content={
        <Stack spacing={2}>
          {isNoneChanged && <Typography>{t('DataCollectionAreas_AreaChangedTextSaveModal')}</Typography>}
          {!isNoneChanged && (
            <>
              {isEBFChanged && hasActionPlans && (
                <Alert severity="error">{t('DataCollectionAreas_EBFChangeAlert')}</Alert>
              )}
              {isEBFChanged && (
                <Stack>
                  {isEBFAndTenantAreasChanged && (
                    <Typography fontWeight={700}>{t('DataCollectionAreas_EnergyReferenceArea')}</Typography>
                  )}
                  <PreList>
                    <Trans
                      i18nKey="DataCollectionAreas_EBFChangeContent"
                      components={{ sup: <sup />, li: <ListItem /> }}
                    />
                  </PreList>
                </Stack>
              )}
              {isAnyTenantAreaChanged && (
                <Stack>
                  {isEBFAndTenantAreasChanged && (
                    <Typography fontWeight={700}>
                      {t('DataCollectionAreas_TenantAreaTitle')} {t('General_And')}{' '}
                      {t('DataCollectionAreas_CommonArea')}
                    </Typography>
                  )}
                  <PreList>
                    <Trans
                      i18nKey="DataCollectionAreas_TenantAreasChangeContent"
                      components={{ sup: <sup />, li: <ListItem /> }}
                    />
                    {hasLinkedConsumptions && isAFSetToZero() && (
                      <ListItem>{t('DataCollectionAreas_AFSetToZeroConsumptionsDraftedWarning')}</ListItem>
                    )}
                  </PreList>
                </Stack>
              )}
            </>
          )}
        </Stack>
      }
      actions={
        <>
          <Button variant="outlined" onClick={onClose}>
            {t('General_KeepEditing')}
          </Button>
          <Button variant="contained" onClick={handleSave}>
            {t('General_Save')}
          </Button>
        </>
      }
    />
  );
};

type Props = {
  isSaveModalOpen: boolean;
  setIsSaveModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const SaveChangesModal = ({ isSaveModalOpen, setIsSaveModalOpen }: Props) => {
  const { trackEvent } = usePosthogTracking();
  const { t } = useTranslation();
  const client = useApolloClient();
  const { org } = useSessionData();
  const { enqueueSnackbar } = useSnackbar();
  const {
    formState: { dirtyFields },
    handleSubmit,
  } = useFormContext<AreaFormValues>();
  const { building } = useBuilding();
  const [openConflictDialog, setOpenConflictDialog] = useState(false);
  const { triggerValidation } = useValidation();
  const [updatedAreas, setUpdatedAreas] = useState<AreaMutation[]>([]);

  const handleClose = () => setIsSaveModalOpen(false);

  const handleError = () => {
    enqueueSnackbar(t('General_BuildingUpdated-error'), {
      variant: 'error',
      autoHideDuration: SnackbarTimeouts.Error,
    });
  };

  const handleOnCompleted = ({ success, scalingResult }: EditAreasMutation['editAreas']) => {
    if (!success) {
      if (scalingResult && !scalingResult.scaling_successful) {
        setOpenConflictDialog(true);
      } else {
        enqueueSnackbar(t('General_BuildingUpdated-error'), {
          variant: 'error',
          autoHideDuration: SnackbarTimeouts.Error,
        });
      }
    } else {
      enqueueSnackbar(t('General_BuildingUpdated-success'), {
        variant: 'success',
        autoHideDuration: SnackbarTimeouts.Success,
      });

      client.refetchQueries({
        include: [GET_BUILDING],
      });

      const getActionType = (area: AreaMutation) => {
        if (area.create) return 'CREATE';
        if (area.delete) return 'DELETE';
        return 'UPDATE';
      };

      trackEvent('BUILDING_AREA_UPDATED', {
        org_id: org?.id || '',
        building_id: building.id,
        areas: updatedAreas.map((item) => ({
          area_id: item.id,
          area_type: item.area_type_id,
          action: getActionType(item),
        })),
      });
    }
  };

  const [editAreas, { loading, data: editAreasData }] = useMutation(EDIT_AREAS, {
    onError: handleError,
    onCompleted: ({ editAreas }) => handleOnCompleted(editAreas),
    refetchQueries: [
      {
        query: GET_BUILDING_CONSUMPTION_PRECONDITION,
        variables: {
          orgId: org?.id,
        },
      },
    ],
  });

  const { final_energy, final_energy_scaled } = editAreasData?.editAreas?.scalingResult ?? {};

  const createHandleSave = (saveInvalidState = false) =>
    handleSubmit((data) => {
      handleClose();
      setUpdatedAreas(getAreaMutationsFromForm(data.areas, dirtyFields.areas));
      editAreas({
        variables: {
          buildingId: building.id,
          areas: getAreaMutationsFromForm(data.areas, dirtyFields.areas),
          saveInvalidState,
        },
      });
    });

  const handleSave = createHandleSave();
  const handleSaveInvalidState = createHandleSave(true);

  useLayoutEffect(() => {
    if (isSaveModalOpen) {
      const isValid = triggerValidation();
      if (!isValid) {
        setIsSaveModalOpen(false);
      }
    }
  }, [isSaveModalOpen, setIsSaveModalOpen, triggerValidation]);

  return (
    <>
      <PreDialog fullWidth open={isSaveModalOpen} onClose={handleClose} type="definedByChildren">
        {isSaveModalOpen && <SaveChangesModalContent handleSave={handleSave} onClose={handleClose} />}
      </PreDialog>
      <ConflictDialog
        open={openConflictDialog}
        onClose={() => setOpenConflictDialog(false)}
        onClick={() => {
          setOpenConflictDialog(false);
          handleSaveInvalidState();
        }}
        finalEnergy={final_energy ?? 0}
        finalEnergyScaled={final_energy_scaled ?? 0}
      />
      {loading && <DelayedLoading text={t('DataCollection_EnvelopeEditForm_LoaderText')} delay={200} fullScreen />}
    </>
  );
};

export default SaveChangesModal;
