import { Card, CardProps } from '@mui/material';
import { Co2CostDistributionOutput } from '@predium/client-graphql';
import { pathValuesFromYear } from '@predium/client-lookup';
import { translateUnitEnum_dynamic } from '@predium/i18n/client';
import { getFilename, Localize, Units } from '@predium/utils';
import { TFunction } from 'i18next';
import isEqual from 'lodash/isEqual';
import merge from 'lodash/merge';
import { memo } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../../../provider/LanguageProvider';
import BaseOptionChart from '../../../theme/apexcharts';
import addCustomCSVExportButtonToChartToolbar from '../../../utils/addCustomCSVExportButtonToChartToolbar';
import { toCsvContent } from '../../../utils/toCsvContent';
import { disableCursorPointerForMarkers } from '../PathGraph';

const getCsvContent = (
  series: {
    landlordCosts: [number, number][];
    tenantCosts: [number, number][];
  },
  title: string,
  t: TFunction<'translation', undefined>,
  localize: Localize,
) => {
  const csvRows = [
    [title, '', ''],
    [t('General_Year'), t('General_OwnersShare'), t('General_TenantsShare')],
    ...series.landlordCosts.map((landlordCost, index) => [
      landlordCost[0],
      localize.formatAsCurrency(landlordCost[1]),
      localize.formatAsCurrency(series.tenantCosts[index][1]),
    ]),
  ];

  return toCsvContent(csvRows);
};

interface Props extends CardProps {
  co2CostDistributionPath: Co2CostDistributionOutput;
  showTotal: boolean;
  animated?: boolean;
}

function CO2CostDevelopmentGraph({ co2CostDistributionPath, showTotal, animated, sx }: Props) {
  const { t } = useTranslation();
  const { language, localize } = useLanguage();
  const currentYear = new Date().getFullYear();
  const tenantCostsFromCurrentYear = pathValuesFromYear(
    showTotal ? co2CostDistributionPath.tenantPath.total : co2CostDistributionPath.tenantPath.m2,
    currentYear,
  );
  const landlordCostsFromCurrentYear = pathValuesFromYear(
    showTotal ? co2CostDistributionPath.landlordPath.total : co2CostDistributionPath.landlordPath.m2,
    currentYear,
  );

  const tenantCosts: [number, number][] = tenantCostsFromCurrentYear.map((costs, idx) => [currentYear + idx, costs]);
  const landlordCosts: [number, number][] = landlordCostsFromCurrentYear.map((costs, idx) => [
    currentYear + idx,
    costs,
  ]);

  const series = [
    {
      name: t('General_OwnersShare'),
      data: landlordCosts,
      color: '#22609B',
    },
    {
      name: t('General_TenantsShare'),
      data: tenantCosts,
      color: '#A7C0D7',
    },
  ];

  const yAxisTitle = `${t('General_CO2Taxes')} ${
    showTotal
      ? `(${translateUnitEnum_dynamic(Units.pricePerYear, t)})`
      : `(${translateUnitEnum_dynamic(Units.pricePerAreaYear, t)})`
  }`;
  const filename = getFilename([t('CO2CostDevelopmentGraph_Title')], language);

  const chartState: ApexCharts.ApexOptions = merge(BaseOptionChart(), {
    chart: {
      animations: {
        enabled: !!animated,
      },
      type: 'area',
      stacked: true,
      events: {
        mounted(chart: any) {
          disableCursorPointerForMarkers(chart.el.id);
          addCustomCSVExportButtonToChartToolbar(
            chart.el.id,
            getCsvContent({ landlordCosts, tenantCosts }, yAxisTitle, t, localize),
            filename,
          );
        },
        updated(chart: any) {
          disableCursorPointerForMarkers(chart.el.id);
          addCustomCSVExportButtonToChartToolbar(
            chart.el.id,
            getCsvContent({ landlordCosts, tenantCosts }, yAxisTitle, t, localize),
            filename,
          );
        },
      },
      toolbar: {
        export: {
          svg: {
            filename,
          },
          png: {
            filename,
          },
        },
      },
    },
    title: {
      text: t('CO2CostDevelopmentGraph_Title'),
    },
    legend: {
      onItemClick: {
        toggleDataSeries: false,
      },
    },
    yaxis: {
      labels: {
        formatter: (value: number) => localize.formatAsCompact(value === Infinity ? 0 : value),
      },
      title: {
        text: yAxisTitle,
      },
    },
    xaxis: {
      tooltip: {
        enabled: false,
      },
      labels: {
        formatter: (value: number, timestamp: number) => (timestamp % 5 === 0 ? value : ''),
      },
    },
    stroke: {
      width: 3,
    },
    markers: {
      // Weird issue which is described and "resolved" here:
      // https://github.com/apexcharts/apexcharts.js/issues/1427#issuecomment-603893948
      size: 0.001,
    },
    tooltip: {
      y: {
        formatter: (value: number) =>
          localize.formatAsCurrency(value === Infinity ? 0 : value, { displayCompact: true }),
      },
      inverseOrder: true,
    },
  });

  return (
    <Card sx={{ p: 3, pb: 1, ...sx }}>
      <ReactApexChart
        id="co2_cost_dev_graph"
        type={chartState.chart?.type}
        options={chartState}
        series={series}
        height={350}
      />
    </Card>
  );
}

export default memo(CO2CostDevelopmentGraph, isEqual);
