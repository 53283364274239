import { Box, Stack } from '@mui/material';
import { data_source_type_enum } from '@predium/enums';
import { UnitsValue } from '@predium/utils';
import { MouseEventHandler, ReactNode } from 'react';
import ActionMoreMenu from '../common/ActionMoreMenu';
import { RHFNumberFieldProps } from '../hook-form/RHFNumberField';
import Input, { InputProps } from './Input';
import Value, { ValueProps } from './Value';

type Props = {
  unit?: UnitsValue;
  source?: data_source_type_enum;
  actions?: ReactNode;
  numberFieldProps?: Partial<RHFNumberFieldProps>;
  onValueChange?: (value: number) => void;
  onClickAway?: InputProps['onClickAway'];
  showTooltip?: boolean;
  isLoading?: boolean;
  ignoreIsDirty?: boolean;
  onSourceClick?: MouseEventHandler<HTMLDivElement>;
  onSourceClickAway?: () => void;
  disabled?: boolean;
};

type PropsValue = Props & ValueProps;
type PropsName = Props & InputProps;

export type CustomDataSourceInputProps = PropsName | PropsValue;

const CustomDataSourceInput = ({ actions, ...props }: CustomDataSourceInputProps) => {
  const isNameBased = 'name' in props;
  const isValueBased = 'value' in props;

  if (isNameBased && isValueBased) {
    throw new Error('CustomDataSourceInput cannot be both name and value based');
  }

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      {isNameBased && <Input {...props} name={props.name} />}
      {isValueBased && <Value {...props} value={props.value} />}
      {actions && (
        <Box>
          <ActionMoreMenu
            variant="icon"
            actions={actions}
            sx={{
              mr: -1.5,
              svg: {
                width: 16,
                height: 16,
              },
            }}
          />
        </Box>
      )}
    </Stack>
  );
};

export default CustomDataSourceInput;
