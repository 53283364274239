import { usePostHog } from 'posthog-js/react';
import { useCallback } from 'react';
import { POSTHOG_PAGES_TO_TRACK, PosthogEvent } from '../utils/posthogEvents';

const usePosthogTracking = () => {
  const posthog = usePostHog();

  /**
   * track an event with posthog
   * @param event the event name, following our event naming convention
   * @param properties optional properties to be added to the event
   */
  const trackEvent = useCallback(
    (
      event: PosthogEvent,
      properties?: Record<
        string,
        string | number | boolean | Array<number | string | boolean | { [key: string]: unknown }> | null
      >,
    ) => {
      posthog?.capture(event, properties);
    },
    [posthog],
  );

  const trackPageView = () => {
    const currentPath = window.location.pathname;

    if (!POSTHOG_PAGES_TO_TRACK.includes(currentPath)) {
      return;
    }

    posthog?.capture('page_view', {
      page_url: currentPath,
    });
  };

  return {
    trackEvent,
    trackPageView,
  };
};

export default usePosthogTracking;
