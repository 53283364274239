import { useCallback } from 'react';
import { BuildingCreationFormCast, useBuildingCreation } from '../BuildingCreationProvider';
import { ACTION_TYPES } from '../hooks/useGlobalState';
import AddressSearch from './Substeps/AddressSearch';
import BuildingSelection from './Substeps/BuildingSelection';
import ManualAddressForm from './Substeps/ManualAddress';

const Step1Location = () => {
  const { getBuilding, activeStepRef, dispatch, isMainFormDirty, currentFormSubstep = 1 } = useBuildingCreation();
  const building = getBuilding();

  const handleValidChange = useCallback(
    (isValid: boolean, message?: string) => {
      dispatch({ type: ACTION_TYPES.SET_STEP_INVALIDITY, payload: !isValid, message });
    },
    [dispatch],
  );

  const handleBlockClose = useCallback(
    (dirty: boolean) => {
      dispatch({ type: ACTION_TYPES.SET_BLOCK_CLOSE, payload: dirty });
    },
    [dispatch],
  );

  // Custom logic for handling substeps navigation. Without it, it defaults to the normal order. ie: 1->2->3
  const handleNextSubstepSubmit = (data: Partial<BuildingCreationFormCast>) => {
    if (currentFormSubstep === 0) {
      // If address exists with lat & long, we want to go the building selection
      if (
        data?.addressSearch?.addressSearch?.lat &&
        data?.addressSearch?.addressSearch?.long &&
        data?.addressSearch?.addressSearch?.postalCode
      )
        dispatch({ type: ACTION_TYPES.SET_ACTIVE_SUBSTEP, payload: 2 });
      // Otherwise, go to manual data input
      else dispatch({ type: ACTION_TYPES.SET_ACTIVE_SUBSTEP, payload: 1 });
    }

    if (currentFormSubstep === 1) {
      // If at the manual data input, we want to skip building selection and go to the next step of the form.
      if (!building.addressSearch.addressSearch.lat || !building.addressSearch.addressSearch.long)
        dispatch({ type: ACTION_TYPES.NEXT_STEP });
      // If lat and long are present, we want to go to building selection
      else dispatch({ type: ACTION_TYPES.SET_ACTIVE_SUBSTEP, payload: 2 });
    }
  };

  // Custom logic for handling back navigation within the substeps. Without it, it defaults to going to the previous steps directly. eg: 3->2->1
  const handlePreviousSubstepSubmit = () => {
    if (currentFormSubstep === 2) {
      if (building.addressSearch.addressSearch.lat && building.addressSearch.addressSearch.long)
        dispatch({ type: ACTION_TYPES.SET_ACTIVE_SUBSTEP, payload: 0 });
      else dispatch({ type: ACTION_TYPES.SET_ACTIVE_SUBSTEP, payload: 1 });

      return;
    }

    dispatch({ type: ACTION_TYPES.SET_ACTIVE_SUBSTEP, payload: 0 });
  };

  const content: { [key: number]: JSX.Element } = {
    0: (
      <AddressSearch
        ref={activeStepRef}
        defaultValues={building.addressSearch}
        onValidChange={handleValidChange}
        onSubmitSuccess={handleNextSubstepSubmit}
      />
    ),
    1: (
      <ManualAddressForm
        defaultValues={building.address}
        ref={activeStepRef}
        isParentDirty={isMainFormDirty}
        onValidChange={handleValidChange}
        onDirtyChange={handleBlockClose}
        onSubmitSuccess={handleNextSubstepSubmit}
        onPreviousSubstep={handlePreviousSubstepSubmit}
      />
    ),
    2: (
      <BuildingSelection
        defaultValues={building.buildingSelection}
        coords={{ lat: building.addressSearch.addressSearch.lat, lng: building.addressSearch.addressSearch.long }}
        ref={activeStepRef}
        onValidChange={handleValidChange}
        onPreviousSubstep={handlePreviousSubstepSubmit}
      />
    ),
  };

  return content[currentFormSubstep];
};

export default Step1Location;
