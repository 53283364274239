import { country_enum } from '@predium/enums';
import { TFunction } from 'i18next';
import * as yup from 'yup';
import { getPostalCodeValidation } from '../../../../../utils/formUtils';

export const getManualAddressFormSchema = (t: TFunction) => {
  const REQUIRED_MESSAGE = t('General_Required');

  const stringSchema = yup.string();

  return yup.object({
    countryId: yup
      .mixed<country_enum>()
      .oneOf(Object.values(country_enum), REQUIRED_MESSAGE)
      .required(REQUIRED_MESSAGE),
    street: stringSchema.required(REQUIRED_MESSAGE),
    postalCode: stringSchema.when('countryId', getPostalCodeValidation(t)),
    city: stringSchema.required(REQUIRED_MESSAGE),
  });
};

export const getAddressSearchFormSchema = (t: TFunction) => {
  const REQUIRED_MESSAGE = t('General_Required');

  return yup.object({
    addressSearch: yup
      .object()
      .typeError(REQUIRED_MESSAGE)
      .shape({
        label: yup.string().required(REQUIRED_MESSAGE),
        inputValue: yup.string().optional(),
        lat: yup.number().optional(),
        long: yup.number().optional(),
        city: yup.string().optional(),
        postalCode: yup.string().optional(),
        address: yup.string().optional(),
        country: yup.string().optional(),
      })
      .required(REQUIRED_MESSAGE),
  });
};

export const getBuildingSelectionFormSchema = (requireBuildingSelection: boolean) => {
  return yup.object({
    building_ids: yup.array().when([], {
      is: () => requireBuildingSelection === true,
      then: yup.array().required().min(1),
      otherwise: yup.array().optional(),
    }),
  });
};

export type ManualAddressFormType = yup.InferType<ReturnType<typeof getManualAddressFormSchema>>;
export type AddressSearchFormType = yup.InferType<ReturnType<typeof getAddressSearchFormSchema>>;
export type BuildingSelectionFormType = yup.InferType<ReturnType<typeof getBuildingSelectionFormSchema>>;
