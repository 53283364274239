import { Alert, AlertTitle, Grid, Stack, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { ICONS } from '../../../../assets/icons';
import DetailedTooltip from '../../../../components/DetailedTooltip/DetailedTooltip';

const ClimateRiskV2UpgradeAlert = () => {
  const { t } = useTranslation();

  return (
    <Grid item xs={12}>
      <Alert
        severity="info"
        sx={{
          color: 'text.primary',
          bgcolor: 'grey.200',
        }}
      >
        <AlertTitle>{t('DataCollection_BuildingClimateRisk_AlertTitle')}</AlertTitle>
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <Typography variant="body2">{t('DataCollection_BuildingClimateRisk_AlertDescription')}</Typography>
          <DetailedTooltip
            labels={{
              title: t('ClimateRiskV2_Header_TooltipTitle'),
              preBody: (
                <Trans
                  i18nKey="ClimateRiskV2_Header_TooltipBody"
                  components={{
                    br: <br />,
                  }}
                />
              ),
            }}
            size={16}
            slotsProps={{
              icon: {
                icon: ICONS.INFO_CONTAINED,
                color: 'text.primary',
              },
              tooltip: {
                sx: {
                  '& .MuiTooltip-tooltip': {
                    maxWidth: '420px',
                  },
                },
              },
            }}
          />
        </Stack>
      </Alert>
    </Grid>
  );
};

export default ClimateRiskV2UpgradeAlert;
