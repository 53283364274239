/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useQuery, useSubscription } from '@apollo/client';
import { Box, Card, CircularProgress, Stack, TableCell, Tooltip } from '@mui/material';
import { translateTypeOfUseEnum_dynamic } from '@predium/i18n/client';
import { getEbfTypesOfUse } from '@predium/utils';
import isNil from 'lodash/isNil';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DelayedLoading } from '../../../components/Loading';
import {
  DataTable,
  DataTableColumns,
  preventRedirect,
  QueryResultValue,
  QueryVariables,
} from '../../../components/data-table';
import { useDebounce } from '../../../components/data-table/debounce';
import { hasRecentlyChanged } from '../../../components/table/TableRowWithHighlight';
import { DataCollectionBuildingTabState } from '../../../contexts/DataCollectionFiltersContext';
import { BUILDING_PROCESSING_SUBSCRIPTION, GET_ALL_BUILDINGS } from '../../../graphql/DataCollection.queries';
import { GET_PORTFOLIOS } from '../../../graphql/Portfolio.queries';
import useDataCollectionFilters from '../../../hooks/useDataCollectionFilters';
import { useMultiSelectFilter } from '../../../hooks/useMultiSelectFilter';
import { OnFilterCallbackParams } from '../../../hooks/useTable';
import { PATHS } from '../../../routes/paths';
import { ALL_PORTFOLIOS } from '../../EsgAnalysis/NavigationSelectors/PortfolioSelector';
import BuildingActionMenu from '../Building/BuildingActionMenu';
import AllBulkActionsMenu from './BulkActions/AllBulkActionsMenu';
import { BulkOperationsFilters } from './BulkActions/AllBulkMove';
import LimitedBulkActionsMenu from './BulkActions/LimitedBulkActionsMenu';
import DataCollectionToolbar from './DataCollectionToolbar';
import { DataCollectionTabEnum } from './TabsEnums';

type Props = {
  setCurrentTab: (buildingTabData: DataCollectionBuildingTabState) => void;
  onFilterChange: ({ orderBy, order }: OnFilterCallbackParams) => void;
  tabTableFilter?: {
    orderBy: string;
    order: 'asc' | 'desc';
  };
};

export default function DataCollectionBuildingList({ tabTableFilter, setCurrentTab, onFilterChange }: Props) {
  const { t } = useTranslation();

  const {
    filters: {
      dataCollectionBuildingFilters: { selectedPortfolios, selectedStatus, filterName },
    },
    setSelectedPortfolios,
    setSelectedStatusBuilding,
    setFilterNameBuilding,
  } = useDataCollectionFilters();

  const {
    tempSelection: tempPortfolioSelection,
    handleSelectionChange: handlePortfolioSelectionChange,
    applySelection: applyPortfolioSelection,
    clearSelection: clearPortfolioSelection,
    isApplyDisabled: applyPortfolioDisabled,
    isClearDisabled: clearPortfolioDisabled,
    setTempSelection,
  } = useMultiSelectFilter<string>((update) => {
    setSelectedPortfolios('building', typeof update === 'function' ? update(selectedPortfolios) : update);
  }, ALL_PORTFOLIOS);

  useEffect(() => {
    setTempSelection(selectedPortfolios);
  }, [selectedPortfolios, setTempSelection]);

  const { loading: portfoliosLoading, data: portfoliosData } = useQuery(GET_PORTFOLIOS);

  const selectedPortfolioIds = useMemo(() => {
    if (selectedPortfolios.includes(ALL_PORTFOLIOS)) {
      return undefined;
    }

    return (portfoliosData?.portfolio ?? [])
      .filter((portfolio) => portfolio.name && selectedPortfolios.includes(portfolio.name))
      .map((portfolio) => portfolio.id)
      .filter((id): id is number => id !== undefined); // Ensure valid IDs
  }, [portfoliosData?.portfolio, selectedPortfolios]);

  const portfolioOptions = useMemo(() => {
    const portfoliosName = (portfoliosData?.portfolio ?? [])
      .map((portfolio) => portfolio.name)
      .filter((name): name is string => !isNil(name) && name !== '');

    return [...portfoliosName];
  }, [portfoliosData]);

  const columns: DataTableColumns<typeof GET_ALL_BUILDINGS> = useMemo(
    () => [
      { type: 'select' },
      { id: 'address.street', label: t('General_Building'), minWidth: 300 },
      { id: 'address.city', label: t('General_City') },
      { id: 'address.postal_code', label: t('General_PostalCode') },
      {
        id: 'areas',
        label: t('General_TypeOfUse'),
        sortable: false,
        minWidth: 200,
        getValueLabel(value: QueryResultValue<typeof GET_ALL_BUILDINGS, 'areas'>) {
          const typesOfUse = getEbfTypesOfUse(value);
          return typesOfUse.map((typeOfUse) => translateTypeOfUseEnum_dynamic(typeOfUse, t)).join(', ');
        },
      },
      {
        id: 'action_menu',
        label: '',
        sortable: false,
        renderCell: (data) => (
          <BuildingProcessingCell
            economicUnitId={data.economic_unit.id}
            currentAmountBuildingsInEu={data.economic_unit.associatedBuildingsAndDrafts.amount}
            buildingId={data.id}
            buildingModelId={data.active_building_model?.id}
            street={data.address.street}
          />
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t],
  );

  const debouncedFilterName = useDebounce(filterName, 500);

  const extraVariables: QueryVariables<typeof GET_ALL_BUILDINGS> = useMemo(
    () => ({
      where: {
        ...(debouncedFilterName && {
          _or: [
            { address: { street: { _ilike: `%${debouncedFilterName}%` } } },
            { address: { city: { _ilike: `%${debouncedFilterName}%` } } },
            { address: { postal_code: { _ilike: `%${debouncedFilterName}%` } } },
          ],
        }),
        ...(selectedStatus !== 'all' && { building_state_id: { _eq: selectedStatus } }),
        ...(selectedPortfolioIds && selectedPortfolioIds.length > 0
          ? { economic_unit: { portfolio: { id: { _in: selectedPortfolioIds } } } }
          : {}),
      },
    }),
    [debouncedFilterName, selectedStatus, selectedPortfolioIds],
  );

  const bulkOperationsFilters = useMemo(
    () =>
      ({
        ...(selectedPortfolioIds && selectedPortfolioIds.length > 0 && { portfolioIds: selectedPortfolioIds }),
        ...(selectedStatus !== 'all' && { status: selectedStatus }),
        ...(filterName && { searchString: filterName }),
      } as BulkOperationsFilters),
    [selectedPortfolioIds, selectedStatus, filterName],
  );

  if (!portfoliosData && portfoliosLoading) {
    return <DelayedLoading />;
  }
  return (
    <Card>
      <DataCollectionToolbar
        onFilterName={setFilterNameBuilding}
        filterName={filterName}
        selectedPortfolios={selectedPortfolios}
        selectedStatus={selectedStatus}
        handleStatusChange={setSelectedStatusBuilding}
        setCurrentTab={() => setCurrentTab({ value: DataCollectionTabEnum.ENERGY_CERTIFICATE_DRAFTS })}
        contextType="building"
        portfolioOptions={portfolioOptions}
        handlePortfolioSelectionChange={handlePortfolioSelectionChange}
        applyPortfolioSelection={applyPortfolioSelection}
        clearPortfolioSelection={clearPortfolioSelection}
        applyPortfolioDisabled={applyPortfolioDisabled}
        clearPortfolioDisabled={clearPortfolioDisabled}
        tempPortfolioSelection={tempPortfolioSelection}
      />
      <DataTable
        onFilterChange={onFilterChange}
        columns={columns}
        query={GET_ALL_BUILDINGS}
        extraVariables={extraVariables}
        initials={{
          sortBy: tabTableFilter?.orderBy || 'address.street',
          sortDirection: tabTableFilter?.order || 'asc',
          paginationOptions: [25, 50, 100, 200],
          rowsPerPage: 50,
        }}
        slotProps={{
          tableRow: (row) => ({
            background: hasRecentlyChanged(row.created_at) ? 'success' : 'default',
            to: PATHS.dataCollection.building({ id: row.id }),
          }),
        }}
        labels={{ selectedLabel: (count) => t('General_BuildingSelected', { count }) }}
        actions={({ getSelectedIds, getExcludedIds, onActionCompleted, areAllSelected, selectedCount }) =>
          areAllSelected ? (
            <AllBulkActionsMenu
              count={selectedCount}
              onActionCompleted={onActionCompleted}
              bulkFilters={{
                ...bulkOperationsFilters,
                excludeIds: getExcludedIds(),
              }}
              buildingWhere={extraVariables.where ?? {}}
            />
          ) : (
            <LimitedBulkActionsMenu buildingsId={getSelectedIds()} onActionCompleted={onActionCompleted} />
          )
        }
        searchKeyword={filterName}
      />
    </Card>
  );

  type BuildingProcessingCellProps = {
    economicUnitId: number;
    currentAmountBuildingsInEu: number;
    buildingModelId?: number;
    buildingId: number;
    street: string;
  };

  function BuildingProcessingCell({
    economicUnitId,
    currentAmountBuildingsInEu,
    buildingModelId,
    buildingId,
    street,
  }: BuildingProcessingCellProps) {
    const { t } = useTranslation();
    const { data: subscriptionData } = useSubscription(BUILDING_PROCESSING_SUBSCRIPTION, {
      variables: { buildingModelId: buildingModelId! },
      skip: !buildingModelId,
    });

    const isProcessing = subscriptionData?.building_model_by_pk?.processing === true;

    return (
      <TableCell align="right" onClick={preventRedirect}>
        <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ width: '100%' }}>
          {isProcessing ? (
            <Tooltip title={t('DataCollection_Building_ProcessingMessage')}>
              <Box component="span" sx={{ display: 'flex', alignItems: 'center', mr: 1 }}>
                <CircularProgress
                  size={20}
                  sx={{
                    color: (theme) => theme.palette.grey[500],
                  }}
                />
              </Box>
            </Tooltip>
          ) : (
            <BuildingActionMenu
              economicUnitId={economicUnitId}
              currentAmountBuildingsInEu={currentAmountBuildingsInEu}
              buildingId={buildingId}
              street={street}
            />
          )}
        </Stack>
      </TableCell>
    );
  }
}
