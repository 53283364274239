import { Dispatch, SetStateAction, useState } from 'react';

/**
 * Custom hook to manage a multi-select filter state.
 *
 * @template T - The type of selectable values (string or number).
 * @param {Dispatch<SetStateAction<T[]>>} setSelectedFilter - Function to update the selected filter state.
 * @param {T} allValue - The default "all" value representing the unfiltered state.
 * @returns {Object} - An object containing selection handlers and state.
 */
export const useMultiSelectFilter = <T extends string | number>(
  setSelectedFilter: Dispatch<SetStateAction<T[]>>,
  allValue: T,
) => {
  /**
   * Temporary selection state for the filter.
   * @type {T[]}
   */
  const [tempSelection, setTempSelection] = useState<T[]>([allValue]);

  /**
   * Handles changes to the selection.
   *
   * @param {(string | number)[]} values - The selected values.
   */
  const handleSelectionChange = (values: (string | number)[]) => {
    const sanitizedValues = values.map((value) => value as T);

    if (sanitizedValues.includes(allValue)) {
      setTempSelection([allValue]);
      return;
    }

    let updatedSelection = tempSelection.includes(allValue) ? [] : [...tempSelection];

    sanitizedValues.forEach((value) => {
      if (updatedSelection.includes(value)) {
        updatedSelection = updatedSelection.filter((item) => item !== value);
      } else {
        updatedSelection.push(value);
      }
    });

    if (updatedSelection.length === 0) {
      updatedSelection = [allValue];
      setSelectedFilter([allValue]);
    }

    setTempSelection(updatedSelection);
  };

  /**
   * Applies the current selection to the state.
   */
  const applySelection = () => {
    setSelectedFilter([...tempSelection]);
  };

  /**
   * Clears the selection, resetting to the default "all" value.
   */
  const clearSelection = () => {
    setTempSelection([allValue]);
    setSelectedFilter([allValue]);
  };

  /**
   * Determines if the clear button should be disabled.
   * @type {boolean}
   */
  const isClearDisabled = !!(tempSelection.length === 1 && tempSelection[0] === allValue);

  /**
   * Determines if the apply button should be disabled.
   * @type {boolean}
   */
  const isApplyDisabled = !!(tempSelection.length === 1 && tempSelection[0] === allValue);

  return {
    tempSelection,
    handleSelectionChange,
    applySelection,
    clearSelection,
    isClearDisabled,
    isApplyDisabled,
    setTempSelection,
  };
};
