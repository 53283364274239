/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { createStandardErrorSnackbar } from '../../../../components/NotistackProvider';
import { EXPORT_ALL_BUILDINGS } from '../../../../graphql/DataCollection.mutations';
import { BulkOperationsFilters } from './AllBulkMove';
import BulkExport, { TriggerExportParams } from './Components/BulkExport';

type Props = {
  bulkFilters: BulkOperationsFilters;
  count: number;
  onActionCompleted?: VoidFunction;
  onClose: VoidFunction;
};

export default function AllBulkExport({ bulkFilters, count, onActionCompleted, onClose }: Props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const displayStandardMutationQueryErrorSnackbar = createStandardErrorSnackbar(t);

  const [buildingExcelExportMutation, { loading }] = useMutation(EXPORT_ALL_BUILDINGS, {
    onError: () => displayStandardMutationQueryErrorSnackbar(enqueueSnackbar),
  });

  const triggerExport = useCallback(
    ({ export_template_id, onSuccess, onError }: TriggerExportParams) =>
      buildingExcelExportMutation({
        variables: {
          export_template_id,
          ...bulkFilters,
        },
        onCompleted: (data) => onSuccess(data.exportAllBuildingsAsExcel.body),
        onError,
      }),
    [bulkFilters, buildingExcelExportMutation],
  );

  return (
    <BulkExport
      count={count}
      loading={loading}
      triggerExport={triggerExport}
      onActionCompleted={onActionCompleted}
      onClose={onClose}
    />
  );
}
