import { language_enum } from '@predium/enums';
import { getFilename } from '@predium/utils';

type FileExtension = 'pdf' | 'xlsx' | 'csv';

/**
 * Downloads a file with the provided data and specified file type.
 * @param {string} data - The base64-encoded file data.
 * @param {('pdf' | 'xlsx' | 'csv')} extension - The file extension.
 * @param {string} filenameWithoutFileExtension - The desired filename without the file extension.
 */
export function downloadFile(data: string, extension: FileExtension, filenameWithoutFileExtension: string) {
  let mimeType = '';

  if (extension === 'csv') {
    mimeType = `data:text/csv;charset=utf-8,${encodeURIComponent(data)}`;
  } else if (extension === 'xlsx') {
    mimeType = `data:application/vnd.ms-excel;base64,${encodeURIComponent(data)}`;
  } else {
    mimeType = `data:application/${extension};base64,${encodeURIComponent(data)}`;
  }

  const link = document.createElement('a');
  link.href = mimeType;
  link.download = `${filenameWithoutFileExtension}.${extension}`;

  // Append the link to the document and simulate a click on it to start the download.
  document.body.appendChild(link);
  link.click();

  // Remove the link from the document and revoke the URL to free up resources.
  document.body.removeChild(link);
  URL.revokeObjectURL(mimeType);
}

/**
 * Downloads a file from the specified URL.
 * @async
 * @param {string} url - The URL of the file to be downloaded.
 * @param {string} filename - The name to be given to the downloaded file.
 * @returns {Promise<void>} A Promise that resolves when the download is complete.
 * @throws {Error} If the download fails or encounters an error.
 */
export async function downloadFileUri(url: string, filename: string): Promise<void> {
  const response = await fetch(url);
  const data = await response.blob();
  const link = document.createElement('a');
  link.href = URL.createObjectURL(data);
  link.download = filename;
  link.click();
  URL.revokeObjectURL(link.href);
}

export const onExportCompleted = (
  data: string,
  extension: FileExtension,
  entities: string[],
  language: language_enum,
  hasDate = true,
) => {
  const filename = getFilename(entities, language, hasDate);

  downloadFile(data, extension, filename);
};
