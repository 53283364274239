import { FileFormat, IMG_FORMATS } from '../../../../components/FileThumbnail';
import PdfViewer from '../../../../components/PdfViewer';
import PreDialog from '../../../../components/presentations/PreDialog/PreDialog';

type Props = {
  previewFile: {
    fileFormat: FileFormat;
    url: string;
  } | null;
  onClose: () => void;
};

export const canBePreviewed = (fileFormat?: FileFormat) => {
  if (!fileFormat) return false;
  return fileFormat === FileFormat.PDF || IMG_FORMATS.includes(fileFormat);
};

const PreviewDialog = ({ previewFile, onClose }: Props) => {
  const maxWidth = previewFile?.fileFormat === FileFormat.PDF ? 'sm' : 'md';

  return (
    <PreDialog type="definedByChildren" maxWidth={maxWidth} open={Boolean(previewFile)} onClose={onClose}>
      {previewFile?.fileFormat === FileFormat.PDF && <PdfViewer fileURL={previewFile.url} />}
      {previewFile?.fileFormat && IMG_FORMATS.includes(previewFile.fileFormat) && (
        <img
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'contain',
          }}
          src={previewFile.url}
          alt="Preview"
        />
      )}
    </PreDialog>
  );
};

export default PreviewDialog;
