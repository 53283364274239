/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, Button, Stack, Typography } from '@mui/material';
import { GetUserByIdQuery } from '@predium/client-graphql';
import { role_enum } from '@predium/enums';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { SnackbarTimeouts } from '../../../components/NotistackProvider';
import { FormProvider, RHFRadioButton, RHFTextField } from '../../../components/hook-form';
import PreDialog, { PreDialogBody } from '../../../components/presentations/PreDialog/PreDialog';
import { UPDATE_USER, UPDATE_USER_ROLE } from '../../../graphql/Users.mutations';
import { GET_USER_BY_ID } from '../../../graphql/Users.queries';
import useSessionData from '../../../hooks/useSessionData';

type Props = {
  open: boolean;
  onClose: () => void;
  user: GetUserByIdQuery['user_by_pk'];
};

type FormValuesProps = {
  first_name: string;
  last_name: string;
  email: string;
  isAdmin: boolean;
  isUser: boolean;
};

export default function UserEditDialog({ open, onClose, user }: Props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { user: self } = useSessionData();

  //@ts-ignore
  const currentUserRole = user.user_role[0]?.role;

  //@ts-ignore

  //@ts-ignore
  const isEditingSelf = self.id === user.id;

  const onError = () => {
    enqueueSnackbar(t('UserEditDialog_Snackbar-Error'), {
      variant: 'error',
      autoHideDuration: SnackbarTimeouts.Error,
    });
  };

  const onCompleted = () => {
    enqueueSnackbar(t('UserEditDialog_Snackbar-Success'), {
      variant: 'success',
      autoHideDuration: SnackbarTimeouts.Success,
    });
    onClose();
    reset();
  };

  const [editUserRoleMutation, { loading: editUserRoleMutationLoading }] = useMutation(UPDATE_USER_ROLE, {
    onError,
    onCompleted,

    //@ts-ignore
    refetchQueries: [{ query: GET_USER_BY_ID, variables: { userId: user.id } }],
  });

  const [editUserMutation, { loading: editUserMutationLoading }] = useMutation(UPDATE_USER, {
    onError,
    onCompleted,

    //@ts-ignore
    refetchQueries: [{ query: GET_USER_BY_ID, variables: { userId: user.id } }],
  });

  const getFromValuesFromUser = () => {
    return {
      //@ts-ignore
      first_name: user.first_name,

      //@ts-ignore
      last_name: user.last_name,

      //@ts-ignore
      email: user.email,
      isAdmin: currentUserRole === role_enum.ORG_ADMIN,
      isUser: currentUserRole !== role_enum.ORG_ADMIN,
    };
  };

  const NewUserSchema = Yup.object().shape({
    first_name: Yup.string().required(t('General_FirstNameIsRequired')),
    last_name: Yup.string().required(t('General_LastNameIsRequired')),
    email: Yup.string().required(t('General_EmailIsRequired')).email(t('General_InvalidEmailAddress')),
    isAdmin: Yup.boolean().required(),
    isUser: Yup.boolean().required(),
  });

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(NewUserSchema),

    //@ts-ignore
    defaultValues: getFromValuesFromUser(),
    mode: 'onChange',
  });

  const { handleSubmit, reset } = methods;

  const selectedRole = methods.watch('isAdmin') ? role_enum.ORG_ADMIN : role_enum.USER;
  const changesMade = methods.formState.isDirty;

  const onSubmit = async (data: FormValuesProps) => {
    if (currentUserRole !== selectedRole) {
      editUserRoleMutation({
        variables: {
          //@ts-ignore
          user_id: user.id,
          role: data.isAdmin ? role_enum.ORG_ADMIN : role_enum.USER,
        },
      });
    }
    editUserMutation({
      variables: {
        //@ts-ignore
        id: user.id,
        first_name: data.first_name,
        last_name: data.last_name,
      },
    });
    handleClose();
  };

  const handleClose = () => {
    reset();
    onClose();
  };

  const handleChange = (field: 'isAdmin' | 'isUser') => {
    methods.setValue(field, true);
    methods.setValue(field === 'isAdmin' ? 'isUser' : 'isAdmin', false);
  };

  useEffect(() => {
    methods.setValue('isAdmin', currentUserRole === role_enum.ORG_ADMIN);
    methods.setValue('isUser', currentUserRole !== role_enum.ORG_ADMIN);
  }, [currentUserRole, methods]);

  useEffect(() => {
    //@ts-ignore
    reset(getFromValuesFromUser());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <PreDialog open={open} onClose={handleClose} fullWidth type="definedByChildren">
      <FormProvider
        methods={methods}
        onSubmit={(event) => {
          event.stopPropagation();
          event.preventDefault();
          handleSubmit(onSubmit)();
        }}
      >
        <PreDialogBody
          dialogtitle={t('General_EditUser')}
          content={
            <>
              <Typography sx={{ textAlign: 'justify', mb: 3 }}></Typography>
              <RHFTextField name="first_name" label={t('General_FirstName')} sx={{ mb: 3 }} />
              <RHFTextField name="last_name" label={t('General_LastName')} sx={{ mb: 3 }} />
              <RHFTextField disabled name="email" label={t('General_Email')} sx={{ mb: 3 }} />
              <Stack spacing={2} sx={{ mb: 3 }}>
                <Typography fontWeight={700} fontSize={16}>
                  {t('General_Role')}
                </Typography>
                {isEditingSelf && (
                  <Alert severity="info" color="info">
                    {t('Settings_UserProfile_AdminRoleDisclaimer')}
                  </Alert>
                )}
                <RHFRadioButton
                  name="isUser"
                  disabled={isEditingSelf}
                  onChange={() => handleChange('isUser')}
                  title={t('General_User')}
                  subtitle={t('UserCreateDialog_UserRoleSubtitle')}
                />
                <RHFRadioButton
                  name="isAdmin"
                  disabled={isEditingSelf}
                  onChange={() => handleChange('isAdmin')}
                  title={t('General_Admin')}
                  subtitle={t('UserCreateDialog_AdminRoleSubtitle')}
                />
              </Stack>
            </>
          }
          actions={
            <>
              <Button variant="outlined" onClick={handleClose}>
                {t('General_Cancel')}
              </Button>
              <LoadingButton
                type="submit"
                variant="contained"
                loading={editUserMutationLoading || editUserRoleMutationLoading}
                disabled={!changesMade}
              >
                {t('General_Confirm')}
              </LoadingButton>
            </>
          }
        ></PreDialogBody>
      </FormProvider>
    </PreDialog>
  );
}
