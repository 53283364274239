import { TableCell, TableRow } from '@mui/material';
import { translateSustainaccountClimateRiskHazardEnum_dynamic } from '@predium/i18n/client';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import {
  COLUMN_WIDTH,
  CURRENT_YEAR,
  getRiskPeriod,
  IN_20_YEARS,
  IN_50_YEARS,
  RiskScenario,
  RiskType,
} from '../mapping';
import { RiskWithScenario, ViewMode } from '../types';
import SeverityChip from './SeverityChip';

type RiskRowProps = {
  // Could be empty if the risk is not available for the scenario
  risks: RiskWithScenario[];
  riskType: RiskType;
  selectedScenarios: RiskScenario[];
  viewMode: ViewMode;
  isLast: boolean;
};

const RiskRow = ({ riskType, risks, selectedScenarios, viewMode, isLast }: RiskRowProps) => {
  const { t } = useTranslation();

  return (
    <TableRow
      sx={{
        bgcolor: 'white',
        ...(!isLast && {
          borderBottom: '1px dashed',
          borderColor: 'divider',
        }),
      }}
    >
      <TableCell sx={{ width: 'auto' }}>{translateSustainaccountClimateRiskHazardEnum_dynamic(riskType, t)}</TableCell>
      {selectedScenarios.map((scenario) => {
        const nearTerm = getRiskPeriod(risks, scenario, CURRENT_YEAR);
        const midTerm = getRiskPeriod(risks, scenario, IN_20_YEARS);
        const longTerm = getRiskPeriod(risks, scenario, IN_50_YEARS);

        return (
          <Fragment key={scenario}>
            {[nearTerm, midTerm, longTerm].map((riskPeriod) => (
              <TableCell align="center" sx={{ width: COLUMN_WIDTH, minWidth: COLUMN_WIDTH, maxWidth: COLUMN_WIDTH }}>
                <SeverityChip riskPeriod={riskPeriod} viewMode={viewMode} />
              </TableCell>
            ))}
          </Fragment>
        );
      })}
    </TableRow>
  );
};

export default RiskRow;
