import { createSearchParams } from 'react-router-dom';
import { InferParamType, ParamFunc } from './params';

export type ParamsConfig = Record<string, ParamFunc>;

/**
 * Infer the type of the params config.
 *
 * @example
 * const MyRouteParams = {
 *  id: numberParam,
 *  name: stringParam,
 * }
 *
 * type MyRouteParamsType = InferParamsConfigType<typeof MyRouteParams>;
 *
 * // MyRouteParamsType is now:
 * // {
 * //   id?: number;
 * //   name?: string;
 * // }
 */
export type InferParamsConfigType<TParamsConfig extends ParamsConfig> = {
  [ParamKey in keyof TParamsConfig]?: InferParamType<TParamsConfig[ParamKey]>;
};

/**
 * Convert the params config and value to a URLSearchParams object.
 *
 * @example
 * const MyRouteParams = {
 *  id: numberParam,
 *  name: stringParam,
 * }
 *
 * const value = {
 *  id: 1,
 *  name: 'John',
 * }
 *
 * const searchParams = convertToSearchParams(MyRouteParams, value);
 *
 * // searchParams is now:
 * // id=1&name=John (as URLSearchParams)
 */
export const convertToSearchParams = <TParamsConfig extends ParamsConfig>(
  paramsConfig: TParamsConfig,
  value: InferParamsConfigType<TParamsConfig> = {},
): URLSearchParams => {
  return createSearchParams(
    Object.fromEntries(
      Object.entries(paramsConfig)
        .map(([key, { stringify }]) => {
          const valueString = value[key];

          if (valueString === undefined) {
            return [key, undefined];
          }

          return [key, stringify(valueString)];
        })
        .filter(([, value]) => value !== undefined),
    ),
  );
};

/**
 * Convert the params config and URLSearchParams object to a value.
 *
 * @example
 * const MyRouteParams = {
 *  id: numberParam,
 *  name: stringParam,
 * }
 *
 * const searchParams = new URLSearchParams('id=1&name=John');
 *
 * const value = convertFromSearchParams(MyRouteParams, searchParams);
 *
 * // value is now:
 * // {
 * //   id: 1,
 * //   name: 'John',
 * // }
 */
export const convertFromSearchParams = <TParamsConfig extends ParamsConfig>(
  paramsConfig: TParamsConfig,
  searchParams: URLSearchParams,
): InferParamsConfigType<TParamsConfig> => {
  return Object.fromEntries(
    Object.entries(paramsConfig)
      .map(([key, { parse, isArray }]) => {
        const value = isArray ? searchParams.getAll(key) : searchParams.get(key);

        if (value === null || (isArray && value.length === 0)) {
          return [key, undefined];
        }

        return [key, parse(value)];
      })
      .filter(([, value]) => value !== undefined),
  );
};
