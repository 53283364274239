/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useQuery } from '@apollo/client';
import { Box, Stack } from '@mui/material';
import { EconomicUnitBasicDetailsFragment } from '@predium/client-graphql';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SearchBoxWithLazyLoading, { SearchItemType } from '../../../components/search/SearchBoxWithLazyLoading';
import { PermissionType } from '../../../contexts/PermissionContext';
import { GET_ECONOMIC_UNITS_BY_IDS, GET_ECONOMIC_UNITS_BY_SEARCHED_STRING } from '../../../graphql/EsgAnalysis.queries';
import useEsgAnalysisFilters from '../../../hooks/useEsgAnalysisFilters';
import usePermissions from '../../../hooks/usePermissions';
import usePosthogTracking from '../../../hooks/usePosthogTracking';
import { EsgAnalysisFiltersKeys } from '../EsgAnalysisFilters/EsgAnalysisFilters';
import NavigationSelectorButton from './NavigationSelectorButton';

type Props = {
  filtersComponent: ReactNode;
};

const ALL_ECONOMIC_UNITS = 'allEconomicUnits';

export default function EconomicUnitsSelector({ filtersComponent }: Props) {
  const { t } = useTranslation();
  const { trackEvent } = usePosthogTracking();
  const ref = useRef<HTMLDivElement>(null);
  const limit = 10; // limit for pagination
  const { checkPortfolioPermission } = usePermissions();

  const [economicUnits, setEconomicUnits] = useState<SearchItemType[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(''); // For debounced query trigger
  const [filteredEconomicUnits, setFilteredEconomicUnits] = useState<SearchItemType[]>([]);

  const { filters, resetFilter, replaceFilter } = useEsgAnalysisFilters();
  const [economicUnitIds, setEconomicUnitsIds] = useState<number[] | []>(filters?.economicUnitIds ?? []);
  const [applyClicked, setApplyClicked] = useState(false);

  useEffect(() => {
    setEconomicUnitsIds(filters?.economicUnitIds ?? []);
    setApplyClicked(false);
  }, [filters]);

  const { loading, fetchMore, refetch } = useQuery(GET_ECONOMIC_UNITS_BY_SEARCHED_STRING, {
    variables: { offset: 0, limit, text: `%${debouncedSearchTerm.trim()}%` },
    skip: !showSearchBox || !debouncedSearchTerm, // Skip if no debounced search term
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (data?.economic_unit) {
        const filteredEconomicUnits = data.economic_unit
          .filter((economicUnit) => checkPortfolioPermission(economicUnit.portfolio.id, PermissionType.READ))
          .map(mapToEconomicUnitSelectOption);

        setEconomicUnits(filteredEconomicUnits);
        setHasMore(data.economic_unit.length === limit); // Check if we have more data
      }
    },
  });

  const { data } = useQuery(GET_ECONOMIC_UNITS_BY_IDS, {
    variables: {
      // @ts-ignore
      ids: economicUnitIds,
    },
    skip: showSearchBox || !economicUnitIds || economicUnitIds.length === 0,
  });

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setShowSearchBox(false);
        if (!applyClicked) {
          const filteredArray = economicUnitIds.filter((id) => (filters?.economicUnitIds ?? []).includes(id));
          setEconomicUnitsIds(filteredArray);
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [applyClicked, economicUnitIds, filters?.economicUnitIds, ref]);

  // useEscapeKey(() => setShowSearchBox(false));

  /**
   * Creates each option for the ecoUnit select
   */
  const mapToEconomicUnitSelectOption = (ecoUnit: EconomicUnitBasicDetailsFragment): SearchItemType => {
    return {
      value: ecoUnit.id,
      label: ecoUnit.name ?? '',
    };
  };

  const getItemFromIds = (ids: number[] | undefined) => {
    if (!ids) {
      return [];
    }
    return economicUnits.filter((ecoUnit: SearchItemType) => ids.includes(ecoUnit.value));
  };

  const onEconomicUnitSelected = (selectedEconomicUnit: SearchItemType) => {
    setEconomicUnitsIds((prev: number[]) => {
      if (prev.includes(selectedEconomicUnit.value)) {
        return prev.filter((id) => id !== selectedEconomicUnit.value);
      } else {
        return [...prev, selectedEconomicUnit.value];
      }
    });
    trackEvent('PORTFOLIO_SELECTED', {
      economic_unit_id: selectedEconomicUnit.value,
    });
  };

  const loadMore = async (newOffset: number, newDebouncedSearchTerm: string) => {
    if (!hasMore) return;

    const result = await fetchMore({
      variables: {
        offset: newOffset,
        limit,
        text: `%${newDebouncedSearchTerm.trim()}%`,
      },
    });

    // Check if we received more data
    if (result.data.economic_unit.length < limit) {
      setHasMore(false); // No more data
    } else {
      setHasMore(true); // More data exists
    }

    // Append new buildings
    setEconomicUnits((prev) => [...prev, ...result.data.economic_unit.map(mapToEconomicUnitSelectOption)]);
  };
  useEffect(() => {
    if (showSearchBox) {
      setFilteredEconomicUnits(getItemFromIds(economicUnitIds) ?? []);
    } else {
      setFilteredEconomicUnits(data?.economic_unit ? data?.economic_unit.map(mapToEconomicUnitSelectOption) : []);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [economicUnits, data, economicUnitIds, showSearchBox]);

  const count = filters?.economicUnitIds?.length ?? 0;

  const selectorLabel =
    count > 1
      ? `${count} ${t('General_EconomicUnit', {
          count: count,
        })}`
      : count === 0
      ? t('General_AllEconomicUnits')
      : filteredEconomicUnits[0]?.label;

  const handleTypographyClick = () => {
    setShowSearchBox(!showSearchBox);
    if (economicUnits.length === 0) {
      refetch(); // Fetch initial data if buildings are empty
    }
  };

  const handleClear = () => {
    resetFilter(EsgAnalysisFiltersKeys.economicUnitIds);
    resetFilter(EsgAnalysisFiltersKeys.buildingIds);
    trackEvent('ESG_ECONOMIC_UNIT_SELECTED', {
      economic_unit_id: ALL_ECONOMIC_UNITS,
    });
  };

  return (
    <Stack direction="row" alignItems={'center'} gap={1}>
      <Box ref={ref} ml={1}>
        <NavigationSelectorButton
          title={selectorLabel}
          onClick={handleTypographyClick}
          isSelected={showSearchBox}
          tooltipText={t('General_SelectEconomicUnit')}
        />

        {showSearchBox && (
          <SearchBoxWithLazyLoading
            refetch={refetch}
            onItemSelected={onEconomicUnitSelected}
            selectedItems={filteredEconomicUnits}
            loading={loading}
            listItems={economicUnits}
            loadMore={loadMore}
            hasMore={hasMore}
            setHasMore={setHasMore}
            setDebouncedSearchTerm={setDebouncedSearchTerm}
            debouncedSearchTerm={debouncedSearchTerm}
            noDataText={t('EsgAnalysisFilterBarEconomicUnits_NoEconomicUnitsFound')}
            placeholder={t('General_SearchEconomicUnitPlaceholder')}
            handleClear={handleClear}
            isMultiSelect
            showClearButton={count > 0 || filteredEconomicUnits.length > 0}
            onHideSearchBox={() => setShowSearchBox(false)}
            handleClick={() => {
              replaceFilter(EsgAnalysisFiltersKeys.economicUnitIds, economicUnitIds);
              setShowSearchBox(false);
              setApplyClicked(true);
            }}
            disableApplyButton={
              (economicUnitIds.length === 0 && selectorLabel === t('General_AllEconomicUnits')) ||
              isEqual(sortBy(economicUnitIds), sortBy(filters?.economicUnitIds ?? []))
            }
          />
        )}
      </Box>
      {filtersComponent && <Box>{filtersComponent}</Box>}
    </Stack>
  );
}
