import { Stack } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import MultiSelectFilterWithSearch from '../../../components/search/MultiSelectFilterWithSearch';
import SearchField from '../../../components/search/SearchField';

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
  pl: 3,
}));

type EconomicUnitToolbarProps = {
  children: React.ReactNode;
  filterName: string;
  onFilterName: (value: string) => void;
  tempPortfolioSelection: string[];
  handlePortfolioSelectionChange: (values: (string | number)[]) => void;
  applyPortfolioSelection: () => void;
  clearPortfolioSelection: () => void;
  applyPortfolioDisabled: boolean;
  clearPortfolioDisabled: boolean;
  portfolioOptions: string[];
  selectedPortfolios: (string | number)[];
};

export default function EconomicUnitToolbar({
  filterName,
  children,
  onFilterName,
  tempPortfolioSelection,
  handlePortfolioSelectionChange,
  applyPortfolioSelection,
  clearPortfolioSelection,
  applyPortfolioDisabled,
  clearPortfolioDisabled,
  portfolioOptions,
  selectedPortfolios,
}: EconomicUnitToolbarProps) {
  const { t } = useTranslation();

  return (
    <RootStyle>
      <Stack direction="row" sx={{ width: '100%' }} gap={2} alignItems="center">
        {onFilterName && (
          <SearchField
            value={filterName}
            onChange={onFilterName}
            placeholder={t('General_SearchEconomicUnitPlaceholder')}
            sx={{ maxWidth: '240px' }}
          />
        )}
        <MultiSelectFilterWithSearch
          label={t('General_AllPortfolios')}
          selectedLabel={t('General_Portfolio_other')}
          items={portfolioOptions.map((portfolio) => ({
            value: portfolio,
            name: portfolio,
          }))}
          tempSelection={tempPortfolioSelection}
          handleSelectionChange={handlePortfolioSelectionChange}
          applySelection={applyPortfolioSelection}
          clearSelection={clearPortfolioSelection}
          isApplyDisabled={applyPortfolioDisabled}
          isClearDisabled={clearPortfolioDisabled}
          noResultsText={t('NoExistingPortfolios_info')}
          selectedFilter={selectedPortfolios}
          searchable
        />
        {children}
      </Stack>
    </RootStyle>
  );
}
