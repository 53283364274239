/* eslint-disable @typescript-eslint/ban-ts-comment */
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Divider, Grid, MenuItem, Typography, useTheme } from '@mui/material';
import { envelope_type_enum } from '@predium/client-graphql';
import { DoorRenovationTemplates } from '@predium/client-lookup';
import { translateUnitEnum_dynamic } from '@predium/i18n/client';
import { Units } from '@predium/utils';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormProvider, RHFNumberField, RHFSelect, RHFTextField } from '../../../../../../components/hook-form';
import InfoTable, { InfoTableData } from '../../../../../../components/presentations/InfoTable';
import { useLanguage } from '../../../../../../provider/LanguageProvider';
import { createEnvelopeInfoTable } from '../../../../Scenario/scenarios.util';
import { getIsCostAvailable, getMinDate } from '../../../ActionPlan.utils';
import { NumberInputSx, SelectSx, StyledListItemText } from '../../../Components/ActionCustomStyles';
import { EnvelopePartialRenovationOption } from '../../CreateAction';
import { DoorValidationSchema, SetValueOptions } from '../../CreateActions/validation-schema';
import { EnvelopeActionEditProps } from '../../EditAction';
import EnvelopeAffectedPartsTable from '../../PartialRenovations/EnvelopeRenovation/EnvelopeAffectedPartsTable';
import EnvelopePartialRenovationsAutocomplete from '../../PartialRenovations/EnvelopeRenovation/EnvelopePartialRenovationsAutocomplete';

export type DoorActionForm = {
  envelope_renovation_parameter: {
    renovation_ids: string[];
    material_name: string;
    cost_per_m2?: number;
    u_value?: number;
    envelope_type: envelope_type_enum.DOOR;
  };
  implementation_to: DateTime | '';
  affected_parts: EnvelopePartialRenovationOption[];
};

export type DoorValidationSchemaType = ReturnType<typeof DoorValidationSchema>;

const DoorAction = forwardRef(
  (
    {
      selectedAction,
      selectedActionType,
      previousActionBuildingModel,
      resetSimulatedData,
      loading,
      parameters,
      affectedParts,
      isRecommendedAction,
      implementationTo,
      implementationToComponent,
    }: EnvelopeActionEditProps,
    ref,
  ) => {
    const { t } = useTranslation();
    const { language, localize } = useLanguage();
    const theme = useTheme();

    const [isNewMaterial, setIsNewMaterial] = useState<boolean>(false);

    const envelopes = previousActionBuildingModel.envelope_units;
    const buildingId = previousActionBuildingModel.building!.id;

    const infoTableData: InfoTableData[] | null = createEnvelopeInfoTable(selectedActionType, envelopes, t, localize);
    const affectedRenovationIds = affectedParts.flatMap((part) => part.secondary_id);
    const minimumDate = isRecommendedAction ? implementationTo : getMinDate();

    const defaultValues = {
      envelope_renovation_parameter: {
        renovation_ids: affectedRenovationIds,
        material_name: parameters.material_name,
        cost_per_m2: parameters.cost_per_m2 ? parseFloat(parameters.cost_per_m2.toFixed(2)) : undefined,
        u_value: parameters.u_value ?? undefined,
        envelope_type: selectedActionType as envelope_type_enum.DOOR,
      },
      implementation_to: implementationTo,
      affected_parts: affectedParts,
    };

    const formMethods = useForm<DoorActionForm>({
      mode: 'onChange',
      reValidateMode: 'onChange',
      defaultValues: defaultValues,
      resolver: yupResolver(DoorValidationSchema(minimumDate, language, isRecommendedAction)),
    });

    useImperativeHandle(ref, () => ({
      methods: formMethods,
    }));

    const { setValue, control, watch } = formMethods;

    const { isSubmitting } = useFormState({
      control,
    });

    const materialName = watch('envelope_renovation_parameter.material_name');
    const affectedPartsChanged = watch('affected_parts');

    const allParameters = DoorRenovationTemplates;
    const templateMaterialNames = allParameters.map((parameter) => parameter.material_name);

    const isMaterialFromTemplate = allParameters.some((params) => params.material_name === parameters.material_name);
    // need to push material na,e to show in the dropdown as selected value for custom material names as well as other
    // material names which are not part of template
    const allMaterialNames = isMaterialFromTemplate
      ? templateMaterialNames
      : [...templateMaterialNames, parameters.material_name];

    const handleMaterialChange = (selectedMaterialName: string | undefined) => {
      resetSimulatedData();

      const values = allParameters.find((material) => material?.material_name === selectedMaterialName);
      if (values) {
        setValue('envelope_renovation_parameter.material_name', get(values, 'material_name', ''), SetValueOptions);
        setValue('envelope_renovation_parameter.u_value', get(values, 'u_value', 0), SetValueOptions);
        setValue('envelope_renovation_parameter.cost_per_m2', get(values, 'cost_per_m2', 0), SetValueOptions);
      } else if (selectedMaterialName === parameters?.material_name) {
        setValue('envelope_renovation_parameter.cost_per_m2', 0, {
          shouldDirty: true,
        });
        setValue('envelope_renovation_parameter.u_value', parameters.u_value ?? 0, {
          shouldDirty: true,
        });
      }
    };

    const handleAddNewMaterial = () => {
      //TODO somehow set to empty instead of 0
      setValue('envelope_renovation_parameter.material_name', '', {
        shouldDirty: true,
      });
      setValue('envelope_renovation_parameter.cost_per_m2', 0, {
        shouldDirty: true,
      });
      setValue('envelope_renovation_parameter.u_value', 0, {
        shouldDirty: true,
      });
      setIsNewMaterial(true);
    };

    const shouldDisable =
      isSubmitting ||
      (!isNewMaterial && materialName !== '') ||
      templateMaterialNames.includes(materialName) ||
      loading;

    useEffect(() => {
      setValue(
        'envelope_renovation_parameter.renovation_ids',
        affectedPartsChanged.flatMap((part: EnvelopePartialRenovationOption) => part.secondary_id),
        {
          shouldDirty: true,
          shouldValidate: true,
        },
      );
      const changedRenovationIds = affectedPartsChanged.flatMap(
        (part: EnvelopePartialRenovationOption) => part.secondary_id,
      );
      if (!isEqual(changedRenovationIds.sort(), affectedRenovationIds.sort())) {
        resetSimulatedData();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [affectedPartsChanged]);

    const isCostAvailableForMaterial = getIsCostAvailable(materialName, allParameters);
    const showBorder = isNewMaterial || materialName;
    const actionType = parameters.envelope_type;

    return (
      <FormProvider
        methods={formMethods}
        onSubmit={(event) => {
          event.stopPropagation();
          event.preventDefault();
        }}
      >
        <Box my={3}>
          {infoTableData && (
            <Box mb={3}>
              <InfoTable buildingId={buildingId} data={infoTableData} title={t('General_StateBeforeRenovation')} />
            </Box>
          )}

          {affectedParts && affectedParts.length > 0 && !!actionType && (
            <EnvelopeAffectedPartsTable affectedParts={affectedParts} action={actionType} envelopes={envelopes} />
          )}

          {!isRecommendedAction && (
            <EnvelopePartialRenovationsAutocomplete
              action={envelope_type_enum.DOOR}
              envelopes={envelopes}
              envelopeRenovations={selectedAction?.renovations_envelope ?? []}
              setValue={(value: EnvelopePartialRenovationOption[]) => {
                setValue('affected_parts', value, {
                  shouldDirty: true,
                  shouldValidate: true,
                });
              }}
              sx={{ ...SelectSx, mb: 3 }}
              disabled={loading}
              actionMode={'edit'}
            />
          )}

          <Box
            sx={{
              p: showBorder ? 2 : 0,
              border: showBorder ? `1px solid ${theme.palette.grey[500_32]}` : 0,
              borderRadius: '8px',
              mb: showBorder ? 3 : 0,
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                {isNewMaterial ? (
                  <RHFTextField
                    name="envelope_renovation_parameter.material_name"
                    label={t('General_Door_one')}
                    size={'small'}
                    sx={{ ...SelectSx, pb: 0, mb: 2 }}
                    disabled={isSubmitting || loading}
                    onChange={(e) => {
                      resetSimulatedData();
                      setValue('envelope_renovation_parameter.material_name', e.target.value, SetValueOptions);
                    }}
                    autoComplete="off"
                  />
                ) : (
                  <RHFSelect
                    name="envelope_renovation_parameter.material_name"
                    label={t('General_Door_one')}
                    size={'small'}
                    sx={{ ...SelectSx, pb: 0, mb: 2 }}
                    disabled={isSubmitting || loading}
                    onValueChange={handleMaterialChange}
                  >
                    {allMaterialNames.map((material) => (
                      <MenuItem key={material} value={material}>
                        <StyledListItemText
                          action={material}
                          secondary={
                            getIsCostAvailable(material, allParameters) ? translateUnitEnum_dynamic(Units.price, t) : ''
                          }
                        />
                      </MenuItem>
                    ))}
                    <Divider />
                    <MenuItem
                      onClick={() => {
                        handleAddNewMaterial();
                      }}
                    >
                      <Button
                        type="button"
                        sx={{
                          '&:hover': {
                            backgroundColor: 'transparent',
                          },
                        }}
                        size="small"
                        variant="text"
                      >
                        {t('CreateNewEnvelopeAction_CreateNewDoorParameters')}
                      </Button>
                    </MenuItem>
                  </RHFSelect>
                )}
              </Grid>
            </Grid>

            {(materialName || isNewMaterial) && (
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <RHFNumberField
                    name="envelope_renovation_parameter.u_value"
                    label={`${t('General_UdValue')} [${translateUnitEnum_dynamic(Units.uValue, t)}]`}
                    size={'small'}
                    sx={SelectSx}
                    disabled={shouldDisable || loading}
                    onValueChange={() => {
                      resetSimulatedData();
                    }}
                  />
                </Grid>
              </Grid>
            )}

            {!isNewMaterial && materialName && (
              <Grid container spacing={2}>
                <Grid item xs={6} mt={0}>
                  <RHFNumberField
                    name="envelope_renovation_parameter.cost_per_m2"
                    label={t('General_PricePerM2Part')}
                    size={'small'}
                    sx={NumberInputSx}
                    disabled={shouldDisable || loading}
                    onValueChange={() => {
                      resetSimulatedData();
                    }}
                  />
                </Grid>
              </Grid>
            )}
            {materialName && !isNewMaterial && !isCostAvailableForMaterial && (
              <Grid item xs={12} mt={2}>
                <Typography variant="caption" sx={{ display: 'flex', mb: 0 }} gutterBottom>
                  {t('General_NoCostAvailableForAction')}
                </Typography>
              </Grid>
            )}
          </Box>
          {implementationToComponent}
        </Box>
      </FormProvider>
    );
  },
);

export default DoorAction;
