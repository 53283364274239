import { language_enum } from '@predium/enums';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import 'dayjs/locale/en-gb';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

// ----------------------------------------------------------------------

const DAYJS_LOCALE: Record<language_enum, string> = {
  [language_enum.deDE]: 'de',
  [language_enum.enGB]: 'en-gb',
};

export function durationFromNow(date: string, language: language_enum) {
  const locale = DAYJS_LOCALE[language];

  return dayjs(date).locale(locale).fromNow();
}
