import { Stack, Typography } from '@mui/material';
import { area_type_enum } from '@predium/enums';
import {
  fFloatingPoint,
  getBuildingIsMixed,
  getBuildingIsOnlyCommercial,
  getBuildingIsOnlyResidential,
  Localize,
  Units,
} from '@predium/utils';
import { TFunction } from 'i18next';
import { ReactNode } from 'react';
import { AreaMutation } from '../types';
import { getGRESBMinMax } from '../utils';

export type Validation = {
  equation: string;
  errorLabels: {
    title: string;
    preBody?: ReactNode;
    body: ReactNode;
    postBody?: ReactNode;
  };
  isFullFilled: boolean;
  affectedAreas: area_type_enum[];
  variant: 'error' | 'warning';
};

export const getAFValidation = (
  areas: AreaMutation[],
  areasByType: Record<area_type_enum, number>,
  t: TFunction<'translation'>,
  localize: Localize,
): Validation => {
  const gresb = getGRESBMinMax(areas);
  const isMixed = getBuildingIsMixed(areas);
  const isCommercial = getBuildingIsOnlyCommercial(areas);
  const isResidential = getBuildingIsOnlyResidential(areas);

  const minThreshold = gresb.min * areasByType.MF;
  const maxThreshold = gresb.max * areasByType.MF;

  const isLowerThanMin = areasByType.AF < minThreshold;

  return {
    equation: 'GRESBmin ≤ AF ≤ GRESBmax',
    errorLabels: {
      title: isLowerThanMin
        ? t('DataCollectionAreas_AFValidationMinTitle')
        : t('DataCollectionAreas_AFValidationMaxTitle'),
      preBody: t('DataCollectionAreas_AFValidationPreBody'),
      body: (
        <Stack spacing={1}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="caption">{t('DataCollectionAreas_AFValidationEnteredArea')}</Typography>
            <Typography variant="caption">
              {localize.formatAsFloat(fFloatingPoint(areasByType.AF), { unit: Units.area })}
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="caption">
              {isMixed && t('DataCollectionAreas_AFValidationMixedDescription')}
              {isCommercial && t('DataCollectionAreas_AFValidationCommercialDescription')}
              {isResidential && t('DataCollectionAreas_AFValidationResidentialDescription')}
            </Typography>
            <Typography variant="caption">
              {localize.formatAsFloat(fFloatingPoint(minThreshold))}-
              {localize.formatAsFloat(fFloatingPoint(maxThreshold), { unit: Units.area })}
            </Typography>
          </Stack>
        </Stack>
      ),
      postBody: isLowerThanMin
        ? t('DataCollectionAreas_AFValidationMinPostBody')
        : t('DataCollectionAreas_AFValidationMaxPostBody'),
    },
    isFullFilled: minThreshold <= areasByType.AF && areasByType.AF <= maxThreshold,
    affectedAreas: [area_type_enum.AF],
    variant: 'warning',
  };
};

export const getNGFBGFValidation = (
  areasByType: Record<area_type_enum, number>,
  t: TFunction<'translation'>,
): Validation => {
  return {
    equation: 'NGF ≤ BGF',
    errorLabels: {
      title: t('DataCollectionAreas_ErrorTooltipTitle'),
      body: `${t('DataCollectionAreas_NetFloorArea')} ≤ ${t('DataCollectionAreas_GrossFloorArea')}`,
      postBody: t('DataCollectionAreas_ErrorTooltipPostBody'),
    },
    isFullFilled: areasByType.NGF <= areasByType.BGF,
    affectedAreas: [area_type_enum.BGF, area_type_enum.NGF],
    variant: 'error',
  };
};

export const getEBFBGFValidation = (
  areasByType: Record<area_type_enum, number>,
  t: TFunction<'translation'>,
): Validation => {
  return {
    equation: 'EBF ≤ BGF',
    errorLabels: {
      title: t('DataCollectionAreas_ErrorTooltipTitle'),
      body: `${t('DataCollectionAreas_EnergyReferenceArea')} ≤ ${t('DataCollectionAreas_GrossFloorArea')}`,
      postBody: t('DataCollectionAreas_ErrorTooltipPostBody'),
    },
    isFullFilled: areasByType.EBF <= areasByType.BGF,
    affectedAreas: [area_type_enum.BGF, area_type_enum.EBF],
    variant: 'error',
  };
};

export const getEBFNGFValidation = (
  areasByType: Record<area_type_enum, number>,
  t: TFunction<'translation'>,
): Validation => {
  return {
    equation: 'EBF ≤ NGF',
    errorLabels: {
      title: t('DataCollectionAreas_EBFNGFValidationTitle'),
      preBody: t('DataCollectionAreas_EBFNGFValidationPreBody'),
      body: `${t('DataCollectionAreas_EnergyReferenceArea')} ≤ ${t('DataCollectionAreas_NetFloorArea')}`,
      postBody: t('DataCollectionAreas_EBFNGFValidationPostBody'),
    },
    isFullFilled: areasByType.EBF <= areasByType.NGF,
    variant: 'warning',
    affectedAreas: [area_type_enum.NGF, area_type_enum.EBF],
  };
};

export const getMFandAFToBGFValidation = (
  areasByType: Record<area_type_enum, number>,
  t: TFunction<'translation'>,
): Validation => {
  return {
    equation: 'MF + AF ≤ BGF',
    errorLabels: {
      title: t('DataCollectionAreas_ErrorTooltipTitle'),
      preBody: t('DataCollectionAreas_ErrorTooltipPreBody'),
      body: `${t('DataCollectionAreas_RentableArea')} + ${t('DataCollectionAreas_CommonArea')} ≤ ${t(
        'DataCollectionAreas_GrossFloorArea',
      )}`,
      postBody: t('DataCollectionAreas_ErrorTooltipPostBody'),
    },
    isFullFilled: fFloatingPoint(areasByType.MF + areasByType.AF) <= areasByType.BGF,
    affectedAreas: [area_type_enum.BGF, area_type_enum.MF, area_type_enum.AF],
    variant: 'error',
  };
};
