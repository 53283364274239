import { graphql } from '@predium/client-graphql';

export const CREATE_PORTFOLIO = graphql(`
  mutation PortfolioManagementCreatePortfolio(
    $name: String!
    $baseEmissionFactorType: base_emission_factor_type_enum
    $configId: Int
  ) {
    createPortfolio(name: $name, configId: $configId, baseEmissionFactorType: $baseEmissionFactorType)
  }
`);

export const DELETE_PORTFOLIO = graphql(`
  mutation PortfolioManagementDeletePortfolio($id: Int!) {
    delete_portfolio_by_pk(id: $id) {
      __typename
      id
    }
  }
`);

export const UPDATE_PORTFOLIO = graphql(`
  mutation PortfolioManagementUpdatePortfolio(
    $name: String!
    $baseEmissionFactorType: base_emission_factor_type_enum
    $configId: Int
    $id: Int!
  ) {
    updatePortfolio(id: $id, name: $name, configId: $configId, baseEmissionFactorType: $baseEmissionFactorType)
  }
`);
