import { MenuItem } from '@mui/material';
import { TFunction } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ActionMoreMenu from '../../../../../components/common/ActionMoreMenu';

export const graphOptions = (
  t: TFunction<'translation', undefined>,
): Array<{
  label: string;
  value: ScenarioGraphType;
}> => {
  return [
    {
      label: t('General_CO2Intensity'),
      value: 'co2_emissions',
    },
    {
      label: t('General_FinalEnergy'),
      value: 'final_energy',
    },
    {
      label: t('General_PrimaryEnergy'),
      value: 'primary_energy',
    },
    {
      label: t('General_Investments'),
      value: 'investment',
    },
  ];
};

export type ScenarioGraphType = 'investment' | 'final_energy' | 'co2_emissions' | 'primary_energy';

type GraphDropdownProps = {
  graphType: ScenarioGraphType;
  onSelect: (type: ScenarioGraphType) => void;
};

const ScenarioImpactGraphSelect: React.FC<GraphDropdownProps> = ({ graphType, onSelect }) => {
  const { t } = useTranslation();

  const options = graphOptions(t);

  const selectedOption = options.find((option) => option.value === graphType)?.label || '';

  return (
    <ActionMoreMenu
      variant="select"
      buttonText={selectedOption}
      actions={options.map((option) => (
        <MenuItem
          key={option.value}
          value={option.value}
          onClick={() => onSelect(option.value)}
          sx={{
            backgroundColor: graphType === option.value ? 'action.selected' : 'transparent',
          }}
        >
          {option.label}
        </MenuItem>
      ))}
      sx={{
        mr: 1,
        typography: 'h6',
        color: 'text.secondary',
      }}
    />
  );
};

export default ScenarioImpactGraphSelect;
