import { yupResolver } from '@hookform/resolvers/yup';
import { Box, InputAdornment, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { BuildingModelFragment } from '@predium/client-graphql';
import { energy_source_type_enum, energy_system_type_enum } from '@predium/enums';
import { translateEnergySourceTypeEnum_dynamic, translateEnergySystemTypeEnum_dynamic } from '@predium/i18n/client';
import { TFunction, t } from 'i18next';
import uniqBy from 'lodash/uniqBy';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { FormProvider, UseFormSetValue, useForm } from 'react-hook-form';
import { TechnologyIcons } from '../../../../../../assets/images';
import { RHFDatePicker, RHFNumberField, RHFTextField } from '../../../../../../components/hook-form';
import { useLanguage } from '../../../../../../provider/LanguageProvider';
import { getMaxDate } from '../../../ActionPlan.utils';
import { NumberInputSx, StyledListItemText } from '../../../Components/ActionCustomStyles';
import { CustomCostsForm } from '../../ActionDetails/ActionPricing/RowCosts';
import InfoTableCustomAction from '../../ActionDetails/InfoTableCustomAction';
import { ConsumerPartialRenovationOption } from '../../CreateAction';
import SystemPartialRenovationsAutocomplete from '../../PartialRenovations/SystemRenovation/ConsumerPartialRenovationsAutocomplete';
import { CustomActionFormSchema } from '../validation-schema';

export enum CustomEnergeticActionCategory {
  ENERGY_SOURCE = 'ENERGY_SOURCE',
  SYSTEM = 'SYSTEM',
  OTHER = 'OTHER',
}

const availableCustomOptions = {
  ENERGY_SOURCE: Object.values(energy_source_type_enum),
  SYSTEM: Object.values(energy_system_type_enum),
};

export type CustomEnergeticActionType = (typeof availableCustomOptions)[keyof typeof availableCustomOptions][number];

export const getCustomActionCategoryName = (category: CustomEnergeticActionCategory, t: TFunction) => {
  switch (category) {
    case CustomEnergeticActionCategory.ENERGY_SOURCE:
      return t('General_EnergySource');
    case CustomEnergeticActionCategory.SYSTEM:
      return t('General_System');
  }
};

export const getCustomActionName = (value: CustomEnergeticActionType, t: TFunction) => {
  if (availableCustomOptions.ENERGY_SOURCE.includes(value as energy_source_type_enum)) {
    return translateEnergySourceTypeEnum_dynamic(value as energy_source_type_enum, t);
  }
  if (availableCustomOptions.SYSTEM.includes(value as energy_system_type_enum)) {
    return translateEnergySystemTypeEnum_dynamic(value as energy_system_type_enum, t);
  }

  //should never happen
  throw new Error('No custom action name found');
};

export const getCustomEnergeticActionIcon = (
  value: (typeof availableCustomOptions)[keyof typeof availableCustomOptions][number],
) => {
  if (availableCustomOptions.ENERGY_SOURCE.includes(value as energy_source_type_enum)) {
    switch (value as energy_source_type_enum) {
      case energy_source_type_enum.ELECTRICITY_GREEN:
      case energy_source_type_enum.ELECTRICITY_MIX:
        return TechnologyIcons.electricity;
      default:
        return TechnologyIcons.heatingV2;
    }
  }
  if (availableCustomOptions.SYSTEM.includes(value as energy_system_type_enum)) {
    switch (value as energy_system_type_enum) {
      case energy_system_type_enum.HEATING:
        return TechnologyIcons.heatingV2;
      case energy_system_type_enum.LIGHTING:
        return TechnologyIcons.lighting;
      case energy_system_type_enum.COOLING:
        return TechnologyIcons.coolingV2;
      case energy_system_type_enum.VENTILATION:
        return TechnologyIcons.ventilation;
      case energy_system_type_enum.HOT_WATER:
        return TechnologyIcons.warm_water;
    }
  }
  return TechnologyIcons.custom;
};

export type CustomEnergeticActionForm = {
  implementation_to: DateTime | '';
  custom_action_renovation_parameter: {
    renovation_ids: string[];
    energy_source_type: energy_source_type_enum | null;
    system_type: energy_system_type_enum | null;
    custom_name: string;
    fraction: number;
    cost?: number | null;
  };
  affected_parts?: ConsumerPartialRenovationOption[];
};

type CustomEnergeticActionCreationProps = {
  previousActionBuildingModel: BuildingModelFragment;
  minimumDate: Date;
  resetSimulatedData: () => void;
  loading: boolean;
  setCostFormValue: UseFormSetValue<CustomCostsForm>;
};

const CustomEnergeticAction = forwardRef(
  (
    {
      loading,
      previousActionBuildingModel,
      minimumDate,
      setCostFormValue,
      resetSimulatedData,
    }: CustomEnergeticActionCreationProps,
    ref,
  ) => {
    const { language } = useLanguage();
    const [validationSchema, setValidationSchema] = useState(() =>
      CustomActionFormSchema(minimumDate, language, false, false, true),
    );
    const [selectedCustomActionType, setSelectedCustomActionType] = useState<CustomEnergeticActionType | null>(null);

    const energySystems = previousActionBuildingModel.energy_systems;

    //filter out anything solar/pv related
    const filteredEnergySystems = energySystems
      .filter((system) => system.energy_system_type_id !== energy_system_type_enum.SOLAR)
      .filter((system) =>
        system.energy_system_consumer_routes.some(
          (route) => route.energy_source_type_id !== energy_source_type_enum.SOLAR,
        ),
      );

    const filteredSystemOptions = availableCustomOptions.SYSTEM.filter((option) =>
      filteredEnergySystems.some((system) => system.energy_system_type_id === option),
    );

    const filteredEnergySourceOptions = availableCustomOptions.ENERGY_SOURCE.filter((option) =>
      filteredEnergySystems.some((system) =>
        system.energy_system_consumer_routes.some(
          (route) =>
            route.energy_source_type_id === option && route.energy_source_type_id !== energy_source_type_enum.SOLAR,
        ),
      ),
    );

    const filteredOptions = {
      ENERGY_SOURCE: filteredEnergySourceOptions,
      SYSTEM: filteredSystemOptions,
    };

    const defaultValues: CustomEnergeticActionForm = {
      implementation_to: '',
      custom_action_renovation_parameter: {
        renovation_ids: [],
        custom_name: '',
        fraction: 0,
        cost: null,
        energy_source_type: null,
        system_type: null,
      },
      affected_parts: [],
    };

    const formMethods = useForm<CustomEnergeticActionForm>({
      mode: 'onChange',
      reValidateMode: 'onChange',
      defaultValues: defaultValues,
      resolver: yupResolver(validationSchema),
    });

    const { setValue, reset, getValues, watch } = formMethods;

    const affectedParts = watch('affected_parts');

    useImperativeHandle(ref, () => ({
      methods: formMethods,
    }));

    const energyConsumers = uniqBy(
      previousActionBuildingModel.energy_systems
        ?.filter((system) => system?.energy_system_type_id === selectedCustomActionType)
        .flatMap((system) => system?.energy_system_consumer_routes.map((route) => route.energy_consumer)) ?? [],
      (c) => c.id,
    );

    const affectedCategory = selectedCustomActionType
      ? availableCustomOptions.ENERGY_SOURCE.includes(selectedCustomActionType as energy_source_type_enum)
        ? CustomEnergeticActionCategory.ENERGY_SOURCE
        : CustomEnergeticActionCategory.SYSTEM
      : null;

    const noSearchResults = Object.values(filteredOptions).every((options) => options.length === 0);
    const enablePartialRenovations = Object.values(energy_system_type_enum).includes(
      selectedCustomActionType as energy_system_type_enum,
    );

    // Update validation schema when selection changes
    useEffect(() => {
      setValidationSchema(CustomActionFormSchema(minimumDate, language, enablePartialRenovations, false, true));
    }, [enablePartialRenovations, language, minimumDate, selectedCustomActionType]);

    const handleCustomActionTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
      setSelectedCustomActionType(event.target.value as CustomEnergeticActionType);
      reset({
        ...getValues(),
        custom_action_renovation_parameter: {
          ...getValues().custom_action_renovation_parameter,
          custom_name: getValues().custom_action_renovation_parameter.custom_name,
        },
      });
      resetSimulatedData();
    };

    useEffect(() => {
      if (Object.values(energy_source_type_enum).includes(selectedCustomActionType as energy_source_type_enum)) {
        setValue(
          'custom_action_renovation_parameter.energy_source_type',
          selectedCustomActionType as energy_source_type_enum,
        );
      } else if (Object.values(energy_system_type_enum).includes(selectedCustomActionType as energy_system_type_enum)) {
        setValue('custom_action_renovation_parameter.system_type', selectedCustomActionType as energy_system_type_enum);
      } else {
        return;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCustomActionType]);

    useEffect(() => {
      // TODO PRE-5602 change to secondary id of consumer
      setValue(
        'custom_action_renovation_parameter.renovation_ids',
        affectedParts
          ? affectedParts.flatMap((part: ConsumerPartialRenovationOption) =>
              part.energy_system_consumer_routes.flatMap((route) => route.secondary_id),
            )
          : [],
        {
          shouldDirty: true,
          shouldValidate: true,
        },
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [affectedParts]);

    return (
      <FormProvider {...formMethods}>
        <TextField
          select
          label={t('General_ToOptimize')}
          onChange={handleCustomActionTypeChange}
          fullWidth
          sx={{
            ...NumberInputSx,
            pb: 0,
            mb: 0,
            mt: 2,
            '& .MuiInputBase-root': {
              height: 37,
            },
          }}
          size="small"
          disabled={loading}
        >
          {Object.entries(filteredOptions).flatMap(([category, options]) => [
            <MenuItem
              key={`header-${category}`}
              tabIndex={-1}
              sx={{
                '&:hover': {
                  backgroundColor: 'transparent',
                  cursor: 'default',
                },
                padding: 0,
                pt: options.length > 0 ? 1 : 0,
                pointerEvents: 'none',
              }}
            >
              {options.length > 0 && (
                <Typography variant="overline" sx={{ pl: 2 }}>
                  {getCustomActionCategoryName(category as CustomEnergeticActionCategory, t)}
                </Typography>
              )}
            </MenuItem>,
            ...options.map((value) => (
              <MenuItem
                value={value}
                key={value}
                sx={{
                  textTransform: 'none',
                  '&.Mui-selected': {
                    bgcolor: selectedCustomActionType === value ? 'action.selected' : 'transparent',
                  },
                }}
              >
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Box component="img" src={getCustomEnergeticActionIcon(value)} sx={{ width: 16, height: 16 }} />
                  <StyledListItemText action={getCustomActionName(value, t)} />
                </Stack>
              </MenuItem>
            )),
          ])}
          {noSearchResults && (
            <MenuItem sx={{ p: 1, '&:hover': { backgroundColor: 'transparent' } }} disableRipple>
              <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'center', width: '100%' }}>
                {t('General_NoResults')}
              </Typography>
            </MenuItem>
          )}
        </TextField>
        {selectedCustomActionType && (
          <>
            <InfoTableCustomAction
              affectedCategory={affectedCategory as CustomEnergeticActionCategory}
              selectedCustomActionType={selectedCustomActionType as CustomEnergeticActionType}
              buildingId={previousActionBuildingModel?.building?.id}
              buildingModel={previousActionBuildingModel}
              sx={{ mt: 3 }}
            />
            <RHFTextField
              name="custom_action_renovation_parameter.custom_name"
              label={t('General_ActionTitle')}
              size={'small'}
              sx={{ ...NumberInputSx, pb: 0, mb: 0, mt: 3 }}
              disabled={loading}
              onChange={(e) => {
                setValue('custom_action_renovation_parameter.custom_name', e.target.value, {
                  shouldDirty: true,
                  shouldValidate: true,
                });
              }}
            />
            {enablePartialRenovations && (
              <SystemPartialRenovationsAutocomplete
                action={selectedCustomActionType as energy_system_type_enum}
                energyConsumers={energyConsumers}
                sx={{ mt: 3 }}
                setValue={(value: ConsumerPartialRenovationOption[]) => {
                  resetSimulatedData();

                  setValue('affected_parts', value, {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                }}
                disabled={loading}
              />
            )}
            <RHFNumberField
              name="custom_action_renovation_parameter.fraction"
              label={`${t('General_FinalEnergyReduction')} - ${getCustomActionName(selectedCustomActionType, t)}`}
              size="small"
              min={0}
              sx={{ ...NumberInputSx, mt: 3 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography variant="body2" color="text.secondary">
                      %
                    </Typography>
                  </InputAdornment>
                ),
              }}
              disabled={loading}
              onValueChange={(values) => {
                if (values.floatValue !== undefined) {
                  setValue('custom_action_renovation_parameter.fraction', values.floatValue);
                }
                resetSimulatedData();
              }}
            />
            <RHFNumberField
              name="custom_action_renovation_parameter.cost"
              label={t('General_Cost', { count: 2 })}
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography variant="body2" color="text.secondary">
                      €
                    </Typography>
                  </InputAdornment>
                ),
              }}
              onValueChange={(value) => {
                setCostFormValue('use_custom_cost', true);
                setCostFormValue('cost_total_custom', value.floatValue);
                setCostFormValue('cost_custom_input', value.formattedValue);
              }}
              sx={{ ...NumberInputSx, mt: 3 }}
              disabled={loading}
            />
            <RHFDatePicker
              label={t('General_PlannedFor')}
              name="implementation_to"
              size={'small'}
              sx={{ ...NumberInputSx, mt: 3 }}
              disabled={loading}
              minDate={minimumDate}
              maxDate={getMaxDate()}
              onChange={(date) => {
                if (date) {
                  setValue('implementation_to', date);
                }
                resetSimulatedData();
              }}
            />
          </>
        )}
      </FormProvider>
    );
  },
);

export default CustomEnergeticAction;
