import { Grid, InputAdornment, MenuItem, ToggleButton, ToggleButtonGroup } from '@mui/material';
import {
  convertUserInputToStandardUnits,
  getConsumptionDisplayUnitValueForType,
  getStandardUnitForConsumptionType,
} from '@predium/client-lookup';
import { consumption_frequency_type_enum, consumption_type_enum, consumption_unit_enum } from '@predium/enums';
import {
  translateConsumptionFrequencyTypeEnum,
  translateConsumptionUnitEnum,
  translateConsumptionUnitEnum_dynamic,
  translateConsumptionUnitEnum_selectOptions,
  translateUnitEnum_dynamic,
} from '@predium/i18n/client';
import { Units } from '@predium/utils';
import { useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import IconScale from '../../../../assets/Icons/consumption/scale';
import { ICONS } from '../../../../assets/icons';
import Iconify from '../../../../components/Iconify';
import RHFInputWithSelect from '../../../../components/hook-form/RHFInputWithSelect';
import RHFNumberField from '../../../../components/hook-form/RHFNumberField';
import { ConsumptionInvoiceDraftFormData } from '../Drafts/SubBuildingConsumptionDraftForm';
import { ConsumptionWasteDetailsFormData } from '../Drafts/SubBuildingSingleConsumptionForm';

type Props = {
  index: number;
  disabled?: boolean;
};

export default function ConsumptionTypeInputs({ index, disabled = false }: Props) {
  const { t } = useTranslation();

  const { watch, setValue, unregister, getValues } = useFormContext<ConsumptionInvoiceDraftFormData>();

  const chosenDataType = watch(`consumptionDrafts.${index}.waste`) ? 'volume' : 'weight';

  const currentConsumptionType = watch(`consumptionDrafts.${index}.consumptionTypeId`);
  const currentEnergySourceType = watch(`consumptionDrafts.${index}.energySourceTypeId`);
  const currentConsumptionSubType = watch(`consumptionDrafts.${index}.subTypeId`);

  const currentDisplayUnitValue = watch(`consumptionDrafts.${index}.displayUnitValue`);
  const currentValue = watch(`consumptionDrafts.${index}.value`);
  const currentDisplayUnitWasteVolume = watch(`consumptionDrafts.${index}.waste.displayUnitVolume`);
  const currentWasteVolume = watch(`consumptionDrafts.${index}.waste.volume`);

  /**
   * The values in the waste form that have been deselected previously. These values are not part of the form and
   * validation anymore. But they should be restored when changing tabs again, so we just remember them here.
   */
  const previousUnusedFormValues = useRef<
    | ConsumptionWasteDetailsFormData
    | {
        value: number;
        displayUnitValue: consumption_unit_enum;
        cost: number;
      }
  >();

  /**
   * Type guard to narrow type of the form data. Is it a waste object or not?
   */
  const isWasteDetailsFormData = (
    formData:
      | ConsumptionWasteDetailsFormData
      | {
          value: number;
          displayUnitValue: consumption_unit_enum;
          cost: number;
        },
  ): formData is ConsumptionWasteDetailsFormData => {
    if ('displayUnitFrequency' in formData || 'displayUnitVolume' in formData) {
      return true;
    } else {
      return false;
    }
  };

  const handleWasteTypeChange = (e: any) => {
    const dataType: 'volume' | 'weight' = e.target.value;
    // Need to spread it since the object is manipulated by the form, nasty...
    const formValuesAtChange = { ...getValues(`consumptionDrafts.${index}`)! };

    // Reset the form fields manually because they are not unregistered automatically due to handling of "id" and other fields that are only set manually e.g. subTypeId
    if (dataType === 'weight') {
      unregister(`consumptionDrafts.${index}.waste`);

      if (previousUnusedFormValues.current && !isWasteDetailsFormData(previousUnusedFormValues.current)) {
        setValue(`consumptionDrafts.${index}.value`, previousUnusedFormValues.current.value);
        setValue(`consumptionDrafts.${index}.cost`, previousUnusedFormValues.current.cost);
        setValue(`consumptionDrafts.${index}.displayUnitValue`, previousUnusedFormValues.current.displayUnitValue);
      } else {
        setValue(`consumptionDrafts.${index}.displayUnitValue`, consumption_unit_enum.kg);
      }

      previousUnusedFormValues.current = {
        ...formValuesAtChange.waste!,
      };
    } else {
      unregister(`consumptionDrafts.${index}.value`);
      unregister(`consumptionDrafts.${index}.displayUnitValue`);
      unregister(`consumptionDrafts.${index}.cost`);

      if (previousUnusedFormValues.current && isWasteDetailsFormData(previousUnusedFormValues.current)) {
        setValue(`consumptionDrafts.${index}.waste.volume`, previousUnusedFormValues.current.volume);
        setValue(
          `consumptionDrafts.${index}.waste.displayUnitVolume`,
          previousUnusedFormValues.current.displayUnitVolume,
        );
        setValue(`consumptionDrafts.${index}.waste.frequency`, previousUnusedFormValues.current.frequency);
        setValue(
          `consumptionDrafts.${index}.waste.displayUnitFrequency`,
          previousUnusedFormValues.current.displayUnitFrequency,
        );
        setValue(`consumptionDrafts.${index}.waste.amount`, previousUnusedFormValues.current.amount);
        setValue(`consumptionDrafts.${index}.waste.price`, previousUnusedFormValues.current.price);
      } else {
        // Set display units to default
        setValue(`consumptionDrafts.${index}.waste.displayUnitVolume`, consumption_unit_enum.l);
        setValue(`consumptionDrafts.${index}.waste.displayUnitFrequency`, consumption_frequency_type_enum.DAILY);
      }

      previousUnusedFormValues.current = {
        value: formValuesAtChange.value!,
        displayUnitValue: formValuesAtChange.displayUnitValue!,
        cost: formValuesAtChange.cost!,
      };
    }
  };

  const OPTIONS = [
    {
      value: 'weight',
      label: t('DataCollection_ConsumptionWasteToggleButton-weight'),
      icon: <IconScale sx={{ mr: 0.5, fontSize: 18 }} />,
    },
    {
      value: 'volume',
      label: t('DataCollection_ConsumptionWasteToggleButton-volume'),
      icon: <Iconify icon={ICONS.VOLUME} height={18} width={18} sx={{ mr: 0.5 }} />,
    },
  ];

  const isWasteSelected = currentConsumptionType === consumption_type_enum.WASTE;
  const isWeightedWasteSelected = isWasteSelected && chosenDataType === 'weight';
  const isVolumeWasteSelected = isWasteSelected && chosenDataType === 'volume';

  const isWeightSelected = isWeightedWasteSelected || !isWasteSelected;

  return (
    <>
      {isWasteSelected && (
        <Grid item xs={12}>
          <ToggleButtonGroup
            color="primary"
            value={chosenDataType}
            exclusive
            onChange={handleWasteTypeChange}
            aria-label="Choose to report by volume or weight"
            size="small"
            fullWidth
            disabled={disabled}
          >
            {OPTIONS.map((option) => (
              <ToggleButton
                key={option.value}
                value={option.value}
                sx={{
                  '&.Mui-selected': {
                    backgroundColor: 'grey.100',
                    '&:hover': {
                      backgroundColor: 'grey.100',
                    },
                  },
                }}
              >
                {option.icon}
                {option.label}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Grid>
      )}

      {isWeightSelected && (
        <>
          <Grid item xs={6}>
            <RHFInputWithSelect
              key={`consumptionDrafts.${index}.value`}
              label={t('General_Consumption')}
              inputName={`consumptionDrafts.${index}.value`}
              selectName={`consumptionDrafts.${index}.displayUnitValue`}
              SelectProps={{
                renderValue: () => (
                  <MenuItem sx={{ padding: 0 }}>
                    {translateConsumptionUnitEnum(currentDisplayUnitValue ?? consumption_unit_enum.kg)}
                  </MenuItem>
                ),
                SelectDisplayProps: {
                  style: {
                    paddingRight: '60px',
                  },
                },
              }}
              InputProps={{
                sx: {
                  backgroundColor: 'common.white', // Set input background to white
                },
              }}
              disabled={disabled}
              selectDisabled={
                disabled || !currentConsumptionType || (!currentEnergySourceType && !currentConsumptionSubType)
              }
              helperText={
                currentDisplayUnitValue &&
                getStandardUnitForConsumptionType(currentConsumptionType) !== currentDisplayUnitValue &&
                currentValue
                  ? t('DataCollection_ConsumptionValueInput-helperText', {
                      value: parseFloat(
                        convertUserInputToStandardUnits(
                          currentConsumptionType,
                          currentDisplayUnitValue,
                          currentValue,
                          (currentEnergySourceType ?? currentConsumptionSubType)!,
                        ).toFixed(4),
                      ),
                      standardUnit: translateConsumptionUnitEnum_dynamic(
                        getStandardUnitForConsumptionType(currentConsumptionType),
                        t,
                      ),
                    })
                  : undefined
              }
            >
              {currentConsumptionType
                ? getConsumptionDisplayUnitValueForType(currentConsumptionType, currentEnergySourceType!).map(
                    (unit) => (
                      <MenuItem key={unit} value={unit}>
                        {translateConsumptionUnitEnum_selectOptions(unit)}
                      </MenuItem>
                    ),
                  )
                : null}
            </RHFInputWithSelect>
          </Grid>
          <Grid item xs={6}>
            <RHFNumberField
              key={`consumptionDrafts.${index}.cost`}
              label={t('General_CostGross')}
              name={`consumptionDrafts.${index}.cost`}
              numberFormatProps={{
                // cost can be negative in cases of payback
                allowNegative: true,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">{translateUnitEnum_dynamic(Units.price, t)}</InputAdornment>
                ),
                sx: {
                  backgroundColor: 'common.white', // Set input background to white
                },
              }}
              disabled={disabled}
            />
          </Grid>
        </>
      )}

      {isVolumeWasteSelected && (
        <>
          <Grid item xs={6}>
            <RHFInputWithSelect
              key={`consumptionDrafts.${index}.waste.volume`}
              label={t('DataCollection_ConsumptionWasteVolumeInput-label')}
              inputName={`consumptionDrafts.${index}.waste.volume`}
              selectName={`consumptionDrafts.${index}.waste.displayUnitVolume`}
              SelectProps={{
                SelectDisplayProps: {
                  style: {
                    paddingRight: '60px',
                  },
                },
              }}
              InputProps={{
                sx: {
                  backgroundColor: 'common.white', // Set input background to white
                },
              }}
              disabled={disabled}
              selectDisabled={disabled}
              helperText={
                currentDisplayUnitWasteVolume &&
                currentDisplayUnitWasteVolume !== consumption_unit_enum.M3 &&
                currentWasteVolume
                  ? t('DataCollection_ConsumptionValueInput-helperText', {
                      value: parseFloat(
                        convertUserInputToStandardUnits(
                          currentConsumptionType,
                          currentDisplayUnitWasteVolume,
                          currentWasteVolume,
                          (currentEnergySourceType ?? currentConsumptionSubType)!,
                        ).toFixed(4),
                      ),
                      standardUnit: translateConsumptionUnitEnum_dynamic(consumption_unit_enum.M3, t),
                    })
                  : undefined
              }
            >
              <MenuItem value={consumption_unit_enum.l}>
                {translateConsumptionUnitEnum(consumption_unit_enum.l)}
              </MenuItem>
              <MenuItem value={consumption_unit_enum.M3}>
                {translateConsumptionUnitEnum(consumption_unit_enum.M3)}
              </MenuItem>
            </RHFInputWithSelect>
          </Grid>
          <Grid item xs={6}>
            <RHFInputWithSelect
              key={`consumptionDrafts.${index}.waste.frequency`}
              label={t('DataCollection_ConsumptionWasteFrequencyInput-label')}
              inputName={`consumptionDrafts.${index}.waste.frequency`}
              selectName={`consumptionDrafts.${index}.waste.displayUnitFrequency`}
              SelectProps={{
                SelectDisplayProps: {
                  style: {
                    paddingRight: '80px',
                  },
                },
              }}
              InputProps={{
                sx: {
                  backgroundColor: 'common.white', // Set input background to white
                },
              }}
              disabled={disabled}
              selectDisabled={disabled}
              endAdornmentPrePosition={<InputAdornment position="start">/</InputAdornment>}
            >
              <MenuItem value={consumption_frequency_type_enum.DAILY}>
                {translateConsumptionFrequencyTypeEnum(consumption_frequency_type_enum.DAILY)}
              </MenuItem>
              <MenuItem value={consumption_frequency_type_enum.WEEKLY}>
                {translateConsumptionFrequencyTypeEnum(consumption_frequency_type_enum.WEEKLY)}
              </MenuItem>
              <MenuItem value={consumption_frequency_type_enum.MONTHLY}>
                {translateConsumptionFrequencyTypeEnum(consumption_frequency_type_enum.MONTHLY)}
              </MenuItem>
            </RHFInputWithSelect>
          </Grid>
          <Grid item xs={6}>
            <RHFNumberField
              key={`consumptionDrafts.${index}.waste.amount`}
              label={t('DataCollection_ConsumptionWasteAmountInput-label')}
              name={`consumptionDrafts.${index}.waste.amount`}
              sx={{
                backgroundColor: 'common.white',
              }}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={6}>
            <RHFNumberField
              key={`consumptionDrafts.${index}.waste.price`}
              label={t('General_CostGross')}
              name={`consumptionDrafts.${index}.waste.price`}
              sx={{
                backgroundColor: 'common.white',
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">{translateUnitEnum_dynamic(Units.price, t)}</InputAdornment>
                ),
              }}
              disabled={disabled}
            />
          </Grid>
        </>
      )}
    </>
  );
}
