import { useGeocodingCore } from '@mapbox/search-js-react';
import { country_enum } from '@predium/enums';
import { maybeEnum } from '@predium/utils';
import { useCallback, useEffect, useState } from 'react';

type Params = {
  searchQuery?: string;
};

export type AddressSearchItem = {
  label: string;
  address: string;
  postalCode?: string;
  city?: string;
  country?: country_enum;
  lat: number;
  long: number;
};

const useAddressSearch = ({ searchQuery }: Params) => {
  const [options, setOptions] = useState<AddressSearchItem[]>([]);
  const searchableCountries = Object.values(country_enum).toString();
  const geocodingCore = useGeocodingCore({
    proximity: 'ip',
    accessToken: import.meta.env.VITE_MAPBOX_ACCESS_TOKEN,
    country: searchableCountries,
    // filters possible results, see options: https://docs.mapbox.com/api/search/geocoding/#geographic-feature-types
    types: 'address',
    permanent: true,
  });

  const searchAddress = useCallback(async () => {
    if (!searchQuery) {
      setOptions([]);
      return;
    }

    const response = await geocodingCore.forward(searchQuery, {
      limit: 5,
    });

    const result = response.features.map((f) => ({
      city: f.properties.context.place?.name,
      address: f.properties.name_preferred,
      postalCode: f.properties.context.postcode?.name,
      lat: f.properties.coordinates.latitude,
      long: f.properties.coordinates.longitude,
      label: f.properties.full_address,
      // Types for the library are wrong. Patch-Package was used to add it based on the docs
      // See type definition and https://docs.mapbox.com/api/search/geocoding/#geographic-feature-types
      country: maybeEnum(country_enum, f.properties.context.country?.country_code),
    }));

    setOptions(result);
  }, [searchQuery, geocodingCore]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      searchAddress();
    }, 500);

    return () => clearTimeout(timeout);
  }, [searchQuery, searchAddress]);

  return { data: options };
};
export default useAddressSearch;
