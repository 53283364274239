import { Box, Stack } from '@mui/material';
import React from 'react';
import { TypographyEllipsis } from '../../../../components/typography/TypographyEllipsis';

export type EnergeticImpactChartLegendItem = {
  color: string;
  label: string;
  isDashed?: boolean;
};

type LineChartLegendProps = {
  items: EnergeticImpactChartLegendItem[];
  isHidden?: boolean;
};

const EnergeticImpactChartLegend: React.FC<LineChartLegendProps> = ({ items, isHidden }) => {
  return (
    <Stack direction="row" spacing={3} justifyContent="center" sx={{ mt: 2, display: isHidden ? 'none' : 'flex' }}>
      {items.map(({ color, label, isDashed }, index) => (
        <Stack key={index} direction="row" spacing={1} alignItems="center">
          <Box
            sx={{
              width: 24,
              borderBottom: 0,
              borderRadius: 2,
              borderColor: color,
              borderStyle: isDashed ? 'dashed' : 'solid',
            }}
          />
          <TypographyEllipsis variant="body2" textTransform={'capitalize'} maxWidth={150} title={label}>
            {label}
          </TypographyEllipsis>
        </Stack>
      ))}
    </Stack>
  );
};

export default EnergeticImpactChartLegend;
