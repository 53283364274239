import { Box, CardHeader, Grid, Typography } from '@mui/material';
import { Units } from '@predium/utils';
import { useTranslation } from 'react-i18next';
import OverflowText from '../../../../components/OverflowText';
import BenchmarkLabel from '../../Components/BenchmarkLabel';
import { EnergyPathGraph } from '../../Visualization/EsgPathGraphs/EnergyPathGraph';

type Props = {
  energyPath: number[];
  energyTargetPath: number[];
  buildingLegendName: string;
  yearOfExcedance?: number | null;
  graphStartYear: number;
  benchmark: {
    selectedBenchmarkName: string;
    yearOfExcedanceChange: number | null;
  };
};

export function EnergyCrremAnalysis({
  energyPath,
  energyTargetPath,
  buildingLegendName,
  yearOfExcedance,
  graphStartYear,
  benchmark: { selectedBenchmarkName, yearOfExcedanceChange },
}: Props) {
  const { t } = useTranslation();

  const isDataAvailable = energyPath && energyPath.length > 0;

  return (
    <Grid container>
      <Grid container>
        <Grid item xs={12} md={2} lg={2}>
          <Box>
            <Box>
              <Typography variant="subtitle1" sx={{ mr: 1 }}>
                {t('General_YearOfExcedance')}
              </Typography>
              <OverflowText
                maxWidth={200}
                text={isDataAvailable ? yearOfExcedance ?? t('General_NoExceedanceLabel') : '–'}
                TypographyProps={{ variant: 'h3', sx: { mr: 1 } }}
              />
              {isDataAvailable && selectedBenchmarkName && (
                <BenchmarkLabel
                  change={yearOfExcedanceChange}
                  selectedBenchmarkName={selectedBenchmarkName}
                  unit={Units.year}
                  inverse
                />
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={10} lg={10}>
          <Box pl={3}>
            <CardHeader
              title={t('General_EnergyPath')}
              subheader={t('EsgAnalysis_CrremAnalysis-EnergyGraphCaption')}
              sx={{ p: 0, pl: 3 }}
            />
            <EnergyPathGraph
              energyPath={energyPath}
              energyTargetPath={energyTargetPath}
              buildingLegendName={buildingLegendName}
              graphStartYear={graphStartYear}
            />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}
