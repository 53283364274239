import { Stack, Typography } from '@mui/material';
import { FC } from 'react';
import Iconify from '../../../../components/Iconify';
import { PreLink } from '../../../../components/presentations/PreLink';

interface CostAvailableWithLinkProps {
  textKey: string;
  linkHref?: string;
  linkTextKey?: string;
}

export const CostExplanationWithLink: FC<CostAvailableWithLinkProps> = ({ textKey, linkHref, linkTextKey }) => {
  return (
    <Stack direction={{ xs: 'column', lg: 'row' }} color="text.primary" spacing={1}>
      <Typography variant="caption" sx={{ whiteSpace: 'normal' }}>
        {textKey + ' '}
        {linkHref && linkTextKey ? (
          <PreLink
            link={{
              href: linkHref,
              name: linkTextKey,
              external: true,
              icon: <Iconify icon={'mdi:open-in-new'} width={12} ml={0.5} />,
              sx: { ml: { xs: 0, lg: 0.5 } },
            }}
            TypographyProps={{ variant: 'caption', sx: { whiteSpace: 'nowrap' } }}
          />
        ) : null}
      </Typography>
    </Stack>
  );
};
