import { PARIS_READY_YEAR } from '@predium/client-lookup';
import React from 'react';
import Label from '../Label';

// Component for label of change in stranding years
interface StrandingDateLabelProps {
  strandingDateChange: number;
  strandingDateAfter: string | number | undefined;
  strandingDateText: string;
}

const StrandingChangeLabel: React.FC<StrandingDateLabelProps> = ({
  strandingDateChange,
  strandingDateAfter,
  strandingDateText,
}) => {
  // Condition to show the label if strandingDateChange is non-zero or strandingDateAfter isn't the Paris Ready year
  const showLabel = strandingDateChange !== 0 || strandingDateAfter !== PARIS_READY_YEAR.toString();

  return showLabel ? (
    <Label
      color={strandingDateChange > 0 ? 'success' : strandingDateChange === 0 ? 'default' : 'error'}
      sx={{ minWidth: '75px' }}
    >
      {strandingDateText}
    </Label>
  ) : null;
};

export { StrandingChangeLabel };
