/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Box, Stack } from '@mui/material';
import { area_type_enum, data_source_type_enum } from '@predium/enums';
import { Units } from '@predium/utils';
import { ComponentProps, ReactNode } from 'react';
import CustomDataSourceInput from '../../../../../components/custom-data-source-input';
import { mergeSx } from '../../../../../utils/cssStyles';
import useBuilding from '../../Context/useBuilding';
import { useCalculator } from '../Calculator/CalculatorProvider';
import { useValidation } from '../Validation/ValidationProvider';
import CalculationTooltip from './CalculationTooltip';
import Calculator from './Calculator';
import SquareMeterRowAlert from './SquareMeterRowAlert';
import SquareMeterRowLabel from './SquareMeterRowLabel';

type BaseProps = {
  actions?: ReactNode;
  source?: data_source_type_enum;
  areaType: area_type_enum;
  displayUnit?: boolean;
  minSquareMeters?: number;
  options?: {
    hoverEffectSupport?: boolean;
    hideEquationError?: boolean;
    hideActionPlaceholder?: boolean;
    displayErrorInline?: boolean;
    showCalculation?: boolean;
  };
} & ComponentProps<typeof SquareMeterRowLabel>;

export type EditableRowProps = {
  name: string;
} & BaseProps;

export type StaticRowProps = {
  value: number;
} & BaseProps;

type Props = EditableRowProps | StaticRowProps;

const SquareMeterRow = ({
  icon,
  actions,
  labels,
  source,
  titleProps,
  areaType,
  options,
  minSquareMeters = 0.01,
  displayUnit = true,
  ...props
}: Props) => {
  const { hasEditAccess } = useBuilding();
  const {
    hoverEffectSupport,
    hideEquationError,
    hideActionPlaceholder,
    displayErrorInline = true,
    showCalculation = false,
  } = options ?? {};

  const { triggerValidation, getAreaAlertVariant } = useValidation();
  const { getActiveStyles, getInfoTooltipProps } = useCalculator();

  const alertVariant = getAreaAlertVariant(areaType);

  const activeStyles = displayErrorInline ? getActiveStyles(areaType) : {};

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={mergeSx(
        {
          height: 40,
          px: 1,
          transition: 'background-color 0.2s',
          borderRadius: 0.5,
          ...(displayErrorInline &&
            alertVariant && {
              bgcolor: alertVariant + '.lighter',
            }),
          ...(hoverEffectSupport && {
            height: 48,
            px: 2,
            py: 0.5,
            '&:hover': {
              bgcolor: 'grey.100',
              '.input-value-root': {
                bgcolor: 'white',
              },
            },
          }),
        },
        activeStyles,
      )}
    >
      <SquareMeterRowLabel icon={icon} labels={labels} titleProps={titleProps} />
      <Stack direction="row" alignItems="center" spacing={1}>
        {!hideEquationError && <SquareMeterRowAlert areaType={areaType} inline={displayErrorInline} />}
        <CalculationTooltip {...getInfoTooltipProps(areaType)}>
          <Stack direction="row" alignItems="center" spacing={0.5}>
            {showCalculation && <Calculator areaType={areaType} />}
            <CustomDataSourceInput
              {...props}
              unit={displayUnit ? Units.area : undefined}
              source={source}
              actions={hasEditAccess && actions}
              onClickAway={triggerValidation}
              min={minSquareMeters}
              max={99_999_999}
            />
          </Stack>
        </CalculationTooltip>

        {!hideActionPlaceholder && !(hasEditAccess && actions) && <Box width="20px" />}
      </Stack>
    </Stack>
  );
};

export default SquareMeterRow;
