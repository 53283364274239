import { Box } from '@mui/material';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import SearchField from '../../../components/search/SearchField';
import SingleSelectFilter from '../../../components/search/SingleSelectFilter';
import { RoleOption } from '../../../utils/Roles';
import { TableToolbar } from '../Portfolio/PortfolioTableToolbar';

type Props = {
  optionsRole: RoleOption[];
  filterName: string;
  filterRole: RoleOption;
  onFilterName: (value: string) => void;
  onFilterRole: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export default function UserTableToolbar({
  filterName,
  filterRole,
  onFilterName,
  onFilterRole,
  optionsRole,
  children,
}: PropsWithChildren<Props>) {
  const { t } = useTranslation();
  return (
    <TableToolbar>
      <SearchField value={filterName} onChange={onFilterName} placeholder={t('General_SearchUser')} />

      <SingleSelectFilter
        items={optionsRole.map((option) => ({
          name: option.displayName,
          value: option.name,
        }))}
        selectedItem={filterRole.name}
        onChanged={(value) => onFilterRole({ target: { value } } as React.ChangeEvent<HTMLInputElement>)}
        label={t('General_Role')}
      />

      <Box sx={{ marginLeft: 'auto' }}>{children}</Box>
    </TableToolbar>
  );
}
