import { IconButton, MenuItem, TableCell } from '@mui/material';
import { COMMON_DATE_FORMATS, formatDateToLocale } from '@predium/utils';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FileThumbnail, { getFileFormat } from '../../../../components/FileThumbnail';
import Iconify from '../../../../components/Iconify';
import { SnackbarTimeouts } from '../../../../components/NotistackProvider';
import TableMoreMenu from '../../../../components/table/TableMoreMenu';
import TableRowWithHighlight, { hasRecentlyChanged } from '../../../../components/table/TableRowWithHighlight';
import { useLanguage } from '../../../../provider/LanguageProvider';
import { downloadFileUri } from '../../../../utils/createDownloadLink';
import useBuilding from '../Context/useBuilding';
import DeleteFile from './DeleteFile';
import { EnrichedFileType, getAvailableDataTypes } from './Documents';
import PreviewDialog, { canBePreviewed } from './PreviewDialog';

type Props = {
  file: EnrichedFileType;
};

export default function DocumentsRow({ file }: Props) {
  const { hasEditAccess } = useBuilding();

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { language } = useLanguage();

  const AVAILABLE_DATA_TYPES = getAvailableDataTypes(t);

  const [openPreviewFile, setOpenPreviewFile] = useState<boolean>(false);

  const fileName = file.name ?? '';
  const fileFormat = getFileFormat(fileName);

  const handleOpenPreview = () => {
    if (!fileFormat) {
      return;
    }

    setOpenPreviewFile(true);
  };

  const handleDownloadClick = async (downloadUrl: string, fileName?: string | null) => {
    try {
      await downloadFileUri(downloadUrl, fileName || 'file');
    } catch (e) {
      enqueueSnackbar(t('General_Download-error', { fileName: fileName ?? '' }), {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
      });
    }
  };

  return (
    <>
      <TableRowWithHighlight background={hasRecentlyChanged(file.created_at) ? 'success' : 'default'} key={file.id}>
        <TableCell sx={{ pl: 1 }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              disabled={!canBePreviewed(fileFormat)}
              style={{ marginRight: '1rem' }}
              onClick={handleOpenPreview}
            >
              <FileThumbnail format={fileFormat} />
            </IconButton>
            {fileName}
          </div>
        </TableCell>
        <TableCell>{AVAILABLE_DATA_TYPES.find((t) => t.key === file.type)?.label}</TableCell>
        <TableCell>{formatDateToLocale(file.created_at, COMMON_DATE_FORMATS.DAY_MONTH_YEAR_TIME, language)}</TableCell>
        <TableCell align="right">
          <TableMoreMenu
            actions={
              <>
                {canBePreviewed(fileFormat) && (
                  <MenuItem onClick={handleOpenPreview}>
                    <Iconify icon={'material-symbols:file-open-outline-rounded'} />
                    {t('General_Open')}
                  </MenuItem>
                )}
                <MenuItem onClick={() => handleDownloadClick(file.downloadUrl, fileName)}>
                  <Iconify icon={'ic:baseline-file-download'} />
                  {t('General_Download')}
                </MenuItem>

                {hasEditAccess && <DeleteFile file={file} />}
              </>
            }
          />
        </TableCell>
      </TableRowWithHighlight>
      <PreviewDialog
        previewFile={
          openPreviewFile && fileFormat
            ? {
                fileFormat,
                url: file.downloadUrl,
              }
            : null
        }
        onClose={() => setOpenPreviewFile(false)}
      />
    </>
  );
}
