import { useMutation } from '@apollo/client';
import { Box, MenuItem, Stack, Table, TableBody, TableCell, TableContainer, Tooltip, Typography } from '@mui/material';
import {
  DataCollectionBuildingSubBuildingFragment,
  DataCollectionSubBuildingConsumptionDraftFragment,
} from '@predium/client-graphql';
import { consumption_type_enum } from '@predium/enums';
import {
  translateConsumptionSourceTypeEnum,
  translateConsumptionTypeEnum_iconTooltip_dynamic,
} from '@predium/i18n/client';
import { COMMON_DATE_FORMATS, formatDateToLocale } from '@predium/utils';
import groupBy from 'lodash/groupBy';
import maxBy from 'lodash/maxBy';
import minBy from 'lodash/minBy';
import uniq from 'lodash/uniq';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import IconConsumptionElectricity from '../../../../assets/Icons/consumption/electricity';
import IconConsumptionHeating from '../../../../assets/Icons/consumption/heating';
import IconConsumptionWaste from '../../../../assets/Icons/consumption/waste';
import IconConsumptionWater from '../../../../assets/Icons/consumption/water';
import { ICONS } from '../../../../assets/icons';
import ColoredLabel, { IconColors } from '../../../../components/ColoredLabel';
import DeleteConfirmationModal from '../../../../components/DeleteConfirmationModal';
import DraftStatusLabel from '../../../../components/DraftStatusLabel';
import Iconify from '../../../../components/Iconify';
import InlineUser from '../../../../components/InlineUser';
import { SnackbarTimeouts } from '../../../../components/NotistackProvider';
import OverflowText from '../../../../components/OverflowText';
import TableHeadCustom, { HeadCell } from '../../../../components/table/TableHeadCustom';
import TableMoreMenu from '../../../../components/table/TableMoreMenu';
import TablePaginationStandard from '../../../../components/table/TablePaginationStandard';
import TableRowWithHighlight, { hasRecentlyChanged } from '../../../../components/table/TableRowWithHighlight';
import {
  DELETE_CONSUMPTION_DRAFT,
  DELETE_CONSUMPTION_INVOICE_DRAFT,
} from '../../../../graphql/DataCollection.mutations';
import useTable, { applySortFilter } from '../../../../hooks/useTable';
import DataCollectionSingleConsumptionDraftModal from '../../../../pages/DataCollection/DataCollectionSingleConsumptionDraftModal';
import { useLanguage } from '../../../../provider/LanguageProvider';
import { PATHS } from '../../../../routes';
import ConsumptionListToolbar from './SubBuildingConsumptionListToolbar';

export default function SubBuildingConsumptionDraftList({
  subBuilding,
}: {
  subBuilding: DataCollectionBuildingSubBuildingFragment;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [editConsumptionDraft, setEditConsumptionDraft] =
    useState<DataCollectionSubBuildingConsumptionDraftFragment | null>(null);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [invoiceDraftIdToDelete, setInvoiceDraftIdToDelete] = useState<number | null>(null);
  const [consumptionDraftIdToDelete, setConsumptionDraftIdToDelete] = useState<number | null>(null);
  const [fromToYearFilter, setFromToYearFilter] = useState<number | 'all'>('all');
  const [consumptionTypeFilter, setConsumptionTypeFilter] = useState<consumption_type_enum | 'all'>('all');

  const { language } = useLanguage();

  const TABLE_HEAD: HeadCell[] = [
    { id: 'from', label: t('General_ConsumptionPeriodFrom'), minWidth: 100 },
    { id: 'to', label: t('General_ConsumptionPeriodTo'), minWidth: 100 },
    {
      id: 'consumption_type_id',
      label: t('General_Category'),
      sortingDisabled: true,
      minWidth: 200,
    },
    { id: 'sourceType', label: t('General_EntryType'), minWidth: 150 },
    { id: 'provider', label: t('General_Provider'), minWidth: 300 },
    { id: 'createdByUser', label: t('General_CreatedBy'), minWidth: 200 },
    { id: 'draft_status', label: t('General_Status') },
    { id: 'actions_menu', label: '', sortingDisabled: true },
  ];

  const { page, setPage, rowsPerPage, setRowsPerPage, order, orderBy, onSort } = useTable({
    defaultOrderBy: 'createdAt',
    defaultOrder: 'desc',
    defaultRowsPerPage: 25,
  });

  const consumptionDrafts: DataCollectionSubBuildingConsumptionDraftFragment[] = subBuilding.consumption_drafts ?? [];
  const availableYearsWithConsumption = uniq(
    consumptionDrafts
      .flatMap(({ from, to }) => [from, to])
      .filter(Boolean)
      .map((date) => new Date(date!).getFullYear()) as number[],
  );

  const consumptionInvoiceDrafts = Object.entries(
    groupBy(
      consumptionDrafts.filter(({ consumption_invoice_draft }) => consumption_invoice_draft?.id),
      ({ consumption_invoice_draft }) => consumption_invoice_draft!.id,
    ),
  ).map(([invoiceId, consumptionsForInvoiceDraft]) => ({
    id: consumptionsForInvoiceDraft[0].id!,
    invoiceId,
    from: minBy(consumptionsForInvoiceDraft, ({ from }) => (from ? new Date(from).getTime() : undefined))?.from,
    to: maxBy(consumptionsForInvoiceDraft, ({ to }) => (to ? new Date(to).getTime() : undefined))?.to,
    consumptionTypes: uniq(consumptionsForInvoiceDraft.map(({ consumption_type_id }) => consumption_type_id))
      .filter(Boolean)
      .sort(),
    provider: consumptionsForInvoiceDraft[0].provider!,
    createdByUser: consumptionsForInvoiceDraft[0].created_by_user,
    createdAt: consumptionsForInvoiceDraft[0].created_at!,
    source_type_id: consumptionsForInvoiceDraft[0].source_type_id!,
    draftStatus: consumptionsForInvoiceDraft[0].consumption_invoice_draft?.draft_status,
  }));

  const consumptionDraftsWithoutInvoice = consumptionDrafts
    .filter(({ consumption_invoice_draft }) => !consumption_invoice_draft?.id)
    .map((consumptionDraft) => ({
      id: consumptionDraft.id,
      invoiceId: null as any,
      area_id: consumptionDraft.area_id,
      from: consumptionDraft.from,
      to: consumptionDraft.to,
      consumptionTypes: [consumptionDraft.consumption_type_id].filter(Boolean),
      provider: consumptionDraft.provider!,
      createdByUser: consumptionDraft.created_by_user,
      createdAt: consumptionDraft.created_at!,
      source_type_id: consumptionDraft.source_type_id!,
      consumption_type_id: consumptionDraft.consumption_type_id,
      energy_source_type_id: consumptionDraft.energy_source_type_id,
      sub_type_id: consumptionDraft.sub_type_id,
      cost: consumptionDraft.cost,
      value: consumptionDraft.value,
      consumption_waste_details_draft: consumptionDraft.consumption_waste_details_draft,
      draftStatus: undefined,
      display_unit_value: consumptionDraft.display_unit_value,
    }));

  const allConsumptionDraftsForTable = [...consumptionDraftsWithoutInvoice, ...consumptionInvoiceDrafts];

  const [deleteInvoiceDraft] = useMutation(DELETE_CONSUMPTION_INVOICE_DRAFT, {
    variables: {
      invoiceDraftId: invoiceDraftIdToDelete!,
    },
    onCompleted: () => {
      enqueueSnackbar(t('DataCollection_DraftBillDelete_SuccessMessage'), {
        variant: 'success',
        autoHideDuration: SnackbarTimeouts.Success,
      });
      setInvoiceDraftIdToDelete(null);
    },
    onError: () => {
      enqueueSnackbar(t('DataCollection_DraftBillDelete-Error'), {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
      });
      setInvoiceDraftIdToDelete(null);
    },
    update: (cache, { data }) => {
      if (data?.delete_consumption_invoice_draft_by_pk) {
        const normalizedId = cache.identify({
          id: data.delete_consumption_invoice_draft_by_pk.id,
          __typename: data.delete_consumption_invoice_draft_by_pk.__typename,
        });
        cache.evict({ id: normalizedId });

        // Delete all the consumption drafts too
        data.delete_consumption_invoice_draft_by_pk.consumption_drafts.forEach((consumptionDrafts) => {
          const normalizedConsumptionDraftId = cache.identify({
            id: consumptionDrafts.id,
            __typename: consumptionDrafts.__typename,
          });
          cache.evict({ id: normalizedConsumptionDraftId });
        });

        cache.gc();
      }
    },
  });

  const [deleteConsumptionMutation] = useMutation(DELETE_CONSUMPTION_DRAFT, {
    onCompleted: () => {
      enqueueSnackbar(t('DataCollection_ConsumptionDraftDelete-success'), {
        variant: 'success',
        autoHideDuration: SnackbarTimeouts.Success,
      });
    },
    onError: () => {
      enqueueSnackbar(t('DataCollection_ConsumptionDraftDelete-error'), {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
      });
    },
    update: (cache, { data }) => {
      if (data?.delete_consumption_draft_by_pk) {
        const normalizedId = cache.identify({
          id: data.delete_consumption_draft_by_pk.id,
          __typename: data.delete_consumption_draft_by_pk.__typename,
        });
        cache.evict({ id: normalizedId });
        cache.gc();
      }
    },
  });

  const filteredRows = applySortFilter({
    dataFilters: [
      fromToYearFilter !== 'all'
        ? (consumptions: (typeof allConsumptionDraftsForTable)[number]) =>
            (consumptions.from ? new Date(consumptions.from).getFullYear() === fromToYearFilter : false) ||
            (consumptions.to ? new Date(consumptions.to).getFullYear() === fromToYearFilter : false)
        : undefined,
      consumptionTypeFilter !== 'all'
        ? (consumptions: (typeof allConsumptionDraftsForTable)[number]) =>
            consumptions.consumptionTypes.includes(consumptionTypeFilter)
        : undefined,
    ].filter(Boolean) as ((consumptions: (typeof allConsumptionDraftsForTable)[number]) => boolean)[],
    data: allConsumptionDraftsForTable,
    orderOptions: {
      order,
      orderBy: function (item) {
        switch (orderBy) {
          // default sort but not available in the table itself.
          case 'createdAt':
            return new Date(item.createdAt).getTime();

          case 'createdByUser': {
            if (!item.createdByUser) {
              return '';
            }
            return `${item.createdByUser.first_name} ${item.createdByUser.last_name}`;
          }
          default:
            return (item as any)[orderBy] ?? '';
        }
      },
    },
  });
  const tableRows = filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const openConsumptionInvoiceDraft = (consumptionDraftId: number) => {
    navigate(
      PATHS.dataCollection.buildingConsumptionInvoiceDraft({
        id: subBuilding.building_id,
        draftId: consumptionDraftId,
      }),
    );
  };

  const handleClick = (consumptionDraft: (typeof tableRows)[number]) => () => {
    if (consumptionDraft.invoiceId) {
      openConsumptionInvoiceDraft(consumptionDraft.invoiceId);
    } else {
      setEditConsumptionDraft(consumptionDraft);
      setIsEditDialogOpen(true);
    }
  };

  return (
    <Box>
      <ConsumptionListToolbar
        consumptionTypeFilter={consumptionTypeFilter}
        fromToYearFilter={fromToYearFilter}
        setConsumptionTypeFilter={setConsumptionTypeFilter}
        setFromToYearFilter={setFromToYearFilter}
        availableYearsWithConsumption={availableYearsWithConsumption}
      />

      <TableContainer>
        <Table>
          <TableHeadCustom order={order} orderBy={orderBy} headLabel={TABLE_HEAD} onSort={onSort} />
          <TableBody>
            {tableRows.map((consumptionDraft) => {
              const {
                id,
                from,
                to,
                consumptionTypes,
                createdAt,
                createdByUser,
                provider,
                source_type_id,
                invoiceId,
                draftStatus,
              } = consumptionDraft;

              return (
                <TableRowWithHighlight
                  key={id}
                  hover
                  sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                  onClick={handleClick(consumptionDraft)}
                  background={hasRecentlyChanged(createdAt) ? 'success' : 'default'}
                >
                  <TableCell>
                    {from ? formatDateToLocale(from, COMMON_DATE_FORMATS.DAY_MONTH_YEAR, language) : '-'}
                  </TableCell>
                  <TableCell>
                    {to ? formatDateToLocale(to, COMMON_DATE_FORMATS.DAY_MONTH_YEAR, language) : '-'}
                  </TableCell>
                  <TableCell>
                    {consumptionTypes.length > 0 ? (
                      <Stack direction="row" gap={1}>
                        {consumptionTypes.map((type) => (
                          <Tooltip key={type} title={translateConsumptionTypeEnum_iconTooltip_dynamic(type!, t)}>
                            <span>
                              <ColoredLabel variant={getIconThemeColor(type!)}>
                                {getIconForConsumptionType(type!)}
                              </ColoredLabel>
                            </span>
                          </Tooltip>
                        ))}
                      </Stack>
                    ) : (
                      '-'
                    )}
                  </TableCell>
                  <TableCell>{translateConsumptionSourceTypeEnum(source_type_id)}</TableCell>
                  <TableCell component="th" scope="row">
                    {provider ? <OverflowText text={provider} maxWidth={'250px'}></OverflowText> : '-'}
                  </TableCell>
                  <TableCell>
                    <InlineUser firstName={createdByUser?.first_name} lastName={createdByUser?.last_name} />
                  </TableCell>
                  <TableCell>{draftStatus ? <DraftStatusLabel status={draftStatus} /> : '-'}</TableCell>

                  <TableCell
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    align="right"
                  >
                    <TableMoreMenu
                      actions={
                        <>
                          <MenuItem onClick={handleClick(consumptionDraft)}>
                            <Iconify icon={'mdi:pencil-outline'} />
                            {t('General_Edit')}
                          </MenuItem>

                          <MenuItem
                            onClick={() => {
                              if (invoiceId !== null) {
                                setInvoiceDraftIdToDelete(invoiceId);
                              } else {
                                setConsumptionDraftIdToDelete(id);
                              }
                            }}
                            sx={{ color: 'error.main' }}
                          >
                            <Iconify icon={ICONS.TRASH} />
                            {t('General_Delete')}
                          </MenuItem>
                        </>
                      }
                    ></TableMoreMenu>
                  </TableCell>
                </TableRowWithHighlight>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {filteredRows.length === 0 && (
        <Box sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center', p: 3 }}>
          <Typography>{t('DataCollection_NoConsumptionsAvailable')}</Typography>
        </Box>
      )}

      <TablePaginationStandard
        count={filteredRows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
      />

      {/* Open existing single consumption draft */}
      <DataCollectionSingleConsumptionDraftModal
        buildingId={subBuilding.building_id}
        subBuildingId={subBuilding.id}
        isOpen={isEditDialogOpen}
        consumptionSourceType={editConsumptionDraft?.source_type_id!}
        onClose={() => {
          setIsEditDialogOpen(false);
          // Set it delayed to avoid flickering
          setTimeout(() => {
            setEditConsumptionDraft(null);
          }, 250);
        }}
        consumptionDraftData={editConsumptionDraft!}
        setConsumptionDraftData={setEditConsumptionDraft}
      />

      <DeleteConfirmationModal
        open={Boolean(invoiceDraftIdToDelete)}
        title={t('DataCollection_DeleteModalTitle-InvoiceDraft', { count: 1 })}
        description={<Trans i18nKey={'DataCollection_DeleteModalDescription-InvoiceDraft'} />}
        onClose={() => {
          setInvoiceDraftIdToDelete(null);
        }}
        onDelete={async () => {
          await deleteInvoiceDraft();
          setInvoiceDraftIdToDelete(null);
        }}
      />
      <DeleteConfirmationModal
        open={Boolean(consumptionDraftIdToDelete)}
        title={t('DataCollection_DeleteModalTitle-ConsumptionDraft')}
        description={<Trans i18nKey={'DataCollection_DeleteModalDescription-ConsumptionDraft'} />}
        onClose={() => {
          setConsumptionDraftIdToDelete(null);
        }}
        onDelete={async () => {
          await deleteConsumptionMutation({
            variables: {
              consumptionDraftId: consumptionDraftIdToDelete!,
            },
          });
          setConsumptionDraftIdToDelete(null);
        }}
      />
    </Box>
  );
}

export function getIconForConsumptionType(consumptionType: consumption_type_enum) {
  return (
    {
      [consumption_type_enum.HEATING]: <IconConsumptionHeating />,
      [consumption_type_enum.ELECTRICITY]: <IconConsumptionElectricity />,
      [consumption_type_enum.WATER]: <IconConsumptionWater />,
      [consumption_type_enum.WASTE]: <IconConsumptionWaste />,
    } satisfies Record<consumption_type_enum, JSX.Element>
  )[consumptionType];
}

export function getIconThemeColor(consumptionType: consumption_type_enum): IconColors {
  return (
    {
      [consumption_type_enum.HEATING]: 'error',
      [consumption_type_enum.ELECTRICITY]: 'warning',
      [consumption_type_enum.WATER]: 'info',
      [consumption_type_enum.WASTE]: 'success',
    } satisfies Record<consumption_type_enum, IconColors>
  )[consumptionType];
}
