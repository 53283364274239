import { Card, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { DataCollectionEmissionCertificateFragment } from '@predium/client-graphql';
import { useTranslation } from 'react-i18next';
import Scrollbar from '../../../components/Scrollbar';
import Unavailable from '../../../components/Unavailable';
import TablePaginationStandard from '../../../components/table/TablePaginationStandard';
import useTable, { applySortFilter } from '../../../hooks/useTable';
import DataCollectionHead, { HeadLabel } from '../../../sections/DataCollection/Buildings/DataCollectionHead';

import useSessionData from '../../../hooks/useSessionData';
import EmissionCertificatesListRow, {
  getLinkedBuildingsCount,
  getStatusFromCertificate,
} from './EmissionCertificatesListRow';
import { EmissionCertificatesTabName } from './EmissionCertificatesTabs';

type EmissionCertificatesListProps = {
  selectedTab: EmissionCertificatesTabName;
  emissionCertificates: DataCollectionEmissionCertificateFragment[];
  openCreationModal: () => void;
};

export default function EmissionCertificatesList({
  selectedTab,
  emissionCertificates,
  openCreationModal,
}: EmissionCertificatesListProps) {
  const { t } = useTranslation();
  const { isAdmin } = useSessionData();

  const COLUMNS: HeadLabel[] = [
    { id: 'issuer', label: t('General_Issuer'), minWidth: 250 },
    { id: 'emission_factor', label: t('General_EmissionFactor'), minWidth: 180 },
    { id: 'primary_energy_factor', label: t('General_PrimaryEnergyFactor'), minWidth: 180, alignment: 'right' },
    { id: 'valid_until', label: t('General_ValidUntil'), minWidth: 160 },
    //We disable sorting here, because we already group by status in the tabs
    { id: 'status', label: t('General_Status'), sortingDisabled: true, minWidth: 100 },
  ];

  // add the number of linked buildings if the user is an admin + the action menu
  if (isAdmin) {
    COLUMNS.splice(3, 0, {
      id: 'linked_buildings',
      label: t('DataCollection_EmissionCertificates_LinkedBuildings'),
      minWidth: 200,
      alignment: 'right',
    });

    COLUMNS.push({ id: 'action_menu', label: '', sortingDisabled: true });
  }

  const {
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    order,
    orderBy,
    // filterName,
    // handleFilterByName,
    handleRequestSort,
  } = useTable({ defaultOrderBy: 'created_at', defaultOrder: 'desc', defaultRowsPerPage: 10 });

  const filteredRows = emissionCertificates.filter((item) => {
    if (selectedTab === 'all') {
      return true;
    }

    return getStatusFromCertificate(item) === selectedTab;
  });

  const sortedRows = applySortFilter({
    data: filteredRows,
    orderOptions: {
      order,
      orderBy: (item) => {
        if (orderBy === 'linked_buildings') {
          return getLinkedBuildingsCount(item, isAdmin);
        }

        return (item as any)[orderBy] ?? '';
      },
    },
  });

  // tracking the empty rows in the next page, to prevent the table from shifting
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredRows.length) : 0;

  const noData = filteredRows.length === 0;

  return (
    <>
      <Card>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <DataCollectionHead
                order={order}
                orderBy={orderBy}
                headLabel={COLUMNS}
                rowCount={sortedRows.length}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                  <EmissionCertificatesListRow key={row.id} row={row} />
                ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              {noData && <EmptyState openCreationModal={openCreationModal} />}
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePaginationStandard
          count={sortedRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
        />
      </Card>
    </>
  );
}

function EmptyState({ openCreationModal }: { openCreationModal: () => void }) {
  const { t } = useTranslation();
  const { isAdmin } = useSessionData();

  return (
    <TableBody>
      <TableRow>
        <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
          {isAdmin ? (
            <Unavailable
              iconString="gridicons:create"
              title={t('General_NoEmissionCertificatesFound')}
              subTitle={t('DataCollection_EmissionCertificates_AddCertificatePrompt')}
              onClick={() => {
                openCreationModal();
              }}
            />
          ) : (
            <Unavailable title={t('General_NoEmissionCertificatesFound')} />
          )}
        </TableCell>
      </TableRow>
    </TableBody>
  );
}
