import { GetOrgPreferencesQuery } from '@predium/client-graphql';
import { base_emission_factor_type_enum } from '@predium/enums';
import { translateBaseEmissionFactorTypeEnum, translateBaseEmissionFactorTypeEnum_dynamic } from '@predium/i18n/client';
import { t } from 'i18next';

type CRREMConfigurationGraphQL = NonNullable<
  GetOrgPreferencesQuery['org'][number]['org_preferences']
>['crrem_configs'][number];

// CRREMConfigurationGraphQL is a union type of CRREMCustomConfigurationObject and CRREMDefaultConfigurationObject
type CRREMConfigurationGraphQL_Custom = Extract<
  CRREMConfigurationGraphQL,
  { __typename: 'CRREMCustomConfigurationObject' }
>;

type CRREMConfigurationGraphQL_Default = Extract<
  CRREMConfigurationGraphQL,
  { __typename: 'CRREMDefaultConfigurationObject' }
>;

/**
 * Represents a CRREM configuration, which can be either a default or custom configuration.
 * Provides methods to interact with and retrieve information from the configuration.
 */
export class CRREMConfiguration {
  private constructor(private readonly graphQLObject: CRREMConfigurationGraphQL) {}

  /**
   * Creates a CRREMConfiguration instance from a GraphQL object.
   * @param graphQLObject - The GraphQL object representing the CRREM configuration.
   */
  static fromGraphQL(graphQLObject: CRREMConfigurationGraphQL): CRREMConfiguration {
    return new CRREMConfiguration(graphQLObject);
  }

  /**
   * Maps the configuration to a specific type based on its kind (default or custom).
   * @param fDefault - Function to handle default configurations.
   * @param fCustom - Function to handle custom configurations.
   */
  private map<T>(
    fDefault: (config: CRREMConfigurationGraphQL_Default) => T,
    fCustom: (config: CRREMConfigurationGraphQL_Custom) => T,
  ): T {
    if (this.graphQLObject.__typename === 'CRREMDefaultConfigurationObject') {
      return fDefault(this.graphQLObject);
    }
    return fCustom(this.graphQLObject);
  }

  /**
   * Retrieves the ID as a string. For a default configuration, it is the base
   * emission factor type. For a custom  configuration, it is the configuration
   * ID.
   */
  getStringId(): string {
    return this.map(
      (config) => config.emissionFactorType,
      (config) => String(config.id),
    );
  }

  /**
   * Retrieves the configuration ID if it is a custom configuration.
   */
  getConfigId(): number | undefined {
    return this.map(
      () => undefined,
      (config) => config.id,
    );
  }

  /**
   * Retrieves the base emission factor type if it is a default configuration.
   */
  getBaseEmissionFactor(): base_emission_factor_type_enum | undefined {
    return this.map(
      (config) => config.emissionFactorType,
      () => undefined,
    );
  }

  /**
   * Retrieves the title element of the configuration.
   */
  getTitleElement(): JSX.Element {
    return this.map(
      (config) => translateBaseEmissionFactorTypeEnum(config.emissionFactorType),
      (config) => {
        return <>{config.title}</>;
      },
    );
  }

  /**
   * Retrieves the title of the configuration.
   */
  getTitle(): string {
    return this.map(
      (config) => translateBaseEmissionFactorTypeEnum_dynamic(config.emissionFactorType, t),
      (config) => config.title,
    );
  }

  /**
   * Creates a default CRREMConfiguration instance.
   */
  static default(): CRREMConfiguration {
    return new CRREMConfiguration({
      __typename: 'CRREMDefaultConfigurationObject',
      setFor: {
        org: true,
        portfolios: [],
      },
      emissionFactorType: base_emission_factor_type_enum.CRREM,
    });
  }

  /**
   * Checks if the configuration is active for the organization.
   */
  isActiveForOrg(): boolean {
    return this.graphQLObject.setFor.org;
  }

  /**
   * Checks if the configuration is active for a specific portfolio.
   * @param id - The ID of the portfolio.
   */
  isActiveForPortfolio(id: number): boolean {
    return this.graphQLObject.setFor.portfolios.some((portfolio) => portfolio.id === id);
  }
}
