import { useTheme } from '@mui/material/styles';
import { AnalyzeScenarioOutputFragment } from '@predium/client-graphql';
import { Units, UnitsValue } from '@predium/utils';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { LineChartSeriesType } from '../../Visualization/EnergeticImpactLineChart';
import { EnergeticImpactChartLegendItem } from '../../Visualization/EnergeticImpactLineChartLegend';
import { ScenarioGraphType } from './ScenarioImpactGraphSelect';

export const getEnergyOrEmissionData = (
  analyzedScenario: AnalyzeScenarioOutputFragment | undefined,
  graphType: ScenarioGraphType,
  totalActions: number,
  colorMap: ColorMapType,
  seriesName: string,
  t: TFunction<'translation', undefined>,
  isScenarioComparison = false,
) => {
  const energyPaths = analyzedScenario?.energy_paths;
  const co2TargetPath = analyzedScenario?.co2TargetPath;
  const energyTargetPath = analyzedScenario?.energyTargetPath;
  const retrofitLineColor = graphType === 'investment' ? '' : colorMap[graphType];

  let statusQuoData: [number, number | null][] = [];
  let renovatedData: [number, number | null][] = [];
  let targetPathData: [number, number | null][] = [];
  let unit: UnitsValue = Units.energyPerAreaYear;
  let yAxisLabel = '';

  switch (graphType) {
    case 'final_energy':
      statusQuoData = energyPaths?.map((path) => [path.year, path.status_quo.final_energy ?? null]) ?? [];
      renovatedData =
        energyPaths?.map((path) => [path.year, path.renovated.final_energy ?? path.status_quo.final_energy ?? null]) ??
        [];
      targetPathData = energyTargetPath?.map((value, index) => [2020 + index, value]) || [];
      yAxisLabel = t('General_FinalEnergy');
      break;

    case 'co2_emissions':
      statusQuoData = energyPaths?.map((path) => [path.year, path.status_quo.co2_emissions ?? null]) ?? [];
      renovatedData =
        energyPaths?.map((path) => [
          path.year,
          path.renovated.co2_emissions ?? path.status_quo.co2_emissions ?? null,
        ]) ?? [];
      targetPathData = co2TargetPath?.map((value, index) => [2020 + index, value]) || [];
      unit = Units.co2EmissionsPerAreaYear;
      yAxisLabel = t('General_CO2Intensity');
      break;

    case 'primary_energy':
      statusQuoData = energyPaths?.map((path) => [path.year, path.status_quo.primary_energy ?? null]) ?? [];
      renovatedData =
        energyPaths?.map((path) => [
          path.year,
          path.renovated.primary_energy ?? path.status_quo.primary_energy ?? null,
        ]) ?? [];
      yAxisLabel = t('General_PrimaryEnergy');
      break;
  }

  const series: LineChartSeriesType[] = [
    {
      name: t('General_StatusQuo'),
      data: statusQuoData,
      color: colorMap.statusQuo,
      stroke: { width: 3, dashArray: 0 },
    },
    ...(totalActions > 0 || isScenarioComparison
      ? [{ name: seriesName, data: renovatedData, color: retrofitLineColor, stroke: { width: 3, dashArray: 0 } }]
      : []),
  ];

  const legendData: EnergeticImpactChartLegendItem[] = [
    ...(totalActions > 0 || isScenarioComparison ? [{ color: retrofitLineColor, label: seriesName }] : []),
    { color: colorMap.statusQuo, label: t('General_StatusQuo') },
  ];

  if (graphType !== 'primary_energy') {
    series.unshift({
      name: t('General_1_5CTargetPath'),
      data: targetPathData,
      color: colorMap.targetPath,
      stroke: { width: 1.5, dashArray: 4 },
    });
    legendData.push({ color: colorMap.targetPath, label: t('General_1_5CTargetPath'), isDashed: true });
  }

  const categories = energyPaths?.map((path) => path.year) ?? [];
  return { categories, series, unit, yAxisLabel, legendData, graphType, totalActions };
};

type ColorMapType = Record<Exclude<ScenarioGraphType, 'investment'>, string> & {
  statusQuo: string;
  targetPath: string;
};

export const useEnergyOrEmissionsData = (
  analyzedScenario: AnalyzeScenarioOutputFragment,
  graphType: ScenarioGraphType,
  totalActions: number,
): ReturnType<typeof getEnergyOrEmissionData> => {
  const theme = useTheme();
  const { t } = useTranslation();

  const seriesName = t('General_WithRetrofits');
  const colorMap = {
    primary_energy: theme.palette.scenarioImpactGraphColors.primaryEnergy,
    final_energy: theme.palette.scenarioImpactGraphColors.finalEnergy,
    co2_emissions: theme.palette.scenarioImpactGraphColors.co2Emissions,
    statusQuo: theme.palette.scenarioImpactGraphColors.statusQuo,
    targetPath: theme.palette.scenarioImpactGraphColors.targetPath,
  };

  return getEnergyOrEmissionData(analyzedScenario, graphType, totalActions, colorMap, seriesName, t);
};

export const useYearlyInvestmentData = (
  analyzedScenario: AnalyzeScenarioOutputFragment,
): {
  categories: number[];
  series: { name: string; data: number[] }[];
} => {
  const { t } = useTranslation();

  const costPaths = analyzedScenario.cost_paths;
  const categories = costPaths.map((item) => item.year);
  const series = [{ name: t('General_YearlyInvestment'), data: costPaths.map((item) => item.value) }];
  return { categories, series };
};
