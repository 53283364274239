import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Controller, useFormContext } from 'react-hook-form';

interface RHFCheckboxProps {
  name: string;
  label: string;
}

export default function RHFCheckbox({ name, label }: RHFCheckboxProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={false}
      render={({ field: { ref, ...field } }) => (
        <FormControlLabel
          key={name}
          control={<Checkbox checked={field.value} inputRef={ref} {...field} />}
          data-checkbox-label
          slotProps={{
            typography: {
              // @ts-expect-error
              'data-checkbox-label': true,
            },
          }}
          label={label}
        />
      )}
    />
  );
}
