import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Stack } from '@mui/material';
import { t } from 'i18next';
import { forwardRef, useImperativeHandle } from 'react';
import { useForm } from 'react-hook-form';
import { FormProvider, RHFTextField } from '../../../../../../components/hook-form';
import { useLanguage } from '../../../../../../provider/LanguageProvider';
import { getMinDate } from '../../../ActionPlan.utils';
import { NumberInputSx } from '../../../Components/ActionCustomStyles';
import { MaintenanceActionForm } from '../../CreateActions/CustomActions/MaintenanceAction';
import { MaintenanceActionSchema } from '../../CreateActions/validation-schema';

export type MaintenanceActionProps = {
  loading: boolean;
  implementationToComponent: JSX.Element;
  parameters: MaintenanceActionForm;
  resetSimulatedData: () => void;
};

const MaintenanceAction = forwardRef(
  ({ loading, parameters, implementationToComponent, resetSimulatedData }: MaintenanceActionProps, ref) => {
    const { language } = useLanguage();

    const formMethods = useForm<MaintenanceActionForm>({
      mode: 'onChange',
      reValidateMode: 'onChange',
      defaultValues: parameters,
      resolver: yupResolver(MaintenanceActionSchema(getMinDate(), language, false)),
    });

    const { setValue } = formMethods;

    useImperativeHandle(ref, () => ({
      methods: formMethods,
    }));

    return (
      <FormProvider methods={formMethods}>
        <Stack spacing={2}>
          <Box>
            <RHFTextField
              name="maintenance_action_parameter.custom_name"
              label={t('General_ActionTitle')}
              size={'small'}
              sx={{ ...NumberInputSx, pb: 0, mb: 0, my: 3 }}
              onChange={(e) => {
                setValue('maintenance_action_parameter.custom_name', e.target.value, {
                  shouldDirty: true,
                  shouldValidate: true,
                });
                resetSimulatedData();
              }}
              disabled={loading}
            />

            {implementationToComponent}
          </Box>
        </Stack>
      </FormProvider>
    );
  },
);

MaintenanceAction.displayName = 'MaintenanceAction';

export default MaintenanceAction;
