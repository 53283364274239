import { useTheme } from '@mui/material/styles';
import { translateUnitEnum_dynamic } from '@predium/i18n/client';
import { getFilename, Localize, UnitsValue } from '@predium/utils';
import { TFunction } from 'i18next';
import merge from 'lodash/merge';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../../../../provider/LanguageProvider';
import BaseOptionChart from '../../../../theme/apexcharts';
import addCustomCSVExportButtonToChartToolbar from '../../../../utils/addCustomCSVExportButtonToChartToolbar';
import { toCsvContent } from '../../../../utils/toCsvContent';
import { ScenarioGraphType } from '../Sections/ScenarioImpactGraphs/ScenarioImpactGraphSelect';
import LineChartLegend, { EnergeticImpactChartLegendItem } from './EnergeticImpactLineChartLegend';

function getCsvContent(
  dataToExport: { name: string; data: [number, number | null][] }[],
  t: TFunction<'translation', undefined>,
  localize: Localize,
  graphType: ScenarioGraphType,
  unit: string,
) {
  const isPrimaryEnergyGraph = graphType === 'primary_energy';

  const yearHeading = t('General_Year');
  const statusQuoHeading = `${t('General_StatusQuo')}  (${unit})`;
  const renovatedHeading = `${t('General_WithRetrofits')}  (${unit})`;
  const targetPathHeading = `${t('General_1_5CTargetPath')}  (${unit})`;

  // Extract all unique years from the data
  const allYears = Array.from(new Set(dataToExport.flatMap((series) => series.data.map(([year]) => year)))).sort(
    (a, b) => a - b,
  );

  // Generate CSV rows
  const csvRows = [
    [
      yearHeading,
      statusQuoHeading,
      renovatedHeading,
      ...(isPrimaryEnergyGraph ? [] : [targetPathHeading]), // Correctly handle conditional inclusion
    ],
    ...allYears.map((year) => [
      year,
      ...dataToExport.map((series) => {
        const dataPoint = series.data.find(([dataYear]) => dataYear === year);
        return localize.formatAsFloat(dataPoint ? dataPoint[1] : undefined);
      }),
    ]), // Data rows
  ];

  return toCsvContent(csvRows);
}

export type LineChartSeriesType = {
  name: string;
  data: [number, number | null][];
  color: string;
  stroke: Pick<ApexStroke, 'width' | 'dashArray'>;
};

type LineChartProps = {
  graphType: ScenarioGraphType;
  series: LineChartSeriesType[];
  yAxisLabel: string;
  unit: UnitsValue;
  legendData: EnergeticImpactChartLegendItem[];
  totalActions: number;
  ariaLabel: string;
  displayToolbar?: boolean;
  isHidden?: boolean;
};

const LineChart = ({
  graphType,
  series,
  yAxisLabel,
  unit,
  legendData,
  ariaLabel,
  displayToolbar = false,
  isHidden = false,
}: LineChartProps) => {
  const theme = useTheme();
  const { localize, language } = useLanguage();
  const { t } = useTranslation();

  const filename = getFilename([yAxisLabel], language);
  const unitTranslated = unit ? translateUnitEnum_dynamic(unit, t) : '';
  const strokeWidths = series.map((it) => it.stroke.width);
  const dashArray = series.map((it) => it.stroke.dashArray);

  // Merge base chart options with specific configurations
  const mergedChartOptions = merge(BaseOptionChart(), {
    chart: {
      type: 'line',
      height: 350,
      toolbar: {
        show: displayToolbar,
        export: {
          svg: { filename },
          png: { filename },
        },
      },
      events: {
        mounted: function (chart: any) {
          addCustomCSVExportButtonToChartToolbar(
            chart.el.id,
            getCsvContent(series, t, localize, graphType, unitTranslated),
            filename,
          );
        },
        updated: function (chart: any) {
          addCustomCSVExportButtonToChartToolbar(
            chart.el.id,
            getCsvContent(series, t, localize, graphType, unitTranslated),
            filename,
          );
        },
      },
    },
    stroke: {
      width: strokeWidths,
      curve: 'straight',
      dashArray,
    },
    markers: {
      size: 0,
      strokeWidth: 0,
    },
    xaxis: {
      title: {
        text: t('General_Year'),
        style: {
          fontSize: '12px',
          color: theme.palette.text.secondary,
          fontWeight: 500,
        },
      },
      tickAmount: 15,
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      title: {
        text: `${yAxisLabel} (${unitTranslated})`,
        style: {
          fontSize: '12px',
          color: theme.palette.text.secondary,
          fontWeight: 500,
        },
      },
      min: 0,
      labels: {
        formatter: (val: number) => localize.formatAsCompact(val),
      },
    },
    tooltip: {
      custom: ({ dataPointIndex, w }: { series: any; seriesIndex: number; dataPointIndex: number; w: any }) => {
        const xValue = w.config.series[0].data[dataPointIndex][0];
        const seriesNames = w.config.series.map((it: LineChartSeriesType) => it.name).reverse();
        const seriesDatapointValues = w.config.series
          .map((it: LineChartSeriesType) => it.data[dataPointIndex][1])
          .reverse();
        return `<div style="background-color: #fff;border: 1px solid #e0e0e0; border-radius: 5px; padding: 10px; font-size: 12px; color: #000">
                  <span style="font-weight:600; margin-bottom: 10px">${xValue}</span><br />
                  ${seriesNames
                    .map(
                      (name: string, index: number) =>
                        `<p style="margin: 8px 0">
                          <span style="
                          text-transform: capitalize; 
                          margin-right: 8px; 
                          display: inline-block; 
                          overflow: hidden; 
                          text-overflow: ellipsis; 
                          white-space: nowrap; 
                          max-width: 200px;
                          vertical-align: middle"
                          >
                            ${name}:
                          </span>
                          <span style="font-weight:600; vertical-align: middle">${localize.formatAsFloat(
                            seriesDatapointValues[index],
                            {
                              unit,
                            },
                          )}</span>
                        </p>`,
                    )
                    .join('')}
                </div>`;
      },
    },
    legend: {
      show: false, // Hide the default legend as we're using a custom one
    },
  });

  return (
    <>
      <ReactApexChart
        id={`'scenario_impact_'${graphType}_graph`}
        aria-label={ariaLabel}
        type="line"
        series={series}
        options={mergedChartOptions}
        height={350}
        style={{ display: isHidden ? 'none' : 'inherit' }}
      />
      <LineChartLegend items={legendData} isHidden={isHidden} />
    </>
  );
};

export default LineChart;
