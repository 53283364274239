import {
  Box,
  Button,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import { getSupportedSourceTypesForSystemType } from '@predium/client-lookup';
import { energy_system_type_enum } from '@predium/enums';
import {
  translateEnergySourceTypeEnum,
  translateEnergySystemTypeEnum,
  translateUnitEnum_dynamic,
} from '@predium/i18n/client';
import { Units } from '@predium/utils';
import sortBy from 'lodash/sortBy';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ICONS } from '../../../../../../assets/icons';
import { RHFNumberField, RHFSelect } from '../../../../../../components/hook-form';
import Iconify from '../../../../../../components/Iconify';

const FILTERED_CONSUMER_TYPES = sortBy(
  Object.values(energy_system_type_enum).filter(
    (systemType) => energy_system_type_enum.GENERAL !== systemType && energy_system_type_enum.SOLAR !== systemType,
  ),
  (type) => type !== energy_system_type_enum.HEATING,
);

const EnergyData = () => {
  const { watch, setValue, formState, trigger, getValues } = useFormContext();
  const { append, fields, remove } = useFieldArray({ name: 'energyData' });

  const { errors } = formState;

  const { t } = useTranslation();

  const checkAtLeastOneHeating = () => {
    if (formState.isSubmitted) {
      trigger('energyData');
    }
  };

  const handleAdd = () => {
    append({
      energyConsumer: null,
      energySource: null,
      finalEnergy: null,
    });
    checkAtLeastOneHeating();
  };

  const getEnergyConsumerOptions = (index: number) => {
    return getSupportedSourceTypesForSystemType(watch(`energyData[${index}].energyConsumer`)) ?? [];
  };

  const isEnergyConsumerSelected = (index: number) => {
    return watch(`energyData[${index}].energyConsumer`) !== undefined;
  };

  const energyConsumerMessage = errors?.energyData?.message;

  return (
    <Stack spacing={2}>
      <Typography variant="h5">{t('General_EnergyData')}</Typography>
      {fields.map((field, index) => (
        <Stack
          key={field.id}
          direction="row"
          spacing={2}
          border="1px solid"
          borderColor="divider"
          borderRadius={2}
          p={3}
          position="relative"
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <RHFSelect
                name={`energyData[${index}].energyConsumer`}
                label={t('General_EnergyConsumer')}
                onValueChange={(value) => {
                  checkAtLeastOneHeating();

                  const supportedSourceTypes = getSupportedSourceTypesForSystemType(value);
                  const selectedSourceType = getValues(`energyData[${index}].energySource`);

                  const isSelectedSourceTypeSupported = supportedSourceTypes?.includes(selectedSourceType);

                  if (!isSelectedSourceTypeSupported) {
                    setValue(`energyData[${index}].energySource`, undefined);
                  }
                }}
              >
                {Object.values(FILTERED_CONSUMER_TYPES).map((type) => (
                  <MenuItem key={type} value={type}>
                    {translateEnergySystemTypeEnum(type)}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Grid>
            <Grid item xs={6}>
              <RHFSelect
                disabled={!isEnergyConsumerSelected(index)}
                name={`energyData[${index}].energySource`}
                label={t('General_EnergySource')}
              >
                {getEnergyConsumerOptions(index).map((type) => (
                  <MenuItem key={type} value={type}>
                    {translateEnergySourceTypeEnum(type)}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Grid>
            <Grid item xs={12}>
              <RHFNumberField
                valueAsText
                name={`energyData[${index}].finalEnergy`}
                label={t('General_FinalEnergy')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {translateUnitEnum_dynamic(Units.energyPerAreaYear, t)}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <IconButton
            sx={{
              position: 'absolute',
              top: -20,
              right: -18,
            }}
            onClick={() => {
              remove(index);
              checkAtLeastOneHeating();
            }}
          >
            <Iconify icon={ICONS.REMOVE} />
          </IconButton>
        </Stack>
      ))}
      {energyConsumerMessage && (
        <Grid sx={{ ml: 4 }}>
          <FormHelperText error>{energyConsumerMessage.toString()}</FormHelperText>
        </Grid>
      )}
      <Box>
        <Button
          variant="outlined"
          onClick={handleAdd}
          startIcon={<Iconify icon={ICONS.ADD} />}
          sx={{ mr: 1, borderColor: 'divider' }}
        >
          {t('BuildingCreation_AddEnergyData')}
        </Button>
      </Box>
    </Stack>
  );
};

export default EnergyData;
