import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react'; // Import useRef
import { useTranslation } from 'react-i18next';
import { SnackbarTimeouts } from '../../../../components/NotistackProvider';
import ExportPdfDialog, { PdfFormData } from '../../../../components/documents/export-pdf/ExportPdfDialog';
import { QUEUE_EXPORT_ACTION_PLAN_PDF } from '../../../../graphql/ActionPlanning.queries';
import { useExportedFile } from '../../../../hooks/useExportedFile';
import { ActionPlanType } from './ActionPlanActionMenu';

type Props = {
  handleClose: () => void;
  open: boolean;
  actionPlan: ActionPlanType;
};

const ActionPlanExportPdf: React.FC<Props> = ({ open, handleClose, actionPlan }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const handleError = () => {
    enqueueSnackbar(t('ActionPlanning_ActionPlan-PdfExportError'), {
      variant: 'error',
      autoHideDuration: SnackbarTimeouts.Error,
    });

    handleClose();
  };

  const handleSuccess = () => {
    enqueueSnackbar(t('ActionPlanning_ActionPlan-PdfExportSuccess'), {
      variant: 'success',
      autoHideDuration: SnackbarTimeouts.Success,
    });

    handleClose();
  };

  const { trackDocument, untrackDocument, isLoading } = useExportedFile({
    onSuccess: handleSuccess,
    onError: handleError,
  });

  const [createPdfExport, { loading }] = useMutation(QUEUE_EXPORT_ACTION_PLAN_PDF, {
    onCompleted: (data) => {
      if (!data.queueExportActionPlanAsPdf.documentId) {
        return handleError();
      }

      trackDocument(data.queueExportActionPlanAsPdf.documentId);
    },
  });

  const onSubmit = async (data: PdfFormData) => {
    createPdfExport({
      variables: {
        actionPlanId: actionPlan.id,
        // to avoid sending empty strings
        title: data.title || undefined,
        description: data.description || undefined,
      },
    });
  };

  useEffect(() => {
    if (!actionPlan) {
      untrackDocument();
    }
  }, [actionPlan, untrackDocument]);

  return (
    <ExportPdfDialog
      open={open}
      loading={loading || isLoading}
      onFormSubmit={(data) => onSubmit(data)}
      onCancel={handleClose}
      dialogTitle={t('ActionPlanning_ActionPlan-ExportAsPdf')}
      descriptions={[
        t('ActionPlanning_ActionPlan-ExportPdfDescriptionFirst'),
        t('ActionPlanning_ActionPlan-ExportPdfDescriptionSecond'),
      ]}
    />
  );
};

export default ActionPlanExportPdf;
