import { sustainaccount_climate_risk_severity_enum } from '@predium/enums';
import { TFunction } from 'i18next';
import { Trans } from 'react-i18next';

export const translateSustainaccountClimateRiskSeverityEnum = (
  enumValue: sustainaccount_climate_risk_severity_enum,
) => {
  switch (enumValue) {
    case sustainaccount_climate_risk_severity_enum.VERY_LOW:
      return <Trans i18nKey="Enum_SustainaccountClimateRiskSeverityEnum-VERY_LOW" />;
    case sustainaccount_climate_risk_severity_enum.LOW:
      return <Trans i18nKey="Enum_SustainaccountClimateRiskSeverityEnum-LOW" />;
    case sustainaccount_climate_risk_severity_enum.MEDIUM:
      return <Trans i18nKey="Enum_SustainaccountClimateRiskSeverityEnum-MEDIUM" />;
    case sustainaccount_climate_risk_severity_enum.HIGH:
      return <Trans i18nKey="Enum_SustainaccountClimateRiskSeverityEnum-HIGH" />;
    case sustainaccount_climate_risk_severity_enum.VERY_HIGH:
      return <Trans i18nKey="Enum_SustainaccountClimateRiskSeverityEnum-VERY_HIGH" />;
  }
};

export const translateSustainaccountClimateRiskSeverityEnum_dynamic = (
  enumValue: sustainaccount_climate_risk_severity_enum,
  t: TFunction<'translation', undefined>,
) => {
  switch (enumValue) {
    case sustainaccount_climate_risk_severity_enum.VERY_LOW:
      return t('Enum_SustainaccountClimateRiskSeverityEnum-VERY_LOW');
    case sustainaccount_climate_risk_severity_enum.LOW:
      return t('Enum_SustainaccountClimateRiskSeverityEnum-LOW');
    case sustainaccount_climate_risk_severity_enum.MEDIUM:
      return t('Enum_SustainaccountClimateRiskSeverityEnum-MEDIUM');
    case sustainaccount_climate_risk_severity_enum.HIGH:
      return t('Enum_SustainaccountClimateRiskSeverityEnum-HIGH');
    case sustainaccount_climate_risk_severity_enum.VERY_HIGH:
      return t('Enum_SustainaccountClimateRiskSeverityEnum-VERY_HIGH');
  }
};
