import { useMutation, useQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { PropsWithChildren, createContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SnackbarTimeouts } from '../../components/NotistackProvider';
import { UPDATE_ORG_PREFERENCE_FOR_ECONOMIC_UNIT } from '../../graphql/common.mutations';
import { GET_ORG_PREFERENCES } from '../../graphql/common.queries';
import useSessionData from '../../hooks/useSessionData';
import { CRREMConfiguration } from './CRREMConfiguration';

type OrgPreferencesContextProps = {
  economicUnitsToggled: boolean;
  activeCRREMConfiguration: CRREMConfiguration;
  crremConfigurations: CRREMConfiguration[];
  toggleEconomicUnits: () => void;
  loading: boolean;
};

const initialCRREMConfiguration: CRREMConfiguration = CRREMConfiguration.default();

const initialState: OrgPreferencesContextProps = {
  economicUnitsToggled: false,
  toggleEconomicUnits: () => {},
  crremConfigurations: [initialCRREMConfiguration],
  activeCRREMConfiguration: initialCRREMConfiguration,
  loading: false,
};

const OrgPreferencesContext = createContext<OrgPreferencesContextProps>(initialState);

type Props = PropsWithChildren<{}>;

function OrgPreferencesContextProvider({ children }: Props) {
  const { t } = useTranslation();
  const [economicUnitsToggled, setEconomicUnitsToggled] = useState<boolean>(initialState.economicUnitsToggled);
  const [activeCRREMConfiguration, setActiveCRREMConfiguration] = useState<CRREMConfiguration>(
    initialState.activeCRREMConfiguration,
  );
  const [crremConfigurations, setCRREMConfigurations] = useState<CRREMConfiguration[]>(
    initialState.crremConfigurations,
  );

  const { org } = useSessionData();
  const { enqueueSnackbar } = useSnackbar();

  const { loading } = useQuery(GET_ORG_PREFERENCES, {
    onCompleted: (data) => {
      const orgPreference = data.org[0].org_preferences;

      if (!orgPreference) {
        throw new Error('Org preferences not found');
      }

      const economicUnitEnabled = orgPreference.economic_unit_enabled;
      const configurations = orgPreference.crrem_configs.map(CRREMConfiguration.fromGraphQL);
      const crremConfiguration = configurations.find((config) => config.isActiveForOrg());

      if (!crremConfiguration) {
        throw new Error('CRREM configuration not found');
      }

      setCRREMConfigurations(configurations);
      setActiveCRREMConfiguration(crremConfiguration);
      setEconomicUnitsToggled(economicUnitEnabled);
    },
  });

  const [toggleEconomicUnit] = useMutation(UPDATE_ORG_PREFERENCE_FOR_ECONOMIC_UNIT, {
    refetchQueries: [GET_ORG_PREFERENCES],
    onError: () => {
      enqueueSnackbar(t('General_FailedToSaveChanges'), {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
      });
    },
    onCompleted: () => {
      enqueueSnackbar(t('General_ChangesSavedSuccessfully'), {
        variant: 'success',
        autoHideDuration: SnackbarTimeouts.Success,
      });
    },
  });

  const toggleEconomicUnits = () => {
    if (org === undefined) {
      throw new Error('Org not found');
    }

    toggleEconomicUnit({
      variables: {
        economicUnitEnabled: !economicUnitsToggled,
        orgId: org.id,
      },
    });
  };

  return (
    <OrgPreferencesContext.Provider
      value={{
        economicUnitsToggled,
        toggleEconomicUnits,
        activeCRREMConfiguration,
        crremConfigurations,
        loading,
      }}
    >
      {children}
    </OrgPreferencesContext.Provider>
  );
}

export { OrgPreferencesContext, OrgPreferencesContextProvider };
