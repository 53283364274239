/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Button, MenuItem } from '@mui/material';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import { styled } from '@mui/material/styles';
import { CreatedEnergyCertificateDraftFragment } from '@predium/client-graphql';
import { building_state_enum } from '@predium/enums';
import { translateBuildingStateEnum_dynamic } from '@predium/i18n/client';
import { useSnackbar } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { BUILDING_CREATION_ICONS, ICONS } from '../../../assets/icons';
import Iconify from '../../../components/Iconify';
import { SnackbarTimeouts } from '../../../components/NotistackProvider';
import { WarningModal } from '../../../components/WarningModal';
import ActionMoreMenu from '../../../components/common/ActionMoreMenu';
import PreDialog from '../../../components/presentations/PreDialog/PreDialog';
import MultiSelectFilterWithSearch from '../../../components/search/MultiSelectFilterWithSearch';
import SearchField from '../../../components/search/SearchField';
import SingleSelectFilter from '../../../components/search/SingleSelectFilter';
import usePermissions from '../../../hooks/usePermissions';
import useSessionData from '../../../hooks/useSessionData';
import { PATHS } from '../../../routes/paths';
import UploadEnergyCertificateDraftModalCommon from '../Building/Drafts/UploadEnergyCertificateDraftModalCommon';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

// ----------------------------------------------------------------------

type Props = {
  onFilterName?: (value: string) => void;
  selectedPortfolios: string[];
  portfolioOptions: string[];
  selectedStatus?: building_state_enum | 'all';
  /**
   * Will open modal to create subBuilding if true.
   * We use this to dynamically open the modal after navigation.
   * Defaults to false.
   */
  shouldOpenCreateModal?: boolean;
  handleStatusChange?: (event: building_state_enum | 'all') => void;
  setCurrentTab?: () => void;
  contextType?: 'building' | 'energyCertificateDraft';
  tempPortfolioSelection: string[];
  handlePortfolioSelectionChange: (values: (string | number)[]) => void;
  applyPortfolioSelection: () => void;
  clearPortfolioSelection: () => void;
  applyPortfolioDisabled: boolean;
  clearPortfolioDisabled: boolean;
  filterName?: string;
};

export default function DataCollectionToolbar({
  onFilterName,
  selectedPortfolios,
  portfolioOptions,
  selectedStatus,
  shouldOpenCreateModal = false,
  handleStatusChange,
  setCurrentTab,
  contextType = 'building',
  tempPortfolioSelection,
  handlePortfolioSelectionChange,
  applyPortfolioSelection,
  clearPortfolioSelection,
  applyPortfolioDisabled,
  clearPortfolioDisabled,
  filterName = '',
}: Props) {
  const { t } = useTranslation();
  const {
    serverSideFeatureFlags: { BUILDING_CREATION },
  } = useSessionData();
  const { hasPortfolioWithWriteAccess } = usePermissions();
  const [uploadEnergyCertificateDraftModalOpen, setUploadEnergyCertificateDraftModalOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [warningModalOpen, setWarningModalOpen] = useState(false);
  const createdEnergyCertificateDrafts = useRef<CreatedEnergyCertificateDraftFragment[]>([]);
  const navigate = useNavigate();

  const statusOptions = [
    {
      name: t('General_AllBuildingStates'),
      value: 'all',
    },
    ...Object.keys(building_state_enum).map((status) => ({
      value: status,
      name: translateBuildingStateEnum_dynamic(building_state_enum[status as building_state_enum], t),
    })),
  ];

  useEffect(() => {
    if (shouldOpenCreateModal) {
      setUploadEnergyCertificateDraftModalOpen(true);
    }
  }, [shouldOpenCreateModal, setUploadEnergyCertificateDraftModalOpen]);

  const onEnergyCertificateDraftsAdded = (energyCertificateDraftIds: number[]) => {
    if (energyCertificateDraftIds.length > 0) {
      setUploadEnergyCertificateDraftModalOpen(false);
      createdEnergyCertificateDrafts.current = [];

      if (energyCertificateDraftIds.length === 1) {
        // Navigate to the newly created energyCertificateDraft
        navigate(PATHS.dataCollection.energyCertificateDraft({ id: energyCertificateDraftIds[0] }));
      } else {
        //@ts-ignore
        setCurrentTab();
      }

      enqueueSnackbar(t('General_DataUploadedSuccessMessage'), {
        variant: 'success',
        autoHideDuration: SnackbarTimeouts.Success,
      });
    }
  };

  return (
    <RootStyle>
      <Stack direction="row" sx={{ width: '100%' }} gap={2} alignItems="center">
        {onFilterName && (
          <SearchField
            value={filterName}
            onChange={onFilterName}
            placeholder={
              contextType !== 'building'
                ? t('General_SearchAddressPlaceholder')
                : t('General_SearchBuildingPlaceholder')
            }
          />
        )}
        <MultiSelectFilterWithSearch
          label={t('General_AllPortfolios')}
          selectedLabel={t('General_Portfolio_other')}
          items={portfolioOptions.map((portfolio) => ({
            value: portfolio,
            name: portfolio,
          }))}
          tempSelection={tempPortfolioSelection}
          handleSelectionChange={handlePortfolioSelectionChange}
          applySelection={applyPortfolioSelection}
          clearSelection={clearPortfolioSelection}
          isApplyDisabled={applyPortfolioDisabled}
          isClearDisabled={clearPortfolioDisabled}
          noResultsText={t('NoExistingPortfolios_info')}
          selectedFilter={selectedPortfolios}
          searchable
        />
        {selectedStatus && handleStatusChange && (
          <SingleSelectFilter
            label={t('General_Status')}
            selectedItem={selectedStatus}
            onChanged={(value) => handleStatusChange?.(value as building_state_enum)}
            items={statusOptions}
          />
        )}
        {hasPortfolioWithWriteAccess && (
          <>
            {!BUILDING_CREATION && (
              <Button
                fullWidth={false}
                variant="contained"
                onClick={() => {
                  setUploadEnergyCertificateDraftModalOpen(true);
                }}
                sx={{ marginLeft: 'auto' }}
                startIcon={<Iconify icon={'ic:baseline-plus'} />}
              >
                {t('General_AddBuilding')}
              </Button>
            )}
            {BUILDING_CREATION && (
              <ActionMoreMenu
                disabledArrow
                actions={
                  <>
                    <MenuItem onClick={() => setUploadEnergyCertificateDraftModalOpen(true)}>
                      <Iconify icon={ICONS.FILEUPLOADOUTLINE} />
                      {t('BuildingCreation_WithEnergyCertificate')}
                    </MenuItem>
                    <MenuItem onClick={() => navigate(PATHS.dataCollection.buildingDraftNew())}>
                      <Iconify icon={BUILDING_CREATION_ICONS.DRAW_PEN} />
                      {t('General_Manually')}
                    </MenuItem>
                  </>
                }
                variant="button"
                icon={ICONS.PLUS}
                sx={{ marginLeft: 'auto', flexShrink: 0 }}
                buttonText={t('General_AddBuilding')}
                buttonVariant="contained"
              />
            )}
          </>
        )}
      </Stack>

      <WarningModal
        title={t('General_Note')}
        open={warningModalOpen}
        description={t('DataCollection_DraftsUpload_WarningMessage')}
        onAcknowledge={() => {
          setWarningModalOpen(false);

          //@ts-ignore
          setCurrentTab();
        }}
        onClose={() => {
          setWarningModalOpen(false);

          //@ts-ignore
          setCurrentTab();
        }}
        buttonText={t('General_Ok')}
      />
      <PreDialog
        type="definedByChildren"
        fullWidth
        open={uploadEnergyCertificateDraftModalOpen}
        onClose={() => {
          if (createdEnergyCertificateDrafts.current.length > 0) {
            setWarningModalOpen(true);
            createdEnergyCertificateDrafts.current = [];
          }
          setUploadEnergyCertificateDraftModalOpen(false);
        }}
      >
        <UploadEnergyCertificateDraftModalCommon
          onEnergyCertificateDraftsAdded={onEnergyCertificateDraftsAdded}
          createdEnergyCertificateDrafts={createdEnergyCertificateDrafts}
          selectedPortfolio={selectedPortfolios[0]}
        />
      </PreDialog>
    </RootStyle>
  );
}
