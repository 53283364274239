import { IconButton, InputAdornment, SxProps, TextField, Theme } from '@mui/material';
import Iconify from '../Iconify';

type SearchFieldProps = {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  sx?: SxProps<Theme>;
};

const SearchField: React.FC<SearchFieldProps> = ({ value, onChange, placeholder, sx }) => {
  return (
    <TextField
      fullWidth
      value={value}
      onChange={(event) => onChange(event.target.value)}
      placeholder={placeholder}
      autoComplete="off"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
          </InputAdornment>
        ),
        endAdornment: value ? (
          <InputAdornment position="end">
            <IconButton onClick={() => onChange('')} sx={{ color: 'text.secondary', padding: '4px' }}>
              <Iconify icon="eva:close-fill" width={18} height={18} />
            </IconButton>
          </InputAdornment>
        ) : null,
      }}
      sx={{
        maxWidth: '220px',
        '.MuiOutlinedInput-root': {
          paddingRight: '4px',
        },
        '.MuiOutlinedInput-input': {
          boxSizing: 'border-box',
          height: 36,
          py: 1,
        },
        ...sx,
      }}
    />
  );
};

export default SearchField;
