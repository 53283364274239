import { alpha, Chip, useTheme } from '@mui/material';
import { translateSustainaccountClimateRiskSeverityEnum_dynamic } from '@predium/i18n/client';
import { ensureDefined, maybeEnum } from '@predium/utils';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../../../../../provider/LanguageProvider';
import { RiskSeverity } from '../mapping';
import { RiskWithScenario, ViewMode } from '../types';

type Props = {
  riskPeriod: RiskWithScenario['periods'][number] | undefined;
  viewMode: ViewMode;
};

const SeverityChip = ({ riskPeriod, viewMode }: Props) => {
  const { t } = useTranslation();
  const { localize } = useLanguage();
  const theme = useTheme();

  const riskSeverity = maybeEnum(RiskSeverity, riskPeriod?.severity);
  const riskPercentage = riskPeriod?.percentage ?? 0;

  const riskColor = useMemo((): { bg: string; text: string } => {
    switch (riskSeverity) {
      case RiskSeverity.VERY_LOW:
        return { bg: theme.palette.grey[100], text: theme.palette.text.secondary };
      case RiskSeverity.LOW:
        return { bg: alpha(theme.palette.success.main, 0.16), text: theme.palette.success.darker };
      case RiskSeverity.MEDIUM:
        return { bg: alpha(theme.palette.warning.main, 0.16), text: theme.palette.warning.darker };
      case RiskSeverity.HIGH:
        return { bg: alpha(theme.palette.error.main, 0.08), text: theme.palette.error.dark };
      case RiskSeverity.VERY_HIGH:
        return { bg: alpha(theme.palette.error.main, 0.16), text: theme.palette.error.darker };
      default:
        return { bg: 'transparent', text: theme.palette.text.secondary };
    }
  }, [riskSeverity, theme]);

  const chipLabel = useMemo(() => {
    if (!riskPeriod) {
      return 'N/A';
    }

    switch (viewMode) {
      case ViewMode.PERCENTAGE:
        return localize.formatAsPercentage(riskPercentage);
      case ViewMode.RISK_CATEGORY:
        return translateSustainaccountClimateRiskSeverityEnum_dynamic(riskSeverity!, t);
      case ViewMode.MONETARY:
        return localize.formatAsCurrency(ensureDefined(riskPeriod.valuationAtRisk));
    }
  }, [riskPeriod, viewMode, localize, riskPercentage, riskSeverity, t]);

  return (
    <Chip
      title={riskPeriod ? chipLabel : t('General_NotAvailable')}
      label={chipLabel}
      size="small"
      sx={{
        width: '80px',
        borderRadius: 1,
        height: '24px',
        bgcolor: riskColor.bg,
        color: riskColor.text,
        '& .MuiChip-label': {
          px: 0.5,
          fontSize: '0.75rem',
          fontWeight: 700,
          width: '100%',
          textAlign: 'center',
        },
      }}
    />
  );
};

export default SeverityChip;
