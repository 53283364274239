import { Box, List, ListItem, Stack, SxProps, Theme, Tooltip, Typography } from '@mui/material';
import { Units } from '@predium/utils';
import sumBy from 'lodash/sumBy';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ICONS } from '../../../../../../assets/icons';
import { TechnologyIcons } from '../../../../../../assets/images';
import Iconify from '../../../../../../components/Iconify';
import { useLanguage } from '../../../../../../provider/LanguageProvider';
import { getEnergySourceNameForPartialRenovations } from '../../../../Scenario/scenarios.util';
import { getSystemLabel } from '../../../ActionPlan.utils';
import AffectedPartsTable from '../../../Components/AffectedPartsTable';
import { ConsumerPartialRenovationOption } from '../../CreateAction';
import PartialRenovationOptionSubtext from '../PartialRenovationOptionSubtext';

type Props = {
  affectedParts: ConsumerPartialRenovationOption[];
  sx?: SxProps<Theme>;
};

export default function AffectedConsumerPartsTable({ affectedParts, sx }: Props) {
  const MAX_AFFECTED_PARTS_TO_SHOW = 3;
  const disabled = affectedParts.length <= MAX_AFFECTED_PARTS_TO_SHOW;
  const [expanded, setExpanded] = useState(affectedParts.length <= MAX_AFFECTED_PARTS_TO_SHOW);
  const { t } = useTranslation();
  const { localize } = useLanguage();
  const useIndexForOptions = affectedParts.some((part, index) =>
    affectedParts.some(
      (otherPart, otherIndex) =>
        index !== otherIndex &&
        part.energy_consumer_technology_type_id === otherPart.energy_consumer_technology_type_id,
    ),
  );

  return (
    <AffectedPartsTable
      expanded={expanded}
      disabled={disabled}
      setExpanded={setExpanded}
      sx={sx}
      summary={
        <Stack sx={{ width: '100%' }}>
          <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
            <Typography variant="subtitle1">{t('General_AffectedParts')}</Typography>
            {!disabled && (
              <Iconify
                icon={ICONS.CHEVRON_DOWN}
                width={16}
                height={16}
                sx={{ color: 'text.secondary', transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)' }}
              />
            )}
          </Stack>
          {!expanded && (
            <Typography variant="body2" mt={2}>
              {affectedParts
                ?.slice(0, MAX_AFFECTED_PARTS_TO_SHOW)
                .map((part) => {
                  if (!part || !part?.id) {
                    return '';
                  }
                  return `${getSystemLabel(part, useIndexForOptions, t)}`;
                })
                .join(', ')}
              {affectedParts.length > MAX_AFFECTED_PARTS_TO_SHOW && (
                <Tooltip
                  placement="top"
                  arrow
                  title={
                    <List dense sx={{ p: 0 }}>
                      {affectedParts.slice(MAX_AFFECTED_PARTS_TO_SHOW).map((part) => {
                        if (!part || !part?.id) {
                          return '';
                        }
                        return (
                          <ListItem sx={{ listStyleType: 'none' }}>
                            {getSystemLabel(part, useIndexForOptions, t)}
                          </ListItem>
                        );
                      })}
                    </List>
                  }
                >
                  <Box component={'span'}>{`, + ${affectedParts.length - MAX_AFFECTED_PARTS_TO_SHOW}`}</Box>
                </Tooltip>
              )}
            </Typography>
          )}
        </Stack>
      }
      content={affectedParts.map((part) => {
        const label = getSystemLabel(part, useIndexForOptions, t);

        const constructionYear = part.construction_year;
        const energyFinal = localize.formatAsFloat(sumBy(part.energy_system_consumer_routes, (r) => r.energy_final));

        return (
          <Stack direction={'column'} width={'100%'} sx={{ py: 0.5 }}>
            <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }}>
              {label}
            </Typography>
            <Stack direction={'row'} alignItems={'center'} gap={1}>
              <PartialRenovationOptionSubtext
                title={t('General_EnergySource')}
                icon={<Box component="img" src={TechnologyIcons.energySourceV2} width={16} height={16} />}
                value={getEnergySourceNameForPartialRenovations(part, t)}
              />
              <PartialRenovationOptionSubtext
                title={t('General_FinalEnergy')}
                icon={ICONS.ENERGY}
                value={localize.formatAsFloat(energyFinal, { unit: Units.energyPerAreaYear })}
              />
              <PartialRenovationOptionSubtext
                title={t('General_Efficiency')}
                icon={ICONS.COG}
                value={`${part.efficiency}`}
              />
              <PartialRenovationOptionSubtext
                title={t('General_ConstructionYear')}
                icon={ICONS.CALENDAR}
                value={constructionYear ?? '-'}
              />
            </Stack>
          </Stack>
        );
      })}
    />
  );
}
