import { FormHelperText, Grid, Typography } from '@mui/material';
import { TARGET_PATH_FIRST_YEAR, TARGET_PATH_LAST_YEAR } from '@predium/client-lookup';
import { UnitsValue } from '@predium/utils';
import { TFunction } from 'i18next';
import isEqual from 'lodash/isEqual';
import React, { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Collapsible from '../../../components/common/Collapsible';
import { useEsgAnalysisPopupFilters } from './EsgAnalysisFilterPopupContext';
import { EsgAnalysisFiltersKeys, EsgAnalysisFiltersSearchParams } from './EsgAnalysisFilters';
import FilterOptionsAsNumberInput from './FiltersComponents/FilterOptionsAsNumberInput';

type RangeFilterType = 'StrandingYears' | 'FinalEnergy' | 'PrimaryEnergy' | 'CO2Emissions';

type RangeFilterProps = {
  minKey: EsgAnalysisFiltersKeys;
  maxKey: EsgAnalysisFiltersKeys;
  title: string;
  unit?: UnitsValue;
  minKeyFilters: EsgAnalysisFiltersSearchParams['minFinalEnergy'];
  maxKeyFilters: EsgAnalysisFiltersSearchParams['maxFinalEnergy'];
  filterType?: RangeFilterType;
};

const validateMinMax = (
  t: TFunction<'translation', undefined>,
  filterType?: RangeFilterType,
  minKeyFilters?: EsgAnalysisFiltersSearchParams['minFinalEnergy'],
  maxKeyFilters?: EsgAnalysisFiltersSearchParams['maxFinalEnergy'],
): string | undefined => {
  const minValue = minKeyFilters?.[0];
  const maxValue = maxKeyFilters?.[0];

  let isMinMxError = false;

  if (minValue !== undefined && maxValue !== undefined) {
    if (minValue > maxValue) {
      isMinMxError = true;
      return t('Validation_MinCannotExceedMax');
    }
  }

  if (filterType && filterType === 'StrandingYears' && !isMinMxError) {
    if (
      (minValue && (minValue < TARGET_PATH_FIRST_YEAR || minValue > TARGET_PATH_LAST_YEAR)) ||
      (maxValue && (maxValue < TARGET_PATH_FIRST_YEAR || maxValue > TARGET_PATH_LAST_YEAR))
    ) {
      return t('Validation_MinMaxStrandingYear', {
        min: TARGET_PATH_FIRST_YEAR,
        max: TARGET_PATH_LAST_YEAR,
      });
    }
  }

  return undefined;
};

const EsgRangeFilter: React.FC<RangeFilterProps> = ({
  minKey,
  maxKey,
  title,
  unit,
  maxKeyFilters,
  minKeyFilters,
  filterType,
}) => {
  const { t } = useTranslation();
  const { setIsError } = useEsgAnalysisPopupFilters();

  const error = validateMinMax(t, filterType, minKeyFilters, maxKeyFilters);

  useEffect(() => {
    setIsError(Boolean(error));
  }, [error, setIsError]);

  return (
    <Collapsible title={title}>
      <Grid item container alignItems="center" justifyContent="space-between">
        <Grid item xs>
          <FilterOptionsAsNumberInput type={minKey} isError={Boolean(error)} unit={unit} />
        </Grid>
        <Grid item>
          <Typography variant="body1" sx={{ mx: 1.5 }}>
            -
          </Typography>
        </Grid>
        <Grid item xs>
          <FilterOptionsAsNumberInput type={maxKey} isError={Boolean(error)} unit={unit} />
        </Grid>
      </Grid>
      <FormHelperText error>{error}</FormHelperText>
    </Collapsible>
  );
};

export default memo(EsgRangeFilter, isEqual);
