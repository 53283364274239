import { Button, Stack, Tooltip, TooltipProps, Typography } from '@mui/material';
import { forwardRef, Ref } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ICONS } from '../../../../../assets/icons';
import Iconify from '../../../../../components/Iconify';
import { PATHS } from '../../../../../routes';
import useBuilding from '../../Context/useBuilding';

type Props = Omit<TooltipProps, 'title'> & {
  disabled: boolean;
};

const MonetaryTooltip = forwardRef(({ disabled, ...props }: Props, ref: Ref<HTMLElement>) => {
  const { t } = useTranslation();
  const { building } = useBuilding();

  return (
    <Tooltip
      {...props}
      ref={ref}
      title={
        disabled ? (
          <Stack p={1} alignItems="flex-start" spacing={1}>
            <Typography variant="subtitle2" fontWeight={700}>
              {t('ClimateRiskV2_MonetaryTooltipTitle')}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              {t('ClimateRiskV2_MonetaryTooltipBody')}
            </Typography>
            <Button
              size="small"
              component={Link}
              endIcon={<Iconify icon={ICONS.EXTERNAL_LINK} width={16} height={16} />}
              to={PATHS.dataCollection.building({ id: building.id }, { openEditDialog: true })}
            >
              {t('DataCollectionEditBuilding_BuildingValuation_AddValuation')}
            </Button>
          </Stack>
        ) : null
      }
      arrow
      placement="top-start"
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: 'background.paper',
            color: 'text.primary',
          },
        },
        arrow: {
          sx: {
            '&::before': {
              bgcolor: 'background.paper',
            },
          },
        },
      }}
    />
  );
});

export default MonetaryTooltip;
