/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useMutation } from '@apollo/client';
import { DialogTitle } from '@mui/material';
import { building_state_enum } from '@predium/client-graphql';
import { ensureDefined } from '@predium/utils';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SnackbarTimeouts } from '../../../../components/NotistackProvider';
import { MOVE_ALL_BUILDINGS_TO_ECONOMIC_UNIT } from '../../../../graphql/DataCollection.mutations';
import {
  GET_ALL_BUILDINGS_WITH_SUB_BUILDINGS,
  GET_ECONOMIC_UNITS_WITH_BUILDINGS,
} from '../../../../graphql/DataCollection.queries';
import { DeleteEmptyEconomicUnit } from '../../Building/Common/DeleteEmptyEconomicUnit';
import EconomicUnitsListToMove from '../../Building/EconomicUnitsToMove';

export type BulkOperationsFilters = {
  searchString?: string;
  portfolioIds?: number[];
  status?: building_state_enum;
  excludeIds?: number[];
};

type Props = {
  count: number;
  filters: BulkOperationsFilters;
  onMoveCompleted: VoidFunction;
  onCancel: VoidFunction;
};

export default function AllBulkMove({ count, filters, onMoveCompleted, onCancel }: Props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [keepEmptyEu, setKeepEmptyEu] = useState(false);
  const [selectedEconomicUnitId, setSelectedEconomicUnitId] = useState<number | null>(null);

  const showErrorSnackbar = () => {
    enqueueSnackbar(t('DataCollection_MoveBuildingsDialog-error'), {
      variant: 'error',
      autoHideDuration: SnackbarTimeouts.Error,
    });
  };

  const [moveBuildings] = useMutation(MOVE_ALL_BUILDINGS_TO_ECONOMIC_UNIT, {
    refetchQueries: [{ query: GET_ECONOMIC_UNITS_WITH_BUILDINGS }, { query: GET_ALL_BUILDINGS_WITH_SUB_BUILDINGS }],
    onError: () => showErrorSnackbar(),
    onCompleted: async (data) => {
      if (!data.moveAllBuildings.success) {
        showErrorSnackbar();
        return;
      }
      enqueueSnackbar(t('DataCollection_MoveBuildingsDialog-success', { count }), {
        variant: 'success',
        autoHideDuration: SnackbarTimeouts.Success,
      });

      onMoveCompleted();
    },
  });

  const handleMoveBuildings = () => {
    moveBuildings({
      variables: {
        keepEmptyEconomicUnits: keepEmptyEu,
        destinationEconomicUnitId: ensureDefined(selectedEconomicUnitId),
        ...filters,
      },
    });
  };

  return (
    <>
      <DialogTitle>{t('DataCollection_MoveBuildingsDialog-title', { count })}</DialogTitle>
      <EconomicUnitsListToMove
        sx={{
          justifyContent: 'flex-end',
        }}
        selectedEconomicUnitId={selectedEconomicUnitId}
        setSelectedEconomicUnitId={setSelectedEconomicUnitId}
        onCancel={onCancel}
        onMove={handleMoveBuildings}
      >
        <DeleteEmptyEconomicUnit economicUnitsToggled isBulkOperation setKeepEmptyEu={setKeepEmptyEu} />
      </EconomicUnitsListToMove>
    </>
  );
}
