import { Divider, Grid, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { AnalyzeScenarioOutputFragment } from '@predium/client-graphql';
import { Units } from '@predium/utils';
import { useTranslation } from 'react-i18next';
import EnergeticImpactLineChart from '../../Visualization/EnergeticImpactLineChart';
import { useGetScenarioEnergyPaths } from '../queries/useGetScenarioEnergyPaths';
import CompareScenariosBarChart from './CompareScenariosEnergeticMetricChart';
import { CompareScenariosEnergeticMetricsCard } from './CompareScenariosEnergeticMetricsCard';

type Props = {
  scenarioOne: { id: number; name: string };
  analyzedScenarioOne?: AnalyzeScenarioOutputFragment;
  scenarioOneTotalActions: number;
  scenarioTwo: { id: number; name: string };
  scenarioOneDataColor: string;
  scenarioTwoDataColor: string;
};

export const CompareScenariosEnergeticMetrics = ({
  scenarioOne,
  analyzedScenarioOne,
  scenarioOneTotalActions,
  scenarioTwo,
  scenarioOneDataColor,
  scenarioTwoDataColor,
}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const statusQuoColor = theme.palette.scenarioComparisonColors.statusQuo;
  const targetPathColor = theme.palette.scenarioImpactGraphColors.targetPath;

  const [energyMetrics, costs, isLoadingEnergyPaths] = useGetScenarioEnergyPaths({
    scenarioOneName: scenarioOne.name,
    scenarioTwoName: scenarioTwo.name,
    analyzedScenarioOne,
    scenarioOneTotalActions,
    scenarioOneDataColor,
    scenarioTwoDataColor,
    statusQuoColor,
    targetPathColor,
    scenarioTwoId: scenarioTwo.id,
  });

  return (
    <Grid item container spacing={3} xs={12}>
      <Grid item xs={12}>
        <Typography variant={'h6'} gutterBottom={false}>
          {t('ActionPlanningScenario_CompareScenarios_EnergeticMetrics')}
        </Typography>
      </Grid>
      {energyMetrics.map((metric, index) => (
        <Grid key={index} item xs={12}>
          <CompareScenariosEnergeticMetricsCard header={metric.header} isLoading={isLoadingEnergyPaths}>
            <CompareScenariosBarChart
              key={`${metric.header}-barChart`}
              ariaLabel={`${metric.header}-barChart`}
              series={metric.barChartSeries}
              categories={[scenarioTwo.name, scenarioOne.name, t('General_StatusQuo')]}
              colors={[scenarioTwoDataColor, scenarioOneDataColor, statusQuoColor]}
              unit={Units.energyPerAreaYear}
            />
            <Divider key={`energetic-metrics-divider-${index}`} />
            <EnergeticImpactLineChart
              key={`${metric.header}-lineChart`}
              ariaLabel={`${metric.header}-lineChart`}
              graphType={metric.lineChartData.graphType}
              series={metric.lineChartData.series}
              unit={metric.lineChartData.unit}
              yAxisLabel={metric.lineChartData.yAxisLabel}
              legendData={metric.lineChartData.legendData}
              totalActions={metric.lineChartData.totalActions}
            />
          </CompareScenariosEnergeticMetricsCard>
        </Grid>
      ))}
      <Grid item xs={12}>
        <Typography variant={'h6'} gutterBottom={false}>
          {t('ActionPlanningScenario_CompareScenarios_FinancialMetrics')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <CompareScenariosEnergeticMetricsCard header={t('General_TotalInvestment')} isLoading={isLoadingEnergyPaths}>
          <CompareScenariosBarChart
            ariaLabel={`${t('General_TotalInvestment')}-barChart`}
            series={costs}
            categories={[scenarioTwo.name, scenarioOne.name]}
            colors={[scenarioTwoDataColor, scenarioOneDataColor]}
            unit={Units.price}
          />
        </CompareScenariosEnergeticMetricsCard>
      </Grid>
    </Grid>
  );
};
