/* eslint-disable @typescript-eslint/ban-ts-comment */
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, InputAdornment } from '@mui/material';
import {
  energy_consumer_technology_type_enum,
  energy_consumer_type_enum,
  energy_source_type_enum,
  energy_system_type_enum,
} from '@predium/client-graphql';
import { translateUnitEnum_dynamic } from '@predium/i18n/client';
import { Units } from '@predium/utils';
import isEqual from 'lodash/isEqual';
import uniqBy from 'lodash/uniqBy';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormProvider, RHFNumberField } from '../../../../../../../components/hook-form';
import InfoTable, { InfoTableData } from '../../../../../../../components/presentations/InfoTable';
import { BuildingTabEnum } from '../../../../../../../pages/DataCollection/DataCollectionBuilding';
import { useLanguage } from '../../../../../../../provider/LanguageProvider';
import { createSystemInfoTable } from '../../../../../Scenario/scenarios.util';
import { getMinDate } from '../../../../ActionPlan.utils';
import { NumberInputSx } from '../../../../Components/ActionCustomStyles';
import { ConsumerPartialRenovationOption } from '../../../CreateAction';
import { LightingActionFormSchema } from '../../../CreateActions/validation-schema';
import ConsumerPartialRenovationsAutocomplete from '../../../PartialRenovations/SystemRenovation/ConsumerPartialRenovationsAutocomplete';
import { TechnicalActionEditProps } from '../../TechnicalAction';

export type LightingActionForm = {
  system_renovation_parameter: {
    renovation_ids: string[];
    efficiency: number | undefined;
    system_type: energy_system_type_enum;
    consumer_technology_type: energy_consumer_technology_type_enum;
    energy_source_type: energy_source_type_enum;
    technology_name: string;
    cost_per_m2: number;
  };
  implementation_to: DateTime | '';
  affected_parts: ConsumerPartialRenovationOption[];
};

const LightingAction = forwardRef(
  (
    {
      previousActionBuildingModel,
      resetSimulatedData,
      loading,
      parameters,
      affectedParts,
      isRecommendedAction,
      implementationTo,
      implementationToComponent,
    }: TechnicalActionEditProps & {
      parameters: LightingActionForm['system_renovation_parameter'];
    },
    ref,
  ) => {
    const { language } = useLanguage();
    const { t } = useTranslation();

    const buildingId = previousActionBuildingModel.building!.id;

    const infoTableData: InfoTableData[] = createSystemInfoTable(
      energy_consumer_type_enum.LIGHTING,
      previousActionBuildingModel,
      t,
    );

    const energyConsumers = uniqBy(
      previousActionBuildingModel.energy_systems
        ?.filter((system) => system?.energy_system_type_id === energy_system_type_enum.LIGHTING)
        .flatMap((system) => system?.energy_system_consumer_routes.map((route) => route.energy_consumer)) ?? [],
      (c) => c.id,
    );

    const minimumDate = isRecommendedAction ? implementationTo : getMinDate();

    const defaultValues = {
      system_renovation_parameter: parameters,
      implementation_to: implementationTo,
      affected_parts: affectedParts,
    };

    const formMethods = useForm<LightingActionForm>({
      mode: 'onChange',
      reValidateMode: 'onChange',
      defaultValues: defaultValues,
      resolver: yupResolver(LightingActionFormSchema(minimumDate, language, isRecommendedAction)),
    });

    const { setValue, watch } = formMethods;

    const affectedPartsChanged = watch('affected_parts');

    useEffect(() => {
      const affectedRenovationIds = affectedParts.flatMap((part) =>
        part.energy_system_consumer_routes.map((route) => route.secondary_id),
      );
      const changedRenovationIds = affectedPartsChanged.flatMap((part: ConsumerPartialRenovationOption) =>
        part.energy_system_consumer_routes.map((route) => route.secondary_id),
      );

      if (!isEqual(affectedRenovationIds.sort(), changedRenovationIds.sort())) {
        resetSimulatedData();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [affectedPartsChanged]);

    useImperativeHandle(ref, () => ({
      methods: formMethods,
    }));

    return (
      <FormProvider methods={formMethods}>
        <Box my={3}>
          {infoTableData && (
            <Box mb={3}>
              <InfoTable
                buildingId={buildingId}
                showTab={BuildingTabEnum.technology}
                data={infoTableData}
                title={t('General_StateBeforeRenovation')}
              />
            </Box>
          )}

          {!isRecommendedAction && (
            <ConsumerPartialRenovationsAutocomplete
              action={energy_system_type_enum.LIGHTING}
              energyConsumers={energyConsumers}
              sx={{ ...NumberInputSx, mt: 3 }}
              setValue={(value: ConsumerPartialRenovationOption[]) => {
                setValue(
                  'system_renovation_parameter.renovation_ids',
                  // TODO PRE-5602 change to secondary id of consumer
                  value.flatMap((v) => v.energy_system_consumer_routes.flatMap((route) => route.secondary_id)),
                  {
                    shouldDirty: true,
                    shouldValidate: true,
                  },
                );
              }}
              disabled={loading}
              defaultAffectedParts={affectedParts}
              actionMode="edit"
            />
          )}

          <Grid container spacing={2}>
            <Grid item xs={12} my={3}>
              <RHFNumberField
                name="system_renovation_parameter.efficiency"
                label={t('General_LightYield')}
                size={'small'}
                sx={NumberInputSx}
                disabled={loading}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">{translateUnitEnum_dynamic(Units.lightYield, t)}</InputAdornment>
                  ),
                }}
                onValueChange={() => {
                  resetSimulatedData();
                }}
              />
            </Grid>
          </Grid>

          {implementationToComponent}
        </Box>
      </FormProvider>
    );
  },
);

export default LightingAction;
