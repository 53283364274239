import { IconButton, SxProps, Theme, Tooltip, TooltipProps, Typography } from '@mui/material';
import { ReactNode } from 'react';
import Iconify from './Iconify';

interface Props {
  text: ReactNode;
  maxwidth?: number;
  sx?: SxProps<Theme>;
  placement?: TooltipProps['placement'];
  textColor?: string;
}

const InfoTooltip = ({ text, maxwidth = 500, sx, placement = 'bottom-start', textColor = 'text.primary' }: Props) => {
  return (
    <Tooltip
      title={
        <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>
          {text}
        </Typography>
      }
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: 'background.paper',
            color: textColor,
            py: 1,
            px: 2,
            maxWidth: maxwidth,
            zIndex: 0, // Fix Safari overflow: hidden with border radius
          },
        },
      }}
      placement={placement}
    >
      <IconButton disableRipple sx={{ cursor: 'default', ...sx }}>
        <Iconify icon={'material-symbols:info-outline'} width={20} height={20} />
      </IconButton>
    </Tooltip>
  );
};

export default InfoTooltip;
