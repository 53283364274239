import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { ICONS } from '../../../assets/icons';
import Iconify from '../../../components/Iconify';
import OverflowText from '../../../components/OverflowText';

interface ChangeBuildingButtonProps {
  title: string;
  onClick: () => void;
  isSelected: boolean;
  tooltipText: string;
}

const NavigationSelectorButton: React.FC<ChangeBuildingButtonProps> = ({ title, onClick, isSelected, tooltipText }) => {
  const theme = useTheme();

  return (
    <Tooltip title={tooltipText} arrow placement="top">
      <Button
        onClick={onClick}
        sx={{
          color: 'text.secondary',
          background: isSelected ? theme.palette.action.selected : '',
          width: 'fit-content',
          maxWidth: 400,
        }}
      >
        <OverflowText maxWidth={360} text={title} variant={'h4'} showTooltip={false} />

        <Iconify icon={ICONS.CHEVRON_DOWN} width={24} height={24} />
      </Button>
    </Tooltip>
  );
};

export default NavigationSelectorButton;
