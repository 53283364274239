import { useQuery } from '@apollo/client';
import { Divider, Stack, Tooltip, Typography } from '@mui/material';
import { CompleteBuildingQuery } from '@predium/client-graphql';
import { data_source_type_enum } from '@predium/enums';
import {
  translateBuildingStateEnum_dynamic,
  translateCountryEnum_dynamic,
  translateTypeOfUseEnum_dynamic,
} from '@predium/i18n/client';
import { COMMON_DATE_FORMATS, ensureDefined, formatDateToLocale, Units } from '@predium/utils';
import isNil from 'lodash/isNil';
import sortBy from 'lodash/sortBy';
import { useTranslation } from 'react-i18next';
import { GET_USERS } from '../../../../../../graphql/DataCollection.queries';
import { GET_PORTFOLIOS_AND_ECONOMIC_UNITS } from '../../../../../../graphql/Portfolio.queries';
import useOrgPreferences from '../../../../../../hooks/useOrgPreferences';
import { useLanguage } from '../../../../../../provider/LanguageProvider';
import { getAreaLabel } from '../../../../Building/Common/building.util';
import { BuildingCreationFormCast, useBuildingCreation } from '../../BuildingCreationProvider';
import DataRow from '../Components/DataRow';

type Props = {
  completedBuilding: BuildingCreationFormCast;
  loading: boolean;
  data: CompleteBuildingQuery | undefined;
};

const CoreDataReview = ({ completedBuilding, loading, data }: Props) => {
  const { economicUnitsToggled } = useOrgPreferences();
  const { t } = useTranslation();
  const { localize, language } = useLanguage();
  const { getBuilding } = useBuildingCreation();

  const building = getBuilding();

  const { data: usersData } = useQuery(GET_USERS);
  const { data: portfolioData } = useQuery(GET_PORTFOLIOS_AND_ECONOMIC_UNITS);

  const getUserFullName = (userId: number) => {
    const user = usersData?.user.find((user) => user.id === userId);
    return `${user?.first_name} ${user?.last_name}`;
  };

  const portfolios = portfolioData?.portfolio ?? [];
  const selectedPortfolio = portfolios.find(
    (portfolio) => portfolio.id === completedBuilding.information.coreData.portfolioId,
  );
  const selectedEconomicUnit = selectedPortfolio?.economic_units.find(
    (unit) => unit.id === completedBuilding.information.coreData.economicUnitId,
  );

  const latestValuation = sortBy(building.information.buildingValuations, 'valuation_at').at(-1);
  const marketValue = latestValuation?.value;
  const formattedMarketValue = localize.formatAsCurrency(marketValue, {
    currency: 'EUR',
    fractionDigits: 2,
  });

  return (
    <Stack spacing={2} divider={<Divider />}>
      <Stack spacing={2}>
        <DataRow label={t('General_Address')}>{completedBuilding.address.street}</DataRow>
        <DataRow label={t('General_PostalCode')}>{completedBuilding.address.postalCode}</DataRow>
        <DataRow label={t('General_City')}>{completedBuilding.address.city}</DataRow>
        <DataRow label={t('General_Country')}>
          {translateCountryEnum_dynamic(ensureDefined(completedBuilding.address.countryId), t)}
        </DataRow>
      </Stack>
      <Stack spacing={2}>
        <DataRow label={t('General_ResponsibleUser')}>
          {getUserFullName(ensureDefined(completedBuilding.information.responsibleUserId))}
        </DataRow>
        <DataRow label={t('General_State')}>
          {translateBuildingStateEnum_dynamic(ensureDefined(completedBuilding.information.coreData.state), t)}
        </DataRow>
        <DataRow label={t('General_CurrentBuildingValue')}>
          {latestValuation ? (
            <Tooltip
              title={t('BuildingValuation_ValuationDate', {
                date: formatDateToLocale(latestValuation.valuation_at!, COMMON_DATE_FORMATS.DAY_MONTH_YEAR, language),
              })}
              placement="top"
              arrow
            >
              <Typography variant="body1">{formattedMarketValue}</Typography>
            </Tooltip>
          ) : (
            '-'
          )}
        </DataRow>
        <DataRow label={t('General_Portfolio')}>{selectedPortfolio?.name}</DataRow>
        {economicUnitsToggled && <DataRow label={t('General_EconomicUnit')}>{selectedEconomicUnit?.name}</DataRow>}
        <DataRow label={t('General_CustomerExternalIdentifier')}>
          {completedBuilding.information.coreData.externalId}
        </DataRow>
        <DataRow
          isApproximating={loading && isNil(building.information.coreData.constructionYear)}
          isApproximated={data?.completeBuilding.yearConstructedDataSource === data_source_type_enum.APPROXIMATED}
          label={t('General_ConstructionYear')}
        >
          {completedBuilding.information.coreData.constructionYear}
        </DataRow>
        <DataRow
          isApproximating={loading && isNil(building.information.coreData.units)}
          isApproximated={data?.completeBuilding.unitsDataSource === data_source_type_enum.APPROXIMATED}
          label={t('General_AmountOfUnits')}
        >
          {completedBuilding.information.coreData.units}
        </DataRow>
        <DataRow
          isApproximating={loading && isNil(building.information.area.typeOfUse)}
          isApproximated={data?.completeBuilding.typeOfUseDataSource === data_source_type_enum.APPROXIMATED}
          label={t('General_TypeOfUse')}
        >
          {completedBuilding.information.area.typeOfUse
            ? translateTypeOfUseEnum_dynamic(ensureDefined(completedBuilding.information.area.typeOfUse), t)
            : null}
        </DataRow>
        <DataRow
          isApproximating={loading && isNil(building.information.area.totalArea)}
          isApproximated={data?.completeBuilding.areaUsableDataSource === data_source_type_enum.APPROXIMATED}
          label={getAreaLabel(ensureDefined(completedBuilding.information.area.typeOfArea), t)}
        >
          {localize.formatAsFloat(completedBuilding.information.area.totalArea, { unit: Units.area })}
        </DataRow>
      </Stack>
    </Stack>
  );
};

export default CoreDataReview;
