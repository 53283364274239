import { language_enum } from '@predium/enums';
import { COMMON_DATE_FORMATS, formatDateToLocale } from '@predium/utils';

export const getScenarioPortfolioName = (portfolioName?: string | null) => {
  if (portfolioName == null || portfolioName === '') {
    return '–';
  }

  return portfolioName;
};

export const getScenarioOwner = (firstName: string | null | undefined, lastName: string | null | undefined) => {
  const fullName = `${firstName ?? ''}${lastName ? ` ${lastName}` : ''}`.trimStart();

  return `${!fullName ? '–' : fullName}`;
};

export const getScenarioCreatedAt = (createdAt: string | null | undefined, language: language_enum) => {
  return createdAt ? formatDateToLocale(createdAt, COMMON_DATE_FORMATS.DAY_MONTH_YEAR, language) : '–';
};
