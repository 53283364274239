import { Stack, StackProps, Typography, TypographyProps } from '@mui/material';
import { translateUnitEnum_dynamic } from '@predium/i18n/client';
import { UnitsValue } from '@predium/utils';
import { useTranslation } from 'react-i18next';
import Iconify from '../../../../components/Iconify';
import InfoTooltip from '../../../../components/InfoTooltip';
import { useLanguage } from '../../../../provider/LanguageProvider';

type Props = {
  icon?: string;
  iconColor?: string;

  title: string;
  subtitle?: string;
  titleTooltip?: string;

  value?: number | null;
  hasValue?: boolean;
  unit: UnitsValue;
  valueSubtitle?: string;

  slotsProp?: {
    root?: StackProps;
    title?: TypographyProps;
    unitsRoot?: StackProps;
  };
};

const KPI = ({ title, subtitle, titleTooltip, valueSubtitle, unit, value, icon, iconColor, slotsProp }: Props) => {
  const { t } = useTranslation();
  const { localize } = useLanguage();

  return (
    <Stack direction="row" spacing={1} {...slotsProp?.root}>
      {icon && <Iconify icon={icon} color={iconColor} fontSize={32} />}
      <Stack>
        <Stack direction="row" spacing={0.75} alignItems="center">
          <Typography variant="body2" fontWeight={700}>
            {title}
          </Typography>
          {titleTooltip && <InfoTooltip text={titleTooltip} sx={{ p: 0, svg: { height: 16, width: 16 } }} />}
        </Stack>

        {subtitle && (
          <Typography variant="caption" mt={1}>
            {subtitle}
          </Typography>
        )}
        <Stack direction="row" spacing={0.5} alignItems={'baseline'}>
          <Typography fontWeight={700} variant="h5" {...slotsProp?.title}>
            {localize.formatAsCompact(value)}
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{
              textAlign: 'left',
              fontWeight: 700,
              color: 'grey.600',
              pb: 0.25,
            }}
          >
            {translateUnitEnum_dynamic(unit, t)}
          </Typography>
        </Stack>
        {valueSubtitle && (
          <Typography variant="caption" color="grey.600">
            {valueSubtitle}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};

export default KPI;
