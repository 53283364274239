import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SnackbarTimeouts } from '../../../components/NotistackProvider';
import ExportPdfDialog, { PdfFormData } from '../../../components/documents/export-pdf/ExportPdfDialog';
import { QUEUE_ESG_ANALYSIS_PDF_EXPORT } from '../../../graphql/EsgAnalysis.queries';
import { useExportedFile } from '../../../hooks/useExportedFile';

type Props = {
  building: {
    id: number;
    street: string;
    city: string;
    postalCode: string;
  };
  handleClose: () => void;
};

const EsgPdfExport = ({ building, handleClose }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const handleError = () => {
    enqueueSnackbar(t('EsgAnalysis_ExportESGPDF-error'), {
      variant: 'error',
      autoHideDuration: SnackbarTimeouts.Error,
    });

    handleClose();
  };

  const handleSuccess = () => {
    enqueueSnackbar(t('EsgAnalysis_ExportESGPDF-success'), {
      variant: 'success',
      autoHideDuration: SnackbarTimeouts.Success,
    });

    handleClose();
  };

  const { trackDocument, untrackDocument, isLoading } = useExportedFile({
    onSuccess: handleSuccess,
    onError: handleError,
  });

  const [createPdfExport, { loading }] = useMutation(QUEUE_ESG_ANALYSIS_PDF_EXPORT, {
    onError: handleError,
    onCompleted: (data) => {
      if (!data.queueEsgAnalysisPdfExport.documentId) {
        return handleError();
      }

      trackDocument(data.queueEsgAnalysisPdfExport.documentId);
    },
  });

  const onSubmit = (data: PdfFormData) => {
    createPdfExport({
      variables: {
        buildingId: building.id,
        // to avoid sending empty strings
        title: data.title || undefined,
        description: data.description || undefined,
      },
    });
  };

  useEffect(() => {
    if (!building) {
      untrackDocument();
    }
  }, [building, untrackDocument]);

  return (
    <ExportPdfDialog
      open={Boolean(building)}
      onCancel={handleClose}
      onFormSubmit={(data) => onSubmit(data)}
      loading={loading || isLoading}
      dialogTitle={t('EsgAnalysis_ExportDialogTitle')}
      descriptions={[t('EsgAnalysis_ExportDialogDescriptionFirst'), t('EsgAnalysis_ExportDialogDescriptionSecond')]}
    />
  );
};

export default EsgPdfExport;
