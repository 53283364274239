import { TypedDocumentNode, useQuery } from '@apollo/client';
import {
  Box,
  Table,
  TableBody,
  TableBodyProps,
  TableContainer,
  TableContainerProps,
  TableHead,
  TableRow,
} from '@mui/material';
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import set from 'lodash/set';
import { Fragment, ReactNode, useCallback, useEffect, useMemo } from 'react';
import useTable, { OnFilterCallbackParams } from '../../hooks/useTable';
import { mergeSx } from '../../utils/cssStyles';
import Scrollbar from '../Scrollbar';
import SearchNotFoundRow from '../SearchNotFound';
import { TableSelectedActions } from '../table';
import TablePaginationStandard from '../table/TablePaginationStandard';
import { dateColumn } from './Columns/DateColumn';
import { selectColumn, useSelectColumn } from './Columns/SelectColumn';
import { textColumn } from './Columns/TextColumn';
import DataTableRow, { DataTableRowProps } from './Components/DataTableRow';
import { DataTableAPIContext } from './DataTableAPIContext';
import { DataTableColumns, MaybeFunction, QueryResult, QueryVariables } from './utils';

export type DataTableProps<TQuery extends TypedDocumentNode> = {
  /**
   * The columns that will be rendered in the table
   *
   * Check the README for more information
   */
  columns: DataTableColumns<TQuery>;
  /**
   * The query that will be used to fetch the data
   *
   * The query should return an object with a specific structure. Check the README for more information
   */
  query: TQuery;
  /**
   * Whenever `extraVariables` changes, page will reset. use `useMemo` to prevent unexpected behavior!
   */
  extraVariables?: QueryVariables<TQuery>;
  initials: {
    sortBy: string;
    /**
     * The default sort direction
     *
     * If you pass a value here, the table will be sorted by this direction
     *
     * @default 'asc'
     */
    sortDirection?: 'asc' | 'desc';
    /**
     * The default number of rows per page
     *
     * @default 20
     */
    rowsPerPage?: number;
    /**
     * Pagination options
     *
     * @default [5, 10, 25]
     */
    paginationOptions?: number[];
  };
  /**
   * This is used to show a message when there is no data found
   *
   * If you are using a search bar, you can pass the search keyword here
   *
   * This will show a message like "No data found for 'searchKeyword'"
   *
   * Note: This value will not be passed to the query, you should handle the search logic by passing it to `extraVariables`
   */
  searchKeyword?: string;
  /**
   * This function will render actions when the user checks a row.
   *
   * Note: You should pass this column to columns array to render the checkbox
   *
   * ```js
   * const columns = [
   *  { type: 'select' },
   *  ...
   * ]
   * ```
   */
  actions?: (arg: {
    getSelectedIds: () => number[];
    getExcludedIds: () => number[];
    onActionCompleted: VoidFunction;
    areAllSelected: boolean;
    selectedCount: number;
  }) => ReactNode;
  labels?: {
    /**
     * This variable will render a message when the user selects a row.
     *
     * If you pass a function, it will receive the number of selected items and should return a ReactNode
     */
    selectedLabel?: MaybeFunction<number, ReactNode>;
  };
  slotProps?: {
    tableContainer?: TableContainerProps;
    tableBody?: TableBodyProps;
    tableRow?: MaybeFunction<QueryResult<TQuery>[number], DataTableRowProps>;
  };
  /**
   * This method exposes the next orderBy and order filters, which is helpful to keep the filters applied or to perform some other logic based on them.
   *
   * If you pass a function, it will expose the next values for orderBy and order filters
   */
  onFilterChange?: ({ orderBy, order }: OnFilterCallbackParams) => void;
};

const COLUMNS = {
  [textColumn.type]: textColumn,
  [selectColumn.type]: selectColumn,
  [dateColumn.type]: dateColumn,
};

function DataTable<TQuery extends TypedDocumentNode<any, any>>({
  columns,
  query,
  extraVariables,
  initials,
  searchKeyword,
  slotProps,
  labels,
  onFilterChange,
  actions,
}: DataTableProps<TQuery>) {
  const {
    order: sortDirection,
    orderBy: sortBy,
    setOrderBy: setSortBy,
    setOrder: setSortDirection,
    rowsPerPage,
    page,
    setPage,
    setRowsPerPage,
  } = useTable({
    defaultRowsPerPage: initials?.rowsPerPage ?? 20,
    defaultOrder: initials?.sortDirection,
    defaultOrderBy: initials?.sortBy,
  });

  const orderByVariable = useMemo(() => set({}, sortBy, sortDirection), [sortDirection, sortBy]);

  const dataQuery = useQuery(query, {
    variables: {
      limit: rowsPerPage,
      offset: page * rowsPerPage,
      orderBy: orderByVariable,
      ...extraVariables,
    },
    // This policy will prevent the table from having invalid data
    // like a row that was deleted and table shows 9 rows instead of 10
    fetchPolicy: 'cache-and-network',
  });

  const getQueryKeys = useCallback((data: Record<string, any>) => {
    const dataKey = Object.keys(data).filter((key) => !key.endsWith('_aggregate'))[0];
    const aggregateKey = Object.keys(data).filter((key) => key.endsWith('_aggregate'))[0];

    if (!dataKey || !aggregateKey) {
      throw new Error('Invalid query data, please check the query structure in the README');
    }

    return {
      dataKey,
      aggregateKey,
    };
  }, []);

  const { count, rows } = useMemo(() => {
    const data = dataQuery.data ?? dataQuery.previousData;

    if (!data) {
      return {
        count: 0,
        rows: [],
      };
    }

    const { aggregateKey, dataKey } = getQueryKeys(data);

    const rows = get(data, dataKey);
    const count = get(data, aggregateKey).aggregate.count;

    return { count, rows };
  }, [dataQuery.data, dataQuery.previousData, getQueryKeys]);

  const {
    toggleSelectAll,
    toggleItem,
    getSelectedIds,
    getExcludedIds,
    clearSelectedItems,
    areAllSelected,
    getIsItemSelected,
    isAnyItemSelected,
    selectedCount,
  } = useSelectColumn({
    count,
  });

  useEffect(() => {
    setPage(0);
    clearSelectedItems();
  }, [extraVariables, setPage, clearSelectedItems]);

  const builtColumns = useMemo(
    () => columns.map((column) => ({ ...COLUMNS[column.type ?? textColumn.type], ...column })),
    [columns],
  );

  const onSortChange = useCallback(
    (newSortBy: string) => {
      if (newSortBy === sortBy) {
        setSortDirection((current) => (current === 'asc' ? 'desc' : 'asc'));
        onFilterChange?.({ order: sortDirection === 'asc' ? 'desc' : 'asc', orderBy: newSortBy });
      } else {
        setSortBy(newSortBy);
        setSortDirection('asc');
        onFilterChange?.({ order: 'asc', orderBy: newSortBy });
      }
    },
    [setSortBy, setSortDirection, onFilterChange, sortDirection, sortBy],
  );

  const onChangeRowsPerPage = useCallback(
    (count: number) => {
      setPage(0);
      setRowsPerPage(count);
    },
    [setPage, setRowsPerPage],
  );

  const onActionCompleted = useCallback(() => {
    clearSelectedItems();

    dataQuery.refetch().then((result) => {
      const data = result.data;

      if (!data) {
        return;
      }

      const { dataKey } = getQueryKeys(data);

      const pageRows = get(data, dataKey);

      if (pageRows.length === 0) {
        setPage(0);
      }
    });
  }, [clearSelectedItems, dataQuery, getQueryKeys, setPage]);

  return (
    <DataTableAPIContext.Provider
      value={{
        onSortChange,
        toggleItem,
        toggleSelectAll,
        sortBy,
        sortDirection,
        rowsPerPage,
        page,
        setPage,
        getIsItemSelected,
      }}
    >
      <Box position="relative">
        <Scrollbar>
          <TableContainer
            component="div"
            {...slotProps?.tableContainer}
            sx={mergeSx({ minWidth: 800 }, slotProps?.tableContainer?.sx)}
          >
            <Table component="div">
              {isAnyItemSelected && (
                <TableSelectedActions
                  selectedText={
                    isFunction(labels?.selectedLabel) ? labels?.selectedLabel(selectedCount) : labels?.selectedLabel
                  }
                  numSelected={selectedCount}
                  rowCount={count}
                  onSelectAllRows={toggleSelectAll}
                  action={actions?.({
                    getSelectedIds,
                    getExcludedIds,
                    onActionCompleted,
                    areAllSelected,
                    selectedCount,
                  })}
                  sx={{ pr: 3 }}
                />
              )}
              <TableHead component="div">
                <TableRow component="div">
                  {builtColumns.map((headCell) => (
                    <Fragment key={headCell.id}>{headCell.renderHead()}</Fragment>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody component="div" {...slotProps?.tableBody}>
                {rows.map((row: QueryResult<TQuery>[number]) => (
                  <DataTableRow
                    key={row.id}
                    selected={getIsItemSelected(row.id)}
                    {...(isFunction(slotProps?.tableRow) ? slotProps?.tableRow(row) : slotProps?.tableRow)}
                  >
                    {builtColumns.map((column) => (
                      <Fragment key={column.id}>{column.renderCell(row)}</Fragment>
                    ))}
                  </DataTableRow>
                ))}
                {count === 0 && searchKeyword && <SearchNotFoundRow searchQuery={searchKeyword} />}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
        <TablePaginationStandard
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          setPage={setPage}
          setRowsPerPage={onChangeRowsPerPage}
          paginationOptions={initials?.paginationOptions}
        />
        {!dataQuery.data && dataQuery.loading && (
          <Box
            height="100%"
            width="100%"
            position="absolute"
            top={0}
            left={0}
            sx={{
              bgcolor: 'primary.main',
              opacity: 0.02,
            }}
          />
        )}
      </Box>
    </DataTableAPIContext.Provider>
  );
}

export { DataTable };
