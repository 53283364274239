import { yupResolver } from '@hookform/resolvers/yup';
import { Box, InputAdornment, MenuItem, Stack, Typography } from '@mui/material';
import { ActionFragment, BuildingModelFragment } from '@predium/client-graphql';
import { custom_action_type_enum, energy_source_type_enum, energy_system_type_enum } from '@predium/enums';
import { ensureDefined } from '@predium/utils';
import { t } from 'i18next';
import isEqual from 'lodash/isEqual';
import uniqBy from 'lodash/uniqBy';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useForm } from 'react-hook-form';
import { FormProvider, RHFNumberField, RHFSelect, RHFTextField } from '../../../../../../components/hook-form';
import { useLanguage } from '../../../../../../provider/LanguageProvider';
import { NumberInputSx, SelectSx, StyledListItemText } from '../../../Components/ActionCustomStyles';
import InfoTableCustomAction from '../../ActionDetails/InfoTableCustomAction';
import { ConsumerPartialRenovationOption } from '../../CreateAction';

import { getMinDate } from '../../../ActionPlan.utils';
import {
  CustomEnergeticActionCategory,
  CustomEnergeticActionForm,
  CustomEnergeticActionType,
  getCustomActionName,
  getCustomEnergeticActionIcon,
} from '../../CreateActions/CustomActions/CustomEnergeticAction';
import { CustomActionFormSchema } from '../../CreateActions/validation-schema';
import ConsumerAffectedPartsTable from '../../PartialRenovations/SystemRenovation/AffectedConsumerPartsTable';
import ConsumerPartialRenovationsAutocomplete from '../../PartialRenovations/SystemRenovation/ConsumerPartialRenovationsAutocomplete';

const CustomEnergeticAction = forwardRef(
  (
    {
      action,
      previousActionBuildingModel,
      resetSimulatedData,
      affectedParts,
      implementationToComponent,
    }: {
      previousActionBuildingModel: BuildingModelFragment;
      resetSimulatedData: () => void;
      action: ActionFragment;
      affectedParts: ConsumerPartialRenovationOption[];
      implementationToComponent: JSX.Element;
    },
    ref,
  ) => {
    const { language } = useLanguage();

    const affectedCategory = ensureDefined(action.custom_action_type_id);
    const renovatedRoutes = action.renovations_energy_routes.map((r) => r.new_energy_system_route);

    const option =
      affectedCategory === custom_action_type_enum.ENERGY_SOURCE
        ? renovatedRoutes[0]?.energy_source_type_id
        : renovatedRoutes[0]?.energy_system.energy_system_type_id;

    const selectedCustomActionType = ensureDefined(option);

    const defaultValues = {
      action: affectedCategory,
      implementation_to: action.implementation_to,
      custom_action_renovation_parameter: {
        renovation_ids: [],
        custom_name: action.custom_name ?? '',
        fraction: action.custom_saving_fraction ? action.custom_saving_fraction * 100 : 0,
        energy_source_type: renovatedRoutes[0]?.energy_source_type_id,
        system_type: renovatedRoutes[0]?.energy_system.energy_system_type_id,
      },
      affected_parts: affectedParts,
    };

    const enablePartialRenovations = Object.values(energy_system_type_enum).includes(
      selectedCustomActionType as energy_system_type_enum,
    );

    const formMethods = useForm<CustomEnergeticActionForm>({
      mode: 'onChange',
      reValidateMode: 'onChange',
      defaultValues: defaultValues,
      resolver: yupResolver(CustomActionFormSchema(getMinDate(), language, enablePartialRenovations)),
    });

    const { watch, setValue } = formMethods;
    const affectedPartsChanged = watch('affected_parts');

    useEffect(() => {
      const affectedRenovationIds = affectedParts.flatMap((part) =>
        part.energy_system_consumer_routes.map((route) => route.secondary_id),
      );
      const changedRenovationIds = affectedPartsChanged?.flatMap((part: ConsumerPartialRenovationOption) =>
        part.energy_system_consumer_routes.map((route) => route.secondary_id),
      );

      if (!isEqual(affectedRenovationIds.sort(), changedRenovationIds?.sort())) {
        resetSimulatedData();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [affectedPartsChanged]);

    useEffect(() => {
      if (Object.values(energy_source_type_enum).includes(selectedCustomActionType as energy_source_type_enum)) {
        setValue(
          'custom_action_renovation_parameter.energy_source_type',
          selectedCustomActionType as energy_source_type_enum,
        );
      } else if (Object.values(energy_system_type_enum).includes(selectedCustomActionType as energy_system_type_enum)) {
        setValue('custom_action_renovation_parameter.system_type', selectedCustomActionType as energy_system_type_enum);
      } else {
        return;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCustomActionType]);

    useImperativeHandle(ref, () => ({
      methods: formMethods,
    }));

    const energyConsumers = uniqBy(
      previousActionBuildingModel.energy_systems
        ?.filter((system) => system?.energy_system_type_id === selectedCustomActionType)
        .flatMap((system) => system?.energy_system_consumer_routes.map((route) => route.energy_consumer)) ?? [],
      (c) => c.id,
    );

    useEffect(() => {
      // TODO PRE-5602 change to secondary id of consumer
      setValue(
        'custom_action_renovation_parameter.renovation_ids',
        affectedParts.flatMap((part: ConsumerPartialRenovationOption) =>
          part.energy_system_consumer_routes.flatMap((route) => route.secondary_id),
        ),
        {
          shouldDirty: true,
          shouldValidate: true,
        },
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [affectedParts]);

    return (
      <FormProvider methods={formMethods}>
        <RHFSelect
          name="action"
          label={t('General_ToOptimize')}
          size={'small'}
          sx={{ ...SelectSx, my: 2 }}
          fullWidth
          disabled
        >
          <MenuItem sx={{ textTransform: 'none' }} value={affectedCategory}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Box
                component="img"
                src={getCustomEnergeticActionIcon(selectedCustomActionType)}
                sx={{ width: 16, height: 16 }}
              />
              <StyledListItemText action={getCustomActionName(selectedCustomActionType, t)} />
            </Stack>
          </MenuItem>
        </RHFSelect>
        <InfoTableCustomAction
          affectedCategory={affectedCategory as unknown as CustomEnergeticActionCategory}
          selectedCustomActionType={selectedCustomActionType as CustomEnergeticActionType}
          buildingId={previousActionBuildingModel?.building?.id}
          buildingModel={previousActionBuildingModel}
        />
        <RHFTextField
          name="custom_action_renovation_parameter.custom_name"
          label={t('General_ActionTitle')}
          size={'small'}
          sx={{ ...NumberInputSx, pb: 0, mb: 0, mt: 3 }}
          onChange={(e) => {
            setValue('custom_action_renovation_parameter.custom_name', e.target.value, {
              shouldDirty: true,
              shouldValidate: true,
            });
            resetSimulatedData();
          }}
        />
        {Object.values(energy_system_type_enum).includes(selectedCustomActionType as energy_system_type_enum) && (
          <ConsumerAffectedPartsTable
            affectedParts={affectedParts as ConsumerPartialRenovationOption[]}
            sx={{
              '&.MuiAccordion-root ': {
                boxShadow: 'none',
                mb: 0,
                mt: 3,
              },
            }}
          />
        )}
        {enablePartialRenovations && (
          <ConsumerPartialRenovationsAutocomplete
            action={selectedCustomActionType as energy_system_type_enum}
            energyConsumers={energyConsumers}
            sx={{ mt: 3 }}
            setValue={(value: ConsumerPartialRenovationOption[]) => {
              setValue('affected_parts', value, {
                shouldDirty: true,
                shouldValidate: true,
              });
            }}
            defaultAffectedParts={affectedParts}
            actionMode="edit"
          />
        )}
        <RHFNumberField
          name="custom_action_renovation_parameter.fraction"
          label={`${t('General_FinalEnergyReduction')} - ${getCustomActionName(selectedCustomActionType, t)}`}
          size="small"
          min={0}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Typography variant="body2" color="text.secondary">
                  %
                </Typography>
              </InputAdornment>
            ),
          }}
          sx={{ ...NumberInputSx, my: 3 }}
          onValueChange={() => {
            resetSimulatedData();
          }}
        />
        {implementationToComponent}
      </FormProvider>
    );
  },
);
export default CustomEnergeticAction;
