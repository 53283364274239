import { Chip, Menu, MenuItem, Stack, Typography, useTheme } from '@mui/material';
import { useRef, useState } from 'react';
import { ICONS } from '../../assets/icons';
import Iconify from '../Iconify';
import Scrollbar from '../Scrollbar';
import { getChipStyles } from './filterStyle';

export type FilterItem = {
  name: string;
  value: string | number;
};

type Props = {
  /**
   * Array of selectable items.
   */
  items: FilterItem[];
  /**
   * The currently selected item value.
   */
  selectedItem: string | number;
  /**
   * Callback function triggered when the selected item changes.
   * @param item The newly selected item value.
   */
  onChanged: (item: string | number) => void;
  /**
   * Label displayed when no item is selected.
   */
  label: string;
  /**
   * Optional styles for the component.
   */
  sx?: object;
};

export default function SingleSelectFilter({ items, selectedItem, onChanged, label, sx }: Props) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const chipRef = useRef(null);

  const isDefaultSelected = selectedItem === items[0]?.value;

  const isOpen = Boolean(anchorEl);
  const selectedName = items.find((item) => item.value === selectedItem)?.name || label;

  const filterLabel = (
    <Stack
      flexDirection={'row'}
      alignItems={'center'}
      gap={0.5}
      sx={{
        display: 'block',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        verticalAlign: 'middle',
        margin: 'auto 0',
        flex: 1,
      }}
    >
      {selectedName}
    </Stack>
  );

  return (
    <>
      <Chip
        ref={chipRef}
        label={filterLabel}
        onClick={() => setAnchorEl(chipRef.current)}
        deleteIcon={
          <Iconify
            icon={ICONS.KEYBOARD_ARROW_DOWN}
            color="text.secondary"
            sx={{
              transition: 'transform 0.3s ease-in-out',
              transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
            }}
          />
        }
        onDelete={() => setAnchorEl(chipRef.current)}
        sx={getChipStyles({
          theme,
          isApplyDisabled: false,
          isDefaultSelected,
          sx,
          variant: 'single',
        })}
      />
      <Menu
        anchorEl={anchorEl}
        open={isOpen}
        onClose={() => setAnchorEl(null)}
        sx={{
          mt: 1,
          '& .MuiPaper-root': {
            minWidth: 200,
            maxWidth: 'unset',
            width: 'auto',
          },
          '& .MuiMenuItem-root': {
            whiteSpace: 'normal',
            wordBreak: 'break-word',
            width: 'auto',
          },
        }}
      >
        <Scrollbar sx={{ maxHeight: 300 }}>
          {items.map((item) => (
            <MenuItem
              key={item.value}
              onClick={() => {
                onChanged(item.value);
                setAnchorEl(null);
              }}
              sx={{
                pl: 2,
                px: 3,
                height: 'auto',
                minHeight: 48,
                backgroundColor: selectedItem === item.value ? theme.palette.action.selected : 'transparent',
                fontWeight: selectedItem === item.value ? 600 : 'normal',
                color: selectedItem === item.value ? theme.palette.text.primary : theme.palette.text.secondary,
                '&:hover': { backgroundColor: theme.palette.action.hover },
              }}
            >
              {item.name}
            </MenuItem>
          ))}
          {items.length === 0 && (
            <Typography variant="body2" color="text.secondary" sx={{ width: '100%', textAlign: 'center', p: 1 }}>
              No Results
            </Typography>
          )}
        </Scrollbar>
      </Menu>
    </>
  );
}
