import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { ExportIcon } from '../../../assets/Icons/ExportIcon';
import { useScenarioDownload } from './hooks';

type Props = {
  scenarioId: number;
  scenarioName: string;
};

export const DownloadScenarioButton = ({ scenarioName, scenarioId }: Props) => {
  const { t } = useTranslation();
  const [handleScenarioDownload, isLoading] = useScenarioDownload(scenarioName, scenarioId);

  return (
    <LoadingButton
      onClick={handleScenarioDownload}
      loading={isLoading}
      loadingPosition="start"
      startIcon={<ExportIcon />}
      variant={'outlined'}
      size="small"
      sx={{ mr: 2 }}
    >
      <Typography variant={'inherit'}>{t('ActionPlanningScenario_ExportAsExcel')}</Typography>
    </LoadingButton>
  );
};
