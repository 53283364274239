import { sustainaccount_climate_risk_hazard_enum } from '@predium/enums';
import { TFunction } from 'i18next';
import { Trans } from 'react-i18next';

export const translateSustainaccountClimateRiskHazardEnum = (enumValue: sustainaccount_climate_risk_hazard_enum) => {
  switch (enumValue) {
    case sustainaccount_climate_risk_hazard_enum.CHANGING_RAIN_PATTERNS:
      return <Trans i18nKey="Enum_SustainaccountClimateRiskHazardEnum-CHANGING_RAIN_PATTERNS" />;
    case sustainaccount_climate_risk_hazard_enum.CHANGING_TEMPERATURE_AIR:
      return <Trans i18nKey="Enum_SustainaccountClimateRiskHazardEnum-CHANGING_TEMPERATURE_AIR" />;
    case sustainaccount_climate_risk_hazard_enum.CHANGING_WIND_PATTERNS:
      return <Trans i18nKey="Enum_SustainaccountClimateRiskHazardEnum-CHANGING_WIND_PATTERNS" />;
    case sustainaccount_climate_risk_hazard_enum.COASTAL_FLOOD:
      return <Trans i18nKey="Enum_SustainaccountClimateRiskHazardEnum-COASTAL_FLOOD" />;
    case sustainaccount_climate_risk_hazard_enum.COASTAL_EROSION:
      return <Trans i18nKey="Enum_SustainaccountClimateRiskHazardEnum-COASTAL_EROSION" />;
    case sustainaccount_climate_risk_hazard_enum.COLD_WAVE:
      return <Trans i18nKey="Enum_SustainaccountClimateRiskHazardEnum-COLD_WAVE" />;
    case sustainaccount_climate_risk_hazard_enum.DROUGHT:
      return <Trans i18nKey="Enum_SustainaccountClimateRiskHazardEnum-DROUGHT" />;
    case sustainaccount_climate_risk_hazard_enum.FROST:
      return <Trans i18nKey="Enum_SustainaccountClimateRiskHazardEnum-FROST" />;
    case sustainaccount_climate_risk_hazard_enum.GROUND_WATER_FLOOD:
      return <Trans i18nKey="Enum_SustainaccountClimateRiskHazardEnum-GROUND_WATER_FLOOD" />;
    case sustainaccount_climate_risk_hazard_enum.HEAT_STRESS:
      return <Trans i18nKey="Enum_SustainaccountClimateRiskHazardEnum-HEAT_STRESS" />;
    case sustainaccount_climate_risk_hazard_enum.HEAT_WAVE:
      return <Trans i18nKey="Enum_SustainaccountClimateRiskHazardEnum-HEAT_WAVE" />;
    case sustainaccount_climate_risk_hazard_enum.HEAVY_HAIL:
      return <Trans i18nKey="Enum_SustainaccountClimateRiskHazardEnum-HEAVY_HAIL" />;
    case sustainaccount_climate_risk_hazard_enum.HEAVY_RAIN:
      return <Trans i18nKey="Enum_SustainaccountClimateRiskHazardEnum-HEAVY_RAIN" />;
    case sustainaccount_climate_risk_hazard_enum.PERMAFROST_THAWING:
      return <Trans i18nKey="Enum_SustainaccountClimateRiskHazardEnum-PERMAFROST_THAWING" />;
    case sustainaccount_climate_risk_hazard_enum.RIVER_FLOOD:
      return <Trans i18nKey="Enum_SustainaccountClimateRiskHazardEnum-RIVER_FLOOD" />;
    case sustainaccount_climate_risk_hazard_enum.SEA_LEVEL_RISE:
      return <Trans i18nKey="Enum_SustainaccountClimateRiskHazardEnum-SEA_LEVEL_RISE" />;
    case sustainaccount_climate_risk_hazard_enum.SNOW_STORM:
      return <Trans i18nKey="Enum_SustainaccountClimateRiskHazardEnum-SNOW_STORM" />;
    case sustainaccount_climate_risk_hazard_enum.SOIL_EROSION:
      return <Trans i18nKey="Enum_SustainaccountClimateRiskHazardEnum-SOIL_EROSION" />;
    case sustainaccount_climate_risk_hazard_enum.SUBSIDENCE:
      return <Trans i18nKey="Enum_SustainaccountClimateRiskHazardEnum-SUBSIDENCE" />;
    case sustainaccount_climate_risk_hazard_enum.TORNADO:
      return <Trans i18nKey="Enum_SustainaccountClimateRiskHazardEnum-TORNADO" />;
    case sustainaccount_climate_risk_hazard_enum.WILDFIRE:
      return <Trans i18nKey="Enum_SustainaccountClimateRiskHazardEnum-WILDFIRE" />;
    case sustainaccount_climate_risk_hazard_enum.WINTER_STORM:
      return <Trans i18nKey="Enum_SustainaccountClimateRiskHazardEnum-WINTER_STORM" />;
    case sustainaccount_climate_risk_hazard_enum.AIR_QUALITY:
      return <Trans i18nKey="Enum_SustainaccountClimateRiskHazardEnum-AIR_QUALITY" />;
    case sustainaccount_climate_risk_hazard_enum.EARTHQUAKE:
      return <Trans i18nKey="Enum_SustainaccountClimateRiskHazardEnum-EARTHQUAKE" />;
    case sustainaccount_climate_risk_hazard_enum.LIGHTNING_STRIKE:
      return <Trans i18nKey="Enum_SustainaccountClimateRiskHazardEnum-LIGHTNING_STRIKE" />;
    case sustainaccount_climate_risk_hazard_enum.NOISE:
      return <Trans i18nKey="Enum_SustainaccountClimateRiskHazardEnum-NOISE" />;
    case sustainaccount_climate_risk_hazard_enum.RADON:
      return <Trans i18nKey="Enum_SustainaccountClimateRiskHazardEnum-RADON" />;
    case sustainaccount_climate_risk_hazard_enum.TEMPERATURE_VARIABILITY:
      return <Trans i18nKey="Enum_SustainaccountClimateRiskHazardEnum-TEMPERATURE_VARIABILITY" />;
    case sustainaccount_climate_risk_hazard_enum.CYCLONE_HURRICANE_TYPHOON:
      return <Trans i18nKey="Enum_SustainaccountClimateRiskHazardEnum-CYCLONE_HURRICANE_TYPHOON" />;
    case sustainaccount_climate_risk_hazard_enum.PRECIPITATION_HYDROLOGICAL_VARIABILITY:
      return <Trans i18nKey="Enum_SustainaccountClimateRiskHazardEnum-PRECIPITATION_HYDROLOGICAL_VARIABILITY" />;
    case sustainaccount_climate_risk_hazard_enum.OCEAN_ACIDIFICATION:
      return <Trans i18nKey="Enum_SustainaccountClimateRiskHazardEnum-OCEAN_ACIDIFICATION" />;
    case sustainaccount_climate_risk_hazard_enum.SALINE_INTRUSION:
      return <Trans i18nKey="Enum_SustainaccountClimateRiskHazardEnum-SALINE_INTRUSION" />;
    case sustainaccount_climate_risk_hazard_enum.WATER_STRESS:
      return <Trans i18nKey="Enum_SustainaccountClimateRiskHazardEnum-WATER_STRESS" />;
    case sustainaccount_climate_risk_hazard_enum.SOIL_DEGRADATION:
      return <Trans i18nKey="Enum_SustainaccountClimateRiskHazardEnum-SOIL_DEGRADATION" />;
    case sustainaccount_climate_risk_hazard_enum.SOLIFLUCTION:
      return <Trans i18nKey="Enum_SustainaccountClimateRiskHazardEnum-SOLIFLUCTION" />;
    case sustainaccount_climate_risk_hazard_enum.AVALANCHE:
      return <Trans i18nKey="Enum_SustainaccountClimateRiskHazardEnum-AVALANCHE" />;
    case sustainaccount_climate_risk_hazard_enum.LANDSLIDE:
      return <Trans i18nKey="Enum_SustainaccountClimateRiskHazardEnum-LANDSLIDE" />;
    case sustainaccount_climate_risk_hazard_enum.GLACIAL_LAKE_OUTBURST:
      return <Trans i18nKey="Enum_SustainaccountClimateRiskHazardEnum-GLACIAL_LAKE_OUTBURST" />;
  }
};

export const translateSustainaccountClimateRiskHazardEnum_dynamic = (
  enumValue: sustainaccount_climate_risk_hazard_enum,
  t: TFunction<'translation', undefined>,
) => {
  switch (enumValue) {
    case sustainaccount_climate_risk_hazard_enum.CHANGING_RAIN_PATTERNS:
      return t('Enum_SustainaccountClimateRiskHazardEnum-CHANGING_RAIN_PATTERNS');
    case sustainaccount_climate_risk_hazard_enum.CHANGING_TEMPERATURE_AIR:
      return t('Enum_SustainaccountClimateRiskHazardEnum-CHANGING_TEMPERATURE_AIR');
    case sustainaccount_climate_risk_hazard_enum.CHANGING_WIND_PATTERNS:
      return t('Enum_SustainaccountClimateRiskHazardEnum-CHANGING_WIND_PATTERNS');
    case sustainaccount_climate_risk_hazard_enum.COASTAL_FLOOD:
      return t('Enum_SustainaccountClimateRiskHazardEnum-COASTAL_FLOOD');
    case sustainaccount_climate_risk_hazard_enum.COASTAL_EROSION:
      return t('Enum_SustainaccountClimateRiskHazardEnum-COASTAL_EROSION');
    case sustainaccount_climate_risk_hazard_enum.COLD_WAVE:
      return t('Enum_SustainaccountClimateRiskHazardEnum-COLD_WAVE');
    case sustainaccount_climate_risk_hazard_enum.DROUGHT:
      return t('Enum_SustainaccountClimateRiskHazardEnum-DROUGHT');
    case sustainaccount_climate_risk_hazard_enum.FROST:
      return t('Enum_SustainaccountClimateRiskHazardEnum-FROST');
    case sustainaccount_climate_risk_hazard_enum.GROUND_WATER_FLOOD:
      return t('Enum_SustainaccountClimateRiskHazardEnum-GROUND_WATER_FLOOD');
    case sustainaccount_climate_risk_hazard_enum.HEAT_STRESS:
      return t('Enum_SustainaccountClimateRiskHazardEnum-HEAT_STRESS');
    case sustainaccount_climate_risk_hazard_enum.HEAT_WAVE:
      return t('Enum_SustainaccountClimateRiskHazardEnum-HEAT_WAVE');
    case sustainaccount_climate_risk_hazard_enum.HEAVY_HAIL:
      return t('Enum_SustainaccountClimateRiskHazardEnum-HEAVY_HAIL');
    case sustainaccount_climate_risk_hazard_enum.HEAVY_RAIN:
      return t('Enum_SustainaccountClimateRiskHazardEnum-HEAVY_RAIN');
    case sustainaccount_climate_risk_hazard_enum.PERMAFROST_THAWING:
      return t('Enum_SustainaccountClimateRiskHazardEnum-PERMAFROST_THAWING');
    case sustainaccount_climate_risk_hazard_enum.RIVER_FLOOD:
      return t('Enum_SustainaccountClimateRiskHazardEnum-RIVER_FLOOD');
    case sustainaccount_climate_risk_hazard_enum.SEA_LEVEL_RISE:
      return t('Enum_SustainaccountClimateRiskHazardEnum-SEA_LEVEL_RISE');
    case sustainaccount_climate_risk_hazard_enum.SNOW_STORM:
      return t('Enum_SustainaccountClimateRiskHazardEnum-SNOW_STORM');
    case sustainaccount_climate_risk_hazard_enum.SOIL_EROSION:
      return t('Enum_SustainaccountClimateRiskHazardEnum-SOIL_EROSION');
    case sustainaccount_climate_risk_hazard_enum.SUBSIDENCE:
      return t('Enum_SustainaccountClimateRiskHazardEnum-SUBSIDENCE');
    case sustainaccount_climate_risk_hazard_enum.TORNADO:
      return t('Enum_SustainaccountClimateRiskHazardEnum-TORNADO');
    case sustainaccount_climate_risk_hazard_enum.WILDFIRE:
      return t('Enum_SustainaccountClimateRiskHazardEnum-WILDFIRE');
    case sustainaccount_climate_risk_hazard_enum.WINTER_STORM:
      return t('Enum_SustainaccountClimateRiskHazardEnum-WINTER_STORM');
    case sustainaccount_climate_risk_hazard_enum.AIR_QUALITY:
      return t('Enum_SustainaccountClimateRiskHazardEnum-AIR_QUALITY');
    case sustainaccount_climate_risk_hazard_enum.EARTHQUAKE:
      return t('Enum_SustainaccountClimateRiskHazardEnum-EARTHQUAKE');
    case sustainaccount_climate_risk_hazard_enum.LIGHTNING_STRIKE:
      return t('Enum_SustainaccountClimateRiskHazardEnum-LIGHTNING_STRIKE');
    case sustainaccount_climate_risk_hazard_enum.NOISE:
      return t('Enum_SustainaccountClimateRiskHazardEnum-NOISE');
    case sustainaccount_climate_risk_hazard_enum.RADON:
      return t('Enum_SustainaccountClimateRiskHazardEnum-RADON');
    case sustainaccount_climate_risk_hazard_enum.TEMPERATURE_VARIABILITY:
      return t('Enum_SustainaccountClimateRiskHazardEnum-TEMPERATURE_VARIABILITY');
    case sustainaccount_climate_risk_hazard_enum.CYCLONE_HURRICANE_TYPHOON:
      return t('Enum_SustainaccountClimateRiskHazardEnum-CYCLONE_HURRICANE_TYPHOON');
    case sustainaccount_climate_risk_hazard_enum.PRECIPITATION_HYDROLOGICAL_VARIABILITY:
      return t('Enum_SustainaccountClimateRiskHazardEnum-PRECIPITATION_HYDROLOGICAL_VARIABILITY');
    case sustainaccount_climate_risk_hazard_enum.OCEAN_ACIDIFICATION:
      return t('Enum_SustainaccountClimateRiskHazardEnum-OCEAN_ACIDIFICATION');
    case sustainaccount_climate_risk_hazard_enum.SALINE_INTRUSION:
      return t('Enum_SustainaccountClimateRiskHazardEnum-SALINE_INTRUSION');
    case sustainaccount_climate_risk_hazard_enum.WATER_STRESS:
      return t('Enum_SustainaccountClimateRiskHazardEnum-WATER_STRESS');
    case sustainaccount_climate_risk_hazard_enum.SOIL_DEGRADATION:
      return t('Enum_SustainaccountClimateRiskHazardEnum-SOIL_DEGRADATION');
    case sustainaccount_climate_risk_hazard_enum.SOLIFLUCTION:
      return t('Enum_SustainaccountClimateRiskHazardEnum-SOLIFLUCTION');
    case sustainaccount_climate_risk_hazard_enum.AVALANCHE:
      return t('Enum_SustainaccountClimateRiskHazardEnum-AVALANCHE');
    case sustainaccount_climate_risk_hazard_enum.LANDSLIDE:
      return t('Enum_SustainaccountClimateRiskHazardEnum-LANDSLIDE');
    case sustainaccount_climate_risk_hazard_enum.GLACIAL_LAKE_OUTBURST:
      return t('Enum_SustainaccountClimateRiskHazardEnum-GLACIAL_LAKE_OUTBURST');
  }
};
