import { useQuery } from '@apollo/client';
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { ActionPlanningScenarioScenarioFragment, efficiency_class_enum } from '@predium/client-graphql';
import { COMMON_DATE_FORMATS, formatDateToLocale } from '@predium/utils';
import { useSnackbar } from 'notistack';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { ICONS } from '../../assets/icons';
import EfficiencyClassLabel from '../../components/EfficiencyClassLabel';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Iconify from '../../components/Iconify';
import LoadingScreen from '../../components/LoadingScreen';
import { createStandardErrorSnackbar } from '../../components/NotistackProvider';
import OverflowText from '../../components/OverflowText';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import Unavailable from '../../components/Unavailable';
import PreDialog from '../../components/presentations/PreDialog/PreDialog';
import TablePaginationStandard from '../../components/table/TablePaginationStandard';
import TableSearchActionToolbar from '../../components/table/TableSearchActionToolbar';
import { PermissionType } from '../../contexts/PermissionContext';
import { GET_ANALYZED_SCENARIO, GET_SCENARIO, GET_SCENARIOS_BASIC_DETAILS } from '../../graphql/ActionPlanning.queries';
import usePermissions from '../../hooks/usePermissions';
import useSessionData from '../../hooks/useSessionData';
import useTable, { applySortFilter } from '../../hooks/useTable';
import { useLanguage } from '../../provider/LanguageProvider';
import { PATHS } from '../../routes';
import ActionPlanActionMenu from '../../sections/Scenarios/ActionPlan/ActionPlanMenu/ActionPlanActionMenu';
import CreateActionPlanModal from '../../sections/Scenarios/ActionPlan/CreateActionPlanModal';
import ScenarioImpactKPIs from '../../sections/Scenarios/Scenario/Analysis/ScenarioImpactKPIs';
import ScenarioActionMenu from '../../sections/Scenarios/Scenario/ScenarioActionMenu';
import ScenarioActionMenuWithoutCompareScenario from '../../sections/Scenarios/Scenario/ScenarioActionMenuWithoutCompareScenario';
import ScenariosTableHead, { HeadLabel } from '../../sections/Scenarios/Scenario/ScenariosTableHead';
import ScenarioImpactGraphs from '../../sections/Scenarios/Scenario/Sections/ScenarioImpactGraphs';
import NoDataPlaceholder from '../../sections/Scenarios/Scenario/Sections/ScenarioImpactGraphs/NoDataPlaceholder';
import { getScenarioNames } from '../../sections/Scenarios/Scenario/utils';
import { getActionPlanTotalActionsCount } from '../../utils/helpers';

export default function ActionPlanningScenario() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { language } = useLanguage();
  const { enqueueSnackbar } = useSnackbar();

  // TABLE STATE
  const {
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    order,
    orderBy,
    filterName,
    handleFilterByName,
    handleRequestSort,
  } = useTable({
    defaultOrderBy: 'street',
    defaultRowsPerPage: 25,
  });
  const { checkPortfolioPermission, checkBuildingPermission } = usePermissions();
  const {
    serverSideFeatureFlags: { SCENARIO_IMPACT_ANALYSIS, SCENARIO_COMPARISON },
  } = useSessionData();

  const [modalOpen, setModalOpen] = useState(false);

  const displayStandardMutationQueryErrorSnackbar = createStandardErrorSnackbar(t);

  // QUERY:
  const { data: scenarioQueryData, loading } = useQuery(GET_SCENARIO, {
    //@ts-ignore
    variables: { scenarioId: parseInt(id), year: new Date().getFullYear() },
    onError: () => displayStandardMutationQueryErrorSnackbar(enqueueSnackbar),
  });

  const { data: scenarios, loading: scenariosLoading } = useQuery(GET_SCENARIOS_BASIC_DETAILS, {
    onError: () => displayStandardMutationQueryErrorSnackbar(enqueueSnackbar),
  });

  const { data: analyzedScenario, loading: analyzedScenarioLoading } = useQuery(GET_ANALYZED_SCENARIO, {
    //@ts-ignore
    variables: { scenario_id: parseInt(id) },
    onError: () => displayStandardMutationQueryErrorSnackbar(enqueueSnackbar),
    skip: !id,
    fetchPolicy: 'cache-and-network',
  });

  const actionPlans = useMemo(() => scenarioQueryData?.scenario_by_pk?.action_plans ?? [], [scenarioQueryData]);
  const totalActions = useMemo(() => getActionPlanTotalActionsCount(actionPlans), [actionPlans]);

  if (loading || scenariosLoading || analyzedScenarioLoading) {
    return <LoadingScreen />;
  }

  //@ts-ignore
  if (!scenarioQueryData.scenario_by_pk) {
    return <Unavailable title={t('ActionPlanningScenario_ScenarioNotAvailable')} />;
  }

  //@ts-ignore
  const scenario: ActionPlanningScenarioScenarioFragment = scenarioQueryData.scenario_by_pk;

  const buildingCount = scenario.portfolio.economic_units_aggregate.aggregate?.count;

  const hasScenarioEditDeletePermission = scenario?.portfolio
    ? checkPortfolioPermission(scenario.portfolio.id, PermissionType.READ)
    : false;

  const hasActionPlanDeletePermission = (buildingId: number) => {
    return buildingId ? checkBuildingPermission(buildingId, PermissionType.READ) : false;
  };

  //TABLE
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - actionPlans.length) : 0;

  const filteredRows = applySortFilter({
    data: actionPlans,
    nameFilter: {
      //@ts-ignore
      fieldName: (actionPlan) => actionPlan.building.address.street,
      filterValue: filterName,
    },
    orderOptions: {
      order,
      orderBy,
    },
  });

  const isNotFound = !filteredRows.length && Boolean(filterName);

  const handleRowClick = (id: number) => {
    navigate(PATHS.actionPlanning.actionPlan({ scenarioId: scenario.id, id }));
  };

  const createdAt = scenario.created_at
    ? formatDateToLocale(scenario.created_at, COMMON_DATE_FORMATS.DAY_MONTH_YEAR, language)
    : '–';

  const owner = scenario.owner ? `${scenario.owner.first_name} ${scenario.owner.last_name}` : '–';

  const metadata = [
    {
      label: t('General_Portfolio'),
      value: scenario.portfolio.name,
      icon: ICONS.PORTFOLIO,
    },
    {
      label: t('General_Buildings'),
      value: buildingCount,
      icon: ICONS.BUILDING,
    },
    {
      label: `${t('General_CreatedBy')} ・ ${t('General_CreatedAt')}`,
      value: `${owner} ・ ${createdAt}`,
      icon: ICONS.ACCOUNT_OUTLINE,
    },
  ];

  const tableHead: HeadLabel[] = [
    { id: 'street', label: t('General_Building'), sortingDisabled: true, minWidth: 300 },
    { id: 'city', label: t('General_City'), sortingDisabled: true, minWidth: 140 },
    { id: 'postal_code', label: t('General_PostalCodeAbbreviated'), sortingDisabled: true, minWidth: 140 },
    { id: 'efficiency_class', label: t('General_EfficiencyClass'), sortingDisabled: true, minWidth: 180 },
    // FIXME: Sorting is broken because nested properties on the object want to be compared. Disabled for now.
    { id: 'actions_count', label: t('ActionPlanningScenario_NumberOfActions'), sortingDisabled: true, minWidth: 100 },
    { id: 'last_updated', label: t('ActionPlanningScenario_LastChanges'), sortingDisabled: true, minWidth: 180 },
    { id: 'actions', label: '', sortingDisabled: true, minWidth: 100 },
  ];

  return (
    <Page title={t('ActionPlanningScenario_ScenarioHeadingWithName', { name: scenario?.name })}>
      <Container maxWidth="lg">
        <HeaderBreadcrumbs
          heading={t('ActionPlanningScenarios_ScenarioHeadingWithName', { name: scenario?.name })}
          metadata={metadata}
          links={[
            { name: t('PageName_Scenarios'), href: PATHS.actionPlanning.scenarios() },
            { name: t('PageName_Scenario') },
          ]}
          infoText={t('ActionPlanningScenario_ScenarioInfoText')}
          action={
            SCENARIO_COMPARISON ? (
              <ScenarioActionMenu
                scenario={scenario}
                analyzedScenario={analyzedScenario?.getAnalyzedScenario}
                scenarioTotalActions={totalActions}
                hasActionPlans={scenario.action_plans.length > 0}
                canEditOrDelete={hasScenarioEditDeletePermission}
                otherScenarioNames={getScenarioNames(scenarios?.scenario ?? [], scenario.id)}
              />
            ) : (
              <ScenarioActionMenuWithoutCompareScenario
                scenario={scenario}
                hasActionPlans={scenario.action_plans.length > 0}
                canEditOrDelete={hasScenarioEditDeletePermission}
                otherScenarioNames={getScenarioNames(scenarios?.scenario ?? [], scenario.id)}
              />
            )
          }
        />

        {SCENARIO_IMPACT_ANALYSIS && (
          <Grid container spacing={4} mt={2}>
            <Grid item xs={12}>
              <ScenarioImpactKPIs
                analyzedScenario={analyzedScenario?.getAnalyzedScenario}
                actionPlanCount={actionPlans.length}
                totalActions={totalActions}
              />
            </Grid>
            <Grid item xs={12}>
              {analyzedScenario ? (
                <ScenarioImpactGraphs
                  analyzedScenario={analyzedScenario?.getAnalyzedScenario}
                  totalActions={totalActions}
                />
              ) : (
                //TODO: Adapt new APIs and add loaders for graph sections
                <NoDataPlaceholder message={t('General_NoData')} />
              )}
            </Grid>
          </Grid>
        )}

        <Card sx={{ p: 0, mt: 4 }}>
          <TableSearchActionToolbar
            numSelected={0}
            filterTerm={filterName}
            onFilterTermChange={handleFilterByName}
            actionLabel={t('General_NewActionPlan')}
            filterPlaceholder={t('General_SearchBuildingPlaceholder')}
            onAction={() => {
              setModalOpen(true);
            }}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <ScenariosTableHead
                  numSelected={0}
                  order={order}
                  orderBy={orderBy}
                  headLabel={tableHead}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { id, actions_aggregate, created_at } = row;

                    const { address, active_building_model } = row.building;
                    const { actions } = row;
                    const actionEfficiencyClass = actions[0]?.building_models[0]?.energy_paths[0]?.efficiency_class_id;
                    return (
                      <TableRow
                        hover
                        key={id}
                        role="checkbox"
                        sx={{ cursor: 'pointer' }}
                        onClick={() => handleRowClick(row.id)}
                      >
                        <TableCell>
                          <OverflowText maxWidth={400} text={address.street} />
                        </TableCell>
                        <TableCell>{address.city}</TableCell>
                        <TableCell>{address.postal_code}</TableCell>
                        <TableCell>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              justifyItems: 'center',
                            }}
                          >
                            <EfficiencyClassLabel
                              efficiencyClass={
                                active_building_model?.energy_paths[0]?.efficiency_class_id ??
                                efficiency_class_enum.NOT_APPLICABLE
                              }
                              sx={{ cursor: 'pointer' }}
                            />
                            {actionEfficiencyClass && (
                              <Iconify icon="bi:arrow-right" width={15} height={15} sx={{ mx: 2 }} />
                            )}
                            {actionEfficiencyClass && (
                              <EfficiencyClassLabel
                                efficiencyClass={actionEfficiencyClass ?? efficiency_class_enum.NOT_APPLICABLE}
                                sx={{ cursor: 'pointer' }}
                              />
                            )}
                          </Box>
                        </TableCell>
                        {/*@ts-ignore */}
                        <TableCell>{actions_aggregate.aggregate.count}</TableCell>
                        <TableCell>
                          {/*@ts-ignore */}
                          {actions_aggregate.aggregate.max?.updated_at
                            ? formatDateToLocale(
                                //@ts-ignore
                                actions_aggregate.aggregate.max?.updated_at,
                                COMMON_DATE_FORMATS.DAY_MONTH_YEAR_TIME,
                                language,
                              )
                            : formatDateToLocale(created_at, COMMON_DATE_FORMATS.DAY_MONTH_YEAR_TIME, language)}
                        </TableCell>
                        <TableCell
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          align="right"
                        >
                          <ActionPlanActionMenu
                            viewType="table"
                            actionPlan={{
                              id,
                              buildingAddress: {
                                street: address.street,
                                postalCode: address.postal_code,
                                city: address.city,
                              },
                              totalActions: actions_aggregate?.aggregate?.count ?? 0,
                              scenarioId: scenario.id,
                            }}
                            hasActionPlanDeletePermission={hasActionPlanDeletePermission(row.building.id)}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}

                  {emptyRows === 0 && !filterName && filteredRows.length === 0 && (
                    <TableRow>
                      <TableCell align="center" colSpan={7} sx={{ height: 300 }}>
                        {t('General_NoData')}
                      </TableCell>
                    </TableRow>
                  )}

                  {isNotFound && <SearchNotFound searchQuery={filterName} />}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePaginationStandard
            count={filteredRows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
          />
        </Card>

        <PreDialog
          type="definedByChildren"
          open={modalOpen}
          maxWidth="md"
          onClose={() => {
            setModalOpen(false);
          }}
          fullWidth
        >
          <CreateActionPlanModal
            onClose={() => {
              setModalOpen(false);
            }}
            scenario={{
              id: scenario.id,
              portfolio_id: scenario.portfolio.id,
            }}
          />
        </PreDialog>
      </Container>
    </Page>
  );
}
