import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ICONS } from '../../../../../assets/icons';
import Iconify from '../../../../../components/Iconify';
import CenterBox from '../../../../../components/common/CenterBox';

const NoClimateRiskReport = () => {
  const { t } = useTranslation();

  return (
    <CenterBox height={400}>
      <Iconify color="text.disabled" fontSize={40} icon={ICONS.FILE_DOCUMENT_CHECK} />
      <Typography mt={3} mb={1} variant="h6" color="text.disabled">
        {t('ClimateRiskV2_NoReport_Title')}
      </Typography>
      <Typography color="text.disabled">{t('ClimateRiskV2_NoReport_Description')}</Typography>
    </CenterBox>
  );
};

export default NoClimateRiskReport;
