import { accessEnum } from '@predium/utils';
import without from 'lodash/without';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import MultiSelectFilterWithSearch, { FilterItem } from '../../../../../components/search/MultiSelectFilterWithSearch';
import { RiskScenario } from '../mapping';

type ScenarioSelectorProps = {
  selectedScenarios: RiskScenario[];
  onChange: (scenarios: RiskScenario[]) => void;
};

const ScenarioSelector = ({ selectedScenarios, onChange }: ScenarioSelectorProps) => {
  const { t } = useTranslation();

  const SCENARIO_OPTIONS = useMemo(() => {
    const descriptions = {
      [RiskScenario.RCP_2_6]: t('ClimateRiskV2_ScenarioSelector-RCP26Description'),
      [RiskScenario.RCP_4_5]: t('ClimateRiskV2_ScenarioSelector-RCP45Description'),
      [RiskScenario.RCP_8_5]: t('ClimateRiskV2_ScenarioSelector-RCP85Description'),
    };

    return Object.values(RiskScenario).map(
      (scenario) =>
        ({
          name: scenario,
          value: scenario,
          description: descriptions[scenario],
        } satisfies FilterItem),
    );
  }, [t]);

  const handleChange = (values: (string | number)[]) => {
    const value = values.toString();
    const scenario = accessEnum(RiskScenario, value);
    const newSelectedScenarios = selectedScenarios.includes(scenario)
      ? without(selectedScenarios, scenario)
      : [...selectedScenarios, scenario];

    // Ensure at least one scenario is selected
    if (newSelectedScenarios.length > 0) {
      onChange(newSelectedScenarios.sort((a, b) => a.localeCompare(b)));
    }
  };

  return (
    <MultiSelectFilterWithSearch
      items={SCENARIO_OPTIONS}
      selectedFilter={selectedScenarios}
      handleSelectionChange={handleChange}
      selectedLabel={t('ClimateRiskV2_ScenarioSelector-SelectedLabel')}
      label={t('ClimateRiskV2_ScenarioSelector-Label')}
      noResultsText={t('ClimateRiskV2_ScenarioSelector-NoResultsText')}
      tempSelection={selectedScenarios}
    />
  );
};

export default ScenarioSelector;
