import { SxProps, Theme } from '@mui/material/styles';

export type ChipStylesProps = {
  theme: Theme;
  isApplyDisabled?: boolean;
  isOpen?: boolean;
  isDefaultSelected?: boolean;
  sx?: SxProps;
  variant?: 'single' | 'multi';
};

export const getChipStyles = ({
  theme,
  isApplyDisabled = false,
  isOpen = false,
  isDefaultSelected = false,
  variant = 'multi',
  sx,
}: ChipStylesProps) => {
  const isMultiSelect = variant === 'multi';

  return {
    background: isMultiSelect
      ? isApplyDisabled
        ? theme.palette.grey[0]
        : theme.palette.grey[500_8]
      : isDefaultSelected
      ? theme.palette.grey[0]
      : theme.palette.grey[500_8],

    borderRadius: 1,
    border: `1px solid ${
      isMultiSelect
        ? isApplyDisabled
          ? isOpen
            ? theme.palette.grey[800]
            : theme.palette.divider
          : 'transparent'
        : isOpen
        ? theme.palette.grey[800]
        : isDefaultSelected
        ? theme.palette.divider
        : 'transparent'
    }`,
    height: 36,
    transition: 'border-color 0.2s, background-color 0.2s',
    '&:hover': {
      borderColor: isMultiSelect
        ? isApplyDisabled
          ? theme.palette.grey[800]
          : 'transparent'
        : isDefaultSelected
        ? theme.palette.grey[800]
        : 'transparent',
      backgroundColor: isMultiSelect
        ? isApplyDisabled
          ? theme.palette.grey[0]
          : theme.palette.grey[500_8]
        : isDefaultSelected
        ? theme.palette.background.paper
        : theme.palette.grey[500_8],
      cursor: 'pointer',
    },
    '& .MuiChip-label': {
      color: 'text.primary',
      display: 'flex',
      minWidth: 0,
      maxWidth: '178px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      alignItems: 'center',
      fontWeight: 600,
      fontSize: 16,
      py: 1,
    },

    ...sx,
  };
};
