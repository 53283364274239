/* eslint-disable @typescript-eslint/ban-ts-comment */
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, InputAdornment, MenuItem, Stack, Typography } from '@mui/material';
import {
  getConsumerEfficiency,
  getSupportedTechnologyTypesForSystemAndSourceType,
  getSupportedTechnologyTypesForSystemType,
} from '@predium/client-lookup';
import { data_source_type_enum, energy_consumer_technology_type_enum, energy_system_type_enum } from '@predium/enums';
import { translateEnergyConsumerTechnologyTypeEnum_dynamic, translateUnitEnum_dynamic } from '@predium/i18n/client';
import { getNetArea, Units } from '@predium/utils';
import { useEffect } from 'react';
import { useForm, useFormContext, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { number, object, string } from 'yup';
import { TechnologyIcons } from '../../../../../../../../assets/images';
import DetailedTooltip from '../../../../../../../../components/DetailedTooltip/DetailedTooltip';
import { FormProvider, RHFAutocomplete, RHFNumberField, RHFSelect } from '../../../../../../../../components/hook-form';
import PreDialog, {
  PreDialogBody,
  PreDialogTitle,
} from '../../../../../../../../components/presentations/PreDialog/PreDialog';
import { SystemRoute } from '../../../../../BuildingTechnology';
import {
  generateSystemFieldNames,
  generateSystemRouteFieldNames,
  generateYearRange,
} from '../../../../../Common/building.util';
import useBuilding from '../../../../../Context/useBuilding';
import { fieldIcon } from '../../TechnologyConsumerRoute';
import { getSelectStyles } from '../EnergySource/EnergySourceEditModal';
import { ConsumerFormType } from './ConsumerEditModal';
import { getExpenditureFactorTooltip } from './utils';

type Props = {
  open: boolean;
  onClose: () => void;
  energySystemRoute: SystemRoute;
  index: number;
  systemIndex: number;
};

const ConsumerEditModal = ({ open, onClose, energySystemRoute, index, systemIndex }: Props) => {
  const { t } = useTranslation();
  const REQUIRED_MESSAGE = t('General_Required');

  const { building } = useBuilding();
  const endYear = new Date().getFullYear();
  const buildingConstructionYear = building.year_constructed;

  const MIN_EFFICIENCY_RANGE_MESSAGE = t('DataCollection_TechnologyEditForm_EfficiencyRangeMinMessage');
  const CONSTRUCTION_YEAR_MESSAGE = t('DataCollection_TechnologyEditForm_ConstructionYearMessage', {
    start: buildingConstructionYear,
    end: endYear,
  });

  //Main system form context
  const { setValue } = useFormContext();

  const { energySource, expenditureFactor, energyConsumerConstructionYear, energySystemType } = energySystemRoute;

  const isVentilationSystem = energySystemType === energy_system_type_enum.VENTILATION;
  const isLightingSystem = energySystemType === energy_system_type_enum.LIGHTING;

  const MAX_RANGE_MESSAGE = t('General_MaxRangeMessage', {
    max: isVentilationSystem ? 1 : isLightingSystem ? 300 : 5,
  });

  const MIN_RANGE_MESSAGE = t('General_MinRangeMessage', {
    min: 0,
  });

  const allConsumerTechnologyTypes = energySource
    ? //@ts-ignore
      getSupportedTechnologyTypesForSystemAndSourceType(energySystemType, energySource)
    : //@ts-ignore
      getSupportedTechnologyTypesForSystemType(energySystemType);

  const defaultValues: ConsumerFormType = {
    consumerTechnologyType: '',

    //@ts-ignore
    expenditureFactor: undefined,
    constructionYear: null,
  };

  const consumerValidationSchema = object().shape({
    consumerTechnologyType: string().required(REQUIRED_MESSAGE).oneOf(allConsumerTechnologyTypes, REQUIRED_MESSAGE),
    expenditureFactor: number()
      .min(isVentilationSystem ? 0 : 0.001, isVentilationSystem ? MIN_RANGE_MESSAGE : MIN_EFFICIENCY_RANGE_MESSAGE)
      .max(isVentilationSystem ? 1 : isLightingSystem ? 300 : 5, MAX_RANGE_MESSAGE),
    constructionYear: number()
      .nullable()

      //@ts-ignore
      .min(buildingConstructionYear, CONSTRUCTION_YEAR_MESSAGE)
      .max(endYear, CONSTRUCTION_YEAR_MESSAGE),
  });

  const methods = useForm<ConsumerFormType>({
    resolver: yupResolver(consumerValidationSchema),
    defaultValues: defaultValues,
    mode: 'onChange',
  });

  const { handleSubmit, reset, getFieldState, watch, control, setValue: setConsumerValue } = methods;

  const { isDirty } = useFormState({
    control,
  });

  const handleClose = () => {
    onClose();
    reset(defaultValues);
  };

  const isConsumerTechnologyTypeDirty = getFieldState('consumerTechnologyType').isDirty;
  const isConstructionYearDirty = getFieldState('constructionYear').isDirty;
  const isExpenditureDirty = getFieldState('expenditureFactor').isDirty;

  const newConsumerTechnologyType = watch('consumerTechnologyType');
  const newConstructionYear = watch('constructionYear');
  const newExpenditureFactor = watch('expenditureFactor');

  useEffect(() => {
    if (
      isConsumerTechnologyTypeDirty &&
      (energySystemType === energy_system_type_enum.HEATING ||
        energySystemType === energy_system_type_enum.HOT_WATER ||
        energySystemType === energy_system_type_enum.LIGHTING ||
        energySystemType === energy_system_type_enum.VENTILATION)
    ) {
      const efficiency = getConsumerEfficiency({
        energyConsumerTechnologyType: newConsumerTechnologyType as energy_consumer_technology_type_enum,
        energySystemType,

        //@ts-ignore
        yearConstructed: buildingConstructionYear,

        area: getNetArea(building.areas),
      });

      setConsumerValue('expenditureFactor', efficiency, {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConsumerTechnologyTypeDirty, newConsumerTechnologyType, setValue]);

  const onSubmit = (data: ConsumerFormType) => {
    handleClose();

    const {
      consumerTechnologyFieldname,
      consumerConstructionYearFieldname,
      expenditureFactorFieldname,
      energyConsumerTypeFieldname,

      //@ts-ignore
    } = generateSystemRouteFieldNames(energySystemType, systemIndex, index);

    setValue(energyConsumerTypeFieldname, energySystemType, { shouldDirty: true }); //default value

    if (isConsumerTechnologyTypeDirty) {
      setValue(consumerTechnologyFieldname, data.consumerTechnologyType, {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
    if (isConstructionYearDirty) {
      setValue(consumerConstructionYearFieldname, data.constructionYear ?? null, {
        shouldDirty: true,
      });
    }
    if (isExpenditureDirty) {
      setValue(expenditureFactorFieldname, data.expenditureFactor, {
        shouldDirty: true,
      });
    }

    if (isDirty) {
      //@ts-ignore
      const { dataSourceTypeFieldname } = generateSystemFieldNames(energySystemType, systemIndex);

      setValue(dataSourceTypeFieldname, data_source_type_enum.MANUAL, {
        shouldDirty: true,
      });
    }
  };

  //@ts-ignore
  const autocompleteOptions = generateYearRange(buildingConstructionYear);

  return (
    <PreDialog open={open} onClose={onClose} fullWidth type="definedByChildren">
      <FormProvider
        methods={methods}
        onSubmit={(event) => {
          event.stopPropagation();
          event.preventDefault();
          handleSubmit(onSubmit)();
        }}
      >
        <PreDialogBody
          dialogtitle={
            <PreDialogTitle
              icon={<Box component="img" src={TechnologyIcons.generator} mt={0.25} mr={1} width={24} height={24} />}
              title={t('DataCollection_Technology_AddConsumerTitle')}
            />
          }
          content={
            <Box my={1}>
              <Grid container spacing={2}>
                <Grid item xs={12} mb={2}>
                  <RHFSelect
                    select
                    label={t('General_Consumer')}
                    name="consumerTechnologyType"
                    sx={getSelectStyles(isConsumerTechnologyTypeDirty)}
                  >
                    {allConsumerTechnologyTypes.map((type) => (
                      <MenuItem sx={{ height: 40 }} key={type} value={type}>
                        {isConsumerTechnologyTypeDirty && newConsumerTechnologyType === type && fieldIcon}
                        <Box>{translateEnergyConsumerTechnologyTypeEnum_dynamic(type, t, 1)}</Box>
                      </MenuItem>
                    ))}
                  </RHFSelect>
                </Grid>

                <Grid item xs={12} mb={2}>
                  <RHFAutocomplete
                    name="constructionYear"
                    label={t('General_YearOfInstallation')}
                    options={autocompleteOptions}
                    type="number"
                    InputProps={{
                      startAdornment:
                        isConstructionYearDirty && newConstructionYear !== energyConsumerConstructionYear
                          ? fieldIcon
                          : null,
                    }}
                  />
                </Grid>

                {energySystemType !== energy_system_type_enum.COOLING && (
                  <Grid item xs={12} mb={2}>
                    <RHFNumberField
                      name={'expenditureFactor'}
                      label={
                        isVentilationSystem
                          ? t('General_HeatRecovery')
                          : isLightingSystem
                          ? t('General_LightYield')
                          : t('General_ExpenditureFactor')
                      }
                      variant="outlined"
                      InputProps={{
                        startAdornment:
                          isExpenditureDirty && newExpenditureFactor !== expenditureFactor ? fieldIcon : null,

                        endAdornment: (
                          <InputAdornment position="end">
                            <Stack direction="row" alignItems="center" spacing={1}>
                              {isLightingSystem ? (
                                <Typography>{translateUnitEnum_dynamic(Units.lightYield, t)}</Typography>
                              ) : null}
                              <DetailedTooltip
                                hide={!getExpenditureFactorTooltip(t, building.address.country_id)}
                                labels={{
                                  title: isVentilationSystem
                                    ? t('General_HeatRecovery')
                                    : isLightingSystem
                                    ? t('General_LightYield')
                                    : t('General_ExpenditureFactor'),
                                  body: isVentilationSystem
                                    ? t('Education_HeatRecoveryBody')
                                    : isLightingSystem
                                    ? t('Education_LightYieldBody')
                                    : getExpenditureFactorTooltip(t, building.address.country_id),
                                }}
                                size={20}
                              />
                            </Stack>
                          </InputAdornment>
                        ),
                        autoComplete: 'off',
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            </Box>
          }
          actions={
            <>
              <Button variant="outlined" onClick={handleClose}>
                {t('General_Cancel')}
              </Button>
              <Button type="submit" variant="contained">
                {t('DataCollection_Technology_EnergySourceEditDone')}
              </Button>
            </>
          }
        ></PreDialogBody>
      </FormProvider>
    </PreDialog>
  );
};

export default ConsumerEditModal;
