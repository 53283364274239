/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TechnologyIcons } from '../../../../../../../../assets/images';
import { SystemRoute } from '../../../../../BuildingTechnology';
import { generateSystemRouteFieldNames } from '../../../../../Common/building.util';
import { TechnologyUnitSubTitle } from '../../TechnologyConsumerRoute';
import TechnologyUnitTable, { TableData } from '../../TechnologyUnitTable';

import { translateEnergyStorageTypeEnum } from '@predium/i18n/client';
import { Units } from '@predium/utils';
import { useLanguage } from '../../../../../../../../provider/LanguageProvider';
import useBuilding from '../../../../../Context/useBuilding';
import EmptyTechnologyUnitCard from '../EmptyTechnologyUnit';
import TechnologyUnitCard from '../TechnologyUnit';
import StorageAddModal from './StorageAddModal';
import StorageEditModal from './StorageEditModal';

type Props = {
  energySystemRoute: SystemRoute;
  routeIndex: number;
  systemIndex: number;
};

const StorageCard: React.FC<Props> = ({ energySystemRoute, routeIndex, systemIndex }) => {
  const { t } = useTranslation();
  const { localize } = useLanguage();
  const { hasEditAccess } = useBuilding();

  const [editStorageModalOpen, setEditStorageModalOpen] = useState<boolean>(false);
  const [addStorageModalOpen, setAddStorageModalOpen] = useState<boolean>(false);

  const { energyStorageType, energyStorageHeatLoss, storageId, energySystemType, energyStorageConstructionYear } =
    energySystemRoute;

  const {
    energyStorageTypeFieldname,
    energyStorageHeatLossFieldname,
    energyStorageConstructionYearFieldname,
    storageDeletedFieldname,

    //@ts-ignore
  } = generateSystemRouteFieldNames(energySystemType, systemIndex, routeIndex);
  const { getFieldState } = useFormContext();

  const isStorageTypeDirty = getFieldState(energyStorageTypeFieldname).isDirty;
  const isStorageHeatLossDirty = getFieldState(energyStorageHeatLossFieldname).isDirty;
  const isStorageConstructionYearDirty = getFieldState(energyStorageConstructionYearFieldname).isDirty;
  const isStorageDeletedDirty = getFieldState(storageDeletedFieldname).isDirty;

  const storageTableData: TableData[] = [
    {
      label: t('General_YearOfInstallation'),
      value: energyStorageConstructionYear ?? '-',
      isDirty: isStorageConstructionYearDirty,
      tooltipProps: {
        labels: {
          title: t('General_YearOfInstallation'),
          body: t('Education_YearOfInstallationBody'),
        },
      },
    },
    {
      label: t('General_StorageLoss'),
      value: localize.formatAsFloat(energyStorageHeatLoss, { unit: Units.energyPerAreaYear }),
      isDirty: isStorageHeatLossDirty,
      tooltipProps: {
        labels: {
          title: t('General_StorageLoss'),
          body: t('Education_StorageLossBody'),
        },
      },
    },
  ];

  const title = t('General_Storage');
  const icon = TechnologyIcons.storage;

  return (
    <>
      {storageId ? (
        <TechnologyUnitCard
          title={title}
          subtitle={
            <TechnologyUnitSubTitle
              //@ts-ignore
              subtitle={translateEnergyStorageTypeEnum(energyStorageType) ?? '–'}
              isDirty={isStorageTypeDirty}
              isDeleted={isStorageDeletedDirty}
            />
          }
          icon={icon}
          body={<TechnologyUnitTable data={storageTableData} isDeleted={isStorageDeletedDirty} />}
          onSemanticButtonClick={() => {
            hasEditAccess && setEditStorageModalOpen(true);
          }}
          isDeleted={isStorageDeletedDirty}
        />
      ) : (
        <EmptyTechnologyUnitCard
          icon={icon}
          title={title}
          onButtonClick={() => {
            hasEditAccess && setAddStorageModalOpen(true);
          }}
          noDataText={t('DataCollection_Technology_StorageNotAvailable')}
        />
      )}

      <StorageAddModal
        open={addStorageModalOpen}
        onClose={() => setAddStorageModalOpen(false)}
        energySystemRoute={energySystemRoute}
        index={routeIndex}
        systemIndex={systemIndex}
      />

      <StorageEditModal
        open={editStorageModalOpen}
        onClose={() => setEditStorageModalOpen(false)}
        energySystemRoute={energySystemRoute}
        systemIndex={systemIndex}
      />
    </>
  );
};

export default StorageCard;
