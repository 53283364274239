import { Button, Checkbox, Stack, Typography } from '@mui/material';
import { getFilename } from '@predium/utils';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FileThumbnail, { FileFormat } from '../../../../../components/FileThumbnail';
import Iconify from '../../../../../components/Iconify';
import { PreDialogBody } from '../../../../../components/presentations/PreDialog/PreDialog';
import { useLanguage } from '../../../../../provider/LanguageProvider';

type ExportFiles = {
  exportExcel: boolean;
  exportDocx: boolean;
};

export type OnExport = (args: ExportFiles) => void;

type Props = {
  onClose: VoidFunction;
  onExport: OnExport;
  hasDocx: boolean;
};

const ExportClimateRiskDialogContent = ({ onClose, onExport, hasDocx }: Props) => {
  const { t } = useTranslation();
  const { language } = useLanguage();

  const files = [
    {
      name: getFilename(['ClimateRiskV2_Report', '.xlsx'], language),
      fileFormat: FileFormat.XLSX,
      title: t('ClimateRiskV2_ExportDialog-ExcelTitle'),
      description: t('ClimateRiskV2_ExportDialog-ExcelDescription'),
      exportKey: 'exportExcel' as const,
    },
    ...(hasDocx
      ? [
          {
            name: getFilename(['ClimateRiskV2_Document', '.docx'], language),
            fileFormat: FileFormat.DOCX,
            title: t('ClimateRiskV2_ExportDialog-DocxTitle'),
            description: t('ClimateRiskV2_ExportDialog-DocxDescription'),
            exportKey: 'exportDocx' as const,
          },
        ]
      : []),
  ];

  const [selectedFiles, setSelectedFiles] = useState<ExportFiles>(
    Object.fromEntries(files.map((file) => [file.exportKey, true])) as ExportFiles,
  );

  const toggleFileSelection = (exportKey: keyof ExportFiles) => {
    setSelectedFiles((prev) => ({
      ...prev,
      [exportKey]: !prev[exportKey],
    }));
  };

  const handleExport = () => {
    onExport(selectedFiles);

    onClose();
  };

  return (
    <PreDialogBody
      dialogtitle={t('ClimateRiskV2_ExportDialog-Title')}
      content={
        <Stack spacing={2}>
          <Stack borderRadius={1} overflow="hidden" border="1px solid" borderColor="divider">
            <Stack direction="row" alignItems="center" bgcolor="background.neutral" p={2}>
              <Typography variant="subtitle2" color="text.secondary">
                {t('ClimateRiskV2_ExportDialog-Description')}
              </Typography>
            </Stack>
            <Stack p={2} spacing={2}>
              {files.map((file) => (
                <Stack key={file.name} direction="row" alignItems="center" gap={1}>
                  <Checkbox
                    checked={selectedFiles[file.exportKey]}
                    onChange={() => toggleFileSelection(file.exportKey)}
                  />
                  <FileThumbnail format={file.fileFormat} />
                  <Stack>
                    <Typography variant="body1">{file.title}</Typography>
                    <Typography variant="body2" color="text.secondary">
                      {file.description}
                    </Typography>
                  </Stack>
                </Stack>
              ))}
            </Stack>
          </Stack>
        </Stack>
      }
      actions={
        <>
          <Button variant="outlined" color="primary" onClick={onClose}>
            {t('General_Cancel')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            startIcon={<Iconify icon={'eva:cloud-download-outline'} width={20} height={20} />}
            disabled={Object.values(selectedFiles).every((value) => !value)}
            onClick={handleExport}
          >
            {t('General_ExportData')}
          </Button>
        </>
      }
    />
  );
};

export default ExportClimateRiskDialogContent;
