import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Button,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { EditBuildingDataMutationVariables } from '@predium/client-graphql';
import { building_state_enum, data_source_type_enum, sub_building_class_enum } from '@predium/enums';
import { translateBuildingStateEnum } from '@predium/i18n/client';
import { ensureDefined } from '@predium/utils';
import { useSnackbar } from 'notistack';
import { FC, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { ICONS } from '../../../../assets/icons';
import { FormProvider, RHFNumberField, RHFSelect, RHFTextField } from '../../../../components/hook-form';
import RHFRadio from '../../../../components/hook-form/RHFRadio';
import Iconify from '../../../../components/Iconify';
import { DelayedLoading } from '../../../../components/Loading';
import { SnackbarTimeouts } from '../../../../components/NotistackProvider';
import PreDialog, { PreDialogBody } from '../../../../components/presentations/PreDialog/PreDialog';
import { WarningModal } from '../../../../components/WarningModal';
import { EDIT_BUILDING_DATA } from '../../../../graphql/DataCollection.mutations';
import { GET_BUILDING } from '../../../../graphql/DataCollection.queries';
import { useLanguage } from '../../../../provider/LanguageProvider';
import { getPostalCodeValidation } from '../../../../utils/formUtils';
import ValuationForm, { getBuildingValuationSchema } from '../../Buildings/BuildingCreation/Components/ValuationForm';
import useBuilding from '../Context/useBuilding';

type BuildingEditingDialogProps = {
  open: boolean;
  onClose: () => void;
};

type Props = {
  onDirtyChange: (isDirty: boolean) => void;
  onSubmit: (variables: EditBuildingDataMutationVariables) => void;
};

const BuildingEditingDialogContent = ({ onSubmit, onDirtyChange }: Props) => {
  const { building } = useBuilding();
  const { t } = useTranslation();
  const theme = useTheme();
  const { language } = useLanguage();

  const buildingValuationSchema = getBuildingValuationSchema(t, language);

  const schema = yup.object({
    address: yup.object({
      street: yup.string().required(t('General_Required')),
      postal_code: getPostalCodeValidation(t)(building.address.country_id),
      city: yup.string().required(t('General_Required')),
    }),
    building: yup.object({
      building_state_id: yup.mixed<building_state_enum>().oneOf(Object.values(building_state_enum)).required(),
      monument_protection: yup.boolean().required(),
      leasehold: yup.boolean().required(),
      heritage_district: yup.boolean().required(),
      milieu_protection: yup.boolean().required(),
      customer_external_identifier: yup.string().nullable(true),
    }),
    sub_building: yup.object({
      units_residential: yup.number().required(),
      units_commercial: yup.number().required(),
      units_data_source: yup.mixed<data_source_type_enum>().oneOf(Object.values(data_source_type_enum)).required(),
    }),
    building_valuations: buildingValuationSchema,
  });

  const subBuildingClass = ensureDefined(building.sub_buildings[0].sub_building_class);

  const defaultValues = useMemo(
    () => ({
      address: {
        street: building.address.street,
        postal_code: building.address.postal_code,
        city: building.address.city,
      },
      building: {
        building_state_id: building.building_state_id,
        monument_protection: building.monument_protection,
        leasehold: building.leasehold,
        heritage_district: building.heritage_district,
        milieu_protection: building.milieu_protection,
        customer_external_identifier: building.customer_external_identifier,
      },
      sub_building: {
        units_residential: building.sub_buildings[0].units_residential,
        units_commercial: building.sub_buildings[0].units_commercial,
        units_data_source: building.sub_buildings[0].units_data_source,
      },
      building_valuations: buildingValuationSchema.cast(building.building_valuations, { stripUnknown: true }),
    }),
    [building, buildingValuationSchema],
  );

  const methods = useForm<yup.InferType<typeof schema>>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const { formState, setValue, watch } = methods;
  const { isDirty } = formState;

  const handleSubmit = methods.handleSubmit((data) => {
    const buildingValuations = data.building_valuations;
    const existingValuationIds = building.building_valuations.map((v) => v.id);

    const valuations = buildingValuations.map((valuation) => {
      return {
        ...valuation,
        building_id: building.id,
        valuation_at: valuation.valuation_at.toISOString(),
      };
    });

    const formValuationIds = buildingValuations.filter((v) => v.id).map((v) => v.id);

    const deletedValuationIds = existingValuationIds.filter((id) => !formValuationIds.includes(id)).map(Number);

    onSubmit({
      buildingId: building.id,
      buildingData: data.building,
      subBuildingId: building.sub_buildings[0].id,
      subBuildingData: data.sub_building,
      addressId: building.address.id,
      addressData: data.address,
      valuations,
      deletedValuationIds,
    });
  });

  const unitsCommercial = watch('sub_building.units_commercial');
  const unitsResidential = watch('sub_building.units_residential');

  useEffect(() => {
    if (defaultValues.sub_building.units_data_source === data_source_type_enum.MANUAL) {
      return;
    }

    const didUnitsChange =
      unitsCommercial !== defaultValues.sub_building.units_commercial ||
      unitsResidential !== defaultValues.sub_building.units_residential;

    setValue(
      'sub_building.units_data_source',
      didUnitsChange ? data_source_type_enum.MANUAL : data_source_type_enum.APPROXIMATED,
    );
  }, [unitsCommercial, unitsResidential, setValue, defaultValues]);

  useEffect(() => {
    onDirtyChange(isDirty);
  }, [isDirty, onDirtyChange]);

  return (
    <PreDialogBody
      dialogtitle={t('DataCollectionEditBuilding_Title')}
      actions={
        <Stack direction="row" spacing={1}>
          <Button variant="contained" onClick={handleSubmit}>
            {t('General_Save')}
          </Button>
        </Stack>
      }
      content={
        <FormProvider methods={methods} onSubmit={handleSubmit}>
          <Grid container spacing={2.5}>
            <Grid item xs={12}>
              <Alert
                severity="info"
                sx={{
                  bgcolor: (theme) => theme.palette.info.main + '26',
                  color: theme.palette.info.dark,
                  border: `1px solid ${theme.palette.info.main}14`,
                  '.MuiAlert-icon': {
                    color: theme.palette.info.main,
                  },
                }}
              >
                {t('DataCollectionEditBuilding_Info')}
              </Alert>
              <Typography sx={{ margin: '16px 0' }} variant="subtitle1">
                {t('BuildingCreation_CoreData')}
              </Typography>
              <Grid item xs={6}>
                <RHFRadio
                  name="building.building_state_id"
                  row
                  options={[
                    {
                      value: building_state_enum.ACQUISITION,
                      label: translateBuildingStateEnum(building_state_enum.ACQUISITION),
                    },
                    {
                      value: building_state_enum.INVENTORY,
                      label: translateBuildingStateEnum(building_state_enum.INVENTORY),
                    },
                  ]}
                  sx={{ margin: '0 12px 12px' }}
                />
              </Grid>
            </Grid>

            <Grid item xs={6}>
              <RHFTextField name="address.street" label={t('General_StreetCommaNumber')} />
            </Grid>
            <Grid item xs={3}>
              <RHFNumberField
                name="address.postal_code"
                label={t('General_PostalCode')}
                allowDecimals={false}
                allowLeadingZeros
                valueAsText
              />
            </Grid>
            <Grid item xs={3}>
              <RHFTextField name="address.city" label={t('General_City')} />
            </Grid>
            <Grid item xs={6}>
              <RHFTextField
                name="building.customer_external_identifier"
                label={t('General_CustomerExternalIdentifier')}
              />
            </Grid>
            <Grid item xs={6}>
              {subBuildingClass === sub_building_class_enum.COMMERCIAL && (
                <RHFNumberField
                  name="sub_building.units_commercial"
                  label={t('DataCollectionSubBuildingValues_UnitsCommercial')}
                />
              )}
              {subBuildingClass === sub_building_class_enum.RESIDENTIAL && (
                <RHFNumberField
                  name="sub_building.units_residential"
                  label={t('DataCollectionSubBuildingValues_UnitsResidential')}
                />
              )}
            </Grid>

            <Grid item xs={12}>
              <Accordion
                defaultExpanded
                sx={{
                  '&.Mui-expanded': {
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                  },
                  '& .MuiAccordionSummary-content.Mui-expanded': {
                    margin: 0,
                  },
                }}
              >
                <AccordionSummary
                  sx={{
                    padding: 0,
                    margin: 0,
                    '& .MuiAccordionSummary-content.Mui-expanded': {
                      minHeight: '24px',
                    },
                  }}
                  expandIcon={
                    <IconButton sx={{ marginTop: '16px' }}>
                      <Iconify icon={ICONS.CHEVRON_DOWN} width={20} height={20} />
                    </IconButton>
                  }
                >
                  <Typography variant="subtitle1">{t('DataCollectionEditBuilding_BuildingValue')}</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ p: 0 }}>
                  <ValuationForm name="building_valuations" />
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={12} sx={{ p: 0 }}>
              <Accordion
                sx={{
                  '&.Mui-expanded': {
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                  },
                  '& .MuiAccordionSummary-content.Mui-expanded': {
                    margin: 0,
                  },
                  '& .MuiAccordionSummary-root.Mui-expanded': {
                    minHeight: '48px',
                  },
                }}
              >
                <AccordionSummary
                  sx={{
                    padding: 0,
                    margin: 0,
                    '& .MuiAccordionSummary-content.Mui-expanded': {
                      minHeight: '24px',
                    },
                  }}
                  expandIcon={
                    <IconButton>
                      <Iconify icon={ICONS.CHEVRON_DOWN} width={20} height={20} />
                    </IconButton>
                  }
                >
                  <Typography variant="subtitle1">{t('General_AdditionalInformation')}</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ p: 0 }}>
                  <Grid container spacing={2} sx={{ paddingTop: '16px' }}>
                    <Grid item xs={6}>
                      <RHFSelect label={t('General_MonumentProtection')} name="building.monument_protection">
                        <MenuItem value="true">{t('General_Yes')}</MenuItem>
                        <MenuItem value="false">{t('General_No')}</MenuItem>
                      </RHFSelect>
                    </Grid>
                    <Grid item xs={6}>
                      <RHFSelect label={t('General_Leasehold')} name="building.leasehold">
                        <MenuItem value="true">{t('General_Yes')}</MenuItem>
                        <MenuItem value="false">{t('General_No')}</MenuItem>
                      </RHFSelect>
                    </Grid>
                    <Grid item xs={6}>
                      <RHFSelect label={t('General_HeritageDistrict')} name="building.heritage_district">
                        <MenuItem value="true">{t('General_Yes')}</MenuItem>
                        <MenuItem value="false">{t('General_No')}</MenuItem>
                      </RHFSelect>
                    </Grid>
                    <Grid item xs={6}>
                      <RHFSelect label={t('General_MilieuProtection')} name="building.milieu_protection">
                        <MenuItem value="true">{t('General_Yes')}</MenuItem>
                        <MenuItem value="false">{t('General_No')}</MenuItem>
                      </RHFSelect>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </FormProvider>
      }
    />
  );
};

const BuildingEditingDialog: FC<BuildingEditingDialogProps> = ({ open, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [openCancelEditDialog, setOpenCancelEditDialog] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);

  const { t } = useTranslation();

  const { building } = useBuilding();
  const buildingId = building.id;

  const [mutateBuilding, { loading }] = useMutation(EDIT_BUILDING_DATA, {
    onCompleted(data) {
      const success = Boolean(
        data?.update_address_by_pk && data?.update_building_by_pk && data?.update_sub_building_by_pk,
      );

      if (!success) {
        enqueueSnackbar(t('General_BuildingUpdated-error'), {
          variant: 'error',
          autoHideDuration: SnackbarTimeouts.Error,
        });
      } else {
        enqueueSnackbar(t('General_BuildingUpdated-success'), {
          variant: 'success',
          autoHideDuration: SnackbarTimeouts.Success,
        });
      }
    },
  });

  const handleClose = () => {
    setIsFormDirty(false);
    setOpenCancelEditDialog(false);
    onClose();
  };

  const handleCloseClick = () => {
    if (isFormDirty) {
      setOpenCancelEditDialog(true);
    } else {
      handleClose();
    }
  };

  const handleSubmit = async (variables: EditBuildingDataMutationVariables) => {
    await mutateBuilding({
      variables,
      refetchQueries: [{ query: GET_BUILDING, variables: { buildingId, year: new Date().getFullYear() } }],
      awaitRefetchQueries: true,
    });

    handleClose();
  };

  return (
    <>
      <PreDialog
        sx={{
          '& .MuiPaper-root': {
            maxWidth: '627px',
          },
        }}
        open={open}
        onClose={handleCloseClick}
        showClose
        type="definedByChildren"
      >
        <BuildingEditingDialogContent onSubmit={handleSubmit} onDirtyChange={setIsFormDirty} />
      </PreDialog>
      <WarningModal
        title={t('General_CancelEditModalTitle')}
        open={openCancelEditDialog}
        description={t('General_CancelEditModalDescription')}
        onAcknowledge={() => handleClose()}
        onClose={() => {
          setOpenCancelEditDialog(false);
        }}
        buttonText={t('General_Discard')}
        cancelText={t('General_Back')}
      />
      {loading && <DelayedLoading fullScreen />}
    </>
  );
};

export default BuildingEditingDialog;
