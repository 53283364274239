/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useMutation } from '@apollo/client';
import { DialogTitle } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SnackbarTimeouts } from '../../../components/NotistackProvider';
import {
  DELETE_EMPTY_EU_AFTER_MOVE_BUILDINGS,
  MOVE_BUILDING_TO_ECONOMIC_UNIT,
} from '../../../graphql/DataCollection.mutations';
import {
  GET_ALL_BUILDINGS_WITH_SUB_BUILDINGS,
  GET_ECONOMIC_UNITS_WITH_BUILDINGS,
} from '../../../graphql/DataCollection.queries';
import { DeleteEmptyEconomicUnit } from './Common/DeleteEmptyEconomicUnit';
import EconomicUnitsListToMove from './EconomicUnitsToMove';

type props = {
  buildingId: number;
  currentAmountBuildingsInEu?: number;
  economicUnitId: number;
  onMoveCompleted: () => void;
  onCancel: () => void;
};

export default function MoveBuildingModal({
  economicUnitId,
  buildingId,
  currentAmountBuildingsInEu,
  onMoveCompleted,
  onCancel,
}: props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedEconomicUnitId, setSelectedEconomicUnitId] = useState<number | null>(null);
  const [keepEmptyEu, setKeepEmptyEu] = useState(false);

  const showErrorSnackbar = () => {
    enqueueSnackbar(t('DataCollection_MoveBuildingDialog-error'), {
      variant: 'error',
      autoHideDuration: SnackbarTimeouts.Error,
    });
  };

  const [removeEmptyEuAfterMove] = useMutation(DELETE_EMPTY_EU_AFTER_MOVE_BUILDINGS, {
    refetchQueries: [{ query: GET_ECONOMIC_UNITS_WITH_BUILDINGS }, { query: GET_ALL_BUILDINGS_WITH_SUB_BUILDINGS }],
  });

  const [moveBuilding] = useMutation(MOVE_BUILDING_TO_ECONOMIC_UNIT, {
    variables: {
      id: buildingId,

      //@ts-ignore
      economicUnitId: selectedEconomicUnitId,
    },
    refetchQueries: [{ query: GET_ECONOMIC_UNITS_WITH_BUILDINGS }, { query: GET_ALL_BUILDINGS_WITH_SUB_BUILDINGS }],
    onError: () => showErrorSnackbar(),
    onCompleted: async (data) => {
      if (!data.update_building_by_pk) {
        showErrorSnackbar();
        return;
      }
      enqueueSnackbar(t('DataCollection_MoveBuildingDialog-success'), {
        variant: 'success',
        autoHideDuration: SnackbarTimeouts.Success,
      });
      if (!keepEmptyEu && currentAmountBuildingsInEu === 1) {
        await removeEmptyEuAfterMove({
          variables: {
            ids: [economicUnitId],
          },
        });
      }

      onMoveCompleted();
    },
  });
  const justifyContent = currentAmountBuildingsInEu !== undefined && currentAmountBuildingsInEu === 1;
  return (
    <>
      <DialogTitle>{t('DataCollection_MoveBuildingDialog-title')}</DialogTitle>
      <EconomicUnitsListToMove
        sx={{ justifyContent: justifyContent ? 'space-between' : 'flex-end' }}
        selectedEconomicUnitId={selectedEconomicUnitId}
        setSelectedEconomicUnitId={setSelectedEconomicUnitId}
        onCancel={onCancel}
        onMove={moveBuilding}
        excludedEconomicUnitIds={[economicUnitId]}
      >
        <DeleteEmptyEconomicUnit
          currentAmountBuildingsInEu={currentAmountBuildingsInEu}
          setKeepEmptyEu={setKeepEmptyEu}
          economicUnitsToggled={true}
        ></DeleteEmptyEconomicUnit>
      </EconomicUnitsListToMove>
    </>
  );
}
