import { Box, FormControlLabel, Radio, RadioGroup, Stack, styled, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import DetailedTooltip from '../../../../../components/DetailedTooltip/DetailedTooltip';

export type CUSTOM_ACTION_TYPE = 'WITH_IMPACT' | 'NO_IMPACT';

export const StyledRadioContainer = styled(Box)(({ theme }) => ({
  display: 'block',
  width: '100%',
  marginBottom: theme.spacing(1),
  '& .radio-button-box': {
    display: 'block',
    width: '100%',
    border: `1px solid ${theme.palette.grey[500_24]}`,
    '&:focus': {
      borderColor: theme.palette.grey[500],
    },

    borderRadius: '8px',
  },
  '& .MuiFormControlLabel-root': {
    margin: 0,
    width: '100%',
    padding: '8px 16px',
    '& .MuiFormControlLabel-label': {
      flex: 1,
    },
  },
  '& .MuiRadio-root': {
    accentColor: theme.palette.primary.main,
  },
}));

type CustomRadioGroupProps = {
  energeticImpact: CUSTOM_ACTION_TYPE | null;
  onChange?: (value: CUSTOM_ACTION_TYPE) => void;
  disabled?: boolean;
};

const CustomRadioGroup: React.FC<CustomRadioGroupProps> = ({ energeticImpact, onChange, disabled = false }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <RadioGroup
      onChange={(e) => onChange?.(e.target.value as CUSTOM_ACTION_TYPE)}
      value={energeticImpact}
      sx={{ gap: 0 }}
    >
      <StyledRadioContainer>
        <Box
          className="radio-button-box"
          sx={{
            backgroundColor: energeticImpact === 'WITH_IMPACT' ? theme.palette.grey[500_12] : 'inherit',
          }}
        >
          <FormControlLabel
            value="WITH_IMPACT"
            control={<Radio />}
            label={
              <Typography variant="body2">
                {t('ActionPlanning_CreateAction-CustomAction-WithEnergeticImpact')}
              </Typography>
            }
            sx={{
              '&:hover': {
                cursor: !disabled ? 'pointer' : 'auto',
                backgroundColor: !disabled ? theme.palette.grey[500_24] : 'inherit',
              },
            }}
            disabled={disabled}
          />
        </Box>
      </StyledRadioContainer>
      <StyledRadioContainer>
        <Box
          className="radio-button-box"
          sx={{
            backgroundColor: energeticImpact === 'NO_IMPACT' ? theme.palette.grey[500_12] : 'inherit',
          }}
        >
          <FormControlLabel
            value="NO_IMPACT"
            control={<Radio />}
            sx={{
              '&:hover': {
                cursor: !disabled ? 'pointer' : 'auto',
                backgroundColor: !disabled ? theme.palette.grey[500_24] : 'inherit',
              },
            }}
            label={
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography variant="body2">
                  {t('ActionPlanning_CreateAction-CustomAction-NoEnergeticImpact')}
                </Typography>
                <DetailedTooltip
                  labels={{
                    title: t('General_MaintenanceAction'),
                    body: t('ActionPlanning_CreateAction-CustomAction-NoEnergeticImpact-Description'),
                  }}
                />
              </Stack>
            }
            disabled={disabled}
          />
        </Box>
      </StyledRadioContainer>
    </RadioGroup>
  );
};

export default CustomRadioGroup;
