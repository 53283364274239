import { Box } from '@mui/material';
import { ActionFragment, BuildingModelFragment, renovation_type_enum } from '@predium/client-graphql';
import { ensureDefined } from '@predium/utils';
import { ActionWithMetricData } from '../../ActionPlanSections/ActionList/ActionListTable';
import CustomActionToggle from '../Components/CustomActionToggle';
import { ConsumerPartialRenovationOption, CustomActionFormRef } from '../CreateAction';
import CustomEnergeticAction from './CustomActions/CustomEnergeticAction';
import MaintenanceAction from './CustomActions/MaintenanceAction';
import { getMaintenanceActionParameters } from './EditActionParameters';

type Props = {
  previousActionBuildingModel: BuildingModelFragment;
  formRef?: CustomActionFormRef;
  implementationTo?: Date;
  resetSimulatedData: () => void;
  loading?: boolean;
  action: ActionWithMetricData;
  affectedParts?: ConsumerPartialRenovationOption[];
  implementationToComponent: JSX.Element;
};

export default function CustomAction({
  previousActionBuildingModel,
  formRef,
  resetSimulatedData,
  loading = false,
  action,
  affectedParts = [],
  implementationToComponent,
}: Props) {
  const renovationType = ensureDefined(action?.renovations[0]?.renovation_type_id);
  const energeticImpact = renovationType === renovation_type_enum.MAINTENANCE ? 'NO_IMPACT' : 'WITH_IMPACT';

  function getActionComponent() {
    switch (renovationType) {
      case renovation_type_enum.CUSTOM:
        return (
          <CustomEnergeticAction
            ref={formRef}
            previousActionBuildingModel={previousActionBuildingModel}
            action={action as ActionFragment}
            affectedParts={affectedParts}
            resetSimulatedData={resetSimulatedData}
            implementationToComponent={implementationToComponent}
          />
        );
      case renovation_type_enum.MAINTENANCE:
        return (
          <MaintenanceAction
            ref={formRef}
            loading={loading}
            implementationToComponent={implementationToComponent}
            parameters={getMaintenanceActionParameters(action)}
            resetSimulatedData={resetSimulatedData}
          />
        );
      default:
        return null;
    }
  }

  return (
    <Box mt={3}>
      <CustomActionToggle energeticImpact={energeticImpact} disabled />

      {getActionComponent()}
    </Box>
  );
}
