import { ReactNode } from 'react';
// form
import { FormProvider as Form, UseFormReturn } from 'react-hook-form';

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
  methods: UseFormReturn<any>;
  onSubmit?: React.DOMAttributes<HTMLFormElement>['onSubmit'];
  /**
   * You can trigger submit of the form from outside by giving form="{formId}" and type="submit" to a button.
   *
   * Example:
   *
   * ```tsx
   * <Box>
   *  <FormProvider formId="my-form" methods={methods} onSubmit={handleSubmit}>
   *   <RHFTextField name="firstName" label="First Name" />
   *  <FormProvider/>
   *  <Button form="my-form" type="submit">Submit</Button>
   * </Box>
   * ```
   */
  formId?: string;
  /**
   * The data-testid attribute for testing with Cypress.
   */
  cyDataTestId?: string;
  fullHeight?: boolean;
};

export default function FormProvider({ children, onSubmit, methods, formId, cyDataTestId, fullHeight }: Props) {
  return (
    <Form {...methods}>
      <form id={formId} onSubmit={onSubmit} data-cy={cyDataTestId} style={{ height: fullHeight ? '100%' : undefined }}>
        {children}
      </form>
    </Form>
  );
}
