import { TextField, TextFieldProps } from '@mui/material';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import { ReactNode } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

export type AutoCompleteOption = { [key: string]: any } | string | number;

type AutoCompletePropsType = AutocompleteProps<AutoCompleteOption, false, false, boolean>;

type Props = {
  name: string;
  label: string;
  type?: 'text' | 'number';
  open?: boolean;
  freeSolo?: boolean;
  popupIcon?: ReactNode;
  forcePopupIcon?: boolean;
  clearOnBlur?: boolean;
  options: AutoCompletePropsType['options'];
  renderOption?: AutoCompletePropsType['renderOption'];
  onInputChange?: AutoCompletePropsType['onInputChange'];
  getOptionLabel?: AutoCompletePropsType['getOptionLabel'];
  filterOptions?: AutoCompletePropsType['filterOptions'];
  onCustomOnChange?: (nextOptionvalue: AutoCompleteOption | null) => void;
} & Omit<AutoCompletePropsType, 'renderInput'> &
  TextFieldProps;

export default function RHFAutocomplete({
  name,
  label,
  type = 'text',
  options,
  freeSolo,
  open,
  popupIcon,
  forcePopupIcon,
  clearOnBlur,
  renderOption,
  getOptionLabel,
  filterOptions,
  onInputChange,
  onCustomOnChange,
  ...other
}: Props) {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Autocomplete
          open={open}
          freeSolo={freeSolo}
          forcePopupIcon={forcePopupIcon}
          popupIcon={popupIcon}
          clearOnBlur={clearOnBlur}
          options={options}
          onInputChange={onInputChange}
          onChange={(_, values) => {
            onCustomOnChange?.(values);
            onChange(values);
          }}
          getOptionLabel={getOptionLabel ? getOptionLabel : (option) => String(option)}
          value={value}
          filterOptions={filterOptions}
          renderOption={renderOption}
          componentsProps={other.componentsProps}
          renderInput={(params) => (
            <TextField
              {...other}
              {...params}
              label={label}
              variant="outlined"
              type={type}
              error={!!error}
              helperText={error ? error.message : ''}
              InputProps={{
                ...params.InputProps,
                ...other?.InputProps,
              }}
              fullWidth
            />
          )}
        />
      )}
    />
  );
}
