/* eslint-disable no-useless-escape */
import { useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  DialogActions,
  DialogTitle,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { export_template_type_enum } from '@predium/enums';
import { getFilename } from '@predium/utils';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { ICONS } from '../../../../../assets/icons';
import FileThumbnail, { FileFormat } from '../../../../../components/FileThumbnail';
import { FormProvider } from '../../../../../components/hook-form';
import RHFRadio from '../../../../../components/hook-form/RHFRadio';
import Iconify from '../../../../../components/Iconify';
import TableRowWithHighlight from '../../../../../components/table/TableRowWithHighlight';
import { GET_BUILDING_EXPORT_TEMPLATES } from '../../../../../graphql/DataCollection.queries';
import usePosthogTracking from '../../../../../hooks/usePosthogTracking';
import useSessionData from '../../../../../hooks/useSessionData';
import { useLanguage } from '../../../../../provider/LanguageProvider';
import { downloadFile } from '../../../../../utils/createDownloadLink';

type FormValues = {
  templateId: string | null;
};

export type TriggerExportParams = {
  export_template_id?: number;
  onSuccess: (fileBody: string) => void;
  onError: () => void;
};

type Props = {
  count: number;
  economicUnitsCount?: number;
  loading?: boolean;
  triggerExport: ({ onSuccess, export_template_id }: TriggerExportParams) => unknown;
  onActionCompleted?: VoidFunction;
  onClose: VoidFunction;
};

export default function BulkExport({
  count,
  loading,
  economicUnitsCount,
  triggerExport,
  onActionCompleted,
  onClose,
}: Props) {
  const { t } = useTranslation();
  const { trackEvent } = usePosthogTracking();
  const { language } = useLanguage();
  const { org } = useSessionData();
  const { data = { export_template: [] }, loading: isQueryLoading } = useQuery(GET_BUILDING_EXPORT_TEMPLATES, {
    variables: {
      orgId: org?.id,
    },
    onCompleted: (data) => {
      if (data.export_template.length) {
        methods.setValue('templateId', data.export_template[0].id.toString());
      }
    },
  });

  const methods = useForm<FormValues>({
    reValidateMode: 'onChange',
    mode: 'onChange',
    defaultValues: {
      templateId: null,
    },
    resolver: yupResolver(
      yup.object({
        templateId: yup.string().required(),
      }),
    ),
  });
  const { isValid } = methods.formState;

  const handleExport = (formValues: FormValues) => {
    const templateId = formValues?.templateId;

    trackEvent('DATA_COLLECTION_EXPORT_DATA', { templateId });
    triggerExport({
      export_template_id: parseInt(templateId || '0'),
      onSuccess: (fileBody: string) => {
        trackEvent('DATA_COLLECTION_EXPORT_DATA_SUCCESS', { templateId });

        let exportName;

        if (!!templateId && data.export_template?.length) {
          exportName = data.export_template?.find((i) => i.id.toString() === templateId)?.name;
        }

        const filename = getFilename([org?.name ?? '', exportName || t('General_DataExport')], language);

        downloadFile(fileBody, 'xlsx', filename);
        if (onActionCompleted) {
          onActionCompleted();
        }
      },
      onError: () => trackEvent('DATA_COLLECTION_EXPORT_DATA_ERROR', { templateId }),
    });
  };

  return (
    <Stack width={600}>
      <DialogTitle>
        {economicUnitsCount
          ? t('DataCollection_EconomicUnitDataExportDialogTitle', { count: economicUnitsCount })
          : t('DataCollection_BuildingDataExportDialogTitle', { count })}
      </DialogTitle>
      <FormProvider methods={methods}>
        <Stack margin={3}>
          <Table sx={{ border: 'solid 1px #919EAB52', borderCollapse: 'separate', borderRadius: '8px' }}>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    borderTopRightRadius: '8px',
                    borderTopLeftRadius: '8px',
                  }}
                >
                  <TableSortLabel disabled>
                    {economicUnitsCount || count > 1
                      ? t('DataCollection_DataExportTableTitleSelection')
                      : t('DataCollection_DataExportTableTitleBuilding')}
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRowWithHighlight sx={{ display: 'block', borderRadius: '8px' }}>
                <TableCell>
                  {!!data?.export_template.length ? (
                    <RHFRadio
                      name="templateId"
                      itemSx={{
                        '&:not(:first-of-type)': {
                          marginTop: 2,
                        },
                      }}
                      options={data?.export_template?.map((item) => ({
                        value: item.id,
                        label: (
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <FileThumbnail format={FileFormat.XLS} sx={{ width: 24, height: 24 }} />
                            <Box pl={2}>
                              <Typography variant="body2">{item.name}</Typography>
                              <Typography variant="body2" color="grey.500">
                                {item.export_type_id === export_template_type_enum.CUSTOM_EXPORT
                                  ? t('DataCollection_DataExportCustomTemplateDescription')
                                  : t('DataCollection_DataExportDefaultTemplateDescription')}
                              </Typography>
                            </Box>
                          </Box>
                        ),
                      }))}
                    />
                  ) : (
                    <Typography variant="body2">{t('DataCollection_DataExportNoTemplates')}</Typography>
                  )}
                </TableCell>
              </TableRowWithHighlight>
            </TableBody>
          </Table>
        </Stack>
        <DialogActions>
          <Button type="button" variant="outlined" onClick={onClose}>
            {t('General_Cancel')}
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isQueryLoading || loading}
            disabled={!isValid}
            startIcon={<Iconify icon={ICONS.PDF_EXPORT} />}
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              methods.handleSubmit(handleExport)();
            }}
          >
            {t('General_ExportData')}
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Stack>
  );
}
