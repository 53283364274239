import { Alert, Box, Button, Grid, IconButton, Typography } from '@mui/material';
import { language_enum } from '@predium/enums';
import { COMMON_DATE_FORMATS, formatDateToLocale } from '@predium/utils';
import { TFunction } from 'i18next';
import { FC } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { FIELD_ICON_STATES, ICONS } from '../../../../../assets/icons';
import { RHFDatePicker, RHFNumberField } from '../../../../../components/hook-form';
import Iconify from '../../../../../components/Iconify';

interface ValuationFormProps {
  name: string;
}

export const getBuildingValuationSchema = (t: TFunction, language: language_enum) =>
  yup
    .array()
    .of(
      yup.object({
        id: yup.number().nullable(),
        value: yup
          .number()
          .moreThan(0, t('General_MoreThanZero'))
          .required(t('General_Required'))
          .typeError(t('General_Required')),
        valuation_at: yup
          .date()
          .required(t('General_Required'))
          .typeError(t('General_Required'))
          .max(
            new Date(),
            t('General_MaxDateMessage', {
              maxDate: formatDateToLocale(new Date(), COMMON_DATE_FORMATS.DAY_MONTH_YEAR, language),
            }),
          ),
      }),
    )
    .required();

const ValuationForm: FC<ValuationFormProps> = ({ name }) => {
  const { t } = useTranslation();
  const { control, watch, setValue } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  return (
    <>
      {fields.map((valuation, index) => (
        <Grid
          container
          item
          spacing={2}
          key={valuation.id || index}
          alignItems="flex-start"
          sx={{ paddingBottom: '16px' }}
        >
          <Grid
            item
            xs={5}
            sx={{
              '&.MuiGrid-root': {
                maxWidth: `calc(50% - 22px)`,
                flexBasis: `calc(50% - 22px)`,
              },
              display: 'flex',
              flexDirection: 'column',
              minHeight: '80px',
            }}
          >
            <RHFNumberField
              name={`${name}.${index}.value`}
              label={t('DataCollectionEditBuilding_BuildingValuation_Label')}
              decimalScale={2}
              InputProps={{
                endAdornment: <Typography sx={{ color: 'text.secondary' }}>€</Typography>,
              }}
              onValueChange={(values) => {
                setValue(`${name}.${index}.value`, values.floatValue);
              }}
            />
          </Grid>

          <Grid
            item
            xs={5}
            sx={{
              '&.MuiGrid-root': {
                maxWidth: `calc(50% - 22px)`,
                flexBasis: `calc(50% - 22px)`,
              },
              display: 'flex',
              flexDirection: 'column',
              minHeight: '80px',
            }}
          >
            <RHFDatePicker
              name={`${name}.${index}.valuation_at`}
              label={t('BuildingValuation_ValuationDate_Placeholder')}
              onChange={(value) => {
                setValue(`${name}.${index}.valuation_at`, value);
              }}
              overrideValue={watch(`${name}.${index}.valuation_at`) || ''}
              size="medium"
              maxDate={new Date()}
              sx={{
                '& .MuiSvgIcon-root': {
                  width: '18px',
                  height: '18px',
                },
              }}
            />
          </Grid>

          <Box
            sx={{
              padding: '5px',
              marginLeft: 'auto',
              display: 'flex',
              alignItems: 'center',
              paddingTop: '26px',
              height: '100%',
            }}
          >
            <IconButton onClick={() => remove(index)}>
              <Iconify sx={{ width: 18, height: 18 }} icon={FIELD_ICON_STATES.DELETED} color="text.secondary" />
            </IconButton>
          </Box>
        </Grid>
      ))}

      {fields.length > 1 && (
        <Alert
          severity="info"
          sx={{
            color: 'text.primary',
            bgcolor: 'grey.200',
            mb: '16px',
            '.MuiAlert-icon': {
              color: 'text.secondary',
            },
          }}
        >
          {t('DataCollectionEditBuilding_BuildingValuation_Info')}
        </Alert>
      )}
      <Box>
        <Button
          variant="outlined"
          onClick={() => append({ value: null, valuation_at: null })}
          startIcon={<Iconify icon={ICONS.ADD} />}
          sx={{ mr: 1, borderColor: 'divider' }}
        >
          {t('DataCollectionEditBuilding_BuildingValuation_AddValuation')}
        </Button>
      </Box>
    </>
  );
};

export default ValuationForm;
