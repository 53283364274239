import { Box, ListItemIcon, MenuItem, TextField, Tooltip } from '@mui/material';
import { BuildingModelFragment } from '@predium/client-graphql';
import { TechnologyRenovationTemplates } from '@predium/client-lookup';
import {
  energy_consumer_technology_type_enum,
  energy_consumer_type_enum,
  energy_source_type_enum,
  energy_system_type_enum,
  envelope_type_enum,
  renovation_type_enum,
} from '@predium/enums';
import { translateRenovationTypeEnum } from '@predium/i18n/client';
import { accessEnum } from '@predium/utils';
import { t } from 'i18next';
import { useState } from 'react';
import { getActionIcon } from '../../../../../utils/icons';
import { DropdownIcon, SelectSx, StyledListItemText } from '../../Components/ActionCustomStyles';
import { ConsumerPartialRenovationOption, TechnicalActionFormRef } from '../CreateAction';
import EnergyRouteAction from './TechnicalActions/EnergyRouteAction';
import HydraulicBalancingAction from './TechnicalActions/HydraulicBalancingAction';
import SolarAction from './TechnicalActions/SolarAction';
import HeatingAction from './TechnicalActions/SystemActions/HeatingAction';
import HotWaterAction from './TechnicalActions/SystemActions/HotWaterAction';
import LightingAction from './TechnicalActions/SystemActions/LightingAction';

export type TechnicalActionEditProps = {
  previousActionBuildingModel: BuildingModelFragment;
  selectedActionType: renovation_type_enum;
  ref: TechnicalActionFormRef;
  resetSimulatedData: () => void;
  loading: boolean;
  affectedParts: ConsumerPartialRenovationOption[];
  parameters: any;
  isRecommendedAction: boolean;
  implementationTo: Date;
  implementationToComponent: JSX.Element;
};

export const availableTechnologyOptions = Object.keys(TechnologyRenovationTemplates);

export const availableOptions = (model: BuildingModelFragment) => {
  const envelopes = model.envelope_units;
  const consumers = model.energy_consumers;
  const energySources = model.energy_systems.flatMap((energySystem) =>
    energySystem.energy_system_consumer_routes.map((route) => route.energy_source_type_id),
  );
  const distributions = model.energy_distributions.map((distribution) => ({
    ...distribution,
    energySystemType: model.energy_systems.find((system) =>
      system.energy_system_consumer_routes.some((route) => route.energy_distribution?.id === distribution.id),
    )?.energy_system_type_id,
  }));

  return { envelopes, consumers, distributions, energySources: energySources };
};

export const ENERGY_ROUTE_TYPE = renovation_type_enum.ENERGY_SYSTEM_ROUTE;
export const SOLAR_PLANT_TYPE = renovation_type_enum.SOLAR_PLANT;
export const HYDRAULIC_BALANCING_TYPE = renovation_type_enum.HYDRAULIC_BALANCING;

export default function TechnicalAction({
  previousActionBuildingModel,
  formRef,
  resetSimulatedData,
  loading,
  isRecommendedAction = false,
  affectedParts,
  parameters,
  renovationType,
  systemType,
  implementationTo,
  implementationToComponent,
}: {
  previousActionBuildingModel: BuildingModelFragment;
  formRef: TechnicalActionFormRef;
  resetSimulatedData: () => void;
  loading: boolean;
  isRecommendedAction?: boolean;
  affectedParts: ConsumerPartialRenovationOption[];
  parameters: any;
  renovationType: renovation_type_enum;
  systemType?: energy_consumer_type_enum;
  implementationTo: Date;
  implementationToComponent: JSX.Element;
}) {
  // State to manage the selected renovation type
  const [selectedRenovationType, setSelectedRenovationType] = useState<renovation_type_enum | ''>(renovationType ?? '');
  const [selectedSystemType, setSelectedSystemType] = useState<energy_consumer_type_enum | ''>(
    renovationType === renovation_type_enum.ENERGY_CONSUMER ? (systemType ? systemType : '') : '',
  );

  const { consumers, envelopes, energySources, distributions } = availableOptions(previousActionBuildingModel);

  const isEnergyRouteActionDisabled = () => {
    const hasElectricityMix = energySources.includes(energy_source_type_enum.ELECTRICITY_MIX);
    const hasNaturalGas = energySources.includes(energy_source_type_enum.NATURAL_GAS);
    const hasDistrictHeating = energySources.some((source) =>
      [
        energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_COAL,
        energy_source_type_enum.DISTRICT_HEATING_PLANTS_FOSSIL_GAS,
        energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_COAL,
        energy_source_type_enum.DISTRICT_HEATING_CHP_FOSSIL_GAS,
      ].includes(source),
    );
    const hasDistrictHeatingRegenerative = energySources.some((source) =>
      [
        energy_source_type_enum.DISTRICT_HEATING_CHP_RENEWABLE,
        energy_source_type_enum.DISTRICT_HEATING_PLANTS_RENEWABLE,
      ].includes(source),
    );

    return !hasElectricityMix && !hasNaturalGas && !(hasDistrictHeating || hasDistrictHeatingRegenerative);
  };

  const maxRoofArea = Math.floor(
    envelopes
      .filter((envelope) =>
        [envelope_type_enum.FLAT_ROOF, envelope_type_enum.PITCHED_ROOF, envelope_type_enum.TOP_FLOOR_CEILING].includes(
          envelope.envelope_type_id,
        ),
      )
      .reduce((acc, curr) => acc + curr.area, 0),
  );

  const solarAvailable = maxRoofArea > 0;
  const lightingAvailable = consumers.some(
    (consumer) =>
      consumer.energy_consumer_technology_type_id &&
      [
        energy_consumer_technology_type_enum.FLUORESCENT_LAMPS,
        energy_consumer_technology_type_enum.HALOGEN_LAMPS,
        energy_consumer_technology_type_enum.INCANDESCENT_LAMPS,
      ].includes(accessEnum(energy_consumer_technology_type_enum, consumer.energy_consumer_technology_type_id)) &&
      consumer.efficiency,
  );

  const heatingDistributions = distributions.filter(
    (distribution) => distribution.energySystemType === energy_system_type_enum.HEATING,
  );
  const heatingDistributionsExist = heatingDistributions.length > 0;
  const hydraulicBalancingAvailable = heatingDistributions.some(
    (distribution) => !distribution.last_hydraulic_balancing,
  );
  // tooltip text for when hydraulic balancing is not available
  const tooltipText = heatingDistributionsExist
    ? t('Scenarios_ListItem-HydraulicBalancingDisabled')
    : t('Scenarios_ListItem-HydraulicBalancingNoDistributionSystems');

  // Props for action components
  const props: TechnicalActionEditProps = {
    previousActionBuildingModel,
    selectedActionType: selectedRenovationType as renovation_type_enum,
    ref: formRef,
    resetSimulatedData: resetSimulatedData,
    loading: loading,
    affectedParts: affectedParts,
    parameters: parameters,
    isRecommendedAction: isRecommendedAction,
    implementationTo: implementationTo,
    implementationToComponent: implementationToComponent,
  };

  function getActionComponent(renovationType: renovation_type_enum) {
    switch (renovationType) {
      case renovation_type_enum.SOLAR_PLANT:
        if (renovationType !== renovation_type_enum.SOLAR_PLANT) {
          throw new Error(`Invalid parameters type`);
        } else {
          return <SolarAction {...props} parameters={parameters} />;
        }
      case renovation_type_enum.ENERGY_CONSUMER:
        if (renovationType !== renovation_type_enum.ENERGY_CONSUMER) {
          throw new Error(`Invalid parameters type`);
        } else {
          switch (selectedSystemType) {
            case energy_consumer_type_enum.LIGHTING:
              return <LightingAction {...props} parameters={parameters} />;
            case energy_consumer_type_enum.HEATING:
              return <HeatingAction {...props} parameters={parameters} />;
            case energy_consumer_type_enum.HOT_WATER:
              return <HotWaterAction {...props} parameters={parameters} />;
            default:
              const exhaustiveCheck = selectedSystemType;
              throw new Error(`Unhandled energy_consumer_type_enum ${selectedSystemType}: ${exhaustiveCheck}`);
          }
        }
      case renovation_type_enum.ENERGY_SYSTEM_ROUTE:
        if (renovationType !== renovation_type_enum.ENERGY_SYSTEM_ROUTE) {
          throw new Error(`Invalid parameters type`);
        } else {
          return <EnergyRouteAction {...props} parameters={parameters} />;
        }
      case renovation_type_enum.HYDRAULIC_BALANCING:
        if (renovationType !== renovation_type_enum.HYDRAULIC_BALANCING) {
          throw new Error(`Invalid parameters type`);
        } else {
          return <HydraulicBalancingAction {...props} parameters={parameters} />;
        }
      default:
        const exhaustiveCheck = renovationType;
        throw new Error(`Unhandled renovation type ${renovationType}: ${exhaustiveCheck}`);
    }
  }
  // Render the selected action component based on the dropdown selection
  const actionComponent = selectedRenovationType ? getActionComponent(selectedRenovationType) : null;

  const handleActionChange = (value: renovation_type_enum | energy_consumer_type_enum) => {
    if (
      value === energy_consumer_type_enum.HEATING ||
      value === energy_consumer_type_enum.HOT_WATER ||
      value === energy_consumer_type_enum.LIGHTING
    ) {
      setSelectedRenovationType(renovation_type_enum.ENERGY_CONSUMER);
      setSelectedSystemType(value as energy_consumer_type_enum);
    } else {
      setSelectedRenovationType(value as renovation_type_enum);
    }
  };

  const hydraulicBalancingItem = (
    <MenuItem key={HYDRAULIC_BALANCING_TYPE} value={HYDRAULIC_BALANCING_TYPE} disabled={!hydraulicBalancingAvailable}>
      <ListItemIcon style={{ minWidth: 24, maxWidth: 24 }}>
        <Box component="img" src={getActionIcon('HYDRAULIC_BALANCING')} />
      </ListItemIcon>
      <StyledListItemText action={t('Enum_RenovationTypeEnum-HYDRAULIC_BALANCING')} />
    </MenuItem>
  );

  return (
    <>
      <TextField
        select
        label={t('General_Category')}
        onChange={(event) => handleActionChange(event.target.value as renovation_type_enum)}
        sx={{ ...SelectSx, mt: 2, mb: 0 }}
        size="small"
        fullWidth
        disabled={true}
        SelectProps={{ IconComponent: DropdownIcon }}
        value={selectedSystemType && selectedSystemType.length > 0 ? selectedSystemType : selectedRenovationType}
      >
        {availableTechnologyOptions.map((action) => (
          <MenuItem
            key={action}
            value={action}
            disabled={
              !consumers
                .map((consumer) => consumer.energy_consumer_type_id)
                .includes(action as energy_consumer_type_enum)
            }
          >
            <ListItemIcon style={{ minWidth: 24 }}>
              <Box component="img" src={getActionIcon(action)} />
            </ListItemIcon>
            <StyledListItemText
              action={
                action === energy_consumer_type_enum.HEATING
                  ? t('Scenarios_ListItem-HeatingConsumerType')
                  : t('Scenarios_ListItem-HotWaterConsumerType')
              }
            />
          </MenuItem>
        ))}
        <MenuItem key={ENERGY_ROUTE_TYPE} value={ENERGY_ROUTE_TYPE} disabled={isEnergyRouteActionDisabled()}>
          <ListItemIcon style={{ minWidth: 24 }}>
            <Box component="img" src={getActionIcon(ENERGY_ROUTE_TYPE)} />
          </ListItemIcon>
          <StyledListItemText action={translateRenovationTypeEnum(ENERGY_ROUTE_TYPE)} />
        </MenuItem>
        {solarAvailable && (
          <MenuItem key={SOLAR_PLANT_TYPE} value={SOLAR_PLANT_TYPE}>
            <ListItemIcon style={{ minWidth: 24 }}>
              <Box component="img" src={getActionIcon(SOLAR_PLANT_TYPE)} />
            </ListItemIcon>
            <StyledListItemText action={translateRenovationTypeEnum(SOLAR_PLANT_TYPE)} />
          </MenuItem>
        )}
        <MenuItem
          key={energy_consumer_type_enum.LIGHTING}
          value={energy_consumer_type_enum.LIGHTING}
          disabled={!lightingAvailable}
        >
          <ListItemIcon style={{ minWidth: 24 }}>
            <Box component="img" src={getActionIcon('LIGHTING')} />
          </ListItemIcon>
          <StyledListItemText action={t('Scenarios_ListItem-LightingConsumerType')} />
        </MenuItem>
        {hydraulicBalancingAvailable ? (
          hydraulicBalancingItem
        ) : (
          <Tooltip title={tooltipText} arrow placement="top">
            <span>{hydraulicBalancingItem}</span>
          </Tooltip>
        )}
      </TextField>
      {actionComponent}
    </>
  );
}
