import { useLazyQuery, useSubscription } from '@apollo/client';
import { ensureDefined } from '@predium/utils';
import { useCallback, useRef, useState } from 'react';
import { BUILDING_DOCUMENT_DOWNLOAD_URL, BUILDING_DOCUMENT_SUBSCRIPTION } from '../graphql/common.queries';
import { downloadFileUri } from '../utils/createDownloadLink';

type Props = {
  onSuccess: VoidFunction;
  onError: VoidFunction;
};

export const useExportedFile = ({ onSuccess, onError }: Props) => {
  const [documentId, setDocumentId] = useState<number | null>(null);

  const abortController = useRef(new AbortController());

  const untrackDocument = useCallback(() => {
    abortController.current.abort();
    setDocumentId(null);
  }, []);

  const [getDownloadUrl] = useLazyQuery(BUILDING_DOCUMENT_DOWNLOAD_URL, {
    onCompleted: (data) => {
      const buildingDocument = data.building_document_by_pk;
      if (!buildingDocument) {
        throw new Error('Building document with the given id not found');
      }

      const downloadUrl = buildingDocument.file.downloadUrl;
      const filename = ensureDefined(buildingDocument.file.name);

      downloadFileUri(downloadUrl, filename);
      onSuccess();
      untrackDocument();
    },
    onError,
    context: {
      fetchOptions: {
        signal: abortController.current.signal,
      },
    },
  });

  useSubscription(BUILDING_DOCUMENT_SUBSCRIPTION, {
    variables: {
      documentId: documentId!,
    },
    skip: !documentId,
    onSubscriptionData: (data) => {
      const buildingDocument = data.subscriptionData.data?.building_document_by_pk;

      if (!buildingDocument) {
        throw new Error('Building document with the given id not found');
      }

      if (buildingDocument.file.uploading) {
        return;
      }

      getDownloadUrl({ variables: { id: buildingDocument.id } });
    },
    context: {
      fetchOptions: {
        signal: abortController.current.signal,
      },
    },
  });

  const trackDocument = useCallback((documentId: number) => {
    setDocumentId(documentId);
  }, []);

  return {
    isLoading: !!documentId,
    trackDocument,
    untrackDocument,
  };
};
