import { useQuery } from '@apollo/client';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { PortfolioManagementPortfolioFragment } from '@predium/client-graphql';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Iconify from '../../components/Iconify';
import LoadingScreen from '../../components/LoadingScreen';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import { UnavailablePageForMissingPortfolios } from '../../components/Unavailable';
import { TableEmptyRows, TableSearchNotFound } from '../../components/table';
import TableHeadCustom, { HeadCell } from '../../components/table/TableHeadCustom';
import TablePaginationStandard from '../../components/table/TablePaginationStandard';
import { GET_PORTFOLIOS } from '../../graphql/Portfolio.queries';
import useOrgPreferences from '../../hooks/useOrgPreferences';
import useSessionData from '../../hooks/useSessionData';
import useSubscriptionWithFallback from '../../hooks/useSubscriptionFallback';
import useTable, { applySortFilter, emptyRows } from '../../hooks/useTable';
import PortfolioActionMenu from '../../sections/Org/Portfolio/PortfolioActionMenu';
import PortfolioCreateDialog from '../../sections/Org/Portfolio/PortfolioCreateDialog';
import PortfolioDeleteDialog from '../../sections/Org/Portfolio/PortfolioDeleteDialog';
import PortfolioEditDialog from '../../sections/Org/Portfolio/PortfolioEditDialog';
import PortfolioTableRow from '../../sections/Org/Portfolio/PortfolioTableRow';
import PortfolioTableToolbar from '../../sections/Org/Portfolio/PortfolioTableToolbar';

export default function PortfolioManagement() {
  const { t } = useTranslation();
  const { dense, page, order, orderBy, setOrder, setOrderBy, rowsPerPage, setPage, onSort, setRowsPerPage } = useTable({
    defaultOrderBy: 'created_at',
    defaultOrder: 'desc',
    defaultRowsPerPage: 25,
  });
  const [showCreatePortfolioDialog, setShowCreatePortfolioDialog] = useState<boolean>(false);
  const [editPortfolio, setEditPortfolio] = useState<PortfolioManagementPortfolioFragment | null>(null);
  const [deletePortfolio, setDeletePortfolio] = useState<PortfolioManagementPortfolioFragment | null>(null);

  const [filterName, setFilterName] = useState('');
  const { user } = useSessionData();
  const { activeCRREMConfiguration, crremConfigurations } = useOrgPreferences();

  const userRole = user?.user_role[0]?.role;

  const { data } = useQuery(GET_PORTFOLIOS);

  const onPortfolioCreate = () => {
    setOrderBy('created_at');
    setOrder('desc');
  };

  const handleFilterName = (filterName: string) => {
    setFilterName(filterName);
    setPage(0);
  };

  if (!data?.portfolio || !userRole) return <LoadingScreen />;

  const dataFiltered = applySortFilter({
    data: data.portfolio,
    nameFilter: {
      fieldName: 'name',
      filterValue: filterName,
    },
    orderOptions: {
      order,
      orderBy: (item) => {
        switch (orderBy) {
          case 'crrem_config': {
            const portfolioActiveCRREMConfiguration =
              crremConfigurations.find((config) => config.isActiveForPortfolio(item.id)) ?? activeCRREMConfiguration;
            return portfolioActiveCRREMConfiguration.getTitle();
          }
          case 'created_at': {
            return item.created_at ?? '';
          }
          case 'name': {
            return item.name ?? '';
          }
          default:
            return '';
        }
      },
    },
  });

  const tableHead: HeadCell[] = [{ id: 'name', label: t('General_Label') }];
  tableHead.push({ id: 'crrem_config', label: t('OrgSettings_DatabaseEmissionFactors') });
  tableHead.push(
    { id: 'created_at', label: t('General_CreatedAt') },
    { id: 'action', label: '', align: 'right', sortingDisabled: true },
  );

  return (
    <UnavailablePageForMissingPortfolios
      title={t('Settings_ManagePortfolios')}
      heading={t('Settings_ManagePortfolios')}
    >
      <Page title={t('Settings_ManagePortfolios')}>
        <Container maxWidth={'lg'}>
          <HeaderBreadcrumbs heading={t('Settings_ManagePortfolios')} links={[]} />
          <Card>
            <PortfolioTableToolbar filterName={filterName} onFilterName={handleFilterName}>
              <Button
                variant="contained"
                size="medium"
                startIcon={<Iconify icon={'eva:plus-fill'} />}
                onClick={() => setShowCreatePortfolioDialog(true)}
                sx={{ minWidth: 220 }}
              >
                {t('PortfolioManagement_CreateNewPortfolio')}
              </Button>
            </PortfolioTableToolbar>

            <Scrollbar>
              <TableContainer>
                <Table>
                  <TableHeadCustom order={order} orderBy={orderBy} headLabel={tableHead} onSort={onSort} />

                  <TableBody>
                    {dataFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((portfolio) => (
                      <PortfolioTableRow
                        key={portfolio.id}
                        row={portfolio}
                        actions={
                          <PortfolioActionMenu
                            setEditPortfolio={setEditPortfolio}
                            portfolio={portfolio}
                            setDeletePortfolio={setDeletePortfolio}
                          />
                        }
                      />
                    ))}

                    <TableEmptyRows
                      height={dense ? 52 : 72}
                      emptyRows={emptyRows(page, rowsPerPage, dataFiltered.length)}
                    />

                    {!dataFiltered.length && <TableSearchNotFound />}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePaginationStandard
              count={dataFiltered.length}
              rowsPerPage={rowsPerPage}
              page={page}
              setPage={setPage}
              setRowsPerPage={setRowsPerPage}
            />
          </Card>

          <PortfolioCreateDialog
            open={showCreatePortfolioDialog}
            onClose={() => setShowCreatePortfolioDialog(false)}
            onPortfolioCreate={onPortfolioCreate}
          />

          <PortfolioEditDialog
            open={Boolean(editPortfolio)}
            onClose={() => setEditPortfolio(null)}
            portfolio={editPortfolio!}
            useSubscription={useSubscriptionWithFallback}
          />
          <PortfolioDeleteDialog
            open={Boolean(deletePortfolio)}
            onClose={() => setDeletePortfolio(null)}
            portfolio={deletePortfolio}
          />
        </Container>
      </Page>
    </UnavailablePageForMissingPortfolios>
  );
}
