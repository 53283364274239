import { useQuery } from '@apollo/client';
import { AnalyzeScenarioOutput, AnalyzeScenarioOutputFragment } from '@predium/client-graphql';
import { TFunction } from 'i18next';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { GET_ANALYZED_SCENARIO, GET_SCENARIO } from '../../../../../graphql/ActionPlanning.queries';
import { getActionPlanTotalActionsCount } from '../../../../../utils/helpers';
import { getEnergyOrEmissionData } from '../../Sections/ScenarioImpactGraphs/hooks';
import { CompareScenariosEnergeticMetricChartSeriesType } from '../EnergeticMetrics/CompareScenariosEnergeticMetricChart';

type ModificationImpactType = AnalyzeScenarioOutput['modification_impact'];
type EnergyMetricType = keyof ModificationImpactType;
type EnergyMetricValueType = ModificationImpactType[EnergyMetricType];
type FinancialCostsType = Pick<AnalyzeScenarioOutput, 'cost_paths' | 'cost_total'>;
type ScenarioEnergyMetric = {
  header: string;
  barChartSeries: CompareScenariosEnergeticMetricChartSeriesType;
  lineChartData: ReturnType<typeof getEnergyOrEmissionData>;
};
type CompareScenariosFinancialCostsChartSeriesType = CompareScenariosEnergeticMetricChartSeriesType;
type UseGetScenarioEnergyPathsParams = {
  scenarioOneName: string;
  scenarioTwoName: string;
  analyzedScenarioOne?: AnalyzeScenarioOutputFragment;
  scenarioOneTotalActions: number;
  scenarioTwoId: number;
  scenarioOneDataColor: string;
  scenarioTwoDataColor: string;
  targetPathColor: string;
  statusQuoColor: string;
};
type UseGetScenarioEnergyPathsReturnType = [
  ScenarioEnergyMetric[],
  CompareScenariosFinancialCostsChartSeriesType,
  boolean,
];

const toBarChartSeries = (
  scenarioOneValues?: EnergyMetricValueType,
  scenarioTwoValues?: EnergyMetricValueType,
): CompareScenariosEnergeticMetricChartSeriesType => {
  return [
    {
      data: [
        scenarioTwoValues?.after ?? scenarioTwoValues?.before ?? 0,
        scenarioOneValues?.after ?? scenarioOneValues?.before ?? 0,
        scenarioOneValues?.before ?? 0,
      ],
    },
  ] as const;
};

const toEnergyMetricArray = ({
  scenarioOneName,
  scenarioTwoName,
  analyzedScenarioOne,
  analyzedScenarioTwo,
  energyMetricsHeaders,
  scenarioOneTotalActions,
  scenarioTwoTotalActions,
  scenarioOneDataColor,
  scenarioTwoDataColor,
  targetPathColor,
  statusQuoColor,
  t,
}: {
  scenarioOneName: string;
  scenarioTwoName: string;
  analyzedScenarioOne?: AnalyzeScenarioOutputFragment;
  analyzedScenarioTwo?: AnalyzeScenarioOutputFragment;
  energyMetricsHeaders: string[];
  scenarioOneTotalActions: number;
  scenarioTwoTotalActions: number;
  scenarioOneDataColor: string;
  scenarioTwoDataColor: string;
  targetPathColor: string;
  statusQuoColor: string;
  t: TFunction<'translation', undefined>;
}): ScenarioEnergyMetric[] => {
  const energyMetrics: EnergyMetricType[] = ['final_energy', 'primary_energy', 'co2_emissions'];
  return energyMetrics.map((metric, index) => {
    const scenarioOneColorMap = {
      primary_energy: scenarioOneDataColor,
      final_energy: scenarioOneDataColor,
      co2_emissions: scenarioOneDataColor,
      statusQuo: statusQuoColor,
      targetPath: targetPathColor,
    };

    const scenarioTwoColorMap = {
      primary_energy: scenarioTwoDataColor,
      final_energy: scenarioTwoDataColor,
      co2_emissions: scenarioTwoDataColor,
      statusQuo: statusQuoColor,
      targetPath: targetPathColor,
    };

    const { categories, series, unit, yAxisLabel, legendData, graphType, totalActions } = getEnergyOrEmissionData(
      analyzedScenarioOne,
      energyMetrics[index],
      scenarioOneTotalActions,
      scenarioOneColorMap,
      scenarioOneName,
      t,
      true,
    );

    const { series: scenarioTwoSeries, legendData: scenarioTwoLegendData } = getEnergyOrEmissionData(
      analyzedScenarioTwo,
      energyMetrics[index],
      scenarioTwoTotalActions,
      scenarioTwoColorMap,
      scenarioTwoName,
      t,
      true,
    );

    const onlyScenarioTwoSeries = scenarioTwoSeries.find((it) => it.name === scenarioTwoName && it.data.length > 0);
    if (onlyScenarioTwoSeries) series.push(onlyScenarioTwoSeries);

    const onlyScenarioTwoLegendData = scenarioTwoLegendData.find(
      (it) => it.label === scenarioTwoName && it.label !== '',
    );
    if (onlyScenarioTwoLegendData) legendData.unshift(onlyScenarioTwoLegendData);

    return {
      header: energyMetricsHeaders[index],
      barChartSeries: toBarChartSeries(
        analyzedScenarioOne?.modification_impact?.[metric],
        analyzedScenarioTwo?.modification_impact?.[metric],
      ),
      lineChartData: {
        categories,
        series,
        unit,
        yAxisLabel,
        legendData,
        graphType,
        totalActions,
      },
    };
  });
};

const toFinancialCosts = ({
  scenarioOneCosts,
  scenarioTwoCosts,
}: {
  scenarioOneCosts?: FinancialCostsType;
  scenarioTwoCosts?: FinancialCostsType;
}): CompareScenariosFinancialCostsChartSeriesType => {
  return [
    {
      data: [scenarioTwoCosts?.cost_total ?? 0, scenarioOneCosts?.cost_total ?? 0],
    },
  ] as const;
};

export const useGetScenarioEnergyPaths = ({
  scenarioOneName,
  scenarioTwoName,
  analyzedScenarioOne,
  scenarioOneTotalActions,
  scenarioTwoId,
  scenarioOneDataColor,
  scenarioTwoDataColor,
  targetPathColor,
  statusQuoColor,
}: UseGetScenarioEnergyPathsParams): UseGetScenarioEnergyPathsReturnType => {
  const { t } = useTranslation();
  const energyMetricsHeaders = [t('General_FinalEnergy'), t('General_PrimaryEnergy'), t('General_CO2Intensity')];

  const { data: analyzedScenarioTwo, loading: isLoadingAnalyzedScenarioTwo } = useQuery(GET_ANALYZED_SCENARIO, {
    variables: { scenario_id: scenarioTwoId },
    skip: scenarioTwoId === Number.MIN_VALUE || scenarioTwoId === Number.MAX_VALUE,
    fetchPolicy: 'cache-and-network',
  });

  const { data: scenarioTwo, loading: isLoadingScenarioTwo } = useQuery(GET_SCENARIO, {
    variables: { scenarioId: scenarioTwoId, year: new Date().getFullYear() },
    skip: scenarioTwoId === Number.MIN_VALUE || scenarioTwoId === Number.MAX_VALUE,
    fetchPolicy: 'cache-and-network',
  });

  const scenarioTwoTotalActions = useMemo(
    () => getActionPlanTotalActionsCount(scenarioTwo?.scenario_by_pk?.action_plans ?? []),
    [scenarioTwo],
  );

  const energyMetrics = toEnergyMetricArray({
    scenarioOneName,
    scenarioTwoName,
    analyzedScenarioOne: analyzedScenarioOne,
    analyzedScenarioTwo: analyzedScenarioTwo?.getAnalyzedScenario,
    energyMetricsHeaders,
    scenarioOneTotalActions,
    scenarioTwoTotalActions,
    scenarioOneDataColor,
    scenarioTwoDataColor,
    targetPathColor,
    statusQuoColor,
    t,
  });

  const costs = toFinancialCosts({
    scenarioOneCosts: {
      cost_paths: analyzedScenarioOne?.cost_paths ?? [],
      cost_total: analyzedScenarioOne?.cost_total,
    },
    scenarioTwoCosts: {
      cost_paths: analyzedScenarioTwo?.getAnalyzedScenario.cost_paths ?? [],
      cost_total: analyzedScenarioTwo?.getAnalyzedScenario.cost_total,
    },
  });

  return [energyMetrics, costs, isLoadingAnalyzedScenarioTwo || isLoadingScenarioTwo] as const;
};
