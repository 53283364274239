import {
  Autocomplete,
  Button,
  Checkbox,
  List,
  ListItem,
  SelectProps,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { EnvelopeRenovationFragment, EnvelopeUnitFragment } from '@predium/client-graphql';
import { getOrientationEnum } from '@predium/client-lookup';
import { envelope_type_enum } from '@predium/enums';
import { translateOrientationEnum_short_dynamic } from '@predium/i18n/client';
import { Units } from '@predium/utils';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ICONS } from '../../../../../../assets/icons';
import Iconify from '../../../../../../components/Iconify';
import Scrollbar from '../../../../../../components/Scrollbar';
import { useLanguage } from '../../../../../../provider/LanguageProvider';
import { getEnvelopeLabel, getEnvelopePartialRenovationOptions } from '../../../ActionPlan.utils';
import { EnvelopePartialRenovationOption } from '../../CreateAction';
import { DoorActionForm } from '../../CreateActions/EnvelopeActions/DoorAction';
import { NonWindowDoorActionForm } from '../../CreateActions/EnvelopeActions/NonWindowDoorAction';
import { WindowActionForm } from '../../CreateActions/EnvelopeActions/WindowAction';
import PartialRenovationOptionSubtext from '../PartialRenovationOptionSubtext';

type Props = {
  action: envelope_type_enum;
  envelopes: EnvelopeUnitFragment[];
  envelopeRenovations: EnvelopeRenovationFragment[];
  sx: SelectProps['sx'];
  setValue: (value: EnvelopePartialRenovationOption[]) => void;
  disabled?: boolean;
  actionMode?: 'create' | 'edit';
};

export default function EnvelopePartialRenovationsAutocomplete({
  action,
  envelopes,
  envelopeRenovations,
  sx,
  setValue,
  disabled,
  actionMode = 'create',
}: Props) {
  const { t } = useTranslation();
  const { localize } = useLanguage();

  const { control, watch } = useFormContext<DoorActionForm | NonWindowDoorActionForm | WindowActionForm>();

  const affectedParts = watch('affected_parts');
  //needs to be controlled because we want to manually close it when user clicks on "select all"
  const [open, setOpen] = useState(false);
  const autocompleteRef = useRef<HTMLDivElement>(null);

  const unsortedOptions: EnvelopePartialRenovationOption[] = getEnvelopePartialRenovationOptions(envelopes, action);

  const changedParts = useMemo(() => {
    if (!envelopeRenovations || envelopeRenovations.length === 0) return [];
    return (
      envelopeRenovations[0].old_envelope?.building_model.envelope_units
        .filter((envelope) => envelope.envelope_type_id === action)
        .flatMap((envelope, idx) => {
          return { idx, ...envelope };
        })
        .filter((oldEnvelope) =>
          envelopeRenovations.find((newEnvelope) => {
            return oldEnvelope.id === newEnvelope.old_entity_id;
          }),
        )
        .flatMap((envelope) => {
          return { idx: envelope.idx, id: envelope.id };
        }) ?? []
    );
  }, [action, envelopeRenovations]);

  //the changed parts will have new ids (higher ones) and thus be the last elements of the array
  //so we loop through them and insert them into the position specified in oldEnvelopes[x].idx
  for (let i = 0; i < changedParts.length; i++) {
    const e1 = unsortedOptions.indexOf(unsortedOptions[changedParts[i].idx]);
    const e2 = unsortedOptions.indexOf(unsortedOptions[unsortedOptions.length - i - 1]);
    const temp = unsortedOptions[e1];
    unsortedOptions[e1] = unsortedOptions[e2];
    unsortedOptions[e2] = temp;
  }

  const sortedOptions = getEnvelopePartialRenovationOptions(unsortedOptions, action).flatMap((option, index) => {
    return { ...option, index };
  });

  const handleSelectAllClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setValue(getEnvelopePartialRenovationOptions(envelopes, action));
    setOpen(false);

    //take out of focus so the tags get shortened
    const input = document.activeElement as HTMLElement;
    input?.blur();
  };

  const handleDeselectAllClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setValue([]);
  };

  useEffect(() => {
    if (actionMode === 'edit') {
      //default value is only currently affected
      const affectedEnvelopes = changedParts.map((envelope) => envelope.id);
      const selectedOptions = sortedOptions.filter((option) => affectedEnvelopes.includes(option.id));
      setValue(getEnvelopePartialRenovationOptions(selectedOptions, action));
    } else if (actionMode === 'create') {
      //default value is all options selected
      setValue(getEnvelopePartialRenovationOptions(envelopes, action));
    } else {
      return;
    }

    // we only want this on the first render so we don't have to worry about the value changing
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action]);

  return (
    <Controller
      control={control}
      name={'affected_parts'}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        const allSelected = value.length === getEnvelopePartialRenovationOptions(envelopes, action).length;
        return (
          <Autocomplete
            ref={autocompleteRef}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            multiple
            disableClearable
            limitTags={3}
            options={sortedOptions}
            size="small"
            onChange={(_, newValue) => onChange(newValue)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={value}
            sx={sx}
            disableCloseOnSelect
            disabled={disabled}
            popupIcon={<Iconify icon={ICONS.CHEVRON_DOWN} width={20} height={20} />}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{
                  '& .MuiInputBase-root': { flexWrap: 'nowrap' },
                  '& .Mui-focused': {
                    flexWrap: 'wrap',
                  },
                }}
                label={t('General_AffectedParts')}
                error={!!error}
                helperText={error ? error.message : ''}
              />
            )}
            getOptionLabel={(option) => getEnvelopeLabel(option, t)}
            slotProps={{
              paper: {
                sx: {
                  mt: 1,
                },
              },
            }}
            componentsProps={{
              popper: {
                modifiers: [
                  {
                    name: 'preventOverflow',
                    enabled: false,
                    options: {
                      altBoundary: true,
                    },
                  },
                  {
                    name: 'flip',
                    enabled: true,
                    options: {
                      fallbackPlacements: ['top-start'],
                    },
                  },
                ],
              },
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Typography variant="body2" {...getTagProps({ index })} whiteSpace="nowrap">
                  {affectedParts.find((part) => part.id === option.id) ? (
                    <>
                      {getEnvelopeLabel(option, t)}
                      {index !== value.length - 1 ? ', ' : ''}
                    </>
                  ) : null}
                </Typography>
              ))
            }
            ListboxComponent={(props) => (
              <List
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
                sx={{
                  '& li': {
                    '&:hover': {
                      backgroundColor: 'action.hover',
                    },
                  },
                }}
                {...props}
              >
                <Scrollbar sx={{ maxHeight: 340 }}>{props.children}</Scrollbar>
                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} px={2} py={1}>
                  {!allSelected ? (
                    <Button type="button" variant="text" onClick={handleSelectAllClick}>
                      {t('General_SelectAll')}
                    </Button>
                  ) : (
                    <Button type="button" variant="text" disabled={value.length === 0} onClick={handleDeselectAllClick}>
                      {t('General_DeselectAll')}
                    </Button>
                  )}
                </Stack>
              </List>
            )}
            renderOption={(props, option) => {
              const label = getEnvelopeLabel(option, t);

              const showOrientation =
                action === envelope_type_enum.WALL ||
                action === envelope_type_enum.WINDOW ||
                action === envelope_type_enum.DOOR;

              const showInsulationThickness = action !== envelope_type_enum.DOOR;

              const windowThickness = option.insulation_material_name
                ?.match(/\d+/g)
                ?.map((number) => number)
                ?.join(' ');

              return (
                <ListItem
                  {...props}
                  style={{
                    paddingLeft: 2,
                    paddingRight: 2,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Checkbox checked={affectedParts.map((part) => part.id).includes(option.id)} />

                  <Stack direction={'column'} width={'100%'}>
                    <Typography>{label}</Typography>
                    <Stack direction={'row'} alignItems={'center'} gap={1}>
                      {showOrientation && (
                        <PartialRenovationOptionSubtext
                          title={t('General_Orientation')}
                          icon={ICONS.ORIENTATION}
                          value={translateOrientationEnum_short_dynamic(
                            getOrientationEnum(option.orientation ?? 0, option.inclination ?? -1),
                            t,
                          )}
                        />
                      )}
                      <PartialRenovationOptionSubtext
                        title={t('General_Area')}
                        icon={ICONS.AREA}
                        value={localize.formatAsFloat(option.area, { unit: Units.area })}
                      />
                      {action === envelope_type_enum.WINDOW ? (
                        <PartialRenovationOptionSubtext
                          title={t('General_Glazing')}
                          icon={ICONS.INSULATION_THICKNESS}
                          value={localize.formatAsFloat(windowThickness, { unit: Units.times })}
                        />
                      ) : (
                        showInsulationThickness && (
                          <PartialRenovationOptionSubtext
                            title={t('General_InsulationThickness')}
                            icon={ICONS.INSULATION_THICKNESS}
                            value={localize.formatAsInteger((option?.insulation_thickness ?? 0) * 100, {
                              unit: Units.centimeters,
                            })}
                          />
                        )
                      )}
                      <PartialRenovationOptionSubtext
                        title={t('General_UValue')}
                        icon={ICONS.U_VALUE}
                        value={localize.formatAsFloat(option?.u_value, {
                          unit: Units.uValue,
                        })}
                      />
                    </Stack>
                  </Stack>
                </ListItem>
              );
            }}
          />
        );
      }}
    />
  );
}
