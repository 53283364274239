import { useQuery } from '@apollo/client';
import { Box, Card, Divider, Stack, Typography, useTheme } from '@mui/material';
import { ConsumptionAnalysis } from '@predium/client-graphql';
import { consumption_type_enum } from '@predium/enums';
import { translateConsumptionTypeEnum_dynamic } from '@predium/i18n/client';
import { Units, UnitsValue } from '@predium/utils';
import dayjs from 'dayjs';
import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';
import { useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { ICONS } from '../../../assets/icons';
import { DelayedLoading } from '../../../components/Loading';
import { useGetNotAvailableLabel } from '../../../components/data-visialization/hooks';
import { ESG_ANALYSIS_CONSUMPTION_ANALYSIS } from '../../../graphql/EsgAnalysis.queries';
import { useLanguage } from '../../../provider/LanguageProvider';
import AddConsumptionButton from './ConsumptionComponents/AddConsumptionButton';
import KPI from './ConsumptionComponents/KPI';

type ConsumptionData = ConsumptionAnalysis['data'][number];

type Props = {
  buildingId: number;
  showTotal: boolean;
};

const ConsumptionOverTime = ({ buildingId, showTotal }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { localize } = useLanguage();

  const thisYear = new Date().getFullYear();

  const { data, loading } = useQuery(ESG_ANALYSIS_CONSUMPTION_ANALYSIS, {
    variables: {
      buildingId,
      from: dayjs()
        .set('y', thisYear - 4)
        .startOf('year')
        .valueOf(),
      to: dayjs().endOf('year').valueOf(),
    },
    fetchPolicy: 'cache-and-network',
  });

  const groupedByType = useMemo(
    () =>
      mapValues(groupBy(data?.getConsumptionAnalysis.data ?? [], 'type'), (value) => value[0]) as Record<
        consumption_type_enum,
        ConsumptionData
      >,
    [data?.getConsumptionAnalysis.data],
  );

  const hasAnyData = (entity: ConsumptionData) => entity.series.some(({ count }) => count > 0);

  const getNotAvailableLabel = useGetNotAvailableLabel();

  const getNoDataAnnotations = (entity: ConsumptionData): XAxisAnnotations[] => {
    if (!hasAnyData(entity)) {
      return [];
    }

    return entity.series
      .map(({ count, from }) => {
        if (count === 0) {
          return getNotAvailableLabel(new Date(from).getFullYear());
        }

        return undefined;
      })
      .filter((x) => x !== undefined) as XAxisAnnotations[];
  };

  const CONSUMPTIONS = [
    {
      icon: ICONS.HEAT,
      type: consumption_type_enum.HEATING,
      unit: showTotal ? Units.energy : Units.energyPerArea,
    },
    {
      icon: ICONS.ELECTRICITY,
      type: consumption_type_enum.ELECTRICITY,
      unit: showTotal ? Units.energy : Units.energyPerArea,
    },
    {
      icon: ICONS.WATER,
      type: consumption_type_enum.WATER,
      unit: showTotal ? Units.waterConsumption : Units.waterConsumptionPerArea,
    },
    {
      icon: ICONS.TRASH,
      type: consumption_type_enum.WASTE,
      unit: showTotal ? Units.wasteConsumptionTons : Units.wasteConsumptionTonsPerArea,
    },
  ] satisfies {
    icon: string;
    type: consumption_type_enum;
    unit: UnitsValue;
  }[];

  if (loading) {
    return (
      <Stack height="300px" alignItems="center" justifyContent="center">
        <DelayedLoading />
      </Stack>
    );
  }

  const getValue = (value: number, absoluteValue: number, type: consumption_type_enum) => {
    const calculatedValue = showTotal ? absoluteValue : value;
    return type === consumption_type_enum.WASTE ? calculatedValue / 1000 : calculatedValue;
  };

  return (
    <Stack spacing={3}>
      <Card>
        <Stack p={4} direction="row" justifyContent="space-between" alignItems="center">
          <Stack spacing={2} direction="row" alignItems="center">
            <Typography variant="h6">{t('EsgAnalysisConsumptionOverTime_Title')}</Typography>
            <Typography variant="caption" color="grey.600" pt={0.25}>
              {t('EsgAnalysisConsumptionOverTime_Subtitle')}
            </Typography>
          </Stack>
          <AddConsumptionButton buildingId={buildingId} />
        </Stack>
        <Divider />
        {
          //TODO negative margin is quickfix. apexchart has right dynamic width for svg element. will add proper fix
          // later
        }
        <Box p={4} mr={-3}>
          {CONSUMPTIONS.map((consumption, index) => (
            <Stack direction="row" key={index}>
              <KPI
                title={translateConsumptionTypeEnum_dynamic(consumption.type, t)}
                subtitle={t('General_Average')}
                valueSubtitle={t('General_PerYear')}
                value={getValue(
                  groupedByType[consumption.type].average,
                  groupedByType[consumption.type].averageAbsolute,
                  consumption.type,
                )}
                hasValue={hasAnyData(groupedByType[consumption.type])}
                unit={consumption.unit}
                icon={consumption.icon}
                iconColor={theme.palette.consumptionColors[consumption.type].main}
                slotsProp={{
                  root: {
                    flex: 0.1,
                  },
                }}
              />
              <Box flex={0.9} position="relative" mt={-2.5}>
                <ReactApexChart
                  height={150}
                  type="bar"
                  options={{
                    chart: { toolbar: { show: false }, animations: { enabled: false } },
                    dataLabels: {
                      enabled: false,
                    },
                    plotOptions: {
                      bar: {
                        borderRadius: 8,
                        columnWidth: '90%',
                        borderRadiusApplication: 'end',
                      },
                    },
                    fill: {
                      opacity: 1,
                    },
                    xaxis: {
                      categories: groupedByType[consumption.type].series.map(({ from }) =>
                        new Date(from).getFullYear(),
                      ),
                      labels: {
                        style: {
                          colors: theme.palette.text.secondary,
                        },
                      },
                    },
                    yaxis: {
                      decimalsInFloat: 0,
                      labels: {
                        style: {
                          colors: theme.palette.text.secondary,
                        },
                        minWidth: 100,
                        align: 'right',
                        formatter: (value) =>
                          localize.formatAsCompact(value, { unit: consumption.unit, fractionDigits: 0 }),
                      },
                      tickAmount: 4,
                      stepSize: hasAnyData(groupedByType[consumption.type]) ? undefined : 50,
                    },
                    annotations: {
                      xaxis: [...getNoDataAnnotations(groupedByType[consumption.type])],
                    },

                    tooltip: {
                      enabled: hasAnyData(groupedByType[consumption.type]),
                      intersect: false,
                      custom: ({ series, dataPointIndex }: { series: number[][]; dataPointIndex: number }) => {
                        const value = series[0][dataPointIndex];
                        const { from } = groupedByType[consumption.type].series[dataPointIndex];

                        return /* html */ `<div
                          style="
                            background-color: #fff;
                            border: 1px solid #e0e0e0;
                            border-radius: 5px;
                            padding: 10px; 
                            font-size: 12px; 
                            color: #000
                          "
                        >
                          <span style="font-weight: 600">
                            ${translateConsumptionTypeEnum_dynamic(consumption.type, t)} - ${dayjs(
                          new Date(from),
                        ).format('YYYY')}
                          </span>
                          <br/>
                          <span>${localize.formatAsCompact(value, { unit: consumption.unit })}</span>
                        </div>`;
                      },
                      style: {
                        fontFamily: theme.typography.fontFamily,
                      },
                    },
                  }}
                  series={[
                    {
                      name: translateConsumptionTypeEnum_dynamic(consumption.type, t),
                      data: groupedByType[consumption.type].series.map(({ sum, sumAbsolute }) =>
                        getValue(sum, sumAbsolute, consumption.type),
                      ),
                      color: theme.palette.consumptionColors[consumption.type].main,
                    },
                  ]}
                />

                {!hasAnyData(groupedByType[consumption.type]) && (
                  <Stack
                    position="absolute"
                    top="40%"
                    right="50%"
                    borderRadius={1}
                    alignItems="center"
                    spacing={1}
                    sx={{
                      transform: 'translate(50%, -50%)',
                      p: 1,
                      pt: 2,
                      pl: 14, // hack to align with the center bar
                    }}
                  >
                    <Typography
                      variant="caption"
                      color="text.secondary"
                      sx={{
                        backgroundColor: theme.palette.background.paper,
                      }}
                    >
                      {t('EsgAnalysisConsumptionOverTime_NoDataAtAll')}
                    </Typography>
                  </Stack>
                )}
              </Box>
            </Stack>
          ))}
        </Box>
      </Card>
    </Stack>
  );
};

export default ConsumptionOverTime;
