import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Stack, Typography } from '@mui/material';
import { forwardRef, useEffect, useImperativeHandle, useMemo } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormProvider } from '../../../../../../components/hook-form';
import BuildingMap from '../../../../../../components/map/BuildingMap';
import usePosthogTracking from '../../../../../../hooks/usePosthogTracking';
import { ActiveStepRef } from '../../BuildingCreationProvider';
import { getBuildingSelectionFormSchema, type BuildingSelectionFormType } from '../validations';

export const BUILDING_SELECTION = 'BUILDING_SELECTION' as const;

export type BuildingSelectionFormRef = {
  type: typeof BUILDING_SELECTION;
  onSubmit: UseFormReturn<BuildingSelectionFormType>['handleSubmit'];
  onPreviousSubstep: () => void;
};

type FormDefaultValues = ReturnType<ReturnType<typeof getBuildingSelectionFormSchema>['cast']>;

type Props = {
  defaultValues: FormDefaultValues;
  coords: { lat: number; lng: number };
  onPreviousSubstep: () => void;
  onValidChange: (isValid: boolean, message?: string) => void;
};

const BuildingSelection = forwardRef<ActiveStepRef, Props>(
  ({ defaultValues, coords, onPreviousSubstep, onValidChange }, ref) => {
    const { t } = useTranslation();
    const schema = getBuildingSelectionFormSchema(true);
    const { trackEvent } = usePosthogTracking();

    const methods = useForm<BuildingSelectionFormType>({
      defaultValues,
      resolver: yupResolver(schema, { stripUnknown: true }),
    });

    const value = methods.watch('building_ids');

    useImperativeHandle(ref, () => ({
      type: BUILDING_SELECTION,
      onSubmit: methods.handleSubmit,
      onPreviousSubstep,
    }));

    const handleSelectionChange = (selection: number[]) => {
      methods.setValue('building_ids', selection);
      methods.trigger('building_ids');
    };

    const handleBuildingClick = (isBeingSelected: boolean) => {
      if (isBeingSelected) {
        trackEvent('MAP_BUILDING_SELECTED');
      } else {
        trackEvent('MAP_BUILDING_UNSELECTED');
      }
    };

    const handleBuildingResult = (isBuildingFound: boolean) => {
      if (!isBuildingFound) trackEvent('MAP_BUILDING_NOT_FOUND');
    };

    useEffect(() => {
      const isValid = methods.formState.isValid;
      const message = isValid ? undefined : t('BuildingCreation_Step1BuildingSelectionHelperMessage');

      onValidChange(isValid, message);
    }, [value, methods.formState.isValid, methods, onValidChange, t]);

    const MemoizedBuildingMap = useMemo(() => {
      return (
        <BuildingMap
          editMode
          preSelectBuilding
          id="building-creation-map-1"
          onSelectionChange={handleSelectionChange}
          onBuildingClick={handleBuildingClick}
          onBuildingResult={handleBuildingResult}
          address={{
            latitude: coords.lat,
            longitude: coords.lng,
            mapbox_building_ids: defaultValues.building_ids || [],
          }}
        />
      );
      // We only want to rebuild the map component if one of its addresses props changes
      // Passing down the other methods in the array causes an infinite loop due to how the map component was built
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [coords.lat, coords.lng, defaultValues.building_ids]);

    return (
      <FormProvider methods={methods} fullHeight>
        <Grid container maxWidth="sm" mx="auto" spacing={2} py={3} height="100%">
          <Grid xs={12} paddingTop={2} display="flex" flexDirection="column" height="100%">
            <Stack spacing={1} mb={2}>
              <Typography variant="h4">{t('BuildingCreation_BuildingSelectionTitle')}</Typography>
              <Typography variant="body1" color="text.secondary">
                {t('BuildingCreation_BuildingSelectionSubTitle')}
              </Typography>
            </Stack>
            <Stack
              flexGrow={1}
              width="600px"
              maxHeight="600px"
              borderRadius={2}
              marginTop={3}
              sx={{
                outline: (theme) => `8px solid ${theme.palette.grey[200]}`,
              }}
            >
              {MemoizedBuildingMap}
            </Stack>
          </Grid>
        </Grid>
      </FormProvider>
    );
  },
);

export default BuildingSelection;
