import { useQuery } from '@apollo/client';
import { area_type_enum, building_state_enum } from '@predium/enums';
import { ensureDefined } from '@predium/utils';
import { UseFormReset } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { GET_BUILDING_DRAFT } from '../../../../../graphql/DataCollection.queries';
import useSessionData from '../../../../../hooks/useSessionData';
import { PATHS } from '../../../../../routes/paths';
import { BuildingCreationFormCast } from '../BuildingCreationProvider';

export const useGetBuildingDraft = ({ id, reset }: { id?: number; reset: UseFormReset<BuildingCreationFormCast> }) => {
  const { user } = useSessionData();
  const navigate = useNavigate();

  return useQuery(GET_BUILDING_DRAFT, {
    variables: { id: id! },
    skip: !id,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const buildingDraft = data.building_draft_by_pk;

      if (!buildingDraft) {
        return navigate(PATHS.notFound());
      }

      reset({
        addressSearch: {
          addressSearch: {
            address: buildingDraft.street,
            city: buildingDraft.city,
            country: buildingDraft.country_id,
            label: `${buildingDraft.street}, ${buildingDraft.postal_code} ${buildingDraft.city}`,
            postalCode: buildingDraft.postal_code,
            lat: buildingDraft.latitude,
            long: buildingDraft.longitude,
          },
        },
        buildingSelection: {
          building_ids: buildingDraft.mapbox_building_ids ?? [],
        },
        address: {
          countryId: buildingDraft.country_id,
          postalCode: buildingDraft.postal_code,
          city: buildingDraft.city,
          street: buildingDraft.street,
        },
        information: {
          responsibleUserId: buildingDraft.responsible_user_id ?? ensureDefined(user).id,
          coreData: {
            state: buildingDraft.building_state_id ?? building_state_enum.ACQUISITION,
            portfolioId: buildingDraft.portfolio_id,
            constructionYear: buildingDraft.year_constructed,
            economicUnitId: buildingDraft.economic_unit_id,
            externalId: buildingDraft.external_id ?? undefined,
            units: buildingDraft.units,
            floors: buildingDraft.floors,
          },
          additionalInformation: {
            monumentProtection: buildingDraft.monument_protection,
            milieuProtection: buildingDraft.milieu_protection,
            heritageDistrict: buildingDraft.heritage_district,
            leasehold: buildingDraft.leasehold,
          },
          area: {
            typeOfUse: buildingDraft.type_of_use_id,
            typeOfArea: area_type_enum.EBF,
            totalArea: buildingDraft.area_usable,
          },
          energyData: buildingDraft.consumers.map((consumer) => ({
            energyConsumer: consumer.energy_system_type_id,
            energySource: consumer.energy_source_type_id,
            finalEnergy: consumer.energy_final,
          })),
          buildingValuations: buildingDraft.building_draft_valuations.map((valuation) => ({
            valuation_at: new Date(valuation.valuation_at),
            value: valuation.value,
          })),
        },
      });
    },
  });
};
