import { TableCell, TableCellProps, useTheme } from '@mui/material';
import {
  DataCollectionEmissionCertificateDraftFragment,
  DataCollectionEmissionCertificateFragment,
} from '@predium/client-graphql';
import { COMMON_DATE_FORMATS, formatDateToLocale, Units } from '@predium/utils';
import dayjs from 'dayjs';
import map from 'lodash/map';
import uniq from 'lodash/uniq';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import OverflowText from '../../../components/OverflowText';
import TableRowWithHighlight, { hasRecentlyChanged } from '../../../components/table/TableRowWithHighlight';
import useSessionData from '../../../hooks/useSessionData';
import { useLanguage } from '../../../provider/LanguageProvider';
import { PATHS } from '../../../routes';
import EmissionCertificateActionMenu from './EmissionCertificateActionMenu';
import { EmissionCertificateStatusType } from './EmissionCertificatesTabs';
import StatusBadge from './StatusBadge';

type RowProps = {
  row: DataCollectionEmissionCertificateFragment;
};

export const getStatusFromCertificate = (
  certificate: DataCollectionEmissionCertificateDraftFragment | DataCollectionEmissionCertificateFragment,
): EmissionCertificateStatusType => {
  if (dayjs(certificate.valid_until).isBefore(dayjs())) {
    return 'expired';
  }

  return 'active';
};

export const getLinkedBuildingsCount = (row: DataCollectionEmissionCertificateFragment, isAdmin: boolean) => {
  if (!isAdmin) {
    return 0;
  }

  const emissionFactorCertificates = row.energy_system_consumer_routes;
  const primaryEnergyFactorCertificates = row.primary_energy_factor_energy_system_consumer_routes;

  const ids = uniq(
    map(
      [...emissionFactorCertificates, ...primaryEnergyFactorCertificates],
      'energy_system.building_model.building_id',
    ),
  );

  return ids.length;
};

export default function EmissionCertificatesListRow({ row }: RowProps) {
  const { localize } = useLanguage();
  const theme = useTheme();
  const navigate = useNavigate();
  const { isAdmin } = useSessionData();

  const linkedBuildings = useMemo(() => getLinkedBuildingsCount(row, isAdmin), [isAdmin, row]);

  const onClickHandler = () => {
    navigate(PATHS.dataCollection.emissionCertificate({ id: row.id }));
  };

  return (
    <TableRowWithHighlight
      background={hasRecentlyChanged(row.created_at) ? 'success' : 'default'}
      sx={{ '& > *': { borderBottom: 'unset' }, cursor: 'pointer' }}
      hover
      onClick={onClickHandler}
    >
      <TableCell>
        <OverflowText
          text={row.issuer}
          maxWidth="250px"
          variant="subtitle2"
          TooltipProps={{
            arrow: true,
            placement: 'top-start',
          }}
        />
      </TableCell>
      <TableCell>
        {localize.formatAsFloat(row.emission_factor, {
          unit: Units.emissionFactor,
          fractionDigits: 3,
        })}
      </TableCell>
      <TableCell align="right">
        {localize.formatAsFloat(row.primary_energy_factor, {
          fractionDigits: 3,
        })}
      </TableCell>
      {isAdmin && <TableCell align="right">{linkedBuildings}</TableCell>}
      <DateCell
        sx={{
          color: dayjs(row.valid_until).isBefore(dayjs()) ? theme.palette.error.main : 'inherit',
        }}
        date={row.valid_until}
      />
      <TableCell>
        <StatusBadge status={getStatusFromCertificate(row)} />
      </TableCell>
      {isAdmin && (
        <TableCell
          align="right"
          onClick={(e) => {
            // prevent the edit modal from opening when clicking on the action menu
            e.stopPropagation();
          }}
        >
          <EmissionCertificateActionMenu
            openEditModal={onClickHandler}
            emissionCertificate={row}
            linkedBuildings={linkedBuildings}
          />
        </TableCell>
      )}
    </TableRowWithHighlight>
  );
}

/**
 * @TODO: maybe add this component to its own file in /table folder
 */

type DateCellProps = {
  date: string;
};

function DateCell({ date, ...other }: DateCellProps & TableCellProps) {
  const { language } = useLanguage();

  return <TableCell {...other}>{formatDateToLocale(date, COMMON_DATE_FORMATS.DAY_MONTH_YEAR, language)}</TableCell>;
}
