import { Box, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { accessEnum } from '@predium/utils';
import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getTableHeaders, RiskCategory, RISKS_GROUPED_BY_CATEGORY, RiskScenario, RiskType } from '../mapping';
import { RiskWithScenario, Scenarios, ViewMode } from '../types';
import RiskCategoryRow from './RiskCategoryRow';
import RiskRow from './RiskRow';

type RiskTableProps = {
  scenarios: Scenarios;
  selectedScenarios: RiskScenario[];
  viewMode: ViewMode;
};

const RiskTable = ({ scenarios, selectedScenarios, viewMode }: RiskTableProps) => {
  const { t } = useTranslation();

  const tableHeaders = getTableHeaders(selectedScenarios, t);

  const risks = useMemo(
    () =>
      scenarios.reduce((risks, scenario) => {
        return [
          ...risks,
          ...scenario.risks.map((risk) => ({
            ...risk,
            riskName: accessEnum(RiskType, risk.riskName),
            scenario: accessEnum(RiskScenario, scenario.type),
          })),
        ];
      }, [] as RiskWithScenario[]),
    [scenarios],
  );

  return (
    <Box
      sx={{
        bgcolor: 'grey.50',
        overflow: 'hidden',
        borderRadius: 2,
        border: '1px solid',
        borderColor: (theme) => theme.palette.divider,
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            {tableHeaders.map((header) => (
              <TableCell
                key={header.id}
                align={header.align}
                width={header.width}
                sx={{
                  width: header.width,
                  minWidth: header.width,
                  maxWidth: header.width,
                }}
              >
                <Stack alignItems="left" spacing={1}>
                  <Typography variant="subtitle2" color="text.secondary">
                    {header.label}
                  </Typography>
                  {header.subLabel && (
                    <Typography variant="caption" color="text.secondary">
                      {header.subLabel}
                    </Typography>
                  )}
                </Stack>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(RISKS_GROUPED_BY_CATEGORY).map(([riskGroup, riskTypes]) => (
            <Fragment key={riskGroup}>
              <RiskCategoryRow riskCategory={accessEnum(RiskCategory, riskGroup)} colSpan={tableHeaders.length} />
              {riskTypes.map((riskType, index) => (
                <RiskRow
                  key={riskType}
                  riskType={riskType}
                  risks={risks.filter((risk) => accessEnum(RiskType, risk.riskName) === riskType)}
                  selectedScenarios={selectedScenarios}
                  viewMode={viewMode}
                  isLast={index === riskTypes.length - 1}
                />
              ))}
            </Fragment>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default RiskTable;
