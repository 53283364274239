import { ActionFragment, BuildingModelFragment } from '@predium/client-graphql';
import { getTechnologyTemplateCostPerM2 } from '@predium/client-lookup';
import {
  energy_consumer_technology_type_enum,
  energy_consumer_type_enum,
  energy_source_type_enum,
  energy_system_type_enum,
  renovation_type_enum,
} from '@predium/enums';
import { accessEnum, ensureDefined, maybeEnum } from '@predium/utils';
import { ActionWithMetricData } from '../../ActionPlanSections/ActionList/ActionListTable';
import { RecommendationOutput } from '../../ActionPlanSections/RecommendedActions';
import { availableOptions } from './TechnicalAction';

export const getHeatingActionParameters = (action: ActionFragment) => {
  const currentActionBuildingModel = ensureDefined(action?.building_models[0]);
  const consumerData = ensureDefined(action?.renovations_energy_consumer[0]?.new_energy_consumer);
  const consumerType = accessEnum(energy_consumer_type_enum, consumerData.energy_consumer_type_id);
  let energySourceType;
  const newConsumerIds =
    action?.renovations_energy_consumer.map((r) => r.new_energy_consumer?.id).filter((id) => id) ?? [];
  const routesOfRenovatedConsumers = currentActionBuildingModel.energy_systems
    .flatMap((s) => s.energy_system_consumer_routes)
    .filter((r) => newConsumerIds?.includes(r.energy_consumer.id));
  energySourceType = maybeEnum(
    energy_source_type_enum,
    routesOfRenovatedConsumers.find((r) => r.energy_source_type_id !== energy_source_type_enum.SOLAR)
      ?.energy_source_type_id ?? routesOfRenovatedConsumers[0]?.energy_source_type_id,
  );

  let costPerM2;
  if (consumerData.energy_consumer_technology_type_id && consumerData.energy_consumer_type_id) {
    costPerM2 = getTechnologyTemplateCostPerM2(
      accessEnum(energy_system_type_enum, consumerData.energy_consumer_type_id),
      accessEnum(energy_consumer_technology_type_enum, consumerData.energy_consumer_technology_type_id),
    );
  }

  const parameters = {
    system_type: consumerType,
    technology_name: consumerData.name ?? '',
    efficiency: consumerData.efficiency ?? undefined,
    energy_source_type: energySourceType,
    consumer_technology_type: maybeEnum(
      energy_consumer_technology_type_enum,
      consumerData.energy_consumer_technology_type_id,
    ),
    cost_per_m2: costPerM2 ?? 0,
    renovation_ids: [], // TODO: Add renovation ids
  };
  return parameters;
};

export const getLightingActionParameters = (action: ActionFragment) => {
  const currentActionBuildingModel = ensureDefined(action?.building_models[0]);

  const { energySources } = availableOptions(currentActionBuildingModel);

  return {
    system_type: energy_consumer_type_enum.LIGHTING,
    efficiency: ensureDefined(action.renovations_energy_consumer[0].new_energy_consumer).efficiency,
    consumer_technology_type: energy_consumer_technology_type_enum.LED,
    technology_name: '',
    energy_source_type: energySources.includes(energy_source_type_enum.ELECTRICITY_GREEN)
      ? energy_source_type_enum.ELECTRICITY_GREEN
      : energy_source_type_enum.ELECTRICITY_MIX,
  };
};

export const getEnergyRouteActionParameters = (action: ActionFragment) => {
  const energyRouteData = action.renovations_energy_routes.filter(
    (route) => route.new_energy_system_route?.energy_source_type_id !== energy_source_type_enum.SOLAR,
  )[0].new_energy_system_route;

  return {
    energy_source_type: energyRouteData?.energy_source_type_id,
  };
};

export const getHydraulicActionParameters = (action: ActionFragment) => {
  const currentActionBuildingModel = ensureDefined(action?.building_models[0]);

  const costsWithComponents = currentActionBuildingModel.energy_distributions.some(
    (distribution) => distribution.balancing_with_components,
  );
  return {
    last_hydraulic_balancing: action.implementation_to,
    costs_with_components: costsWithComponents ?? false,
  };
};

export const getMaintenanceActionParameters = (action: ActionWithMetricData) => {
  return {
    implementation_to: new Date(action.implementation_to),
    maintenance_action_parameter: {
      custom_name: action.custom_name ?? '',
    },
  };
};

export const getSolarActionParameters = (action: ActionFragment) => {
  const actionData = action.renovations_energy_producer[0].new_energy_producer;

  const roof_area_ratio = actionData?.roof_area_ratio ? actionData.roof_area_ratio * 100 : undefined;

  const technologyName = actionData?.name ?? '';
  const module_peak_power = actionData?.module_peak_power ?? undefined;
  const module_angle = actionData?.module_angle ?? undefined;
  const solar_active_area = actionData?.solar_active_area ?? undefined;
  const cost_per_m2 = actionData?.cost_per_m2 ?? undefined;

  return {
    technology_name: technologyName,
    module_peak_power: module_peak_power,
    module_angle: module_angle,
    solar_active_area: solar_active_area,
    roof_area_ratio: roof_area_ratio,
    cost_per_m2: cost_per_m2,
  };
};

export function getRecommendedActionInputParameters(
  action: RecommendationOutput,
  baseBuildingModel: BuildingModelFragment,
) {
  const renovationType = action.renovation_type;
  const selectedSystemType =
    action.renovation_type === renovation_type_enum.ENERGY_CONSUMER ? action.parameters.system_type : '';

  switch (renovationType) {
    case renovation_type_enum.SOLAR_PLANT:
      if (action.renovation_type !== renovation_type_enum.SOLAR_PLANT) {
        throw new Error(`Invalid parameters type`);
      } else {
        return {
          ...action.parameters,
          cost_per_m2: action.parameters.cost_per_m2 ? parseFloat(action.parameters.cost_per_m2.toFixed(2)) : undefined,
        };
      }

    case renovation_type_enum.ENERGY_CONSUMER:
      if (action.renovation_type !== renovation_type_enum.ENERGY_CONSUMER) {
        throw new Error(`Invalid parameters type`);
      } else {
        switch (selectedSystemType) {
          case energy_system_type_enum.LIGHTING:
          case energy_system_type_enum.HEATING:
          case energy_system_type_enum.HOT_WATER:
            const renovationIds = baseBuildingModel.energy_systems
              .filter((system) => system.energy_system_type_id === selectedSystemType)
              .flatMap((system) => system.energy_system_consumer_routes.map((route) => route.secondary_id));

            return {
              ...action.parameters,
              renovation_ids: renovationIds,
            };
          default:
            const exhaustiveCheck = selectedSystemType;
            throw new Error(`Unhandled energy_consumer_type_enum ${selectedSystemType}: ${exhaustiveCheck}`);
        }
      }
    case renovation_type_enum.ENERGY_SYSTEM_ROUTE:
      if (action.renovation_type !== renovation_type_enum.ENERGY_SYSTEM_ROUTE) {
        throw new Error(`Invalid parameters type`);
      } else {
        return action.parameters;
      }
    case renovation_type_enum.HYDRAULIC_BALANCING:
      if (action.renovation_type !== renovation_type_enum.HYDRAULIC_BALANCING) {
        throw new Error(`Invalid parameters type`);
      } else {
        return action.parameters;
      }
    default:
      const exhaustiveCheck = renovationType;
      throw new Error(`Unhandled renovation type ${renovationType}: ${exhaustiveCheck}`);
  }
}

export function getActionInputParameters(action: ActionFragment) {
  const renovationType = action?.renovations[0]?.renovation_type_id;
  let systemType = '';

  if (renovationType === renovation_type_enum.ENERGY_CONSUMER) {
    const consumerData = ensureDefined(action?.renovations_energy_consumer[0]?.new_energy_consumer);
    systemType = consumerData.energy_consumer_type_id;
  }

  switch (renovationType) {
    case renovation_type_enum.SOLAR_PLANT:
      if (renovationType !== renovation_type_enum.SOLAR_PLANT) {
        throw new Error(`Invalid parameters type`);
      } else {
        return getSolarActionParameters(action);
      }

    case renovation_type_enum.ENERGY_CONSUMER:
      if (renovationType !== renovation_type_enum.ENERGY_CONSUMER) {
        throw new Error(`Invalid parameters type`);
      } else {
        switch (systemType) {
          case energy_system_type_enum.LIGHTING:
            return getLightingActionParameters(action);
          case energy_system_type_enum.HEATING:
            return getHeatingActionParameters(action);
          case energy_system_type_enum.HOT_WATER:
            return getHeatingActionParameters(action);
          default:
            const exhaustiveCheck = systemType;
            throw new Error(`Unhandled energy_consumer_type_enum ${systemType}: ${exhaustiveCheck}`);
        }
      }
    case renovation_type_enum.ENERGY_SYSTEM_ROUTE:
      if (renovationType !== renovation_type_enum.ENERGY_SYSTEM_ROUTE) {
        throw new Error(`Invalid parameters type`);
      } else {
        return getEnergyRouteActionParameters(action);
      }
    case renovation_type_enum.HYDRAULIC_BALANCING:
      if (renovationType !== renovation_type_enum.HYDRAULIC_BALANCING) {
        throw new Error(`Invalid parameters type`);
      } else {
        return getHydraulicActionParameters(action);
      }
    default:
      const exhaustiveCheck = renovationType;
      throw new Error(`Unhandled renovation type ${renovationType}: ${exhaustiveCheck}`);
  }
}
