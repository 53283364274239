import { Card, CardHeader, CardProps, Stack, useTheme } from '@mui/material';
import { energy_source_type_enum } from '@predium/enums';
import { translateUnitEnum_dynamic } from '@predium/i18n/client';
import { getFilename, Localize, Units, UnitsValue } from '@predium/utils';
import { TFunction } from 'i18next';
import isEqual from 'lodash/isEqual';
import merge from 'lodash/merge';
import sum from 'lodash/sum';
import { memo } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Trans, useTranslation } from 'react-i18next';
import { ACADEMY_LINKS } from '../../../assets/links';
import { useLanguage } from '../../../provider/LanguageProvider';
import BaseOptionChart from '../../../theme/apexcharts';
import addCustomCSVExportButtonToChartToolbar from '../../../utils/addCustomCSVExportButtonToChartToolbar';
import { toCsvContent } from '../../../utils/toCsvContent';
import InfoTooltip from '../../InfoTooltip';
import { PreLink } from '../../presentations/PreLink';
import { disableCursorPointerForMarkers } from '../PathGraph';
import { updateStylingForLegend } from './BuildingsByEnergySourceTypeGraph';

const getCsvContent = (
  series: number[],
  labels: string[],
  unit: UnitsValue,
  t: TFunction<'translation', undefined>,
  localize: Localize,
) => {
  const totalValue = sum(series);

  const csvRows = [
    [t('General_CO2IntensityByScope123'), t('General_Percent'), translateUnitEnum_dynamic(unit, t)],
    ...series.map((it, index) => [
      labels[index],
      localize.formatAsPercentage((it / totalValue) * 100),
      localize.formatAsFloat(it),
    ]),
  ];

  return toCsvContent(csvRows);
};

type Scope = {
  co2Emissions?: number;
  co2EmissionsTotal?: number;
};

interface Props extends CardProps {
  scopeData: {
    scope1: Scope;
    scope2: Scope;
    scope3: Scope;
  };
  animated?: boolean;
  showTotal: boolean;
}

function Scope123Graph({ scopeData, animated, showTotal }: Props) {
  const { t } = useTranslation();
  const { language, localize } = useLanguage();
  const theme = useTheme();
  const labels = ['Scope 1', 'Scope 2', 'Scope 3'];
  const unit = showTotal ? Units.co2EmissionsPerYear : Units.co2EmissionsPerAreaYear;

  const series = [
    showTotal ? scopeData.scope1.co2EmissionsTotal ?? 0 : scopeData.scope1.co2Emissions ?? 0,
    showTotal ? scopeData.scope2.co2EmissionsTotal ?? 0 : scopeData.scope2.co2Emissions ?? 0,
    showTotal ? scopeData.scope3.co2EmissionsTotal ?? 0 : scopeData.scope3.co2Emissions ?? 0,
  ];

  const totalValue = sum(series);

  const colors = [
    theme.palette.energySourceTypeColors[energy_source_type_enum.COAL].light,
    theme.palette.energySourceTypeColors[energy_source_type_enum.ELECTRICITY_MIX].light,
    theme.palette.energySourceTypeColors[energy_source_type_enum.NATURAL_GAS].light,
  ];
  const filename = getFilename([t('General_CO2IntensityByScope123')], language);

  const chartState: ApexCharts.ApexOptions = {
    colors,
    chart: {
      animations: {
        enabled: !!animated,
      },
      type: 'donut',
      events: {
        mounted: function (chart) {
          updateStylingForLegend(chart.el.id);
          disableCursorPointerForMarkers(chart.el.id);
          addCustomCSVExportButtonToChartToolbar(
            chart.el.id,
            getCsvContent(series, labels, unit, t, localize),
            filename,
          );
        },
        updated: function (chart) {
          updateStylingForLegend(chart.el.id);
          disableCursorPointerForMarkers(chart.el.id);
          addCustomCSVExportButtonToChartToolbar(
            chart.el.id,
            getCsvContent(series, labels, unit, t, localize),
            filename,
          );
        },
      },
      toolbar: {
        offsetY: -40,
        export: {
          svg: {
            filename,
          },
          png: {
            filename,
          },
        },
      },
    },
    legend: {
      position: 'right',
      onItemClick: {
        toggleDataSeries: false,
      },
      formatter: (legendEntry, opts) => {
        const value = opts.w.globals.series[opts.seriesIndex];
        const shortenedValue = localize.formatAsCompact(value, {
          unit,
          roundToNearestHundred: showTotal,
        });
        const percent = localize.formatAsPercentage((value / totalValue) * 100, { fractionDigits: 1 });
        return `<strong>${legendEntry}</strong>
          <span style="font-size: 13px; color: ${theme.palette.grey[500]}">(${percent})</span>
          <p style="margin-left: 15px; font-size: 13px; color: ${theme.palette.grey[500]}">${shortenedValue}</p>`;
      },
      fontSize: '18px',
      fontWeight: 600,
      fontFamily: theme.typography.fontFamily,
      offsetX: -10,
      markers: {
        offsetX: -5,
      },
      itemMargin: {
        vertical: 10,
      },
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      y: {
        formatter: (value: number) =>
          `${localize.formatAsCompact(value, {
            roundToNearestHundred: showTotal,
          })} <span style="font-size: 10px">${translateUnitEnum_dynamic(unit, t)}</span>`,
        title: {
          formatter: (seriesName: string) => seriesName,
        },
      },
    },
    stroke: {
      width: 0,
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
        donut: {
          size: '90%',
          labels: {
            show: true,
            total: {
              show: true,
              label: t('General_Total'),
              color: theme.palette.text.secondary,
              fontSize: theme.typography.subtitle2.fontSize as string,
              fontWeight: theme.typography.subtitle2.fontWeight,
              fontFamily: theme.typography.fontFamily,
              formatter: () => localize.formatAsCompact(totalValue, { roundToNearestHundred: showTotal }),
            },
            value: {
              show: true,
              offsetY: -20,
              color: theme.palette.text.primary,
              fontSize: theme.typography.h3.fontSize as string,
              fontWeight: theme.typography.h3.fontWeight,
              fontFamily: theme.typography.fontFamily,
              formatter: (value) => localize.formatAsCompact(value, { roundToNearestHundred: showTotal }),
            },
            name: {
              show: true,
              formatter: (value) => `${translateUnitEnum_dynamic(unit, t)} (${value})`,
              offsetY: 20,
              color: theme.palette.text.secondary,
            },
          },
        },
      },
    },
    labels,
    states: {
      active: {
        filter: {
          type: 'none',
        },
      },
      hover: {
        filter: {
          type: 'darken',
          value: 0.5,
        },
      },
    },

    // ---------
    // 'totalValue' here is very important!
    // Reason: if totalValue changes, the ChartOptions object must be updated to reflect these changes in ApexCharts
    // https://github.com/apexcharts/react-apexcharts/issues/107#issuecomment-557318940
    //@ts-ignore
    totalValue,
    // ---------
  };

  return (
    <Card
      sx={{
        p: 4,
        '.apexcharts-legend-series': {
          display: 'flex',
          flexDirection: 'row',
        },
        '.apexcharts-legend-marker': {
          marginTop: 0.5,
          minWidth: 12,
        },
      }}
    >
      <Stack direction="row" mb={3} alignItems={'center'} mt={-1}>
        <CardHeader title={t('General_CO2IntensityByScope123')} sx={{ p: 0, pl: 3 }} />

        <InfoTooltip
          text={
            <Trans
              i18nKey={'ESGAnalysis_Scope123Graph-tooltip'}
              components={{
                articleLink: (
                  <PreLink
                    link={{
                      href: ACADEMY_LINKS.SCOPE_123_GRAPH,
                      name: t('General_LearnMore'),
                      external: true,
                    }}
                  />
                ),
              }}
            />
          }
          maxwidth={400}
        />
      </Stack>

      <ReactApexChart
        id="scope_123_graph"
        type={chartState.chart?.type}
        options={merge(BaseOptionChart(), chartState)}
        series={series}
        height="250px"
      />
    </Card>
  );
}

export default memo(Scope123Graph, isEqual);
