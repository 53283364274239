import { Box, Typography } from '@mui/material';
import React from 'react';
import { Trans } from 'react-i18next';
import InfoTooltip from '../InfoTooltip';

type TabLabelWithTooltipProps = {
  label: string;
  tooltipText?: string;
  link?: React.ReactElement;
};

const TabLabelWithTooltip: React.FC<TabLabelWithTooltipProps> = ({ label, tooltipText, link }) => {
  return (
    <Box display="flex" alignItems="center">
      {label}
      {tooltipText && (
        <InfoTooltip
          text={
            <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>
              <Trans
                i18nKey={tooltipText}
                components={
                  link && {
                    bold: <strong />,
                    articleLink: link,
                  }
                }
              />
            </Typography>
          }
          maxwidth={600}
          sx={{ pr: 0 }}
        />
      )}
    </Box>
  );
};

export default TabLabelWithTooltip;
