import { yupResolver } from '@hookform/resolvers/yup';
import { Box, InputAdornment, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import { forwardRef, useImperativeHandle } from 'react';
import { FormProvider, useForm, UseFormSetValue } from 'react-hook-form';
import { RHFDatePicker, RHFNumberField, RHFTextField } from '../../../../../../components/hook-form';
import { useLanguage } from '../../../../../../provider/LanguageProvider';
import { getMaxDate } from '../../../ActionPlan.utils';
import { NumberInputSx } from '../../../Components/ActionCustomStyles';
import { CustomCostsForm } from '../../ActionDetails/ActionPricing/RowCosts';
import { MaintenanceActionSchema } from '../validation-schema';

export type MaintenanceActionForm = {
  implementation_to: Date | '';
  maintenance_action_parameter: {
    custom_name: string;
    cost?: number;
  };
};

type MaintenanceActionProps = {
  minimumDate: Date;
  loading: boolean;
  setCostFormValue: UseFormSetValue<CustomCostsForm>;
};

const MaintenanceAction = forwardRef(({ loading, minimumDate, setCostFormValue }: MaintenanceActionProps, ref) => {
  const { language } = useLanguage();

  const formMethods = useForm<MaintenanceActionForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      implementation_to: '',
      maintenance_action_parameter: {
        custom_name: '',
        cost: undefined,
      },
    },
    resolver: yupResolver(MaintenanceActionSchema(minimumDate, language, true)),
  });

  const { setValue } = formMethods;

  useImperativeHandle(ref, () => ({
    methods: formMethods,
  }));

  return (
    <FormProvider {...formMethods}>
      <Stack spacing={2}>
        <Box>
          <RHFTextField
            name="maintenance_action_parameter.custom_name"
            label={t('General_ActionTitle')}
            size={'small'}
            sx={{ ...NumberInputSx, pb: 0, mb: 0, mt: 2 }}
            disabled={loading}
            onChange={(e) => {
              setValue('maintenance_action_parameter.custom_name', e.target.value, {
                shouldDirty: true,
                shouldValidate: true,
              });
            }}
          />
          <RHFNumberField
            name="maintenance_action_parameter.cost"
            label={t('General_Cost', { count: 2 })}
            size="small"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Typography variant="body2" color="text.secondary">
                    €
                  </Typography>
                </InputAdornment>
              ),
            }}
            onValueChange={(value) => {
              setValue('maintenance_action_parameter.cost', value.floatValue, {
                shouldDirty: true,
                shouldValidate: true,
              });
              setCostFormValue('use_custom_cost', true);
              setCostFormValue('cost_total_custom', Number(value.floatValue));
              setCostFormValue('cost_custom_input', value.formattedValue);
            }}
            sx={{ ...NumberInputSx, mt: 3 }}
            disabled={loading}
          />
          <RHFDatePicker
            label={t('General_PlannedFor')}
            name="implementation_to"
            size={'small'}
            sx={{ ...NumberInputSx, mt: 3 }}
            disabled={loading}
            minDate={minimumDate}
            maxDate={getMaxDate()}
            onChange={(date) => {
              if (date) {
                setValue('implementation_to', date);
              }
            }}
          />
        </Box>
      </Stack>
    </FormProvider>
  );
});

MaintenanceAction.displayName = 'MaintenanceAction';

export default MaintenanceAction;
