/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useQuery, type useSubscription } from '@apollo/client';
import { Button, Grid, IconButton, Stack, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Tab, { TabProps } from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { data_source_type_enum } from '@predium/client-graphql';
import { ensureDefined } from '@predium/utils';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import GeneralDataIcon from '../../assets/Icons/GeneralDataIcon';
import { ICONS } from '../../assets/icons';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Iconify from '../../components/Iconify';
import LoadingScreen from '../../components/LoadingScreen';
import Page from '../../components/Page';
import { WarningModal } from '../../components/WarningModal';
import FormEditBar from '../../components/common/FormEditBar';
import StickyBox from '../../components/common/StickyBox';
import {
  BUILDING_PROCESSING_SUBSCRIPTION,
  GET_BUILDING_MODEL_ACTIONS_STATUS,
} from '../../graphql/DataCollection.queries';
import useSessionData from '../../hooks/useSessionData';
import { PATHS, SEARCH_PARAMS, useTypeSafeSearchParams } from '../../routes';
import Areas from '../../sections/DataCollection/Building/Areas/Areas';
import { AreaRef, AreaState } from '../../sections/DataCollection/Building/Areas/types';
import BuildingActionMenu from '../../sections/DataCollection/Building/BuildingActionMenu';
import BuildingClimateRisk from '../../sections/DataCollection/Building/BuildingClimateRisk';
import BuildingGeneral from '../../sections/DataCollection/Building/BuildingGeneral';
import BuildingTechnologyEnvelope, {
  TechnologyEnvelopeRef,
  TechnologyEnvelopeState,
  technologyEnvelopeStateDefault,
} from '../../sections/DataCollection/Building/BuildingTechnologyEnvelope';
import ClimateRiskV2 from '../../sections/DataCollection/Building/ClimateRiskV2/ClimateRiskV2';
import { AccessRightsRequiredAlert } from '../../sections/DataCollection/Building/Common/BuildingAlerts';
import BuildingIsProcessing from '../../sections/DataCollection/Building/Common/BuildingIsProcessing';
import DataSourceTypeChip from '../../sections/DataCollection/Building/Common/DataSourceTypeChip';
import TechnologyEnvelopeFormBar from '../../sections/DataCollection/Building/Common/TechnologyEnvelopeFormBar';
import useBuilding from '../../sections/DataCollection/Building/Context/useBuilding';
import Documents from '../../sections/DataCollection/Building/Documents/Documents';
import SubBuildingSubsidy from '../../sections/DataCollection/Building/General/SubBuildingSubsidy';
import SubBuildingConsumption from '../../sections/DataCollection/Building/SubBuildingConsumption';

export enum BuildingTabEnum {
  general = 'general',
  areas = 'areas',
  consumption = 'consumption',
  technology = 'technology',
  envelope = 'envelope',
  gisImmoRisk = 'gisImmoRisk',
  climateRisk = 'climateRisk',
  subsidies = 'subsidies',
  documents = 'documents',
}

type BuildingTab = {
  label: ReactNode;
  value: BuildingTabEnum;
  icon?: string;
  customIcon?: TabProps['icon'];
};

type Props = {
  useSubscription: typeof useSubscription;
};

export default function DataCollectionBuilding({ useSubscription }: Props) {
  const { t } = useTranslation();
  const { searchParams, setSearchParams } = useTypeSafeSearchParams(SEARCH_PARAMS.dataCollection.building);

  const {
    serverSideFeatureFlags: { CLIMATE_RISK_V2 },
  } = useSessionData();

  const { building, hasEditAccess, reloadCurrentBuilding } = useBuilding();
  const buildingModel = ensureDefined(building.active_building_model);

  const [openCancelEditDialog, setOpenCancelEditDialog] = useState(false);

  const [techEnvelopeState, setTechEnvelopeState] = useState<TechnologyEnvelopeState>(technologyEnvelopeStateDefault);
  const technologyEnvelopeRef = useRef<TechnologyEnvelopeRef>(null);

  const [areaState, setAreaState] = useState<AreaState>({
    isDirty: false,
    errorLabel: null,
  });
  const areaRef = useRef<AreaRef>(null);

  const {
    loading: actionStatusLoading,
    data: buildingModalMutabilityData,
    refetch: refetchBuildingModalMutabilityData,
  } = useQuery(GET_BUILDING_MODEL_ACTIONS_STATUS, {
    variables: {
      buildingModelId: buildingModel.id,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  const buildingModelSubscription = useSubscription(BUILDING_PROCESSING_SUBSCRIPTION, {
    variables: { buildingModelId: buildingModel.id },
    onData: async ({ data }) => {
      const subscriptionData = data.data?.building_model_by_pk;

      if (!subscriptionData) {
        return;
      }

      if (!subscriptionData.processing && !data.loading) {
        reloadCurrentBuilding();
        refetchBuildingModalMutabilityData();
      } else {
        return;
      }
    },
  });

  const isProcessing = buildingModelSubscription.data?.building_model_by_pk?.processing === true;

  const currentTab = searchParams.tab;

  useEffect(() => {
    if ((isProcessing && currentTab !== BuildingTabEnum.general) || !currentTab) {
      setSearchParams({ tab: BuildingTabEnum.general }, { replace: true });
    }
  }, [currentTab, isProcessing, setSearchParams]);

  if (actionStatusLoading) {
    return <LoadingScreen />;
  }

  const buildingTabs = (
    [
      {
        label: t('DataCollection_BuildingTabs-general'),
        value: BuildingTabEnum.general,
        customIcon: <GeneralDataIcon />,
      },
      {
        label: t('DataCollection_BuildingTabs-areas'),
        value: BuildingTabEnum.areas,
        icon: 'mdi:texture-box',
      },
      {
        label: (
          <>
            {t('DataCollection_BuildingTabs-technology')}
            {techEnvelopeState.technologyEditCount > 0 && (
              <Box ml={1}>
                <DataSourceTypeChip value={techEnvelopeState.technologyEditCount} type={data_source_type_enum.MANUAL} />
              </Box>
            )}
          </>
        ),
        value: BuildingTabEnum.technology,
        icon: 'mdi:clipboard-pulse-outline',
      },
      {
        label: (
          <>
            {t('DataCollection_BuildingTabs-envelope')}
            {techEnvelopeState.envelopeEditCount > 0 && (
              <Box ml={1}>
                <DataSourceTypeChip value={techEnvelopeState.envelopeEditCount} type={data_source_type_enum.MANUAL} />
              </Box>
            )}
          </>
        ),
        value: BuildingTabEnum.envelope,
        icon: 'mdi:home-variant-outline',
      },
      {
        label: t('DataCollection_BuildingTabs-consumption'),
        value: BuildingTabEnum.consumption,
        icon: 'mdi:cog-outline',
      },
      ...(CLIMATE_RISK_V2
        ? [
            {
              label: t('DataCollection_BuildingTabs-gisImmoRisk'),
              value: BuildingTabEnum.gisImmoRisk,
              icon: 'mdi:tsunami',
            },
            {
              label: t('DataCollection_BuildingTabs-climateRisk'),
              value: BuildingTabEnum.climateRisk,
              icon: ICONS.GLOBE,
            },
          ]
        : [
            {
              label: t('DataCollection_BuildingTabs-climateRisk'),
              value: BuildingTabEnum.climateRisk,
              icon: 'mdi:tsunami',
            },
          ]),
      {
        label: t('DataCollection_BuildingTabs-subsidies'),
        value: BuildingTabEnum.subsidies,
        icon: 'mdi:percent-outline',
      },
      {
        label: t('DataCollection_BuildingTabs-documents'),
        value: BuildingTabEnum.documents,
        icon: 'mdi:file-outline',
      },
    ] satisfies (BuildingTab | undefined)[]
  ).filter((item) => typeof item !== 'undefined');

  const getCurrentTabComponent = () => {
    switch (currentTab) {
      case BuildingTabEnum.general:
        return <BuildingGeneral isProcessing={isProcessing} />;
      case BuildingTabEnum.consumption:
        return <SubBuildingConsumption />;
      case BuildingTabEnum.gisImmoRisk:
        return <BuildingClimateRisk />;
      case BuildingTabEnum.climateRisk:
        if (CLIMATE_RISK_V2) {
          return <ClimateRiskV2 />;
        }

        return <BuildingClimateRisk />;
      case BuildingTabEnum.subsidies:
        return <SubBuildingSubsidy />;
      case BuildingTabEnum.documents:
        return <Documents />;
      case BuildingTabEnum.areas:
        return (
          <Areas
            ref={areaRef}
            initialAreas={building.areas}
            setAreaState={setAreaState}
            isActionPlanningAvailable={buildingModel!.action_planning_available}
          />
        );
      case BuildingTabEnum.technology:
      case BuildingTabEnum.envelope:
        return (
          <BuildingTechnologyEnvelope
            ref={technologyEnvelopeRef}
            currentTab={currentTab}
            buildingModalMutabilityData={ensureDefined(buildingModalMutabilityData)}
            setExternalState={setTechEnvelopeState}
          />
        );
      default:
        return <BuildingGeneral isProcessing={isProcessing} />;
    }
  };

  const isTechEnvelopeDirty = techEnvelopeState.technologyIsDirty || techEnvelopeState.envelopeIsDirty;
  const isDirty = isTechEnvelopeDirty || areaState.isDirty;
  const showHeader = !isDirty && !isProcessing;

  const address = `${building.address.street}, ${building.address.postal_code} ${building.address.city}`;

  const isTabDisabled = (tab: BuildingTab) => {
    if (isProcessing && tab.value !== BuildingTabEnum.general) {
      return true;
    }

    // we block changing to any tab if there are unsaved changes in area
    if (areaState.isDirty && tab.value !== currentTab) {
      return true;
    }

    // we block changing to any tab if there are unsaved changes in technology or envelope
    if (isTechEnvelopeDirty && ![BuildingTabEnum.technology, BuildingTabEnum.envelope].includes(tab.value)) {
      return true;
    }

    return false;
  };

  return (
    <Page title={t('PageName_Building')}>
      <Container maxWidth="lg">
        {!hasEditAccess && (
          <Box sx={{ mb: 3 }}>
            <AccessRightsRequiredAlert />
          </Box>
        )}

        <HeaderBreadcrumbs
          heading={showHeader && address}
          links={[
            { name: t('General_DataCollection'), href: PATHS.dataCollection.buildings() },
            { name: t('PageName_BuildingOverview') },
          ]}
          sx={{ mb: showHeader ? 3 : 0 }}
        />

        {isProcessing && <BuildingIsProcessing address={address} />}

        {isTechEnvelopeDirty && (
          <TechnologyEnvelopeFormBar
            techEnvelopeState={techEnvelopeState}
            address={address}
            onCancel={() => setOpenCancelEditDialog(true)}
            onSubmit={ensureDefined(technologyEnvelopeRef.current).handleSubmit}
          />
        )}

        {areaState.isDirty && (
          <StickyBox>
            <FormEditBar
              title={address}
              onCancel={ensureDefined(areaRef.current).onCancel}
              errorCountText={areaState.errorLabel}
              primaryButton={
                <Button
                  disabled={!!areaState.errorLabel}
                  variant="contained"
                  onClick={ensureDefined(areaRef.current).onSave}
                >
                  {t('General_Save')}
                </Button>
              }
            />
          </StickyBox>
        )}

        <Grid container sx={{ mb: 5 }}>
          <Grid item xs={12} md={11} lg={11}>
            <Tabs
              value={currentTab}
              scrollButtons="auto"
              variant="scrollable"
              allowScrollButtonsMobile
              onChange={(_, value: BuildingTabEnum) => setSearchParams({ tab: value })}
            >
              {buildingTabs.map((tab) => (
                <Tab
                  disabled={isTabDisabled(tab)}
                  disableRipple
                  key={tab.value}
                  label={tab.label}
                  icon={tab.customIcon ?? <Iconify icon={tab.icon} width={24} height={24} />}
                  value={tab.value}
                  sx={{
                    '&.MuiButtonBase-root': {
                      mr: 3,
                    },
                    '&.MuiTab-root': {
                      '&.Mui-disabled': {
                        cursor: 'not-allowed',
                        pointerEvents: 'all',
                      },
                    },
                  }}
                />
              ))}
            </Tabs>
          </Grid>

          <Grid item xs={12} md={1} lg={1}>
            <Stack sx={{ p: 1 }} justifyContent={'end'} direction={'row'}>
              <Tooltip title={t('DataCollectionBuilding_TooltipShowESGAnalysis')}>
                <IconButton to={PATHS.esgAnalysis.building({ id: building.id })} component={Link}>
                  <Iconify icon="mdi:chart-bar-stacked" width={20} height={20} />
                </IconButton>
              </Tooltip>

              {!isProcessing && (
                <BuildingActionMenu
                  economicUnitId={building.economic_unit.id}
                  currentAmountBuildingsInEu={building.economic_unit.associatedBuildingsAndDrafts.amount}
                  buildingId={building.id}
                  street={building.address.street}
                />
              )}
            </Stack>
          </Grid>
        </Grid>

        {getCurrentTabComponent()}

        <WarningModal
          title={t('General_CancelEditModalTitle')}
          open={openCancelEditDialog}
          description={t('General_CancelEditModalDescription')}
          onAcknowledge={() => {
            if (technologyEnvelopeRef.current) {
              technologyEnvelopeRef.current.reset();
            }

            if (areaRef.current) {
              areaRef.current.onCancel();
            }

            setOpenCancelEditDialog(false);
          }}
          onClose={() => setOpenCancelEditDialog(false)}
          buttonText={t('General_Discard')}
          cancelText={t('General_Back')}
        />
      </Container>
    </Page>
  );
}
