import { Box, CircularProgress, Container, Divider, Grid, Stack, Typography } from '@mui/material';
import some from 'lodash/some';
import { useEffect, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { ICONS } from '../../../../../assets/icons';
import Iconify from '../../../../../components/Iconify';
import BuildingMap from '../../../../../components/map/BuildingMap';
import { useBuildingCreation } from '../BuildingCreationProvider';
import EstimatedFieldsDetailsIcon from '../Components/EstimatedFieldsDetailsIcon';
import { useCompleteBuilding } from '../hooks/useCompleteBuilding';
import { ACTION_TYPES } from '../hooks/useGlobalState';
import AdditionalDataReview from './sections/AdditionalDataReview';
import CoreDataReview from './sections/CoreDataReview';
import EnergyDataReview from './sections/EnergyDataReview';

const REVIEW = 'REVIEW' as const;

export type Step3ReviewRef = {
  type: typeof REVIEW;
};

const Step3Review = () => {
  const { t } = useTranslation();
  const { activeStepRef, dispatch } = useBuildingCreation();

  const { completedBuilding, loading, estimatedFieldsCount, data } = useCompleteBuilding();

  useImperativeHandle(activeStepRef, () => ({
    type: REVIEW,
  }));

  const hasAnyAdditionalData = some(Object.values(completedBuilding.information.additionalInformation));
  const showMapImage =
    completedBuilding.addressSearch.addressSearch.postalCode &&
    completedBuilding.buildingSelection.building_ids?.length;

  useEffect(() => {
    dispatch({ type: ACTION_TYPES.SET_STEP_INVALIDITY, payload: loading });
  }, [loading, dispatch]);

  return (
    <Container maxWidth={showMapImage ? 'lg' : 'sm'} sx={{ height: '100%', padding: '0!important' }}>
      <Grid container={showMapImage} mx="auto" marginBottom={2} flexShrink={0} height="100%">
        {showMapImage ? (
          <Grid
            item
            xs={6}
            position="sticky"
            height="calc(100% - 52px)"
            paddingRight="20px"
            paddingLeft={3}
            top="16px"
            display="flex"
            flexDirection="column"
          >
            <Typography variant="h4">{t('BuildingCreation_Step3Title')}</Typography>
            <Typography variant="body1" color="text.secondary">
              {t('BuildingCreation_Step3Subtitle')}
            </Typography>
            <Stack
              height="100%"
              width="100%"
              borderRadius={2}
              marginTop={3}
              sx={{
                outline: (theme) => `8px solid ${theme.palette.grey[200]}`,
              }}
            >
              <Box width="100%" height="100%">
                <BuildingMap
                  editMode
                  preSelectBuilding
                  lockView
                  id="building-creation-map-review"
                  onSelectionChange={() => {}}
                  address={{
                    mapbox_building_ids: completedBuilding.buildingSelection.building_ids,
                    latitude: completedBuilding.addressSearch.addressSearch.lat,
                    longitude: completedBuilding.addressSearch.addressSearch.long,
                  }}
                />
              </Box>
            </Stack>
          </Grid>
        ) : undefined}
        <Grid
          item
          xs={6}
          paddingLeft={showMapImage ? '20px' : undefined}
          paddingTop="16px"
          overflow="auto"
          height="100%"
          paddingBottom="28px"
          paddingRight={3}
        >
          <Box border="1px solid" borderColor="divider" borderRadius={2}>
            <Stack pt={3} pb={2} px={3} direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="subtitle2" fontWeight={700}>
                {t('General_GeneralData')}
              </Typography>
              {loading ? (
                <Stack spacing={1} direction="row" alignItems="center">
                  <CircularProgress size={16} />
                  <Typography variant="body2">{t('BuildingCreation_EstimateMissingData')}</Typography>
                </Stack>
              ) : (
                estimatedFieldsCount > 0 && (
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Iconify icon={ICONS.APPROXIMATED} width={20} height={20} color="info.main" />
                    <Typography variant="body2">{t('BuildingCreation_EstimatedFields')}</Typography>
                    <EstimatedFieldsDetailsIcon />
                  </Stack>
                )
              )}
            </Stack>
            <Divider />
            <Stack divider={<Divider />} spacing={2} px={3} pt={2} pb={3}>
              <CoreDataReview completedBuilding={completedBuilding} loading={loading} data={data} />
              <EnergyDataReview completedBuilding={completedBuilding} loading={loading} data={data} />
              {hasAnyAdditionalData && <AdditionalDataReview completedBuilding={completedBuilding} />}
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Step3Review;
