import { Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SEARCH_PARAMS, useTypeSafeSearchParams } from '../../../../routes';
import MonetaryTooltip from './components/MonetaryTooltip';
import RiskTable from './components/RiskTable';
import ScenarioSelector from './components/ScenarioSelector';
import { RiskScenario } from './mapping';
import { Scenarios, ViewMode } from './types';

type Props = {
  scenarios: Scenarios;
  hasValuation: boolean;
};

const ClimateRiskV2Body = ({ scenarios, hasValuation }: Props) => {
  const { t } = useTranslation();

  const { searchParams, setSearchParams } = useTypeSafeSearchParams(SEARCH_PARAMS.dataCollection.building, {
    riskScenario: [RiskScenario.RCP_2_6, RiskScenario.RCP_4_5, RiskScenario.RCP_8_5],
    riskViewMode: ViewMode.RISK_CATEGORY,
  });

  const handleViewModeChange = (_: React.MouseEvent<HTMLElement>, newMode: ViewMode) => {
    if (newMode !== null) {
      setSearchParams({
        ...searchParams,
        riskViewMode: newMode,
      });
    }
  };

  const handleScenarioChange = (newScenarios: RiskScenario[]) => {
    setSearchParams({
      ...searchParams,
      riskScenario: newScenarios,
    });
  };

  const selectedScenarios = searchParams.riskScenario;
  const viewMode = searchParams.riskViewMode;

  return (
    <Stack spacing={3}>
      <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
        <ScenarioSelector selectedScenarios={selectedScenarios} onChange={handleScenarioChange} />
        <ToggleButtonGroup value={viewMode} exclusive onChange={handleViewModeChange} size="small">
          <ToggleButton value={ViewMode.RISK_CATEGORY}>{t('ClimateRiskV2_RiskCategory')}</ToggleButton>
          <ToggleButton value={ViewMode.PERCENTAGE}>{t('ClimateRiskV2_Percentage')}</ToggleButton>
          <MonetaryTooltip disabled={!hasValuation}>
            <ToggleButton
              disabled={!hasValuation}
              value={ViewMode.MONETARY}
              // needed because of the button not being a direct child of the ToggleButtonGroup
              selected={viewMode === ViewMode.MONETARY}
              sx={{
                // needed to show tooltip for a disabled button
                '&.Mui-disabled': {
                  cursor: 'not-allowed',
                  pointerEvents: 'auto!important',
                  '&:hover': {
                    backgroundColor: 'transparent!important',
                  },
                },
              }}
            >
              {t('ClimateRiskV2_Monetary')}
            </ToggleButton>
          </MonetaryTooltip>
        </ToggleButtonGroup>
      </Stack>
      <RiskTable scenarios={scenarios} selectedScenarios={selectedScenarios} viewMode={viewMode} />
    </Stack>
  );
};

export default ClimateRiskV2Body;
