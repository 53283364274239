import { Button, CardContent, Grid, IconButton, Stack } from '@mui/material';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { ActionPlanningScenariosScenarioFragment, language_enum } from '@predium/client-graphql';
import { TFunction } from 'i18next';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ICONS } from '../../../../assets/icons';
import { CircleIcon } from '../../../../assets/Icons/CircleIcon';
import Iconify from '../../../../components/Iconify';
import { TypographyEllipsis } from '../../../../components/typography/TypographyEllipsis';
import { useLanguage } from '../../../../provider/LanguageProvider';
import { PATHS } from '../../../../routes';
import MetaInformation, { MetaInformationItem } from '../../../EsgAnalysis/Components/MetaInformation';
import { getScenarioCreatedAt, getScenarioOwner, getScenarioPortfolioName } from './compare-scenarios.utils';
import { CompareScenarioCardLoading } from './CompareScenarioCardLoading';

const getScenarioPortfolioMetadata = (
  scenario: ActionPlanningScenariosScenarioFragment | undefined,
  language: language_enum,
  t: TFunction<'translation', undefined>,
): MetaInformationItem[] => {
  if (!scenario) return [];

  return [
    {
      label: t('General_Portfolio'),
      value: (
        <TypographyEllipsis maxWidth={160}>{getScenarioPortfolioName(scenario.portfolio.name)}</TypographyEllipsis>
      ),
      icon: ICONS.PORTFOLIO,
    },
    {
      label: `${t('General_CreatedBy')} ・ ${t('General_CreatedAt')}`,
      value: (
        <>
          <TypographyEllipsis maxWidth={90}>
            {getScenarioOwner(scenario.owner.first_name, scenario.owner.last_name)}
          </TypographyEllipsis>
          <Typography variant={'caption'} color={'text.secondary'}>
            {` ・ ${getScenarioCreatedAt(scenario.created_at, language)}`}
          </Typography>
        </>
      ),
      icon: ICONS.ACCOUNT_OUTLINE,
    },
  ];
};

type Props = {
  scenario: ActionPlanningScenariosScenarioFragment | undefined;
  dataColor: string;
  isLoading?: boolean;
  isHidden?: boolean;
  hasExternalLink?: boolean;
  handleSelectChangeClick?: VoidFunction;
};

export const CompareScenarioCard = ({
  scenario,
  dataColor,
  handleSelectChangeClick,
  isLoading = false,
  hasExternalLink = false,
  isHidden = false,
}: Props) => {
  const { t } = useTranslation();
  const { language } = useLanguage();

  const scenarioMetadata = useMemo(() => getScenarioPortfolioMetadata(scenario, language, t), [language, scenario, t]);

  if (isLoading) {
    return <CompareScenarioCardLoading dataColor={dataColor} />;
  }

  if (!scenario) {
    return null;
  }

  return (
    <Card
      sx={{
        backgroundColor: 'grey.5008',
        padding: 2,
        borderRadius: 1,
        boxShadow: 'none',
        display: isHidden ? 'none' : 'block',
      }}
    >
      <CardContent sx={{ padding: 0, '&:last-child': { paddingBottom: 0 } }}>
        <Grid container spacing={1}>
          <Grid item container spacing={1} alignItems={'center'} justifyContent={'space-between'}>
            <Grid item xs={handleSelectChangeClick !== undefined ? 10 : 12}>
              <Stack spacing={1} direction={'row'}>
                <CircleIcon backgroundColor={dataColor} />
                <TypographyEllipsis
                  variant={'subtitle1'}
                  color={'text.primary'}
                  gutterBottom={false}
                  maxWidth={390}
                  title={scenario.name}
                >
                  {scenario.name}
                </TypographyEllipsis>
                {hasExternalLink ? (
                  <IconButton
                    size="small"
                    href={PATHS.actionPlanning.scenario({ id: scenario.id })}
                    target={'_blank'}
                    rel={'noopener noreferrer'}
                  >
                    <Iconify icon={ICONS.EXTERNAL_LINK} width={16} height={16} sx={{ verticalAlign: 'middle' }} />
                  </IconButton>
                ) : undefined}
              </Stack>
            </Grid>
            {handleSelectChangeClick !== undefined ? (
              <Grid item xs={2}>
                <Button
                  variant={'outlined'}
                  size={'small'}
                  sx={{ paddingY: 0.5, paddingX: 1 }}
                  onClick={handleSelectChangeClick}
                >
                  {t('General_Change')}
                </Button>
              </Grid>
            ) : undefined}
          </Grid>
          <Grid item>
            <MetaInformation items={scenarioMetadata} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
