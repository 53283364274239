import { language_enum } from '@predium/enums';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import 'dayjs/locale/en-gb';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(localizedFormat);
dayjs.extend(timezone);

// ----------------------------------------------------------------------

/**
 * German date formats that are commonly used.
 * TODO: Should be made shareable between backend and frontend.
 */
export enum COMMON_DATE_FORMATS {
  DAY_MONTH_YEAR,
  DAY_MONTH_YEAR_TIME,
}

const DAYJS_LOCALE: Record<language_enum, string> = {
  [language_enum.deDE]: 'de',
  [language_enum.enGB]: 'en-gb',
};

export function formatDateToLocale(
  date: string | Date,
  formatEnum: COMMON_DATE_FORMATS,
  language: language_enum,
  dateFormatString?: string,
): string {
  const dateFormatStringFromEnum = formatEnum === COMMON_DATE_FORMATS.DAY_MONTH_YEAR ? 'L' : 'L LT';
  const locale = DAYJS_LOCALE[language];
  let dateWithoutTimezone = date;

  if (typeof dateWithoutTimezone === 'string' && dateWithoutTimezone.indexOf('+') > 0) {
    dateWithoutTimezone = sanitizeDateTimezone(dateWithoutTimezone);
  }

  const dateObject = formatEnum === COMMON_DATE_FORMATS.DAY_MONTH_YEAR ? dayjs(dateWithoutTimezone) : dayjs(date).tz();

  return dateObject.locale(locale).format(dateFormatString ?? dateFormatStringFromEnum);
}

export function sanitizeDateTimezone(date: string) {
  const index = date.indexOf('+');

  if (index > 0) {
    return date.slice(0, index);
  }

  return date;
}
