import { maybeEnum } from '@predium/utils';
import isNil from 'lodash/isNil';

/**
 * A generic type for parsing function.
 *
 * @template T - The type of the value to parse.
 * @template IsArray - A boolean flag to determine if the value is an array.
 *
 * @example
 * ParamFunc<number, false>; // A param function that parses a single number.
 * ParamFunc<number, true>; // A param function that parses an array of numbers.
 */
type ParseFunc<T, IsArray extends boolean> = (
  value: IsArray extends true ? string[] : string,
) => (IsArray extends true ? T[] : T) | undefined;

/**
 * A generic type for stringify function.
 *
 * @template T - The type of the value to stringify.
 * @template IsArray - A boolean flag to determine if the value is an array.
 *
 * @example
 * StringifyFunc<number, false>; // A stringify function that stringifies a single number.
 * StringifyFunc<number, true>; // A stringify function that stringifies an array of numbers.
 */
type StringifyFunc<T, IsArray extends boolean> = (
  value: IsArray extends true ? T[] : T,
) => IsArray extends true ? string[] : string;

/**
 * A generic type for a param function.
 *
 * @template T - The type of the value to parse.
 * @template IsArray - A boolean flag to determine if the value is an array.
 *
 * @example
 * const stringParam: ParamFunc<number, false> = {
 *  parse: (value) => parseInt(value, 10),
 *  stringify: (value) => value.toString(),
 * };
 */
export type ParamFunc<T = any, IsArray extends boolean = boolean> = {
  parse: ParseFunc<T, IsArray>;
  stringify: StringifyFunc<T, IsArray>;
  isArray?: IsArray;
};

/**
 * Infer the type of the value from the param function.
 *
 * @template T - The param function.
 *
 * @example
 * InferParamType<ParamFunc<number, false>>; // number
 * InferParamType<ParamFunc<number, true>>; // number[]
 *
 * InferParamType<ParamFunc<string, false>>; // string
 * InferParamType<ParamFunc<string, true>>; // string[]
 */
export type InferParamType<T> = T extends ParamFunc<infer U, infer IsArray>
  ? NonNullable<IsArray extends true ? U[] : U>
  : never;

export const stringParam: ParamFunc<string, false> = {
  parse: (value) => value,
  stringify: (value) => value,
};

export const booleanParam: ParamFunc<boolean, false> = {
  parse: (value) => (value === 'true' ? true : value === 'false' ? false : undefined),
  stringify: (value) => value.toString(),
};

export const numberParam: ParamFunc<number, false> = {
  parse: (value) => {
    const parsed = Number(value);
    return isNaN(parsed) ? undefined : parsed;
  },
  stringify: (value) => (!isNil(value) ? value.toString() : ''),
};
export const enumParam = <T extends object>(enumValue: T): ParamFunc<T[keyof T], false> => ({
  parse: (value) => maybeEnum(enumValue, value),
  stringify: (value: T[keyof T]) => value as string,
});

export const arrayParam = <TParamFunc extends ParamFunc<any, false>>(
  param: TParamFunc,
): ParamFunc<InferParamType<TParamFunc>, true> => ({
  isArray: true,
  parse: (values) => values.map((value) => param.parse(value)).filter((value) => value !== undefined),
  stringify: (values) => values.map(param.stringify),
});
