import { useAuth0 } from '@auth0/auth0-react';
import 'mapbox-gl/dist/mapbox-gl.css';
import { PostHogProvider } from 'posthog-js/react';
import { useEffect } from 'react';
import LoadingScreen from './components/LoadingScreen';
import NotistackProvider from './components/NotistackProvider';
import { ProgressBarStyle } from './components/ProgressBar';
import ThemeColorPresets from './components/ThemeColorPresets';
import { MotionLazyContainer } from './components/animate';
import GeneralErrorBoundary from './components/error-boundaries/GeneralErrorBoundary';
import { OrgPreferencesContextProvider } from './contexts/OrgPreferences/OrgPreferencesContext';
import { PermissionProvider } from './contexts/PermissionContext';
import { SessionDataProvider } from './contexts/SessionDataContext';
import useAccessToken from './hooks/useAccessToken';
import ApiHealthCheckPage from './pages/ApiHealthCheckPage';
import { DatePickerLanguageProvider } from './provider/DatePickerLanguageProvider';
import HasuraApiProvider from './provider/HasuraApiProvider';
import { LanguageProvider } from './provider/LanguageProvider';
import { Router, TrackPageView } from './routes';
import ThemeProvider from './theme';
import { loadPlanhatAnalytics } from './utils/loadPlanhatAnalytics';

// ----------------------------------------------------------------------

export default function App() {
  const { accessToken } = useAccessToken();
  const { isAuthenticated } = useAuth0();

  useEffect(() => {
    //check if analytics script is already loaded
    if (document.getElementById('analytics-script')) {
      return;
    }
    //check for prod
    if (import.meta.env.VITE_ENV === 'production' && import.meta.env.VITE_PLANHAT_ID) {
      loadPlanhatAnalytics(import.meta.env.VITE_PLANHAT_ID);
    }
  }, []);

  if (isAuthenticated || import.meta.env.VITE_DEVELOP_OFFLINE === 'true') {
    if (accessToken || import.meta.env.VITE_DEVELOP_OFFLINE === 'true') {
      return (
        <LanguageProvider>
          <DatePickerLanguageProvider>
            <ThemeProvider>
              <ThemeColorPresets>
                <GeneralErrorBoundary>
                  <PostHogProvider
                    apiKey={import.meta.env.VITE_PUBLIC_POSTHOG_KEY}
                    options={{
                      api_host: import.meta.env.VITE_POSTHOG_HOST,
                    }}
                  >
                    <NotistackProvider>
                      <MotionLazyContainer>
                        <ProgressBarStyle />
                        {/* <Settings /> */}
                        <HasuraApiProvider authToken={accessToken ?? ''}>
                          <SessionDataProvider>
                            <PermissionProvider>
                              <ApiHealthCheckPage>
                                <OrgPreferencesContextProvider>
                                  <TrackPageView />
                                  <Router />
                                </OrgPreferencesContextProvider>
                              </ApiHealthCheckPage>
                            </PermissionProvider>
                          </SessionDataProvider>
                        </HasuraApiProvider>
                      </MotionLazyContainer>
                    </NotistackProvider>
                  </PostHogProvider>
                </GeneralErrorBoundary>
              </ThemeColorPresets>
            </ThemeProvider>
          </DatePickerLanguageProvider>
        </LanguageProvider>
      );
    } else {
      return <LoadingScreen />;
    }
  } else {
    return (
      <LanguageProvider>
        <DatePickerLanguageProvider>
          <ThemeProvider>
            <ThemeColorPresets>
              <GeneralErrorBoundary>
                <MotionLazyContainer>
                  <ProgressBarStyle />
                  {/* <Settings /> */}
                  <Router />
                </MotionLazyContainer>
              </GeneralErrorBoundary>
            </ThemeColorPresets>
          </ThemeProvider>
        </DatePickerLanguageProvider>
      </LanguageProvider>
    );
  }
}
