import isNil from 'lodash/isNil';
import * as yup from 'yup';

declare module 'yup' {
  interface ObjectSchema<TShape, TContext, TIn, TOut> {
    allOrNone(message: string): ObjectSchema<TShape, TContext, TIn, TOut>;
  }
}

/**
 * This method ensures that all fields in an object are either all filled or all nil.
 * If one field is filled, all fields must be filled.
 * If one field is nil, all fields must be nil.
 *
 * @example
 *
 * yup.object().shape({
 *   name: yup.string().required(),
 *   age: yup.number().required(),
 * }).allOrNone(t('General_Required'));
 */
yup.addMethod<yup.AnyObjectSchema>(yup.object, 'allOrNone', function (this: yup.AnyObjectSchema, message: string) {
  return this.test('allOrNone', message, function (values: Record<string, unknown>) {
    const allFields = Object.keys(this.schema.fields);

    // If all values are nil, we don't need to add a required error on any of them
    const anyFilled = allFields.some((item) => !isNil(values[item]));
    if (!anyFilled) {
      return true;
    }

    const errors: yup.ValidationError[] = [];
    allFields.forEach((key) => {
      // If the value is not nil, we don't need to add a required error
      if (!isNil(values[key])) {
        return;
      }

      errors.push(
        this.createError({
          path: `${this.path}.${key}`,
          message,
        }),
      );
    });

    if (errors.length) {
      // Throw a ValidationError with the array of field-level errors
      throw new yup.ValidationError(errors);
    }

    return true;
  });
});

/**
 * Filters out objects with all nil values from an array
 */
export const filterEmptyObjects = <T extends Record<string, unknown>>(array: T[]) => {
  return array.filter((item) => {
    return Object.values(item).some((value) => !isNil(value));
  });
};
