import LoadingButton from '@mui/lab/LoadingButton';
import { Button, ButtonProps as MuiButtonProps, SxProps, Theme } from '@mui/material';
import { useState } from 'react';
import Iconify from '../Iconify';
import MenuPopover, { MenuPopoverArrowValue } from '../MenuPopover';

type BaseProps = {
  actions: React.ReactNode;
  sx?: SxProps<Theme>;
  arrow?: MenuPopoverArrowValue;
  disabledArrow?: boolean;
  ariaLabel?: string;
  isLoading?: boolean;
};
type IconProps = BaseProps & {
  variant: 'icon';
  icon?: string;
};

type ButtonProps = BaseProps & {
  variant: 'button';
  icon?: string;
  buttonText: string;
  buttonVariant?: MuiButtonProps['variant'];
};

type DropDownProps = BaseProps & {
  variant: 'select';
  buttonText: string;
  buttonVariant?: MuiButtonProps['variant'];
};

type Props = IconProps | ButtonProps | DropDownProps;

export default function ActionMoreMenu(props: Props) {
  const { actions, variant = 'icon', sx, arrow, disabledArrow, ariaLabel, isLoading } = props;

  const [open, setOpen] = useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      {variant === 'icon' && (
        <LoadingButton
          aria-label={ariaLabel}
          onClick={handleOpen}
          sx={{
            minWidth: 4,
            borderRadius: '50%',
            padding: 1,
            ...sx,
          }}
          loading={isLoading}
        >
          <Iconify icon={'eva:more-vertical-fill'} width={20} height={20} />
        </LoadingButton>
      )}

      {props.variant === 'button' && (
        <Button
          onClick={handleOpen}
          startIcon={props.icon ? <Iconify icon={props.icon} width={20} height={20} /> : null}
          sx={sx}
          variant={props.buttonVariant}
        >
          {props.buttonText}
        </Button>
      )}

      {props.variant === 'select' && (
        <Button
          onClick={handleOpen}
          sx={sx}
          variant={props.buttonVariant}
          endIcon={<Iconify icon="eva:chevron-down-fill" width={20} height={20} />}
        >
          {props.buttonText}
        </Button>
      )}

      <MenuPopover
        open={open}
        anchorEl={open}
        onClose={handleClose}
        handleClick={handleClose}
        arrow={arrow}
        disabledArrow={disabledArrow}
        sx={{
          '& .MuiMenuItem-root': {
            px: 1,
            typography: 'body2',
            borderRadius: 0.75,
            '& svg': { mr: 2, width: 20, height: 20 },
          },
        }}
      >
        {actions}
      </MenuPopover>
    </>
  );
}
