/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useQuery } from '@apollo/client';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { ActionPlanImpactDataOutputFragment, ActionPlanningActionSubsidyFragment } from '@predium/client-graphql';
import { ensureDefined, getNetArea } from '@predium/utils';
import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { createStandardErrorSnackbar } from '../../components/NotistackProvider';
import Page from '../../components/Page';
import Unavailable from '../../components/Unavailable';
import StickyBox from '../../components/common/StickyBox';
import { PermissionType } from '../../contexts/PermissionContext';
import { GET_SUBSIDIES } from '../../graphql/ActionPlanning.queries';
import usePermissions from '../../hooks/usePermissions';
import usePosthogTracking from '../../hooks/usePosthogTracking';
import { PATHS } from '../../routes';
import ActionPlanActionMenu from '../../sections/Scenarios/ActionPlan/ActionPlanMenu/ActionPlanActionMenu';
import ActionListTable, {
  ActionWithMetricData,
} from '../../sections/Scenarios/ActionPlan/ActionPlanSections/ActionList/ActionListTable';
import ActionPlanDetails from '../../sections/Scenarios/ActionPlan/ActionPlanSections/ActionPlanDetails';
import BuildingProfileStatusQuo from '../../sections/Scenarios/ActionPlan/ActionPlanSections/BuildingProfileStatusQuo';
import Co2PathGraph from '../../sections/Scenarios/ActionPlan/ActionPlanSections/Co2PathGraph';
import CreateAction from '../../sections/Scenarios/ActionPlan/Actions/CreateAction';
import EditAction from '../../sections/Scenarios/ActionPlan/Actions/EditAction';
import useActionPlan from '../../sections/Scenarios/ActionPlan/Context/useActionPlan';
dayjs.extend(quarterOfYear);

export type ActionPlanMetricsData = {
  actions: ActionWithMetricData[];
  action_plan: ActionPlanImpactDataOutputFragment;
};

export type ActionState = {
  selected: ActionWithMetricData | undefined;
  previous: ActionWithMetricData | undefined;
  next: ActionWithMetricData | undefined;
};

export default function ActionPlanningActionPlan() {
  const { t } = useTranslation();
  const { id } = useParams();
  const { trackEvent } = usePosthogTracking();
  const { enqueueSnackbar } = useSnackbar();
  const { checkBuildingPermission } = usePermissions();

  const [newActionModalOpen, setNewActionModalOpen] = useState(false);
  const [hoverRowId, setHoverRowId] = useState<number | null>(null);
  const [actionState, setActionState] = useState<ActionState>({
    selected: undefined,
    previous: undefined,
    next: undefined,
  });
  const [openEditModal, setOpenEditModal] = useState(false);

  const [metricsData, setMetricsData] = useState<ActionPlanMetricsData | null>(null);
  const displayStandardMutationQueryErrorSnackbar = createStandardErrorSnackbar(t);

  const { actionPlan } = useActionPlan();

  const { data: subsidyData } = useQuery(GET_SUBSIDIES, {
    //@ts-ignore
    variables: { actionPlanId: parseInt(id) },
    onError: () => displayStandardMutationQueryErrorSnackbar(enqueueSnackbar),
    skip: !actionPlan,
  });

  useEffect(() => {
    if (actionState.selected) {
      setOpenEditModal(true);
    }
  }, [actionState]);

  const actionSubsidies: ActionPlanningActionSubsidyFragment[] = subsidyData?.action_subsidy ?? [];

  if (!actionPlan) {
    return <Unavailable title={t('ActionPlanningActionPlan_ActionPlanNotAvailable')} />;
  }

  const building = ensureDefined(actionPlan?.building);
  const activeBuildingModel = ensureDefined(building.active_building_model);
  const totalArea = getNetArea(building.areas);

  const actions = metricsData?.actions;

  const lastAction = actions?.at(-1);
  // find the last action with an envelope, also copy the array to prevent mutation
  const latestEnvelopeAction = actions
    ? [...actions].reverse().find((action) => action.renovations_envelope && action.renovations_envelope?.length > 0)
    : undefined;

  const subBuildingSubsidies = building.sub_buildings
    .flatMap((subBuilding) => subBuilding.subsidies)
    .map((item) => item.subsidy);

  const hasActionPlanDeletePermission = building ? checkBuildingPermission(building.id, PermissionType.READ) : false;

  const afterDataBuildingModel = lastAction ? lastAction.building_models[0] : null;
  const implementationTo = lastAction ? new Date(lastAction.implementation_to) : new Date();
  implementationTo.setHours(0, 0, 0, 0); // we ignore the time

  const baseBuildingModel = lastAction ? afterDataBuildingModel : activeBuildingModel;

  const handleActionSelection = (selectedActionId: number, previousActionId: number | undefined) => {
    const actions = metricsData?.actions ?? [];
    const selectedAction = actions.find((action) => action.id === selectedActionId);
    const previousAction = actions.find((action) => action.id === previousActionId);
    const selectedIndex = actions.findIndex((action) => action.id === selectedActionId);
    const nextAction =
      selectedIndex !== undefined && selectedIndex !== -1 && actions && selectedIndex < actions.length - 1
        ? actions[selectedIndex + 1]
        : undefined;

    setActionState({
      selected: selectedAction,
      previous: previousAction,
      next: nextAction,
    });
    setHoverRowId(null);
    trackEvent('ACTION_VIEWED', { action_id: selectedActionId });
  };

  const { street, postal_code, city, country_id } = building.address;

  return (
    <Page title={t('ActionPlanningActionPlan_ActionPlanTitleWithName', { name: building.address.street })}>
      <Container maxWidth="lg">
        <StickyBox>
          <HeaderBreadcrumbs
            heading={t('ActionPlanningActionPlan_ActionPlanHeadingWithName', {
              name: building.address.street,
            })}
            links={[
              { name: t('PageName_Scenario'), href: PATHS.actionPlanning.scenarios() },
              {
                name: t('ActionPlanningActionPlan_ScenarioWithName', { name: actionPlan.scenario.name }),
                href: PATHS.actionPlanning.scenario({ id: actionPlan.scenario.id }),
              },
              { name: t('PageName_ActionPlan') },
            ]}
            infoText={t('ActionPlanningActionPlan_ActionPlanInfoText')}
            action={
              <ActionPlanActionMenu
                hasActionPlanDeletePermission={hasActionPlanDeletePermission}
                actionPlan={{
                  id: actionPlan.id,
                  buildingAddress: {
                    street,
                    postalCode: postal_code,
                    city,
                  },
                  totalActions: actions ? actions.length : 0,
                  scenarioId: actionPlan.scenario.id,
                }}
              />
            }
          />

          <Grid item xs={12} sx={{ mt: -4, pb: 2 }}>
            <BuildingProfileStatusQuo actionPlan={actionPlan} />
          </Grid>
        </StickyBox>

        <Grid container gap={8}>
          <Grid item xs={12}>
            <Co2PathGraph
              actionPlan={actionPlan}
              metricsData={metricsData}
              handleActionSelection={handleActionSelection}
              setHoverRowId={setHoverRowId}
              hoverRowId={hoverRowId}
            />
          </Grid>

          <Grid item xs={12}>
            <ActionListTable
              actionPlan={actionPlan}
              handleActionSelection={handleActionSelection}
              hoverRowId={hoverRowId}
              setMetricsData={(data: ActionPlanMetricsData) => setMetricsData(data)}
              totalArea={totalArea}
              baseBuildingModel={ensureDefined(baseBuildingModel)}
              onNewAction={() => setNewActionModalOpen(true)}
            />
          </Grid>

          <Grid item xs={12}>
            <ActionPlanDetails
              street={street}
              metricsData={metricsData}
              setNewActionModalOpen={setNewActionModalOpen}
              actionSubsidies={actionSubsidies}
              country={country_id}
            />
          </Grid>
        </Grid>

        {newActionModalOpen && (
          <CreateAction
            isOpen={newActionModalOpen}
            onClose={() => {
              setNewActionModalOpen(false);
            }}
            baseBuildingModel={ensureDefined(baseBuildingModel)}
            metricsData={metricsData}
            subBuildingSubsidies={subBuildingSubsidies}
            lastEnvelopeAction={latestEnvelopeAction}
            implementationTo={implementationTo}
            country={country_id}
            scenarioId={actionPlan.scenario.id}
          />
        )}

        {openEditModal && actionState.selected && (
          <EditAction
            onClose={() => {
              setOpenEditModal(false);
            }}
            baseBuildingModel={activeBuildingModel}
            allActions={actions ?? []}
            actionSubsidies={actionSubsidies}
            country={building.address.country_id}
            actionState={actionState}
            subBuildingSubsidies={subBuildingSubsidies}
            scenarioId={actionPlan.scenario.id}
          />
        )}
      </Container>
    </Page>
  );
}
