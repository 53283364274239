import { Box, Stack, TableCell, TableRow, Tooltip, Typography, useTheme } from '@mui/material';
import {
  ActionPlanningActionPlanActionPlanFragment,
  BuildingModelFragment,
  efficiency_class_enum,
  renovation_type_enum,
} from '@predium/client-graphql';
import { translateRenovationTypeEnum, translateRenovationTypeEnum_dynamic } from '@predium/i18n/client';
import { calculateImpact, Units } from '@predium/utils';
import { TFunction } from 'i18next';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EfficiencyClassLabel from '../../../../../components/EfficiencyClassLabel';
import Iconify from '../../../../../components/Iconify';
import { ParisReadyTooltip } from '../../../../../components/paris-ready-label/ParisReadyTooltip';
import { StrandingChangeLabel } from '../../../../../components/paris-ready-label/StrandingChangeLabel';
import StickyTableCell from '../../../../../components/table/StickyTableCell';
import { useLanguage } from '../../../../../provider/LanguageProvider';
import { getActionIcon } from '../../../../../utils/icons';
import {
  getRecommendedEnvelopeActionName,
  getRecommendedRouteActionName,
  getRecommendedSystemActionName,
} from '../../ActionPlan.utils';
import { ComparisonTableCell } from '../../Components/ComparisonTableCell';
import { RecommendationOutput } from '../RecommendedActions';
import RecommendedActionMenu from './RecommendedActionMenu';

type Props = {
  action: RecommendationOutput;
  hideAction: (id: number) => void;
  baseBuildingModel: BuildingModelFragment;
  actionPlan: ActionPlanningActionPlanActionPlanFragment;
  defaultImplementationDate: Date;
  totalArea: number;
};

const RecommendedAction = ({ action, hideAction, baseBuildingModel, actionPlan, defaultImplementationDate }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { localize } = useLanguage();

  const [editModalOpen, setEditModalOpen] = useState(false);

  const actionImpact = action.actionImpact;
  const {
    efficiency_class: efficiencyClass,
    total_estimated_cost,
    co2_emissions,
    final_energy,
    primary_energy,
  } = actionImpact;

  const strandingDate = actionImpact.stranding_date;

  // const finalEnergyBefore = Number(final_energy.before ?? 0).toFixed(1);
  const finalEnergyAfter = localize.formatAsFloat(final_energy.after ?? 0, {
    fractionDigits: 1,
  });

  // const co2EmissionBefore = Number(co2_emissions.before ?? 0).toFixed(1);
  const co2EmissionAfter = localize.formatAsFloat(co2_emissions.after ?? 0, {
    fractionDigits: 1,
  });

  // const primaryEnergyBefore = Number(primary_energy.before ?? 0).toFixed(1);
  const primaryEnergyAfter = localize.formatAsFloat(primary_energy.after ?? 0, {
    fractionDigits: 1,
  });

  const strandingDateAfter = strandingDate.after ? new Date(strandingDate.after).getFullYear().toString() : '-';
  const strandingDateChange = strandingDate?.impact ?? 0;

  const strandingDateText = localize.formatAsInteger(strandingDateChange, {
    unit: Units.year,
    signDisplay: 'exceptZero',
  });

  const { title, icon } = getRecommendedActionTitle(action, t);

  useEffect(() => {
    const tableContainer = document.querySelector('.MuiTableContainer-root');
    if (!tableContainer) return;

    const handleScroll = () => {
      const stickyElements = document.querySelectorAll('.sticky-cell');
      const hasScrollbar = tableContainer.clientWidth < tableContainer.scrollWidth;
      const isScrolled =
        hasScrollbar && tableContainer.scrollLeft + tableContainer.clientWidth < tableContainer.scrollWidth - 20;

      stickyElements.forEach((element) => {
        if (isScrolled) {
          element.classList.add('scrolled');
        } else {
          element.classList.remove('scrolled');
        }
      });
    };

    tableContainer.addEventListener('scroll', handleScroll);
    // Trigger initial check
    handleScroll();

    return () => tableContainer.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <TableRow
      hover
      sx={{
        width: '100%',
        background: theme.palette.grey[100],
        cursor: 'pointer',
      }}
      onClick={(e) => {
        if (editModalOpen) {
          e.stopPropagation();
        } else {
          setEditModalOpen(true);
        }
      }}
    >
      <TableCell
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          height: 60,
          width: 250,
        }}
      >
        <Tooltip placement="top" arrow title={translateRenovationTypeEnum(action.renovation_type)}>
          <Box component={'img'} src={icon} width={20} mr={2} />
        </Tooltip>
        <Typography variant="body2">{title}</Typography>
      </TableCell>

      <ComparisonTableCell
        after={finalEnergyAfter}
        comparisonPercent={calculateImpact(final_energy?.after ?? 0, final_energy?.before ?? 0)}
      />

      <ComparisonTableCell
        after={<EfficiencyClassLabel efficiencyClass={efficiencyClass.after ?? efficiency_class_enum.UNKNOWN} />}
        hideComparisonLabel
      />
      <ComparisonTableCell
        after={primaryEnergyAfter}
        comparisonPercent={calculateImpact(primary_energy?.after ?? 0, primary_energy?.before ?? 0)}
      />

      <ComparisonTableCell
        after={co2EmissionAfter}
        comparisonPercent={calculateImpact(co2_emissions?.after ?? 0, co2_emissions?.before ?? 0)}
      />

      <TableCell>
        <Stack display="flex" flexDirection={'row'} justifyContent={'start'} gap={2}>
          <Box display="flex" flexDirection="row" alignItems={'center'} gap={0.5}>
            <ParisReadyTooltip dateToCheck={strandingDateAfter} />
          </Box>
          <StrandingChangeLabel
            strandingDateChange={strandingDateChange}
            strandingDateAfter={strandingDateAfter}
            strandingDateText={strandingDateText}
          />
        </Stack>
      </TableCell>

      <TableCell>
        <Typography variant="body2">
          <Tooltip arrow placement="top" title={t('General_ApproximatedValue')}>
            <Stack direction={'row'} justifyContent={'end'} alignItems={'center'} whiteSpace={'nowrap'}>
              <Iconify width={20} height={20} color="info.main" icon="mdi:chart-timeline-variant-shimmer" mr={1} />
              <span>
                {localize.formatAsCurrency(total_estimated_cost, {
                  roundToNearestHundred: true,
                })}
              </span>
            </Stack>
          </Tooltip>
        </Typography>
      </TableCell>

      <TableCell>
        <Typography variant="body2" textAlign={'right'}>
          –
        </Typography>
      </TableCell>

      <StickyTableCell colSpan={2} align="right" sx={{ background: theme.palette.grey[100] }}>
        <RecommendedActionMenu
          action={action}
          hideAction={hideAction}
          baseBuildingModel={baseBuildingModel}
          actionPlan={actionPlan}
          defaultImplementationDate={defaultImplementationDate}
          editModalOpen={editModalOpen}
          setEditModalOpen={setEditModalOpen}
        />
      </StickyTableCell>
    </TableRow>
  );
};

export default RecommendedAction;

export const getRecommendedActionTitle = (action: RecommendationOutput, t: TFunction<'translation', undefined>) => {
  let title;
  let icon;
  switch (action.renovation_type) {
    case renovation_type_enum.ENVELOPE:
      const envelopeType = action.parameters.envelope_type;
      title = getRecommendedEnvelopeActionName(action.parameters.envelope_type);
      icon = getActionIcon(envelopeType);
      break;

    case renovation_type_enum.ENERGY_CONSUMER:
      const materialName = action.parameters.technology_name;
      const technologyType = action.parameters.consumer_technology_type;
      title = getRecommendedSystemActionName(materialName, technologyType);
      const systemType = action.parameters.system_type;
      icon = getActionIcon(systemType);
      break;

    case renovation_type_enum.ENERGY_SYSTEM_ROUTE:
      const energySourceType = action.parameters.energy_source_type;
      title = getRecommendedRouteActionName(energySourceType);
      icon = getActionIcon(energySourceType);
      break;

    case renovation_type_enum.SOLAR_PLANT:
    case renovation_type_enum.HYDRAULIC_BALANCING:
      title = translateRenovationTypeEnum_dynamic(action.renovation_type, t);
      icon = getActionIcon(action.renovation_type);
      break;

    default:
      title = '';
      break;
  }

  return { title, icon };
};
