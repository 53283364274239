import Box from '@mui/material/Box';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { consumption_type_enum, DataCollectionYearlyConsumptionFragment } from '@predium/client-graphql';
import { translateConsumptionTypeEnum } from '@predium/i18n/client';
import { Units } from '@predium/utils';
import { useTranslation } from 'react-i18next';
import InfoTooltip from '../../../../components/InfoTooltip';
import { useLanguage } from '../../../../provider/LanguageProvider';

export default function SubBuildingConsumptionWater({
  consumptionSummary,
}: {
  consumptionSummary: DataCollectionYearlyConsumptionFragment;
}) {
  const { t } = useTranslation();
  const { localize } = useLanguage();
  return (
    <Box sx={{ ml: 3 }}>
      <InfoTooltip
        text={t('DataCollection_ConsumptionTooltip')}
        maxwidth={450}
        sx={{ position: 'absolute', right: 15, top: 15 }}
      />
      <CardHeader sx={{ textAlign: 'left', mb: 0 }} title={translateConsumptionTypeEnum(consumption_type_enum.WATER)} />

      <Grid container spacing={3} sx={{ px: 3, py: 1 }}>
        <Grid item xs={12} md={4}>
          <Typography>{t('General_Provider')}</Typography>
        </Grid>
        <Grid textAlign="right" item xs={12} md={8}>
          <Typography align="right">
            {' '}
            {consumptionSummary.providers.length > 0 ? consumptionSummary?.providers.join(', ') : '—'}
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={3} sx={{ px: 3, py: 1 }}>
        <Grid item xs={12} md={6}>
          <Typography>{t('General_Consumption')}</Typography>
        </Grid>
        <Grid textAlign="right" item xs={12} md={6}>
          <Typography align="right">
            {localize.formatAsFloat(consumptionSummary.value, { unit: Units.waterConsumption })}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3} sx={{ px: 3, py: 1 }}>
        <Grid item xs={12} md={6}>
          <Typography>{t('General_Costs')}</Typography>
        </Grid>
        <Grid textAlign="right" item xs={12} md={6}>
          <Typography align="right">{localize.formatAsCurrency(consumptionSummary.cost)}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
