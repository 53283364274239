import { useMutation } from '@apollo/client';
import { ActionPlanningScenariosScenarioFragment } from '@predium/client-graphql';
import { useSnackbar } from 'notistack';
import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SnackbarTimeouts } from '../../../components/NotistackProvider';
import { EXPORT_SCENARIO_AS_EXCEL } from '../../../graphql/ActionPlanning.mutations';
import usePosthogTracking from '../../../hooks/usePosthogTracking';
import { useLanguage } from '../../../provider/LanguageProvider';
import { onExportCompleted } from '../../../utils/createDownloadLink';
import { getScenarioToDelete } from './utils';

export const useScenarioDownload = (scenarioName: string, scenarioId: number) => {
  const { t } = useTranslation();
  const { language } = useLanguage();
  const { enqueueSnackbar } = useSnackbar();

  const [downloadScenario, { loading: isLoading }] = useMutation(EXPORT_SCENARIO_AS_EXCEL, {
    variables: {
      scenarioId,
    },
    onCompleted: async (data) => {
      onExportCompleted(data?.exportScenarioAsExcel.body, 'xlsx', [scenarioName, 'action_plan'], language);
    },
    onError: () => {
      enqueueSnackbar(t('ActionPlanningScenario_ScenarioCouldNotBeExported'), {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
      });
    },
  });

  return [
    async () => {
      await downloadScenario();
    },
    isLoading,
  ] as const;
};

export const useEditScenarioDialog = () => {
  const [isEditScenarioDialogOpen, setIsEditScenarioDialogOpen] = useState(false);
  const handleEditScenarioClick = useCallback(() => setIsEditScenarioDialogOpen((open) => !open), []);

  return [isEditScenarioDialogOpen, handleEditScenarioClick] as const;
};

export const useCompareScenarioDialog = (isInitiallyOpen = false) => {
  const [isCompareScenarioDialogOpen, setIsCompareScenarioDialogOpen] = useState(isInitiallyOpen);
  const { trackEvent } = usePosthogTracking();
  const handleCompareScenarioClick = useCallback(() => {
    setIsCompareScenarioDialogOpen((open) => {
      if (!open) {
        trackEvent('SCENARIO_COMPARISON_DIALOG_OPEN');
      }
      return !open;
    });
  }, [trackEvent]);

  return [isCompareScenarioDialogOpen, handleCompareScenarioClick] as const;
};

export const useDeleteScenarioDialog = (scenario: ActionPlanningScenariosScenarioFragment) => {
  const [isDeleteScenarioDialogOpen, setIsDeleteScenarioDialogOpen] = useState(false);
  const handleDeleteScenarioClick = useCallback(() => setIsDeleteScenarioDialogOpen((open) => !open), []);
  const scenarioToDelete = useMemo(() => getScenarioToDelete(scenario), [scenario]);

  return [isDeleteScenarioDialogOpen, handleDeleteScenarioClick, scenarioToDelete] as const;
};

export const useMeasureOnResizeHeight = (initialHeight?: number) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [elementHeight, setElementHeight] = useState(initialHeight ?? 0);

  useLayoutEffect(() => {
    const windowResizeObserver = new ResizeObserver(() => {
      if (ref.current) {
        setElementHeight(ref.current.offsetHeight);
      }
    });

    if (ref.current) windowResizeObserver.observe(ref.current);

    return () => windowResizeObserver.disconnect();
  }, []);

  return [ref, elementHeight] as const;
};

export const useMeasureOnResizeWidth = (initialWidth?: number) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [elementWidth, setElementWidth] = useState(initialWidth ?? 0);

  useLayoutEffect(() => {
    const windowResizeObserver = new ResizeObserver(() => {
      if (ref.current) {
        setElementWidth(ref.current.offsetWidth);
      }
    });

    if (ref.current) windowResizeObserver.observe(ref.current);

    return () => windowResizeObserver.disconnect();
  }, []);

  return [ref, elementWidth] as const;
};
