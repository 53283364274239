import { Box, CardHeader, Typography, useTheme } from '@mui/material';
import { StrandingDataFragment } from '@predium/client-graphql';
import isEqual from 'lodash/isEqual';
import max from 'lodash/max';
import merge from 'lodash/merge';
import { memo } from 'react';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import BaseOptionChart from '../../../theme/apexcharts';

type Props = {
  strandingData: StrandingDataFragment[];
};

const PortfolioDistributionByStranding = ({ strandingData }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const isDataAvailable = strandingData && strandingData.length > 0;

  const dataWithYears = isDataAvailable
    ? strandingData.map((data) => {
        return [data.year, data ? data.count : 0];
      })
    : Array.from({ length: 31 }, (_, i) => [2020 + i, null]); //show x-axis from 2020 to 2050 if no data

  const chartState: ApexCharts.ApexOptions = merge(BaseOptionChart(), {
    chart: {
      id: 'portfolio-distribution-by-stranding',
    },
    xaxis: {
      tooltip: {
        enabled: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          fontSize: '12px',
        },
      },
      title: {
        text: t('General_Year'),
        style: {
          fontSize: '12px',
          color: theme.palette.text.secondary,
          fontWeight: 500,
        },
        offsetY: 5,
      },
    },
    yaxis: {
      min: 0,
      max: isDataAvailable ? max(strandingData.map((data) => data.count)) : 10,
      tickAmount: isDataAvailable ? Math.min(max(strandingData.map((data) => data.count)) ?? 0, 5) : 5, // Adjust the number of ticks based on the max value
      tooltip: {
        enabled: false,
      },

      labels: {
        formatter: function (val: number) {
          return Math.round(val).toString();
        },
      },
      title: {
        text: t('General_NoOfStrandedBuildings'),
        style: {
          fontSize: '12px',
          color: theme.palette.text.secondary,
          fontWeight: 500,
        },
      },
    },
    stroke: {
      width: 3,
      curve: 'straight',
      colors: [theme.palette.energySourceTypeColors.NATURAL_GAS.dark],
    },
    markers: {
      size: 3,
      colors: [theme.palette.energySourceTypeColors.NATURAL_GAS.dark],
      strokeColors: theme.palette.energySourceTypeColors.NATURAL_GAS.dark,
      strokeWidth: 2,
      hover: {
        size: 4,
      },
    },

    tooltip: {
      custom: ({ seriesIndex, dataPointIndex, w }: { seriesIndex: number; dataPointIndex: number; w: any }) => {
        const data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
        return `
          <div class="apexcharts-custom-tooltip" style="font-size:12px;">           
            <div>
              <span>${t('General_Year')}: ${data[0]}</span>
            </div>
            <div>
               <span>${t('General_Number')}: ${data[1]}</span>
            </div>
          </div>
        `;
      },
    },
  });

  const series = [
    {
      name: t('General_NoOfStrandedBuildings'),
      data: dataWithYears,
    },
  ];

  return (
    <Box
      sx={{
        width: '100%',
        position: 'relative',
      }}
    >
      <CardHeader
        title={t('EsgAnalysis_PortfolioDistributionByStrandingGraph-Title')}
        sx={{ p: 0, pl: 3 }}
        subheader={t('EsgAnalysis_PortfolioDistributionByStrandingGraph-SubTitle')}
      />
      {
        //@ts-ignore
        <Chart options={chartState} series={series} type="line" height={350} />
      }
      {!isDataAvailable && (
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center',
            mt: -2,
            background: theme.palette.common.white,
            p: 1,
          }}
        >
          <Box>
            <Typography variant="caption" color="text.secondary">
              {t('General_NoData')}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default memo(PortfolioDistributionByStranding, isEqual);
