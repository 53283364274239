import {
  sustainaccount_climate_risk_scenario_enum as RiskScenario,
  sustainaccount_climate_risk_severity_enum as RiskSeverity,
  sustainaccount_climate_risk_hazard_enum as RiskType,
} from '@predium/enums';
import { TFunction } from 'i18next';
import { CLIMATE_RISK_ICONS } from '../../../../assets/icons';
import { RiskWithScenario } from './types';

export const COLUMN_WIDTH = '120px';

export const CURRENT_YEAR = new Date().getFullYear();
export const IN_20_YEARS = CURRENT_YEAR + 20;
export const IN_50_YEARS = CURRENT_YEAR + 50;

export enum RiskCategory {
  TEMPERATURE_CHRONIC = 'TEMPERATURE_CHRONIC',
  TEMPERATURE_ACUTE = 'TEMPERATURE_ACUTE',
  WIND_CHRONIC = 'WIND_CHRONIC',
  WIND_ACUTE = 'WIND_ACUTE',
  WATER_CHRONIC = 'WATER_CHRONIC',
  WATER_ACUTE = 'WATER_ACUTE',
  SOLID_MASS_CHRONIC = 'SOLID_MASS_CHRONIC',
  SOLID_MASS_ACUTE = 'SOLID_MASS_ACUTE',
}

export const translateRiskCategory = (category: RiskCategory, t: TFunction) => {
  return (
    {
      [RiskCategory.TEMPERATURE_CHRONIC]: t('ClimateRiskV2_Category_TemperatureChronic'),
      [RiskCategory.TEMPERATURE_ACUTE]: t('ClimateRiskV2_Category_TemperatureAcute'),
      [RiskCategory.WIND_CHRONIC]: t('ClimateRiskV2_Category_WindChronic'),
      [RiskCategory.WIND_ACUTE]: t('ClimateRiskV2_Category_WindAcute'),
      [RiskCategory.WATER_CHRONIC]: t('ClimateRiskV2_Category_WaterChronic'),
      [RiskCategory.WATER_ACUTE]: t('ClimateRiskV2_Category_WaterAcute'),
      [RiskCategory.SOLID_MASS_CHRONIC]: t('ClimateRiskV2_Category_SolidMassChronic'),
      [RiskCategory.SOLID_MASS_ACUTE]: t('ClimateRiskV2_Category_SolidMassAcute'),
    } satisfies Record<RiskCategory, string>
  )[category];
};

export const RISKS_GROUPED_BY_CATEGORY = {
  [RiskCategory.TEMPERATURE_CHRONIC]: [
    RiskType.CHANGING_TEMPERATURE_AIR,
    RiskType.HEAT_STRESS,
    RiskType.PERMAFROST_THAWING,
    RiskType.TEMPERATURE_VARIABILITY,
  ],
  [RiskCategory.TEMPERATURE_ACUTE]: [RiskType.HEAT_WAVE, RiskType.COLD_WAVE, RiskType.FROST, RiskType.WILDFIRE],
  [RiskCategory.WIND_CHRONIC]: [RiskType.CHANGING_WIND_PATTERNS],
  [RiskCategory.WIND_ACUTE]: [
    RiskType.TORNADO,
    RiskType.SNOW_STORM,
    RiskType.WINTER_STORM,
    RiskType.CYCLONE_HURRICANE_TYPHOON,
  ],
  [RiskCategory.WATER_CHRONIC]: [
    RiskType.CHANGING_RAIN_PATTERNS,
    RiskType.SEA_LEVEL_RISE,
    RiskType.PRECIPITATION_HYDROLOGICAL_VARIABILITY,
    RiskType.OCEAN_ACIDIFICATION,
    RiskType.SALINE_INTRUSION,
    RiskType.WATER_STRESS,
  ],
  [RiskCategory.WATER_ACUTE]: [
    RiskType.DROUGHT,
    RiskType.HEAVY_RAIN,
    RiskType.HEAVY_HAIL,
    RiskType.GROUND_WATER_FLOOD,
    RiskType.RIVER_FLOOD,
    RiskType.COASTAL_FLOOD,
    RiskType.GLACIAL_LAKE_OUTBURST,
  ],
  [RiskCategory.SOLID_MASS_CHRONIC]: [
    RiskType.COASTAL_EROSION,
    RiskType.SOIL_EROSION,
    RiskType.SOIL_DEGRADATION,
    RiskType.SOLIFLUCTION,
  ],
  [RiskCategory.SOLID_MASS_ACUTE]: [RiskType.SUBSIDENCE, RiskType.AVALANCHE, RiskType.LANDSLIDE],
} as Record<RiskCategory, RiskType[]>;

export const RISK_CATEGORY_ICONS = {
  [RiskCategory.TEMPERATURE_CHRONIC]: CLIMATE_RISK_ICONS.TEMPERATURE,
  [RiskCategory.TEMPERATURE_ACUTE]: CLIMATE_RISK_ICONS.TEMPERATURE,
  [RiskCategory.WIND_CHRONIC]: CLIMATE_RISK_ICONS.WIND,
  [RiskCategory.WIND_ACUTE]: CLIMATE_RISK_ICONS.WIND,
  [RiskCategory.WATER_CHRONIC]: CLIMATE_RISK_ICONS.WATER,
  [RiskCategory.WATER_ACUTE]: CLIMATE_RISK_ICONS.WATER,
  [RiskCategory.SOLID_MASS_CHRONIC]: CLIMATE_RISK_ICONS.LANDSLIDE,
  [RiskCategory.SOLID_MASS_ACUTE]: CLIMATE_RISK_ICONS.LANDSLIDE,
} as Record<RiskCategory, string>;

export const getRiskPeriod = (risks: RiskWithScenario[], scenario: RiskScenario | undefined, year: number) => {
  const scenarioRisk = risks
    .find((risk) => {
      if (scenario) {
        return risk.scenario === scenario;
      }

      return true;
    })
    ?.periods.find((period) => {
      const [start, end] = period.range.split('-');

      return year >= parseInt(start) && year <= parseInt(end);
    });

  return scenarioRisk;
};

export const getTableHeaders = (selectedScenarios: string[], t: TFunction) => {
  const headers: {
    id: string;
    label: string;
    align: 'left' | 'center' | 'right';
    width: string;
    subLabel?: string;
  }[] = [{ id: 'risk', label: t('ClimateRiskV2_Risk'), align: 'left' as const, width: 'auto' }];

  selectedScenarios.forEach((scenario) => {
    headers.push(
      {
        id: `${CURRENT_YEAR}_${scenario}`,
        label: scenario,
        align: 'center' as const,
        width: COLUMN_WIDTH,
        subLabel: t('ClimateRiskV2_CurrentYear'),
      },
      {
        id: `${IN_20_YEARS}_${scenario}`,
        label: scenario,
        align: 'center' as const,
        width: COLUMN_WIDTH,
        subLabel: IN_20_YEARS.toString(),
      },
      {
        id: `${IN_50_YEARS}_${scenario}`,
        label: scenario,
        align: 'center' as const,
        width: COLUMN_WIDTH,
        subLabel: IN_50_YEARS.toString(),
      },
    );
  });

  return headers;
};

export { RiskScenario, RiskSeverity, RiskType };
