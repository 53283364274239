import { Box, useTheme } from '@mui/material';
import { Units, UnitsValue } from '@predium/utils';
import merge from 'lodash/merge';
import { useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../../../../../provider/LanguageProvider';
import BaseOptionChart from '../../../../../theme/apexcharts';

export type CompareScenariosEnergeticMetricChartSeriesType = [{ data: number[] }];

type Props = {
  series: CompareScenariosEnergeticMetricChartSeriesType;
  categories: string[];
  colors: string[];
  unit: UnitsValue;
  ariaLabel: string;
};

export default function CompareScenariosEnergeticMetricChart({ series, categories, colors, unit, ariaLabel }: Props) {
  const { localize } = useLanguage();
  const { t } = useTranslation();
  const theme = useTheme();

  const yLabelMinWidth = 160;
  const offsetX = useMemo(() => {
    // 6 and 8 are the average character width (empirical values)
    const highestLabelCharacterCount = categories.map((it) => it.length).sort((a, b) => b - a)[0];
    const longestLabelLength =
      highestLabelCharacterCount > 10 ? highestLabelCharacterCount * 6.6 : highestLabelCharacterCount * 8;
    if (longestLabelLength > yLabelMinWidth) {
      // 136.5 is the width of ellipsed text
      return (yLabelMinWidth - 136.5) * -1;
    }
    return (yLabelMinWidth - longestLabelLength) * -1;
  }, [categories]);

  const chartOptions = merge(BaseOptionChart(), {
    colors,
    chart: {
      width: '100%',
      height: '100%',
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        distributed: true,
        barHeight: 8,
        borderRadius: 2,
      },
    },
    grid: {
      show: false,
      padding: { top: -5, bottom: -5, left: 0, right: 0 },
    },

    xaxis: {
      type: 'category',
      categories,
      tickAmount: series[0].data.reduce((sum, value) => sum + value, 0) === 0 ? 0 : 5,
      min: 0,
      max: Math.round(Math.max(...series[0].data)) + Math.round(Math.max(...series[0].data)) * 0.2,
      labels: {
        style: {
          fontSize: '12px',
        },
        formatter: (value: number) =>
          localize.formatAsCompact(value, { fractionDigits: 0, roundToNearestHundred: true }),
      },
    },

    yaxis: {
      labels: {
        align: 'left',
        minWidth: yLabelMinWidth,
        style: {
          fontSize: '12px',
        },
        offsetX,
        offsetY: 2.5,
      },
    },
    legend: {
      show: false,
    },

    dataLabels: {
      enabled: true,
      formatter: function (value: number) {
        // do not show data labels if there is no scenarioTwo selected
        const scenarioTwoCategoryLabel = categories[0];
        if ((!scenarioTwoCategoryLabel || scenarioTwoCategoryLabel === '') && value === 0) return;

        if (unit === Units.price) {
          return localize.formatAsCurrency(value, { displayCompact: true });
        }
        return localize.formatAsCompact(value, { unit });
      },
      textAnchor: 'start',
      offsetY: -18,
      offsetX: 15,
    },
    states: {
      hover: {
        filter: {
          type: 'none',
        },
      },
      active: {
        filter: {
          type: 'none',
        },
      },
    },
    noData: {
      text: t('General_NoDataAvailable'),
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: -40,
      style: {
        color: theme.palette.text.secondary,
        fontSize: '12px',
        fontFamily: undefined,
      },
    },
  });

  return (
    <Box pr={1}>
      <ReactApexChart aria-label={ariaLabel} type="bar" series={series} options={chartOptions} height={200} />
    </Box>
  );
}
