import { Box, Divider, Stack, Typography } from '@mui/material';
import { area_type_enum } from '@predium/enums';
import { ensureDefined, getAreaSum } from '@predium/utils';
import uniqBy from 'lodash/uniqBy';
import { useTranslation } from 'react-i18next';
import useBuilding from '../Context/useBuilding';
import SquareMeterRow from './Components/SquareMeterRow';
import { useArea } from './hooks';
import { calculateNGF } from './utils';

const AreaSummary = () => {
  const { t } = useTranslation();
  const {
    building: {
      address: { country_id },
    },
  } = useBuilding();
  const { fields, areas, getPropsForEditableArea } = useArea();

  const bgf = ensureDefined(fields.find((area) => area.area_type_id === area_type_enum.BGF));

  const mf = areas.filter((area) => area.area_type_id === area_type_enum.MF && !area.delete);

  const sumOfMF = getAreaSum(mf);
  const countOfMF = mf.length;

  const af = ensureDefined(areas.find((area) => area.area_type_id === area_type_enum.AF));

  const ebf = areas.filter((area) => area.area_type_id === area_type_enum.EBF && !area.delete);

  const sumOfEBF = getAreaSum(ebf);
  const countOfEBF = uniqBy(ebf, 'type_of_use_id').length;

  const ngf = calculateNGF(areas, country_id);

  return (
    <Stack flex={0.4} spacing={2}>
      <Typography variant="subtitle2" fontWeight={700}>
        {t('DataCollectionAreas_AreaSummary')}
      </Typography>
      <Box bgcolor="grey.100" px={1} py={2} sx={{ borderRadius: 2 }}>
        <Stack spacing={0.25}>
          <SquareMeterRow
            labels={{
              title: t('DataCollectionAreas_GrossFloorArea'),
              tooltipBody: t('DataCollectionAreas_GrossFloorAreaTooltipBody'),
            }}
            titleProps={{
              variant: 'body2',
              color: 'text.secondary',
            }}
            options={{
              hideActionPlaceholder: true,
            }}
            {...getPropsForEditableArea(bgf.uid)}
          />
          <SquareMeterRow
            labels={{
              title: t('DataCollectionAreas_NetFloorArea'),
              tooltipBody: t('DataCollectionAreas_NetFloorAreaTooltipBody'),
            }}
            titleProps={{
              variant: 'body2',
              color: 'text.secondary',
            }}
            options={{
              hideEquationError: true,
              hideActionPlaceholder: true,
              showCalculation: true,
            }}
            areaType={area_type_enum.NGF}
            value={ngf}
          />
          <Divider sx={{ my: 1 }} />
          <SquareMeterRow
            labels={{
              title: t('DataCollectionAreas_RentableArea'),
              tooltipBody: t('DataCollectionAreas_RentableAreaTooltipBody'),
            }}
            titleProps={{
              variant: 'body2',
              color: 'text.secondary',
            }}
            options={{
              hideEquationError: true,
              hideActionPlaceholder: true,
            }}
            areaType={area_type_enum.MF}
            value={sumOfMF}
          />
          <SquareMeterRow
            labels={{
              title: t('DataCollectionAreas_CommonArea'),
              tooltipBody: t('DataCollectionAreas_CommonAreaTooltipBody'),
            }}
            titleProps={{
              variant: 'body2',
              color: 'text.secondary',
            }}
            options={{
              hideEquationError: true,
              hideActionPlaceholder: true,
            }}
            areaType={area_type_enum.AF}
            value={af.value}
          />
          <SquareMeterRow
            labels={{
              title: t('DataCollectionAreas_RentalUnits'),
            }}
            titleProps={{
              variant: 'body2',
              color: 'text.secondary',
            }}
            displayUnit={false}
            options={{
              hideEquationError: true,
              hideActionPlaceholder: true,
              displayErrorInline: false,
            }}
            areaType={area_type_enum.MF}
            value={countOfMF}
          />
          <Divider sx={{ my: 1 }} />
          <SquareMeterRow
            labels={{
              title: t('DataCollectionAreas_EnergyReferenceArea'),
              tooltipSubtitle: t('DataCollectionAreas_EnergyReferenceAreaTooltipSubtitle'),
              tooltipBody: t('DataCollectionAreas_EnergyReferenceAreaTooltipBody'),
            }}
            titleProps={{
              variant: 'body2',
              color: 'text.secondary',
            }}
            options={{
              hideEquationError: true,
              hideActionPlaceholder: true,
            }}
            areaType={area_type_enum.EBF}
            value={sumOfEBF}
          />
          <SquareMeterRow
            labels={{
              title: t('General_TypeOfUse', { count: 2 }),
            }}
            titleProps={{
              variant: 'body2',
              color: 'text.secondary',
            }}
            displayUnit={false}
            options={{
              hideEquationError: true,
              hideActionPlaceholder: true,
              displayErrorInline: false,
            }}
            areaType={area_type_enum.EBF}
            value={countOfEBF}
          />
        </Stack>
      </Box>
    </Stack>
  );
};

export default AreaSummary;
