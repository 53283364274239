/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Grid } from '@mui/material';
import { Units } from '@predium/utils';
import { useTranslation } from 'react-i18next';
import { OtherFormValuesProps } from '../../BuildingEnvelope';
import { EnvelopeUnitField } from './EnvelopeUnitField';
import { FieldSectionTitle } from './EnvelopeUnitWrapper';

export function EnvelopeConstructionDetails({
  data,
  index,
  reCalculateUValue,
}: {
  data: OtherFormValuesProps;
  index: number;
  reCalculateUValue: () => void;
}) {
  const { t } = useTranslation();
  const { envelope_type: type, id } = data;
  const fieldNamePrefix = `${type}[${index}]`;

  return (
    <>
      <Grid item xs={12}>
        <FieldSectionTitle title={t('General_BasicConstruction')} />
      </Grid>
      <Grid item xs={12}>
        <EnvelopeUnitField
          title={t('General_Type')}
          name={`${fieldNamePrefix}.base_construction_material_name`}
          //@ts-ignore
          id={id}
          fieldType="text"
          tooltipProps={{
            labels: {
              title: t('General_Type'),
              body: t('Education_BasicConstructionBody'),
            },
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <EnvelopeUnitField
          title={t('General_ComponentThickness')}
          name={`${fieldNamePrefix}.base_construction_thickness`}
          unit={Units.centimeters}
          //@ts-ignore
          id={id}
          fieldType="number"
          onValueChange={reCalculateUValue}
        />
      </Grid>
      <Grid item xs={6}>
        <EnvelopeUnitField
          title={t('General_ThermalConductivityLambda')}
          name={`${fieldNamePrefix}.base_construction_lambda`}
          //@ts-ignore
          id={id}
          fieldType="number"
          unit={Units.thermalConductivityLambda}
          tooltipProps={{
            labels: {
              title: t('General_ThermalConductivityLambda'),
              body: t('Education_ThermalConductivityLambdaBody'),
            },
          }}
          onValueChange={reCalculateUValue}
        />
      </Grid>
    </>
  );
}
