import { yupResolver } from '@hookform/resolvers/yup';
import { area_type_enum, building_state_enum, country_enum, language_enum } from '@predium/enums';
import { accessEnum, ensureDefined } from '@predium/utils';
import { TFunction } from 'i18next';
import isNil from 'lodash/isNil';
import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useForm, UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { DelayedLoading } from '../../../../components/Loading';
import useSessionData from '../../../../hooks/useSessionData';
import { useLanguage } from '../../../../provider/LanguageProvider';
import { filterEmptyObjects } from '../../../../utils/yupCustomMethods';
import useBlockOnBack from './hooks/useBlockOnBack';
import { useGetBuildingDraft } from './hooks/useGetBuildingDraft';
import { ACTION_TYPES, ActionTypeDispatch, GlobalState, useGlobalState } from './hooks/useGlobalState';
import { useUpsertDraftBuilding } from './hooks/useUpsertDraftBuilding';
import { ADDRESS_SEARCH, AddressSearchFormRef } from './Step1Location/Substeps/AddressSearch';
import { BUILDING_SELECTION, BuildingSelectionFormRef } from './Step1Location/Substeps/BuildingSelection';
import { MANUAL_ADDRESS, ManualAddressFormRef } from './Step1Location/Substeps/ManualAddress';
import {
  getAddressSearchFormSchema,
  getBuildingSelectionFormSchema,
  getManualAddressFormSchema,
} from './Step1Location/validations';
import { BUILDING_INFORMATION, InformationFormRef } from './Step2Information';
import { getInformationFormSchema } from './Step2Information/validations';
import { Step3ReviewRef } from './Step3Review';
import { steps } from './Steps';

const getBuildingCreationSchema = (t: TFunction, requireBuildingSelection: boolean, language: language_enum) => {
  return yup.object({
    addressSearch: getAddressSearchFormSchema(t),
    address: getManualAddressFormSchema(t),
    buildingSelection: getBuildingSelectionFormSchema(requireBuildingSelection),
    information: getInformationFormSchema(t, language),
  });
};

export type BuildingCreationFormCast = ReturnType<ReturnType<typeof getBuildingCreationSchema>['cast']>;

const INITIAL_STEP = 'INITIAL_STEP' as const;

type InitialStep = {
  type: typeof INITIAL_STEP;
};

export type ActiveStepRef =
  | InitialStep
  | AddressSearchFormRef
  | ManualAddressFormRef
  | BuildingSelectionFormRef
  | InformationFormRef
  | Step3ReviewRef;

type ContextType = {
  // Core data
  getBuilding: () => BuildingCreationFormCast;
  state: GlobalState;
  dispatch: ActionTypeDispatch;
  activeStep: (typeof steps)[number];
  currentFormSubstep?: number | undefined;
  activeStepRef: React.MutableRefObject<ActiveStepRef>;
  canSaveAsDraft: boolean;
  handleBack: (whichSubstepToGoBack?: number) => void;
  handleNext: VoidFunction;
  handleSaveAsDraft: (
    onSuccess?: (draftId: number | undefined) => void,
    skipNotification?: boolean,
    skipRedirect?: boolean,
  ) => void;
  handleCloseMainDialog: VoidFunction;
  handleOpenSaveAsDraftDialog: VoidFunction;
  handleCloseDialog: VoidFunction;
  setValue: UseFormSetValue<BuildingCreationFormCast>;
  isLoading: boolean;
  isMainFormDirty: boolean;
};

const BuildingCreationContext = createContext<ContextType | null>(null);

type Props = {
  id?: number;
  children: (value: ContextType) => React.ReactNode;
  onClose: VoidFunction;
};

const BuildingCreationProvider = ({ children, id, onClose: onCloseMainDialog }: Props) => {
  const { t } = useTranslation();
  const [requireBuildingSelection, setRequireBuildingSelection] = useState(false);
  const { language } = useLanguage();
  const schema = useMemo(
    () => getBuildingCreationSchema(t, requireBuildingSelection, language),
    [language, t, requireBuildingSelection],
  );
  const { user } = useSessionData();

  const { state, dispatch } = useGlobalState({
    defaultStep: isNil(id) ? 0 : 1,
  });

  const activeStep = steps[state.activeStepIndex];
  const hasSubsteps = !!activeStep?.substeps;
  const currentFormSubstep = state.substeps[activeStep?.id];
  const maxSubstep = activeStep?.substeps?.length ? activeStep?.substeps?.length - 1 : 0;

  const activeStepRef = useRef<ActiveStepRef>({
    type: INITIAL_STEP,
  });

  const { getValues, formState, setValue, handleSubmit, reset } = useForm({
    defaultValues: schema.cast({
      addressSearch: schema.fields.addressSearch.cast({
        addressSearch: undefined,
      }),
      address: schema.fields.address.cast({
        countryId: country_enum.DE,
        street: undefined,
        postalCode: undefined,
        city: undefined,
      }),
      information: schema.fields.information.cast({
        responsibleUserId: ensureDefined(user).id,
        coreData: {
          state: building_state_enum.INVENTORY,
          portfolioId: undefined,
          floors: undefined,
        },
        additionalInformation: {
          monumentProtection: false,
          milieuProtection: false,
          heritageDistrict: false,
          leasehold: false,
        },
        area: {
          typeOfUse: undefined,
          typeOfArea: area_type_enum.EBF,
          totalArea: undefined,
        },
        energyData: [],
        buildingValuations: [],
      }),
    }),
    resolver: yupResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const { loading: loadingBuildingDraft } = useGetBuildingDraft({ id, reset });

  const { handleCreateDraftBuilding, loading } = useUpsertDraftBuilding({
    id,
    dispatch,
    reset,
  });

  const { isValid, isDirty } = formState;

  const getBuilding = useCallback(() => getValues(), [getValues]);
  const building = getBuilding();

  useEffect(() => {
    const searchSelection = building.addressSearch?.addressSearch;
    const isNotManualFlow = searchSelection.lat && searchSelection.long && searchSelection.postalCode;

    setRequireBuildingSelection(isNotManualFlow);
  }, [building.addressSearch]);

  const canSaveAsDraft = useMemo(() => {
    return isValid && !state.isCurrentStepInValid;
  }, [isValid, state.isCurrentStepInValid]);

  const handleStepSubmit = useCallback(
    ({ onSuccess, onError }: { onSuccess?: VoidFunction; onError?: VoidFunction }) => {
      const ref = activeStepRef.current;

      switch (ref.type) {
        case ADDRESS_SEARCH: {
          const { onSubmit, onSubmitSuccess } = ref;

          onSubmit((data) => {
            setValue('addressSearch', data);

            // Prefills the fields from substep 2
            setValue('address.city', data.addressSearch.city);
            setValue('address.postalCode', data.addressSearch.postalCode);
            setValue('address.street', data.addressSearch.address || data.addressSearch.inputValue);
            setValue('address.countryId', accessEnum(country_enum, data.addressSearch.country || country_enum.DE));

            if (onSubmitSuccess)
              onSubmitSuccess({
                ...getBuilding(),
                addressSearch: data,
              });
            else onSuccess?.();
          }, onError)();
          break;
        }
        case MANUAL_ADDRESS: {
          const { onSubmit, onSubmitSuccess } = ref;
          onSubmit((data) => {
            setValue('address', data);

            if (onSubmitSuccess)
              onSubmitSuccess({
                ...getBuilding(),
                address: data,
              });
            else onSuccess?.();
          }, onError)();

          break;
        }
        case BUILDING_SELECTION: {
          const { onSubmit } = ref;
          onSubmit((data) => {
            setValue('buildingSelection', data);
            onSuccess?.();
          }, onError)();

          break;
        }
        case BUILDING_INFORMATION: {
          const { onSubmit } = ref;

          onSubmit((data) => {
            setValue('information', {
              ...data,
              // Removes any empty entries from the array.
              // Which makes it easier to handle data in the useCompleteBuilding hook
              energyData: filterEmptyObjects(data.energyData ?? []),
            });

            onSuccess?.();
          }, onError)();

          break;
        }
        default:
          onSuccess?.();
          break;
      }
    },
    [setValue, getBuilding],
  );

  const handleBack = useCallback(
    (whichSubstepToGoBack?: number) => {
      const ref = activeStepRef.current;
      const overrideSubstep = typeof whichSubstepToGoBack === 'number';

      const handleSubmit = () => {
        dispatch({ type: ACTION_TYPES.SET_STEP_INVALIDITY, payload: false });

        handleStepSubmit({
          onError: () => {
            if (hasSubsteps && currentFormSubstep > 1) {
              dispatch({
                type: ACTION_TYPES.SET_ACTIVE_SUBSTEP,
                payload: overrideSubstep ? whichSubstepToGoBack : currentFormSubstep - 1,
              });
            } else {
              dispatch({ type: ACTION_TYPES.PREVIOUS_STEP });
              if (overrideSubstep) dispatch({ type: ACTION_TYPES.SET_ACTIVE_SUBSTEP, payload: whichSubstepToGoBack });
            }
          },
          onSuccess: () => {
            if (hasSubsteps && currentFormSubstep > 1) {
              dispatch({
                type: ACTION_TYPES.SET_ACTIVE_SUBSTEP,
                payload: overrideSubstep ? whichSubstepToGoBack : currentFormSubstep - 1,
              });
            } else {
              dispatch({ type: ACTION_TYPES.PREVIOUS_STEP });
              if (overrideSubstep) dispatch({ type: ACTION_TYPES.SET_ACTIVE_SUBSTEP, payload: whichSubstepToGoBack });
            }
          },
        });
      };

      switch (ref.type) {
        case MANUAL_ADDRESS: {
          ref.onPreviousSubstep();
          break;
        }

        case BUILDING_SELECTION: {
          ref.onPreviousSubstep();
          break;
        }

        default:
          handleSubmit();
          break;
      }

      if (ref.type === MANUAL_ADDRESS) {
        ref.onPreviousSubstep();
        return;
      }
    },
    [dispatch, handleStepSubmit, currentFormSubstep, hasSubsteps],
  );

  const handleNext = useCallback(() => {
    handleStepSubmit({
      onSuccess: () => {
        if (hasSubsteps && currentFormSubstep < maxSubstep) {
          dispatch({ type: ACTION_TYPES.SET_ACTIVE_SUBSTEP, payload: currentFormSubstep + 1 });
        } else {
          dispatch({ type: ACTION_TYPES.NEXT_STEP });
        }
      },
    });
  }, [dispatch, handleStepSubmit, currentFormSubstep, hasSubsteps, maxSubstep]);

  const handleSaveAsDraft = useCallback(
    (onSuccess?: (draftId: number | undefined) => void, skipNotification?: boolean, skipRedirect?: boolean) => {
      handleStepSubmit({
        onSuccess: handleSubmit((building) => {
          handleCreateDraftBuilding(building, skipNotification, skipRedirect).then(onSuccess);
        }),
      });
    },
    [handleStepSubmit, handleSubmit, handleCreateDraftBuilding],
  );

  const handleCloseMainDialog = useCallback(() => onCloseMainDialog(), [onCloseMainDialog]);

  const handleOpenSaveAsDraftDialog = useCallback(() => {
    handleStepSubmit({
      onSuccess: () => dispatch({ type: ACTION_TYPES.SET_DRAFT_DIALOG, payload: true }),
    });
  }, [dispatch, handleStepSubmit]);

  const handleCloseDialog = useCallback(() => {
    if (!state.blockClose) {
      onCloseMainDialog();
      return;
    }

    handleStepSubmit({
      onSuccess: handleSubmit(handleOpenSaveAsDraftDialog, () => {
        dispatch({ type: ACTION_TYPES.SET_LEAVE_DIALOG, payload: true });
      }),
      onError: () => {
        dispatch({ type: ACTION_TYPES.SET_LEAVE_DIALOG, payload: true });
      },
    });
  }, [state.blockClose, handleOpenSaveAsDraftDialog, dispatch, handleStepSubmit, handleSubmit, onCloseMainDialog]);

  useBlockOnBack(state.blockClose, handleCloseDialog);

  const value = {
    getBuilding,
    state,
    activeStep,
    currentFormSubstep,
    canSaveAsDraft,
    handleBack,
    handleNext,
    handleSaveAsDraft,
    handleCloseMainDialog,
    handleOpenSaveAsDraftDialog,
    handleCloseDialog,
    activeStepRef,
    isLoading: loading,
    isMainFormDirty: isDirty,
    setValue,
    dispatch,
  } satisfies ContextType;

  if (loadingBuildingDraft) {
    return <DelayedLoading />;
  }

  return <BuildingCreationContext.Provider value={value}>{children(value)}</BuildingCreationContext.Provider>;
};

export default BuildingCreationProvider;

export const useBuildingCreation = () => {
  const context = useContext(BuildingCreationContext);

  if (!context) {
    throw new Error('useBuildingCreation must be used within a BuildingCreationProvider');
  }

  return context;
};
