import { Dispatch, useReducer } from 'react';
import { steps } from '../Steps';

export type GlobalState = {
  activeStepIndex: number;
  isCurrentStepInValid: boolean;
  isLeavePageDialogOpen: boolean;
  isSaveAsDraftDialogOpen: boolean;
  blockClose: boolean;
  hasSubsteps: boolean;
  isCreatingBuilding: boolean;
  invalidMessage: string | undefined;
  substeps: {
    [x: string]: number;
  };
};

// Action type constants
export const ACTION_TYPES = {
  SET_ACTIVE_STEP: 'SET_ACTIVE_STEP',
  NEXT_STEP: 'NEXT_STEP',
  PREVIOUS_STEP: 'PREVIOUS_STEP',
  SET_STEP_INVALIDITY: 'SET_STEP_INVALIDITY',
  SET_LEAVE_DIALOG: 'SET_LEAVE_DIALOG',
  SET_DRAFT_DIALOG: 'SET_DRAFT_DIALOG',
  SET_BLOCK_CLOSE: 'SET_BLOCK_CLOSE',
  SET_ACTIVE_SUBSTEP: 'SET_ACTIVE_SUBSTEP',
  SET_IS_CREATING_BUILDING: 'SET_IS_CREATING_BUILDING',
} as const;

export type ActionType =
  | { type: typeof ACTION_TYPES.SET_ACTIVE_STEP; payload: number }
  | { type: typeof ACTION_TYPES.NEXT_STEP }
  | { type: typeof ACTION_TYPES.PREVIOUS_STEP }
  | { type: typeof ACTION_TYPES.SET_STEP_INVALIDITY; payload: boolean; message?: string }
  | { type: typeof ACTION_TYPES.SET_LEAVE_DIALOG; payload: boolean }
  | { type: typeof ACTION_TYPES.SET_DRAFT_DIALOG; payload: boolean }
  | { type: typeof ACTION_TYPES.SET_BLOCK_CLOSE; payload: boolean }
  | { type: typeof ACTION_TYPES.SET_IS_CREATING_BUILDING; payload: boolean }
  | { type: typeof ACTION_TYPES.SET_ACTIVE_SUBSTEP; payload: number };

const initialState: GlobalState = {
  activeStepIndex: 0,
  isCurrentStepInValid: false,
  blockClose: false,
  isLeavePageDialogOpen: false,
  isSaveAsDraftDialogOpen: false,
  hasSubsteps: !!steps[0]?.substeps?.length,
  isCreatingBuilding: false,
  invalidMessage: undefined,
  substeps: steps
    .map((s) => ({ [s.id]: 0 }))
    .reduce((a, v) => {
      return { ...a, ...v };
    }, {}),
};

function reducer(state: GlobalState, action: ActionType): GlobalState {
  switch (action.type) {
    case ACTION_TYPES.SET_ACTIVE_STEP:
      return {
        ...state,
        activeStepIndex: action.payload,
      };
    case ACTION_TYPES.NEXT_STEP:
      return {
        ...state,
        hasSubsteps: !!steps[state.activeStepIndex + 1].substeps?.length,
        activeStepIndex: state.activeStepIndex + 1,
      };
    case ACTION_TYPES.PREVIOUS_STEP:
      return {
        ...state,
        hasSubsteps: !!steps[state.activeStepIndex - 1].substeps?.length,
        activeStepIndex: state.activeStepIndex - 1,
      };
    case ACTION_TYPES.SET_STEP_INVALIDITY:
      return {
        ...state,
        isCurrentStepInValid: action.payload,
        invalidMessage: action.payload ? action.message : undefined,
      };
    case ACTION_TYPES.SET_LEAVE_DIALOG:
      return {
        ...state,
        isLeavePageDialogOpen: action.payload,
      };
    case ACTION_TYPES.SET_DRAFT_DIALOG:
      return {
        ...state,
        isSaveAsDraftDialogOpen: action.payload,
      };
    case ACTION_TYPES.SET_BLOCK_CLOSE:
      return {
        ...state,
        blockClose: action.payload,
      };
    case ACTION_TYPES.SET_IS_CREATING_BUILDING:
      return {
        ...state,
        isCreatingBuilding: action.payload,
      };
    case ACTION_TYPES.SET_ACTIVE_SUBSTEP:
      return {
        ...state,
        substeps: {
          ...state.substeps,
          [steps[state.activeStepIndex].id]: action.payload,
        },
      };
    default:
      return state;
  }
}

export const useGlobalState = ({ defaultStep }: { defaultStep: number }) => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    activeStepIndex: defaultStep,
    hasSubsteps: !!steps[defaultStep].substeps?.length,
  });

  return {
    state,
    dispatch,
  };
};

export type ActionTypeDispatch = Dispatch<ActionType>;
