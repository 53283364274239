import { CardContent, Divider, Stack, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import {
  AnalyzeBuildingCrremAnalysisFragment,
  AnalyzeBuildingsCrremAnalysisFragment,
  StrandingDataFragment,
} from '@predium/client-graphql';
import { PARIS_READY_YEAR } from '@predium/client-lookup';
import isEqual from 'lodash/isEqual';
import { ReactNode, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ACADEMY_LINKS } from '../../../assets/links';
import { DelayedLoading } from '../../../components/Loading';
import TabLabelWithOptionalTooltip from '../../../components/common/TabLabelWithOptionalTooltip';
import { PreLink } from '../../../components/presentations/PreLink';
import PreTabsAsToggle from '../../../components/presentations/PreTabsAsToggle';
import usePosthogTracking from '../../../hooks/usePosthogTracking';
import { CurrentPageView } from '../Portfolio/PortfolioAnalysisHeader';
import PortfolioDistributionByStranding from '../Visualization/PortfolioDistributionByStranding';
import Co2CrremAnalysis from './CrremAnalysis/Co2CrremAnalysis';
import { EnergyCrremAnalysis } from './CrremAnalysis/EnergyCrremAnalysis';
import ExcessEmissionCost from './CrremAnalysis/ExcessEmissionCost';

export const esgPathGraphOptions = {
  chart: {
    toolbar: {
      offsetY: -40,
    },
  },
  stroke: {
    curve: 'straight',
    dashArray: [0, 4],
    width: [4, 2],
  },
  legend: {
    show: false,
  },
} as ApexCharts.ApexOptions;

type Props = {
  buildingLegendName: string;
  cumulativeExcessEmissions: number | undefined;
  selectedBenchmarkName: string;
  benchmarkCrremAnalysis:
    | {
        yearOfExcedance?: number | null;
        strandingYear?: number | null;
      }
    | undefined;
  crremAnalysis: AnalyzeBuildingCrremAnalysisFragment | AnalyzeBuildingsCrremAnalysisFragment | undefined | null;
  loading?: boolean;
  graphStartYear: number;
  currentPageView: CurrentPageView;
  strandingData?: StrandingDataFragment[];
};

type PathGraphType = 'co2-graph' | 'energy-graph' | 'excess-emission-cost' | 'stranding-year';

function changeOfYears(benchmarkValue: number | null | undefined, currentValue: number | null | undefined): number {
  // Case where there was stranding and still is stranding
  if (benchmarkValue && currentValue) {
    return currentValue - benchmarkValue;

    // Case where there was no stranding, but action worsened it => now there is stranding
  } else if (currentValue) {
    return currentValue - PARIS_READY_YEAR;

    // Case where there was stranding, but action improved it => now there is no stranding.
  } else if (benchmarkValue) {
    return PARIS_READY_YEAR - benchmarkValue;
  }

  // Standard case where there was no stranding and stayed that way
  return 0;
}

function CrremAnalysis({
  buildingLegendName,
  selectedBenchmarkName,
  cumulativeExcessEmissions,
  graphStartYear,
  benchmarkCrremAnalysis,
  crremAnalysis,
  loading,
  currentPageView,
  strandingData,
}: Props) {
  const { t } = useTranslation();
  const { trackEvent } = usePosthogTracking();

  const [pathGraphType, setPathGraphType] = useState<PathGraphType>('co2-graph');

  if (loading && crremAnalysis === undefined) {
    return <DelayedLoading />;
  }

  const createLink = (link: string) => {
    return (
      <PreLink
        link={{
          href: link,
          name: t('General_LearnMore'),
          external: true,
        }}
      />
    );
  };

  const co2Path = crremAnalysis?.co2Path?.m2 ?? [];
  const co2TargetPath = crremAnalysis?.co2TargetPath ?? [];
  const energyPath = crremAnalysis?.energyPath?.m2 ?? [];
  const energyTargetPath = crremAnalysis?.energyTargetPath ?? [];
  const strandingYear = crremAnalysis?.strandingYear;
  const yearOfExcedance = crremAnalysis?.yearOfExcedance;

  // Calculate change in years
  const yearOfExcedanceChange = changeOfYears(benchmarkCrremAnalysis?.yearOfExcedance, crremAnalysis?.yearOfExcedance);
  const strandingYearChange = changeOfYears(benchmarkCrremAnalysis?.strandingYear, crremAnalysis?.strandingYear);

  const tabs: {
    label: ReactNode;
    value: PathGraphType;
  }[] = [
    {
      label: (
        <TabLabelWithOptionalTooltip
          label={t('General_CO2Path')}
          tooltipText={t('General_1_5C02PathGraph-tooltip')}
          link={createLink(ACADEMY_LINKS.CO2_PATH_GRAPH)}
        />
      ),
      value: 'co2-graph',
    },

    {
      label: (
        <TabLabelWithOptionalTooltip
          label={t('General_ExcessEmissionCost')}
          tooltipText={t('General_ExcessEmissionCostGraph-tooltip')}
          //PRE-6000
        />
      ),
      value: 'excess-emission-cost',
    },
    {
      label: (
        <TabLabelWithOptionalTooltip
          label={t('General_EnergyPath')}
          tooltipText={t('General_1_5EnergyPathGraph-tooltip')}
          link={createLink(ACADEMY_LINKS.ENERGY_PATH_GRAPH)}
        />
      ),
      value: 'energy-graph',
    },
  ];

  // Only add stranding-year tab if not in building view
  if (currentPageView !== 'esg-building') {
    tabs.push({
      label: <TabLabelWithOptionalTooltip label={t('ESGAnalysis_CRREMAnalysis_StrandingGraph-title')} />,
      value: 'stranding-year',
      //PRE-6000
    });
  }

  // Rearrange tabs in desired order: CO2 Path -> Energy Path -> Excess Emission Cost -> Stranding Year
  const rearrangedTabs = tabs
    .reduce((orderedTabs, tab) => {
      switch (tab.value) {
        case 'co2-graph':
          orderedTabs[0] = tab;
          break;
        case 'stranding-year':
          orderedTabs[1] = tab;
          break;
        case 'excess-emission-cost':
          orderedTabs[2] = tab;
          break;
        case 'energy-graph':
          orderedTabs[3] = tab;
          break;
      }
      return orderedTabs;
    }, [] as typeof tabs)
    // Remove empty slots for building view
    .filter(Boolean);

  const handleTabChange = (newValue: PathGraphType) => {
    setPathGraphType(newValue);

    if (newValue === 'excess-emission-cost') {
      switch (currentPageView) {
        case 'esg-portfolio':
          trackEvent('ESG_PORTFOLIO_CRREM_ANALYSIS_TAB_CHANGE', {
            tab_name: 'excess-emission-cost-portfolio',
          });
          break;
        case 'esg-building':
          trackEvent('ESG_BUILDING_CRREM_ANALYSIS_TAB_CHANGE', {
            tab_name: 'excess-emission-cost-building',
          });
          break;
        case 'esg-economics-units':
          trackEvent('ESG_ECONOMIC_UNIT_CRREM_ANALYSIS_TAB_CHANGE', {
            tab_name: 'excess-emission-cost-economic-unit',
          });
          break;
        default:
          const exhaustiveCheck = currentPageView;
          throw new Error(`Unhandled page  ${currentPageView}: ${exhaustiveCheck}`);
      }
    }
  };

  return (
    <Card>
      <Stack direction="row" alignItems="center" px={3} py={1}>
        <Stack mr={3} spacing={0} direction="row" alignItems="center">
          <Typography variant="h6">{t('EsgAnalysis_CrremAnalysis-Header')}</Typography>
          <Typography ml={2} variant="caption" color="grey.600">
            {t('EsgAnalysis_CrremAnalysis-SubTitle')}
          </Typography>
        </Stack>
        <PreTabsAsToggle
          value={pathGraphType}
          tabs={rearrangedTabs}
          onChange={(_, newValue) => handleTabChange(newValue as PathGraphType)}
        />
      </Stack>
      <Divider />
      <CardContent sx={{ p: 3 }}>
        {pathGraphType === 'co2-graph' ? (
          <Co2CrremAnalysis
            co2Path={co2Path}
            co2TargetPath={co2TargetPath}
            buildingLegendName={buildingLegendName}
            strandingYear={strandingYear}
            cumulativeExcessEmissions={cumulativeExcessEmissions}
            graphStartYear={graphStartYear}
            benchmark={{
              selectedBenchmarkName,
              strandingYearChange,
            }}
          />
        ) : pathGraphType === 'energy-graph' ? (
          <EnergyCrremAnalysis
            energyPath={energyPath}
            energyTargetPath={energyTargetPath}
            buildingLegendName={buildingLegendName}
            yearOfExcedance={yearOfExcedance}
            graphStartYear={graphStartYear}
            benchmark={{
              selectedBenchmarkName,
              yearOfExcedanceChange,
            }}
          />
        ) : pathGraphType === 'excess-emission-cost' ? (
          <ExcessEmissionCost
            graphStartYear={graphStartYear}
            excessEmissionCostPath={crremAnalysis?.excessEmissionCostPath}
          />
        ) : pathGraphType === 'stranding-year' ? (
          <PortfolioDistributionByStranding strandingData={strandingData ?? []} />
        ) : null}
      </CardContent>
    </Card>
  );
}

export default memo(CrremAnalysis, isEqual);
