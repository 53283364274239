import { Box, Stack, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import { AnalyzeScenarioOutputFragment } from '@predium/client-graphql';
import { calculateImpact, Units } from '@predium/utils';
import { useTranslation } from 'react-i18next';
import InfoTooltip from '../../../../components/InfoTooltip';
import SimpleComparisonMetric from '../../../../components/SimpleComparisonMetric';
import { useLanguage } from '../../../../provider/LanguageProvider';

type ScenarioImpactKPIsProps = {
  analyzedScenario?: AnalyzeScenarioOutputFragment;
  actionPlanCount: number;
  totalActions: number;
};

export default function ScenarioImpactKPIs({
  analyzedScenario,
  actionPlanCount,
  totalActions,
}: ScenarioImpactKPIsProps) {
  const { t } = useTranslation();
  const { localize } = useLanguage();

  const costs = analyzedScenario?.cost_total;

  const metrics = {
    finalEnergy: {
      before: analyzedScenario?.modification_impact.final_energy?.before ?? 0,
      after: analyzedScenario?.modification_impact.final_energy?.after ?? 0,
    },
    primaryEnergy: {
      before: analyzedScenario?.modification_impact.primary_energy?.before ?? 0,
      after: analyzedScenario?.modification_impact.primary_energy?.after ?? 0,
    },
    co2Intensity: {
      before: analyzedScenario?.modification_impact.co2_emissions?.before ?? 0,
      after: analyzedScenario?.modification_impact.co2_emissions?.after ?? 0,
    },
  };

  return (
    <>
      <Stack direction="row" alignItems="stretch" gap={2} mt={1}>
        <Card sx={{ p: 2, width: '100%', height: '100%', position: 'relative' }}>
          <Stack direction="row" width="100%" alignItems="center">
            <SimpleComparisonMetric
              title={t('General_FinalEnergy')}
              value={totalActions > 0 ? metrics.finalEnergy.after : metrics.finalEnergy.before}
              unit={Units.energyPerAreaYear}
              showTotal={false}
              change={
                totalActions > 0
                  ? calculateImpact(metrics.finalEnergy?.after ?? 0, metrics.finalEnergy?.before ?? 0) / 100
                  : null
              }
              selectedBenchmarkName={totalActions > 0 ? t('General_withoutRetrofit', { count: 2 }) : ''}
              sx={{ width: '100%' }}
            />
            <SimpleComparisonMetric
              title={t('General_PrimaryEnergy')}
              value={totalActions > 0 ? metrics.primaryEnergy?.after : metrics.primaryEnergy?.before}
              unit={Units.energyPerAreaYear}
              showTotal={false}
              change={
                totalActions > 0
                  ? calculateImpact(metrics.primaryEnergy?.after ?? 0, metrics.primaryEnergy?.before ?? 0) / 100
                  : null
              }
              selectedBenchmarkName={totalActions > 0 ? t('General_withoutRetrofit', { count: 2 }) : ''}
              sx={{ width: '100%' }}
            />
            <SimpleComparisonMetric
              title={t('General_CO2Intensity')}
              value={totalActions > 0 ? metrics.co2Intensity?.after : metrics.co2Intensity?.before}
              unit={Units.co2EmissionsPerAreaYear}
              showTotal={false}
              change={
                totalActions > 0
                  ? calculateImpact(metrics.co2Intensity?.after ?? 0, metrics.co2Intensity?.before ?? 0) / 100
                  : null
              }
              selectedBenchmarkName={totalActions > 0 ? t('General_withoutRetrofit', { count: 2 }) : ''}
              sx={{ width: '100%' }}
            />
          </Stack>

          <Box sx={{ position: 'absolute', top: 8, right: 8 }}>
            <InfoTooltip
              text={t('ActionPlanning_Scenario_Metrics-tooltip')}
              placement="top"
              maxwidth={242}
              textColor="text.secondary"
            />
          </Box>
        </Card>

        <Card sx={{ p: 2, minWidth: 250 }}>
          <Stack direction="column" width="100%">
            <Typography variant="subtitle2">{t('General_TotalInvestment')}</Typography>
            <Stack direction="row" alignItems="end" gap={0.5}>
              <Typography variant="h3">
                {localize.formatAsCurrency(costs, {
                  fractionDigits: 0,
                  displayCompact: true,
                  roundToNearestHundred: true,
                })}
              </Typography>
            </Stack>
            {totalActions > 0 && (
              <Typography variant="caption" color="text.secondary" sx={{ mt: 1 }}>
                {t('ActionPlanning_Scenario_Metrics-investmentSubtitle', { count: actionPlanCount })}
              </Typography>
            )}
          </Stack>
        </Card>
      </Stack>
    </>
  );
}
