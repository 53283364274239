import { ApolloQueryResult } from '@apollo/client';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import debounce from '@mui/utils/debounce';
import { SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ICONS } from '../../assets/icons';
import { highlightText } from '../../utils/highlightText';
import Iconify from '../Iconify';
import OverflowText from '../OverflowText';

export type SearchItemType = {
  value: number;
  label: string;
};

type Props = {
  selectedItems: SearchItemType[];
  onItemSelected: (building: SearchItemType) => void;
  refetch: (
    variables?:
      | Partial<{
          offset: number;
          limit: number;
          text: string;
        }>
      | undefined,
  ) => Promise<ApolloQueryResult<any>>;
  loadMore: (offset: number, debouncedSearchTerm: string) => Promise<void>;
  loading: boolean;
  listItems: SearchItemType[];
  hasMore: boolean;
  setHasMore: (hasMore: boolean) => void;
  debouncedSearchTerm: string;
  setDebouncedSearchTerm: (debouncedSearchTerm: string) => void;
  noDataText?: string;
  isMultiSelect?: boolean;
  handleClear?: () => void;
  placeholder?: string;
  limit?: number;
  showClearButton?: boolean;
  onHideSearchBox?: () => void;
  handleClick?: () => void;
  disableApplyButton?: boolean;
};

/**
 * @typedef {Object} SearchItemType
 * @property {number} value - The value of the search item.
 * @property {string} label - The label of the search item.
 */

/**
 * @property {SearchItemType[]} selectedItems - The currently selected items.
 * @property {(building: SearchItemType) => void} onItemSelected - Callback function when an item is selected.
 * @property {(variables?: Partial<{ offset: number; limit: number; text: string; }>) => Promise<ApolloQueryResult<any>>} refetch - Function to refetch data with optional variables.
 * @property {(offset: number, debouncedSearchTerm: string) => Promise<void>} loadMore - Function to load more items based on offset and search term.
 * @property {boolean} loading - Indicates if data is currently being loaded.
 * @property {SearchItemType[]} listItems - The list of items to display.
 * @property {boolean} hasMore - Indicates if there are more items to load.
 * @property {(hasMore: boolean) => void} setHasMore - Function to set the hasMore state.
 * @property {string} debouncedSearchTerm - The debounced search term.
 * @property {(debouncedSearchTerm: string) => void} setDebouncedSearchTerm - Function to set the debounced search term.
 * @property {string} [noDataText] - Text to display when there is no data.
 * @property {boolean} [isMultiSelect] - Indicates if multiple items can be selected.
 * @property {() => void} [handleClear] - Function to clear the selected items.
 * @property {string} [placeholder] - Placeholder text for the search input.
 * @property {number} [limit] - The number of items to load at a time.
 * @property {boolean} [showClearButton] - Indicates if the clear button should be shown.
 * @property {() => void} [onHideSearchBox] - Function to hide the search box.
 * @property {() => void} [handleClick] - Function to handle the click event.
 */
const SearchBoxWithLazyLoading = ({
  selectedItems,
  onItemSelected,
  refetch,
  loadMore,
  loading,
  listItems,
  hasMore,
  setHasMore,
  debouncedSearchTerm,
  setDebouncedSearchTerm,
  noDataText,
  isMultiSelect = false,
  handleClear,
  placeholder,
  limit = 10,
  showClearButton = false,
  onHideSearchBox,
  handleClick,
  disableApplyButton = false,
}: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const [items, setItems] = useState<SearchItemType[]>(listItems);
  const [searchInput, setSearchInput] = useState(debouncedSearchTerm ?? ''); // For immediate input display
  const [offset, setOffset] = useState(0);
  const [focusedIndex, setFocusedIndex] = useState(-1); // Track focused list item

  const inputRef = useRef<HTMLInputElement>(null); // Create a ref for the input element

  useEffect(() => {
    setItems(listItems);
  }, [listItems]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce((value) => {
      setDebouncedSearchTerm(value.toLowerCase()); // Set debounced search term
      setItems([]);
      setOffset(0);
      setHasMore(true);
      refetch({ offset: 0, limit, text: `%${value.trim()}%` });
    }, 300), // Reduced debounce time to 300ms for a more responsive UI
    [refetch],
  );

  const handleSearch = (value: string) => {
    setSearchInput(value); // Update the input state immediately
    debouncedSearch(value); // Trigger debounced search
  };

  const onLoadMore = async () => {
    if (!hasMore) return;

    const newOffset = offset + limit;
    loadMore(newOffset, debouncedSearchTerm);
    setOffset(newOffset); // Update offset for next fetch
  };

  // Highlight search term in displayed text

  const handleBuildingChange = (_: SyntheticEvent<Element, Event>, newValue: SearchItemType) => {
    onItemSelected(newValue);
    const newIndex = items.findIndex((item) => item === newValue);
    setFocusedIndex(newIndex); // Keep the focus on the selected item
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setFocusedIndex((prevIndex) => (prevIndex + 1) % items.length);
        break;
      case 'ArrowUp':
        e.preventDefault();
        setFocusedIndex((prevIndex) => (prevIndex - 1 + items.length) % items.length);
        break;

      case 'Enter':
        if (focusedIndex >= 0 && focusedIndex < items.length) {
          handleBuildingChange(e as unknown as SyntheticEvent<Element, Event>, items[focusedIndex]);
          // Keep focus on the same element
          setFocusedIndex(focusedIndex);
        }
        break;
      case 'Escape':
        onHideSearchBox && onHideSearchBox();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    inputRef.current?.focus();

    if (focusedIndex >= 0 && focusedIndex < items.length) {
      const focusedItem = document.getElementById(`search-item-${focusedIndex}`);
      if (focusedItem) {
        focusedItem.scrollIntoView({ block: 'nearest' });
      }
    }
  }, [items.length, focusedIndex]);

  return (
    <Box
      onKeyDown={handleKeyDown}
      tabIndex={0}
      sx={{
        position: 'absolute',
        mt: 1,
        border: '1px solid',
        borderColor: 'divider',
        background: theme.palette.common.white,
        zIndex: 2,
        borderRadius: '8px',
        p: 1,
        boxShadow: theme.shadows[3],
        width: 480,
      }}
    >
      <TextField
        variant="outlined"
        value={searchInput} // Show search input value immediately
        sx={{
          '& .MuiOutlinedInput-root': {
            color: 'text.secondary',
            '&.Mui-focused fieldset': {
              border: '1px solid',
              borderColor: 'divider',
            },
            pl: 1,
          },
          mb: 1,
        }}
        size="small"
        fullWidth
        InputProps={{
          startAdornment: (
            <Iconify icon={ICONS.MAGNIFY} width={24} height={24} sx={{ color: 'text.secondary', mr: 1 }} />
          ),
          endAdornment: searchInput && (
            <IconButton
              onClick={() => handleSearch('')} // Clear search input
              sx={{ mr: -1 }}
            >
              <Iconify icon={ICONS.CLOSE} width={18} height={18} sx={{ color: 'text.secondary' }} />
            </IconButton>
          ),
        }}
        placeholder={placeholder ?? t('General_Search')}
        onChange={(e) => handleSearch(e.target.value)} // Update search input and trigger debounce
        inputRef={inputRef}
        autoComplete="off"
        focused
      />
      <Box
        id="scrollableDiv"
        sx={{
          maxHeight: '336px',
          overflowY: 'auto',
          scrollbarWidth: 'thin',
        }}
      >
        <InfiniteScroll
          dataLength={items.length}
          next={onLoadMore}
          hasMore={hasMore}
          loader={
            loading && (
              <Stack alignContent={'center'} alignItems={'center'} width={'100%'} height={32}>
                <CircularProgress size={24} />
              </Stack>
            )
          }
          scrollableTarget="scrollableDiv"
        >
          {items.length !== 0 ? (
            <List>
              {items.map((item, index) => (
                <ListItem
                  key={item.value}
                  id={`search-item-${index}`}
                  sx={{
                    margin: theme.spacing(0.5),
                    borderRadius: '6px',
                    cursor: 'pointer',
                    width: `calc(100% - 8px)`,
                    '&:hover': {
                      background: theme.palette.action.hover,
                    },
                    background: selectedItems.some((selectedItem) => selectedItem.value === item.value)
                      ? theme.palette.action.selected
                      : '',
                    backgroundColor: focusedIndex === index ? theme.palette.action.hover : '',
                  }}
                  onClick={(e) => {
                    handleBuildingChange(e, item);
                  }}
                >
                  {isMultiSelect && (
                    <Checkbox
                      checked={selectedItems.some((selectedItem) => selectedItem.value === item.value)}
                      size={'small'}
                    />
                  )}

                  <ListItemText
                    primary={<OverflowText maxWidth={'inherit'} text={highlightText(item.label, searchInput)} />}
                  />
                </ListItem>
              ))}
            </List>
          ) : (
            <Stack width={'100%'} justifyContent={'center'} alignItems={'center'}>
              <Typography
                sx={{
                  color: 'text.secondary',
                  padding: 3,
                }}
              >
                {noDataText}
              </Typography>
            </Stack>
          )}
        </InfiniteScroll>
      </Box>
      {isMultiSelect && (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          p={1}
          sx={{
            background: theme.palette.common.white,
            height: 48,
            pr: 3,
          }}
        >
          <Button variant="text" size="small" onClick={handleClear} disabled={!showClearButton}>
            {t('General_Clear')}
          </Button>
          {handleClick && (
            <Button variant="contained" size="medium" onClick={handleClick} disabled={disableApplyButton}>
              {t('General_Apply')}
            </Button>
          )}
        </Stack>
      )}
    </Box>
  );
};

export default SearchBoxWithLazyLoading;
