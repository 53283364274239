import { Theme } from '@mui/material/styles';
import {
  ActionFragment,
  ActionImpactDataOutputFragment,
  ActionPlanImpactDataOutputFragment,
  ActionPlanningActionSubsidyFragment,
  BuildingModelFragment,
  DataCollectionSubBuildingSubBuildingSubsidyFragment,
  EnergyConsumerForActionFragment,
  EnvelopeUnitFragment,
  RecommendedActionImpactOutput,
  SimulateActionOutputResponseFragment,
  SubsidyInput,
} from '@predium/client-graphql';
import {
  DoorTemplate,
  EnvelopeRenovationTemplates,
  InsulationMaterial,
  SolarModuleTemplate,
  TARGET_PATH_FIRST_YEAR,
  TechnologyTemplate,
  WindowTemplate,
} from '@predium/client-lookup';
import {
  action_subsidy_value_type_enum,
  custom_action_type_enum,
  efficiency_class_enum,
  energy_consumer_technology_type_enum,
  energy_source_type_enum,
  energy_system_type_enum,
  envelope_type_enum,
  insulation_method_enum,
  renovation_type_enum,
} from '@predium/enums';
import {
  translateEnergyConsumerTechnologyTypeEnum_dynamic,
  translateEnergyConsumerTypeEnum_dynamic,
  translateEnergySourceTypeEnum_dynamic,
  translateEnergySystemTypeEnum_dynamic,
  translateEnvelopeTypeEnum_dynamic,
  translateEnvelopeTypeEnum_insulation_dynamic,
  translateRenovationTypeEnum_dynamic,
} from '@predium/i18n/client';
import { calculateImpact, calculateSavings, ensureDefined } from '@predium/utils';
import dayjs from 'dayjs';
import { TFunction, t } from 'i18next';
import sortBy from 'lodash/sortBy';
import { ICONS } from '../../../assets/icons';
import { getUtcValue } from '../../../components/hook-form/RHFDatePicker';
import { getActionIcon } from '../../../utils/icons';
import { ActionWithMetricData } from './ActionPlanSections/ActionList/ActionListTable';
import {
  ConsumerPartialRenovationOption,
  EnvelopePartialRenovationOption,
  category_enum,
} from './Actions/CreateAction';

export const getActionName = (action: ActionFragment) => {
  if (!!action.custom_name) {
    return action.custom_name;
  }
  const renovationType = action?.renovations[0]?.renovation_type_id;
  switch (renovationType) {
    case renovation_type_enum.ENVELOPE:
      const envelopeTypeId = action.renovations_envelope[0]?.new_envelope?.envelope_type_id;
      if (envelopeTypeId) {
        return translateEnvelopeTypeEnum_insulation_dynamic(envelopeTypeId, t);
      }
      return t('General_Envelope');
    case renovation_type_enum.ENERGY_CONSUMER:
      return action.renovations_energy_consumer[0]?.new_energy_consumer?.name
        ? action.renovations_energy_consumer[0]?.new_energy_consumer?.name
        : translateEnergyConsumerTechnologyTypeEnum_dynamic(
            energy_consumer_technology_type_enum[
              //@ts-ignore
              action.renovations_energy_consumer[0]?.new_energy_consumer?.energy_consumer_technology_type_id
            ],
            t,
            2,
          );
    case renovation_type_enum.ENERGY_SYSTEM_ROUTE:
      switch (
        action.renovations_energy_routes.filter(
          (r) => r.new_energy_system_route?.energy_source_type_id !== energy_source_type_enum.SOLAR,
        )[0]?.new_energy_system_route?.energy_source_type_id
      ) {
        case energy_source_type_enum.ELECTRICITY_GREEN:
          return t('ActionPlanning_NewEnergySystemRoute_ElectricityGreen');

        case energy_source_type_enum.BIO_GAS:
          return t('ActionPlanning_NewEnergySystemRoute_BioGas');

        case energy_source_type_enum.DISTRICT_HEATING_PLANTS_RENEWABLE:
          return t('ActionPlanning_NewEnergySystemRoute_DistrictHeatingPlantsRenewable');

        case energy_source_type_enum.DISTRICT_HEATING_CHP_RENEWABLE:
          return t('ActionPlanning_NewEnergySystemRoute_DistrictHeatingCHPRenewable');

        default:
          return '';
      }

    case renovation_type_enum.SOLAR_PLANT:
    case renovation_type_enum.HYDRAULIC_BALANCING:
    case renovation_type_enum.CUSTOM:
    case renovation_type_enum.MAINTENANCE:
      return translateRenovationTypeEnum_dynamic(renovationType, t);
    default:
      return '';
  }
};

/**
 * Returns a Date object representing the maximum date, which is December 31, 2050.
 *
 * @returns {Date} A Date object representing the maximum date of December 31, 2050.
 */
export const getMaxDate = (): Date => {
  return getUtcValue(dayjs('2050-12-31').toDate());
};

/**
 * Returns a Date object representing the minimum date, which is January 1, 2020.
 *
 * @returns {Date} A Date object representing the maximum date of January 1, 2020..
 */
export const getMinDate = (): Date => {
  return getUtcValue(dayjs('2020-01-01').toDate());
};

export const filterSeriesByStartYear = (series: [number, number][], startYear: number): [number, number][] => {
  return series.filter(([year]) => year >= startYear);
};

/*
  determine the start year for the graph.
  it should starts from building construction date.  but if construction date is less than 2020 (e.g 1962) the it should start from 2020 (from which we have data available)
  */

export const determineStartYear = (constructionYear: number): number => {
  if (constructionYear < new Date().getFullYear() && constructionYear > TARGET_PATH_FIRST_YEAR) {
    return constructionYear;
  } else if (constructionYear < TARGET_PATH_FIRST_YEAR) {
    return TARGET_PATH_FIRST_YEAR;
  } else {
    return constructionYear;
  }
};

export const getActionType = (action: ActionFragment) => {
  const renovationType = action?.renovations[0]?.renovation_type_id;
  let actionType: undefined | string = '';
  switch (renovationType) {
    case renovation_type_enum.ENVELOPE:
      actionType = action.renovations_envelope[0]?.new_envelope?.envelope_type_id;
      break;
    case renovation_type_enum.ENERGY_CONSUMER:
      actionType = action.renovations_energy_consumer[0]?.new_energy_consumer?.energy_consumer_type_id;
      break;
    case renovation_type_enum.ENERGY_SYSTEM_ROUTE:
      actionType = action.renovations_energy_routes.filter(
        (r) => r.new_energy_system_route?.energy_source_type_id !== energy_source_type_enum.SOLAR,
      )[0]?.new_energy_system_route?.energy_source_type_id;
      break;
    case renovation_type_enum.SOLAR_PLANT:
    case renovation_type_enum.HYDRAULIC_BALANCING:
    case renovation_type_enum.CUSTOM:
      actionType = renovationType;
      break;
    case renovation_type_enum.MAINTENANCE:
      actionType = renovationType;
      break;
    default:
      const exhaustiveCheck = renovationType;
      throw new Error(`Unhandled renovationType ${renovationType}: ${exhaustiveCheck}`);
  }

  return actionType;
};

export const getIconURLByAction = (action: ActionFragment) => {
  const actionType = getActionType(action);

  if (!actionType) {
    return '';
  }

  return getActionIcon(actionType);
};

export const getIconBase64ByActionType = async (actionType: string) => {
  const response = await fetch(getActionIcon(actionType));
  const text = await response.text();

  return 'data:image/svg+xml;base64,' + btoa(text);
};

export const getEfficiencyClassColor = (
  beforeEfficiencyClass: efficiency_class_enum,
  afterEfficiencyClass: efficiency_class_enum,
  theme: Theme,
) => {
  const colors = theme.palette.energyEfficiencyClassColors;
  const beforeColor = beforeEfficiencyClass
    ? colors[beforeEfficiencyClass].main
    : colors[efficiency_class_enum.NOT_APPLICABLE].main;
  const afterColor = afterEfficiencyClass
    ? colors[afterEfficiencyClass].main
    : colors[efficiency_class_enum.NOT_APPLICABLE].main;

  return [beforeColor, afterColor];
};

export function getInsulationMethodOptions(
  firstLevelKey: keyof typeof EnvelopeRenovationTemplates,
): insulation_method_enum[] {
  const firstLevelObject = EnvelopeRenovationTemplates[firstLevelKey];
  if (firstLevelObject) {
    const secondLevelKeys = Object.keys(firstLevelObject);
    return secondLevelKeys as insulation_method_enum[];
  }
  return [];
}

/**
 * Calculates the cost per m2 of insulation material based on it's thickness.
 */
export function getInsulationCostsPerM2(costPerCmM2: number, insulationThicknessInCm: number): number {
  return Number((costPerCmM2 * insulationThicknessInCm).toFixed(2));
}

export const getIsCostAvailable = (
  material: string,
  allParameters: WindowTemplate[] | DoorTemplate[] | InsulationMaterial[],
) => {
  const parameters = allParameters.find(
    (p: WindowTemplate | DoorTemplate | InsulationMaterial) => p.material_name === material,
  );

  if (parameters) {
    return (
      ('cost_per_cm_m2' in parameters && !!parameters.cost_per_cm_m2) ||
      ('cost_per_m2' in parameters && !!parameters.cost_per_m2)
    );
  }
  return false;
};

export const getIsCostAvailableForTechnology = (
  material: string,
  allParameters: SolarModuleTemplate[] | TechnologyTemplate[],
) => {
  const parameters = allParameters.find(
    (p: SolarModuleTemplate | TechnologyTemplate) => p.technology_name === material,
  );

  if (parameters) {
    return 'cost_per_m2' in parameters && !!parameters.cost_per_m2;
  }
  return false;
};

/**
 * Envelope label for the partial renovation dropdown menu
 * @param action The selected action
 */
export const getEnvelopeLabel = (action: EnvelopePartialRenovationOption, t: TFunction<'translation', undefined>) => {
  const envelope = translateEnvelopeTypeEnum_dynamic(action.envelope_type_id, t);
  return `${envelope} ${(action.index + 1).toString()}`;
};

/**
 * System label for the partial renovation dropdown menu
 * @param action The selected action
 */
export const getSystemLabel = (
  action: ConsumerPartialRenovationOption,
  withIndex: boolean = true,
  t: TFunction<'translation', undefined>,
) => {
  let systemName;
  if (action.energy_consumer_technology_type_id) {
    systemName = translateEnergyConsumerTechnologyTypeEnum_dynamic(action.energy_consumer_technology_type_id, t, 1);
  } else {
    const actionSystem = ensureDefined(action.energy_system_consumer_routes[0].energy_system);
    systemName = translateEnergySystemTypeEnum_dynamic(actionSystem.energy_system_type_id, t);
  }
  return withIndex ? `${systemName} ${(action.index + 1).toString()}` : systemName;
};

export const getEnvelopePartialRenovationOptions = (
  envelopes: EnvelopeUnitFragment[],
  action: envelope_type_enum,
): EnvelopePartialRenovationOption[] => {
  return sortBy(
    envelopes.filter((envelope) => envelope.envelope_type_id === action),
    'orientation',
    'secondary_id',
  ).flatMap((envelope, i) => {
    return {
      index: i,
      ...envelope,
    };
  });
};

export const getConsumerPartialRenovationOptions = (
  energyConsumers: EnergyConsumerForActionFragment[],
  action: energy_system_type_enum,
): ConsumerPartialRenovationOption[] => {
  // TODO use consumer secondary ids instead of route secondary ids
  return sortBy(energyConsumers, (c) => c.energy_system_consumer_routes[0].secondary_id)
    .flatMap((consumer, i) => {
      return {
        index: i,
        ...consumer,
      };
    })
    .filter((consumer) =>
      consumer.energy_system_consumer_routes.some((route) => route.energy_system?.energy_system_type_id === action),
    );
};

/**
 * Get the affected parts for an action in the current building model
 * @param action The action to get the affected parts for
 * @param allActions All actions in the action plan
 * @param buildingModel The current building model
 * @returns the `PartialRenovationOption` for each affected part of the action, including the index in order to keep
 *   track of the order of parts between building models
 */
export const affectedEnvelopePartsByAction = (
  action: ActionFragment,
  allActions: ActionFragment[],
  buildingModel: BuildingModelFragment,
) => {
  const indexOfAction = allActions.findIndex((a) => a.id === action.id);
  const lastBuildingModel = indexOfAction > 0 ? allActions[indexOfAction - 1].building_models[0] : buildingModel;

  const allPartsWithIndex: EnvelopePartialRenovationOption[] = sortBy(
    lastBuildingModel?.envelope_units.filter(
      (part) => part.envelope_type_id === action.renovations_envelope[0]?.new_envelope?.envelope_type_id,
    ),
    (p) => p.orientation,
    (p) => p.secondary_id,
  ).map((part, index) => ({
    ...part,
    index,
  }));

  const affectedParts = allPartsWithIndex.filter((part) =>
    action.renovations_envelope.some((renovation) => renovation.old_entity_id === part.id),
  );

  return affectedParts;
};

export const affectedConsumerPartsByAction = (
  action: ActionFragment,
  allActions: ActionFragment[],
  buildingModel: BuildingModelFragment,
) => {
  const indexOfAction = allActions.findIndex((a) => a.id === action.id);
  const lastBuildingModel = indexOfAction > 0 ? allActions[indexOfAction - 1].building_models[0] : buildingModel;

  let affectedConsumers = [];
  if (action.renovations_energy_consumer.length) {
    affectedConsumers.push(
      ...action.renovations_energy_consumer
        .map((c) => c.old_energy_consumer)
        .filter((c) => c !== null && c !== undefined),
    );
  } else if (action.renovations_energy_routes.length) {
    const oldEnergySystemRouteIds = action.renovations_energy_routes.map((r) => r.old_energy_system_route?.id);
    const consumers = lastBuildingModel.energy_systems
      .flatMap((s) => s.energy_system_consumer_routes)
      .filter((r) => oldEnergySystemRouteIds.includes(r.id))
      .map((r) => r.energy_consumer);
    affectedConsumers.push(...consumers);
  }

  affectedConsumers = sortBy(affectedConsumers, (c) => c.energy_system_consumer_routes[0].secondary_id).map(
    (consumer, index) => ({
      ...consumer,
      index,
    }),
  );

  return affectedConsumers;
};

//This is temporary and will be returned from the backend after unifying simulation response similar modification impact
//Determine before and after data for the action creation
export const getModificationImpactAfterSimulation = (
  beforeSimulationData: ActionImpactDataOutputFragment | ActionPlanImpactDataOutputFragment | null,
  afterSimulationData: SimulateActionOutputResponseFragment | undefined,
): Partial<ActionImpactDataOutputFragment> | null => {
  if (!beforeSimulationData && !afterSimulationData) {
    return null;
  }
  const modificationImpact = beforeSimulationData?.modification_impact;
  if (!modificationImpact) {
    return null;
  }
  const { final_energy, co2_emissions, energy_cost_total, co2_cost_total, primary_energy, heating_demand } =
    modificationImpact;
  const combinedData = {
    modification_impact: {
      co2_emissions: {
        before: modificationImpact.co2_emissions.before,
        after: afterSimulationData?.co2_emissions,
        impact: co2_emissions?.before
          ? calculateImpact(afterSimulationData?.co2_emissions, co2_emissions?.before)
          : undefined,
      },
      final_energy: {
        before: modificationImpact.final_energy.before,
        after: afterSimulationData?.energy_final,
        impact: final_energy.before
          ? calculateImpact(afterSimulationData?.energy_final, final_energy.before)
          : undefined,
      },
      heating_demand: {
        before: modificationImpact.heating_demand?.before,
        after: afterSimulationData?.heating_demand,
        impact:
          afterSimulationData?.heating_demand && heating_demand?.before
            ? calculateImpact(afterSimulationData.heating_demand, heating_demand.before)
            : undefined,
      },
      primary_energy: {
        before: modificationImpact.primary_energy.before,
        after: afterSimulationData?.energy_primary,
        impact: primary_energy.before
          ? calculateImpact(afterSimulationData?.energy_primary, primary_energy.before)
          : undefined,
      },
      efficiency_class: {
        before: modificationImpact.efficiency_class.before,
        after: afterSimulationData?.efficiency_class,
      },
      stranding_date: {
        before: modificationImpact.stranding_date.before ?? null,
        after: afterSimulationData?.stranding_date,
      },
      energy_cost_total: {
        before: modificationImpact.energy_cost_total.before,
        after: afterSimulationData?.energy_final_cost,
        impact: energy_cost_total.before
          ? calculateSavings(afterSimulationData?.energy_final_cost, energy_cost_total.before)
          : undefined,
      },
      co2_cost_total: {
        before: modificationImpact.co2_cost_total.before,
        after: afterSimulationData?.co2_tax_cost,
        impact: co2_cost_total.before
          ? calculateSavings(afterSimulationData?.co2_tax_cost, co2_cost_total.before)
          : undefined,
      },
    },
    total_renovation_cost: afterSimulationData?.renovation_costs ?? undefined,
    total_estimated_cost: afterSimulationData?.renovation_costs, //there is no estimated cost in the simulation
    // response, and the way way cost component is is
    // implemented it needs to pass the same value for
    // both
    total_subsidy_cost: undefined,
  };

  return combinedData as Partial<ActionImpactDataOutputFragment>;
};

export const getModificationImpactForMaintenanceAction = (
  beforeSimulationData: ActionImpactDataOutputFragment | ActionPlanImpactDataOutputFragment | undefined,
  noActions: boolean,
): Partial<ActionImpactDataOutputFragment> | null => {
  const modificationImpact = beforeSimulationData?.modification_impact;

  if (!modificationImpact) {
    return null;
  }

  const {
    final_energy,
    co2_emissions,
    energy_cost_total,
    co2_cost_total,
    primary_energy,
    heating_demand,
    efficiency_class,
    stranding_date,
  } = modificationImpact;

  //when no actions values will taken from action plan modification impact
  const combinedData = {
    modification_impact: {
      co2_emissions: {
        before: noActions ? co2_emissions.before : co2_emissions.after,
        after: noActions ? co2_emissions.before : co2_emissions.after,
        impact: 0,
      },
      final_energy: {
        before: noActions ? final_energy.before : final_energy.after,
        after: noActions ? final_energy.before : final_energy.after,
        impact: 0,
      },
      heating_demand: {
        before: noActions ? heating_demand?.before : heating_demand?.after,
        after: noActions ? heating_demand?.before : heating_demand?.after,
        impact: 0,
      },
      primary_energy: {
        before: noActions ? primary_energy.before : primary_energy.after,
        after: noActions ? primary_energy.before : primary_energy.after,
        impact: 0,
      },
      efficiency_class: {
        before: noActions ? efficiency_class?.before ?? null : efficiency_class?.after ?? null,
        after: noActions ? efficiency_class?.before ?? null : efficiency_class?.after ?? null,
      },
      stranding_date: {
        before: noActions ? (stranding_date.before as Date) : (stranding_date.after as Date),
        after: noActions ? (stranding_date.before as Date) : (stranding_date.after as Date),
      },
      energy_cost_total: {
        before: noActions ? energy_cost_total.before : energy_cost_total.after,
        after: noActions ? energy_cost_total.before : energy_cost_total.after,
        impact: 0,
      },
      co2_cost_total: {
        before: noActions ? co2_cost_total.before : co2_cost_total.after,
        after: noActions ? co2_cost_total.before : co2_cost_total.after,
        impact: 0,
      },
    },
    total_renovation_cost: undefined,
    total_estimated_cost: undefined,
    total_subsidy_cost: undefined,
  };

  return combinedData;
};

const getModificationImpact = (
  modificationImpact: ActionImpactDataOutputFragment['modification_impact'] | RecommendedActionImpactOutput,
  atLeastOneAction: boolean,
) => {
  return {
    co2_emissions: {
      before: atLeastOneAction ? modificationImpact.co2_emissions.after : modificationImpact.co2_emissions.before,
      after: undefined,
      impact: undefined,
    },
    final_energy: {
      before: atLeastOneAction ? modificationImpact.final_energy.after : modificationImpact.final_energy.before,
      after: undefined,
      impact: undefined,
    },
    primary_energy: {
      before: atLeastOneAction ? modificationImpact.primary_energy.after : modificationImpact.primary_energy.before,
      after: undefined,
      impact: undefined,
    },
    efficiency_class: {
      before: atLeastOneAction ? modificationImpact.efficiency_class.after : modificationImpact.efficiency_class.before,
      after: undefined,
    },
    heating_demand: {
      before: modificationImpact.heating_demand?.before,
      after: undefined,
      impact: undefined,
    },
    stranding_date: {
      before: atLeastOneAction ? modificationImpact.stranding_date.after : modificationImpact.stranding_date.before,
      after: undefined,
    },
    energy_cost_total: {
      before: atLeastOneAction
        ? modificationImpact.energy_cost_total.after
        : modificationImpact.energy_cost_total.before,
      after: undefined,
      impact: undefined,
    },
    co2_cost_total: {
      before: atLeastOneAction ? modificationImpact.co2_cost_total.after : modificationImpact.co2_cost_total.before,
      after: undefined,
      impact: undefined,
    },
  };
};

export const getModificationImpactBeforeSimulation = (
  data: ActionImpactDataOutputFragment | ActionPlanImpactDataOutputFragment | undefined,
  atLeastOneAction: boolean,
): Partial<ActionImpactDataOutputFragment> | null => {
  if (!data) {
    return null;
  }
  const modificationImpact = data.modification_impact;

  const combinedData = {
    modification_impact: getModificationImpact(modificationImpact, atLeastOneAction),
    total_renovation_cost: undefined,
  };

  return combinedData as Partial<ActionImpactDataOutputFragment>;
};

export const getRecommendedActionModificationImpactBeforeSimulation = (
  data: RecommendedActionImpactOutput | null,
  atLeastOneAction: boolean,
) => {
  if (!data) {
    return null;
  }
  const modificationImpact = data;

  const combinedData = {
    modification_impact: getModificationImpact(modificationImpact, atLeastOneAction),
    total_renovation_cost: undefined,
  };

  return combinedData;
};

//TODO : Refactor PRE-6013
export const getEditActionStatusQue = (statusQue: SimulateActionOutputResponseFragment) => {
  return {
    modification_impact: {
      co2_emissions: {
        before: statusQue.co2_emissions,
        after: undefined,
        impact: undefined,
      },
      final_energy: {
        before: statusQue.energy_final,
        after: undefined,
        impact: undefined,
      },
      primary_energy: {
        before: statusQue.energy_primary,
        after: undefined,
        impact: undefined,
      },
      efficiency_class: {
        before: statusQue.efficiency_class,
        after: undefined,
      },
      heating_demand: {
        before: statusQue.heating_demand,
        after: undefined,
        impact: undefined,
      },
      stranding_date: {
        before: statusQue.stranding_date,
        after: undefined,
      },
      energy_cost_total: {
        before: statusQue.energy_final_cost,
        after: undefined,
        impact: undefined,
      },
      co2_cost_total: {
        before: statusQue.co2_tax_cost,
        after: undefined,
        impact: undefined,
      },
    },
    total_renovation_cost: undefined,
    total_estimated_cost: undefined,
  };
};

//TODO : Refactor PRE-6013
export const getEditActionModificationImpact = (
  statusQue: SimulateActionOutputResponseFragment,
  simulatedData: SimulateActionOutputResponseFragment,
) => {
  return {
    modification_impact: {
      co2_emissions: {
        before: statusQue.co2_emissions,
        after: simulatedData.co2_emissions,
        impact:
          statusQue.co2_emissions && simulatedData.co2_emissions
            ? calculateImpact(simulatedData.co2_emissions, statusQue.co2_emissions)
            : undefined,
      },
      final_energy: {
        before: statusQue.energy_final,
        after: simulatedData.energy_final,
        impact:
          statusQue.energy_final && simulatedData.energy_final
            ? calculateImpact(simulatedData.energy_final, statusQue.energy_final)
            : undefined,
      },
      primary_energy: {
        before: statusQue.energy_primary,
        after: simulatedData.energy_primary,
        impact:
          statusQue.energy_primary && simulatedData.energy_primary
            ? calculateImpact(simulatedData.energy_primary, statusQue.energy_primary)
            : undefined,
      },
      efficiency_class: {
        before: statusQue.efficiency_class,
        after: simulatedData.efficiency_class,
      },
      heating_demand: {
        before: statusQue.heating_demand,
        after: simulatedData.heating_demand,
        impact:
          statusQue.heating_demand && simulatedData.heating_demand
            ? calculateImpact(simulatedData.heating_demand, statusQue.heating_demand)
            : undefined,
      },
      stranding_date: {
        before: statusQue.stranding_date,
        after: simulatedData.stranding_date,
      },
      energy_cost_total: {
        before: statusQue.energy_final_cost,
        after: simulatedData.energy_final_cost,
        impact:
          statusQue.energy_final_cost && simulatedData.energy_final_cost
            ? calculateSavings(simulatedData.energy_final_cost, statusQue.energy_final_cost)
            : undefined,
      },
      co2_cost_total: {
        before: statusQue.co2_tax_cost,
        after: simulatedData.co2_tax_cost,
        impact:
          statusQue.co2_tax_cost && simulatedData.co2_tax_cost
            ? calculateSavings(simulatedData.co2_tax_cost, statusQue.co2_tax_cost)
            : undefined,
      },
    },
    total_renovation_cost: simulatedData.renovation_costs ?? undefined,
    total_estimated_cost: simulatedData.renovation_costs ?? undefined,
  };
};

export const getRecommendedSystemActionName = (
  materialName: string | null,
  technologyType: energy_consumer_technology_type_enum | null,
) => {
  return materialName
    ? materialName
    : technologyType
    ? translateEnergyConsumerTechnologyTypeEnum_dynamic(technologyType, t, 2)
    : '';
};

export const getRecommendedEnvelopeActionName = (actionType: envelope_type_enum | null) => {
  if (actionType) {
    return translateEnvelopeTypeEnum_insulation_dynamic(actionType as envelope_type_enum, t);
  }
  return t('General_Envelope');
};

export const getRecommendedRouteActionName = (energySourceType: energy_source_type_enum | null) => {
  switch (energySourceType) {
    case energy_source_type_enum.ELECTRICITY_GREEN:
      return t('ActionPlanning_NewEnergySystemRoute_ElectricityGreen');

    case energy_source_type_enum.BIO_GAS:
      return t('ActionPlanning_NewEnergySystemRoute_BioGas');

    case energy_source_type_enum.DISTRICT_HEATING_PLANTS_RENEWABLE:
      return t('ActionPlanning_NewEnergySystemRoute_DistrictHeatingPlantsRenewable');

    case energy_source_type_enum.DISTRICT_HEATING_CHP_RENEWABLE:
      return t('ActionPlanning_NewEnergySystemRoute_DistrictHeatingCHPRenewable');

    default:
      return '';
  }
};

export const getActionCategoryIcon = (value: category_enum) => {
  switch (value) {
    case category_enum.ENVELOPE:
      return ICONS.HOME;
    case category_enum.TECHNICAL:
      return ICONS.COG;
    case category_enum.CUSTOM:
      return ICONS.PENCIL;
    default:
      const exhaustiveCheck: never = value;
      throw new Error(`Unhandled category ${value}: ${exhaustiveCheck}`);
  }
};

export const getCustomActionType = (action: ActionFragment) => {
  switch (action.custom_action_type_id) {
    case custom_action_type_enum.ENERGY_SOURCE:
      const energySourceType =
        action.renovations_energy_consumer[0]?.new_energy_consumer?.energy_system_consumer_routes?.[0]
          ?.energy_source_type_id;

      if (!energySourceType) {
        return null;
      }

      return translateEnergySourceTypeEnum_dynamic(energySourceType, t);

    case custom_action_type_enum.SYSTEM:
      const energyConsumerType = action.renovations_energy_consumer[0]?.new_energy_consumer?.energy_consumer_type_id;

      if (!energyConsumerType) {
        return null;
      }

      return translateEnergyConsumerTypeEnum_dynamic(energyConsumerType, t);

    default:
      return null;
  }
};

export function processSubsidies(
  subBuildingSubsidies: DataCollectionSubBuildingSubBuildingSubsidyFragment[],
  subsidyData: SubsidyInput[],
  selectedAction: ActionWithMetricData | undefined,
  renovationType: renovation_type_enum,
  simulatedData: SimulateActionOutputResponseFragment | undefined,
  customCost: { cost_total_custom: number | undefined; use_custom_cost: boolean },
): Omit<ActionPlanningActionSubsidyFragment, 'id'>[] {
  const result = subBuildingSubsidies
    .filter((availableSubsidy) =>
      subsidyData.find((selectedSubsidyInput) => selectedSubsidyInput.id === availableSubsidy.id),
    )
    .map((selectedSubsidy) => {
      // We know this subsidy is selected, but we need to get the input data again
      const subsidyInput = subsidyData.find((selectedSubsidyInput) => selectedSubsidyInput.id === selectedSubsidy.id);
      if (!subsidyInput) return null;

      const { cost_total_custom, use_custom_cost } = customCost;

      return {
        subsidy: {
          id: selectedSubsidy.id,
          title: selectedSubsidy.title,
          categories: selectedSubsidy.categories,
        },
        value: subsidyInput.value,
        type: subsidyInput.type,
        action: {
          // Since the action is not yet created
          id: selectedAction?.id ?? undefined,
          renovations: [
            {
              cost_total: use_custom_cost ? cost_total_custom : simulatedData?.renovation_costs ?? 0,
              renovation_type_id: renovationType,
            },
          ],
        },
      };
    })
    .filter(Boolean); // Filter out null values

  return result as Omit<ActionPlanningActionSubsidyFragment, 'id'>[];
}

export const isCustomCostLowerThanSubsidies = (
  cost: number,
  subsidies: Omit<ActionPlanningActionSubsidyFragment, 'id'>[],
) => {
  const totalSubsidy = subsidies.reduce((acc, curr) => {
    const totalActionCost = curr.action.renovations.reduce((acc, curr) => acc + (curr.cost_total ?? 0), 0);
    if (curr.type === action_subsidy_value_type_enum.percentage) {
      return acc + (totalActionCost ?? 0) * (curr.value / 100);
    }
    return acc + curr.value;
  }, 0);

  return cost < totalSubsidy;
};
