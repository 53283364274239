export const highlightText = (text: string, searchTerm: string) => {
  if (!searchTerm) return text;
  const escapedSearchTerm = searchTerm.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
  const regex = new RegExp(`(${escapedSearchTerm})`, 'gi');
  const parts = text.split(regex);

  return parts.map((part, index) =>
    regex.test(part) ? (
      <span
        key={index}
        style={{
          backgroundColor: '#F0DBAE',
          padding: '2px 0',
          whiteSpace: 'pre-wrap',
        }}
      >
        {part}
      </span>
    ) : (
      part.replace(/ /g, '\u00A0')
    ),
  );
};
