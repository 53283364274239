/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useMutation, useSubscription } from '@apollo/client';
import { Button, Card, Checkbox, Table, TableBody, TableCell, TableContainer } from '@mui/material';
import { COMMON_DATE_FORMATS, formatDateToLocale } from '@predium/utils';
import uniq from 'lodash/uniq';
import without from 'lodash/without';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ICONS } from '../../../assets/icons';
import DeleteConfirmationModal from '../../../components/DeleteConfirmationModal';
import DraftStatusLabel from '../../../components/DraftStatusLabel';
import Iconify from '../../../components/Iconify';
import InlineUser from '../../../components/InlineUser';
import { DelayedLoading } from '../../../components/Loading';
import { SnackbarTimeouts } from '../../../components/NotistackProvider';
import OverflowText from '../../../components/OverflowText';
import Scrollbar from '../../../components/Scrollbar';
import SearchNotFound from '../../../components/SearchNotFound';
import { TableSelectedActions } from '../../../components/table';
import TablePaginationStandard from '../../../components/table/TablePaginationStandard';
import TableRowWithHighlight, { hasRecentlyChanged } from '../../../components/table/TableRowWithHighlight';
import { DataCollectionBuildingTabState } from '../../../contexts/DataCollectionFiltersContext';
import { DELETE_ENERGY_CERTIFICATE_DRAFTS } from '../../../graphql/DataCollection.mutations';
import {
  GET_ALL_BUILDING_DRAFTS_SUBSCRIPTION,
  GET_ALL_ENERGY_CERTIFICATE_DRAFTS_SUBSCRIPTION,
} from '../../../graphql/DataCollection.queries';
import useDataCollectionFilters from '../../../hooks/useDataCollectionFilters';
import { useMultiSelectFilter } from '../../../hooks/useMultiSelectFilter';
import useTable, { applySortFilter, OnFilterCallbackParams } from '../../../hooks/useTable';
import { useLanguage } from '../../../provider/LanguageProvider';
import { PATHS } from '../../../routes';
import { deleteCache } from '../../../utils/cacheHelpers';
import { ALL_PORTFOLIOS } from '../../EsgAnalysis/NavigationSelectors/PortfolioSelector';
import DataCollectionHead, { HeadLabel } from './DataCollectionHead';
import DataCollectionToolbar from './DataCollectionToolbar';
import { DataCollectionTabEnum } from './TabsEnums';

type Props = {
  useSubscription: typeof useSubscription;
  shouldOpenCreateModal: boolean;
  setCurrentTab: (subBuildingTabData: DataCollectionBuildingTabState) => void;
  onFilterChange: ({ orderBy, order }: OnFilterCallbackParams) => void;
  tabTableFilter?: {
    orderBy: string;
    order: 'asc' | 'desc';
  };
};

export default function DataCollectionEnergyCertificateDraftsList({
  useSubscription,
  shouldOpenCreateModal,
  setCurrentTab,
  onFilterChange,
  tabTableFilter,
}: Props) {
  const subscription = useSubscription(GET_ALL_ENERGY_CERTIFICATE_DRAFTS_SUBSCRIPTION);
  const buildingDraftsSubscription = useSubscription(GET_ALL_BUILDING_DRAFTS_SUBSCRIPTION);

  const { t } = useTranslation();
  const { language } = useLanguage();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const energyCertificateDrafts = subscription.data?.energy_certificate_draft ?? [];
  const buildingDrafts = buildingDraftsSubscription.data?.building_draft ?? [];

  const drafts = [...energyCertificateDrafts, ...buildingDrafts];

  const showErrorSnackbar = () => {
    enqueueSnackbar(t('DataCollection_EnergyCertificateDraft_DeleteDraft-error'), {
      variant: 'error',
      autoHideDuration: SnackbarTimeouts.Error,
    });
  };

  const [deleteEnergyCertificateDraftsMutation] = useMutation(DELETE_ENERGY_CERTIFICATE_DRAFTS, {
    update: (cache, { data: deletedEnergyCertificateDraftData }) => {
      const deletedCertificateDrafts = deletedEnergyCertificateDraftData?.delete_energy_certificate_draft;

      if (deletedCertificateDrafts) {
        deleteCache(cache, deletedCertificateDrafts);
      }

      const deletedBuildingDrafts = deletedEnergyCertificateDraftData?.delete_building_draft;

      if (deletedBuildingDrafts) {
        deleteCache(cache, deletedBuildingDrafts);
      }
    },
    onError: () => showErrorSnackbar(),
    onCompleted: (data) => {
      if (!data.delete_energy_certificate_draft?.affected_rows && !data.delete_building_draft?.affected_rows) {
        showErrorSnackbar();
        return;
      }

      enqueueSnackbar(t('DataCollection_EnergyCertificateDraft_DeleteDraft-success'), {
        variant: 'success',
        autoHideDuration: SnackbarTimeouts.Success,
      });
    },
  });

  const {
    filters: {
      dataCollectionDraftsFilters: { selectedPortfolios, filterName },
    },
    setSelectedPortfolios,
    setOrderedDraftsIdList,
    setFilterAddressDrafts,
  } = useDataCollectionFilters();

  const {
    tempSelection: tempPortfolioSelection,
    handleSelectionChange: handlePortfolioSelectionChange,
    applySelection: applyPortfolioSelection,
    clearSelection: clearPortfolioSelection,
    isApplyDisabled: applyPortfolioDisabled,
    isClearDisabled: clearPortfolioDisabled,
    setTempSelection,
  } = useMultiSelectFilter<string>((update) => {
    setSelectedPortfolios('drafts', typeof update === 'function' ? update(selectedPortfolios) : update);
  }, ALL_PORTFOLIOS);

  useEffect(() => {
    setTempSelection(selectedPortfolios);
  }, [selectedPortfolios, setTempSelection]);

  const { page, setPage, rowsPerPage, setRowsPerPage, order, orderBy, handleRequestSort } = useTable({
    defaultOrder: tabTableFilter?.order || 'desc',
    defaultOrderBy: tabTableFilter?.orderBy || 'updated_at',
    defaultRowsPerPage: 25,
    onSortCallback: onFilterChange,
  });
  const [selectedBuildingDrafts, setSelectedBuildingDrafts] = useState<number[]>([]);
  const [selectedEnergyCertificateDrafts, setSelectedEnergyCertificateDrafts] = useState<number[]>([]);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const selected = [...selectedBuildingDrafts, ...selectedEnergyCertificateDrafts];

  const filteredDrafts = drafts.filter((draft) => {
    if (
      !selectedPortfolios.includes(ALL_PORTFOLIOS) &&
      draft.portfolio?.name &&
      !selectedPortfolios.includes(draft.portfolio.name)
    ) {
      return false;
    }

    if (filterName) {
      const normalizedQuery = filterName.toLowerCase().trim();
      const tokens = normalizedQuery.split(/\s+/);

      const fullAddress = `${draft.street || ''} ${draft.city || ''}`.toLowerCase();

      return tokens.every((token) => fullAddress.includes(token));
    }

    return true;
  });

  const portfolios = drafts.map((draft) => draft.portfolio);
  const portfolioOptions = uniq([
    ...portfolios
      .map((portfolio) => portfolio?.name)
      .filter((name) => typeof name === 'string')
      .sort(),
  ]);

  const handleDraftClick = (type: 'building_draft' | 'energy_certificate_draft', id: number) => {
    if (type === 'building_draft') {
      navigate(PATHS.dataCollection.buildingDraft({ id }));
    } else {
      navigate(PATHS.dataCollection.energyCertificateDraft({ id }));
    }
  };

  const sortedRows = applySortFilter({
    data: filteredDrafts,
    orderOptions: {
      order,

      //@ts-ignore
      orderBy: (item) => {
        switch (orderBy) {
          case 'file_name':
            if (item.__typename === 'energy_certificate_draft') {
              return item.file.name;
            }

            return '';
          case 'name':
            return `${item.user?.first_name ?? ''} ${item.user?.last_name ?? ''}`;
          default:
            return item[orderBy as keyof typeof item] as string | number;
        }
      },
    },
  });

  useEffect(() => {
    setOrderedDraftsIdList(sortedRows.map((i) => i.id));
    // This useEffect is used to the set the orderedDraftsIdList within the context so its value can be used to apply the pagination properly
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedRows.map((i) => i.id).toString()]);

  const handleCheckboxClick = (type: 'building_draft' | 'energy_certificate_draft', id: number) => {
    const selectedIndex = selected.indexOf(id);

    if (type === 'building_draft') {
      setSelectedBuildingDrafts(
        selectedIndex === -1 ? [...selectedBuildingDrafts, id] : without(selectedBuildingDrafts, id),
      );
    }

    if (type === 'energy_certificate_draft') {
      setSelectedEnergyCertificateDrafts(
        selectedIndex === -1 ? [...selectedEnergyCertificateDrafts, id] : without(selectedEnergyCertificateDrafts, id),
      );
    }
  };

  const handleSelectAllClick = (checked: boolean) => {
    if (checked) {
      setSelectedBuildingDrafts(buildingDrafts.map((draft) => draft.id));
      setSelectedEnergyCertificateDrafts(energyCertificateDrafts.map((draft) => draft.id));
    } else {
      setSelectedBuildingDrafts([]);
      setSelectedEnergyCertificateDrafts([]);
    }
  };

  const isNotFound = !sortedRows.length && Boolean(filterName);

  const tableColumns: HeadLabel[] = [
    { id: 'street', label: t('General_Address'), minWidth: 200 },
    { id: 'city', label: t('General_City'), minWidth: 140 },
    { id: 'file_name', label: t('General_Document', { count: 1 }), minWidth: 200 },
    { id: 'draft_status_id', label: t('General_OCR'), minWidth: 200 },
    { id: 'created_at', label: t('General_CreatedAt'), minWidth: 200 },
    { id: 'name', label: t('General_ResponsibleUser'), minWidth: 200 },
  ];

  if (!subscription.data) {
    return <DelayedLoading />;
  }

  return (
    <>
      <Card>
        <DataCollectionToolbar
          portfolioOptions={portfolioOptions}
          selectedPortfolios={selectedPortfolios}
          shouldOpenCreateModal={shouldOpenCreateModal}
          setCurrentTab={() => setCurrentTab({ value: DataCollectionTabEnum.ENERGY_CERTIFICATE_DRAFTS })}
          filterName={filterName}
          onFilterName={setFilterAddressDrafts}
          contextType="energyCertificateDraft"
          tempPortfolioSelection={tempPortfolioSelection}
          handlePortfolioSelectionChange={handlePortfolioSelectionChange}
          applyPortfolioSelection={applyPortfolioSelection}
          clearPortfolioSelection={clearPortfolioSelection}
          applyPortfolioDisabled={applyPortfolioDisabled}
          clearPortfolioDisabled={clearPortfolioDisabled}
        />

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            {selected.length > 0 && (
              <TableSelectedActions
                selectedText={t('General_BuildingDraft', { count: selected.length })}
                numSelected={selected.length}
                rowCount={sortedRows.length}
                onSelectAllRows={handleSelectAllClick}
                action={
                  <Button
                    color="error"
                    endIcon={<Iconify icon={ICONS.TRASH} />}
                    onClick={() => {
                      setDeleteModalOpen(true);
                    }}
                  >
                    {t('General_Delete')}
                  </Button>
                }
                sx={{ pr: 3 }}
              />
            )}
            <Table>
              <DataCollectionHead
                order={order}
                orderBy={orderBy}
                headLabel={tableColumns}
                rowCount={sortedRows.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {isNotFound ? (
                  <SearchNotFound searchQuery={filterName} />
                ) : (
                  sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((draft) => {
                    const isItemSelected = selected.indexOf(draft.id) !== -1;
                    return (
                      <TableRowWithHighlight
                        hover
                        key={draft.id}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                        sx={{ cursor: 'pointer' }}
                        background={hasRecentlyChanged(draft.created_at!) ? 'success' : 'default'}
                        onClick={() => handleDraftClick(draft.__typename, draft.id)}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            onClick={(event) => {
                              event.stopPropagation();
                              handleCheckboxClick(draft.__typename, draft.id);
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <OverflowText
                            text={draft.street ?? '-'}
                            maxWidth="200px"
                            TooltipProps={{
                              arrow: true,
                              placement: 'top-start',
                            }}
                          />
                        </TableCell>
                        <TableCell>{draft.city ?? '-'}</TableCell>
                        <TableCell>
                          {draft.__typename === 'energy_certificate_draft'
                            ? draft.file.name ?? '-'
                            : t('DataCollection_BuildingDraftMessage')}
                        </TableCell>
                        <TableCell>
                          {draft.__typename === 'energy_certificate_draft' ? (
                            <DraftStatusLabel status={draft.draft_status_id} />
                          ) : (
                            '-'
                          )}
                        </TableCell>
                        <TableCell>
                          {formatDateToLocale(draft.created_at!, COMMON_DATE_FORMATS.DAY_MONTH_YEAR_TIME, language)}
                        </TableCell>
                        <TableCell>
                          {draft.user ? (
                            <InlineUser firstName={draft.user.first_name} lastName={draft.user.last_name} size={25} />
                          ) : (
                            '-'
                          )}
                        </TableCell>
                      </TableRowWithHighlight>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePaginationStandard
          count={sortedRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
        />
      </Card>

      <DeleteConfirmationModal
        open={deleteModalOpen}
        title={t('General_DeleteModalTitle-BuildingDraft', { count: selected.length })}
        description={
          // t('General_DeleteModalDescription-BuildingDraft', { count })
          <Trans
            i18nKey={'General_DeleteModalDescription-BuildingDraft'}
            values={{
              count: selected.length,
            }}
            components={{ bold: <strong /> }}
          />
        }
        onClose={() => {
          setDeleteModalOpen(false);
        }}
        onDelete={async () => {
          setDeleteModalOpen(false);

          const energyCertificateDraftIds = selected.filter((id) =>
            energyCertificateDrafts.find((draft) => draft.id === id),
          );
          const draftBuildingIds = selected.filter((id) => buildingDrafts.find((draft) => draft.id === id));

          await deleteEnergyCertificateDraftsMutation({
            variables: {
              energyCertificateDraftIds,
              draftBuildingIds,
            },
          });

          setSelectedBuildingDrafts([]);
          setSelectedEnergyCertificateDrafts([]);
        }}
      />
    </>
  );
}
