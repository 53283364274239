import { TypedDocumentNode } from '@apollo/client';
import isNil from 'lodash/isNil';
import { isValidElement, ReactNode } from 'react';
import { type Get } from 'type-fest';
import { IBaseColumn } from './Columns/BaseColumn';

/**
 * Get the first key of an object. exclude keys that end with '_aggregate'.
 *
 * @example
 * type T = { building_aggregate: boolean; building: string;  };
 * type A = FirstKey<T>; // 'a'
 */
type FirstKey<T> = keyof {
  [K in keyof T as K extends `${string}_aggregate` ? never : {} extends { [P in K]: T[K] } ? never : K]: T[K];
};

/**
 * Gets the result type of a graphql query.
 * @template Query - The graphql query.
 *
 * @example
 * type Result = QueryResult<typeof GET_BUILDING>;
 */
export type QueryResult<T> = T extends TypedDocumentNode<infer ResultType, any>
  ? ResultType[FirstKey<ResultType>]
  : never;

/**
 * Gets the value type of a graphql query.
 *
 * @example
 * type IdType = QueryResultValue<typeof GET_BUILDING, 'id'>;
 */
export type QueryResultValue<T, ColumnKey extends string> = Get<QueryResult<T>[number], ColumnKey>;

/**
 * Gets the variables type of a graphql query.
 * @template Query - The graphql query.
 *
 * @example
 * type Variables = QueryVariables<typeof GET_BUILDING>;
 * const variables: Variables = { id: '1' };
 */
export type QueryVariables<T> = T extends TypedDocumentNode<any, infer VariablesType> ? Partial<VariablesType> : never;

/**
 * Gets the columns type for a graphql query to build the DataTable columns prop.
 *
 * @template TQuery - The graphql query.
 *
 * @example
 * const columns: TColumns<typeof GET_BUILDING> = [
 *  { id: 'id', label: 'ID', sortable: true },
 *  { id: 'name', label: 'Name', sortable: true },
 * ];
 */
export type DataTableColumns<T> = Partial<IBaseColumn<QueryResult<T>[number]>>[];

export type MaybeFunction<Arg, T> = T | ((arg: Arg) => T);

export const isValidReactNode = (value: any): value is ReactNode => {
  return (
    isNil(value) ||
    typeof value === 'string' ||
    typeof value === 'number' ||
    typeof value === 'boolean' ||
    isValidElement(value)
  );
};

/**
 * Prevents the default behavior of a click event if the target is not a checkbox.
 *
 * It's useful when using a menu that has a dialog with a checkbox.
 *
 * Checkbox is the only element that should not be prevented because it will be disabled
 *
 * @see https://www.geeksforgeeks.org/why-does-preventdefault-on-a-parent-elements-click-disable-a-checkbox/
 * @see https://github.com/facebook/react/issues/11387
 *
 * @param event - The click event.
 */
export const preventRedirect = (event: React.MouseEvent<HTMLDivElement>) => {
  event.stopPropagation();

  const target = event.target as HTMLElement;

  const isCheckbox = target instanceof HTMLInputElement && ['checkbox', 'radio'].includes(target.type);
  const isCheckboxLabel = target.dataset?.['checkboxLabel'] === 'true';
  if (!isCheckbox && !isCheckboxLabel) {
    event.preventDefault();
  }
};
