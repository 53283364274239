/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Box, Card, Divider, Grid, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ModificationImpactsFragment, efficiency_class_enum } from '@predium/client-graphql';
import { Units } from '@predium/utils';
import isNil from 'lodash/isNil';
import { useTranslation } from 'react-i18next';
import EfficiencyClassLabel from '../../../../../components/EfficiencyClassLabel';
import WidgetSummary from '../../../../../components/data-visialization/WidgetSummary';
import { getEfficiencyClassColor } from '../../ActionPlan.utils';
import ActionBeforeAfterChart from '../ActionBeforeAfterChart';
import { EfficiencyClassAndYearContainer } from '../EfficiencyClassAndYear';

type Props = {
  isOverview: boolean;
  isSimulated: boolean;
  modificationImpact: ModificationImpactsFragment;
  energyType?: 'final_energy' | 'primary_energy' | 'heating_demand';
  title: string;
};

export function EnergyWithEfficiencyClass({
  isOverview,
  isSimulated,
  modificationImpact,
  energyType = 'final_energy',
  title,
}: Props) {
  const { t } = useTranslation();
  const theme = useTheme();

  const {
    primary_energy: primaryEnergy,
    final_energy: finalEnergy,
    heating_demand: heatingDemand,
    efficiency_class,
  } = modificationImpact;

  const efficiencyClass = {
    before: efficiency_class?.before ?? efficiency_class_enum.UNKNOWN,
    after: efficiency_class?.after ?? efficiency_class_enum.UNKNOWN,
  };

  let energyData;
  switch (energyType) {
    case 'final_energy':
      energyData = finalEnergy;
      break;
    case 'primary_energy':
      energyData = primaryEnergy;
      break;
    case 'heating_demand':
      energyData = heatingDemand;
      break;
  }

  const primaryEnergyChartData = [energyData?.before ?? undefined, energyData?.after ?? undefined];

  return (
    <Card>
      <Stack direction="row" sx={{ pl: 3, pt: 3 }}>
        <Typography variant="h6">{title}</Typography>
        {!isNil(energyData?.impact) ? <WidgetSummary percent={energyData?.impact} /> : null}
      </Stack>

      <Stack>
        <Grid container spacing={1}>
          <Grid item xs={8} md={8} lg={8}>
            <Box px={2} py={isOverview ? 2 : 0}>
              <ActionBeforeAfterChart
                chartData={primaryEnergyChartData}
                colors={getEfficiencyClassColor(efficiencyClass?.before, efficiencyClass?.after, theme)}
                unit={Units.energyPerAreaYear}
                isSimulated={isSimulated}
              />
            </Box>
          </Grid>

          <Divider orientation="vertical" flexItem sx={{ mr: '-5px', mb: 2 }} variant="middle" />
          <Grid item xs={4} md={4} lg={4}>
            <Box p={isOverview ? 2 : 0}>
              <EfficiencyClassAndYearContainer
                title={t('General_EfficiencyClass')}
                before={
                  <EfficiencyClassLabel
                    efficiencyClass={efficiencyClass?.before ?? efficiency_class_enum.UNKNOWN}
                    variant={'filled'}
                    sx={{ alignItems: 'center' }}
                  />
                }
                after={
                  <EfficiencyClassLabel
                    efficiencyClass={efficiencyClass?.after ?? efficiency_class_enum.UNKNOWN}
                    variant={'filled'}
                    sx={{ alignItems: 'center' }}
                  />
                }
              />
            </Box>
          </Grid>
        </Grid>
      </Stack>
    </Card>
  );
}
