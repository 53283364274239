import { useApolloClient } from '@apollo/client';
import { Button, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import DropzoneComponent, { FileType, UploadingFile } from '../../../../components/DropzoneComponent';
import { CREATE_BUILDING_DOCUMENT } from '../../../../graphql/DataCollection.mutations';
import useUpdateFilename from '../../../../hooks/useUpdateFilename';

type Props = {
  buildingId: number;
  onClose: VoidFunction;
};

export default function UploadFileDialog({ buildingId, onClose }: Props) {
  const { t } = useTranslation();
  const apollo = useApolloClient();
  const { updateFilename } = useUpdateFilename();
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [anyFileUploaded, setAnyFileUploaded] = useState(false);

  const asyncCreateUploadUrl = async (): Promise<UploadingFile> => {
    const res = await apollo.mutate({
      mutation: CREATE_BUILDING_DOCUMENT,
      variables: {
        buildingId,
      },
    });

    const result = res.data!.createBuildingDocument;

    setAnyFileUploaded(true);

    return {
      fileUrl: result.uploadLink,
      fileId: result.fileId,
    };
  };

  return (
    <>
      <DialogTitle>{t('DataCollectionDocuments_UploadFileTitle')}</DialogTitle>

      <DialogContent sx={{ width: 600 }}>
        <DropzoneComponent
          fileDisplayName={t('General_Document', { count: 2 })}
          fileType={FileType.ANY}
          description={
            <Typography variant="body1">
              <Trans
                i18nKey="DataCollectionDocuments_UploadFileDescription"
                components={{
                  underline: <span style={{ textDecoration: 'underline', cursor: 'pointer' }} />,
                }}
              />
            </Typography>
          }
          createUploadUrl={asyncCreateUploadUrl}
          onUploadSuccess={(file) => {
            updateFilename(file.fileId, file.name);
          }}
          setUploadInProgress={setUploadInProgress}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} variant="contained" disabled={uploadInProgress || !anyFileUploaded} fullWidth>
          {t('General_Done')}
        </Button>
      </DialogActions>
    </>
  );
}
