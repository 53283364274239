import { FormControl, FormControlLabel, Radio, RadioGroup, SxProps } from '@mui/material';
import { ReactNode } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

type Props = {
  name: string;
  options: { value: string | number; label: ReactNode }[];

  /**
   * if true, the radio group is rendered in a horizontal row
   */
  row?: boolean;
  sx?: SxProps;
  itemSx?: SxProps;
};

export default function RHFRadio({ name, options, row, sx, itemSx }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, ...field } }) => (
        <FormControl>
          <RadioGroup {...field} row={row} sx={{ ...sx }}>
            {options.map((option) => (
              <FormControlLabel
                sx={itemSx}
                key={option.value}
                value={option.value}
                control={<Radio />}
                label={option.label}
              />
            ))}
          </RadioGroup>
        </FormControl>
      )}
    />
  );
}
