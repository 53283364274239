import { InputAdornment, Typography } from '@mui/material';
import { translateUnitEnum_dynamic } from '@predium/i18n/client';
import { UnitsValue } from '@predium/utils';
import isNil from 'lodash/isNil';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NumberField from '../../../../components/form/NumberField';
import { useEsgAnalysisPopupFilters } from '../EsgAnalysisFilterPopupContext';
import { esgAnalysisFilters, EsgAnalysisFiltersKeys } from '../EsgAnalysisFilters';

type FilterOptionsAsRangeProps<Key extends EsgAnalysisFiltersKeys> = {
  type: Key;
  isError?: boolean;
  unit?: UnitsValue;
};

const FilterOptionsAsNumberInput = memo(function FilterOptionsAsNumberInput<Key extends EsgAnalysisFiltersKeys>({
  type,
  isError,
  unit,
}: FilterOptionsAsRangeProps<Key>) {
  const { t } = useTranslation();
  const { replaceFilter, resetFilter, filters } = useEsgAnalysisPopupFilters();

  const [value, setValue] = useState<number | null>(filters?.[type]?.[0] as number | null);

  const filter = esgAnalysisFilters.find((f) => f.key === type);

  useEffect(() => {
    if (!isNil(value)) {
      replaceFilter(type, [value]);
    } else {
      resetFilter(type);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    // Reset value if filter is removed
    if (isNil(filters?.[type]?.[0])) {
      setValue(null);
    }
  }, [filters, type]);

  return (
    <NumberField
      label={filter?.title}
      value={value}
      onValueChange={(values) => {
        const { floatValue, formattedValue } = values;
        if (formattedValue === '') {
          setValue(null);
        } else if (!isNil(floatValue)) {
          setValue(floatValue);
        }
      }}
      variant="outlined"
      size="small"
      allowDecimals={false}
      isError={isError}
      InputProps={{
        endAdornment: unit ? (
          <InputAdornment position="end" sx={{ color: 'text.secondary' }}>
            <Typography fontSize={'0.75rem'}>{translateUnitEnum_dynamic(unit, t)} </Typography>
          </InputAdornment>
        ) : null,
      }}
    />
  );
});

export default FilterOptionsAsNumberInput;
