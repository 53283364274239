/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useMutation, useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, DialogActions } from '@mui/material';
import { AnswerParamsInput } from '@predium/client-graphql';
import { report_answer_state_enum, report_template_question_type_enum } from '@predium/enums';
import { Localize } from '@predium/utils';
import { useSnackbar } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import { UseFieldArrayReturn, useFieldArray, useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { array, date, mixed, number, object, string } from 'yup';
import Iconify from '../../../components/Iconify';
import { DelayedLoading } from '../../../components/Loading';
import { SnackbarTimeouts } from '../../../components/NotistackProvider';
import { FormProvider } from '../../../components/hook-form';
import { ANSWER_QUESTIONS } from '../../../graphql/Report.mutations';
import {
  GET_ALL_REPORT_AUTOFILL_QUESTIONS,
  GET_All_EVIDENCES,
  GET_OPTIONS_WITH_SUBQUESTIONS,
  GET_QUESTION_ANSWER,
  GET_QUESTION_EVIDENCES,
  GET_REPORT_COMPLETION,
  GET_ROOT_QUESTIONS_AND_ANSWERS,
  GET_SECTIONS_PROGRESS,
} from '../../../graphql/Report.queries';
import { useLanguage } from '../../../provider/LanguageProvider';
import { ReportQuestionDC } from '../ReportingDataClasses/ReportQuestion.dc';
import { ReportQuestionOptionDC } from '../ReportingDataClasses/ReportQuestionOption.dc';
import DeleteReportAnswer from './ReportAnswers/DeleteReportAnswer';
import AddEvidenceDialog from './ReportEvidence/AddEvidenceDialog';
import RootQuestionEvidences, { EvidenceFieldArrayHandles } from './ReportEvidence/RootQuestionEvidences';
import DateQuestion from './ReportQuestions/DateQuestion';
import MultipleChoiceQuestion from './ReportQuestions/MultipleChoiceQuestion';
import NumberQuestion from './ReportQuestions/NumberQuestion';
import SingleChoiceQuestion from './ReportQuestions/SingleChoiceQuestion';
import TextQuestion from './ReportQuestions/TextQuestion';

/**
 * Only questions of a specified type and with a provided value can be autofilled.
 */
export const isAutoFillQuestion = (question: ReportQuestionDC) => {
  return (
    question.answer && (question.answer.autofill !== null || question.answer.report_answer_autofill_options.length > 0)
  );
};

export const isAnsweredQuestion = (question: ReportQuestionDC) => {
  return question.answer?.state === report_answer_state_enum.ANSWERED;
};

export type ReportQuestionSwitchProps = {
  question: ReportQuestionDC;
  reportId: number;
  isSubQuestion: boolean;
  isEditable?: boolean;
  /**
   * Delegates the focus to a question.
   * Currently, this is the first unanswered question. If the question is answered the next following answer is focused.
   */
  autoFocus?: boolean;
  formArrayProps?: UseFieldArrayReturn<FormValues, 'questions', 'id'>;
};

export default function ReportQuestion(props: ReportQuestionSwitchProps) {
  const { isSubQuestion, question, formArrayProps, reportId } = props;
  const { fields, append, remove } = formArrayProps!;
  const { localize } = useLanguage();

  const { data } = useQuery(GET_OPTIONS_WITH_SUBQUESTIONS, {
    variables: {
      questionId: question.id,
    },
  });

  const { data: answerData } = useQuery(GET_QUESTION_ANSWER, {
    variables: {
      reportId,
      questionId: question.id,
    },
    skip: !isSubQuestion,
  });

  const methods = useFormContext();
  const { reset, getValues } = methods;

  //@ts-ignore
  if (!question.options && data?.report_template_question_by_pk.report_template_question_options) {
    //transform the received options to DCs along with its subquestions
    question.options = data.report_template_question_by_pk.report_template_question_options.map((option) => {
      return new ReportQuestionOptionDC({
        ...option,
        report_template_child_questions: option.report_template_child_questions.map(
          //@ts-ignore
          (child) => new ReportQuestionDC(child),
        ),
      });
    });
  }

  const answer = answerData?.report_by_pk?.report_answers.find((answer) => answer.report_question_id === question.id);
  if (!question.answer) {
    question.answer = answer;
  }

  // Register a new form field the first time a new question gets rendered.
  // Reset the form initially so that submitting form is not messing up the state of other forms.
  useEffect(() => {
    // If the form has already be set up we don't populate it again.
    const isInitialized = getValues('questions').length > 0;

    if (question.answer && !isInitialized) {
      // If we display an answer we provide a default value instead of nothing.
      const questionAnswer = getFormPresetValues(question, localize);

      const formEntry: FormValues['questions'][number] = {
        _id: question.id,
        type: question.type,
        ...questionAnswer,
      };
      append(formEntry as FormValues['questions'][number]);

      // we must use reset to reset the dirty state on initialization
      reset(undefined, { keepValues: true });
    }

    // Just execute once after the answer is set.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question.answer]);

  useEffect(() => {
    // We only want to append once after the answer is set.
    // Appending the form field for followup questions.
    if (isSubQuestion && answerData) {
      const questionAnswer = getFormPresetValues(question, localize);

      const formEntry: FormValues['questions'][number] = {
        _id: question.id,
        type: question.type,
        ...questionAnswer,
      };
      // Delegate focus to the component itself because many are added at the same time.
      append(formEntry as FormValues['questions'][number], { shouldFocus: false });
    }
    // On unmount we remove the form field (answering a question or deleting an answer)
    return () => {
      // Need this check to not get called on ReactStrict mode.
      if (isSubQuestion && answerData) {
        const formArrayValues: FormValues['questions'] = getValues('questions');
        const indexInForm = formArrayValues.findIndex((field) => field._id === question.id);
        remove(indexInForm);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localize, answerData]);

  // wait until data has been fetched and the new form field has been registered in the array before anything is shown.
  if (!data || !question.options || !fields.find((field) => field._id === question.id)) return <DelayedLoading />;

  switch (question.type) {
    case report_template_question_type_enum.SINGLE_CHOICE:
      return <SingleChoiceQuestion {...props} />;

    case report_template_question_type_enum.MULTIPLE_CHOICE:
      return <MultipleChoiceQuestion {...props} />;

    case report_template_question_type_enum.TEXT:
      return <TextQuestion {...props} />;

    case report_template_question_type_enum.DATE:
      return <DateQuestion {...props} />;

    case report_template_question_type_enum.YEAR:
      /*
        removing thousandSeparator so 2024 doesn't become 2.024 
        adding max={9999} so we can only accept four digits without having to add more logic here
      */
      return <NumberQuestion {...props} thousandSeparator="" max={9999} allowDecimals={false} />;

    case report_template_question_type_enum.INTEGER:
    case report_template_question_type_enum.FLOAT:
    case report_template_question_type_enum.PERCENTAGE:
      return <NumberQuestion {...props} />;

    default:
      const exhaustiveCheck: never = question.type;
      throw new Error(`Unhandled question type: ${exhaustiveCheck}`);
  }
}

export type FormValues = {
  questions: {
    _id: number;
    type: string;
    value?: FormControlValues['value'];
    singleChoiceOptionId?: FormControlValues['singleChoiceOptionId'];
    multipleChoiceOptionIds?: FormControlValues['multipleChoiceOptionIds'];
    evidences?: {
      id: number;
      description: string;
    }[];
  }[];
};

type FormControlValues = {
  value?: string | number | Date;
  singleChoiceOptionId?: number;
  multipleChoiceOptionIds?: number[];
};

/**
 * A root question can have multiple child questions. All of them are going to be submitted together and are organized in an array.
 * This component is recursive so that the root question is a <ReportQuestion> but can contain child questions which are <ReportQuestion> as well.
 * These child questions can have child questions as well.
 */
export function ReportQuestionRoot({
  isAnsweredQuestion,
  handleSingleQuestionAnswered,
  onDeleteCallback,
  ...props
}: ReportQuestionSwitchProps & {
  isAnsweredQuestion: boolean;
  handleSingleQuestionAnswered?: (question: ReportQuestionDC) => void;
  onDeleteCallback?: () => void;
}) {
  const { t } = useTranslation();
  const { localize } = useLanguage();
  const { enqueueSnackbar } = useSnackbar();

  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [evidenceDialogOpen, setEvidenceDialogOpen] = useState(false);
  const displayEvidenceRef = useRef<EvidenceFieldArrayHandles>(null);

  const { data } = useQuery(GET_REPORT_COMPLETION, {
    variables: {
      id: props.question.reportId,
    },
    nextFetchPolicy: 'cache-only',
  });

  // An answer is editable if the user is in edit mode and the report is not completed.
  // Open questions are always editable because there are no open questions when the report is completed.

  //@ts-ignore
  const isEditable = (isEditMode && !data?.report_by_pk.completed) || !isAnsweredQuestion;

  const evidenceShape = object().shape({
    id: number().required(t('General_Required')),
    description: string(),
  });

  // "evidences" are only set for the first root question. Followup questions can not have evidences.
  // To represent this model in the validation a custom callback function needs to be implemented.
  const EvidenceValidation = props.question.evidence_required
    ? array(evidenceShape).when((_, schema) => {
        return schema.test({
          test: function (value: FormValues['questions'][number]['evidences'] | undefined) {
            const isFirstEntry = this.path === 'questions[0].evidences';
            if (isFirstEntry) {
              //@ts-ignore
              return value.length > 0;
            } else {
              return value === undefined;
            }
          },
          message: t('ReportQuestion_EvidenceRequired'),
        });
      })
    : // @ts-ignore
    props.question.evidence_required === false
    ? array(evidenceShape).when((_, schema) => {
        return schema.test({
          test: function (value: FormValues['questions'][number]['evidences'] | undefined) {
            const isFirstEntry = this.path === 'questions[0].evidences';
            if (isFirstEntry) {
              return Array.isArray(value);
            } else {
              return value === undefined;
            }
          },
          message: t('ReportQuestion_EvidenceMustBeDefined'),
        });
      })
    : mixed().oneOf([null, undefined], t('ReportQuestion_NoEvidencePossible'));

  // Each entry of the array can be a different question with a different data type and validation.
  const AnswerSchema = object().shape({
    questions: array().of(
      object().shape({
        _id: number().required(t('General_Required')),
        // For distinct differentiation how the type will look like.
        type: string().required(t('General_Required')),
        value: mixed()
          .when('type', {
            is: report_template_question_type_enum.TEXT,
            then: string().required(t('General_Required')),
          })
          .when('type', {
            is: report_template_question_type_enum.INTEGER,
            then: number()
              .required(t('General_Required'))
              .integer(t('General_InvalidInteger'))
              .typeError(t('General_InvalidInteger')),
          })
          .when('type', {
            is: (value: report_template_question_type_enum) =>
              value === report_template_question_type_enum.FLOAT ||
              value === report_template_question_type_enum.PERCENTAGE,
            then: number().required(t('General_Required')).typeError(t('ReportQuestion_InvalidDecimal')),
          })
          .when('type', {
            is: report_template_question_type_enum.DATE,
            then: date().required(t('General_Required')).typeError(t('General_InvalidDate')),
          }),
        singleChoiceOptionId: number().when('type', {
          is: report_template_question_type_enum.SINGLE_CHOICE,
          then: number().required(t('General_SelectionRequired')),
        }),
        multipleChoiceOptionIds: array().when('type', {
          is: report_template_question_type_enum.MULTIPLE_CHOICE,
          then: array().required(t('General_SelectionRequired')).min(1, t('General_SelectionRequired')),
        }),
        evidences: EvidenceValidation,
      }),
    ),
  });

  const methods = useForm<FormValues>({
    reValidateMode: 'onSubmit',
    resolver: yupResolver(AnswerSchema),
    defaultValues: {
      questions: [],
    },
  });
  const {
    handleSubmit,
    formState: { isDirty },
    reset,
    control,
  } = methods;
  const formArrayProps = useFieldArray({
    control,
    name: 'questions',
  });

  const [answerQuestions, { loading: answeringLoading }] = useMutation(ANSWER_QUESTIONS, {
    refetchQueries: [
      GET_ROOT_QUESTIONS_AND_ANSWERS,
      GET_ALL_REPORT_AUTOFILL_QUESTIONS,
      GET_REPORT_COMPLETION,
      {
        query: GET_QUESTION_EVIDENCES,
        variables: {
          reportId: props.question.reportId,
          questionId: props.question.id,
        },
      },
      {
        query: GET_All_EVIDENCES,
        variables: {
          reportId: props.question.reportId,
        },
      },
      {
        query: GET_SECTIONS_PROGRESS,
        variables: {
          reportId: props.question.reportId,
        },
      },
    ],
    onError: () =>
      enqueueSnackbar(t('ReportQuestion_SaveAnswer-error'), {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
      }),
    onCompleted: () => {
      handleSingleQuestionAnswered?.(props.question);
    },
    // We need to await the GET_ROOT_QUESTIONS_AND_ANSWERS query to focus the first of an updated list of questions.
    awaitRefetchQueries: true,
  });

  const onSubmit = (formPayload: FormValues) => {
    const answerInputs: AnswerParamsInput[] = formPayload.questions.map(
      ({ _id, value, singleChoiceOptionId, multipleChoiceOptionIds, evidences }) => {
        if (value instanceof Date) {
          // Convert date to ISO8601 format because backend excepts only strings
          value = value.toISOString();
        }
        return {
          report_question_id: _id,
          // Cast all values (e.g. numbers) to strings because the backend expects strings.
          value: value?.toString() ?? null,
          selected_option_ids: multipleChoiceOptionIds
            ? multipleChoiceOptionIds
            : singleChoiceOptionId
            ? [singleChoiceOptionId]
            : null,
          evidences: evidences?.map(({ id, description }) => ({
            id,
            // The database expects null if the user has not made any inputs but the html textarea always needs a string.
            description: description !== '' ? description : null,
          })),
        };
      },
    );

    setIsEditMode(false);
    answerQuestions({
      variables: {
        answers: answerInputs,
        report_id: props.question.reportId,
        sub_building_id: props.question.subBuildingId ?? null,
      },
    });
  };

  const onReset = () => {
    if (!isAnsweredQuestion) {
      return reset();
    }

    const questionAnswer = getFormPresetValues(props.question, localize, true);
    const formEntry: FormValues['questions'][number] = {
      _id: props.question.id,
      type: props.question.type,
      ...questionAnswer,
    };

    reset({
      questions: [formEntry],
    });
  };

  if (answeringLoading) return <DelayedLoading />;

  const isUntouchedPrefill = !isDirty && isAutoFillQuestion(props.question);

  return (
    <>
      <FormProvider
        methods={methods}
        onSubmit={handleSubmit(onSubmit)}
        cyDataTestId={`cy-report-question-${props.question.id}`}
      >
        {isAnsweredQuestion && (
          <DeleteReportAnswer question={props.question} isEditable={isEditable} onDeleteCallback={onDeleteCallback} />
        )}

        <ReportQuestion {...props} formArrayProps={formArrayProps} isSubQuestion={false} isEditable={isEditable} />

        {props.question.evidence_required !== null && (
          <RootQuestionEvidences
            ref={displayEvidenceRef}
            required={props.question.evidence_required}
            openDialog={() => setEvidenceDialogOpen(true)}
            isAnsweredQuestion={isAnsweredQuestion}
            isEditable={isEditable}
            questionId={props.question.id}
            reportId={props.question.reportId}
          />
        )}
        <DialogActions>
          {isAutoFillQuestion(props.question) && (
            <Button
              type="button"
              onClick={onReset}
              variant="outlined"
              color="error"
              aria-label="form-reset-button"
              // only enabled if changed for unanswered question or if answered question is editable
              disabled={(!isAnsweredQuestion && isUntouchedPrefill) || !isEditable}
            >
              {t('ReportQuestion_ResetToSuggestion')}
            </Button>
          )}

          {!isAnsweredQuestion ? (
            <Button type="submit" variant="contained">
              {isUntouchedPrefill ? t('ReportQuestion_AcceptSuggestion') : t('ReportQuestion_Answer')}
            </Button>
          ) : !isEditMode ? (
            <Button
              type="button"
              variant="contained"
              //@ts-ignore
              disabled={data?.report_by_pk.completed}
              startIcon={<Iconify icon="material-symbols:lock-open-rounded" width={20} height={20} />}
              onClick={(event) => {
                // React is stoopid and propagates the click event to the submit button that is rendered in its place.
                // We have to prevent it from calling submit even though the button type is "button"
                event.preventDefault();
                setIsEditMode(true);
              }}
            >
              {t('ReportQuestion_EditAnswer')}
            </Button>
          ) : (
            //@ts-ignore
            <Button type="submit" variant="contained" disabled={data?.report_by_pk.completed}>
              {t('General_Save')}
            </Button>
          )}
        </DialogActions>
      </FormProvider>

      <AddEvidenceDialog
        open={evidenceDialogOpen}
        closeDialog={() => setEvidenceDialogOpen(false)}
        reportId={props.reportId}
        displayEvidenceRef={displayEvidenceRef}
      />
    </>
  );
}

/**
 * This functions returns the default value for the form control of a single answer.
 *
 * @param question The question DTO initialized from the GQL node.
 * @param localize
 * @param forceAutofill
 * @returns Object with only the defaultValue of the form control set.
 */
const getFormPresetValues = (
  question: ReportQuestionDC,
  localize: Localize,
  forceAutofill: boolean = false,
): FormControlValues => {
  if (!question.answer) return {};

  let autofillValue;

  switch (question.type) {
    case report_template_question_type_enum.INTEGER:
      autofillValue =
        (question.answer?.autofill?.length ?? 0) > 0
          ? localize.formatAsInteger(question.answer.autofill, { defaultString: '' })
          : undefined;

      return {
        value: forceAutofill
          ? autofillValue
          : Number.isNaN(Number(question.answer.value)) || question.answer.value === null
          ? autofillValue
          : localize.formatAsInteger(question.answer.value, { defaultString: '' }),
      };
    case report_template_question_type_enum.FLOAT:
    case report_template_question_type_enum.PERCENTAGE:
      autofillValue =
        (question.answer?.autofill?.length ?? 0) > 0
          ? localize.formatAsFloat(question.answer.autofill, { defaultString: '' })
          : undefined;

      return {
        value: forceAutofill
          ? autofillValue
          : Number.isNaN(Number(question.answer.value)) || question.answer.value === null
          ? autofillValue
          : localize.formatAsFloat(question.answer.value, { defaultString: '' }),
      };

    case report_template_question_type_enum.YEAR:
    case report_template_question_type_enum.DATE:
    case report_template_question_type_enum.TEXT:
      autofillValue = question.answer?.autofill;

      return {
        //@ts-ignore
        value: forceAutofill ? autofillValue : question.answer?.value ?? autofillValue ?? '',
      };

    case report_template_question_type_enum.SINGLE_CHOICE:
      autofillValue = question.answer?.report_answer_autofill_options[0]?.report_template_question_option.id;

      return {
        singleChoiceOptionId: forceAutofill
          ? autofillValue
          : question.answer?.report_answer_chosen_options[0]?.report_template_question_option.id ?? autofillValue,
      };

    case report_template_question_type_enum.MULTIPLE_CHOICE:
      autofillValue = question.answer?.report_answer_autofill_options.map(
        (option) => option.report_template_question_option.id,
      );

      return {
        multipleChoiceOptionIds: forceAutofill
          ? autofillValue
          : question.answer?.report_answer_chosen_options.length > 0
          ? question.answer.report_answer_chosen_options.map((option) => option.report_template_question_option.id)
          : autofillValue,
      };

    default:
      const exhaustiveCheck: never = question.type;
      throw new Error(`Unhandled question type: ${exhaustiveCheck}`);
  }
};
