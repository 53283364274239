import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid } from '@mui/material';
import { DataCollectionBuildingModelFragment } from '@predium/client-graphql';
import { Units } from '@predium/utils';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { FormProvider } from '../../../../../components/hook-form';
import useSessionData from '../../../../../hooks/useSessionData';
import { FormRef, SetFormState } from '../../BuildingTechnologyEnvelope';
import { EnvelopeUnitField } from '../EnvelopeUnit/EnvelopeUnitField';

type BuildingModelValues = {
  heating_demand?: number;
  internal_temperature?: number;
  annual_heating_days?: number;
};

export type BuildingModelValuesFormValues = {
  heating_demand?: number;
  internal_temperature?: number;
  annual_heating_days?: number;
};

export type NestedForm = {
  buildingModel: DataCollectionBuildingModelFragment;
  setFormState: SetFormState;
};

const BuildingModelValuesSection = forwardRef<FormRef<BuildingModelValuesFormValues>, NestedForm>(
  ({ buildingModel, setFormState }, ref) => {
    const { t } = useTranslation();
    const { serverSideFeatureFlags } = useSessionData();

    const REQUIRED_MESSAGE = t('General_Required');
    const HEATING_DEMAND_RANGE_MESSAGE = t('DataCollection_BuildingModelValuesSection_HeatingDemandRangeMessage');
    const INTERNAL_TEMPERATURE_RANGE_MESSAGE = t(
      'DataCollection_BuildingModelValuesSection_InternalTemperatureRangeMessage',
    );
    const ANNUAL_HEATING_DAYS_RANGE_MESSAGE = t(
      'DataCollection_BuildingModelValuesSection_AnnualHeatingDaysRangeMessage',
    );

    const [shouldValidate, setShouldValidate] = useState(false);

    const BuildingModelValuesDataSchema = Yup.object().shape({
      heating_demand: Yup.number().optional().min(0, HEATING_DEMAND_RANGE_MESSAGE),
      internal_temperature: Yup.number().required(REQUIRED_MESSAGE).min(0, INTERNAL_TEMPERATURE_RANGE_MESSAGE),
      annual_heating_days: Yup.number()
        .required(REQUIRED_MESSAGE)
        .min(0, ANNUAL_HEATING_DAYS_RANGE_MESSAGE)
        .max(366, ANNUAL_HEATING_DAYS_RANGE_MESSAGE),
    });

    const defaultValues = useMemo<BuildingModelValues>(() => {
      return {
        heating_demand: buildingModel.heating_demand ?? undefined,
        internal_temperature: buildingModel.internal_temperature,
        annual_heating_days: buildingModel.annual_heating_days,
      };
    }, [buildingModel]);

    const methods = useForm<BuildingModelValues>({
      defaultValues,
      mode: 'onChange',
      reValidateMode: 'onChange',
      resolver: shouldValidate ? yupResolver(BuildingModelValuesDataSchema) : undefined,
    });

    const { control, reset } = methods;

    const { isDirty, errors, dirtyFields } = useFormState({
      control,
    });

    const errorCount = Object.keys(errors).length;
    const editCount = Object.keys(dirtyFields).length;

    useEffect(() => {
      reset(defaultValues);
    }, [reset, defaultValues]);

    useEffect(() => {
      setShouldValidate(isDirty);
      setFormState({
        isDirty,
        errorCount,
        editCount,
      });
    }, [isDirty, errorCount, editCount, setFormState]);

    useImperativeHandle(ref, () => ({
      methods: methods,
    }));

    const hasEditAccess = serverSideFeatureFlags.HEATING_DEMAND_EDITABLE;

    return (
      <Box width="100%" sx={{ mb: 2 }}>
        <FormProvider methods={methods}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <EnvelopeUnitField
                id="heating_demand"
                name="heating_demand"
                title={t('DataCollection_BuildingModelValuesSection_HeatingDemandEnergyCertificate')}
                fieldType="number"
                isFieldEditable={hasEditAccess}
                unit={Units.energyPerAreaYear}
              />
            </Grid>
            <Grid item xs={2}>
              <EnvelopeUnitField
                id="internal_temperature"
                name="internal_temperature"
                title={t('General_InternalTemperature')}
                fieldType="number"
                isFieldEditable={hasEditAccess}
                unit={Units.internalTemperature}
              />
            </Grid>
            <Grid item xs={2}>
              <EnvelopeUnitField
                id="annual_heating_days"
                name="annual_heating_days"
                title={t('General_AnnualHeatingDays')}
                fieldType="number"
                isFieldEditable={hasEditAccess}
                unit={Units.days}
              />
            </Grid>
          </Grid>
        </FormProvider>
      </Box>
    );
  },
);
export default BuildingModelValuesSection;
