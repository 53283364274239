/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Button, Stack, Toolbar } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Iconify from '../../components/Iconify';
import MultiSelectFilterWithSearch, { FilterItem } from '../../components/search/MultiSelectFilterWithSearch';
import SingleSelectFilter from '../../components/search/SingleSelectFilter';
import { useMultiSelectFilter } from '../../hooks/useMultiSelectFilter';
import { getTemplateMetadataForTemplateTitle } from './ReportingDataClasses/ReportTemplate.dc';

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

type Props = {
  availablePortfolios: { id: number; name: string }[];
  availableTemplates: { id: number; title: string }[];
  availableOwners: { label: string; value: number }[];
  setSelectedPortfolioIdFilter: React.Dispatch<React.SetStateAction<('General_AllPortfolios' | number)[]>>;
  selectedPortfolioIdFilter: ('General_AllPortfolios' | number)[];
  selectedOwnerIdFilter: 'General_AllOwners' | number;
  setSelectedOwnerIdFilter: (value: 'General_AllOwners' | number) => void;
  selectedTemplateFilter: string[];
  setSelectedTemplateFilter: React.Dispatch<React.SetStateAction<string[]>>;
  onCreateReport: VoidFunction;
};

export default function ReportingTableToolbar({
  availablePortfolios,
  availableTemplates,
  availableOwners,
  selectedOwnerIdFilter,
  setSelectedOwnerIdFilter,
  setSelectedTemplateFilter,
  selectedTemplateFilter,
  onCreateReport,
  setSelectedPortfolioIdFilter,
  selectedPortfolioIdFilter,
}: Props) {
  const { t } = useTranslation();

  const templateItems: FilterItem[] = [
    ...availableTemplates.map(({ title }) => ({
      name: getTemplateMetadataForTemplateTitle(title).readableTitle ?? title,
      value: title,
    })),
  ];

  const ownerItems: FilterItem[] = [
    { name: t('General_AllOwners'), value: 'General_AllOwners' },
    ...availableOwners.map(({ label, value }) => ({
      name: label,
      value,
    })),
  ];

  const portfolioItems: FilterItem[] = [
    ...availablePortfolios.map(({ id, name }) => ({
      name,
      value: id,
    })),
  ];
  const {
    tempSelection: tempTemplateSelection,
    handleSelectionChange: handleTemplateSelectionChange,
    applySelection: applyTemplateSelection,
    clearSelection: clearTemplateSelection,
    isApplyDisabled: applyTemplateSelectionDisabled,
    isClearDisabled: applyTemplateClearDisabled,
  } = useMultiSelectFilter<'Reporting_AllTemplates' | string>(setSelectedTemplateFilter, 'Reporting_AllTemplates');

  const {
    tempSelection: tempPortfolioSelection,
    handleSelectionChange: handlePortfolioSelectionChange,
    applySelection: applyPortfolioSelection,
    clearSelection: clearPortfolioSelection,
    isApplyDisabled: applyPortfolioSelectionDisabled,
    isClearDisabled: applyPortfolioClearDisabled,
  } = useMultiSelectFilter<'General_AllPortfolios' | number>(setSelectedPortfolioIdFilter, 'General_AllPortfolios');

  return (
    <RootStyle>
      <Stack direction="row" sx={{ width: '100%' }} gap={2} alignItems="center">
        <MultiSelectFilterWithSearch
          label={t('Reporting_AllTemplates')}
          items={templateItems}
          selectedLabel={t('General_Template', { count: 0 })}
          tempSelection={tempTemplateSelection}
          handleSelectionChange={handleTemplateSelectionChange}
          applySelection={applyTemplateSelection}
          clearSelection={clearTemplateSelection}
          isApplyDisabled={applyTemplateSelectionDisabled}
          isClearDisabled={applyTemplateClearDisabled}
          noResultsText={t('Reporting_CreateReportNoReportTemplates')}
          selectedFilter={selectedTemplateFilter}
          searchable
        />

        <SingleSelectFilter
          label={t('General_Owner')}
          items={ownerItems}
          selectedItem={selectedOwnerIdFilter}
          onChanged={(value) =>
            setSelectedOwnerIdFilter(value === 'General_AllOwners' ? 'General_AllOwners' : (value as number))
          }
        />

        <MultiSelectFilterWithSearch
          label={t('General_AllPortfolios')}
          items={portfolioItems}
          selectedLabel={t('General_Portfolio_other')}
          tempSelection={tempPortfolioSelection}
          handleSelectionChange={handlePortfolioSelectionChange}
          applySelection={applyPortfolioSelection}
          clearSelection={clearPortfolioSelection}
          isApplyDisabled={applyPortfolioSelectionDisabled}
          isClearDisabled={applyPortfolioClearDisabled}
          noResultsText={t('NoExistingPortfolios_info')}
          selectedFilter={selectedPortfolioIdFilter}
          searchable
        />

        <Button
          variant="contained"
          startIcon={<Iconify icon={'eva:plus-fill'} width={20} height={20} />}
          sx={{ marginLeft: 'auto' }}
          size="medium"
          onClick={onCreateReport}
        >
          {t('Reporting_CreateReportButton')}
        </Button>
      </Stack>
    </RootStyle>
  );
}
