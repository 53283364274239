import { Alert } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

export const CompareScenariosAlertInfo = () => {
  const { t } = useTranslation();
  return (
    <Alert severity="info">
      <Typography variant={'body2'} gutterBottom={false}>
        {t('ActionPlanningScenario_CompareScenarios_Info')}
      </Typography>
    </Alert>
  );
};
