/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useQuery } from '@apollo/client';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { GetAllUsersQuery } from '@predium/client-graphql';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Iconify from '../../components/Iconify';
import LoadingScreen from '../../components/LoadingScreen';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import { TableEmptyRows, TableSearchNotFound } from '../../components/table';
import TableHeadCustom, { HeadCell } from '../../components/table/TableHeadCustom';
import TablePaginationStandard from '../../components/table/TablePaginationStandard';
import { GET_USERS } from '../../graphql/Users.queries';
import useSessionData from '../../hooks/useSessionData';
import useTable, { applySortFilter, emptyRows } from '../../hooks/useTable';
import UserCreateDialog from '../../sections/Org/User/UserCreateDialog';
import UserDelete from '../../sections/Org/User/UserDelete';
import UserTableRow from '../../sections/Org/User/UserTableRow';
import UserTableToolbar from '../../sections/Org/User/UserTableToolbar';
import { RoleOption, RoleSortingOptions } from '../../utils/Roles';

export default function Users() {
  const { t } = useTranslation();
  const { dense, page, order, orderBy, rowsPerPage, setPage, onSort, setRowsPerPage } = useTable({
    defaultOrderBy: 'created_at',
    defaultRowsPerPage: 25,
  });
  const { user, isAdmin } = useSessionData();
  const userRole = user?.user_role[0]?.role;
  const { data, loading } = useQuery(GET_USERS);

  const [filterName, setFilterName] = useState('');
  const [filterRole, setFilterRole] = useState<RoleOption>(RoleSortingOptions(t)[0]);
  const [showCreateUserDialog, setShowCreateUserDialog] = useState<boolean>(false);
  const [userToDelete, setUserToDelete] = useState<GetAllUsersQuery['user'][number] | null>(null);

  const handleFilterName = (filterName: string) => {
    setFilterName(filterName);
    setPage(0);
  };

  const handleFilterRole = (event: React.ChangeEvent<HTMLInputElement>) => {
    //@ts-ignore
    setFilterRole(RoleSortingOptions(t).find((option) => option.name === event.target.value));
  };

  if (!data?.user || !userRole) return <LoadingScreen />;

  const dataFiltered = applySortFilter({
    data: data.user,
    nameFilter: {
      fieldName: (user) => `${user.first_name} ${user.last_name}`,
      filterValue: filterName,
    },
    dataFilters: filterRole.name === 'all' ? [] : [(user) => user.user_role[0].role === filterRole.name],
    orderOptions: {
      order,
      orderBy,
    },
  });

  const isNotFound = !dataFiltered.length;

  const tableHead: HeadCell[] = [
    { id: 'first_name', label: t('General_User'), minWidth: 150 },
    { id: 'user_role[0].role', label: t('General_Role'), minWidth: 150 },
    { id: 'email', label: t('General_Email'), minWidth: 150 },
    { id: 'created_at', label: t('General_CreatedAt'), minWidth: 150 },
    { id: 'actions', label: '', sortingDisabled: true },
  ];

  return (
    <Page title={t('PageName_ManageUsers')}>
      <Container maxWidth={'lg'}>
        <HeaderBreadcrumbs heading={t('PageName_ManageUsers')} links={[]} />
        <Card>
          <UserTableToolbar
            filterName={filterName}
            filterRole={filterRole}
            onFilterName={handleFilterName}
            onFilterRole={handleFilterRole}
            optionsRole={RoleSortingOptions(t)}
          >
            {isAdmin ? (
              <Button
                variant="contained"
                size="medium"
                startIcon={<Iconify icon={'eva:plus-fill'} />}
                sx={{ minWidth: 200 }}
                onClick={() => setShowCreateUserDialog(true)}
              >
                {t('Users_CreateNewUser')}
              </Button>
            ) : null}
          </UserTableToolbar>

          <Scrollbar>
            <TableContainer>
              <Table size={dense ? 'small' : 'medium'}>
                <TableHeadCustom order={order} orderBy={orderBy} headLabel={tableHead} onSort={onSort} />

                <TableBody>
                  {dataFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user) => (
                    <UserTableRow key={user.id} row={user} openUserDeleteDialog={() => setUserToDelete(user)} />
                  ))}

                  <TableEmptyRows
                    height={dense ? 52 : 72}
                    emptyRows={emptyRows(page, rowsPerPage, dataFiltered.length)}
                  />
                  {isNotFound && <TableSearchNotFound />}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePaginationStandard
            count={dataFiltered.length}
            rowsPerPage={rowsPerPage}
            page={page}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
          />
        </Card>
      </Container>

      {!loading && (
        <UserCreateDialog
          open={showCreateUserDialog}
          onClose={() => setShowCreateUserDialog(false)}
          users={data.user}
        />
      )}

      <UserDelete userToDelete={userToDelete} closeUserDeleteDialog={() => setUserToDelete(null)} />
    </Page>
  );
}
