import { unit_enum } from '@predium/enums';
import { TFunction } from 'i18next';
import { Trans } from 'react-i18next';

export const translateUnitEnum = (enumValue: unit_enum) => {
  switch (enumValue) {
    case unit_enum.CENTIGRADE:
      return <Trans i18nKey="Enum_UnitEnum-CENTIGRADE" />;
    case unit_enum.CENTIMETERS:
      return <Trans i18nKey="Enum_UnitEnum-CENTIMETERS" />;
    case unit_enum.CUBIC_METERS:
      return <Trans i18nKey="Enum_UnitEnum-CUBIC_METERS" />;
    case unit_enum.CUBIC_METERS_PER_SQM:
      return <Trans i18nKey="Enum_UnitEnum-CUBIC_METERS_PER_SQM" />;
    case unit_enum.DAYS:
      return <Trans i18nKey="Enum_UnitEnum-DAYS" />;
    case unit_enum.DEGREES:
      return <Trans i18nKey="Enum_UnitEnum-DEGREES" />;
    case unit_enum.EURO:
      return <Trans i18nKey="Enum_UnitEnum-EURO" />;
    case unit_enum.EURO_PER_KWH:
      return <Trans i18nKey="Enum_UnitEnum-EURO_PER_KWH" />;
    case unit_enum.EURO_PER_KW_PEAK:
      return <Trans i18nKey="Enum_UnitEnum-EURO_PER_KW_PEAK" />;
    case unit_enum.EURO_PER_SQM:
      return <Trans i18nKey="Enum_UnitEnum-EURO_PER_SQM" />;
    case unit_enum.EURO_PER_SQM_PER_YEAR:
      return <Trans i18nKey="Enum_UnitEnum-EURO_PER_SQM_PER_YEAR" />;
    case unit_enum.EURO_PER_YEAR:
      return <Trans i18nKey="Enum_UnitEnum-EURO_PER_YEAR" />;
    case unit_enum.G_CO2E:
      return <Trans i18nKey="Enum_UnitEnum-G_CO2E" />;
    case unit_enum.G_CO2E_PER_EURO_PER_YEAR:
      return <Trans i18nKey="Enum_UnitEnum-G_CO2E_PER_EURO_PER_YEAR" />;
    case unit_enum.G_CO2E_PER_KWH:
      return <Trans i18nKey="Enum_UnitEnum-G_CO2E_PER_KWH" />;
    case unit_enum.KILOGRAM:
      return <Trans i18nKey="Enum_UnitEnum-KILOGRAM" />;
    case unit_enum.KG_CO2E:
      return <Trans i18nKey="Enum_UnitEnum-KG_CO2E" />;
    case unit_enum.KG_CO2E_PER_KWH:
      return <Trans i18nKey="Enum_UnitEnum-KG_CO2E_PER_KWH" />;
    case unit_enum.KG_CO2E_PER_MWH:
      return <Trans i18nKey="Enum_UnitEnum-KG_CO2E_PER_MWH" />;
    case unit_enum.KG_CO2E_PER_SQM:
      return <Trans i18nKey="Enum_UnitEnum-KG_CO2E_PER_SQM" />;
    case unit_enum.KG_CO2E_PER_SQM_PER_YEAR:
      return <Trans i18nKey="Enum_UnitEnum-KG_CO2E_PER_SQM_PER_YEAR" />;
    case unit_enum.KG_CO2E_PER_YEAR:
      return <Trans i18nKey="Enum_UnitEnum-KG_CO2E_PER_YEAR" />;
    case unit_enum.KWH:
      return <Trans i18nKey="Enum_UnitEnum-KWH" />;
    case unit_enum.KWH_PER_SQM:
      return <Trans i18nKey="Enum_UnitEnum-KWH_PER_SQM" />;
    case unit_enum.KWH_PER_SQM_PER_YEAR:
      return <Trans i18nKey="Enum_UnitEnum-KWH_PER_SQM_PER_YEAR" />;
    case unit_enum.KWH_PER_YEAR:
      return <Trans i18nKey="Enum_UnitEnum-KWH_PER_YEAR" />;
    case unit_enum.KW_PEAK:
      return <Trans i18nKey="Enum_UnitEnum-KW_PEAK" />;
    case unit_enum.LUMENS:
      return <Trans i18nKey="Enum_UnitEnum-LUMENS" />;
    case unit_enum.LUMENS_PER_WATT:
      return <Trans i18nKey="Enum_UnitEnum-LUMENS_PER_WATT" />;
    case unit_enum.MWH:
      return <Trans i18nKey="Enum_UnitEnum-MWH" />;
    case unit_enum.METERS:
      return <Trans i18nKey="Enum_UnitEnum-METERS" />;
    case unit_enum.PERCENTAGE:
      return <Trans i18nKey="Enum_UnitEnum-PERCENTAGE" />;
    case unit_enum.SQM:
      return <Trans i18nKey="Enum_UnitEnum-SQM" />;
    case unit_enum.SQM_PER_YEAR:
      return <Trans i18nKey="Enum_UnitEnum-SQM_PER_YEAR" />;
    case unit_enum.TIMES:
      return <Trans i18nKey="Enum_UnitEnum-TIMES" />;
    case unit_enum.TONS:
      return <Trans i18nKey="Enum_UnitEnum-TONS" />;
    case unit_enum.TONS_PER_SQM:
      return <Trans i18nKey="Enum_UnitEnum-TONS_PER_SQM" />;
    case unit_enum.TONS_CO2E:
      return <Trans i18nKey="Enum_UnitEnum-TONS_CO2E" />;
    case unit_enum.WATT:
      return <Trans i18nKey="Enum_UnitEnum-WATT" />;
    case unit_enum.WATT_PER_M_PER_KELVIN:
      return <Trans i18nKey="Enum_UnitEnum-WATT_PER_M_PER_KELVIN" />;
    case unit_enum.WATT_PER_SQM_PER_KELVIN:
      return <Trans i18nKey="Enum_UnitEnum-WATT_PER_SQM_PER_KELVIN" />;
    case unit_enum.YEAR:
      return <Trans i18nKey="Enum_UnitEnum-YEAR" />;
  }
};

export const translateUnitEnum_dynamic = (enumValue: unit_enum, t: TFunction<'translation', undefined>) => {
  switch (enumValue) {
    case unit_enum.CENTIGRADE:
      return t('Enum_UnitEnum-CENTIGRADE');
    case unit_enum.CENTIMETERS:
      return t('Enum_UnitEnum-CENTIMETERS');
    case unit_enum.CUBIC_METERS:
      return t('Enum_UnitEnum-CUBIC_METERS');
    case unit_enum.CUBIC_METERS_PER_SQM:
      return t('Enum_UnitEnum-CUBIC_METERS_PER_SQM');
    case unit_enum.DAYS:
      return t('Enum_UnitEnum-DAYS');
    case unit_enum.DEGREES:
      return t('Enum_UnitEnum-DEGREES');
    case unit_enum.EURO:
      return t('Enum_UnitEnum-EURO');
    case unit_enum.EURO_PER_KWH:
      return t('Enum_UnitEnum-EURO_PER_KWH');
    case unit_enum.EURO_PER_KW_PEAK:
      return t('Enum_UnitEnum-EURO_PER_KW_PEAK');
    case unit_enum.EURO_PER_SQM:
      return t('Enum_UnitEnum-EURO_PER_SQM');
    case unit_enum.EURO_PER_SQM_PER_YEAR:
      return t('Enum_UnitEnum-EURO_PER_SQM_PER_YEAR');
    case unit_enum.EURO_PER_YEAR:
      return t('Enum_UnitEnum-EURO_PER_YEAR');
    case unit_enum.G_CO2E:
      return t('Enum_UnitEnum-G_CO2E');
    case unit_enum.G_CO2E_PER_EURO_PER_YEAR:
      return t('Enum_UnitEnum-G_CO2E_PER_EURO_PER_YEAR');
    case unit_enum.G_CO2E_PER_KWH:
      return t('Enum_UnitEnum-G_CO2E_PER_KWH');
    case unit_enum.KILOGRAM:
      return t('Enum_UnitEnum-KILOGRAM');
    case unit_enum.KG_CO2E:
      return t('Enum_UnitEnum-KG_CO2E');
    case unit_enum.KG_CO2E_PER_KWH:
      return t('Enum_UnitEnum-KG_CO2E_PER_KWH');
    case unit_enum.KG_CO2E_PER_MWH:
      return t('Enum_UnitEnum-KG_CO2E_PER_MWH');
    case unit_enum.KG_CO2E_PER_SQM:
      return t('Enum_UnitEnum-KG_CO2E_PER_SQM');
    case unit_enum.KG_CO2E_PER_SQM_PER_YEAR:
      return t('Enum_UnitEnum-KG_CO2E_PER_SQM_PER_YEAR');
    case unit_enum.KG_CO2E_PER_YEAR:
      return t('Enum_UnitEnum-KG_CO2E_PER_YEAR');
    case unit_enum.KWH:
      return t('Enum_UnitEnum-KWH');
    case unit_enum.KWH_PER_SQM:
      return t('Enum_UnitEnum-KWH_PER_SQM');
    case unit_enum.KWH_PER_SQM_PER_YEAR:
      return t('Enum_UnitEnum-KWH_PER_SQM_PER_YEAR');
    case unit_enum.KWH_PER_YEAR:
      return t('Enum_UnitEnum-KWH_PER_YEAR');
    case unit_enum.KW_PEAK:
      return t('Enum_UnitEnum-KW_PEAK');
    case unit_enum.LUMENS:
      return t('Enum_UnitEnum-LUMENS');
    case unit_enum.LUMENS_PER_WATT:
      return t('Enum_UnitEnum-LUMENS_PER_WATT');
    case unit_enum.MWH:
      return t('Enum_UnitEnum-MWH');
    case unit_enum.METERS:
      return t('Enum_UnitEnum-METERS');
    case unit_enum.PERCENTAGE:
      return t('Enum_UnitEnum-PERCENTAGE');
    case unit_enum.SQM:
      return t('Enum_UnitEnum-SQM');
    case unit_enum.SQM_PER_YEAR:
      return t('Enum_UnitEnum-SQM_PER_YEAR');
    case unit_enum.TIMES:
      return t('Enum_UnitEnum-TIMES');
    case unit_enum.TONS:
      return t('Enum_UnitEnum-TONS');
    case unit_enum.TONS_PER_SQM:
      return t('Enum_UnitEnum-TONS_PER_SQM');
    case unit_enum.TONS_CO2E:
      return t('Enum_UnitEnum-TONS_CO2E');
    case unit_enum.WATT:
      return t('Enum_UnitEnum-WATT');
    case unit_enum.WATT_PER_M_PER_KELVIN:
      return t('Enum_UnitEnum-WATT_PER_M_PER_KELVIN');
    case unit_enum.WATT_PER_SQM_PER_KELVIN:
      return t('Enum_UnitEnum-WATT_PER_SQM_PER_KELVIN');
    case unit_enum.YEAR:
      return t('Enum_UnitEnum-YEAR');
  }
};
