import { Button, Stack, Toolbar, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Iconify from '../../../components/Iconify';
import MultiSelectFilterWithSearch, { FilterItem } from '../../../components/search/MultiSelectFilterWithSearch';
import SearchField from '../../../components/search/SearchField';
import usePermissions from '../../../hooks/usePermissions';
import useSessionData from '../../../hooks/useSessionData';

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

// ----------------------------------------------------------------------
type Props = {
  numSelected: number;
  onFilterName: (value: string) => void;
  onCreateScenario: VoidFunction;
  portfolioOptions: FilterItem[];
  tempSelection: number[];
  handleSelectionChange: (values: (string | number)[]) => void;
  applySelection: () => void;
  clearSelection: () => void;
  isApplyDisabled: boolean;
  isClearDisabled: boolean;
  selectedPortfolios: (string | number)[];
  filterName: string;
};

export default function ScenariosTableToolbar({
  portfolioOptions,
  numSelected,
  onFilterName,
  onCreateScenario,
  tempSelection,
  handleSelectionChange,
  applySelection,
  clearSelection,
  isApplyDisabled,
  isClearDisabled,
  selectedPortfolios,
  filterName,
}: Props) {
  const { t } = useTranslation();
  const { isAdmin } = useSessionData();
  const { portfolios: portfolioPermissions } = usePermissions();
  const theme = useTheme();

  const isLight = theme.palette.mode === 'light';
  const displayCreateButton = isAdmin || portfolioPermissions?.some((permission) => permission.read);

  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: isLight ? 'primary.main' : 'text.primary',
          bgcolor: isLight ? 'primary.lighter' : 'primary.dark',
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {t('ScenariosTableToolbar_BuildingsSelectedCount', { count: numSelected })}
        </Typography>
      ) : (
        <Stack direction="row" gap={2} alignItems="center">
          <SearchField
            value={filterName}
            onChange={onFilterName}
            placeholder={t('ScenariosTableToolbar_SearchPortfolioPlaceholder')}
          />
          <MultiSelectFilterWithSearch
            items={portfolioOptions}
            selectedLabel={t('General_Portfolio_other')}
            label={t('General_AllPortfolios')}
            tempSelection={tempSelection}
            handleSelectionChange={handleSelectionChange}
            applySelection={applySelection}
            clearSelection={clearSelection}
            isApplyDisabled={isApplyDisabled}
            isClearDisabled={isClearDisabled}
            noResultsText={t('NoExistingPortfolios_info')}
            selectedFilter={selectedPortfolios}
            searchable
          />
        </Stack>
      )}

      {displayCreateButton && (
        <Button
          variant="contained"
          size="medium"
          startIcon={<Iconify icon={'eva:plus-fill'} width={20} height={20} />}
          onClick={onCreateScenario}
        >
          {t('General_NewScenario')}
        </Button>
      )}
    </RootStyle>
  );
}
