import { building_state_enum } from '@predium/enums';
import { PropsWithChildren, createContext, useState } from 'react';
import { DataCollectionTabActionsEnum, DataCollectionTabEnum } from '../sections/DataCollection/Buildings/TabsEnums';
import { ALL_PORTFOLIOS } from '../sections/EsgAnalysis/NavigationSelectors/PortfolioSelector';

export type DataCollectionBuildingTabState = { value: DataCollectionTabEnum; action?: DataCollectionTabActionsEnum };

type DataCollectionFilters = {
  selectedPortfolios: string[];
  selectedStatus: building_state_enum | 'all';
  filterName: string;
};

type AllDataCollectionFilters = {
  dataCollectionBuildingFilters: DataCollectionFilters;
  dataCollectionDraftsFilters: {
    orderedDraftsIdList: number[];
  } & Pick<DataCollectionFilters, 'selectedPortfolios' | 'filterName'>;
  dataCollectionEconomicUnitsFilters: Omit<DataCollectionFilters, 'selectedStatus'>;
};

type DataCollectionFiltersContextProps = {
  filters: AllDataCollectionFilters;
  setSelectedPortfolios: (
    filterType: 'building' | 'drafts' | 'economicUnits',
    selectedValues: string[] | ((prev: string[]) => string[]),
  ) => void;
  setSelectedStatusBuilding: (selectedStatus: building_state_enum | 'all') => void;
  setFilterNameBuilding: (name: string) => void;
  setFilterNameEconomicUnit: (economicUnit: string) => void;
  setOrderedDraftsIdList: (orderedDraftsIdList: number[]) => void;
  resetAllFilters: () => void;
  setFilterAddressDrafts: (address: string) => void;
};

const initialFilters: AllDataCollectionFilters = {
  dataCollectionBuildingFilters: {
    selectedPortfolios: [ALL_PORTFOLIOS],
    selectedStatus: 'all',
    filterName: '',
  },
  dataCollectionDraftsFilters: {
    selectedPortfolios: [ALL_PORTFOLIOS],
    orderedDraftsIdList: [],
    filterName: '',
  },
  dataCollectionEconomicUnitsFilters: {
    selectedPortfolios: [ALL_PORTFOLIOS],
    filterName: '',
  },
};

const DataCollectionFiltersContext = createContext<DataCollectionFiltersContextProps | null>(null);

function DataCollectionFilterContextProvider({ children }: PropsWithChildren) {
  const [filters, setFilters] = useState<AllDataCollectionFilters>(initialFilters);
  const filterKeyMap = {
    building: 'dataCollectionBuildingFilters',
    drafts: 'dataCollectionDraftsFilters',
    economicUnits: 'dataCollectionEconomicUnitsFilters',
  } as const;

  // TODO PRE-2034
  const resetAllFilters = () => {
    setFilters(initialFilters);
  };

  const setSelectedPortfolios = (
    filterType: keyof typeof filterKeyMap,
    selectedValues: string[] | ((prev: string[]) => string[]),
  ) => {
    setFilters((prevFilters) => {
      const key = filterKeyMap[filterType];

      const newSelectedPortfolios =
        typeof selectedValues === 'function' ? selectedValues(prevFilters[key].selectedPortfolios) : selectedValues;

      return {
        ...prevFilters,
        [key]: {
          ...prevFilters[key],
          selectedPortfolios: newSelectedPortfolios,
        },
      };
    });
  };

  const setOrderedDraftsIdList = (orderedDraftsIdList: number[]) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      dataCollectionDraftsFilters: {
        ...prevFilters.dataCollectionDraftsFilters,
        orderedDraftsIdList,
      },
    }));
  };

  const setFilterAddressDrafts = (address: string) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      dataCollectionDraftsFilters: {
        ...prevFilters.dataCollectionDraftsFilters,
        filterName: address,
      },
    }));
  };

  const setSelectedStatusBuilding = (selectedStatus: building_state_enum | 'all') => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      dataCollectionBuildingFilters: {
        ...prevFilters.dataCollectionBuildingFilters,
        selectedStatus,
      },
    }));
  };

  const setFilterNameBuilding = (filterName: string) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      dataCollectionBuildingFilters: {
        ...prevFilters.dataCollectionBuildingFilters,
        filterName,
      },
    }));
  };

  const setFilterNameEconomicUnit = (filterName: string) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      dataCollectionEconomicUnitsFilters: {
        ...prevFilters.dataCollectionEconomicUnitsFilters,
        filterName,
      },
    }));
  };

  return (
    <DataCollectionFiltersContext.Provider
      value={{
        filters,
        setSelectedStatusBuilding,
        setFilterNameBuilding,
        setFilterNameEconomicUnit,
        setSelectedPortfolios,
        setOrderedDraftsIdList,
        resetAllFilters,
        setFilterAddressDrafts,
      }}
    >
      {children}
    </DataCollectionFiltersContext.Provider>
  );
}

export { DataCollectionFilterContextProvider, DataCollectionFiltersContext };
