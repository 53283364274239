import { useQuery } from '@apollo/client';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useTheme } from '@mui/material';
import { translateBuildingStateEnum, translateTypeOfUseEnum_dynamic } from '@predium/i18n/client';
import { getEbfTypesOfUse, getNetArea } from '@predium/utils';
import { t } from 'i18next';
import { Link } from 'react-router-dom';
import { preventRedirect } from '../../../components/data-table';
import { GET_BUILDINGS_BY_ECONOMIC_UNIT_ID } from '../../../graphql/DataCollection.queries';
import { PATHS } from '../../../routes';
import BuildingActionMenu from '../Building/BuildingActionMenu';
import { type HeadLabel } from './EconomicUnitsHead';

type Props = {
  economicUnitId: number;
  isEconomicUnitSelected: boolean;
};

const EconomicUnitsListBuildingsList = ({ economicUnitId, isEconomicUnitSelected }: Props) => {
  const theme = useTheme();

  const { data } = useQuery(GET_BUILDINGS_BY_ECONOMIC_UNIT_ID, {
    variables: {
      economicUnitId,
    },
  });

  const buildingsColumns: HeadLabel[] = [
    { id: 'street', label: t('General_Street') },
    { id: 'typesOfUse', label: t('General_TypeOfUse') },
    { id: 'year', label: t('General_ConstructionYear') },
    { id: 'area', label: t('General_Area') },
    { id: 'status', label: t('General_Status') },
    { id: 'action_menu', label: '' },
  ];

  if (!data) {
    return <Box height="150px" />;
  }

  const buildingsCount = data.building.length;

  return (
    <TableContainer
      sx={{
        border: `1px solid ${theme.palette.grey[500_32]}`,
        borderRadius: '8px',
        width: 'auto',
        ml: '110px',
        my: 2,
      }}
    >
      <Table>
        <colgroup>
          <col width="31%"></col>
          <col width="16%"></col>
          <col width="16%"></col>
          <col width="16%"></col>
          <col width="16%"></col>
          <col></col>
        </colgroup>
        <TableHead>
          <TableRow>
            {buildingsColumns.map((column) => (
              <TableCell key={column.id}>{column.label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.building.map((building) => (
            <TableRow
              key={building.id}
              hover
              selected={isEconomicUnitSelected}
              component={Link}
              to={PATHS.dataCollection.building(building)}
              sx={{
                textDecoration: 'none',
                cursor: 'pointer',
                '& > *': { borderBottom: 'unset' },
              }}
            >
              <TableCell sx={{ pl: 4 }}>{building.address.street}</TableCell>
              <TableCell>
                {getEbfTypesOfUse(building.areas)
                  .map((type) => translateTypeOfUseEnum_dynamic(type, t))
                  .join(', ')}
              </TableCell>
              <TableCell>{building.year_constructed}</TableCell>
              <TableCell>{getNetArea(building.areas)} m2</TableCell>
              <TableCell>{translateBuildingStateEnum(building.building_state_id)}</TableCell>
              <TableCell onClick={preventRedirect}>
                <BuildingActionMenu
                  economicUnitId={economicUnitId}
                  currentAmountBuildingsInEu={buildingsCount}
                  buildingId={building.id}
                  street={building.address.street}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default EconomicUnitsListBuildingsList;
