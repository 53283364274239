/* eslint-disable @typescript-eslint/ban-ts-comment */
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, ListItemIcon, MenuItem } from '@mui/material';
import { energy_source_type_enum } from '@predium/client-graphql';
import { forwardRef, useImperativeHandle } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormProvider, RHFSelect } from '../../../../../../components/hook-form';
import InfoTable, { InfoTableData } from '../../../../../../components/presentations/InfoTable';
import { BuildingTabEnum } from '../../../../../../pages/DataCollection/DataCollectionBuilding';
import { useLanguage } from '../../../../../../provider/LanguageProvider';
import { getActionIcon } from '../../../../../../utils/icons';
import { createEnergyInfoTable } from '../../../../Scenario/scenarios.util';
import { getMinDate } from '../../../ActionPlan.utils';
import { SelectSx, StyledListItemText } from '../../../Components/ActionCustomStyles';
import {
  energyRouteOptions,
  getEnergyRouteTypeEnumTitle,
  routeOptionExclusions,
  SupportedEnergySources,
} from '../../CreateActions/TechnicalActions/EnergyRouteAction';
import { EnergyRouteActionFormSchema } from '../../CreateActions/validation-schema';
import { availableOptions, TechnicalActionEditProps } from '../TechnicalAction';

export type EnergyRouteActionForm = {
  energy_system_route_renovation_parameter: {
    energy_source_type: energy_source_type_enum;
  };
  implementation_to: DateTime | '';
};

const EnergyRouteAction = forwardRef(
  (
    {
      previousActionBuildingModel,
      resetSimulatedData,
      loading,
      parameters,
      implementationTo,
      implementationToComponent,
      isRecommendedAction,
    }: TechnicalActionEditProps & {
      parameters: EnergyRouteActionForm['energy_system_route_renovation_parameter'];
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const { language, localize } = useLanguage();

    const { energySources } = availableOptions(previousActionBuildingModel);
    const buildingId = previousActionBuildingModel.building!.id;
    const defaultEnergySource =
      parameters.energy_source_type === energy_source_type_enum.DISTRICT_HEATING_CHP_RENEWABLE
        ? energy_source_type_enum.DISTRICT_HEATING_PLANTS_RENEWABLE // the dropdown only has one option for district heating and it will be disable
        : parameters.energy_source_type;

    const minimumDate = isRecommendedAction ? implementationTo : getMinDate();

    const defaultValues: EnergyRouteActionForm = {
      implementation_to: implementationTo,
      energy_system_route_renovation_parameter: {
        energy_source_type: defaultEnergySource,
      },
    };

    const formMethods = useForm<EnergyRouteActionForm>({
      mode: 'onChange',
      reValidateMode: 'onChange',
      defaultValues: defaultValues,
      resolver: yupResolver(EnergyRouteActionFormSchema(minimumDate, language)),
    });
    const { watch, setValue } = formMethods;

    useImperativeHandle(ref, () => ({
      methods: formMethods,
    }));

    const energySource = watch('energy_system_route_renovation_parameter.energy_source_type');

    const infoTableData: InfoTableData[] | null = createEnergyInfoTable(
      energySource,
      previousActionBuildingModel,
      t,
      localize,
    );

    const getRouteOptionAvailability = (routeOptionType: energy_source_type_enum) => {
      const exclusions = routeOptionExclusions[routeOptionType];
      return exclusions ? !exclusions.some((exclusion) => energySources.includes(exclusion)) : true;
    };

    const isAlreadyRenovated = (energySource: energy_source_type_enum) => {
      return energySource === defaultEnergySource;
    };

    const handleEnergySourceChange = (value: SupportedEnergySources) => {
      resetSimulatedData();

      setValue('energy_system_route_renovation_parameter.energy_source_type', value);
    };

    return (
      <FormProvider methods={formMethods}>
        <Box my={3}>
          <RHFSelect
            name="energy_system_route_renovation_parameter.energy_source_type"
            label={t('General_EnergySource')}
            size={'small'}
            sx={{ ...SelectSx, mb: 3 }}
            disabled={loading}
            onValueChange={handleEnergySourceChange}
          >
            {energyRouteOptions.map((routeType: SupportedEnergySources) => (
              <MenuItem
                key={routeType}
                value={routeType}
                disabled={getRouteOptionAvailability(routeType) || isAlreadyRenovated(routeType)}
              >
                <ListItemIcon style={{ minWidth: '16px' }}>
                  <Box component="img" src={getActionIcon(routeType)} />
                </ListItemIcon>
                <StyledListItemText action={getEnergyRouteTypeEnumTitle(routeType as SupportedEnergySources)} />
              </MenuItem>
            ))}
          </RHFSelect>

          {infoTableData && (
            <Box mb={3}>
              <InfoTable
                buildingId={buildingId}
                showTab={BuildingTabEnum.technology}
                data={infoTableData}
                title={t('General_StateBeforeRenovation')}
              />
            </Box>
          )}

          {implementationToComponent}
        </Box>
      </FormProvider>
    );
  },
);

export default EnergyRouteAction;
