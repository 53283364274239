/* eslint-disable @typescript-eslint/ban-ts-comment */
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, MenuItem, useTheme } from '@mui/material';
import { BuildingModelFragment, EnvelopeUnitFragment, envelope_type_enum } from '@predium/client-graphql';
import { getSolarRenovationTemplates } from '@predium/client-lookup';
import { translateUnitEnum_dynamic } from '@predium/i18n/client';
import { Units } from '@predium/utils';
import get from 'lodash/get';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormProvider, RHFNumberField, RHFSelect } from '../../../../../../components/hook-form';
import InfoTable, { InfoTableData } from '../../../../../../components/presentations/InfoTable';
import { BuildingTabEnum } from '../../../../../../pages/DataCollection/DataCollectionBuilding';
import { useLanguage } from '../../../../../../provider/LanguageProvider';
import { createSolarInfoTable } from '../../../../Scenario/scenarios.util';
import { getIsCostAvailableForTechnology, getMinDate } from '../../../ActionPlan.utils';
import { NumberInputSx, SelectSx, StyledListItemText } from '../../../Components/ActionCustomStyles';
import { SetValueOptions, SolarActionFormSchema } from '../../CreateActions/validation-schema';

const DEFAULT_ROOF_AREA_RATIO = 80;

export type SolarActionForm = {
  implementation_to: DateTime | '';
  solar_renovation_parameter: {
    module_peak_power: number | undefined;
    module_angle: number | undefined;
    solar_active_area: number | undefined;
    roof_area_ratio: number | undefined;
    cost_per_m2?: number;
    technology_name: string;
  };
};

export type SolarActionSchemaType = {
  solar_renovation_parameter: {
    module_peak_power: number;
    module_angle: number;
    solar_active_area: number;
    roof_area_ratio: number;
    cost_per_m2: number;
    technology_name: string;
  };
  implementation_to: DateTime;
};

const SolarAction = forwardRef(
  (
    {
      previousActionBuildingModel,
      resetSimulatedData,
      loading,
      parameters,
      implementationTo,
      implementationToComponent,
      isRecommendedAction,
    }: {
      previousActionBuildingModel: BuildingModelFragment;
      resetSimulatedData: () => void;
      loading: boolean;
      parameters: SolarActionForm['solar_renovation_parameter'];
      implementationTo: Date;
      implementationToComponent: JSX.Element;
      isRecommendedAction: boolean;
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { language, localize } = useLanguage();

    const updateSource = useRef<'area' | 'ratio' | null>(null);

    const envelopes = previousActionBuildingModel.envelope_units;
    const buildingId = previousActionBuildingModel.building!.id;

    const solarTemplates = Object.values(getSolarRenovationTemplates());
    const allParameters = solarTemplates;
    const technologyNames = solarTemplates.map((value) => value.technology_name);

    const maxRoofArea = Math.floor(
      envelopes
        .filter((envelope: EnvelopeUnitFragment) =>
          [
            envelope_type_enum.FLAT_ROOF,
            envelope_type_enum.PITCHED_ROOF,
            envelope_type_enum.TOP_FLOOR_CEILING,
          ].includes(envelope.envelope_type_id),
        )
        .reduce((acc: number, curr: EnvelopeUnitFragment) => acc + curr.area, 0),
    );

    const infoTableData: InfoTableData[] = createSolarInfoTable(maxRoofArea, previousActionBuildingModel, t, localize);

    const defaultSolarActiveArea = Math.floor((maxRoofArea * DEFAULT_ROOF_AREA_RATIO) / 100);

    const defaultValues: SolarActionForm = {
      solar_renovation_parameter: parameters,

      implementation_to: implementationTo,
    };

    const minimumDate = isRecommendedAction ? implementationTo : getMinDate();

    const formMethods = useForm<SolarActionForm>({
      mode: 'onChange',
      reValidateMode: 'onChange',
      defaultValues: defaultValues,
      resolver: yupResolver(SolarActionFormSchema(minimumDate, language, maxRoofArea)),
    });
    const { watch, setValue } = formMethods;

    useImperativeHandle(ref, () => ({
      methods: formMethods,
    }));

    const technologyName = watch('solar_renovation_parameter.technology_name');
    const solarActiveArea = watch('solar_renovation_parameter.solar_active_area');
    const roofAreaRatio = watch('solar_renovation_parameter.roof_area_ratio');

    const handleNameChange = (selectedName: string) => {
      resetSimulatedData();
      const parameters = allParameters.find((material) => material.technology_name === selectedName);
      setValue('solar_renovation_parameter.cost_per_m2', get(parameters, 'cost_per_m2', 0), SetValueOptions);
      setValue(
        'solar_renovation_parameter.module_peak_power',
        get(parameters, 'module_peak_power', 0),
        SetValueOptions,
      );
      setValue('solar_renovation_parameter.module_angle', 45, SetValueOptions);
      setValue('solar_renovation_parameter.solar_active_area', defaultSolarActiveArea, SetValueOptions);
    };

    const handleSolarActiveAreaChange = (rawValue: number | undefined) => {
      if (!rawValue) return;

      if (updateSource.current !== 'ratio') {
        const newRoofAreaRatio = Math.floor((rawValue / maxRoofArea) * 100);
        if (newRoofAreaRatio !== roofAreaRatio) {
          updateSource.current = 'area';
          setValue('solar_renovation_parameter.roof_area_ratio', newRoofAreaRatio, SetValueOptions);
          setTimeout(() => {
            // important as ratio and area are dependent on each other
            updateSource.current = null;
          }, 0);
        }
      }
    };

    const handleSolarRatioChange = (rawValue: number | undefined) => {
      if (!rawValue) return;

      if (updateSource.current !== 'area') {
        const newSolarActiveArea = Math.floor((rawValue / 100) * maxRoofArea);
        if (newSolarActiveArea !== solarActiveArea) {
          updateSource.current = 'ratio';
          setValue('solar_renovation_parameter.solar_active_area', newSolarActiveArea, SetValueOptions);
          setTimeout(() => {
            // important as ratio and area are dependent on each other
            updateSource.current = null;
          }, 0);
        }
      }
    };

    return (
      <FormProvider methods={formMethods}>
        <Box my={3}>
          {infoTableData && (
            <Box mb={3}>
              <InfoTable
                buildingId={buildingId}
                showTab={BuildingTabEnum.technology}
                data={infoTableData}
                title={t('General_StateBeforeRenovation')}
              />
            </Box>
          )}

          <Box
            sx={{
              p: technologyName ? 2 : 0,
              border: technologyName ? `1px solid ${theme.palette.grey[500_32]}` : 0,
              borderRadius: '8px',
              mb: 3,
            }}
          >
            <RHFSelect
              name="solar_renovation_parameter.technology_name"
              label={t('General_Technology')}
              size={'small'}
              sx={{ ...SelectSx, pb: 0, mb: 0 }}
              disabled={loading}
              onValueChange={handleNameChange}
            >
              {technologyNames.map((name) => (
                <MenuItem key={name} value={name}>
                  <StyledListItemText
                    action={name}
                    secondary={
                      getIsCostAvailableForTechnology(name, allParameters)
                        ? translateUnitEnum_dynamic(Units.price, t)
                        : ''
                    }
                  />
                </MenuItem>
              ))}
            </RHFSelect>

            {technologyName && (
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <RHFNumberField
                    name="solar_renovation_parameter.module_peak_power"
                    label={t('CreateNewTechnicalAction_ModulePeakPower')}
                    size={'small'}
                    sx={{ ...NumberInputSx, mt: 2 }}
                    disabled={loading}
                    onValueChange={() => {
                      resetSimulatedData();
                    }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <RHFNumberField
                    name="solar_renovation_parameter.module_angle"
                    label={t('CreateNewTechnicalAction_ModuleAngle')}
                    size={'small'}
                    sx={{ ...NumberInputSx, mt: 2 }}
                    disabled={loading}
                    onValueChange={() => {
                      resetSimulatedData();
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <RHFNumberField
                    name="solar_renovation_parameter.solar_active_area"
                    label={t('CreateNewTechnicalAction_SolarActiveArea')}
                    size={'small'}
                    sx={NumberInputSx}
                    disabled={loading}
                    onValueChange={(values) => {
                      resetSimulatedData();
                      handleSolarActiveAreaChange(values.floatValue);
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <RHFNumberField
                    name="solar_renovation_parameter.roof_area_ratio"
                    label={t('CreateNewTechnicalAction_RoofAreaRatio')}
                    size={'small'}
                    sx={NumberInputSx}
                    disabled={loading}
                    onValueChange={(values) => {
                      resetSimulatedData();
                      handleSolarRatioChange(values.floatValue);
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <RHFNumberField
                    name="solar_renovation_parameter.cost_per_m2"
                    label={t('General_PricePerM2KwPeak')}
                    size={'small'}
                    sx={NumberInputSx}
                    onValueChange={() => {
                      resetSimulatedData();
                    }}
                    disabled
                  />
                </Grid>
              </Grid>
            )}
          </Box>
          {implementationToComponent}
        </Box>
      </FormProvider>
    );
  },
);

export default SolarAction;
