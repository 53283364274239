import { Box, Stack } from '@mui/material';
import Link from '@mui/material/Link';
import isString from 'lodash/isString';
import { ReactNode } from 'react';
import MetaInformation, { MetaInformationItem } from '../sections/EsgAnalysis/Components/MetaInformation';
import Breadcrumbs, { Props as BreadcrumbsProps } from './Breadcrumbs';
import InfoTooltip from './InfoTooltip';
import OverflowText from './OverflowText';

interface Props extends BreadcrumbsProps {
  action?: ReactNode;
  heading: string | ReactNode;
  moreLink?: string[];
  infoText?: string;
  navigationSelector?: ReactNode;
  metadata?: MetaInformationItem[];
}

export default function HeaderBreadcrumbs({
  links,
  action,
  heading,
  infoText,
  moreLink = '' || [],
  metadata,
  sx,
  navigationSelector,
  ...other
}: Props) {
  return (
    <Box sx={{ mb: 5, ...sx }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ flexGrow: 1 }}>
          {links.length > 0 && <Breadcrumbs links={links} {...other} />}

          <Stack direction="row" alignItems="center" sx={{ pb: 0.5 }}>
            {typeof heading === 'string' ? <OverflowText maxWidth={800} text={heading} variant={'h4'} /> : heading}
            {infoText && <InfoTooltip text={infoText} maxwidth={800} />}
            {navigationSelector}
          </Stack>
          {metadata && metadata.length > 0 && (
            <Stack>
              <MetaInformation items={metadata} />
            </Stack>
          )}
        </Box>

        {action && <Box sx={{ flexShrink: 0 }}>{action}</Box>}
      </Box>

      <Box sx={{ mt: 2 }}>
        {isString(moreLink) ? (
          <div />
        ) : (
          moreLink.map((href) => (
            <Link
              noWrap
              key={href}
              href={href}
              variant="body2"
              target="_blank"
              rel="noopener"
              sx={{ display: 'table' }}
            >
              {href}
            </Link>
          ))
        )}
      </Box>
    </Box>
  );
}
