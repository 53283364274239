import dayjs from 'dayjs';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import RHFDatePicker, { getUtcValue } from '../../../../../../components/hook-form/RHFDatePicker';
import { getMaxDate, getMinDate } from '../../../ActionPlan.utils';
import { NumberInputSx } from '../../../Components/ActionCustomStyles';
import { SetValueOptions } from '../../CreateActions/validation-schema';

type Props = {
  resetSimulatedData: () => void;
  refetchStatusQuoOnDateChange?: (date: Date) => void;
  minimumDate?: Date;
  disabled?: boolean;
};

const ImplementationToDate: React.FC<Props> = ({
  resetSimulatedData,
  refetchStatusQuoOnDateChange,
  minimumDate,
  disabled = false,
}) => {
  const { t } = useTranslation();
  const { setValue } = useFormContext();

  return (
    <RHFDatePicker
      label={t('General_PlannedFor')}
      name={'implementation_to'}
      size={'small'}
      sx={NumberInputSx}
      minDate={minimumDate ?? getMinDate()}
      maxDate={getMaxDate()}
      disabled={disabled}
      onChange={(date) => {
        setValue('implementation_to', date, SetValueOptions);

        resetSimulatedData();
        if (date && dayjs(date).isValid()) {
          const newDate = getUtcValue(date);

          refetchStatusQuoOnDateChange?.(newDate);
        }
      }}
    />
  );
};

export default ImplementationToDate;
