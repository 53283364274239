import { Stack, TableCell, TableRow, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Iconify from '../../../../../components/Iconify';
import { RISK_CATEGORY_ICONS, RiskCategory, translateRiskCategory } from '../mapping';

const RiskCategoryRow = ({ riskCategory, colSpan }: { riskCategory: RiskCategory; colSpan: number }) => {
  const { t } = useTranslation();

  return (
    <TableRow>
      <TableCell colSpan={colSpan} sx={{ bgcolor: 'grey.100', py: 1 }}>
        <Stack alignItems="center" direction="row" spacing={1}>
          <Iconify icon={RISK_CATEGORY_ICONS[riskCategory]} height={22} width={22} color="text.secondary" />
          <Typography variant="subtitle2" color="text.secondary" fontSize={12}>
            {translateRiskCategory(riskCategory, t)}
          </Typography>
        </Stack>
      </TableCell>
    </TableRow>
  );
};

export default RiskCategoryRow;
