import { useEffect, useMemo } from 'react';
import { SEARCH_PARAMS, useTypeSafeSearchParams } from '../routes';
import { DataCollectionTabEnum } from '../sections/DataCollection/Buildings/TabsEnums';
import useOrgPreferences from './useOrgPreferences';

const useDataCollectionTabs = () => {
  const { searchParams, setSearchParams } = useTypeSafeSearchParams(SEARCH_PARAMS.dataCollection.buildings);
  const { economicUnitsToggled: isEconomicUnitsToggled, loading: isLoading } = useOrgPreferences();

  const currentTab = useMemo(() => {
    if (isLoading) {
      return searchParams.tab;
    }

    if (searchParams.tab && (isEconomicUnitsToggled || DataCollectionTabEnum.ECONOMIC_UNITS !== searchParams.tab)) {
      return DataCollectionTabEnum[searchParams.tab];
    }

    const tab = isEconomicUnitsToggled ? DataCollectionTabEnum.ECONOMIC_UNITS : DataCollectionTabEnum.BUILDINGS;

    return tab;
  }, [isEconomicUnitsToggled, searchParams.tab, isLoading]);

  useEffect(() => {
    setSearchParams({ tab: currentTab }, { replace: true });
  }, [currentTab, setSearchParams]);

  return {
    currentTab,
    currentAction: searchParams.action,
    isEconomicUnitsToggled,
    setCurrentTab: (nextTab: DataCollectionTabEnum) => setSearchParams({ tab: nextTab }),
  };
};

export default useDataCollectionTabs;
