import {
  building_state_enum,
  efficiency_class_enum,
  energy_source_type_enum,
  eu_taxonomy_compliance_enum,
  sub_building_class_enum,
  tax_bracket_enum,
  type_of_use_enum,
} from '@predium/enums';
import {
  translateBuildingStateEnum,
  translateEfficiencyClassEnum,
  translateEnergySourceTypeEnum,
  translateEuTaxonomyComplianceEnum,
  translateSubBuildingClassEnum,
  translateTaxBracketEnum,
  translateTypeOfUseEnum,
} from '@predium/i18n/client';
import { memo } from 'react';
import { Trans } from 'react-i18next';
import { EsgAnalysisFiltersKeys, EsgAnalysisFiltersValues } from './EsgAnalysisFilters';

type Props = {
  type: EsgAnalysisFiltersKeys;
  value: EsgAnalysisFiltersValues[number];
};

const EsgAnalysisFilterValueLabel = memo(({ type, value }: Props) => {
  switch (type) {
    case EsgAnalysisFiltersKeys.subBuildingClasses:
      return translateSubBuildingClassEnum(value as sub_building_class_enum);
    case EsgAnalysisFiltersKeys.typeOfUses:
      return translateTypeOfUseEnum(value as type_of_use_enum);
    case EsgAnalysisFiltersKeys.efficiencyClasses:
      return translateEfficiencyClassEnum(value as efficiency_class_enum);
    case EsgAnalysisFiltersKeys.energySourceTypes:
      return translateEnergySourceTypeEnum(value as energy_source_type_enum);
    case EsgAnalysisFiltersKeys.taxBrackets:
      return translateTaxBracketEnum(value as tax_bracket_enum);
    case EsgAnalysisFiltersKeys.euTaxonomyCompliances:
      return translateEuTaxonomyComplianceEnum(value as eu_taxonomy_compliance_enum);
    case EsgAnalysisFiltersKeys.buildingStates:
      return translateBuildingStateEnum(value as building_state_enum);
    case EsgAnalysisFiltersKeys.monumentProtections:
    case EsgAnalysisFiltersKeys.heritageDistrict:
    case EsgAnalysisFiltersKeys.milieuProtection:
    case EsgAnalysisFiltersKeys.leasehold:
      return value ? <Trans i18nKey="General_Yes" /> : <Trans i18nKey="General_No" />;
    default:
      throw new Error(`Unhandled esg analysis filter label for "${type}" type`);
  }
});

export default EsgAnalysisFilterValueLabel;
