/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useQuery } from '@apollo/client';
import { Card, Divider, Stack, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import {
  ActionPlanningActionSubsidyFragment,
  SubsidyInput,
  action_subsidy_value_type_enum,
  renovation_type_enum,
} from '@predium/client-graphql';
import { Units } from '@predium/utils';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { createStandardErrorSnackbar } from '../../../../../../components/NotistackProvider';
import { GET_ACTIONPLAN } from '../../../../../../graphql/ActionPlanning.queries';
import { useLanguage } from '../../../../../../provider/LanguageProvider';
import { AddSubsidyModal, isSubsidyApplicableToAction } from '../AddSubsidy/AddSubsidyModal';
import ExpectedSubsidyFunding from '../AddSubsidy/ExpectedSubsidyFunding';
import RowCosts, { CustomCostsForm } from './RowCosts';
import { RowEstimatedCosts } from './RowEstimatedCosts';

type Props = {
  totalRenovationCost: number;
  totalCostEstimated: number;
  title: string;
  showAddSubsidyButton?: boolean;
  onAddSubsidy?: (data: SubsidyInput[]) => void;
  actionSubsidies: Omit<ActionPlanningActionSubsidyFragment, 'id'>[];
  renovationType?: renovation_type_enum;
  showUseOwnCostsButton?: boolean;
  renovationArea?: number;
  setIsCustomCostLowerThanSubsidies?: (value: boolean) => void;
  forceCustomCost?: boolean;
  showResetButton?: boolean;
};

export default function ActionPricingTotalCost({
  title,
  totalRenovationCost,
  totalCostEstimated,
  onAddSubsidy,
  showAddSubsidyButton = false,
  actionSubsidies,
  renovationType,
  renovationArea,
  showUseOwnCostsButton = false,
  setIsCustomCostLowerThanSubsidies,
  forceCustomCost = false,
  showResetButton = true,
}: Props) {
  const { t } = useTranslation();
  const { localize } = useLanguage();
  const { enqueueSnackbar } = useSnackbar();

  const { id: actionPlanIdString } = useParams();
  const displayStandardMutationQueryErrorSnackbar = createStandardErrorSnackbar(t);

  const actionPlanId = parseInt(actionPlanIdString ?? '-1'); //TODO: why -1? need a comment here

  const { data: actionPlanData } = useQuery(GET_ACTIONPLAN, {
    variables: { actionPlanId },
    onError: () => displayStandardMutationQueryErrorSnackbar(enqueueSnackbar),
    skip: !actionPlanId || actionPlanId < 0,
  });

  const subBuilding = actionPlanData?.action_plan_by_pk?.building.sub_buildings[0];
  const subBuildingSubsidies = subBuilding?.subsidies.map((item) => item.subsidy) ?? [];
  const applicableSubsidies = subBuildingSubsidies.filter((item) => isSubsidyApplicableToAction(item, renovationType));
  // we need to make sure that the actual action costs from the DB are used in the Financial KPIs overview
  const useActionCostsInDB = !showUseOwnCostsButton;

  const methods = useFormContext<CustomCostsForm>();

  const cost_total_custom = methods.watch('cost_total_custom');
  const use_custom_cost = methods.watch('use_custom_cost');

  const totalOrCustomTotal = use_custom_cost ? cost_total_custom : totalCostEstimated ?? 0;

  const totalExpectedSubsidyAmount = useMemo(() => {
    return actionSubsidies.reduce((acc, curr) => {
      const totalActionCost = useActionCostsInDB
        ? curr.action.renovations.reduce((acc, curr) => acc + (curr.cost_total ?? 0), 0)
        : totalOrCustomTotal;
      if (curr.type === action_subsidy_value_type_enum.percentage) {
        return acc + (totalActionCost ?? 0) * (curr.value / 100);
      }
      return acc + curr.value;
    }, 0);
  }, [actionSubsidies, totalOrCustomTotal, useActionCostsInDB]);

  const ownCostShare = Math.max((totalOrCustomTotal ?? 0) - totalExpectedSubsidyAmount, 0);
  const formattedOwnCostShare = localize.formatAsCurrency(ownCostShare !== 0 ? ownCostShare : null, {
    fractionDigits: 0,
  });

  const pricePerM2 = ((totalOrCustomTotal ?? 0) - totalExpectedSubsidyAmount) / (renovationArea ?? 1);
  const formattedPricePerM2 = localize.formatAsCurrency(pricePerM2 !== 0 ? pricePerM2 : null, {
    perUnit: Units.area,
    fractionDigits: 0,
  });

  const customCostLowerThanSubsidies = () => {
    const cost = !!cost_total_custom ? cost_total_custom : totalRenovationCost ?? 0; //TODO : confirm data is simulated
    // cost
    const totalSubsidy = actionSubsidies.reduce((acc, curr) => {
      const totalActionCost = curr.action.renovations.reduce((acc, curr) => acc + (curr.cost_total ?? 0), 0);
      if (curr.type === action_subsidy_value_type_enum.percentage) {
        return acc + (totalActionCost ?? 0) * (curr.value / 100);
      }
      return acc + curr.value;
    }, 0);

    const isCustomCostLowerThanSubsidies = cost < totalSubsidy;
    setIsCustomCostLowerThanSubsidies?.(isCustomCostLowerThanSubsidies);

    return isCustomCostLowerThanSubsidies;
  };

  const errorMessage = customCostLowerThanSubsidies()
    ? t('ActionPlanning_SubsidiesAddSubsidyForm-higherThanCostError')
    : undefined;

  return (
    <Card sx={{ mb: 2, height: '100%' }} key={title}>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} px={3} pt={3}>
        <Typography variant="h6">{title}</Typography>
      </Stack>
      <TableContainer component={Paper} sx={{ p: 3, minHeight: 40, pb: 0, overflow: 'hidden' }}>
        <Table
          sx={{
            '.MuiTableCell-root': {
              p: 'unset',
            },
          }}
        >
          <TableBody sx={{ width: '100%' }}>
            {useActionCostsInDB ? (
              <RowEstimatedCosts estimatedCost={totalCostEstimated} />
            ) : (
              <RowCosts
                renovationArea={renovationArea ?? 0}
                totalCostEstimated={totalCostEstimated ?? undefined}
                forceCustomCost={forceCustomCost}
                showResetButton={showResetButton}
              />
            )}

            {showAddSubsidyButton && actionSubsidies.length === 0 ? (
              // ADD SUBSIDIES
              <>
                <TableRow>
                  <TableCell colSpan={9}>
                    <Divider sx={{ my: 1 }} />
                  </TableCell>
                </TableRow>
                <TableRow sx={{ '& > *': { borderBottom: 'unset', whiteSpace: 'nowrap', width: '100%', p: 0 } }}>
                  <TableCell colSpan={9}>
                    <Stack
                      display={'flex'}
                      flexDirection={'row'}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                      py={1}
                    >
                      <Stack display={'flex'} flexDirection={'column'}>
                        <Typography variant="subtitle2" fontWeight={700}>
                          {t('General_Subsidy', { count: 2 })}
                        </Typography>
                        <Typography variant="caption" color="text.secondary">
                          {t('ActionPlanningSubsidies_AddSubsidy_Subtitle', { amount: applicableSubsidies.length })}
                        </Typography>
                      </Stack>
                      –
                    </Stack>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={9}>
                    <AddSubsidyModal
                      onAddSubsidy={onAddSubsidy ?? ((_: SubsidyInput[]) => {})}
                      actionSubsidies={actionSubsidies}
                      subBuildingSubsidies={subBuildingSubsidies}
                      totalCosts={totalOrCustomTotal ?? 0}
                      renovationType={renovationType}
                      isOverview={!showUseOwnCostsButton}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={9}>
                    <Divider sx={{ my: 1 }} />
                  </TableCell>
                </TableRow>
              </>
            ) : actionSubsidies.length > 0 && showAddSubsidyButton ? (
              // EDIT SUBSIDIES
              <>
                <TableRow>
                  <TableCell colSpan={9}>
                    <Divider sx={{ my: 1 }} />
                  </TableCell>
                </TableRow>
                <AddSubsidyModal
                  onAddSubsidy={onAddSubsidy ?? ((_: SubsidyInput[]) => {})}
                  actionSubsidies={actionSubsidies}
                  subBuildingSubsidies={subBuildingSubsidies}
                  totalCosts={totalOrCustomTotal ?? 0}
                  renovationType={renovationType}
                  isOverview={!showUseOwnCostsButton}
                />
                <TableRow>
                  <TableCell colSpan={9}>
                    <Divider sx={{ my: 1 }} />
                  </TableCell>
                </TableRow>
              </>
            ) : (
              // OVERVIEW
              <>
                <TableRow>
                  <TableCell colSpan={9}>
                    <Divider sx={{ my: 1 }} />
                  </TableCell>
                </TableRow>
                <ExpectedSubsidyFunding
                  total={totalExpectedSubsidyAmount}
                  actionSubsidies={actionSubsidies}
                  actionCost={totalOrCustomTotal ?? 0}
                  useActionCostsInDB={useActionCostsInDB}
                  canEdit={showAddSubsidyButton}
                />
                <TableRow>
                  <TableCell colSpan={9}>
                    <Divider sx={{ my: 1 }} />
                  </TableCell>
                </TableRow>
              </>
            )}

            <TableRow
              sx={{
                '& > *': { borderBottom: 'unset' },
              }}
            >
              <TableCell component="th" scope="row" sx={{ verticalAlign: 'top' }}>
                <Typography variant="subtitle2" fontWeight={700}>
                  {t('ActionPlanningSubsidies_OwnCostShare')}
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="subtitle2" fontWeight={700} sx={{ whiteSpace: 'nowrap' }}>
                  {formattedOwnCostShare}
                </Typography>
                {!!pricePerM2 && pricePerM2 > 0 && !!renovationArea && (
                  <Typography color="text.secondary" variant="caption">
                    {formattedPricePerM2}
                  </Typography>
                )}
              </TableCell>
            </TableRow>
            {!!errorMessage && (
              <TableRow>
                <Typography variant="caption" color="error" textAlign={'right'}>
                  {errorMessage}
                </Typography>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}
