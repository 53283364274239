export enum orientation_enum {
  NORTH = 'NORTH',
  NORTH_EAST = 'NORTH_EAST',
  EAST = 'EAST',
  SOUTH_EAST = 'SOUTH_EAST',
  SOUTH = 'SOUTH',
  SOUTH_WEST = 'SOUTH_WEST',
  WEST = 'WEST',
  NORTH_WEST = 'NORTH_WEST',
  HORIZONTAL = 'HORIZONTAL',
}

/**
 * This enum actually come from hasura..
 * If its not referenced by a fk_relationship its not generated though (why?)
 * So for now we manually declare it here
 */
export enum roof_type_enum {
  FLAT_ROOF = 'FLAT_ROOF',
  GABLE_ROOF = 'GABLE_ROOF',
  SHED_ROOF = 'SHED_ROOF',
}

export enum sustainaccount_climate_risk_hazard_enum {
  CHANGING_RAIN_PATTERNS = 'changingRainPatterns',
  CHANGING_TEMPERATURE_AIR = 'changingTemperatureAir',
  CHANGING_WIND_PATTERNS = 'changingWindPatterns',
  COASTAL_FLOOD = 'coastalFlood',
  COASTAL_EROSION = 'coastalErosion',
  COLD_WAVE = 'coldWave',
  DROUGHT = 'drought',
  FROST = 'frost',
  GROUND_WATER_FLOOD = 'groundWaterFlood',
  HEAT_STRESS = 'heatStress',
  HEAT_WAVE = 'heatWave',
  HEAVY_HAIL = 'heavyHail',
  HEAVY_RAIN = 'heavyRain',
  PERMAFROST_THAWING = 'permafrostThawing',
  RIVER_FLOOD = 'riverFlood',
  SEA_LEVEL_RISE = 'seaLevelRise',
  SNOW_STORM = 'snowStorm',
  SOIL_EROSION = 'soilErosion',
  SUBSIDENCE = 'subsidence',
  TORNADO = 'tornado',
  WILDFIRE = 'wildfire',
  WINTER_STORM = 'winterStorm',

  // unused ones
  AIR_QUALITY = 'airQuality',
  EARTHQUAKE = 'earthquake',
  LIGHTNING_STRIKE = 'lightningStrike',
  NOISE = 'noise',
  RADON = 'radon',

  // non sustainable climate risks
  TEMPERATURE_VARIABILITY = 'temperatureVariability',
  CYCLONE_HURRICANE_TYPHOON = 'cycloneHurricaneTyphoon',
  PRECIPITATION_HYDROLOGICAL_VARIABILITY = 'precipitationHydrologicalVariability',
  OCEAN_ACIDIFICATION = 'oceanAcidification',
  SALINE_INTRUSION = 'salineIntrusion',
  WATER_STRESS = 'waterStress',
  SOIL_DEGRADATION = 'soilDegradation',
  SOLIFLUCTION = 'solifluction',
  AVALANCHE = 'avalanche',
  LANDSLIDE = 'landslide',
  GLACIAL_LAKE_OUTBURST = 'glacialLakeOutburst',
}

export enum sustainaccount_climate_risk_scenario_enum {
  RCP_2_6 = 'RCP 2.6',
  RCP_4_5 = 'RCP 4.5',
  RCP_8_5 = 'RCP 8.5',
}

export enum sustainaccount_climate_risk_framework_enum {
  BASIC = 'basic',
  EU_TAXONOMY = 'eu_taxonomy',
  DGNB18 = 'dgnb18',
  DGNB23 = 'dgnb23',
}

export enum sustainaccount_climate_risk_severity_enum {
  VERY_LOW = 'veryLow',
  LOW = 'low',
  MEDIUM = 'moderate',
  HIGH = 'high',
  VERY_HIGH = 'veryHigh',
}

export enum unit_enum {
  CENTIGRADE = 'CENTIGRADE',
  CENTIMETERS = 'CENTIMETERS',
  CUBIC_METERS = 'CUBIC_METERS',
  CUBIC_METERS_PER_SQM = 'CUBIC_METERS_PER_SQM',
  DAYS = 'DAYS',
  DEGREES = 'DEGREES',
  EURO = 'EURO',
  EURO_PER_KWH = 'EURO_PER_KWH',
  EURO_PER_KW_PEAK = 'EURO_PER_KW_PEAK',
  EURO_PER_SQM = 'EURO_PER_SQM',
  EURO_PER_SQM_PER_YEAR = 'EURO_PER_SQM_PER_YEAR',
  EURO_PER_YEAR = 'EURO_PER_YEAR',
  G_CO2E = 'G_CO2E',
  G_CO2E_PER_EURO_PER_YEAR = 'G_CO2E_PER_EURO_PER_YEAR',
  G_CO2E_PER_KWH = 'G_CO2E_PER_KWH',
  KILOGRAM = 'KILOGRAM',
  KG_CO2E = 'KG_CO2E',
  KG_CO2E_PER_KWH = 'KG_CO2E_PER_KWH',
  KG_CO2E_PER_MWH = 'KG_CO2E_PER_MWH',
  KG_CO2E_PER_SQM = 'KG_CO2E_PER_SQM',
  KG_CO2E_PER_SQM_PER_YEAR = 'KG_CO2E_PER_SQM_PER_YEAR',
  KG_CO2E_PER_YEAR = 'KG_CO2E_PER_YEAR',
  KWH = 'KWH',
  KWH_PER_SQM = 'KWH_PER_SQM',
  KWH_PER_SQM_PER_YEAR = 'KWH_PER_SQM_PER_YEAR',
  KWH_PER_YEAR = 'KWH_PER_YEAR',
  KW_PEAK = 'KW_PEAK',
  LUMENS = 'LUMENS',
  LUMENS_PER_WATT = 'LUMENS_PER_WATT',
  MWH = 'MWH',
  METERS = 'METERS',
  PERCENTAGE = 'PERCENTAGE',
  SQM = 'SQM',
  SQM_PER_YEAR = 'SQM_PER_YEAR',
  TIMES = 'TIMES',
  TONS = 'TONS',
  TONS_PER_SQM = 'TONS_PER_SQM',
  TONS_CO2E = 'TONS_CO2E',
  WATT = 'WATT',
  WATT_PER_M_PER_KELVIN = 'WATT_PER_M_PER_KELVIN',
  WATT_PER_SQM_PER_KELVIN = 'WATT_PER_SQM_PER_KELVIN',
  YEAR = 'YEAR',
}
