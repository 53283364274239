import { Box, Stack } from '@mui/material';
import { ActionPlanningActionPlanActionPlanFragment } from '@predium/client-graphql';
import { Trans, useTranslation } from 'react-i18next';
import { ICONS } from '../../../../assets/icons';
import { ACADEMY_LINKS } from '../../../../assets/links';
import InfoTooltip from '../../../../components/InfoTooltip';
import { DelayedLoading } from '../../../../components/Loading';
import { PreLink } from '../../../../components/presentations/PreLink';
import { ActionPlanMetricsData } from '../../../../pages/ActionPlanning/ActionPlanningActionPlan';
import ActionPlanSectionTitle from '../Components/ActionPlanSectionTitle';
import { ActionPlanPathGraph } from '../Visualization/ActionPlanPathGraph';

type Props = {
  actionPlan: ActionPlanningActionPlanActionPlanFragment;
  handleActionSelection: (selectedActionId: number, previousActionId: number | undefined) => void;
  setHoverRowId: (id: number | null) => void;
  hoverRowId: number | null;
  metricsData: ActionPlanMetricsData | null;
};

const Co2PathGraph = ({ actionPlan, metricsData, handleActionSelection, setHoverRowId, hoverRowId }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Stack alignItems="center" direction={'row'}>
        <ActionPlanSectionTitle title={t('General_CO2Path')} icon={ICONS.CONVERSION_PATH} />

        <InfoTooltip
          text={
            <Trans
              i18nKey="General_1_5C02PathGraph-tooltip"
              components={{
                bold: <strong />,
                articleLink: (
                  <PreLink
                    link={{
                      href: ACADEMY_LINKS.CO2_PATH_GRAPH,
                      name: t('General_LearnMore'),
                      external: true,
                    }}
                  />
                ),
              }}
            />
          }
          maxwidth={600}
        />
      </Stack>

      {metricsData ? (
        <ActionPlanPathGraph
          metricsData={metricsData}
          actionPlan={actionPlan}
          handleActionSelection={(id: number) => {
            const actions = metricsData?.actions || [];
            const selectedAction = actions.find((action) => action.id === id);
            if (selectedAction) {
              const index = actions.indexOf(selectedAction);

              const previousAction = index > 0 ? actions[index - 1] : null;
              handleActionSelection(selectedAction.id, previousAction?.id);
            }
          }}
          setHighlightCurrentAction={(id: number | null) => {
            if (hoverRowId !== id) {
              setHoverRowId(id);
            }
          }}
        />
      ) : (
        <Box height={350}>
          <DelayedLoading />
        </Box>
      )}
    </>
  );
};

export default Co2PathGraph;
