import { base_emission_factor_type_enum } from '@predium/enums';
import { TFunction } from 'i18next';
import { Trans } from 'react-i18next';

export const translateBaseEmissionFactorTypeEnum = (enumValue: base_emission_factor_type_enum) => {
  switch (enumValue) {
    case base_emission_factor_type_enum.CRREM:
      return <Trans i18nKey="Enum_BaseEmissionFactorTypeEnum-CRREM" />;
    case base_emission_factor_type_enum.GEG:
      return <Trans i18nKey="Enum_BaseEmissionFactorTypeEnum-GEG" />;
  }
};

export const translateBaseEmissionFactorTypeEnum_dynamic = (
  enumValue: base_emission_factor_type_enum,
  t: TFunction<'translation', undefined>,
) => {
  switch (enumValue) {
    case base_emission_factor_type_enum.CRREM:
      return t('Enum_BaseEmissionFactorTypeEnum-CRREM');
    case base_emission_factor_type_enum.GEG:
      return t('Enum_BaseEmissionFactorTypeEnum-GEG');
  }
};
