/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useMutation } from '@apollo/client';
import {
  Button,
  Card,
  Checkbox,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  AccessRightsBuildingFragment,
  AccessRightsPortfolioFragment,
  user_building_permission_insert_input,
} from '@predium/client-graphql';
import { type_of_use_enum } from '@predium/enums';
import { translateTypeOfUseEnum_dynamic } from '@predium/i18n/client';
import { getEbfTypesOfUse } from '@predium/utils';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useMultiSelectFilter } from '../../../..//hooks/useMultiSelectFilter';
import Iconify from '../../../../components/Iconify';
import { DelayedLoading } from '../../../../components/Loading';
import { SnackbarTimeouts } from '../../../../components/NotistackProvider';
import OverflowText from '../../../../components/OverflowText';
import Scrollbar from '../../../../components/Scrollbar';
import MultiSelectFilterWithSearch from '../../../../components/search/MultiSelectFilterWithSearch';
import SearchField from '../../../../components/search/SearchField';
import SearchNotFound from '../../../../components/SearchNotFound';
import { TableSelectedActions } from '../../../../components/table';
import TablePaginationStandard from '../../../../components/table/TablePaginationStandard';
import TableRowWithHighlight, { hasRecentlyChanged } from '../../../../components/table/TableRowWithHighlight';
import { GET_PORTFOLIO_AND_BUILDING_PERMISSIONS } from '../../../../graphql/Permissions.queries';
import { UPSERT_USER_BUILDING_PERMISSION } from '../../../../graphql/Users.mutations';
import usePosthogTracking from '../../../../hooks/usePosthogTracking';
import useTable, { applySortFilter } from '../../../../hooks/useTable';
import DataCollectionHead, { HeadLabel } from '../../../DataCollection/Buildings/DataCollectionHead';
import AccessControlRoleSelector from './AccessControlRoleSelector';
import { usePermissionTableEntry } from './usePermissionTableEntry';
import { AccessRole, getRoleByPermissions, getRoleFromSelection, useGetPermissionsFromQuery } from './utils';

type Props = {
  buildings: AccessRightsBuildingFragment[];
  portfolios: AccessRightsPortfolioFragment[];
};

export default function AccessControlBuildingList({ buildings, portfolios }: Props) {
  const { t } = useTranslation();
  const { id } = useParams();
  const { trackEvent } = usePosthogTracking();
  const { enqueueSnackbar } = useSnackbar();

  const [selectedBuildingIds, setSelectedBuildingIds] = useState<number[]>([]);
  const [searchInput, setSearchInput] = useState('');
  const [portfolioFilters, setPortfolioFilters] = useState<{ name: string; value: string }[]>([
    { name: t('General_AllPortfolios'), value: 'AllPortfolios' },
  ]);
  const [typeOfUseFilters, setTypeOfUseFilters] = useState<{ name: string; value: type_of_use_enum }[]>([
    { name: t('General_TypeOfUse'), value: 'AllTypesOfUse' as type_of_use_enum },
  ]);

  //@ts-ignore
  const { checkBuildingPermissionTableEntry } = usePermissionTableEntry(parseInt(id));
  const {
    buildings: buildingPermissions,
    portfolios: portfolioPermissions,
    loading,

    //@ts-ignore
  } = useGetPermissionsFromQuery(parseInt(id));

  const [upsertUserBuildingPermissions] = useMutation(UPSERT_USER_BUILDING_PERMISSION, {
    onCompleted: (data) => {
      if (!data?.insert_user_building_permission?.affected_rows) {
        enqueueSnackbar(t('OrgSettings_AccessRights_UpsertError'), {
          variant: 'error',
          autoHideDuration: SnackbarTimeouts.Error,
        });
      }

      enqueueSnackbar(t('OrgSettings_AccessRights_UpsertSuccess'), {
        variant: 'success',
        autoHideDuration: SnackbarTimeouts.Success,
      });

      trackEvent('ACCESS_RIGHTS_UPDATED', {
        type: 'building',
      });
    },

    refetchQueries: [GET_PORTFOLIO_AND_BUILDING_PERMISSIONS],
  });

  const updatePortfolioFilters = (updatedSelection: string[]) => {
    return updatedSelection.map((value) => ({
      name: value === 'AllPortfolios' ? t('General_AllPortfolios') : value,
      value,
    }));
  };

  const updateTypeOfUseFilters = (updatedSelection: type_of_use_enum[]) => {
    if (updatedSelection.length === 0) {
      return [{ name: t('General_TypeOfUse'), value: 'AllTypesOfUse' as type_of_use_enum }];
    }
    return updatedSelection.map((value) => ({
      name: translateTypeOfUseEnum_dynamic(value, t),
      value,
    }));
  };

  // creates a unique list of all type of uses
  const allTypeOfUseSet = new Set<type_of_use_enum>();
  buildings.map((building) => {
    const typesOfUse = getEbfTypesOfUse(building.areas);
    return typesOfUse.map((typeOfUse) => allTypeOfUseSet.add(typeOfUse));
  });
  const allTypeOfUse = Array.from(allTypeOfUseSet)
    .flatMap((typeOfUse) => {
      return {
        name: translateTypeOfUseEnum_dynamic(typeOfUse, t),
        value: typeOfUse,
      };
    })
    .sort((a, b) => a.name.localeCompare(b.name));

  const allPortfolios = portfolios.flatMap((portfolio) => {
    return {
      name: portfolio.name ?? '',
      value: portfolio.name ?? '',
    };
  });

  const filtersActive = portfolioFilters.length > 0 || typeOfUseFilters.length > 0 || searchInput.length > 0;

  const COLUMNS: HeadLabel[] = [
    { id: 'address', label: t('General_Address'), minWidth: 300 },
    { id: 'portfolio', label: t('General_Portfolio'), minWidth: 150 },
    { id: 'typeOfUse', label: t('General_TypeOfUse'), minWidth: 120, sortingDisabled: true },
    {
      id: 'permissions',
      label: (
        <Stack sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: 1 }}>
          <Typography>{t('General_Permission', { count: 2 })}</Typography>
          <Tooltip title={t('OrgSettings_AccessRights_InferredTooltip')} arrow>
            <Typography sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Iconify icon="material-symbols:info-outline" width={20} height={20} />
            </Typography>
          </Tooltip>
        </Stack>
      ),
    },
  ];

  const {
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    order,
    orderBy,
    // filterName,
    // handleFilterByName,
    handleRequestSort,
  } = useTable({ defaultOrderBy: 'address', defaultOrder: 'asc', defaultRowsPerPage: 10 });

  const sortedRows = applySortFilter({
    data: buildings,
    nameFilter: {
      fieldName: (building) =>
        `${building.address.street} ${building.address.house_number} ${building.address.city} ${building.address.postal_code}`,
      filterValue: searchInput,
    },
    orderOptions: {
      order,

      //@ts-ignore
      orderBy: (item) => {
        switch (orderBy) {
          case 'address':
            return item.address.street;
          case 'portfolio':
            return item.economic_unit.portfolio.name;
          case 'permissions':
            return getRoleByPermissions(
              buildingPermissions.find((b) => b.id === item.id) || {
                read: false,
                write: false,
                id: item.id,
              },
            );
          default:
            return item[orderBy as keyof typeof item] as string | number;
        }
      },
    },
  });

  const filteredRows = sortedRows.filter((row) => {
    const portfolioFilter =
      portfolioFilters.length === 0 ||
      portfolioFilters.some(
        (filter) => filter.value === 'AllPortfolios' || filter.value === row.economic_unit.portfolio.name,
      );

    const typeOfUseFilter =
      typeOfUseFilters.length === 0 ||
      typeOfUseFilters.some(
        (filter) =>
          filter.value === ('AllTypesOfUse' as type_of_use_enum) ||
          getEbfTypesOfUse(row.areas).includes(filter.value as type_of_use_enum),
      );

    const searchFilter =
      searchInput.length === 0 ||
      `${row.address.street} ${row.address.house_number} ${row.address.city} ${row.address.postal_code}`
        .toLowerCase()
        .includes(searchInput.toLowerCase());

    return typeOfUseFilter && portfolioFilter && searchFilter;
  });

  const isNotFound = filteredRows.length === 0 && filtersActive;

  // tracking the empty rows in the next page, to prevent the table from shifting
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredRows.length) : 0;

  const handleCheckboxClick = (id: number) => {
    const selectedIndex = selectedBuildingIds.indexOf(id);

    if (selectedIndex === -1) {
      setSelectedBuildingIds([...selectedBuildingIds, id]);
    } else {
      // removes the index from the array in a more efficient way than filter
      setSelectedBuildingIds(
        //@ts-ignore
        [].concat(selectedBuildingIds.slice(0, selectedIndex), selectedBuildingIds.slice(selectedIndex + 1)),
      );
    }
  };

  const handleSelectAllClick = (checked: boolean) => {
    if (checked) {
      const newSelectedIds = filteredRows.map((n) => n.id);
      setSelectedBuildingIds(newSelectedIds);
    } else {
      setSelectedBuildingIds([]);
    }
  };

  const {
    tempSelection: tempPortfolioSelection,
    handleSelectionChange: handlePortfolioSelectionChange,
    applySelection: applyPortfolioSelection,
    clearSelection: clearPortfolioSelection,
    isClearDisabled: clearPortfolioSelectionDisabled,
    isApplyDisabled: applyPortfolioSelectionDisabled,
  } = useMultiSelectFilter<string>((updatedSelection) => {
    setPortfolioFilters(updatePortfolioFilters(updatedSelection as string[]));
  }, 'AllPortfolios');

  const {
    tempSelection: tempTypeOfUseSelection,
    handleSelectionChange: handleTypeOfUseSelectionChange,
    applySelection: applyTypeOfUseSelection,
    clearSelection: clearTypeOfUseSelection,
    isClearDisabled: clearTypeOfUseSelectionDisabled,
    isApplyDisabled: applyTypeOfUseSelectionDisabled,
  } = useMultiSelectFilter<type_of_use_enum>((updatedSelection) => {
    setTypeOfUseFilters(updateTypeOfUseFilters(updatedSelection as type_of_use_enum[]));
  }, 'AllTypesOfUse' as type_of_use_enum);

  const resetAllFilters = () => {
    clearPortfolioSelection();
    clearTypeOfUseSelection();
    setPortfolioFilters([{ name: t('General_AllPortfolios'), value: 'AllPortfolios' }]);
    setTypeOfUseFilters([{ name: t('General_TypeOfUse'), value: 'AllTypesOfUse' as type_of_use_enum }]);
    setSearchInput('');
  };

  const onRoleChange = (role: AccessRole, buildingId: number) => {
    const object: user_building_permission_insert_input = {
      //@ts-ignore
      user_id: parseInt(id),
      building_id: buildingId,
      read: role !== AccessRole.NO_ACCESS,
      write: role === AccessRole.MANAGER,
    };

    upsertUserBuildingPermissions({ variables: { objects: [object] } });
  };

  const onRoleChangeAll = (role: AccessRole) => {
    const objects: user_building_permission_insert_input[] = selectedBuildingIds
      .filter((id) => {
        //filter out all buildings which have a portfolio permission higher than the selected role -> they cannot be set lower
        const portfolioPermission = portfolioPermissions.find(
          (p) => p.id === buildings.find((b) => b.id === id)?.economic_unit.portfolio.id,
        );

        switch (role) {
          case AccessRole.NO_ACCESS:
            return !portfolioPermission?.read;
          case AccessRole.CONTRIBUTOR:
            return !portfolioPermission?.write;
          case AccessRole.MANAGER:
            return true;
          default:
            return false;
        }
      })
      .map((buildingId) => ({
        //@ts-ignore
        user_id: parseInt(id),
        building_id: buildingId,
        read: role !== AccessRole.NO_ACCESS,
        write: role === AccessRole.MANAGER,
      }));

    upsertUserBuildingPermissions({ variables: { objects } });
  };

  if (loading) {
    return <DelayedLoading />;
  }

  return (
    <>
      <Card>
        <Scrollbar>
          <Stack
            sx={{ px: 4, py: 3, width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}
          >
            <SearchField
              value={searchInput}
              onChange={setSearchInput}
              placeholder={t('General_SearchBuildingPlaceholder')}
            />

            <MultiSelectFilterWithSearch
              selectedLabel={t('General_Portfolio_other')}
              label={t('General_AllPortfolios')}
              items={allPortfolios}
              tempSelection={tempPortfolioSelection}
              handleSelectionChange={handlePortfolioSelectionChange}
              applySelection={applyPortfolioSelection}
              clearSelection={() => {
                setPortfolioFilters([{ name: t('General_AllPortfolios'), value: 'AllPortfolios' }]);
                clearPortfolioSelection();
              }}
              isClearDisabled={clearPortfolioSelectionDisabled}
              isApplyDisabled={applyPortfolioSelectionDisabled}
              selectedFilter={portfolioFilters.map((filter) => filter.value.toString())}
              noResultsText={t('NoExistingPortfolios_info')}
              searchable
            />

            <MultiSelectFilterWithSearch
              label={t('General_TypeOfUse')}
              selectedLabel={t('General_TypeOfUse_other')}
              items={allTypeOfUse}
              tempSelection={tempTypeOfUseSelection}
              handleSelectionChange={handleTypeOfUseSelectionChange}
              applySelection={applyTypeOfUseSelection}
              clearSelection={clearTypeOfUseSelection}
              isClearDisabled={clearTypeOfUseSelectionDisabled}
              isApplyDisabled={applyTypeOfUseSelectionDisabled}
              selectedFilter={typeOfUseFilters.map((filter) => filter.value?.toString()).filter(Boolean)}
              noResultsText={t('NoExistingTypesOfUse_info')}
              searchable
            />

            {filtersActive && (
              <Button onClick={resetAllFilters}>
                <Typography variant="button" fontWeight={600} fontSize={'14px'}>
                  {t('General_ClearAll')}
                </Typography>
              </Button>
            )}
          </Stack>
          <TableContainer sx={{ minWidth: '100%' }}>
            {selectedBuildingIds.length > 0 && (
              <TableSelectedActions
                selectedText={t('General_BuildingSelected', { count: selectedBuildingIds.length })}
                numSelected={selectedBuildingIds.length}
                rowCount={filteredRows.length}
                onSelectAllRows={handleSelectAllClick}
                action={
                  <AccessControlRoleSelector
                    onRoleChange={onRoleChangeAll}
                    value={getRoleFromSelection(selectedBuildingIds, buildingPermissions)}
                    allValues={selectedBuildingIds.flatMap((id) => {
                      return {
                        role: getRoleByPermissions(buildingPermissions.find((b) => b.id === id)),
                        portfolioRole: getRoleByPermissions(
                          portfolioPermissions.find(
                            (p) => p.id === buildings.find((b) => b.id === id)?.economic_unit.portfolio.id,
                          ),
                        ),
                      };
                    })}
                    type="building"
                  />
                }
                sx={{ pr: 2.5 }}
              />
            )}
            <Table>
              <DataCollectionHead
                order={order}
                orderBy={orderBy}
                headLabel={COLUMNS}
                rowCount={filteredRows.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
                numSelected={selectedBuildingIds.length}
              />
              <TableBody>
                {isNotFound ? (
                  <SearchNotFound searchQuery={t('Reporting_SearchNotFound')} />
                ) : (
                  filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const currentPermissions = buildingPermissions.find((b) => b.id === row.id) || {
                      read: false,
                      write: false,
                      id: row.id,
                    };

                    const currentPortfolioPermissions = portfolioPermissions.find(
                      (p) => p.id === row.economic_unit.portfolio.id,
                    ) || {
                      read: false,
                      write: false,
                      id: row.economic_unit.portfolio.id,
                    };

                    const isPortfolioLower =
                      !!checkBuildingPermissionTableEntry(row.id) &&
                      //portfolio permission is lower than building permission
                      ((!currentPortfolioPermissions.read && currentPermissions.read) ||
                        (!currentPortfolioPermissions.write && currentPermissions.write));

                    return (
                      <Row
                        key={row.id}
                        row={row}
                        handleCheckboxClick={handleCheckboxClick}
                        isItemSelected={selectedBuildingIds.includes(row.id)}
                        currentRole={getRoleByPermissions(currentPermissions)}
                        portfolioRole={getRoleByPermissions(currentPortfolioPermissions)}
                        onRoleChange={onRoleChange}
                        isPortfolioLower={isPortfolioLower}
                      />
                    );
                  })
                )}

                {emptyRows > 0 && !isNotFound && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePaginationStandard
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
        />
      </Card>
    </>
  );
}

type RowProps = {
  row: AccessRightsBuildingFragment;
  handleCheckboxClick: (id: number) => void;
  isItemSelected: boolean;
  currentRole: AccessRole;
  portfolioRole: AccessRole;
  onRoleChange: (role: AccessRole, buildingId: number) => void;
  isPortfolioLower?: boolean;
};

function Row({ row, handleCheckboxClick, isItemSelected, currentRole, onRoleChange, portfolioRole }: RowProps) {
  const typesOfUse = getEbfTypesOfUse(row.areas);
  const { t } = useTranslation();
  return (
    <>
      <TableRowWithHighlight
        background={hasRecentlyChanged(row.created_at) ? 'success' : 'default'}
        sx={{ '& > *': { borderBottom: 'unset' }, cursor: 'pointer' }}
        selected={isItemSelected}
        hover
      >
        <TableCell padding="checkbox">
          <Checkbox checked={isItemSelected} onClick={() => handleCheckboxClick(row.id)} />
        </TableCell>
        <TableCell>
          <Stack>
            <OverflowText
              text={row.address.street}
              maxWidth={'300px'}
              color={'text.primary'}
              variant="body2"
              TooltipProps={{
                arrow: true,
                placement: 'top-start',
              }}
            />

            <OverflowText
              text={`${row.address.postal_code} ${row.address.city}`}
              maxWidth={'300px'}
              color={'text.secondary'}
              variant="body2"
              TooltipProps={{
                arrow: true,
                placement: 'top-start',
              }}
            />
          </Stack>
        </TableCell>
        <TableCell>
          <Typography variant="body2">{row.economic_unit.portfolio.name}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2">
            {typesOfUse.map((type) => translateTypeOfUseEnum_dynamic(type, t)).join(', ')}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2">
            <AccessControlRoleSelector
              onRoleChange={(role) => onRoleChange(role, row.id)}
              value={currentRole}
              allValues={[{ role: currentRole, portfolioRole }]}
              type="building"
            />
          </Typography>
        </TableCell>
      </TableRowWithHighlight>
    </>
  );
}
