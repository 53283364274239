import { useMutation } from '@apollo/client';
import { Box } from '@mui/material';
import {
  DataCollectionBuildingModelFragment,
  EnergyConsumerMutation,
  EnergyDistributionMutation,
  EnergyStorageMutation,
  EnergySystemMutation,
  EnergySystemRouteMutation,
  EnvelopeMutation,
  getBuildingModelMutationAvailabilityQuery,
} from '@predium/client-graphql';
import {
  country_enum,
  energy_consumer_technology_type_enum,
  energy_consumer_type_enum,
  energy_system_type_enum,
  envelope_type_enum,
} from '@predium/enums';
import { cmToM, ensureDefined } from '@predium/utils';
import { useSnackbar } from 'notistack';
import { MutableRefObject, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { FieldNamesMarkedBoolean, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FullScreenLoader from '../../../components/FullScreenLoader';
import NavigationModal from '../../../components/NavigationModal/NavigationModal';
import { SnackbarTimeouts } from '../../../components/NotistackProvider';
import { APPLY_BUILDING_MODEL_MUTATIONS } from '../../../graphql/DataCollection.mutations';
import { GET_BUILDING, GET_BUILDING_MODEL_ACTIONS_STATUS } from '../../../graphql/DataCollection.queries';
import usePosthogTracking from '../../../hooks/usePosthogTracking';
import useSessionData from '../../../hooks/useSessionData';
import { BuildingTabEnum } from '../../../pages/DataCollection/DataCollectionBuilding';
import BuildingEnvelope, { CUSTOM, EnvelopeFormValues } from './BuildingEnvelope';
import BuildingTechnology, {
  EnergySystemFormValues,
  SystemRoute,
  consumerFields,
  distributionFields,
  energyRouteFields,
  storageFields,
} from './BuildingTechnology';
import { ActionPlanDeleteConfirmationDialog } from './Common/ActionDeleteConfirmationDialog';
import { ActionPlanDeleteAlert } from './Common/BuildingAlerts';
import { ConflictDialog } from './Common/ConflictDialog';
import { HeatingDemandConflictDialog } from './Common/HeatingDemandConflictDialog';
import useBuilding from './Context/useBuilding';
import BuildingModelValuesSection, {
  BuildingModelValuesFormValues,
} from './Envelopes/EnvelopeSection/BuildingModelValuesSection';

type FormState = {
  isDirty: boolean;
  errorCount: number;
  editCount: number;
};

const defaultFormState = {
  isDirty: false,
  errorCount: 0,
  editCount: 0,
};

export type SetFormState = React.Dispatch<React.SetStateAction<FormState>>;

export type NestedForm = {
  buildingModel: DataCollectionBuildingModelFragment;
  isShown: boolean;
  setFormState: SetFormState;
};

export type FormRef<T> = {
  methods: UseFormReturn<
    //@ts-ignore
    T,
    EnergySystemFormValues | EnvelopeFormValues | BuildingModelValuesFormValues
  >;
};

export type FormRefs = {
  technology: MutableRefObject<FormRef<EnergySystemFormValues> | null>;
  envelope: MutableRefObject<FormRef<EnvelopeFormValues> | null>;
  buildingModelValues: MutableRefObject<FormRef<BuildingModelValuesFormValues> | null>;
};

export type TechnologyEnvelopeState = {
  envelopeErrorCount: number;
  envelopeEditCount: number;
  envelopeIsDirty: boolean;
  technologyErrorCount: number;
  technologyEditCount: number;
  technologyIsDirty: boolean;
};

export const technologyEnvelopeStateDefault = {
  envelopeErrorCount: 0,
  envelopeEditCount: 0,
  envelopeIsDirty: false,
  technologyErrorCount: 0,
  technologyEditCount: 0,
  technologyIsDirty: false,
} as TechnologyEnvelopeState;

const SCALING_SNACKBAR_KEY = 'scaling_snackbar_key';

type Props = {
  currentTab: BuildingTabEnum;
  buildingModalMutabilityData: getBuildingModelMutationAvailabilityQuery;
  setExternalState: React.Dispatch<React.SetStateAction<TechnologyEnvelopeState>>;
};

export type TechnologyEnvelopeRef = {
  handleSubmit: () => void;
  reset: () => void;
};

const BuildingTechnologyEnvelope = forwardRef<TechnologyEnvelopeRef, Props>(
  ({ currentTab, buildingModalMutabilityData, setExternalState }, ref) => {
    const { t } = useTranslation();
    const { building } = useBuilding();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [openDeleteActionModal, setDeleteActionsModalOpen] = useState<boolean>(false);
    const [openConflictDialog, setOpenConflictDialog] = useState(false);
    const { trackEvent } = usePosthogTracking();
    const { serverSideFeatureFlags } = useSessionData();

    const [technologyFormState, setTechnologyFormState] = useState<FormState>(defaultFormState);
    const [envelopeFormState, setEnvelopeFormState] = useState<FormState>(defaultFormState);
    const [buildingModelValuesFormState, setBuildingModelValuesFormState] = useState<FormState>(defaultFormState);

    const formRefs: FormRefs = {
      technology: useRef(null),
      envelope: useRef(null),
      buildingModelValues: useRef(null),
    };

    const technologyFormMethods: UseFormReturn<EnergySystemFormValues> | undefined =
      formRefs?.technology?.current?.methods;

    const envelopeFormMethods: UseFormReturn<EnvelopeFormValues> | undefined = formRefs?.envelope?.current?.methods;

    const buildingModelValuesFormMethods: UseFormReturn<BuildingModelValuesFormValues> | undefined =
      formRefs?.buildingModelValues?.current?.methods;

    const buildingId = building.id;
    const buildingModel = ensureDefined(building.active_building_model);

    const { id: buildingModelId } = buildingModel;

    const errorSnackbar = () => {
      return enqueueSnackbar(t('DataCollection_TechnologyMutation-error'), {
        variant: 'error',
        autoHideDuration: SnackbarTimeouts.Error,
        key: SCALING_SNACKBAR_KEY,
      });
    };

    const successSnackbar = () => {
      return enqueueSnackbar(t('DataCollection_TechnologyMutation-success'), {
        variant: 'success',
        autoHideDuration: SnackbarTimeouts.Success,
        key: SCALING_SNACKBAR_KEY,
      });
    };

    const isTechEnvelopeDirty =
      technologyFormState.isDirty || envelopeFormState.isDirty || buildingModelValuesFormState.isDirty;

    const refetchQueries = [
      {
        query: GET_BUILDING_MODEL_ACTIONS_STATUS,
        variables: { buildingModelId: building?.active_building_model?.id },
      },
      { query: GET_BUILDING, variables: { buildingId: buildingId, year: new Date().getFullYear() } },
    ];

    const [applyBuildingModalMutations, { data: applyMutationResult, loading: applyMutationLoading }] = useMutation(
      APPLY_BUILDING_MODEL_MUTATIONS,
      {
        onError: () => errorSnackbar(),
        onCompleted: (data) => {
          if (data.applyBuildingModelMutations.scaling_successful) {
            successSnackbar();
            trackEvent('BUILDING_MODEL_MUTATED', {
              type: technologyFormState.isDirty ? 'systems' : 'envelopes',
            });
          } else {
            setOpenConflictDialog(true);
          }
        },
        refetchQueries: refetchQueries,
      },
    );

    const { action_deletion_necessary, affected_action_plan_count, affected_users } =
      buildingModalMutabilityData.getBuildingModelMutationAvailability;

    const [saveBuildingModalMutations, { loading: saveMutationLoading }] = useMutation(APPLY_BUILDING_MODEL_MUTATIONS, {
      onError: () => errorSnackbar(),
      onCompleted: () => {
        successSnackbar();
      },
      refetchQueries: refetchQueries,
    });

    function findOrCreateMutation(
      mutations:
        | EnergySystemRouteMutation[]
        | EnergyConsumerMutation[]
        | EnergyStorageMutation[]
        | EnergyDistributionMutation[],
      id: number,
    ) {
      let mutation = mutations.find(
        (m: EnergySystemRouteMutation | EnergyConsumerMutation | EnergyStorageMutation | EnergyDistributionMutation) =>
          m.id === id,
      );

      if (!mutation) {
        mutation = {
          id: id,
          ...(id < 0 ? { create: true } : {}),
        };
        mutations.push(mutation);
      }

      return mutation;
    }

    /**
     * user can edit single field, multiple fields
     * can also attach existing technology unit to route or newly created technology unit
     */
    const createRouteMutations = (
      energySystemRouteMutations: EnergySystemRouteMutation[],
      routeData: SystemRoute,
      key: keyof SystemRoute,
    ) => {
      const mutation: EnergySystemRouteMutation = findOrCreateMutation(
        energySystemRouteMutations,
        routeData.energyRouteId,
      );

      if (routeData.routeDeleted) {
        //if route is deleted, only need to send id and delete flag
        mutation.delete = routeData.routeDeleted;
        return;
      }

      switch (key) {
        case 'energySource':
          mutation.energy_source_type = routeData.energySource;
          break;
        case 'energyFinal':
          mutation.energy_final = routeData.energyFinal;
          break;
        case 'storageId':
          mutation.energy_storage_id = routeData.storageId;
          break;
        case 'consumerId':
          mutation.energy_consumer_id = routeData.consumerId;
          break;
        case 'distributionId':
          mutation.energy_distribution_id = routeData.distributionId;
          break;
        case 'emissionCertificateId':
          mutation.emission_certificate = {
            ...(routeData.emissionCertificateId > 0 ? { id: routeData.emissionCertificateId } : { delete: true }),
          };
          break;
        case 'primaryEnergyFactorEmissionCertificateId':
          mutation.primary_energy_factor_emission_certificate = {
            ...(routeData.primaryEnergyFactorEmissionCertificateId > 0
              ? { id: routeData.primaryEnergyFactorEmissionCertificateId }
              : { delete: true }),
          };
          break;
        default:
          const exhaustiveCheck = key;
          throw new Error(`Unhandled EnergySystemRouteMutation key ${key}: ${exhaustiveCheck}`);
      }
    };

    /**
     * user can edit single field, multiple fields or can also create new technology unit
     * backend needs to know what is edited
     */
    const createConsumerMutations = (
      energySystemConsumerMutations: EnergyConsumerMutation[],
      routeData: SystemRoute,
      key: keyof SystemRoute,
    ) => {
      const mutation: EnergyConsumerMutation = findOrCreateMutation(
        energySystemConsumerMutations,
        routeData.consumerId,
      );

      if (
        (mutation.create &&
          (routeData.energySystemType === energy_system_type_enum.LIGHTING ||
            routeData.energySystemType === energy_system_type_enum.COOLING)) ||
        routeData.energySystemType === energy_system_type_enum.ELECTRICITY
      ) {
        mutation.efficiency = 1;
      }

      if (routeData.energySystemType === energy_system_type_enum.ELECTRICITY) {
        mutation.energy_consumer_type_id = energy_consumer_type_enum.ELECTRICITY;
        mutation.energy_consumer_technology_type_id = energy_consumer_technology_type_enum.ELECTRICITY_MISCELLANEOUS;
        return;
      }

      switch (key) {
        case 'energyConsumerType':
          mutation.energy_consumer_type_id = routeData.energyConsumerType;
          break;
        case 'consumerTechnologyType':
          mutation.energy_consumer_technology_type_id = routeData.consumerTechnologyType;
          break;
        case 'expenditureFactor':
          mutation.efficiency = routeData.expenditureFactor;
          break;
        case 'energyConsumerConstructionYear':
          mutation.construction_year = routeData.energyConsumerConstructionYear;
          break;
        default:
          break;
      }
    };

    const createStorageMutations = (
      energySystemStorageMutations: EnergyStorageMutation[],
      routeData: SystemRoute,
      key: keyof SystemRoute,
    ) => {
      //fields will be shown as edited incase of attaching existing storage unit to route but backend doesn't those as it use id to make db relation
      const mutation: EnergyStorageMutation = findOrCreateMutation(energySystemStorageMutations, routeData.storageId);

      switch (key) {
        case 'energyStorageType':
          mutation.energy_storage_type_id = routeData.energyStorageType;
          break;
        case 'energyStorageHeatLoss':
          mutation.annual_heat_loss = routeData.energyStorageHeatLoss;
          break;
        case 'energyStorageConstructionYear':
          mutation.construction_year = routeData.energyStorageConstructionYear;
          break;
        case 'storageDeleted':
          mutation.delete = routeData.storageDeleted;
          break;
        default:
          break;
      }
    };

    const createDistributionMutations = (
      energySystemDistributionMutations: EnergyDistributionMutation[],
      routeData: SystemRoute,
      key: keyof SystemRoute,
    ) => {
      const mutation: EnergyDistributionMutation = findOrCreateMutation(
        energySystemDistributionMutations,
        routeData.distributionId,
      );

      switch (key) {
        case 'energyDistributionType':
          mutation.energy_distribution_type_id = routeData.energyDistributionType;
          break;
        case 'distributionHeatLoss':
          mutation.heat_distribution_loss = routeData.distributionHeatLoss;
          break;
        case 'transferHeatLoss':
          mutation.heat_transfer_loss = routeData.transferHeatLoss;
          break;
        case 'lastHydraulicBalancing':
          mutation.last_hydraulic_balancing = routeData.lastHydraulicBalancing;
          break;
        case 'lastBalancingSetToNever':
          mutation.last_balancing_set_to_never = routeData.lastBalancingSetToNever;
          break;
        case 'energyDistributionConstructionYear':
          mutation.construction_year = routeData.energyDistributionConstructionYear;
          break;
        case 'distributionDeleted':
          mutation.delete = routeData.distributionDeleted;
          break;
        default:
          break;
      }
    };

    const createSystemMutations = (
      data: EnergySystemFormValues,
      dirtyFields: FieldNamesMarkedBoolean<EnergySystemFormValues> | undefined,
    ) => {
      const energySystemConsumerMutations: EnergyConsumerMutation[] = [];
      const energySystemStorageMutations: EnergyStorageMutation[] = [];
      const energySystemDistributionMutations: EnergyDistributionMutation[] = [];
      const energySystemMutations: EnergySystemMutation[] = [];
      //only submit edited data
      if (dirtyFields && Object.keys(dirtyFields).length > 0) {
        for (const systemType in dirtyFields) {
          const systemData = data[systemType as keyof typeof dirtyFields];

          //@ts-ignore
          dirtyFields[systemType as keyof typeof dirtyFields].forEach((dirtySystem, systemIndex) => {
            const energySystemRouteMutations: EnergySystemRouteMutation[] = [];

            //@ts-ignore
            const system = systemData[systemIndex];
            if (!system) {
              return;
            }

            //@ts-ignore
            dirtySystem.routes.forEach((dirtyField, routeIndex) => {
              const routeData = system.routes[routeIndex];
              if (!routeData) {
                return;
              }

              Object.keys(dirtyField).forEach((key) => {
                if (energyRouteFields.includes(key)) {
                  createRouteMutations(energySystemRouteMutations, routeData, key as keyof SystemRoute);
                }

                //ignore all other mutations if route is deleted
                if (routeData.routeDeleted) {
                  return;
                }

                if (consumerFields.includes(key)) {
                  createConsumerMutations(energySystemConsumerMutations, routeData, key as keyof SystemRoute);
                }

                if (storageFields.includes(key)) {
                  createStorageMutations(energySystemStorageMutations, routeData, key as keyof SystemRoute);
                }

                if (distributionFields.includes(key)) {
                  createDistributionMutations(energySystemDistributionMutations, routeData, key as keyof SystemRoute);
                }
              });
            });
            energySystemRouteMutations.length > 0 &&
              energySystemMutations.push({
                id: system.id,
                ...(system.id < 0 ? { create: true, energy_system_type_id: system.type } : {}),
                energy_system_route_mutations: energySystemRouteMutations,
              });
          });
        }
      }

      return {
        energySystemMutations,
        energySystemConsumerMutations,
        energySystemStorageMutations,
        energySystemDistributionMutations,
      };
    };

    /**
  Creates an array of envelope mutations based on the provided form data and dirty fields.
  The function takes data as the form data and dirtyFields as an object indicating which fields have changed.
  It iterates through each envelope type and its corresponding dirty fields to identify changes.
  For each changed field, it creates an object with the mutation details.
  Additional fields (specified in extraFields) are excluded from the mutation.
  u_value value is readonly field in the form and only editable for window and door.
  The resulting array includes mutations for changed envelopes and deleted envelopes.
  The function returns an array of envelope mutations
 */
    const createEnvelopeMutations = (
      data: EnvelopeFormValues,
      dirtyFields: FieldNamesMarkedBoolean<EnvelopeFormValues> | undefined,
    ) => {
      const envelopMutations: EnvelopeMutation[] = [];

      if (dirtyFields && Object.keys(dirtyFields).length > 0) {
        for (const envelope in dirtyFields) {
          //@ts-ignore
          dirtyFields[envelope as keyof typeof dirtyFields].forEach((dirtyField, i) => {
            const formData = data[envelope as keyof typeof dirtyFields]?.[i];

            if (formData) {
              if (formData?.delete) {
                envelopMutations.push({
                  id: formData.envelope_id,
                  delete: true,
                });
                return; //ignore everything else if envelope is deleted
              }

              const dirtyKeys = Object.keys(dirtyField).filter((key) => dirtyField[key as keyof typeof dirtyField]);

              //only used for readonly or frontend manipulations
              const extraFields = [
                'envelope_id',
                'data_source_type_id',
                'had_insulation',
                'has_insulation',
                'id',
                'delete',
              ];

              if (!(envelope === envelope_type_enum.DOOR || envelope === envelope_type_enum.WINDOW)) {
                extraFields.push('u_value');
                delete dirtyField.u_value;
              }

              if (Object.keys(dirtyField).length > 0) {
                envelopMutations.push({
                  ...(formData?.create === true ? {} : { id: formData.envelope_id }),
                  ...dirtyKeys
                    .filter((key) => !extraFields.includes(key))
                    .reduce((acc, key) => {
                      if (key === 'base_construction_thickness' || key === 'insulation_thickness') {
                        // @ts-ignore No time to try to understand this typing.
                        acc[key] = cmToM(formData[key]);
                      } else if (key === 'insulation_method') {
                        // @ts-ignore No time to try to understand this typing.
                        acc[key] = formData[key] === '' ? null : formData[key]; //default value of the select is empty string
                      } else {
                        // @ts-ignore No time to try to understand this typing.
                        acc[key] = formData[key];
                      }
                      if (key === 'insulation_material_name' && formData[key] === CUSTOM) {
                        // @ts-ignore No time to try to understand this typing.
                        acc['insulation_material_name'] = formData['insulation_material_custom'];
                      }
                      // @ts-ignore No time to try to understand this typing.
                      delete acc['insulation_material_custom'];

                      return acc;
                    }, {}),
                });
              }
            }
          });
        }
      }

      return envelopMutations;
    };

    const handleFormSubmit = async (saveInvalidState: boolean) => {
      let techData: EnergySystemFormValues = {};
      let envelopeData: EnvelopeFormValues = {};
      let buildingModelValuesData: BuildingModelValuesFormValues = {};

      await technologyFormMethods?.handleSubmit((data: EnergySystemFormValues) => {
        techData = data;
      })();

      await envelopeFormMethods?.handleSubmit((data: EnvelopeFormValues) => {
        envelopeData = data;
      })();

      await buildingModelValuesFormMethods?.handleSubmit((data: BuildingModelValuesFormValues) => {
        buildingModelValuesData = data;
      })();

      if (techData && envelopeData) {
        if (action_deletion_necessary && !openDeleteActionModal && !saveInvalidState) {
          setDeleteActionsModalOpen(true);
        } else {
          const {
            energySystemMutations,
            energySystemConsumerMutations,
            energySystemStorageMutations,
            energySystemDistributionMutations,
          } = createSystemMutations(techData, technologyFormMethods?.formState.dirtyFields);

          const mutations = {
            buildingModelId: buildingModelId,
            buildingModelMutation: buildingModelValuesData,
            envelopeMutations: createEnvelopeMutations(envelopeData, envelopeFormMethods?.formState.dirtyFields),
            energySystemMutations,
            energySystemConsumerMutations: energySystemConsumerMutations,
            energySystemStorageMutations: energySystemStorageMutations,
            energySystemDistributionMutations: energySystemDistributionMutations,
            saveInvalidState: saveInvalidState,
          };

          if (saveInvalidState) {
            saveBuildingModalMutations({
              variables: {
                ...mutations,
              },
            });
          } else {
            applyBuildingModalMutations({
              variables: {
                ...mutations,
              },
            });
          }
        }
      }
    };

    const isShowHeatingDemandValues = () => {
      if (building.address.country_id === country_enum.AT) {
        // Show heating demand to normal user if heating demand is already set (non-editable)
        // OR if the feature flag is enabled (only enabled for Predium users)
        if (heatingDemand || serverSideFeatureFlags.HEATING_DEMAND_EDITABLE) {
          return true;
        }
      }
      return false;
    };

    const heatingDemand = buildingModelValuesFormMethods?.getValues().heating_demand ?? buildingModel.heating_demand;

    const deleteMessage =
      affected_action_plan_count > 0
        ? t('DataCollection_BuildingDeletionMessage-public', {
            count: affected_action_plan_count,
            affectedUser: affected_users[0].name,
          })
        : '';

    const showAlert = isTechEnvelopeDirty && action_deletion_necessary;

    useImperativeHandle(ref, () => ({
      handleSubmit: () => handleFormSubmit(false),
      reset: () => {
        formRefs.buildingModelValues.current?.methods.reset();
        ensureDefined(formRefs.envelope.current).methods.reset();
        ensureDefined(formRefs.technology.current).methods.reset();
      },
    }));

    useEffect(() => {
      if (isTechEnvelopeDirty) {
        closeSnackbar(SCALING_SNACKBAR_KEY);
      }
    }, [closeSnackbar, isTechEnvelopeDirty]);

    useEffect(() => {
      setExternalState({
        envelopeErrorCount: envelopeFormState.errorCount + buildingModelValuesFormState.errorCount,
        envelopeEditCount: envelopeFormState.editCount + buildingModelValuesFormState.editCount,
        envelopeIsDirty: envelopeFormState.isDirty || buildingModelValuesFormState.isDirty,
        technologyErrorCount: technologyFormState.errorCount,
        technologyEditCount: technologyFormState.editCount,
        technologyIsDirty: technologyFormState.isDirty,
      });
    }, [buildingModelValuesFormState, envelopeFormState, setExternalState, technologyFormState]);

    return (
      <>
        {showAlert && (
          <Box mt={1}>
            <ActionPlanDeleteAlert actionPlanCount={affected_action_plan_count} />
          </Box>
        )}

        <BuildingTechnology
          buildingModel={buildingModel}
          baseEmissionFactors={building.base_emission_factors}
          ref={formRefs.technology}
          isShown={currentTab === BuildingTabEnum.technology}
          setFormState={setTechnologyFormState}
          address={building.address}
        />

        <BuildingEnvelope
          ref={formRefs.envelope}
          buildingModel={buildingModel}
          isShown={currentTab === BuildingTabEnum.envelope}
          setFormState={setEnvelopeFormState}
          heatingDemandComponent={
            isShowHeatingDemandValues() && (
              <BuildingModelValuesSection
                ref={formRefs.buildingModelValues}
                buildingModel={ensureDefined(buildingModel)}
                setFormState={setBuildingModelValuesFormState}
              />
            )
          }
        />

        <FullScreenLoader
          open={applyMutationLoading || saveMutationLoading}
          text={t('DataCollection_EnvelopeEditForm_LoaderText')}
          disableCloseAction
        />

        {heatingDemand ? (
          <HeatingDemandConflictDialog
            open={openConflictDialog}
            onClose={() => setOpenConflictDialog(false)}
            onClick={() => {
              setOpenConflictDialog(false);
              handleFormSubmit(true);
            }}
            heatingDemandSystem={applyMutationResult?.applyBuildingModelMutations.heating_demand_system ?? -1}
            heatingDemand={heatingDemand ?? -1}
            heatingDemandEnvelope={applyMutationResult?.applyBuildingModelMutations.heating_demand_envelope ?? -1}
          />
        ) : (
          <ConflictDialog
            open={openConflictDialog}
            onClose={() => setOpenConflictDialog(false)}
            onClick={() => {
              setOpenConflictDialog(false);
              handleFormSubmit(true);
            }}
            //@ts-ignore
            finalEnergy={applyMutationResult?.applyBuildingModelMutations.final_energy}
            //@ts-ignore
            finalEnergyScaled={applyMutationResult?.applyBuildingModelMutations.final_energy_scaled}
          />
        )}

        <NavigationModal
          title={t('DataCollection_BuildingDocuments_UnsavedChangesInDraft-Title')}
          message={t('DataCollection_BuildingDocuments_UnsavedChangesInDraft-Message')}
          primaryActionText={t('General_Save')}
          shouldBlock={() => isTechEnvelopeDirty}
          blockOnSearchParamChange={false}
          primaryActionCallback={async () => {
            const buildingForm = await handleFormSubmit(false);
            return Boolean(buildingForm);
          }}
        />

        <ActionPlanDeleteConfirmationDialog
          actionPlanCount={affected_action_plan_count}
          setDeleteActionPlanModalOpen={setDeleteActionsModalOpen}
          open={openDeleteActionModal}
          onDeleteConfirm={() => {
            setDeleteActionsModalOpen(false);
            handleFormSubmit(false);
          }}
          deleteMessage={deleteMessage}
        />
      </>
    );
  },
);

export default BuildingTechnologyEnvelope;
