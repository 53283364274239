import {
  Box,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { ICONS } from '../../assets/icons';
import { BuildingTabEnum } from '../../pages/DataCollection/DataCollectionBuilding';
import { PATHS } from '../../routes/paths';
import Iconify from '../Iconify';

export type InfoTableData = {
  label: string;
  value: string | number | ReactNode;
  icon?: ReactNode;
};

type Props = {
  data: InfoTableData[];
  buildingId: number;
  title: string;
  background?: string;
  color?: string;
  showTab?: BuildingTabEnum;
};

const InfoTable = ({ data, buildingId, title, background, color, showTab = BuildingTabEnum.envelope }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box
      sx={{
        background: background ? background : theme.palette.grey[200],
        borderRadius: 1,
        p: 2,
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1} mb={1}>
        <Typography variant="subtitle1">{title}</Typography>
        <IconButton
          size="small"
          target="_blank"
          href={PATHS.dataCollection.building({ id: buildingId }, { tab: showTab })}
        >
          <Iconify icon={ICONS.EDIT} />
        </IconButton>
      </Stack>

      <TableContainer component={Paper} sx={{ background: 'transparent', overflow: 'hidden' }}>
        <Table
          sx={{
            p: 0,
            td: {
              px: 0,
              py: 1,
              '&:last-child': {
                pb: 0,
              },
            },
          }}
        >
          <TableBody>
            {data.map((row, index) => {
              let values = [row.value];
              if (typeof row.value === 'string') {
                values = row.value.split(' , ');
              }

              return (
                <TableRow
                  key={index}
                  sx={{
                    borderBottom: '1px solid',
                    borderColor: theme.palette.divider,
                    '&:last-child': {
                      borderBottom: 'none', // Remove border for the last row
                    },
                  }}
                >
                  <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} py={1}>
                    <Typography variant="body2" color={color} whiteSpace={'nowrap'}>
                      {row.label}
                    </Typography>
                    <Stack direction={'row'} alignItems={'center'}>
                      {values.length > 1 ? (
                        <Tooltip title={row.value}>
                          <Typography variant="body2">{t('General_Multiple')}</Typography>
                        </Tooltip>
                      ) : typeof row.value === 'string' && row.value.length > 30 ? (
                        <Tooltip title={row.value}>
                          <Typography variant="body2">{row.value.substring(0, 25) + '...'}</Typography>
                        </Tooltip>
                      ) : (
                        <Typography variant="body2">{row.value}</Typography>
                      )}
                      {row.icon ?? null}
                    </Stack>
                  </Stack>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default InfoTable;
