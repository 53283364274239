import { Trans } from 'react-i18next';
import Step1Location from './Step1Location';
import { ADDRESS_SEARCH } from './Step1Location/Substeps/AddressSearch';
import { BUILDING_SELECTION } from './Step1Location/Substeps/BuildingSelection';
import { MANUAL_ADDRESS } from './Step1Location/Substeps/ManualAddress';
import Step2Information from './Step2Information';
import Step3Review from './Step3Review';

export const steps = [
  {
    id: 'step1' as const,
    title: <Trans i18nKey="General_Location" />,
    Component: Step1Location,
    substeps: [ADDRESS_SEARCH, MANUAL_ADDRESS, BUILDING_SELECTION],
  },
  {
    id: 'step2' as const,
    title: <Trans i18nKey="General_Information" />,
    Component: Step2Information,
  },
  {
    id: 'step3' as const,
    title: <Trans i18nKey="General_Review" />,
    Component: Step3Review,
  },
] satisfies {
  id: string;
  title: React.ReactNode;
  Component: () => JSX.Element;
  substeps?: string[];
}[];

export type StepsType = (typeof steps)[number]['id'];
