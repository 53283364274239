import { Button, Stack, useTheme } from '@mui/material';
import { area_type_enum } from '@predium/enums';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Iconify from '../../../../components/Iconify';
import EnergyReferenceAreas from './Systems/EnergyReferenceAreas';
import TenantAreas from './Systems/TenantAreas';
import { useValidation } from './Validation/ValidationProvider';

const variantStyles = {
  error: {
    icon: 'mdi:alert-circle',
    color: 'error.main',
  },
  warning: {
    icon: 'mdi:alert',
    color: 'warning.main',
  },
};

const AreaTabs = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState<'tenantAreas' | 'energeticAreas'>('tenantAreas');
  const { getAreaAlerts } = useValidation();
  const theme = useTheme();

  const getStructureVariant = (areaType: area_type_enum) => {
    const alerts = getAreaAlerts(areaType);
    if (!alerts.length) {
      return null;
    }

    return alerts[0].variant;
  };

  const tenantStructureAreas = [area_type_enum.MF, area_type_enum.AF];
  const energyReferenceAreas = [area_type_enum.EBF];

  const tenantStructureVariant = tenantStructureAreas.map(getStructureVariant).find((variant) => variant);
  const energyStructureVariant = energyReferenceAreas.map(getStructureVariant).find((variant) => variant);

  const options = [
    {
      label: t('DataCollectionAreas_TenantStructure'),
      key: 'tenantAreas',
      variant: tenantStructureVariant ? variantStyles[tenantStructureVariant] : null,
    },
    {
      label: t('DataCollectionAreas_EnergeticStructure'),
      key: 'energeticAreas',
      variant: energyStructureVariant ? variantStyles[energyStructureVariant] : null,
    },
  ] as const;

  return (
    <Stack flex={0.6} spacing={2}>
      <Stack direction="row" spacing={2}>
        {options.map((option) => (
          <Button
            size="small"
            key={option.key}
            onClick={() => setActiveTab(option.key)}
            variant={activeTab === option.key ? 'outlined' : 'text'}
            sx={{
              px: 1,
              py: 0.5,
              '&.MuiButton-outlined': {
                bgcolor: theme.palette.grey[500_8],
                border: theme.palette.grey[500_8],
              },
            }}
          >
            {option.label}
            {/* Show error of the tab is not open and has error or warning */}
            {option.key !== activeTab && option.variant && (
              <Iconify sx={{ ml: 0.5 }} color={option.variant.color} icon={option.variant.icon} />
            )}
          </Button>
        ))}
      </Stack>
      {activeTab === 'tenantAreas' ? <TenantAreas /> : <EnergyReferenceAreas />}
    </Stack>
  );
};

export default AreaTabs;
