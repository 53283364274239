import { Box } from '@mui/material';
import { data_source_type_enum, energy_source_type_enum, energy_system_type_enum } from '@predium/enums';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { EnergySystemFormValues, System } from '../BuildingTechnology';
import {
  generateSystemFieldNames,
  generateSystemRouteFieldNames,
  generateUniqueNegativeId,
} from '../Common/building.util';
import TechnologyConsumerRoute from './TechnologySection/TechnologyRoute/TechnologyConsumerRoute';
import { TechnologySectionWrapper } from './TechnologySection/TechnologySectionWrapper';

type Props = {
  baseEmissionFactors: Record<energy_source_type_enum, number>;
  energySystem: energy_system_type_enum;
  defaultValues: EnergySystemFormValues;
};

const Technology: React.FC<Props> = ({ defaultValues, baseEmissionFactors, energySystem }) => {
  const { control, watch, setValue, getValues, clearErrors, setFocus } = useFormContext();

  const { remove, update } = useFieldArray({
    control,
    name: energySystem,
  });

  const systemFieldsArray = watch(energySystem);

  const resetFieldArray = (systemIndex: number, id: number) => {
    // remove it directly from field array if its newly added system
    if (id < 0) {
      remove(systemIndex);
      return;
    }
    // reset field array for existing system
    // @ts-ignore
    update(systemIndex, defaultValues[energySystem as keyof EnergySystemFormValues][systemIndex] ?? []);
    clearErrors(energySystem[systemIndex]);
  };

  const onAddRouteClick = (systemIndex: number, lastRouteIndex: number) => {
    const uniqueRouteId = generateUniqueNegativeId(getValues());
    const {
      energyRouteIdFieldname,
      energyFinalFieldname,
      energySystemTypeFieldname: routeSystemTypeFieldName,
      energyConsumerIdFieldname,
    } = generateSystemRouteFieldNames(energySystem, systemIndex, lastRouteIndex);

    setValue(energyRouteIdFieldname, generateUniqueNegativeId(getValues()), {
      shouldDirty: true,
    });
    setValue(routeSystemTypeFieldName, energySystem, {
      shouldDirty: true,
    });
    setValue(energyFinalFieldname, undefined, {
      shouldDirty: true,
    });
    setValue(energyConsumerIdFieldname, uniqueRouteId - 1, {
      shouldDirty: true,
    });

    const { dataSourceTypeFieldname } = generateSystemFieldNames(energySystem, systemIndex);

    setValue(dataSourceTypeFieldname, data_source_type_enum.MANUAL, {
      shouldDirty: true,
    });

    // Slight delay in setting a Focus on the energy final field as it is not yet rendered
    setTimeout(() => {
      setFocus(energyFinalFieldname);
    }, 10);
  };

  return (
    <>
      <Box key={energySystem + '_wrapper'} mb={3}>
        {systemFieldsArray.map(
          (system: System, index: number) =>
            system?.routes.length > 0 && (
              <TechnologySectionWrapper
                key={system.id}
                dataSourceType={system.dataSourceType}
                resetSystem={() => resetFieldArray(index, system.id)}
                energySystemType={energySystem as keyof EnergySystemFormValues}
                onAddRouteClick={() => onAddRouteClick(index, system.routes.length)}
                routes={system.routes}
                systemIndex={index}
              >
                <TechnologyConsumerRoute
                  key={index}
                  baseEmissionFactors={baseEmissionFactors}
                  systemIndex={index}
                  energySystem={system.type}
                />
              </TechnologySectionWrapper>
            ),
        )}
      </Box>
    </>
  );
};

export default Technology;
