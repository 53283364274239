import {
  Button,
  Checkbox,
  Chip,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ICONS } from '../../assets/icons';
import { useOverflowDetection } from '../../hooks/useOverflowDetection';
import { highlightText } from '../../utils/highlightText';
import Iconify from '../Iconify';
import OverflowText from '../OverflowText';
import Scrollbar from '../Scrollbar';
import { getChipStyles } from './filterStyle';

export type FilterItem = {
  name: string;
  value: string | number;
  description?: string;
};

type MultiSelectProps = {
  /**
   * Array of selectable items.
   */
  items: FilterItem[];
  /**
   * The label displayed when multiple items are selected.
   */
  selectedLabel: string;
  /**
   * Label displayed when no item is selected.
   */
  label: string;
  /**
   * Enables the search functionality in the dropdown.
   * @default false
   */
  searchable?: boolean;
  /**
   * Temporary selection state for managing selection before applying changes.
   */
  tempSelection: (string | number)[];
  /**
   * Callback function triggered when selections change.
   * @param values The newly selected values.
   */
  handleSelectionChange: (values: (string | number)[]) => void;
  /**
   * Function triggered when the user applies selected filters.
   */
  applySelection?: () => void;
  /**
   * Function triggered when the user clears all selected filters.
   */
  clearSelection?: () => void;
  /**
   * Disables the Apply button if no changes have been made.
   * @default false
   */
  isApplyDisabled?: boolean;
  /**
   * Disables the Clear button if no selection exists.
   * @default false
   */
  isClearDisabled?: boolean;
  /**
   * Optional styles for the component.
   */
  sx?: object;
  /**
   * Customizable text displayed when no results are found.
   */
  noResultsText: string;
  /**
   * Array of currently selected values.
   */
  selectedFilter?: (string | number)[];
};

export default function MultiSelectFilterWithSearch({
  items,
  selectedLabel,
  label,
  searchable = false,
  tempSelection,
  handleSelectionChange,
  applySelection,
  clearSelection,
  isApplyDisabled = false,
  isClearDisabled = false,
  sx,
  noResultsText,
  selectedFilter = [],
  ...props
}: MultiSelectProps) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [searchInput, setSearchInput] = useState('');
  const { t } = useTranslation();

  const chipRef = useRef(null);
  const { textRefs, truncatedStates, startObserving, stopObserving } = useOverflowDetection(items);

  const closeMenu = () => {
    setAnchorEl(null);
    stopObserving();
  };

  const openMenu = () => {
    setAnchorEl(chipRef.current);
    startObserving();
  };

  const filteredItems = items.filter((item) => item.name.toLowerCase().includes(searchInput.toLowerCase()));
  const noResults = filteredItems.length === 0;

  const filterLabelContent =
    selectedFilter.length === 1
      ? items.find((item) => item.value === selectedFilter[0])?.name || label
      : `${selectedFilter.length} ${selectedLabel}`;

  const filterLabel = (
    <Stack
      flexDirection={'row'}
      alignItems={'center'}
      gap={0.5}
      sx={{
        display: 'flex',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        flex: 1,
        minWidth: 0,
      }}
    >
      {!filterLabelContent.includes('All') && (
        <Iconify icon={ICONS.CHECK} color="text.secondary" width={16} height={16} />
      )}
      <Typography
        noWrap
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          flex: 1,
          minWidth: 0,
          fontWeight: 600,
        }}
      >
        {filterLabelContent}
      </Typography>
    </Stack>
  );

  return (
    <>
      <Chip
        {...props}
        ref={chipRef}
        label={filterLabel}
        onClick={openMenu}
        onDelete={openMenu}
        deleteIcon={
          <Iconify
            icon={ICONS.KEYBOARD_ARROW_DOWN}
            color="text.secondary"
            sx={{
              transition: 'transform 0.3s ease-in-out',
              transform: anchorEl ? 'rotate(180deg)' : 'rotate(0deg)',
            }}
          />
        }
        sx={getChipStyles({
          theme,
          isApplyDisabled,
          isOpen: Boolean(anchorEl),
          isDefaultSelected: false,
          sx,
        })}
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        sx={{
          mt: 1,
          '& .MuiPaper-root': {
            width: '480px',
          },
          '& .MuiMenuItem-root': {
            whiteSpace: 'normal',
            wordBreak: 'break-word',
            width: 'auto',
            flex: '1',
          },
        }}
      >
        {searchable && (
          <TextField
            placeholder="Search"
            value={searchInput}
            autoComplete="off"
            onKeyDown={(e) => e.stopPropagation()}
            onChange={(e) => setSearchInput(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon={ICONS.MAGNIFY} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setSearchInput('')}
                    sx={{
                      color: 'text.secondary',
                      visibility: searchInput ? 'visible' : 'hidden',
                    }}
                  >
                    <Iconify icon={ICONS.CLOSE} width={18} height={18} />
                  </IconButton>
                </InputAdornment>
              ),
              style: {
                paddingRight: '14px',
              },
            }}
            sx={{
              width: 'calc(100% - 32px)',
              height: 36,
              '.MuiOutlinedInput-input': {
                py: 1,
                boxSizing: 'border-box',
                height: 36,
              },
              mx: 2,
              mb: 2,
              position: 'sticky',
              top: 0,
              backgroundColor: 'background.paper',
              zIndex: 1,
            }}
          />
        )}

        <Scrollbar sx={{ maxHeight: 400 }}>
          {filteredItems.map((item) => (
            <MenuItem
              key={item.value}
              onClick={() => handleSelectionChange([item.value])}
              sx={{
                pl: 1,
                px: 1,
                height: 'auto',
                minHeight: 48,
                bgcolor: tempSelection.includes(item.value) ? theme.palette.action.selected : 'inherit',
                '&:hover': { bgcolor: theme.palette.action.hover },
              }}
            >
              <Checkbox checked={tempSelection.includes(item.value)} />
              <Tooltip
                title={truncatedStates[item.value] ? item.name : ''}
                arrow
                disableInteractive
                placement="top-end"
                PopperProps={{
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [65, 0],
                      },
                    },
                  ],
                }}
              >
                <Stack ref={(el: HTMLDivElement | null) => (textRefs.current[item.value] = el)}>
                  <OverflowText text={highlightText(item.name, searchInput)} maxWidth="410px" />
                  {item.description && (
                    <Typography variant="body2" color="text.secondary">
                      {item.description}
                    </Typography>
                  )}
                </Stack>
              </Tooltip>
            </MenuItem>
          ))}
          {noResults && (
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ width: '100%', textAlign: 'center', p: '24px 24px 16px 24px' }}
            >
              {items.length === 0 ? noResultsText : t('General_NoSearchResultsFound')}
            </Typography>
          )}
        </Scrollbar>

        {(clearSelection || applySelection) && (
          <Stack direction="row" justifyContent="space-between" sx={{ padding: '8px 16px 8px 10px' }}>
            {clearSelection && (
              <Button
                variant="text"
                size="medium"
                onClick={() => {
                  clearSelection();
                  closeMenu();
                }}
                disabled={isClearDisabled}
                sx={{ width: 'min-content', whiteSpace: 'nowrap', pr: '12px' }}
              >
                {t('General_Clear')}
              </Button>
            )}
            {applySelection && (
              <Button
                variant="contained"
                onClick={() => {
                  applySelection();
                  closeMenu();
                }}
                disabled={isApplyDisabled}
              >
                {t('General_Apply')}
              </Button>
            )}
          </Stack>
        )}
      </Menu>
    </>
  );
}
