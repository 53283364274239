import { unit_enum } from '@predium/enums';
import { ValueOf } from 'type-fest';

export const Units = {
  /** Displayed in English as `d` */
  days: unit_enum.DAYS,
  /** Displayed in English as `yr` */
  year: unit_enum.YEAR,
  /** Displayed in English as `%` */
  percentage: unit_enum.PERCENTAGE,
  /** Displayed in English as `kg` */
  kilogram: unit_enum.KILOGRAM,
  /** Displayed in English as `m` */
  meters: unit_enum.METERS,
  /** Displayed in English as `cm` */
  centimeters: unit_enum.CENTIMETERS,
  /** Displayed in English as `m²` */
  area: unit_enum.SQM,
  /** Displayed in English as `x */
  times: unit_enum.TIMES,

  /* For cost, price and taxes - Start*/
  /** Displayed in English as `€` */
  price: unit_enum.EURO,
  /** Displayed in English as `€/kWh` */
  pricePerEnergy: unit_enum.EURO_PER_KWH,
  /** Displayed in English as `€/m²` */
  pricePerArea: unit_enum.EURO_PER_SQM,
  /** Displayed in English as `€/m²·yr` */
  pricePerAreaYear: unit_enum.EURO_PER_SQM_PER_YEAR,
  /** Displayed in English as `€/yr` */
  pricePerYear: unit_enum.EURO_PER_YEAR,
  /** Displayed in English as `€/kWp` */
  pricePerKwp: unit_enum.EURO_PER_KW_PEAK,
  /* For cost, price and taxes - End*/

  /* For primary and final energy, energy demand, heating, electricity, storage loss, distribution loss - Start*/
  /** Displayed in English as `kWh` */
  energy: unit_enum.KWH,
  /** Displayed in English as `kWh/yr` */
  energyPerYear: unit_enum.KWH_PER_YEAR,
  /** Displayed in English as `kWh/m²` */
  energyPerArea: unit_enum.KWH_PER_SQM,
  /** Displayed in English as `kWh/m²·yr` */
  energyPerAreaYear: unit_enum.KWH_PER_SQM_PER_YEAR,
  /* For primary and final energy, energy demand, heating, electricity, storage loss, distribution loss - End*/

  /** Displayed in English as `kg CO₂e` */
  co2Emissions: unit_enum.KG_CO2E,
  /** Displayed in English as `kg CO₂e/yr` */
  co2EmissionsPerYear: unit_enum.KG_CO2E_PER_YEAR,
  /** Displayed in English as `kg CO₂e/m²` */
  co2EmissionsPerArea: unit_enum.KG_CO2E_PER_SQM,
  /** Displayed in English as `kg CO₂e/m²·yr` */
  co2EmissionsPerAreaYear: unit_enum.KG_CO2E_PER_SQM_PER_YEAR,

  /** Displayed in English as `t CO₂e` */
  excessiveEmissions: unit_enum.TONS_CO2E,

  /** Displayed in English as `kg CO₂e/kWh` */
  emissionFactor: unit_enum.KG_CO2E_PER_KWH,
  /** Displayed in English as `g CO₂e/kWh` */
  emissionFactorGrams: unit_enum.G_CO2E_PER_KWH,
  /** Displayed in English as `g CO₂e/€·yr` */
  emissionFactorGramsPricePerYear: unit_enum.G_CO2E_PER_EURO_PER_YEAR,
  /** Displayed in English as `kg CO₂e/MWh` */
  emissionFactorMegawatt: unit_enum.KG_CO2E_PER_MWH,
  /** Displayed in English as `kg CO₂e/m²` */
  emissionFactorPerArea: unit_enum.KG_CO2E_PER_SQM,
  /** Displayed in English as `kg CO₂e/m²·yr` */
  emissionFactorPerAreaYear: unit_enum.KG_CO2E_PER_SQM_PER_YEAR,
  /** Displayed in English as `kg CO₂e/yr` */
  emissionFactorPerYear: unit_enum.KG_CO2E_PER_YEAR,

  /** Displayed in English as `W/(m·K)` */
  thermalConductivityLambda: unit_enum.WATT_PER_M_PER_KELVIN,
  /** Displayed in English as `W/(m²·K)` */
  uValue: unit_enum.WATT_PER_SQM_PER_KELVIN,

  /** Displayed in English as `°` */
  inclination: unit_enum.DEGREES,
  /** Displayed in English as `°` */
  orientation: unit_enum.DEGREES,

  /** Displayed in English as `lm` */
  lighting: unit_enum.LUMENS,
  /** Displayed in English as `lm/W` */
  lightYield: unit_enum.LUMENS_PER_WATT,

  /** Displayed in English as `°C` */
  internalTemperature: unit_enum.CENTIGRADE,

  /** Displayed in English as `m³` */
  waterConsumption: unit_enum.CUBIC_METERS,
  /** Displayed in English as `m³·m²` */
  waterConsumptionPerArea: unit_enum.CUBIC_METERS_PER_SQM,

  /** Displayed in English as `t` */
  wasteConsumptionTons: unit_enum.TONS,
  /** Displayed in English as `t/m²` */
  wasteConsumptionTonsPerArea: unit_enum.TONS_PER_SQM,

  /** Displayed in English as `kWp` */
  solarModulePower: unit_enum.KW_PEAK,
} as const;

type Units = typeof Units;
export type UnitsValue = ValueOf<Units>;
