import {
  Box,
  Button,
  FormHelperText,
  Grid,
  InputAdornment,
  MenuItem,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { getSupportedSourceTypesForSystemType } from '@predium/client-lookup';
import { energy_certificate_type_enum, energy_source_type_enum, energy_system_type_enum } from '@predium/enums';
import {
  translateEnergySourceTypeEnum,
  translateEnergySystemTypeEnum,
  translateUnitEnum_dynamic,
} from '@predium/i18n/client';
import { Units } from '@predium/utils';
import { useFieldArray, useFormContext, useFormState } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { ICONS } from '../../../../assets/icons';
import Iconify from '../../../../components/Iconify';
import InfoTooltip from '../../../../components/InfoTooltip';
import { RHFNumberField, RHFSelect } from '../../../../components/hook-form';
import RHFToggleButton from '../../../../components/hook-form/RHFToggleButton';
import { useLanguage } from '../../../../provider/LanguageProvider';
import { EnergyCertificateDraftFormValueProps } from './EnergyCertificateDraftForm';

const FILTERED_SYSTEM_TYPES = Object.keys(energy_system_type_enum).filter(
  (systemType) =>
    ![energy_system_type_enum.GENERAL, energy_system_type_enum.SOLAR].includes(
      energy_system_type_enum[systemType as energy_system_type_enum],
    ),
) as energy_system_type_enum[];

export default function EnergyCertificateDraftEnergyDataTab() {
  const { t } = useTranslation();
  const { localize } = useLanguage();
  const theme = useTheme();

  const { control, watch, trigger, setValue } = useFormContext<EnergyCertificateDraftFormValueProps>();
  const { errors } = useFormState({ control });
  const { fields, append, remove } = useFieldArray({
    name: 'energy_certificate_consumers',
    control,
  });

  // Make sure to get updates on value changes (fields only contains default values)
  const watchFieldArray = watch('energy_certificate_consumers');
  const controlledFields = fields.map((field, index) => ({
    ...field,
    ...watchFieldArray[index],
  }));
  const energyCertificateType = watch('energy_certificate_type_id');
  const energyInfoTooltip =
    energyCertificateType === energy_certificate_type_enum.CONSUMPTION
      ? t('DataCollection_Building_EnergyDataTab_InfoTooltip-consumption')
      : t('DataCollection_Building_EnergyDataTab_InfoTooltip-demand');

  const onAppendEnergyConsumer = () => {
    // We can not set the value to undefined because useFieldArray will take its old values.
    // This behavior is reproducible in isolation:
    // https://codesandbox.io/s/react-hook-form-mui-forked-xcrw87?file=/src/App.tsx
    // @ts-ignore
    append({ energy_source_type_id: '', energy_system_type_id: '', energy_final: '' });
  };

  const onRemoveEnergyCertificateConsumer = (index: number) => {
    remove(index);
    trigger('energy_certificate_consumers');
  };

  const energyConsumerMessage: string = errors?.energy_certificate_consumers?.message as string;

  return (
    <>
      <Box sx={{ px: 2, py: 4 }}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h6">{t('General_TypeOfCertificate')}</Typography>
          </Grid>
          <Grid item xs={12}>
            <RHFToggleButton
              name="energy_certificate_type_id"
              leftValue={{
                key: energy_certificate_type_enum.DEMAND,
                value: t('General_EnergyDemandCertificate'),
              }}
              rightValue={{
                key: energy_certificate_type_enum.CONSUMPTION,
                value: t('General_EnergyConsumptionCertificate'),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" alignItems="center">
              <Typography variant="h6">{t('General_EnergySource')}</Typography>
              <InfoTooltip text={energyInfoTooltip} maxwidth={800} />
            </Stack>
          </Grid>
          {fields.map((field, index) => {
            const fieldValue = controlledFields[index];
            const energySystemType = fieldValue.energy_system_type_id;
            const supportedEnergySources =
              getSupportedSourceTypesForSystemType(energySystemType) &&
              getSupportedSourceTypesForSystemType(energySystemType).length > 0
                ? getSupportedSourceTypesForSystemType(energySystemType)
                : [];

            // TODO: rename 'ELECTRICITY' to 'OTHER' (PRE-1433)
            // Move element 'ELECTRICITY' to the end of availableSystemTypes array
            const electricityIndex = FILTERED_SYSTEM_TYPES.indexOf(energy_system_type_enum.ELECTRICITY);
            if (electricityIndex > -1) {
              FILTERED_SYSTEM_TYPES.push(FILTERED_SYSTEM_TYPES.splice(electricityIndex, 1)[0]);
            }

            return (
              <Grid
                container
                key={field.id}
                spacing={2}
                sx={{
                  ml: 4,
                  mt: 2,
                  pr: 2,
                  pb: 2,
                  border: `1px solid ${theme.palette.grey[500_12]}`,
                  borderRadius: '10px',
                }}
              >
                <div style={{ width: '100%', position: 'relative' }}>
                  <Iconify
                    icon={ICONS.REMOVE}
                    onClick={() => onRemoveEnergyCertificateConsumer(index)}
                    height={24}
                    width={24}
                    color={theme.palette.grey[600]}
                    sx={{ position: 'absolute', top: '-12px', right: '-24px', cursor: 'pointer' }}
                  />
                </div>
                <Grid item xs={12} container sx={{ pt: 0 }}>
                  <Grid item xs={6}>
                    <RHFSelect
                      name={`energy_certificate_consumers.${index}.energy_system_type_id`}
                      label={t('General_EnergyConsumer')}
                      includeEmptyOption
                      onValueChange={() => {
                        setValue(
                          `energy_certificate_consumers.${index}.energy_source_type_id`,
                          '' as energy_source_type_enum,
                        );
                      }}
                    >
                      {FILTERED_SYSTEM_TYPES.map((systemType) => (
                        <MenuItem key={systemType} value={systemType}>
                          {translateEnergySystemTypeEnum(
                            energy_system_type_enum[systemType as energy_system_type_enum],
                          )}
                        </MenuItem>
                      ))}
                    </RHFSelect>
                  </Grid>
                  <Grid item xs={6} pl={2}>
                    <RHFSelect
                      name={`energy_certificate_consumers.${index}.energy_source_type_id`}
                      label={t('General_EnergySource')}
                      includeEmptyOption
                      disabled={!energySystemType}
                      onValueChange={() => {
                        trigger('energy_certificate_consumers');
                      }}
                    >
                      {supportedEnergySources.map((sourceType) => (
                        <MenuItem key={sourceType} value={sourceType}>
                          {translateEnergySourceTypeEnum(
                            energy_source_type_enum[sourceType as energy_source_type_enum],
                          )}
                        </MenuItem>
                      ))}
                    </RHFSelect>
                  </Grid>
                </Grid>

                <Grid item xs={6}>
                  <RHFNumberField
                    name={`energy_certificate_consumers.${index}.energy_final`}
                    label={
                      energyCertificateType === energy_certificate_type_enum.CONSUMPTION
                        ? t('General_FinalEnergyConsumption')
                        : t('General_FinalEnergyDemand')
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {translateUnitEnum_dynamic(Units.energyPerAreaYear, t)}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            );
          })}

          {energyConsumerMessage && (
            <Grid sx={{ ml: 4 }}>
              <FormHelperText error>{energyConsumerMessage}</FormHelperText>
            </Grid>
          )}

          <Grid item xs={12}>
            <Button
              variant="outlined"
              fullWidth
              startIcon={<Iconify icon="akar-icons:circle-plus" />}
              onClick={onAppendEnergyConsumer}
              sx={{ py: 1.5 }}
            >
              {t('General_AddAdditionalEnergyConsumer')}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ backgroundColor: theme.palette.grey[700] }}>
        <Box sx={{ backgroundColor: 'white', height: 16, borderBottomLeftRadius: 16, borderBottomRightRadius: 16 }} />
        <Stack direction="row" sx={{ px: 4, py: 1 }} alignItems="center" justifyContent="space-between">
          <Typography variant="body1" color="white">
            <Trans
              i18nKey={'DataCollection_EnergyDataTab-Title'}
              values={{
                type:
                  energyCertificateType === energy_certificate_type_enum.CONSUMPTION
                    ? t('General_FinalEnergyConsumption')
                    : t('General_FinalEnergyDemand'),
              }}
            />
          </Typography>
          <Stack
            direction="row"
            alignItems="baseline"
            sx={{ px: 2, py: 0.5, border: '1px solid white', borderRadius: 1 }}
          >
            <Typography variant="h4" color="white">
              {localize.formatAsCompact(controlledFields.reduce((acc, prev) => (acc += prev.energy_final ?? 0), 0))}
            </Typography>
            <Typography variant="caption" color="white" sx={{ pl: '4px' }}>
              {translateUnitEnum_dynamic(Units.energyPerAreaYear, t)}
            </Typography>
          </Stack>
        </Stack>
      </Box>
    </>
  );
}
