import { MenuItem, Stack, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BUILDING_CREATION_ICONS } from '../../../../../assets/icons';
import RHFAutocomplete, { AutoCompleteOption } from '../../../../../components/hook-form/RHFAutoComplete';
import Iconify from '../../../../../components/Iconify';
import useAddressSearch from '../hooks/useAddressSearch';

type Props = {
  onAddressChange?: (nextOptionvalue: AutoCompleteOption | null) => void;
  onEnterPress?: () => void;
};

const AddressSearchInput = ({ onAddressChange, onEnterPress }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState('');
  const { data } = useAddressSearch({ searchQuery: inputValue });

  const getInputLabel = (option: AutoCompleteOption) => {
    if (typeof option === 'string' || typeof option === 'number') return option;

    return option.inputValue || option.label || '';
  };

  const renderOption = (props: any, option: AutoCompleteOption) => {
    const isObject = typeof option === 'object';
    const text = isObject ? option.label : option;
    const showIcon = isObject && option.inputValue;
    const textColor = showIcon ? 'text.secondary' : 'text.primary';

    return (
      <MenuItem {...props} value={text}>
        <Stack direction="row" alignItems="center">
          {showIcon && (
            <Iconify icon={BUILDING_CREATION_ICONS.PENCIL_OUTLINE} width={20} height={20} color="text.secondary" />
          )}
          <Typography variant="subtitle2" fontWeight={400} color={textColor} marginLeft={theme.spacing(1)}>
            {text}
          </Typography>
        </Stack>
      </MenuItem>
    );
  };

  const filterOptions = (options: AutoCompleteOption[]) => {
    const items = [...options];

    if (inputValue)
      items.push({
        inputValue,
        label: t('BuildingCreation_ManualAddress'),
      });

    return items;
  };

  return (
    <RHFAutocomplete
      freeSolo
      clearOnBlur
      forcePopupIcon
      name="addressSearch"
      label={t('BuildingCreation_SearchAddress')}
      type="text"
      options={data}
      popupIcon={<Iconify icon={BUILDING_CREATION_ICONS.MAP_MARKER} width={24} height={24} />}
      getOptionLabel={getInputLabel}
      filterOptions={filterOptions}
      renderOption={renderOption}
      onCustomOnChange={onAddressChange}
      onInputChange={(_, value) => setInputValue(value)}
      InputProps={{
        onKeyDown: (e) => {
          if (e.key === 'Enter') {
            onEnterPress?.();
            e.preventDefault();
          }
        },
      }}
      componentsProps={{
        paper: {
          elevation: 2,
        },
      }}
    />
  );
};

export default AddressSearchInput;
