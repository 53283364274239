import { Box, CardProps, Stack, useTheme } from '@mui/material';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { translateUnitEnum_dynamic } from '@predium/i18n/client';
import { UnitsValue } from '@predium/utils';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../provider/LanguageProvider';
import BenchmarkLabel from '../sections/EsgAnalysis/Components/BenchmarkLabel';

interface Props extends CardProps {
  title: string;
  value?: number | null;
  change?: number | null;
  /**
   * Hide the change label if the change is 0
   */
  hideNoChange?: boolean;
  unit: UnitsValue;
  titleEndAdornment?: ReactNode;
  selectedBenchmarkName?: string;
  isCardLayout?: boolean;
  showTotal?: boolean;
}

export default function SimpleComparisonMetric({
  title,
  value,
  change,
  hideNoChange,
  unit,
  titleEndAdornment,
  selectedBenchmarkName = '',
  isCardLayout = false,
  sx,
  showTotal = false,
}: Props) {
  const theme = useTheme();
  const { t } = useTranslation();
  const { localize } = useLanguage();

  const metric = (
    <Stack sx={{ height: '100%' }}>
      <Stack direction="row" alignItems="center" flexWrap="wrap">
        <Typography variant="subtitle2" sx={{ padding: '2px 0', marginRight: 'auto' }}>
          {title}
        </Typography>
      </Stack>
      <Stack direction="row" whiteSpace="nowrap">
        <Stack alignItems={'baseline'} direction={'row'}>
          <Typography variant="h3">
            {localize.formatAsCompact(value, {
              roundToNearestHundred: showTotal,
            })}
          </Typography>
          <Typography
            variant="subtitle2"
            color={theme.palette.grey[500]}
            sx={{ display: 'flex', alignItems: 'baseline', ml: 0.5 }}
          >
            {translateUnitEnum_dynamic(unit, t)}{' '}
          </Typography>
        </Stack>

        {titleEndAdornment}
      </Stack>
      {selectedBenchmarkName && selectedBenchmarkName.length > 0 && (
        <BenchmarkLabel change={change} hideNoChange={hideNoChange} selectedBenchmarkName={selectedBenchmarkName} />
      )}
    </Stack>
  );

  return isCardLayout ? <Card sx={{ p: 2, height: '100%', ...sx }}>{metric}</Card> : <Box sx={{ ...sx }}>{metric}</Box>;
}
