import { SxProps, TextField, TextFieldProps, Theme } from '@mui/material';
import { language_enum } from '@predium/enums';
import { Controller, useFormContext } from 'react-hook-form';
import NumberFormat, { NumberFormatProps, NumberFormatValues, SourceInfo } from 'react-number-format';
import { useLanguage } from '../../provider/LanguageProvider';

interface IProps {
  name: string;
  label?: string;
  valueAsText?: boolean;
  min?: number;
  max?: number;
  size?: TextFieldProps['size']; // Update the type declaration for size
  sx?: SxProps<Theme>;
  disabled?: boolean;
  onBlur?: () => void;
  onEnter?: () => void;
  onValueChange?: (values: NumberFormatValues, sourceInfo: SourceInfo) => void;
  variant?: TextFieldProps['variant'];
  placeholder?: string;
  decimalScale?: number;
  thousandSeparator?: string;
  decimalSeparator?: string;
  suffix?: string;
  helperText?: string;
  nullable?: boolean;
  autoFocus?: boolean;
  numberFormatProps?: NumberFormatProps<TextFieldProps>;
  allowDecimals?: boolean;
  maxDecimalPlaces?: number;
  autoComplete?: TextFieldProps['autoComplete'];
  allowLeadingZeros?: boolean;
}

export type RHFNumberFieldProps = IProps & Pick<TextFieldProps, 'InputProps' | 'onChange'>;

export default function RHFNumberField({
  name,
  valueAsText,
  size = 'medium',
  disabled = false,
  min,
  max,
  sx,
  onBlur,
  onEnter,
  variant,
  placeholder,
  helperText,
  nullable,
  onValueChange,
  numberFormatProps = {},
  allowDecimals = true,
  autoComplete = 'on',
  maxDecimalPlaces = 3,
  allowLeadingZeros = false,
  ...other
}: RHFNumberFieldProps) {
  const { control } = useFormContext();
  const { language } = useLanguage();
  const decimalSeparator = language === language_enum.deDE ? ',' : '.';
  const thousandSeparator = language === language_enum.deDE ? '.' : ',';

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, ...field }, fieldState: { error } }) => {
        const handleValueChange = (values: NumberFormatValues, sourceInfo: SourceInfo) => {
          if (nullable && values.value === '') {
            field.onChange(null);
          } else if (valueAsText) {
            field.onChange(values.value);
          } else {
            field.onChange(values.floatValue ?? null);
            onValueChange?.(values, sourceInfo);
          }
        };

        const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
          if (e.key === 'Enter' || e.key === 'Escape') {
            onBlur?.(); // Trigger onBlur on Enter key press
            onEnter?.();
            e.preventDefault();
            e.stopPropagation();
          }
        };

        const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
          e.preventDefault();
          let pasteData = e.clipboardData.getData('Text').trim();

          if (!pasteData) return;

          // Check if pasted number is in German format (1.234,56)
          const isGermanFormat = /^[0-9]+(?:\.[0-9]{3})*(?:,[0-9]+)?$/.test(pasteData);

          // Check if pasted number is in English format (1,234.56)
          const isEnglishFormat = /^[0-9]+(?:,[0-9]{3})*(?:\.[0-9]+)?$/.test(pasteData);

          let normalizedNumber = pasteData;

          if (isGermanFormat) {
            // Convert German format (1.234,56) → (1234.56)
            normalizedNumber = pasteData.replace(/\./g, '').replace(',', '.');
          } else if (isEnglishFormat) {
            // Convert English format (1,234.56) → (1234.56)
            normalizedNumber = pasteData.replace(/,/g, '');
          }

          // Convert to float
          const parsedValue = parseFloat(normalizedNumber);
          if (isNaN(parsedValue)) return;

          // Update the field with the correctly formatted number
          field.onChange(parsedValue);
        };

        const onChange = (_: React.ChangeEvent<HTMLInputElement>) => {
          //intentionally left blank to prevent input field to set the value as string
          //This won't work with German formatting, so we use onValueChange instead
        };

        return (
          <NumberFormat
            //isNumericString is necessary for when the language is in german, otherwise the decimalSeparator won't work as expected
            isNumericString
            allowNegative={false}
            allowLeadingZeros={allowLeadingZeros}
            allowedDecimalSeparators={allowDecimals ? [',', '.'] : undefined}
            decimalSeparator={allowDecimals ? decimalSeparator : undefined}
            thousandSeparator={allowDecimals ? thousandSeparator : false}
            decimalScale={allowDecimals ? maxDecimalPlaces : 0} // Default to 3 decimal places if decimals are allowed
            inputRef={ref}
            variant={variant}
            customInput={TextField}
            color="primary"
            fullWidth
            size={size}
            error={!!error}
            helperText={error?.message ?? helperText}
            sx={[{ maxWidth: '100%' }, ...(Array.isArray(sx) ? sx : [sx])]}
            disabled={disabled}
            onKeyDown={handleKeyDown}
            onPaste={handlePaste}
            placeholder={placeholder}
            autoComplete={autoComplete}
            isAllowed={(values) => {
              if (!min && !max) {
                return true;
              }
              const floatValue = values?.floatValue;
              const isValueWithinRange =
                (typeof min !== 'number' || (floatValue !== undefined && floatValue >= min)) &&
                (typeof max !== 'number' ||
                  (floatValue !== undefined && floatValue <= max) ||
                  (!min && floatValue === undefined));
              // if max is defined an min is 0: allow empty input
              return isValueWithinRange;
            }}
            {...numberFormatProps}
            {...field}
            {...other}
            onBlur={onBlur}
            onChange={onChange}
            onValueChange={handleValueChange}
          />
        );
      }}
    />
  );
}
