/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  Boolean_comparison_exp,
  BuildingWhere,
  Int_comparison_exp,
  building_bool_exp,
  building_state_enum_comparison_exp,
  efficiency_class_enum_comparison_exp,
  energy_source_type_enum_comparison_exp,
  eu_taxonomy_compliance_enum_comparison_exp,
  sub_building_class_enum_comparison_exp,
  tax_bracket_enum_comparison_exp,
  type_of_use_enum_comparison_exp,
} from '@predium/client-graphql';
import {
  ConjunctionEnum,
  OperatorEnum,
  OperatorInEnum,
  area_select_column,
  building_model_select_column,
  building_select_column,
  building_state_enum,
  economic_unit_select_column,
  efficiency_class_enum,
  energy_path_select_column,
  energy_source_type_enum,
  energy_system_consumer_route_select_column,
  eu_taxonomy_compliance_enum,
  sub_building_class_enum,
  tax_bracket_enum,
  type_of_use_enum,
} from '@predium/enums';
import {
  IConjunctionWhereInt,
  IWhereBoolean,
  IWhereBuildingState,
  IWhereEfficiencyClass,
  IWhereEnergySourceType,
  IWhereEuTaxonomyCompliance,
  IWhereInt,
  IWhereInts,
  IWhereSubBuildingClass,
  IWhereTaxBracket,
  IWhereTypeOfUse,
} from '@predium/graphql-where-types';
import countBy from 'lodash/countBy';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import set from 'lodash/set';

/**
 * Abstract class for defining a filter class which can be used for graphql where on the sub_building table and also as getAnalyzedSubBuildings parameter.
 */
export abstract class WhereFilter<A, B> {
  constructor(readonly propertyName: string) {}

  abstract toGetAnalyzedSubBuildingsWhere(): A | null;

  abstract toSubBuildingsWhere(): B | null;
}

class SimpleWhereIntsFilter extends WhereFilter<IWhereInts, Int_comparison_exp> {
  private readonly ints: number[];

  constructor(propertyName: string, ints: number[]) {
    super(propertyName);
    this.ints = ints.filter((i) => !!i);
  }

  toGetAnalyzedSubBuildingsWhere(): IWhereInts | null {
    if (this.ints.length > 0) {
      return {
        op: OperatorInEnum._in,
        value: this.ints,
      };
    }
    return null;
  }

  toSubBuildingsWhere(): Int_comparison_exp | null {
    if (this.ints.length > 0) {
      return {
        _in: this.ints,
      };
    }
    return null;
  }
}

class SimpleWhereBooleansFilter extends WhereFilter<IWhereBoolean, Boolean_comparison_exp> {
  private readonly booleans: boolean[];

  constructor(propertyName: string, booleans: boolean[]) {
    super(propertyName);
    this.booleans = booleans;
  }

  toGetAnalyzedSubBuildingsWhere(): IWhereBoolean | null {
    if (this.booleans.length > 0) {
      // Prisma doesn't support _in for boolean, so we can't send both!
      const isBothSelected = [true, false].every((value) => this.booleans.includes(value));
      if (isBothSelected) {
        return null;
      }

      return {
        op: OperatorEnum._eq,
        value: this.booleans[0],
      };
    }
    return null;
  }

  toSubBuildingsWhere(): Boolean_comparison_exp | null {
    if (this.booleans.length > 0) {
      const isBothSelected = [true, false].every((value) => this.booleans.includes(value));
      if (isBothSelected) {
        return null;
      }

      return {
        _eq: this.booleans[0],
      };
    }
    return null;
  }
}

// Hasura relations and thus not available through prisma.<xyz>_column enums
const ACTIVE_BUILDING_MODEL_PROPERTY_NAME = 'active_building_model';
const ECONOMIC_UNIT_PROPERTY_NAME = 'economic_unit';
const ENERGY_PATH_NESTED_PROPERTY_NAME = 'energy_paths';
const ENERGY_SYSTEM_NESTED_PROPERTY_NAME = 'energy_systems';
const ENERGY_SYSTEM_CONSUMER_ROUTE_NESTED_PROPERTY_NAME = 'energy_system_consumer_routes';
const AREA_NESTED_PROPERTY_NAME = 'areas';

export class InBuildingIdFilter extends SimpleWhereIntsFilter {
  constructor(buildingIds: number[]) {
    super(building_select_column.id, buildingIds);
  }
}

export class InBuildingStateFilter extends WhereFilter<IWhereBuildingState, building_state_enum_comparison_exp> {
  constructor(private readonly buildingState: building_state_enum[]) {
    super(building_select_column.building_state_id);
  }

  toGetAnalyzedSubBuildingsWhere(): IWhereBuildingState | null {
    if (this.buildingState.length > 0) {
      return {
        op: OperatorInEnum._in,
        value: this.buildingState,
      };
    }
    return null;
  }

  toSubBuildingsWhere(): building_state_enum_comparison_exp | null {
    if (this.buildingState.length > 0) {
      return {
        [OperatorInEnum._in]: this.buildingState,
      };
    }
    return null;
  }
}

export class InMonumentProtectionFilter extends SimpleWhereBooleansFilter {
  constructor(booleans: boolean[]) {
    super(building_select_column.monument_protection, booleans);
  }
}

export class InHeritageDistrictFilter extends SimpleWhereBooleansFilter {
  constructor(booleans: boolean[]) {
    super(building_select_column.heritage_district, booleans);
  }
}

export class InMilieuProtectionFilter extends SimpleWhereBooleansFilter {
  constructor(booleans: boolean[]) {
    super(building_select_column.milieu_protection, booleans);
  }
}

export class InLeaseholdFilter extends SimpleWhereBooleansFilter {
  constructor(booleans: boolean[]) {
    super(building_select_column.leasehold, booleans);
  }
}

export class InNestedEconomicUnitFilter extends SimpleWhereIntsFilter {
  constructor(economicUnitIds: number[]) {
    super(`${ECONOMIC_UNIT_PROPERTY_NAME}.${economic_unit_select_column.id}`, economicUnitIds);
  }
}

export class InNestedPortfolioFilter extends SimpleWhereIntsFilter {
  constructor(portfolioIds: number[]) {
    super(`${ECONOMIC_UNIT_PROPERTY_NAME}.${economic_unit_select_column.portfolio_id}`, portfolioIds);
  }
}

export class InNestedEnergySystemConsumerRouteEnergySourceTypeFilter extends WhereFilter<
  IWhereEnergySourceType,
  energy_source_type_enum_comparison_exp
> {
  constructor(private readonly energySourceTypes: energy_source_type_enum[]) {
    super(
      `${ACTIVE_BUILDING_MODEL_PROPERTY_NAME}.${ENERGY_SYSTEM_NESTED_PROPERTY_NAME}.${ENERGY_SYSTEM_CONSUMER_ROUTE_NESTED_PROPERTY_NAME}.${energy_system_consumer_route_select_column.energy_source_type_id}`,
    );
  }

  toGetAnalyzedSubBuildingsWhere(): IWhereEnergySourceType | null {
    if (this.energySourceTypes.length > 0) {
      return {
        op: OperatorInEnum._in,
        value: this.energySourceTypes,
      };
    }
    return null;
  }

  toSubBuildingsWhere(): energy_source_type_enum_comparison_exp | null {
    if (this.energySourceTypes.length > 0) {
      return {
        [OperatorInEnum._in]: this.energySourceTypes,
      };
    }
    return null;
  }
}

export class InNestedEnergyPathYearFilter extends WhereFilter<IWhereInt, Int_comparison_exp> {
  constructor(private readonly year: number) {
    super(
      `${ACTIVE_BUILDING_MODEL_PROPERTY_NAME}.${ENERGY_PATH_NESTED_PROPERTY_NAME}.${energy_path_select_column.year}`,
    );
  }

  toGetAnalyzedSubBuildingsWhere(): IWhereInt {
    return {
      op: OperatorEnum._eq,
      value: this.year,
    };
  }

  toSubBuildingsWhere(): Int_comparison_exp {
    return {
      [OperatorEnum._eq]: this.year,
    };
  }
}

export class InNestedEnergyPathTaxBracketFilter extends WhereFilter<IWhereTaxBracket, tax_bracket_enum_comparison_exp> {
  constructor(private readonly taxBracket: tax_bracket_enum[]) {
    super(
      `${ACTIVE_BUILDING_MODEL_PROPERTY_NAME}.${ENERGY_PATH_NESTED_PROPERTY_NAME}.${energy_path_select_column.tax_bracket_id}`,
    );
  }

  toGetAnalyzedSubBuildingsWhere(): IWhereTaxBracket | null {
    if (this.taxBracket.length > 0) {
      return {
        op: OperatorInEnum._in,
        value: this.taxBracket,
      };
    }
    return null;
  }

  toSubBuildingsWhere(): tax_bracket_enum_comparison_exp | null {
    if (this.taxBracket.length > 0) {
      return {
        [OperatorInEnum._in]: this.taxBracket,
      };
    }
    return null;
  }
}

export class InNestedEnergyPathEfficiencyClassFilter extends WhereFilter<
  IWhereEfficiencyClass,
  efficiency_class_enum_comparison_exp
> {
  constructor(private readonly efficiencyClass: efficiency_class_enum[]) {
    super(
      `${ACTIVE_BUILDING_MODEL_PROPERTY_NAME}.${ENERGY_PATH_NESTED_PROPERTY_NAME}.${energy_path_select_column.efficiency_class_id}`,
    );
  }

  toGetAnalyzedSubBuildingsWhere(): IWhereEfficiencyClass | null {
    if (this.efficiencyClass.length > 0) {
      return {
        op: OperatorInEnum._in,
        value: this.efficiencyClass,
      };
    }
    return null;
  }

  toSubBuildingsWhere(): efficiency_class_enum_comparison_exp | null {
    if (this.efficiencyClass.length > 0) {
      return {
        [OperatorInEnum._in]: this.efficiencyClass,
      };
    }
    return null;
  }
}

export class InNestedSubBuildingClassFilter extends WhereFilter<
  IWhereSubBuildingClass,
  sub_building_class_enum_comparison_exp
> {
  constructor(private readonly subBuildingClass: sub_building_class_enum[]) {
    super(`${AREA_NESTED_PROPERTY_NAME}.${area_select_column.class_of_use_id}`);
  }

  toGetAnalyzedSubBuildingsWhere(): IWhereSubBuildingClass | null {
    if (this.subBuildingClass.length > 0) {
      return {
        op: OperatorInEnum._in,
        value: this.subBuildingClass,
      };
    }
    return null;
  }

  toSubBuildingsWhere(): sub_building_class_enum_comparison_exp | null {
    if (this.subBuildingClass.length > 0) {
      return {
        [OperatorInEnum._in]: this.subBuildingClass,
      };
    }
    return null;
  }
}

export class InNestedTypeOfUseFilter extends WhereFilter<IWhereTypeOfUse, type_of_use_enum_comparison_exp> {
  constructor(private readonly typeOfUse: type_of_use_enum[]) {
    super(`${AREA_NESTED_PROPERTY_NAME}.${area_select_column.type_of_use_id}`);
  }

  toGetAnalyzedSubBuildingsWhere(): IWhereTypeOfUse | null {
    if (this.typeOfUse.length > 0) {
      return {
        op: OperatorInEnum._in,
        value: this.typeOfUse,
      };
    }
    return null;
  }

  toSubBuildingsWhere(): type_of_use_enum_comparison_exp | null {
    if (this.typeOfUse.length > 0) {
      return {
        [OperatorInEnum._in]: this.typeOfUse,
      };
    }
    return null;
  }
}

export class InNestedEnergyPathEUTaxonomyComplianceFilter extends WhereFilter<
  IWhereEuTaxonomyCompliance,
  eu_taxonomy_compliance_enum_comparison_exp
> {
  constructor(private readonly euTaxonomyComplianceClasses: eu_taxonomy_compliance_enum[]) {
    super(
      `${ACTIVE_BUILDING_MODEL_PROPERTY_NAME}.${ENERGY_PATH_NESTED_PROPERTY_NAME}.${energy_path_select_column.eu_taxonomy_compliance_id}`,
    );
  }

  toGetAnalyzedSubBuildingsWhere(): IWhereEuTaxonomyCompliance | null {
    if (this.euTaxonomyComplianceClasses.length > 0) {
      return {
        op: OperatorInEnum._in,
        value: this.euTaxonomyComplianceClasses,
      };
    }
    return null;
  }

  toSubBuildingsWhere(): eu_taxonomy_compliance_enum_comparison_exp | null {
    if (this.euTaxonomyComplianceClasses.length > 0) {
      return {
        [OperatorInEnum._in]: this.euTaxonomyComplianceClasses,
      };
    }
    return null;
  }
}

export class SearchCriteriaFilter extends WhereFilter<null, building_bool_exp> {
  private readonly searchText: string;

  constructor(searchText: string) {
    super('searchCriteria'); // Set a valid property name
    this.searchText = searchText;
  }

  toGetAnalyzedSubBuildingsWhere(): null {
    return null; // Not used in this context
  }

  toSubBuildingsWhere(): building_bool_exp | null {
    if (this.searchText) {
      return {
        _or: [
          { address: { street: { _ilike: `%${this.searchText}%` } } },
          { address: { city: { _ilike: `%${this.searchText}%` } } },
          { address: { postal_code: { _ilike: `%${this.searchText}%` } } },
        ],
      };
    }
    return null;
  }
}

abstract class BaseRangeFilter extends WhereFilter<IConjunctionWhereInt, Int_comparison_exp> {
  private readonly conjunction: ConjunctionEnum = ConjunctionEnum._and;
  constructor(
    propertyName: string,
    private readonly minValue: number | null,
    private readonly maxValue: number | null,
  ) {
    super(propertyName);
  }

  toGetAnalyzedSubBuildingsWhere(): IConjunctionWhereInt | null {
    const values: IWhereInt[] = [];
    if (!isNil(this.minValue)) {
      values.push({
        op: OperatorEnum._gte,
        value: this.minValue,
      });
    }
    if (!isNil(this.maxValue)) {
      values.push({
        op: OperatorEnum._lte,
        value: this.maxValue,
      });
    }
    return !isEmpty(values) ? { conjunction: this.conjunction, values } : null;
  }

  toSubBuildingsWhere(): Int_comparison_exp | null {
    const conditions: Int_comparison_exp = {};
    if (!isNil(this.minValue)) {
      conditions._gte = this.minValue;
    }
    if (!isNil(this.maxValue)) {
      conditions._lte = this.maxValue;
    }
    return !isEmpty(conditions) ? conditions : null;
  }
}

export class InRangeFinalEnergyFilter extends BaseRangeFilter {
  constructor(minValue: number | null, maxValue: number | null) {
    super(`${ACTIVE_BUILDING_MODEL_PROPERTY_NAME}.${building_model_select_column.energy_final}`, minValue, maxValue);
  }
}

export class InRangePrimaryEnergyFilter extends BaseRangeFilter {
  constructor(minValue: number | null, maxValue: number | null) {
    super(`${ACTIVE_BUILDING_MODEL_PROPERTY_NAME}.${building_model_select_column.energy_primary}`, minValue, maxValue);
  }
}

export class InRangeCo2EmissionsFilter extends BaseRangeFilter {
  constructor(minValue: number | null, maxValue: number | null) {
    super(
      `${ACTIVE_BUILDING_MODEL_PROPERTY_NAME}.${ENERGY_PATH_NESTED_PROPERTY_NAME}.${energy_path_select_column.crrem_co2_emissions}`,
      minValue,
      maxValue,
    );
  }
}

/**
 * Converts an array of filters to the corresponding where object which can be consumed by our getAnalyzedSubBuildings resolver method.
 */
export const getAnalyzedBuildingsWhere = (
  filters: Array<WhereFilter<unknown, unknown>>,
  year: number = new Date().getFullYear(),
): BuildingWhere => {
  const extendedFilters = filters.concat(new InNestedEnergyPathYearFilter(year));

  checkDuplicateFilters(extendedFilters);
  const buildingWhere = {};

  for (const filter of extendedFilters) {
    const filterGetAnalyzedBuildingWhere = filter.toGetAnalyzedSubBuildingsWhere();
    if (!filterGetAnalyzedBuildingWhere) {
      continue;
    }

    set(buildingWhere, filter.propertyName, filterGetAnalyzedBuildingWhere);
  }

  return buildingWhere;
};

/**
 * Converts an array of filters to the corresponding graphql where sub_building table object.
 */
export const getBuildingsWhere = (
  filters: Array<WhereFilter<unknown, unknown>>,
  searchCriteria = '',
  year: number = new Date().getFullYear(),
): building_bool_exp => {
  const extendedFilters = filters.concat(new InNestedEnergyPathYearFilter(year));

  checkDuplicateFilters(extendedFilters);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const buildingsWhere: Record<ConjunctionEnum, any[]> = {};
  for (const filter of extendedFilters) {
    const filterBuildingsWhere = filter.toSubBuildingsWhere();
    if (!filterBuildingsWhere) {
      continue;
    }

    set(buildingsWhere, filter.propertyName, filterBuildingsWhere);
  }

  if (searchCriteria) {
    const searchCriteriaFilter = new SearchCriteriaFilter(searchCriteria);
    const searchCriteriaBuildingsWhere = searchCriteriaFilter.toSubBuildingsWhere();
    if (searchCriteriaBuildingsWhere) {
      if (!buildingsWhere[ConjunctionEnum._or]) {
        buildingsWhere[ConjunctionEnum._or] = [];
      }
      buildingsWhere[ConjunctionEnum._or].push(searchCriteriaBuildingsWhere);
    }
  }

  return buildingsWhere;
};

/**
 * Checks if any property is duplicated such as referred to in graphql-where.md and throws an error for the developer.
 * ```
 *  getAnalyzedSubBuildings(where: {
 *    area_usable: {op: _gte, value: 100},
 *   area_usable: {op: _lt, value: 9999}
 *  }) { ... }
 * ```
 */
const checkDuplicateFilters = (filters: WhereFilter<unknown, unknown>[]): void => {
  const counts = countBy(filters, (f: WhereFilter<unknown, unknown>) => f.propertyName);
  const duplicates = filters.filter((f) => counts[f.propertyName] > 1);
  if (duplicates.length > 0) {
    throw new Error(`Duplicate filters for: ${duplicates.map((f) => f.propertyName)}`);
  }
};
